import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Select from "../../Components/Select"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const ClientPhone = ({jsonClientPhone, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [clientCode, setClientCode] = useState("")
  const [number, setNumber] = useState("")
  const [contact, setContact] = useState("")
  const [standard, setStandard] = useState(2)

  const [firstAcess, setFirstAcess] = useState(true)

  useEffect(() => {
    setCode(jsonClientPhone.ID)
    setClientCode(jsonClientPhone.CLIENT.ID)

    if ((jsonClientPhone.NUMBER !== undefined) && (jsonClientPhone.NUMBER != null)) setNumber(jsonClientPhone.NUMBER)
    if ((jsonClientPhone.CONTACT !== undefined) && (jsonClientPhone.CONTACT != null)) setContact(jsonClientPhone.CONTACT)

    setStandard(jsonClientPhone.STANDARD)

    if (firstAcess){
      setFirstAcess(false)
    } else document.getElementById("clientPhoneNumber") && document.getElementById("clientPhoneNumber").focus()

  }, [jsonClientPhone, firstAcess])

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"CLIENT\": { \"ID\": " + clientCode + " }"
    if (number !== undefined) json += ", \"NUMBER\": \""+ number.replaceAll("(","").replaceAll(")","").replaceAll("_","") +"\""
    else json += ", \"NUMBER\": \"\""
    if (contact !== undefined) json += ", \"CONTACT\": \""+ contact +"\""
    else json += ", \"CONTACT\": \"\""
    json += ", \"STANDARD\": "+ standard + " }"

    save(json)
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

        <div style={(showMenu) ?
            (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
          :
            (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

          <div style={(showMenu) ?
              (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : (width < 830) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 950) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}
            :
              (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}            
              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

            <div style={(showMenu) ?
                (width < 950) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
              :
                (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

              <InputMasked
                type="text"
                name="clientPhoneNumber"
                description="Telefone"
                mask="(999)999999999"
                value={number}
                disabled={false}
                require={true}
                image={false}
                typeImage={0}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setNumber(event.target.value)} />
            </div>
            <div style={(showMenu)?
                (width < 560) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                : (width < 830) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
              :
                (width < 560) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, minWidth: 180}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

              <Select
                name="clientPhoneStandard"
                description="Padrão"
                value={standard || 2}
                require={false}
                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                image={false}
                typeImage={0}
                typeInput={2}
                onSelect={setFieldSelected}
                set={setStandard} />
            </div>
          </div>
          <div style={(showMenu) ?
              (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10}
            :             
              (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

            <Input
              type="text"
              name="clientPhoneContact"
              description="Contato"
              value={contact}
              maxLength={60}
              disabled={false}
              require={false}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setContact(event.target.value)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientPhone