import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Titulo from "../../Components/Titulo"
import AccessControl from "../AccessControl"
import SearchEmployee from "./Search"
import SearchEmployeePhone from "../EmployeePhone/Search"
import SearchRhPosition from "../RhPosition/Search"
import SearchRhShift from "../RhShift/Search"
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Employee = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [registration, setRegistration] = useState("")
  const [situation, setSituation] = useState(0)

  const [cpf, setCpf] = useState("")
  const [rg, setRg] = useState("")
  const [name, setName] = useState("")
  const [birthday, setBirthday] = useState("")
  const [password, setPassword] = useState("")
  const [barcode, setBarcode] = useState("")
  const [permission, setPermission] = useState(0)
  const [email, setEmail] = useState("")
  const [photo, setPhoto] = useState("")
  const [observation, setObservation] = useState("")

  const [rhPositionCode, setRhPositionCode] = useState("")
  const [rhPositionDescription, setRhPositionDescription] = useState("")

  const [rhShiftCode, setRhShiftCode] = useState("")
  const [rhShiftDescription, setRhShiftDescription] = useState("")

  const [ctps, setCtps] = useState("")
  const [ctpsSeries, setCtpsSeries] = useState("")
  const [salary, setSalary] = useState(0)
  const [emissionDate, setEmissionDate] = useState("")
  const [layoffDate, setLayoffDate] = useState("")

  const [addressRelease, setAddressRelease] = useState(false)

  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [cityIBGE, setCityIBGE] = useState("")
  const [uf, setUf] = useState("")
  const [ufIBGE, setUfIBGE] = useState("")
  const [countryCode, setCountryCode] = useState(0)
  const [countryDescription, setCountryDescription] = useState("")
  const [countryIBGE, setCountryIBGE] = useState("")

  const [orderDiscount, setOrderDiscount] = useState(0)
  const [porcentageDiscount, setPorcentageDiscount] = useState(0)
  const [executesOS, setExecutesOS] = useState(0)
  const [transfersOS, setTransfersOS] = useState(0)
  const [finalizesOS, setFinalizesOS] = useState(0)
  const [access, setAccess] = useState("")
  const [sge, setSge] = useState("")

  const [monday, setMonday] = useState(1)
  const [tuesday, setTuesday] = useState(1)
  const [wednesday, setWednesday] = useState(1)
  const [thursday, setThursday] = useState(1)
  const [friday, setFriday] = useState(1)
  const [saturday, setSaturday] = useState(1)
  const [sunday, setSunday] = useState(1)
  
  const [national, setNational] = useState(true)
  const [listCountry, setListCountry] = useState([])
  const [optionsCountries, setOptionsCountries] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  
  const [jEmployee, setJEmployee] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showRHPosition, setShowRHPosition] = useState(false)
  const [showRHShift, setShowRHShift] = useState(false)
  const [showAccess, setShowAccess] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (firstAcess) {
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "COUNTRY/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os países. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          let country = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              country += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\"}"
            ))

          country += "]}"

          setListCountry(returnJson)
          setOptionsCountries(country)
          setCountryCode(34)

          setLoading("")
          setFirstAcess(false)
        }
      })
    }
    else{
      (countryDescription === "BRASIL") ? setNational(true) : setNational(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }

    if (code === ""){
      setRegistration("")
      setSituation(0)

      setCpf("")
      setRg("")
      setName("")
      setBirthday("")
      setPassword("")
      setBarcode("")
      setPermission(0)
      setEmail("")
      setPhoto("")
      setObservation("")

      setRhPositionCode("")
      setRhPositionDescription("")

      setRhShiftCode("")
      setRhShiftDescription("")

      setCtps("")
      setCtpsSeries("")
      setSalary(0)
      setEmissionDate("")
      setLayoffDate("")

      setAddressRelease(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setOrderDiscount(0)
      setPorcentageDiscount(0)
      setExecutesOS(0)
      setTransfersOS(0)
      setFinalizesOS(0)
      setAccess("F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F")

      setSge("")
    }
    else{
      if ((jEmployee.REGISTRATION !== null) && (jEmployee.REGISTRATION !== undefined)) setRegistration(jEmployee.REGISTRATION)

      setSituation(jEmployee.SITUATION)
      setCpf(jEmployee.CPF)

      if ((jEmployee.RG !== null) && (jEmployee.RG !== undefined)) setRg(jEmployee.RG)

      setName(jEmployee.NAME)

      if ((jEmployee.DATE_BIRTH !== null) &&  (jEmployee.DATE_BIRTH !== undefined)) setBirthday(jEmployee.DATE_BIRTH)

      setPassword(jEmployee.PASSWORD)
      setBarcode(jEmployee.BAR_CODE)
      setPermission(jEmployee.PERMISSION)

      if ((jEmployee.EMAIL !== null) && (jEmployee.EMAIL !== undefined)) setEmail(jEmployee.EMAIL)
      if ((jEmployee.PATH_PHOTO !== null) && (jEmployee.PATH_PHOTO !== undefined)) setPhoto(jEmployee.PATH_PHOTO)
      if ((jEmployee.OBSERVATION !== null) && (jEmployee.OBSERVATION !== undefined)) setObservation(jEmployee.OBSERVATION)
      if ((jEmployee.CTPS !== null) && (jEmployee.CTPS !== undefined)) setCtps(jEmployee.CTPS)
      if ((jEmployee.CTPS_SERIES !== null) && (jEmployee.CTPS_SERIES !== undefined)) setCtpsSeries(jEmployee.CTPS_SERIES)
      if (jEmployee.SALARY > 0) setSalary(formatNumeric(jEmployee.SALARY, 2)); else setSalary("0.00")

      let counter = 1
      const days_array = jEmployee.WORK_DAYS.split("/")
      days_array.forEach((dia) => {
        if (counter === 1){
          if (dia === "T") setMonday(1)
            else setMonday(2)
        } else if (counter === 2){
        if (dia === "T") setTuesday(1)
        else setTuesday(2)
        } else if (counter === 3){
          if (dia === "T") setWednesday(1)
          else setWednesday(2)
        } else if (counter === 4){
          if (dia === "T") setThursday(1)
          else setThursday(2)
        } else if (counter === 5){
          if (dia === "T") setFriday(1)
          else setFriday(2)
        } else if (counter === 6){
          if (dia === "T") setSaturday(1)
          else setSaturday(2)
        } else if (counter === 7){
          if (dia === "T") setSunday(1)
          else setSunday(2)
        }

        counter++
      })

      let dtEmissionDate = ""
      if (jEmployee.DATE_ADMISSION !== "") dtEmissionDate = jEmployee.DATE_ADMISSION.substr(8,2) + "\\" +
        jEmployee.DATE_ADMISSION.substr(5,2) + "\\" + jEmployee.DATE_ADMISSION.substr(0,4)        
      setEmissionDate(dtEmissionDate)
        
      let dtLayoffDate = ""
      if (((jEmployee.DATE_LAYOFF !== null) && (jEmployee.DATE_LAYOFF !== undefined)) && (jEmployee.DATE_LAYOFF !== "0001-01-01T00:00:00")) {
        dtLayoffDate = jEmployee.DATE_LAYOFF.substr(8,2) + "\\" + jEmployee.DATE_LAYOFF.substr(5,2) + "\\" + jEmployee.DATE_LAYOFF.substr(0,4)
        setLayoffDate(dtLayoffDate)
      }

      if ((jEmployee.RH_POSITION !== null) && (jEmployee.RH_POSITION !== undefined)) {
        setRhPositionCode(jEmployee.RH_POSITION.ID)
        setRhPositionDescription(jEmployee.RH_POSITION.DESCRIPTION)
      }

      if ((jEmployee.RH_SHIFT !== null) && (jEmployee.RH_SHIFT !== undefined)) {
        setRhShiftCode(jEmployee.RH_SHIFT.ID)
        setRhShiftDescription(jEmployee.RH_SHIFT.DESCRIPTION)
      }

      setCep(jEmployee.CEP)
      setAddress(jEmployee.ADDRESS)
      setNumber(jEmployee.NUMBER)

      if ((jEmployee.COMPLEMENT !== null) && (jEmployee.COMPLEMENT !== undefined)) setComplement(jEmployee.COMPLEMENT)

      setNeighborhood(jEmployee.NEIGHBORHOOD)
      setCity(jEmployee.CITY)
      setCityIBGE(jEmployee.CITY_IBGE)
      setUf(jEmployee.UF)
      setUfIBGE(jEmployee.UF_IBGE)

      if (jEmployee.COUNTRY_DESCRIPTION === "BRASIL") setNational(true); else setNational(false)

      let codeP = 0;
      listCountry.OBJECT.map(item => (
        (item.NAME === jEmployee.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
      ))

      setCountryCode(codeP)
      setCountryDescription(jEmployee.COUNTRY_DESCRIPTION)
      setCountryIBGE(jEmployee.COUNTRY_IBGE)
        
      setOrderDiscount(jEmployee.ORDER_DISCOUNT)

      if (jEmployee.PERCENTAGE_DISCOUNT > 0) setPorcentageDiscount(formatNumeric(jEmployee.PERCENTAGE_DISCOUNT, 0)) 
      else setPorcentageDiscount("0")

      setExecutesOS(jEmployee.EXECUTE_OS)
      setTransfersOS(jEmployee.TRANSFER_OS)
      setFinalizesOS(jEmployee.FINALIZES_OS)

      if ((jEmployee.ACCESS !== null) && (jEmployee.ACCESS !== undefined)) setAccess(jEmployee.ACCESS)
      if ((jEmployee.SGE !== null) && (jEmployee.SGE !== undefined)) setSge(jEmployee.SGE)
    }
    
    document.getElementById("employeeRegistration") && document.getElementById("employeeRegistration").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, code, jEmployee, countryDescription, listCountry.OBJECT,
    updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setRegistration("")
      setSituation(0)

      setCpf("")
      setRg("")
      setName("")
      setBirthday("")
      setPassword("")
      setBarcode("")
      setPermission(0)
      setEmail("")
      setPhoto("")
      setObservation("")

      setRhPositionCode("")
      setRhPositionDescription("")

      setRhShiftCode("")
      setRhShiftDescription("")

      setCtps("")
      setCtpsSeries("")
      setSalary(0)
      setEmissionDate("")
      setLayoffDate("")

      setAddressRelease(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setOrderDiscount(0)
      setPorcentageDiscount(0)
      setExecutesOS(0)
      setTransfersOS(0)
      setFinalizesOS(0)
      setAccess("F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F")

      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const clearAddress = () => {
    setCep("")
    setAddress("")
    setNumber("")
    setComplement("")
    setNeighborhood("")
    setCity("")
    setCityIBGE("")
    setUf("")
    setUfIBGE("")

    setAddressRelease(false)
  }

  const cleanRHPosition = () => {
    setRhPositionCode("")
    setRhPositionDescription("")
    setShowRHPosition(false)
  }

  const cleanRHShift = () => {
    setRhShiftCode("")
    setRhShiftDescription("")
    setShowRHShift(false)
  }

  const enableRHPosition = () => {
    setShowRHPosition(!showRHPosition)
  }

  const enableRHShift = () => {
    setShowRHShift(!showRHShift)
  }

  const fillRHPosition = (position) => {
    setRhPositionCode(position.ID)
    setRhPositionDescription(position.DESCRIPTION) 
    setShowRHPosition(false)
  }

  const fillRHShift = (shift) => {
    setRhShiftCode(shift.ID)
    setRhShiftDescription(shift.DESCRIPTION) 
    setShowRHShift(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }

  const handleFile = (e) => {
    const _file = e.target.files[0];
    uploadPhoto(_file)
  }  

  const handleFriday = () => {
    setFriday(2)
  }

  const handleFridayC = () => {
    setFriday(1)
  }

  const handleMonday = () => {
    setMonday(2)
  }

  const handleMondayC = () => {
    setMonday(1)
  }

  const handlePorcentageDiscount = (event) => {
    setPorcentageDiscount(formatNumeric(event.target.value, 0))
  }

  const handleSalary = (event) => {
    setSalary(formatNumeric(event.target.value, 2))
  }

  const handleSaturday = () => {
    setSaturday(2)
  }

  const handleSaturdayC = () => {
    setSaturday(1)
  }

  const handleSunday = () => {
    setSunday(2)
  }

  const handleSundayC = () => {
    setSunday(1)
  }

  const handleThursday = () => {
    setThursday(2)
  }

  const handleThursdayC = () => {
    setThursday(1)
  }

  const handleTuesday = (event) => {
    setTuesday(2)
  }

  const handleTuesdayC = (event) => {
    setTuesday(1)
  }

  const handleWednesday = () => {
    setWednesday(2)
  }

  const handleWednesdayC = () => {
    setWednesday(1)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonEmployee_New = {}

    jsonEmployee_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== ""))
      jsonEmployee_New += ", \"ID\": "+ code
    if (((registration !== undefined) && (registration !== null)) && (registration !== ""))
      jsonEmployee_New += ", \"REGISTRATION\": "+ registration
    if (((cpf !== undefined) && (cpf !== null)) && (cpf !== ""))
      jsonEmployee_New += ", \"CPF\": \""+ cpf +"\""
    if (((rg !== undefined) && (rg !== null)) && (rg !== ""))
      jsonEmployee_New += ", \"RG\": \""+ rg +"\""
    if (((name !== undefined) && (name !== null)) && (name !== ""))
      jsonEmployee_New += ", \"NAME\": \""+ name +"\""
    if (((birthday !== undefined) && (birthday !== null)) && (birthday !== ""))
      jsonEmployee_New += ", \"DATE_BIRTH\": \""+ birthday +"\""

    if (((password !== undefined) && (password !== null)) && (password !== "")) 
      jsonEmployee_New += ", \"PASSWORD\": \""+ password +"\""
    else jsonEmployee_New += ", \"PASSWORD\": \"123\""

    if (((barcode !== undefined) && (barcode !== null)) && (barcode !== ""))
      jsonEmployee_New += ", \"BAR_CODE\": \""+ barcode.replaceAll("_","") +"\""
    else jsonEmployee_New += ", \"BAR_CODE\": \""+ cpf +"\""

    jsonEmployee_New += ", \"PERMISSION\": "+ permission

    if (((email !== undefined) && (email !== null)) && (email !== ""))
      jsonEmployee_New += ", \"EMAIL\": \""+ email +"\""
    if (((ctps !== undefined) && (ctps !== null)) && (ctps !== ""))
      jsonEmployee_New += ", \"CTPS\": \""+ ctps +"\""
    if (((ctpsSeries !== undefined) && (ctpsSeries !== null)) && (ctpsSeries !== ""))
      jsonEmployee_New += ", \"CTPS_SERIES\": \""+ ctpsSeries +"\""

    jsonEmployee_New += ", \"SALARY\": "+ salary

    let days = ""
    if (monday === 1) days += "T/"
    else days += "F/"
    if (tuesday === 1) days += "T/"
    else days += "F/"
    if (wednesday === 1) days += "T/"
    else days += "F/"
    if (thursday === 1) days += "T/"
    else days += "F/"
    if (friday === 1) days += "T/"
    else days += "F/"
    if (saturday === 1) days += "T/"
    else days += "F/"
    if (sunday === 1) days += "T"
    else days += "F"
    jsonEmployee_New += ", \"WORK_DAYS\": \""+ days +"\""

    if (((emissionDate !== undefined) && (emissionDate !== null)) && (emissionDate !== "")) {
      const sEmissionDate = emissionDate.substr(6, 4) + "-" + emissionDate.substr(3, 2) + "-" + emissionDate.substr(0, 2) + "T00:00:00"
        jsonEmployee_New += ", \"DATE_ADMISSION\": \""+ sEmissionDate +"\""
    }
    else{
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      jsonEmployee_New += ", \"DATE_ADMISSION\": \""+ year + "-" + month + "-" + day + "T00:00:00\""
    }

    if (((layoffDate !== undefined) && (layoffDate !== null)) && (layoffDate !== "")) {
      const sLayoffDate = layoffDate.substr(6, 4) + "-" + layoffDate.substr(3, 2) + "-" + layoffDate.substr(0, 2) + "T00:00:00"
      jsonEmployee_New += ", \"DATE_LAYOFF\": \""+ sLayoffDate +"\""
    }        

    let cRhPosition = 0
    if (((rhPositionCode !== undefined) && (rhPositionCode !== null)) && (rhPositionCode !== ""))
      cRhPosition = rhPositionCode

    if (cRhPosition !== 0){
      jsonEmployee_New += ", \"RH_POSITION\": { \"ID\": "+ cRhPosition +
      ", \"DESCRIPTION\": \""+ rhPositionDescription +"\", \"RH_SECTION\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } } }"
    }
    
    let cRhShift = 0
    if (((rhShiftCode !== undefined) && (rhShiftCode !== null)) && (rhShiftCode !== ""))
      cRhShift = rhShiftCode

    if (cRhShift !== 0){
      jsonEmployee_New += ", \"RH_SHIFT\": { \"ID\": "+ cRhShift +
      ", \"DESCRIPTION\": \""+ rhShiftDescription +"\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }"
    }

    if (((cep !== undefined) && (cep !== null)) && (cep !== ""))
      jsonEmployee_New += ", \"CEP\": "+ cep

    jsonEmployee_New += ", \"ADDRESS\": \""+ address +"\""
    jsonEmployee_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== ""))
      jsonEmployee_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonEmployee_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonEmployee_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIBGE !== undefined) && (cityIBGE !== null)) && (cityIBGE !== ""))
        jsonEmployee_New += ", \"CITY_IBGE\": "+ cityIBGE
    }
    else jsonEmployee_New += ", \"CITY_IBGE\": "+ 9999999
        
    jsonEmployee_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIBGE !== undefined) && (ufIBGE !== null)) && (ufIBGE !== ""))
        jsonEmployee_New += ", \"UF_IBGE\": "+ ufIBGE
    }        
    else jsonEmployee_New += ", \"UF_IBGE\": "+ 99

    jsonEmployee_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonEmployee_New += ", \"COUNTRY_IBGE\": "+ countryIBGE
    else jsonEmployee_New += ", \"COUNTRY_IBGE\": "+ 9999

    if (((photo !== undefined) && (photo !== null)) && (photo !== ""))
      jsonEmployee_New += ", \"PATH_PHOTO\": \""+ photo +"\""

    if (orderDiscount !== 0) jsonEmployee_New += ", \"ORDER_DISCOUNT\": "+ orderDiscount
    else jsonEmployee_New += ", \"ORDER_DISCOUNT\": 2"

    jsonEmployee_New += ", \"PERCENTAGE_DISCOUNT\": "+ porcentageDiscount

    if (jsonCompany.TYPE === 1) {
      if (executesOS !== 0) jsonEmployee_New += ", \"EXECUTE_OS\": "+ executesOS
      else jsonEmployee_New += ", \"EXECUTE_OS\": 2"

      if (transfersOS !== 0) jsonEmployee_New += ", \"TRANSFER_OS\": "+ transfersOS
      else jsonEmployee_New += ", \"TRANSFER_OS\": 2"

      if (finalizesOS !== 0) jsonEmployee_New += ", \"FINALIZES_OS\": "+ finalizesOS
      else jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    } 
    else if (jsonCompany.TYPE === 2) {
      jsonEmployee_New += ", \"EXECUTE_OS\": 2"
      jsonEmployee_New += ", \"TRANSFER_OS\": 2"
      jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    } 
    else if (jsonCompany.TYPE === 3) {
      if (executesOS !== 0) jsonEmployee_New += ", \"EXECUTE_OS\": "+ executesOS
      else jsonEmployee_New += ", \"EXECUTE_OS\": 2"

      if (transfersOS !== 0) jsonEmployee_New += ", \"TRANSFER_OS\": "+ transfersOS
      else jsonEmployee_New += ", \"TRANSFER_OS\": 2"

      if (finalizesOS !== 0) jsonEmployee_New += ", \"FINALIZES_OS\": "+ finalizesOS
      else jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    } 
    else if (jsonCompany.TYPE === 4) {
      if (executesOS !== 0) jsonEmployee_New += ", \"EXECUTE_OS\": "+ executesOS
      else jsonEmployee_New += ", \"EXECUTE_OS\": 2"

      if (transfersOS !== 0) jsonEmployee_New += ", \"TRANSFER_OS\": "+ transfersOS
      else jsonEmployee_New += ", \"TRANSFER_OS\": 2"

      if (finalizesOS !== 0) jsonEmployee_New += ", \"FINALIZES_OS\": "+ finalizesOS
      else jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    } 
    else if (jsonCompany.TYPE === 5) {
      jsonEmployee_New += ", \"EXECUTE_OS\": 2"
      jsonEmployee_New += ", \"TRANSFER_OS\": 2"
      jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    }
    else if (jsonCompany.TYPE === 6) {
      jsonEmployee_New += ", \"EXECUTE_OS\": 2"
      jsonEmployee_New += ", \"TRANSFER_OS\": 2"
      jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    }
    else if (jsonCompany.TYPE === 7) {
      jsonEmployee_New += ", \"EXECUTE_OS\": 2"
      jsonEmployee_New += ", \"TRANSFER_OS\": 2"
      jsonEmployee_New += ", \"FINALIZES_OS\": 2"
    }

    if (((observation !== undefined) && (observation !== null)) && (observation !== ""))
      jsonEmployee_New += ", \"OBSERVATION\": \""+ observation +"\""
    if (((access !== undefined) && (access !== null)) && (access !== ""))
      jsonEmployee_New += ", \"ACCESS\": \""+ access +"\""

    if (situation !== 0) jsonEmployee_New += ", \"SITUATION\": "+ situation
    else jsonEmployee_New += ", \"SITUATION\": 1"

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonEmployee_New += ", \"SGE\": "+ sge
    jsonEmployee_New += " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonEmployee_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "EMPLOYEE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o funcionário. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("employeeDescription") && document.getElementById("employeeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJEmployee(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)        
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jEmployee))
      formData.append("JSON_NEW_1", jsonEmployee_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "EMPLOYEE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o funcionário. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("employeeDescription") && document.getElementById("employeeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJEmployee(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const searchCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setWarning("")
      setError("É necessário informar o CEP.")

      return
    }

    setLoading("Aguarde buscando o CEP...")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {
      if (response.data.erro !== "true") {
        setWarning("")
        setError("")

        if ((response.data.logradouro === '') && (response.data.bairro === '')) setAddressRelease(true)
        else setAddressRelease(false)

        setAddress(response.data.logradouro)
        setNeighborhood(response.data.bairro)
        setCity(response.data.localidade)
        setCityIBGE(response.data.ibge)
        setUf(response.data.uf)

        if (response.data.uf === "RO") setUfIBGE(11)
        if (response.data.uf === "AC") setUfIBGE(12)
        if (response.data.uf === "AM") setUfIBGE(13)
        if (response.data.uf === "RR") setUfIBGE(14)
        if (response.data.uf === "PA") setUfIBGE(15)
        if (response.data.uf === "AP") setUfIBGE(16)
        if (response.data.uf === "TO") setUfIBGE(17)
        if (response.data.uf === "MA") setUfIBGE(21)
        if (response.data.uf === "PI") setUfIBGE(22)
        if (response.data.uf === "CE") setUfIBGE(23)
        if (response.data.uf === "RN") setUfIBGE(24)
        if (response.data.uf === "PB") setUfIBGE(25)
        if (response.data.uf === "PE") setUfIBGE(26)
        if (response.data.uf === "AL") setUfIBGE(27)
        if (response.data.uf === "SE") setUfIBGE(28)
        if (response.data.uf === "BA") setUfIBGE(29)
        if (response.data.uf === "MG") setUfIBGE(31)
        if (response.data.uf === "ES") setUfIBGE(32)
        if (response.data.uf === "RJ") setUfIBGE(33)
        if (response.data.uf === "SP") setUfIBGE(35)
        if (response.data.uf === "PR") setUfIBGE(41)
        if (response.data.uf === "SC") setUfIBGE(42)
        if (response.data.uf === "RS") setUfIBGE(43)
        if (response.data.uf === "MS") setUfIBGE(50)
        if (response.data.uf === "MT") setUfIBGE(51)
        if (response.data.uf === "GO") setUfIBGE(52)
        if (response.data.uf === "DF") setUfIBGE(53)
      } 
      else
      {
        setWarning("")
        setError("CEP não encontrado.")
        alert("CEP não encontrado.")

        setAddress("")
        setNeighborhood("")
        setCity("")
        setCityIBGE("")
        setUf("")
        setUfIBGE("")
      }
    })
    .catch(() => {
      setWarning("")
      setError("Não foi possível obter CEP.")
      alert("Não foi possível obter CEP.")
    }) 
    .finally(() => { setLoading("") })
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)

    if (event.ID === 1){
      alert("Não é possível alterar o funcionário 1.")
      return
    }

    setJEmployee(event)    
    setCode(event.ID)
  }

  const setSelectedAccess = (event) => {
    setAccess(event)
    setShowAccess(false)
  }

  const selectCountry = (event) => {
    let itemP = {}
    listCountry.OBJECT.map(item => (
      (item.ID ===  parseInt(event)) ? itemP = item : ""
    ))

    setCountryCode(itemP.ID)
    setCountryDescription(itemP.NAME)
    setCountryIBGE(itemP.IBGE)
  }

  const uploadPhoto = async(e) => {
    let returnJson = []

    setLoading("Aguarde efetuando o upload da foto do usuário...")
    setWarning("")
    setError("")

    let jsonEmployee = {}
    jsonEmployee = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"ID\": "+ code +", \"CPF\": \""+ cpf +"\" }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", "{}")
    formData.append("JSON_NEW_1", jsonEmployee)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("FILE", e);
       
    axios.post(BASE_URL + "EMPLOYEE/UploadPhoto", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar o funcionário. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("employeeDescription") && document.getElementById("employeeDescription").focus()
        return
      }

      returnJson.WARNING ? setWarning("Foto inserida com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Foto inserida com sucesso!")

      jEmployee.PATH_PHOTO = String(returnJson.OBJECT)

      setLoading("")
      setUpdateFocu(updateFocu + 1)
    })
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Funcionário"
            titleSearch="Pesquisa dos Funcionários"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchEmployee
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showRHPosition && !showRHShift &&
                        <>
                          <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>
            
                            { (code === "") &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="employeeRegistration"
                                    description="Matrícula"
                                    planceHolder=""
                                    value={registration}
                                    maxLength={11}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setRegistration(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

                                  <Select
                                    name="employeeSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            { (code !== "") &&
                              <div style={{display: "flex", justifyContent: "center"}}>
                                <Input 
                                  type="file"
                                  name="employeePhoto"
                                  title=""
                                  maxLength={255}
                                  typeImage={1}
                                  disabled={false}
                                  placeholder=""
                                  grow={1}
                                  accept=".jpg,.jpeg,.png,.bmp"
                                  pathPhoto={photo}
                                  onChange={handleFile} />
                              </div>
                            }

                            { (code !== "") &&
                              <div style={(showMenu) ?
                                  (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="employeeCode"
                                      description="Código"
                                      planceHolder=""
                                      value={code}
                                      maxLength={11}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="employeeRegistration"
                                      description="Matrícula"
                                      planceHolder=""
                                      value={registration}
                                      maxLength={11}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setRegistration(event.target.value)} />
                                  </div>
                                </div>
                                
                                <div style={(showMenu) ?
                                    (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
                                  :
                                    (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>
                                  <Select
                                    name="employeeSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            <div style={(showMenu) ?
                                (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 450) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1050) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 450) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(showMenu) ?
                                    (width < 1050) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  : 
                                    (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <InputMasked
                                    name="employeeCpf"
                                    description="CPF"
                                    planceHolder=""
                                    mask="99999999999"
                                    value={cpf}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCpf(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1050) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                  :                                  
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="employeeRg"
                                    description="RG"
                                    planceHolder=""
                                    value={rg}
                                    maxLength={20}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setRg(event.target.value)} />
                                </div>
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                  : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                : 
                                  (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                  : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                <Input
                                  type="text"
                                  name="employeeName"
                                  description="Nome"
                                  planceHolder=""
                                  value={name}
                                  maxLength={50}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setName(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <InputMasked
                                  name="employeeBarcode"
                                  description="Código de barras"
                                  planceHolder=""
                                  mask="99999999999999"
                                  value={barcode}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setBarcode(event.target.value)} />
                              </div>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 610) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={(showMenu) ?
                                    (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                  :
                                    (width < 610) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="employeePassword"
                                    description="Senha"
                                    planceHolder=""
                                    value={password}
                                    maxLength={6}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setPassword(event.target.value)} />
                                </div>

                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    name="employeeBirthday"
                                    description="Data nascimento"
                                    planceHolder=""
                                    mask="99/99"
                                    value={birthday}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setBirthday(event.target.value)} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 230, maxWidth: 230}
                                :
                                  (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 230, maxWidth: 230}}>
                                <Select
                                  name="employeePermission"
                                  description="Permissão"
                                  value={permission || 0}
                                  disabled={false}
                                  require={false}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Caixa\" }, {\"ID\": 2, \"VALUE\": \"Operador\" }"+
                                    ", {\"ID\": 3, \"VALUE\": \"Atendente\" }, {\"ID\": 4, \"VALUE\": \"Operador Supervisor\" }] }")}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setPermission} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 890) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :
                                  (width < 680) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="employeeEmail"
                                  description="Email"
                                  planceHolder=""
                                  value={email}
                                  maxLength={70}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setEmail(event.target.value)} />
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="employeeObservation"
                                  description="Observações"
                                  value={observation}
                                  maxLength={65535}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setObservation(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <Titulo description="Cargo" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="employeeRhPositionDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={rhPositionDescription}
                                      maxLength={100}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setRhPositionDescription(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                      marginTop: 10}
                                    : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="employeeSearchRhPosition"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableRHPosition} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="employeeCleanRhPosition"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanRHPosition} />
                                  </div>
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>

                                <Titulo description="Turno" />
                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="employeeRhShiftDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={rhShiftDescription}
                                      maxLength={80}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setRhShiftDescription(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>

                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="employeeSearchRhShift"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableRHShift} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="employeeCleanRhShift"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanRHShift} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                              <Titulo description="Endereço" />
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                : {paddingLeft: 10, paddingRight: 10}
                              : 
                                (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                : {paddingLeft: 10, paddingRight: 10}}>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(showMenu) ?
                                    (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : 
                                    (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={{display: "flex", flexGrow: 1}}>
                                    <Select
                                      name="employeeCountryCode"
                                      description="País"
                                      value={countryCode || 0}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse(optionsCountries)}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={selectCountry} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                      : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                    :   
                                      (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                    <Input
                                      type="text"
                                      name="employeeCep"
                                      description="CEP"
                                      value={cep}
                                      maxLength={8}
                                      disabled={false}
                                      require={national ? true : false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCep(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                                  { (national && (!addressRelease)) &&
                                    <img
                                      style={{height: 42, width: 42, marginLeft: 10}}
                                      name="employeeSearchAddress"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={() => searchCEP()} />
                                  }

                                  <img
                                    style={{height: 42, width: 42, marginLeft: 10}}
                                    name="employeeClearAddress"
                                    src={image_clean} 
                                    alt="Limpar" 
                                    onClick={clearAddress} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                : 
                                  (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="employeeAddress"
                                    description="Logradouro"
                                    value={address}
                                    maxLength={60}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setAddress(event.target.value)} />
                                </div>

                                <div style={(showMenu) ?
                                    (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                    <Input
                                      type="text"
                                      name="employeeNumber"
                                      description="Número"
                                      value={number}
                                      maxLength={60}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setNumber(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="employeeComplement"
                                      description="Complemento"
                                      value={complement}
                                      maxLength={60}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setComplement(event.target.value)} />
                                  </div>
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="employeeNeighborhood"
                                    description="Bairro"
                                    value={neighborhood}
                                    maxLength={60}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setNeighborhood(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                  <Input
                                    type="text"
                                    name="employeeCity"
                                    description="Cidade"
                                    value={city}
                                    maxLength={60}
                                    disabled={national ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setCity(event.target.value)} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="employeeUf"
                                    description="Estado"
                                    value={uf}
                                    maxLength={2}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setUf(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                      
                                  <Input
                                    type="text"
                                    name="employeeCountryDescription"
                                    description="País"
                                    value={countryDescription}
                                    maxLength={60}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setCountryDescription(event.target.value)} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10, width: "100%"}}>
                              <Titulo description="Outras informações" />
                            </div>

                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <div style={(showMenu) ?
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="employeeCtps"
                                      description="CTPS"
                                      planceHolder=""
                                      value={ctps}
                                      maxLength={10}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCtps(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="employeeCtpsSeries"
                                      description="CTPS série"
                                      planceHolder=""
                                      value={ctpsSeries}
                                      maxLength={5}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCtpsSeries(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                  <InputNumeric
                                    type="text"
                                    name="employeeSalary"
                                    description="Salário"
                                    value={salary || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleSalary} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10, width: "100%"}}>
                              <Titulo description="Dias do trabalho" />
                            </div>

                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Segunda</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { monday === 1 ?
                                            <input
                                              type="checkbox"
                                              name="employeeMonday"
                                              checked={true}
                                              onChange={handleMonday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeMonday"
                                              checked={false}
                                              onChange={handleMondayC} />
                                          }
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Terça</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { tuesday === 1 ?
                                            <input
                                              type="checkbox"
                                              name="employeeTuesday"
                                              checked={true}
                                              onChange={handleTuesday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeTuesday"
                                              checked={false}
                                              onChange={handleTuesdayC} />
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Quarta</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { wednesday === 1 ? 
                                            <input
                                              type="checkbox"
                                              name="employeeWednesday"
                                              checked={true}
                                              onChange={handleWednesday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeWednesday"
                                              checked={false}
                                              onChange={handleWednesdayC} />
                                          }
                                        </div>
                                      </div>
                                      
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Quinta</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { thursday === 1 ? 
                                            <input
                                              type="checkbox"
                                              name="employeeThursday"
                                              checked={true}
                                              onChange={handleThursday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeThursday"
                                              checked={false}
                                              onChange={handleThursdayC} />
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Sexta</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { friday === 1 ? 
                                            <input
                                              type="checkbox"
                                              name="employeeFriday"
                                              checked={true}
                                              onChange={handleFriday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeFriday"
                                              checked={false}
                                              onChange={handleFridayC} />
                                          }
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                          <label style={{fontWeight: 600}}>Sábado</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { saturday === 1 ? 
                                            <input
                                              type="checkbox"
                                              name="employeeSaturday_"
                                              checked={true}
                                              onChange={handleSaturday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeSaturday"
                                              checked={false}
                                              onChange={handleSaturdayC} />
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
                                          <label style={{fontWeight: 600}}>Domingo</label>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}}>
                                          { sunday === 1 ? 
                                            <input
                                              type="checkbox"
                                              name="employeeSunday"
                                              checked={true}
                                              onChange={handleSunday} />
                                            :
                                            <input
                                              type="checkbox"
                                              name="employeeSunday"
                                              checked={false}
                                              onChange={handleSundayC} />
                                          }
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}} />
                                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5}} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}}>
                                  <InputMasked
                                    name="employeeEmissionDate"
                                    planceHolder=""
                                    mask="99/99/9999"
                                    description="Data da admissão"
                                    value={emissionDate}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setEmissionDate(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    name="employeeLayoffDate"
                                    description="Data da demissão"
                                    planceHolder=""
                                    mask="99/99/9999"
                                    value={layoffDate}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setLayoffDate(event.target.value)} />
                                </div>
                              </div>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Select
                                    name="employeeOrderDiscount"
                                    description="Desconto no Pedido"
                                    value={orderDiscount || 0}
                                    disabled={false}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setOrderDiscount} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                    minWidth: 180}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="employeePorcentageDiscount"
                                    description="(%) do desconto"
                                    value={porcentageDiscount || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handlePorcentageDiscount} />
                                </div>
                              </div>

                              {/* Múltipla / Detetização / Licitação */}
                              { (((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) &&
                                <div style={(showMenu) ?
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="employeeExecutesOS"
                                        description="Executa OS"
                                        value={executesOS || 0}
                                        disabled={false}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setExecutesOS} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Select
                                        name="employeeTransfersOS"
                                        description="Transfere OS"
                                        value={transfersOS || 0}
                                        disabled={false}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setTransfersOS} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : 
                                      (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Select
                                      name="employeeFinalizesOS"
                                      description="Finaliza OS"
                                      value={finalizesOS || 0}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setFinalizesOS} />
                                  </div>
                                </div>
                              }
                            </div>

                            <div style={{marginTop: 10, width: "100%"}}>
                              <Titulo description="Acessos" />
                            </div>

                            { !showAccess &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                                    <img 
                                      style={{height: 38, width: 38}}
                                      name="employeeAddAcess"
                                      src={image_add}
                                      alt="Adicionar" 
                                      onClick={(() => { setShowAccess(!showAccess) })} />
                                  </div>
                                </div>
                              </div>
                            }

                            { showAccess &&
                              <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
                                <AccessControl
                                  jsonCompany={jsonCompany}
                                  access={access}
                                  setSelected={setSelectedAccess} 
                                  showMenu={showMenu} 
                                  width={width} />
                              </div>
                            }

                            {/* Phones */}
                            { code &&
                              <div>
                                <div style={{marginTop: 10}}>
                                  <Titulo description="Telefones" />
                                </div>

                                <SearchEmployeePhone 
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonEmployee={JSON.parse("{ \"EMPLOYEE\": { \"ID\": "+ code +", \"COMPANY\": { \"ID\": " + jsonCompany.ID + " } } }")}
                                  jsonCompany={jsonCompany}
                                  jsonLogin={jsonLogin}
                                  system={system} 
                                  width={width} />
                              </div>
                            }
                          </div>
                        </>
                      }
                
                      { showRHPosition && 
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o cargo:</b>
                          </div>

                          <SearchRhPosition
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillRHPosition} 
                            setReturn={() => setShowRHPosition(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showRHShift &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o turno:</b>
                          </div>

                          <SearchRhShift
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillRHShift} 
                            setReturn={() => setShowRHShift(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Employee