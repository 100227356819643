import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import image_07824993000165 from '../../Images/logos/07824993000165.png';
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_printer from "../../Images/Buttons/printer.png"
import image_search from "../../Images/Buttons/search.png"
import image_select_circle from "../../Images/Buttons/select_circle.png"

const ReportAccountBalance = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [accountDescription, setAccountDescription] = useState("")
  const [accountDescriptionSelected, setAccountDescriptionSelected] = useState("")
  const [accountResult, setAccountResult] = useState({})
  const [accountResultFilter, setAccountResultFilter] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showAccount, setShowAccount] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)

  useEffect(() => {
    if (firstAcess){
      let returnJson = []

      ////
      // Load account information // Carrega as informações das contas
      ////
      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações das contas...")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 1000)

      axios.post(BASE_URL + "ACCOUNT/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("") 
          return
        }
      
        let arrAccount = []
        returnJson.OBJECT.map(item => (
          arrAccount.push(item)
        ))
        setAccountResult(arrAccount)

        let arrAccountFilter = []
        returnJson.OBJECT.map(item => (
          arrAccountFilter.push(item)
        ))
        setAccountResultFilter(arrAccountFilter)

        setLoading("")
      })

      setFirstAcess(false)
    }

    if (showAccount){
      let arrAccount = []
  
      if (accountDescription === ""){
        accountResult.map(item => (
          arrAccount.push(item)
        ))
      }
      else{
        accountResult.map(item => (
        (item.OWNER.indexOf(accountDescription.toUpperCase()) !== -1) &&
        arrAccount.push(item)
        ))
      }  
      setAccountResultFilter(arrAccount)
    }
  }, [accountDescription])

  const addAccount = () => {
    cleanAccount()
    setShowAccount(true)
  }

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setShowAccount(false)

    setAccountResultFilter(accountResultFilter)

    setAccountDescription("")
    setAccountDescriptionSelected("")
  }

  const cleanAccount = () => {
    accountResult.map(item => (
      item.MARKET = false
    ))

    setAccountResultFilter(accountResult)

    setAccountDescriptionSelected("")
    setAccountDescription("")
    setShowAccount(false)
  }

  const formatValue = (value, decimals) => {
    if (!value) {
      return "0.00"
    }

    if (value === ".") {
      return value = "0."
    }
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }

  const getDate = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    return today.toLocaleString();
  }

  const marketAllAccount = () => {
    let selected = ""

    accountResult.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.OWNER)
      : selected += ("," + item.ID + "-" + item.OWNER)
    ))
    
    setAccountDescriptionSelected(selected)
    setShowAccount(false)
  }

  const print = () => {
    window.print()
  }  

  const returnSearch = () => {
    setShowAccount(false)
    setShowResult(false)

    setAccountResultFilter(accountResult)

    setAccountDescriptionSelected("")
    setAccountDescription("")
   
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")
  }

  const search = () => {
    let returnJson = []

    setLoading("")
    setError("")
    setLoading("Aguarde buscando as informações...") 

    var jAccount = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
    if (accountDescriptionSelected !== "") jAccount += ", \"ACCOUNTS\": \""+ accountDescriptionSelected +"\""           
    jAccount += " }"
      
    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jAccount)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT/Report", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os saldos das contas. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")
        return
      }

      if (returnJson.WARNING === "Nenhum registro encontrado.") {
        setWarning("Nenhum registro encontrado com o filtro informado.")
        return
      }

      setResult(returnJson)
      setShowResult(true)
      setLoading("")
    })
  }

  const selectAccount = () => {
    setShowAccount(false)
  }
  
  const setFieldSelected = () => {
  }

  const updateShowAccount = (id) => {
    accountResult.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    accountResult.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.OWNER
        : selected = selected + "," + item.ID + "-" + item.OWNER
      : ""
    ))
    setAccountDescriptionSelected(selected)
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, borderCollapse: "collapse", minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu && 
          <div style={{display: "flex", flexGrow: 0, flexDirection: "row"}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} width={width} logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", paddingTop: 15, paddingBottom: 15}}>
            { showMenu ?
                <div style={(width < 520) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", paddingLeft: 10, paddingRight: 10}
                  :
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", paddingLeft: 10,
                    paddingRight: 15}}>

                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5,
                    paddingRight: 5, marginTop: 5}}>
                      <label style={                  
                        (width < 520) ? {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>Saldo da Conta Bancária</label>
                  </div>

                  { (width < 520) ?
                      <div style={{marginTop: 10}}>
                        <img src={image_multiplaWhite} 
                          style={{ height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={{ height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
              :
                <div style={(width < 560) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                  : 
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center",
                    paddingLeft: 10, paddingRight: 15}}>

                  <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "flex-start", alignItems: "center",
                      paddingLeft: 10, paddingRight: 10}}>
                        <img src={image_menu}
                        style={(width < 360) ? { height: "auto", width: 30 } : { height: "auto", width: 35 }}
                        alt="Retrair"
                        onClick={(() => { setShowMenu(!showMenu) })} />
                    </div>

                    <div style={(width < 420) ? 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5,
                          marginTop: 15}
                      : 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5,
                          marginTop: 5}}>
                      <label style={
                        (width < 420) ? {color: "white", fontSize: 16, fontWeight: 600, textAlign: "center"} 
                        : (width < 760) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>Saldo da Conta Bancária</label>
                    </div>
                  </div>

                  { (width < 560) ?
                      <div style={{display: "flex", flex: 1, marginTop: 10, justifyContent: "center"}}>
                        <img src={image_multiplaWhite} 
                          style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
            }
          </div>

          { !showResult &&
            <>
              { loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems: "center",
                  minHeight: 495}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              }

              { !loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => search()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => clean()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => setReturn()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning && 
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div> 
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }
                    
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0, marginBottom: 10,
                      marginLeft: 0}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                    </div>

                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                        <Input
                          type="search"
                          name="reportAccountBalanceAccountDescriptionSelected"
                          description="Contas Selecionados"
                          value={accountDescriptionSelected}
                          maxLength={6000}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                      </div>
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", alignItems: "flex-end", paddingLeft: 5, paddingRight: 5}}>
                        <div style={{display: "flex", flexDirection: "row"}}
                          onClick={(() => { addAccount() })}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingBottom: 5, paddingLeft: 5}}>
                            <img style={{height: 38, width: 38}} src={image_add} alt="Adicionar"/>
                          </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginLeft: 5}}
                           onClick={(() => { cleanAccount() })}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingBottom: 5, paddingLeft: 5}}>
                            <img style={{height: 38, width: 38}} src={image_clean} alt="Limpar"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    { showAccount &&
                      <div style={{marginBottom: 10}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingRight: 0, paddingBottom: 0,
                          paddingLeft: 10}}>
                          <div style={{width: "100%"}}>
                            <Titulo description="Conta" />
                          </div>
                        </div>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                              marginTop: 10, marginLeft: 5, width: 185}} onClick={() => selectAccount()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select_circle} alt="Selecionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                              marginTop: 10, marginLeft: 5, width: 185}} onClick={() => marketAllAccount()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select_circle} alt="Marcar Todos"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Marcar Todos</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                              marginTop: 10, marginLeft: 5, width: 185}} onClick={() => cleanAccount()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Limpar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
              
                          <div style={{paddingRight: 10, paddingLeft: 10}}>
                            <div style={{display: "flex", flexGrow: 1, marginTop: 10}}>
                              <Input
                                type="search"
                                name="reportAccountBalanceAccountDescription"
                                description="Descrição"
                                value={accountDescription}
                                maxLength={50}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setAccountDescription(event.target.value)} />
                            </div>

                            { accountResultFilter && (accountResultFilter.length > 0) &&
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", borderBottomWidth: 1, borderBottomStyle: "solid",
                                borderBottomColor: "black", marginTop: 10, marginBottom: 10}}>
                                <div style={showMenu ?
                                      (width < 670) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    :
                                      (width < 670) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={{display: "flex", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 10, minWidth: 50, 
                                        maxWidth: 50}} />
                                      <div style={{display: "flex", flexGrow: 0, minWidth: 70, maxWidth: 70}}>
                                        <label style={{fontWeight: 600}}>Código</label>
                                      </div>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120, paddingLeft: 10}}>
                                      <label style={{fontWeight: 600}}>Número</label>
                                    </div>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                    <label style={{fontWeight: 600}}>Descrição</label>
                                  </div>
                                </div>
                              </div>
                            }

                            { accountResultFilter && accountResultFilter.map(item => (
                              <div key={item.ID + "_" + item.NUMERO + "_" + item.TITULAR} style={{display: "flex", flexGrow: 0,
                                flexDirection: "row", marginTop: 5, backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid",
                                borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}}>
                                <div style={showMenu ?
                                      (width < 670) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    :
                                      (width < 670) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                    <div style={{display: "flex", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 10, minWidth: 45,
                                        maxWidth: 45}}>
                                        <input type="checkbox" checked={item.MARCADO} onChange={ () => updateShowAccount(item.ID) } />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, minWidth: 70, maxWidth: 70, paddingLeft: 10}}>
                                        <label>{item.ID}</label>
                                      </div>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120, paddingLeft: 10}}>
                                      <label>{item.NUMBER}</label>
                                    </div>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                    <label>{item.OWNER}</label>
                                  </div>    
                                </div>
                              </div>
                            ))
                            }

                            { accountResultFilter && (accountResultFilter.length === 0) &&
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
            </>
          }

          { showResult &&
            <>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => print()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => returnSearch()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>

              <div id="print">
                <div style={showMenu ?
                    (width < 980) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                  :
                    (width < 720) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}}>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}
                    :
                      (width < 720) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}}>

                      { jsonCompany.CPF_CNPJ === "07824993000165" && 
                      <img src={image_07824993000165} alt="AVATAR" /> }
                  </div>

                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                    : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>Saldo da Conta Bancária</label>
                    </div>

                    <div style={showMenu ?
                        (width < 980) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}
                      :
                        (width < 720) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>{getDate()}</label>
                    </div>
                  </div>
                </div>

                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginLeft: 20, marginRight: 20}}>
                  { result.OBJECT.LstACCOUNT_ITEM.map(item => (
                    item.TYPE === 1 ?
                      <div style={showMenu ?
                          (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "rgb(0, 178, 177)",
                            borderBottomColor: "black", borderBottomStyle: "solid", borderBottomWidth: 1, webkitPrintColorAdjust: "exact"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "rgb(0, 178, 177)",
                            borderBottomColor: "black", borderBottomStyle: "solid", borderBottomWidth: 1, webkitPrintColorAdjust: "exact"}
                        :
                          (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "rgb(0, 178, 177)",
                            borderBottomColor: "black", borderBottomStyle: "solid", borderBottomWidth: 1, webkitPrintColorAdjust: "exact"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "rgb(0, 178, 177)",
                            borderBottomColor: "black", borderBottomStyle: "solid", borderBottomWidth: 1, webkitPrintColorAdjust: "exact"}}>

                        <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                              borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                              borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, alignItems: "center", borderLeftWidth: 1, borderLeftStyle: "solid",
                              borderLeftColor: "black", paddingLeft: 10, minWidth: 70, maxWidth: 70}}>
                            <label style={{fontWeight: 600, color: "white"}}>Cód.</label>
                          </div>
                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", borderRightColor: "black", 
                                  borderRightStyle: "solid", borderRightWidth: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 120, maxWidth: 120}
                            :
                              (width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 680) ? {display: "flex", flexGrow: 1, alignItems: "center", borderRightColor: "black", 
                                  borderRightStyle: "solid", borderRightWidth: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 120, maxWidth: 120}}>
                            <label style={{fontWeight: 600, color: "white"}}>Número</label>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                            : (width < 780) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}
                          :
                            (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                          <div style={showMenu ?
                              (width < 490) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, alignItems: "center", minWidth: 180}
                            :
                              (width < 540) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 680) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, alignItems: "center", minWidth: 180}}>

                            <label style={{fontWeight: 600, color: "white"}}>Titular</label>
                          </div>
                          <div style={showMenu ?
                              (width < 490) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                              : (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                  borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                            :
                              (width < 540) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}}>
                            <label style={{fontWeight: 600, color: "white"}}>Saldo</label>
                          </div>
                        </div>
                      </div>
                    : item.TYPE === 2 ?
                      <div style={
                        (item.SEQUENTIAL%2 === 0) ?
                          showMenu ?
                            (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                          :
                            (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                        :
                          showMenu ?
                            (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}
                          :
                            (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}}>

                        <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                              borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                              borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, alignItems: "center", borderLeftWidth: 1, borderLeftStyle: "solid",
                              borderLeftColor: "black", paddingLeft: 10, minWidth: 70, maxWidth: 70}}>
                            <label>{item.ACCOUNT.ID}</label>
                          </div>
                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", borderRightColor: "black", 
                                  borderRightStyle: "solid", borderRightWidth: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 120, maxWidth: 120}
                            :
                              (width < 450) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 680) ? {display: "flex", flexGrow: 1, alignItems: "center", borderRightColor: "black", 
                                  borderRightStyle: "solid", borderRightWidth: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 120, maxWidth: 120}}>
                            <label>{item.ACCOUNT.NUMBER}</label>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                            : (width < 780) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}
                          :
                            (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                          <div style={showMenu ?
                              (width < 490) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, alignItems: "center", minWidth: 180}
                            :                            
                              (width < 540) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightColor: "black", borderRightStyle: "solid",
                                borderRightWidth: 1, paddingLeft: 10, minWidth: 180}
                              : (width < 680) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, alignItems: "center", minWidth: 180}}>

                            <label>{item.ACCOUNT.OWNER}</label>
                          </div>
                          <div style={showMenu ?
                              (width < 490) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                              : (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                  borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                            :
                              (width < 540) ? {display: "flex", flexGrow: 1, alignItems: "center", borderLeftColor: "black",
                                borderLeftStyle: "solid", borderLeftWidth: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}}>
                            <label>{formatValue(item.ACCOUNT.BALANCE, 2)}</label>
                          </div>
                        </div>
                      </div>
                    :
                      <>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", justifyContent: "center",  borderWidth: 1,
                          borderStyle: "solid", borderColor: "black", webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)",
                          color: "white", paddingTop: 5, paddingBottom: 5}}>
                          <label style={{fontWeight: 600}} >{"Totalização"}</label>
                        </div>

                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row",
                          borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black",
                          borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                          borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                          webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)", color: "white"}}>

                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row"}}>
                            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10}}>Total do Saldo: {formatValue(item.BALANCE_TOTAL, 2)}</label>
                            </div>
                          </div>
                        </div>
                      </>
                  ))}
                </div>

                <div style={{display: "flex", flexGrow: 0, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                  borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", marginLeft: 20, marginRight: 20, height: 20}} />

                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", borderRightWidth: 1, borderRightStyle: "solid",
                  borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderLeftWidth: 1,
                  borderLeftStyle: "solid", borderLeftColor: "black", webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)",
                  color: "white", fontStretch: 600, marginLeft: 20, marginRight: 20}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>DESENVOLVEDORA DE SISTEMAS - SGE - MULTIPLA SISTEMAS - (031) 2511-0909 - www.multiplasistemas.com.br</label>
                </div>
              </div>
            </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ReportAccountBalance