import React, { useState, useEffect } from "react"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const NfSerie = ({jsonNfSeries, save, previous, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [modelCode, setModelCode] = useState("")
  const [modelDescription, setModelDescription] = useState("")
  const [series, setSeries] = useState("")
  const [subSeries, setSubSeries] = useState("")
  const [type, setType] = useState(0)
    
  useEffect(() => {       
    setCode(jsonNfSeries.ID)
    setModelCode(jsonNfSeries.NF_MODEL.CODE)
    setModelDescription(jsonNfSeries.NF_MODEL.DESCRIPTION)
    setSeries(jsonNfSeries.SERIES)
    if (jsonNfSeries.SUBSERIES != null) setSubSeries(jsonNfSeries.SUBSERIES); else setSubSeries("")
    setType(jsonNfSeries.TYPE)

  }, [jsonNfSeries])

  const onSave = () => {
    let json = "{"

    if (code !== undefined){
      json += " \"ID\": "+ code
      json += ", \"NF_MODEL\": { \"CODE\": \"" + modelCode + "\", \"DESCRIPTION\": \""+ modelDescription +"\" }"
    } else json += " \"NF_MODEL\": { \"CODE\": \"" + modelCode + "\", \"DESCRIPTION\": \""+  modelDescription +"\" }"

    if ((series !== undefined) && (series !== "")) json += ", \"SERIES\": \"" + series + "\""
    if ((subSeries !== undefined) && (subSeries !== "")) json += ", \"SUBSERIES\": \"" + subSeries + "\""
    if ((type !== undefined) && (type !== "")) json += ", \"TYPE\": "+ type

    json += " }"
    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
          marginTop: 10, marginLeft: 5, width: 147}} onClick={() => onSave()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
          marginTop: 10, marginLeft: 5, width: 147}} onClick={() => previous()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_previous} alt="Voltar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Voltar</label>
          </div>
        </div>        
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 480) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10} : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
        { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

        <div style={showMenu ?
            (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0}
            : {display: "flex", flexDirection: "row", flexGrow: 0}
          :          
            (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0}
            : {display: "flex", flexDirection: "row", flexGrow: 0}}>
          <div style={showMenu ?
              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
              : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0}
              : {display: "flex", flexDirection: "row", flexGrow: 0}
            : 
              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0}
              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

            <div style={showMenu ?
                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1, minWidth: 180}
                : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
              :
                (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
              <InputNumeric
                type="text"
                name="nfSeriesSeries"
                description="Série"
                value={series || ""}
                maxLength={3}
                disabled={code === undefined ? false : true}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setSeries(event.target.value)} />
            </div>
            <div style={showMenu ?
                (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
              :
                (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
              <InputNumeric
                type="text"
                name="nfSeriesSubSeries"
                description="Sub-Série"
                value={subSeries || ""}
                maxLength={2}
                disabled={code === undefined ? false : true}
                require={false}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setSubSeries(event.target.value)} />
            </div>
          </div>
          <div style={showMenu ?
              (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
            : 
              (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
            <Select
              name="nfSeriesType"
              description="Tipo"
              value={type || 0}
              require={true}
              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Entrada\" }"+
                ", {\"ID\": 2, \"VALUE\": \"Saída\" }, {\"ID\": 3, \"VALUE\": \"Serviço\" }] }")}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              set={setType} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NfSerie