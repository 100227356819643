import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Footer from "../../Components/Footer"
import LogoAriEletrica from "../../Images/logos/17180191000113.png"
import LogoEmige from "../../Images/logos/71505564000124.png"
import LogoMultipla from "../../Images/logos/07824993000165.png"

const Login = ({ logon, height, width }) => {
  const [companyCnpj, setCompanyCnpj] = useState("")
  const [companySelected, setCompanySelected] = useState(false)
  const [databasePath, setDatabasePath] = useState("")
  const [databaseType, setDatabaseType] = useState(1)  
  const [employeeCode,  setEmployeeCode] = useState("0")
  const [employeeCodeMultipla, setEmployeeCodeMultipla] = useState("0")
  const [password, setPassword] = useState("")
  const [system, setSystem] = useState(false)

  const [production, setProduction] = useState(1)
  const [showProduction, setShowProduction] = useState(false)

  const [jsonCompany, setJsonCompany] = useState("")
  const [jsonEmployee, setJsonEmployee] = useState("")
  const [jsonEmployeeMultipla, setJsonEmployeeMultipla] = useState("")
  const [jsonEmployees, setJsonEmployees] = useState("")
  const [jsonEmployeesMultipla, setJsonEmployeesMultipla] = useState("")

  const [optionsEmployee, setOptionsEmployee] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsEmployeesMultipla, setOptionsEmployeesMultipla] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [firstAccess, setFirstAccess] = useState(true)
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [fieldSelected, setFieldSelected] = useState("")
  const [updateFocu, setUpdateFocu] = useState(0)  

  useEffect(() => {
    if (firstAccess){
      let resultJson = []

      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", "2")
      formData.append("DATABASE_PATH", "User Id=postgres;Server=clmultipla-id43.cloudtsci.tec.br;Port=5432;Database=dbCentral;")
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": 1 }, \"SITUATION\": 1 }")
      formData.append("JSON_LOGIN", "{ \"CPF\": \"79772468050\", \"PASSWORD\": \"M!LTPL\", \"COMPANY\": { \"ID\": 1 } }")
      formData.append("SYSTEM", true)
      formData.append("QUANTITY_INITIAL", "0")
      formData.append("QUANTITY_RECORDS", "99")

      axios.post(BASE_URL + "EMPLOYEE/Search", formData)
      .then((response) => { resultJson = JSON.parse(response.data) })
      .catch((response) => { resultJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null,"+
        "\"ERROR\": \"Não foi possível obter os dados o funcionário. ("+ response +")\"}") })        
      .finally(() => {
        if (resultJson.SUCCESS === false){
          setError(resultJson.ERROR)
          setLoading("")
          return
        }

        if (resultJson.WARNING !== null) setWarning(resultJson.WARNING)
        else setWarning("")

        resultJson.OBJECT && resultJson.OBJECT && setJsonEmployeesMultipla(resultJson.OBJECT)

        let employee = "{ \"RESULT\": [ { \"ID\": 0, \"VALUE\": \"Selecione...\" }"
        resultJson && resultJson.OBJECT &&
          resultJson.OBJECT.map(item => (
            employee += ", { \"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\" }"
        ))
        employee += "]}"
                  
        setOptionsEmployeesMultipla(employee)
        setLoading("")
      })

      document.getElementById("companyCnpj").focus()
      setFirstAccess(false)      
    }

    if (fieldSelected === "companyCnpj") document.getElementById("companyCnpj").focus()
    else if (fieldSelected === "employeeCode") document.getElementById("employeeCode").focus()
    else if (fieldSelected === "employeeCodeMultipla") document.getElementById("employeeCodeMultipla").focus()
    else if (fieldSelected === "companyPassword") document.getElementById("companyPassword").focus()

    if (companyCnpj === "07824993000165") setShowProduction(true)
    else setShowProduction(false)

  }, [firstAccess, companyCnpj, fieldSelected, updateFocu])

  const formatEmployee = (item) => {
    let sEmployee = "{ "
    sEmployee += "\"ID\": "+ item.ID + ", "
    sEmployee += "\"COMPANY\": { \"ID\": "+ item.COMPANY.ID + " }, "
    if ((item.REGISTRATION !== null) && (item.REGISTRATION !== "")) sEmployee += "\"REGISTRATION\": " + item.REGISTRATION + ", "
    sEmployee += "\"CPF\": \"" + item.CPF + "\", "
    if ((item.RG !== null) && (item.RG !== "")) sEmployee += "\"RG\": \"" + item.RG + "\", "
    sEmployee += "\"NAME\": \"" + item.NAME + "\", "
    if ((item.DATE_BIRTH !== null) && (item.DATE_BIRTH !== "")) sEmployee += "\"DATE_BIRTH\": \"" + item.DATE_BIRTH + "\", "
    sEmployee += "\"PASSWORD\": \"" + item.PASSWORD + "\", "
    sEmployee += "\"BAR_CODE\": \"" + item.BAR_CODE + "\", "
    sEmployee += "\"PERMISSION\": " + item.PERMISSION + ", "
    if ((item.EMAIL !== null) && (item.EMAIL !== "")) sEmployee += "\"EMAIL\": \"" + item.EMAIL + "\", "
    if ((item.CTPS !== null) && (item.CTPS !== "")) sEmployee += "\"CTPS\": \"" + item.CTPS + "\", "
    if ((item.CTPS_SERIES !== null) && (item.CTPS_SERIES !== "")) sEmployee += "\"CTPS_SERIES\": \"" + item.CTPS_SERIES + "\", "                
    sEmployee += "\"SALARY\": " + item.SALARY + ", "
    sEmployee += "\"WORK_DAYS\": \"" + item.WORK_DAYS + "\", "
    sEmployee += "\"DATE_ADMISSION\": \"" + item.DATE_ADMISSION + "\", "
    if ((item.DATE_LAYOFF !== null) && (item.DATE_LAYOFF !== "0001-01-01T00:00:00")) sEmployee += "\"DATE_LAYOFF\": \"" + item.DATE_LAYOFF + "\", "

    if (item.RH_POSITION !== null) {
      sEmployee += "\"RH_POSITION\": { \"ID\": "+ item.RH_POSITION.ID

      if (item.RH_POSITION.RH_SECTION !== null)
        if (item.RH_POSITION.RH_SECTION.PERMISSION !== null)
          sEmployee += ", \"RH_SECTION\": { \"PERMISSION\": \""+ item.RH_POSITION.RH_SECTION.PERMISSION + "\" }"

      sEmployee += " },"
    }

    if (item.RH_SHIFT !== null) sEmployee += "\"RH_SHIFT\": { \"ID\": "+ item.RH_SHIFT.ID + " }, "
    sEmployee += "\"CEP\": \""+ item.CEP + "\", "
    sEmployee += "\"ADDRESS\": \""+ item.ADDRESS + "\", "
    sEmployee += "\"NUMBER\": \"" + item.NUMBER + "\", "
    if ((item.COMPLEMENT !== null) && (item.COMPLEMENT !== "")) sEmployee += "\"COMPLEMENT\": \"" + item.COMPLEMENT + "\", "
    sEmployee += "\"NEIGHBORHOOD\": \"" + item.NEIGHBORHOOD + "\", "
    sEmployee += "\"CITY\": \"" + item.CITY + "\", "
    sEmployee += "\"CITY_IBGE\": " + item.CITY_IBGE + ", "
    sEmployee += "\"UF\": \"" + item.UF + "\", "
    sEmployee += "\"UF_IBGE\": " + item.UF_IBGE + ", "
    sEmployee += "\"COUNTRY_ID\": \"" + item.COUNTRY_ID + "\", "
    sEmployee += "\"COUNTRY_DESCRIPTION\": \"" + item.COUNTRY_DESCRIPTION + "\", "
    sEmployee += "\"COUNTRY_IBGE\": " + item.COUNTRY_IBGE + ", "
    if ((item.PATH_PHOTO !== null) && (item.PATH_PHOTO !== "")) sEmployee += "\"PATH_PHOTO\": \"" + item.PATH_PHOTO + "\", "
    sEmployee += "\"ORDER_DISCOUNT\": " + item.ORDER_DISCOUNT + ", "
    sEmployee += "\"PERCENTAGE_DISCOUNT\": " + item.PERCENTAGE_DISCOUNT + ", "
    sEmployee += "\"EXECUTE_OS\": " + item.EXECUTE_OS + ", "
    sEmployee += "\"TRANSFER_OS\": " + item.TRANSFER_OS + ", "
    sEmployee += "\"FINALIZES_OS\": " + item.FINALIZES_OS + ", "
    if ((item.OBSERVATION !== null) && (item.OBSERVATION !== "")) sEmployee += "\"OBSERVATION\": \"" + item.OBSERVATION + "\", "
    if ((item.ACCESS !== null) && (item.ACCESS !== "")) sEmployee += "\"ACCESS\": \"" + item.ACCESS + "\", "
    sEmployee += "\"SITUATION\": " + item.SITUATION + " } "

    return JSON.parse(sEmployee)
  }

  const handleEmployee = (event) => {
    const code = event
    setEmployeeCode(code)

    jsonEmployees.map(item => (
      ((item.ID) === parseInt(code)) && setJsonEmployee(formatEmployee(item))
    ))
  }

  const handleEmployeeMultipla = (event) => {
    const code = event
    setEmployeeCodeMultipla(code)
    setSystem(true)

    jsonEmployeesMultipla.map(item => (
      ((item.ID) === parseInt(code)) && setJsonEmployeeMultipla(formatEmployee(item))
    ))
  }

  const login = () => {    
    if (String(employeeCode) === "0"){
      setError("É necessário selecionar o coolaborador!!!")
      setWarning("")
      setLoading("")
      document.getElementById("employeeCode").focus()
      return
    }

    if (String(employeeCode) === "1"){
      if (String(employeeCodeMultipla) === "0"){
        if (jsonCompany.CPF_CNPJ === "07824993000165"){
          setError("Não é possivel logar com o usuário sistema na Múltipla!!!")
          setWarning("")
          setLoading("")
          document.getElementById("employeeCode").focus()
          return
        }
        else{
          setError("É necessário selecionar o coolaborador da Múltipla!!!")
          setWarning("")
          setLoading("")
          document.getElementById("employeeCodeMultipla").focus()
          return
        }
      }
    }

    if (password === ""){
      setError("É necessário preender a senha!!!")
      setWarning("")
      setLoading("")
      document.getElementById("companyPassword").focus()
      return
    }

    if ((parseInt(employeeCode) === 1) && (jsonCompany.CPF_CNPJ === "07824993000165"))
    {
      if (String(jsonEmployeeMultipla.PASSWORD) !== password)
      {
        setError("Senha inválida.")
        setWarning("")
        setLoading("")
        document.getElementById("companyPassword").focus()
        return
      }
    }
    else
    {
      if (jsonEmployee.PASSWORD !== password)
      {
        setError("Senha inválida.")
        setWarning("")
        setLoading("")
        document.getElementById("companyPassword").focus()
        return
      }
    }

    if ((parseInt(employeeCode) === 1) && (jsonCompany.CPF_CNPJ !== "07824993000165")) 
      logon(databaseType, databasePath, system, jsonCompany, jsonEmployeeMultipla)
    else logon(databaseType, databasePath, system, jsonCompany, jsonEmployee)
  }

  const previous = () => {
    setError("")
    setWarning("")
    setLoading("")

    setCompanyCnpj("")
    setProduction(1)
    setShowProduction(true)

    setEmployeeCode(0)
    setEmployeeCodeMultipla(0)
    setSystem(false)
    setPassword("")

    setOptionsEmployee("{ \"RESULT\": [{ \"ID\": 0, \"VALUE\": \"Selecione...\" }")
    setCompanySelected(false)

    setFieldSelected("companyCnpj")
    setUpdateFocu(updateFocu + 1)
  }

  const selectCompany = () => {
    let resultJson = []
    let systemType = 0
    let typeTransfer = 0
    let valueTransfer = 0
  

    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações...")

    let formData = new FormData()
    formData.append("JSON_NEW_1", "{ \"CPF_CNPJ\": \""+ companyCnpj + "\" }")
    formData.append("JSON_LOGIN", "{ \"CPF\": \"79772468050\", \"PASSWORD\": \"M!LTPL\", \"COMPANY\": { \"ID\": 1 } }")
    
    axios.post(BASE_URL + "CLIENT/SearchCentral", formData)
    .then((response) => { resultJson = JSON.parse(response.data) })
    .catch((response) => { resultJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null,"+
      "\"ERROR\": \"Não foi possível obter os dados da filial no central. ("+ response +"\")}") })
    .finally(() => {
      if (resultJson.SUCCESS === false){
        setError(resultJson.ERROR)
        setWarning(resultJson.WARNING)
        setLoading("")
        document.getElementById("companyCnpj").focus()
        return
      }

      if (resultJson.WARNING !== null){
        if (resultJson.WARNING === "Nenhum registro encontrado.") {
          setWarning("A empresa informada não consta na base de dados da Múltipla Sistemas.")
          setError("")
          setLoading("")
          document.getElementById("companyCnpj").focus()
          return
        } 
        else setWarning(resultJson.WARNING)
      }

      if (production === 1) {
        systemType = resultJson.OBJECT.SYSTEM_TYPE
        setDatabaseType(resultJson.OBJECT.DATABASE_TYPE)
      } else {
        systemType = resultJson.OBJECT.SYSTEM_TYPE_HOMOLOGATION
        setDatabaseType(resultJson.OBJECT.DATABASE_TYPE_HOMOLOGATION)
      }

      ////
      // Get date from server
      ////
      let serverDate = new Date()
      const dayServer = String(serverDate.getDate()).padStart(2, "0")
      const monthServer = String(serverDate.getMonth() + 1).padStart(2, "0")
      const yearServer = serverDate.getFullYear()
      serverDate = new Date(monthServer + "/" + dayServer + "/" + yearServer)
      
      ////
      // Format the expiration date of the contract
      ////
      const expirationDate = new Date(
        String(resultJson.OBJECT.CONTRACT_EXPIRATION_DATE).substring(5, 7) + "/" +
          String(resultJson.OBJECT.CONTRACT_EXPIRATION_DATE).substring(8, 10) + "/" +
            String(resultJson.OBJECT.CONTRACT_EXPIRATION_DATE).substring(0, 4))
                  
      ////
      // Check the validity of the contract
      ////
      if (expirationDate.valueOf() < serverDate.valueOf()){
        setWarning("Período de uso expirado favor entrar em contato com a Múltipla Sistemas.")
        setError("")
        setLoading("")
        document.getElementById("companyCnpj").focus()
        return
      }
          
      ////
      // Check the cancellation date
      ////
      const cancellationDate = 
        (resultJson.OBJECT.CANCELLATION_DATE).substring(8, 10) + "/" +
          String(resultJson.OBJECT.CANCELLATION_DATE).substring(5, 7) + "/" +
            String(resultJson.OBJECT.CANCELLATION_DATE).substring(0, 4)
          
      if (cancellationDate !== "01/01/0001"){
        setWarning("Contrato cancelado entre em contato com a Múltipla Sistemas.")
        setError("")
        setLoading("")
        document.getElementById("companyCnpj").focus()
        return
      }

      ////
      // Capture database connection data
      ///
      let clientConnection = ""

      let databaseType = 0
      if (production === 1) databaseType = resultJson.OBJECT.DATABASE_TYPE
      else databaseType  = resultJson.OBJECT.DATABASE_TYPE_HOMOLOGATION
          
      if (databaseType === 1) {
        if (production === 1) {
          clientConnection = "Server=" + resultJson.OBJECT.DOMAIN + 
          ";Database=" + resultJson.OBJECT.DATABASE_PATH + ";"
        }
        else {
          clientConnection = "Server=" + resultJson.OBJECT.DOMAIN_HOMOLOGATION + 
          ";Database=" + resultJson.OBJECT.DATABASE_PATH_HOMOLOGATION + ";"            
        }
      }
      else {
        if (production === 1) {
          clientConnection = "User Id=postgres;Server=" + resultJson.OBJECT.DOMAIN +
          ";Port=5432;Database=" + resultJson.OBJECT.DATABASE_PATH + ";"
        }
        else {
          clientConnection = "User Id=postgres;Server=" + resultJson.OBJECT.DOMAIN_HOMOLOGATION +
          ";Port=5432;Database=" + resultJson.OBJECT.DATABASE_PATH_HOMOLOGATION + ";"
        }
      }

      setDatabasePath(clientConnection) 

      ////
      // Integrate SGE
      ////
      let clientSGEConnection = ""

      if (resultJson.OBJECT.INTEGRATE_SGE === 1){
        clientSGEConnection = "Server=" + resultJson.OBJECT.DOMAIN_SGE + 
        ";Database=" + resultJson.OBJECT.DATABASE_SGE + ";"
      }

      ////
      // Bank details
      ////
      typeTransfer = resultJson.OBJECT.TYPE_TRANSFER
      valueTransfer = resultJson.OBJECT.VALUE_TRANSFER

      ////
      // Get the company code
      ////
      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", clientConnection)
      formData.append("JSON_NEW_1", "{ \"CPF_CNPJ\": \""+ companyCnpj + "\" }")
      formData.append("JSON_LOGIN", "{ \"CPF\": \"79772468050\", \"PASSWORD\": \"M!LTPL\", \"COMPANY\": { \"ID\": 1 } }")
      formData.append("SYSTEM", true)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 99)

      axios.post(BASE_URL + "COMPANY/Search", formData)
      .then((response) => { resultJson = JSON.parse(response.data) })
      .catch((response) => { resultJson = JSON.parse("{\"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null,"+
        "\"ERROR\": \"Não foi possível obter o código da filial. ("+ response +"\")}") })
      .finally(() => {
        if (resultJson.SUCCESS === false){
          setError(resultJson.ERROR)
          setWarning(resultJson.WARNING)
          setLoading("")
          document.getElementById("companyCnpj").focus()
          return
        }

        if (resultJson.WARNING !== null){
          if (resultJson.WARNING === "Nenhum registro encontrado."){
            setWarning("Não foi possível localizar a empresa.")
            setError("")
            setLoading("")
            document.getElementById("companyCnpj").focus()
            return
          } else setWarning(resultJson.WARNING)
        }

        let jCompany = "{ "
        jCompany += "\"ID\": "+ resultJson.OBJECT[0].ID + ", "
        jCompany += "\"CPF_CNPJ\": \"" + resultJson.OBJECT[0].CPF_CNPJ + "\", "
        jCompany += "\"CORPORATE_NAME\": \"" + resultJson.OBJECT[0].CORPORATE_NAME + "\", "
        jCompany += "\"FANTASY_NAME\": \"" + resultJson.OBJECT[0].FANTASY_NAME + "\", "
        jCompany += "\"CEP\": " + resultJson.OBJECT[0].CEP + ", "
        jCompany += "\"ADDRESS\": \"" + resultJson.OBJECT[0].ADDRESS + "\", "
        jCompany += "\"NUMBER\": \"" + resultJson.OBJECT[0].NUMBER + "\", "

        if ((resultJson.OBJECT[0].COMPLEMENT !== null) && (resultJson.OBJECT[0].COMPLEMENT !== ""))
          jCompany += "\"COMPLEMENT\": \"" + resultJson.OBJECT[0].COMPLEMENT + "\", "

        jCompany += "\"NEIGHBORHOOD\": \"" + resultJson.OBJECT[0].NEIGHBORHOOD + "\", "
        jCompany += "\"CITY\": \"" + resultJson.OBJECT[0].CITY + "\", "
        jCompany += "\"CITY_IBGE\": " + resultJson.OBJECT[0].CITY_IBGE + ", "
        jCompany += "\"UF\": \"" + resultJson.OBJECT[0].UF + "\", "
        jCompany += "\"UF_IBGE\": " + resultJson.OBJECT[0].UF_IBGE + ", "
        jCompany += "\"COUNTRY_ID\": \"" + resultJson.OBJECT[0].COUNTRY_ID + "\", "
        jCompany += "\"COUNTRY_DESCRIPTION\": \"" + resultJson.OBJECT[0].COUNTRY_DESCRIPTION + "\", "
        jCompany += "\"COUNTRY_IBGE\": " + resultJson.OBJECT[0].COUNTRY_IBGE + ", "

        if ((resultJson.OBJECT[0].STANDARD_PHONE !== null) && (resultJson.OBJECT[0].STANDARD_PHONE !== ""))
          jCompany += "\"STANDARD_PHONE\": \"" + resultJson.OBJECT[0].STANDARD_PHONE + "\", "

        jCompany += "\"RESPONSIBLE_CPF\": \"" + resultJson.OBJECT[0].RESPONSIBLE_CPF + "\", "
        jCompany += "\"RESPONSIBLE_NAME\": \"" + resultJson.OBJECT[0].RESPONSIBLE_NAME + "\", "

        if ((resultJson.OBJECT[0].HOME_PAGE !== null) && (resultJson.OBJECT[0].HOME_PAGE !== ""))
          jCompany += "\"HOME_PAGE\": \"" + resultJson.OBJECT[0].HOME_PAGE + "\", "

        jCompany += "\"IE\": \"" + resultJson.OBJECT[0].IE + "\", "

        if ((resultJson.OBJECT[0].IM !== null) && (resultJson.OBJECT[0].IM !== "")) jCompany += "\"IM\": \"" + resultJson.OBJECT[0].IM + "\", "

        jCompany += "\"ESTABLISHMENT\": { \"ID\": "+ resultJson.OBJECT[0].ESTABLISHMENT.ID + " }, "
        jCompany += "\"SPECIES\": \"" + resultJson.OBJECT[0].SPECIES + "\", "
        jCompany += "\"PERCENTAGE_COMMISSION\": " + resultJson.OBJECT[0].PERCENTAGE_COMMISSION + ", "
        jCompany += "\"IDENTIFICATION_AGREEMENT\": " + resultJson.OBJECT[0].IDENTIFICATION_AGREEMENT + ", "
        jCompany += "\"IDENTIFICATION_NATURE\": " + resultJson.OBJECT[0].IDENTIFICATION_NATURE + ", "
        jCompany += "\"IDENTIFICATION_FINALITY\": " + resultJson.OBJECT[0].IDENTIFICATION_FINALITY + ", "

        if ((resultJson.OBJECT[0].SUFRAMA !== null) && (resultJson.OBJECT[0].SUFRAMA !== ""))
          jCompany += "\"SUFRAMA\": \"" + resultJson.OBJECT[0].SUFRAMA + "\", "

        jCompany += "\"PROFILE\": " + resultJson.OBJECT[0].PROFILE + ", "
        jCompany += "\"ACTIVITY\": " + resultJson.OBJECT[0].ACTIVITY + ", "
        jCompany += "\"ALIQUOT_PIS\": " + resultJson.OBJECT[0].ALIQUOT_PIS + ", "
        jCompany += "\"ALIQUOT_COFINS\": " + resultJson.OBJECT[0].ALIQUOT_COFINS + ", "
        jCompany += "\"ALIQUOT_SIMPLES\": " + resultJson.OBJECT[0].ALIQUOT_SIMPLES + ", "
        jCompany += "\"ALIQUOT_ISS\": " + resultJson.OBJECT[0].ALIQUOT_ISS + ", "

        if ((resultJson.OBJECT[0].SERIES_ISS !== null) && (resultJson.OBJECT[0].SERIES_ISS !== ""))
          jCompany += "\"SERIES_ISS\": \"" + resultJson.OBJECT[0].SERIES_ISS + "\", "
        
        jCompany += "\"REGIME\": " + resultJson.OBJECT[0].REGIME + ", "

        if ((resultJson.OBJECT[0].CNAE !== null) && (resultJson.OBJECT[0].CNAE !== ""))
          jCompany += "\"CNAE\": \"" + resultJson.OBJECT[0].CNAE + "\", "

        jCompany += "\"CULTURE\": " + resultJson.OBJECT[0].CULTURE + ", "
        jCompany += "\"REGIME_SPECIAL\": " + resultJson.OBJECT[0].REGIME_SPECIAL + ", "

        if ((resultJson.OBJECT[0].LOGO !== null) && (resultJson.OBJECT[0].LOGO !== null))
          jCompany += "\"LOGO\": \"" + resultJson.OBJECT[0].LOGO.replaceAll("\\","\\\\") + "\", "

        if ((resultJson.OBJECT[0].NFS_OBSERVATION !== null) && (resultJson.OBJECT[0].NFS_OBSERVATION !== ""))
          jCompany += "\"NFS_OBSERVATION\": \"" + resultJson.OBJECT[0].NFS_OBSERVATION + "\", "

        if ((resultJson.OBJECT[0].ORDER_OBSERVATION !== null) && (resultJson.OBJECT[0].ORDER_OBSERVATION !== ""))
          jCompany += "\"ORDER_OBSERVATION\": \"" + resultJson.OBJECT[0].ORDER_OBSERVATION + "\", "

        jCompany += "\"ENVIRONMENT\": " + resultJson.OBJECT[0].ENVIRONMENT + ", "
        jCompany += "\"EMISSION_TYPE\": " + resultJson.OBJECT[0].EMISSION_TYPE + ", "
        jCompany += "\"TYPE\": " + systemType + ", "
        // jCompany += "\"TYPE\": " + 1 + ", "
        jCompany += "\"TYPE_TRANSFER\": " + typeTransfer + ", "
        jCompany += "\"VALUE_TRANSFER\": " + valueTransfer + ", "
        jCompany += "\"SEND_NFE\": " + resultJson.OBJECT[0].SEND_NFE + ", "
        jCompany += "\"SEND_NFCE\": " + resultJson.OBJECT[0].SEND_NFCE + ", "
        jCompany += "\"SEND_NFSE\": " + resultJson.OBJECT[0].SEND_NFSE + ", " 
        jCompany += "\"SALE_TYPE\": " + resultJson.OBJECT[0].SALE_TYPE + ", "
        jCompany += "\"CERTIFICATE_PASSWORD\": \"" + resultJson.OBJECT[0].CERTIFICATE_PASSWORD + "\", "

        if ((resultJson.OBJECT[0].IE_ST !== null) && (resultJson.OBJECT[0].IE_ST !== "")) jCompany += "\"IE_ST\": \"" + resultJson.OBJECT[0].IE_ST + "\", "

        if ((clientSGEConnection === "") || ((resultJson.OBJECT[0].CPF_CNPJ === "07824993000165") && production === 1)){
          jCompany += "\"INTEGRATE_SGE\": 2, "
          jCompany += "\"DATABASE_SGE_PATH\": \"\", "
        } 
        else{
          jCompany += "\"INTEGRATE_SGE\": 1, "
          jCompany += "\"DATABASE_SGE_PATH\": \"" + clientSGEConnection + "\", "
        }

        jCompany += "\"PRODUCTION\": " + production + ", "

        if ((resultJson.OBJECT[0].OBSERVATION !== null) && (resultJson.OBJECT[0].OBSERVATION !== ""))
          jCompany += "\"OBSERVATION\": \"" + resultJson.OBJECT[0].OBSERVATION + "\", "

        jCompany += "\"SITUATION\": " + resultJson.OBJECT[0].SITUATION + " } "
        setJsonCompany(JSON.parse(jCompany))
        
        ////
        // Get company employees
        ////
        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", clientConnection)
        formData.append("JSON_NEW_1", "{ \"SITUATION\": 1, \"COMPANY\": { \"ID\": "+ resultJson.OBJECT[0].ID +" } }")
        formData.append("JSON_LOGIN", "{ \"CPF\": \"79772468050\", \"PASSWORD\": \"M!LTPL\", \"COMPANY\": { \"ID\": 1 } }")
        formData.append("SYSTEM", true)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", 99)

        axios.post(BASE_URL + "EMPLOYEE/Search", formData)
        .then((response) => { resultJson = JSON.parse(response.data) })
        .catch((response) => { resultJson = JSON.parse("{\"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null,"+
          "\"ERROR\": \"Não foi possível os dados da filial. ("+ response +"\")}") })
        .finally(() => {
          if (resultJson.CONCRETIZADO === false){
            setError(resultJson.ERROR)
            setWarning(resultJson.WARNING)
            setLoading("")
            document.getElementById("companyCnpj").focus()
            return
          }

          if (resultJson.WARNING !== null){
            if (resultJson.WARNING === "Nenhum registro encontrado."){
              setWarning("Não foi possível localizar a empresa.")
              setError("")
              setLoading("")
              document.getElementById("companyCnpj").focus()
              return
            } else setWarning(resultJson.WARNING)
          }

          let employee = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          resultJson && resultJson.OBJECT &&
            resultJson.OBJECT.map(item => (
              employee += ", { \"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\" }"
            ))
          employee += "]}"

          resultJson && resultJson.OBJECT && setJsonEmployees(resultJson.OBJECT)

          setOptionsEmployee(employee)
          setCompanySelected(true)
          setLoading("")

          setFieldSelected("employeeCode")
          setUpdateFocu(updateFocu + 1)
        })
      })
    })
  }

  return(
    <div style={{display: "flex", flexDirection: "column", minHeight: height}}>
      <div style={{display: "flex", flexGrow: 1}}>
        <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
          <div style={(width < 300) ? {paddingLeft: 20, paddingRight: 20, minHeight: 250, minWidth: 200, maxWidth: 200}
                      : (width < 500) ? {paddingLeft: 20, paddingRight: 20, minHeight: 250, minWidth: 300, maxWidth: 300}
                      : {paddingLeft: 20, paddingRight: 20, minHeight: 250, minWidth: 500, maxWidth: 500}}>

            { !companySelected &&
              <>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 10}}>
                  <img src={LogoMultipla} alt="Logomarca" />
                </div>

                <div style={{display: "flex", justifyContent: "center", marginBottom: 10}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>Informe os dados da empresa</label>
                </div>

                <div style={{marginTop: 10}}>
                  <Input
                    type="text"
                    name="companyCnpj"
                    planceHolder="CNPJ/CPF"
                    value={companyCnpj}
                    maxLength={14}
                    disabled={false}
                    require={true}
                    image={true}
                    typeImage={1}
                    typeInput={1}
                    onSelect={setFieldSelected}
                    onChange={(event) => setCompanyCnpj(event.target.value)} />
                </div>

                { showProduction &&
                  <div style={{marginTop: 10}}>
                    <Select
                      name="productionMultipla"
                      description="Produção:"
                      value={production || 1}
                      disabled={false}
                      require={true}
                      options={JSON.parse("{\"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\"}, {\"ID\": 2, \"VALUE\": \"Não\"}]}")}
                      image={false}
                      typeImage={0}
                      typeInput={1}
                      onSelect={setFieldSelected}
                      set={setProduction} />
                  </div>
                }

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 3,  borderColor: "rgb(128, 130, 133)",
                  borderStyle: "solid", borderRadius: 20, height: 52, backgroundColor: "rgb(0, 177, 171)", marginTop: 10}} onClick={selectCompany}>
                  <label style={{fontWeight: 600, color: "white"}}>ENTRAR</label>                
                </div>
              </>
            }

            { companySelected &&
              <>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 10}}>
                  { jsonCompany.CPF_CNPJ === "17180191000113" && <img src={LogoAriEletrica} alt="Logomarca" /> }
                  { jsonCompany.CPF_CNPJ === "71505564000124" && <img src={LogoEmige} alt="Logomarca" /> }
                  { jsonCompany.CPF_CNPJ === "07824993000165" && <img src={LogoMultipla} alt="Logomarca" /> }
                </div>

                <div style={{marginTop: 10}}>
                  <Select
                    name="employeeCode"
                    description="Coolaborador"
                    value={employeeCode || 0}
                    disabled={false}
                    require={true}
                    options={JSON.parse(optionsEmployee)}
                    image={true}
                    typeImage={1}
                    typeInput={1}
                    onSelect={setFieldSelected}
                    set={handleEmployee} />
                </div>

                { ((parseInt(employeeCode) === 1) && ((jsonCompany.CPF_CNPJ) !== "07824993000165")) &&
                  <div style={{marginTop: 10}}>
                    <Select
                      name="employeeCodeMultipla"
                      description="Coolaborador Múltipla"
                      value={employeeCodeMultipla || 0}
                      disabled={false}
                      require={true}
                      options={JSON.parse(optionsEmployeesMultipla)}
                      image={true}
                      typeImage={1}
                      typeInput={1}
                      onSelect={setFieldSelected}
                      set={handleEmployeeMultipla} />
                  </div>
                }

                <div style={{marginTop: 10}}>
                  <Input
                    type="password"
                    name="companyPassword"
                    description="Senha"
                    value={password}
                    maxLength={14}
                    disabled={false}
                    require={true}
                    image={true}
                    typeImage={2}
                    typeInput={1}
                    onSelect={setFieldSelected}
                    onChange={(event) => setPassword(event.target.value)} />
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 3,  borderColor: "rgb(128, 130, 133)",
                  borderStyle: "solid", borderRadius: 20, height: 52, backgroundColor: "rgb(0, 177, 171)", marginTop: 10}} onClick={login}>
                  <label style={{fontWeight: 600, color: "white"}}>LOGIN</label>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 3,  borderColor: "rgb(128, 130, 133)",
                  borderStyle: "solid", borderRadius: 20, height: 52, backgroundColor: "rgb(0, 177, 171)", marginTop: 10}} onClick={previous}>
                  <label style={{fontWeight: 600, color: "white"}}>RETORNAR</label>
                </div>
              </>
            }

            <br/>
            { loading && <div style={{textAlign: "center"}}><p>{loading}</p></div> }
            { warning && <div style={{textAlign: "center"}}><p>{warning}</p></div> }
            { error && <div style={{textAlign: "center"}}><p>{error}</p></div> }
          </div>
        </div>
      </div>

      <div style={{display: "flex", flexGrow: 0, width: width}}>
        <Footer />
      </div>
    </div>
  )
}

export default Login