import React, {useEffect} from "react"

const DigitalKeyboard = ({field, pressButton, pressBackSpace, pressEnter, width}) => {  
  useEffect(() => {
  }, [field])

  return(
    <div style={(width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0}
      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

      <div style={{display: "flex", flexDirection: "column", flexGrow: 0, alignItems: "center"}}>
        <div style={(width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 0, flexBasis: 1}
          : {display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "Q")}>
              <label style={{color: "white", fontWeight: 600}}>Q</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "W")}>
              <label style={{color: "white", fontWeight: 600}}>W</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "E")}>
              <label style={{color: "white", fontWeight: 600}}>E</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "R")}>
              <label style={{color: "white", fontWeight: 600}}>R</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "T")}>
              <label style={{color: "white", fontWeight: 600}}>T</label>
            </div>
          </div>

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "Y")}>
              <label style={{color: "white", fontWeight: 600}}>Y</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "U")}>
              <label style={{color: "white", fontWeight: 600}}>U</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "I")}>
              <label style={{color: "white", fontWeight: 600}}>I</label>
            </div>          
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "O")}>
              <label style={{color: "white", fontWeight: 600}}>O</label>
            </div>                    
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "P")}>
              <label style={{color: "white", fontWeight: 600}}>P</label>
            </div>                              
          </div>
        </div>

        <div style={(width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 0, flexBasis: 1}
          : {display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "A")}>
              <label style={{color: "white", fontWeight: 600}}>A</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "S")}>
              <label style={{color: "white", fontWeight: 600}}>S</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "D")}>
              <label style={{color: "white", fontWeight: 600}}>D</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "F")}>
              <label style={{color: "white", fontWeight: 600}}>F</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "G")}>
              <label style={{color: "white", fontWeight: 600}}>G</label>
            </div>
          </div>

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "H")}>
              <label style={{color: "white", fontWeight: 600}}>H</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "J")}>
              <label style={{color: "white", fontWeight: 600}}>J</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "K")}>
              <label style={{color: "white", fontWeight: 600}}>K</label>
            </div>          
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "L")}>
              <label style={{color: "white", fontWeight: 600}}>L</label>
            </div>                    
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "Ç")}>
              <label style={{color: "white", fontWeight: 600}}>Ç</label>
            </div>                              
          </div>                              
        </div>

        <div style={(width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 0, flexBasis: 1}
          : {display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "Z")}>
              <label style={{color: "white", fontWeight: 600}}>Z</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "X")}>
              <label style={{color: "white", fontWeight: 600}}>X</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "C")}>
              <label style={{color: "white", fontWeight: 600}}>C</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "V")}>
              <label style={{color: "white", fontWeight: 600}}>V</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "B")}>
              <label style={{color: "white", fontWeight: 600}}>B</label>
            </div>            
          </div>

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexBasis: 1}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "N")}>
              <label style={{color: "white", fontWeight: 600}}>N</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, "M")}>
              <label style={{color: "white", fontWeight: 600}}>M</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, ",")}>
              <label style={{color: "white", fontWeight: 600}}>,</label>
            </div>          
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, ".")}>
              <label style={{color: "white", fontWeight: 600}}>.</label>
            </div>                    
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
              alignItems: "center"}} onClick={() => pressButton(field, ";")}>
              <label style={{color: "white", fontWeight: 600}}>;</label>
            </div>
          </div>
        </div>

        <div style={{display: "flex", flexGrow: 1, height: 50, width: "100%", backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, " ")}>
          <label style={{color: "white", fontWeight: 600}}>Espaço</label>
        </div>
      </div>

      <div style={(width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, alignItems: "center", marginTop: 10}
        : {display: "flex", flexDirection: "column", flexGrow: 0}}>
        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "7")}>
            <label style={{color: "white", fontWeight: 600}}>7</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "8")}>
            <label style={{color: "white", fontWeight: 600}}>8</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "9")}>
            <label style={{color: "white", fontWeight: 600}}>9</label>
            </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "4")}>
            <label style={{color: "white", fontWeight: 600}}>4</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "5")}>
            <label style={{color: "white", fontWeight: 600}}>5</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "6")}>
            <label style={{color: "white", fontWeight: 600}}>6</label>
            </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)",  justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "1")}>
            <label style={{color: "white", fontWeight: 600}}>1</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "2")}>
            <label style={{color: "white", fontWeight: 600}}>2</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "3")}>
            <label style={{color: "white", fontWeight: 600}}>3</label>
            </div>
        </div>
        
        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressBackSpace(field)}>
            <label style={{color: "white", fontWeight: 600}}>{"<"}</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressButton(field, "0")}>
            <label style={{color: "white", fontWeight: 600}}>0</label>
            </div>
            <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
            alignItems: "center"}} onClick={() => pressEnter(field)}>
            <label style={{color: "white", fontWeight: 600}}>{">"}</label>
            </div>
        </div>
      </div>        
    </div>
  )
}

export default DigitalKeyboard