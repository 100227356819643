import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import image_save from "../../Images/Buttons/save.png"
import image_previous from "../../Images/Buttons/previous.png"

const BillReceivePortions = ({jsonCompany, paymentFormTerm, savePortions, returnAdd, showMenu, width}) => {  
  const [firstChange, setFirstChange] = useState(true)
  const [jPortions, setJPortions] = useState([])

  const [firstAccess, setFirstAccess] = useState(true)
  const [updatePage, setUpdatePage] = useState(0)

  useEffect(() => {
    if (firstAccess){
      removePortions()

      const aTerm = paymentFormTerm.split("#")
      const portions = aTerm.length
  
      let jsonPortions = ("")
      let jportion = " ["
  
      for (let i = 0; i < portions; i++) {
        if (i > 0) jportion += ", "
  
        jportion += " {"
        jportion += " \"PORTION\": "+ (i + 1)
        jportion += ", \"DUE_DATE\": \""+ addDaysDate(aTerm[i]) +"\""
        jportion += ", \"VALUE\": \"\""
        jportion += ", \"OBSERVATION\": \"\""
        jportion += " }"
      }
  
      jportion += " ]"
  
      jsonPortions = JSON.parse(jportion)
      setJPortions(jsonPortions)

      setFirstAccess(false)
    }

    console.log("Effect:")
    console.log(jPortions)

  }, [jsonCompany, paymentFormTerm, updatePage])
  
  const addDaysDate = (days) => {
    const today = new Date();
    const dueDate = new Date(today.getTime() + (days * 24 * 60 * 60 * 1000));
    return String(dueDate.getDate()).padStart(2,'0') + "/" + String(dueDate.getMonth() + 1).padStart(2, '0') + "/" + dueDate.getFullYear()
  }

  const changeDueDate = (id, event) => {
    jPortions[id].DUE_DATE = event.target.value

    if (firstChange) setFirstChange(false)
    setUpdatePage(updatePage + 1)
  }

  const changeObservation = (id, event) => {
    jPortions[id].OBSERVATION = event.target.value

    if (firstChange) setFirstChange(false)
    setUpdatePage(updatePage + 1)
  }

  const changeValue = (id, event) => {
    if (event.target.value === "") jPortions[id].VALUE = "0"
    else jPortions[id].VALUE = formatNumeric(event.target.value, 2)
    
    if (firstChange) setFirstChange(false)
    setUpdatePage(updatePage + 1)
  }

  const fieldSelected = () => {
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return String(parsed)
  }

  const generatePortions = () => {
    let validated = true

    for (let i = 0; i < jPortions.length; i++) {
      const cPortion =  jPortions[i].PORTION;
      const cDueDate = jPortions[i].DUE_DATE;
      var cValue = jPortions[i].VALUE;
        
      if (cPortion === ""){
        alert("Parcela inválida.")
        validated = false
        return
      }

      if (cDueDate === ""){
        alert("Data do vencimento inválida.")
        validated = false
        return
      }

      if (!validateDate(cDueDate)){
        alert("Data da parcela "+ (i+1) + " inválida.");
        validated = false
        return
      }

      if ((cValue === "") || (cValue === 0)){
        alert("Valor da parcela "+ (i+1) + " inválido.")
        validated = false
        return
      }

      validated = true
    }

    if (validated) savePortions(jPortions)
  }

  const removePortions = () => {
    let el = document.getElementById("portions_billReceivePortions");
    const divs = document.querySelectorAll('#portions_billReceivePortions > [id^=port]');

    for (var i = 0; i < divs.length; i++) {
      el.removeChild(divs[i])
    }
  }

  const validateDate = (value) => {
    let error = false;
    const date = value;
    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      {/* Begin - Buttons */}
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        { jPortions &&
          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
            marginLeft: 5, width: 147}} onClick={generatePortions}>
            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
              paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
              <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
            </div>
            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
            </div>
          </div>
        }

        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
          marginLeft: 5, width: 147}} onClick={returnAdd}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>
      {/* End - Buttons */}

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      { jPortions && jPortions.map(item => (

        <div key={"port"+ String(item.PORTION)} id={"port"+ String(item.PORTION)} 
          style={(width < 180) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}} >

          <div style={showMenu ?
              (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
            :
              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

            <div style={showMenu ?
                (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}
              :
                (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

              <div style={showMenu ?
                  (width < 770) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                :
                  (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                <Input
                  type="text"
                  id={"txtPortion" + item.PORTION}
                  name={"txtPortion" + item.PORTION}
                  description="Parcela"
                  value={item.PORTION}
                  maxLength={11}
                  disabled={true}
                  require={false}
                  image={false}
                  typeInput={2} 
                  onSelect={fieldSelected} />
              </div>

              <div style={showMenu ?
                  (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 150, maxWidth: 150}
                :
                  (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 150, maxWidth: 150}}>
                <InputMasked
                  type="text"
                  id={"txtDueDate" + item.PORTION}
                  name={"txtDueDate" + item.PORTION}
                  description="Dt. Vencto"
                  mask="99/99/9999"
                  value={ (firstChange) ? jPortions[item.PORTION - 1].DUE_DATE : jPortions[item.PORTION - 1].DUE_DATE.value }
                  disabled={false}
                  require={true}
                  image={false}
                  typeInput={2}
                  onSelect={fieldSelected}
                  onChange={ (e) => changeDueDate(item.PORTION - 1, e) } />               
              </div>
            </div>

            <div style={showMenu ?
                (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}
              :
                (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

              <div style={showMenu ?
                  (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1310) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                :
                  (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1030) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                <InputNumeric
                  type="text"
                  name={"txtValue"+ item.PORTION}
                  description="Valor"
                  value={ jPortions[item.PORTION - 1].VALUE }
                  maxLength={13}
                  disabled={false}
                  require={true}
                  negative={false}
                  image={false}
                  typeImage={0}
                  typeInput={2}
                  onSelect={fieldSelected}
                  onChange={ (e) => changeValue(item.PORTION - 1, e) } />
              </div>

              <div style={showMenu ?
                  (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                :
                  (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                <Input
                  type="text"
                  id={"txtObservation"+ item.PORTION +"_billReceivePortions"}
                  name={"txtObservation"+ item.PORTION +"_billReceivePortions"}
                  description="Observação"
                  value={ jPortions[item.PORTION - 1].OBSERVATION.value }
                  maxLength={65535}
                  disabled={false}
                  require={false}
                  image={false}
                  typeInput={2}
                  onSelect={fieldSelected}
                  onChange={ (e) => changeObservation(item.PORTION - 1, e) } />
              </div>
            </div>
          </div>
        </div>
       ))
      }

    </div>
  )
}

export default BillReceivePortions