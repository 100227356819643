import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import SearchClient from "../Client/Search"
import Titulo from "../../Components/Titulo"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const BilletClient = ({databaseType, databasePath, jsonBilletClient, jsonCompany,  jsonLogin, system, save, back, showMenu, width}) => {
  const [companyCode, setCompanyCode] = useState("")
  const [clientCode, setClientCode] = useState("")
  const [clientCpfCnpj, setClientCpfCnpj] = useState("")
  const [clientCorporateName, setClientCorporateName] = useState("")    

  const [showClient, setShowClient] = useState(false)

  useEffect(() => {
    setCompanyCode(jsonBilletClient.COMPANY.ID)
    document.getElementById("companyBankType") && document.getElementById("companyBankType").focus()
  }, [jsonBilletClient])

  const callSave = () => {
    let json = "{ \"COMPANY\": { \"ID\": " + companyCode + " }"

    if (clientCode !== ""){
        json += ", \"CLIENT\": { \"ID\": "+ clientCode +", \"CPF_CNPJ\": \""+ clientCpfCnpj +
            "\", \"CORPORATE_NAME\": \""+ clientCorporateName +"\" }"
    }

    json += " }"
    save(json)
  }

  const cleanClient = () => {
    setClientCode("")
    setClientCpfCnpj("")
    setClientCorporateName("")
  }  

  const enableClient = () => {
    setShowClient(!showClient)
  }

  const fillClient = (client) => {
    setClientCode(client.ID)
    setClientCpfCnpj(client.CPF_CNPJ)
    setClientCorporateName(client.CORPORATE_NAME)
    setShowClient(false)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showClient ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>
            
            <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10}
              : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

              <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                <Titulo description="Cliente" />
              </div>

              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 180}
                : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                  <div style={{display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                    <Input
                      type="text"
                      name="billetClientCpfCnpj"
                      description="CPF/CNPJ"
                      planceHolder=""
                      value={clientCpfCnpj}
                      maxLength={14}
                      disabled={true}
                      require={true}
                      image={false}
                      typeImage={0}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setClientCpfCnpj(event.target.value)} />
                  </div>

                  <div style={{display: "flex", flexGrow: 1, marginLeft: 10}}>
                    <Input
                      type="text"
                      name="billetClientCorporateName"
                      description="Nome"
                      planceHolder=""
                      value={clientCorporateName}
                      maxLength={60}
                      disabled={true}
                      require={true}
                      image={false}
                      typeImage={0}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setClientCorporateName(event.target.value)} />
                  </div>
                </div>
                <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                  : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                  <img
                    style={{height: 42, width: 42}}
                    name="billerClientSearchClient"
                    src={image_search} 
                    alt="Pesquisar" 
                    onClick={enableClient} />

                    <img
                      style={{marginLeft: 10, height: 42, width: 42}}
                      name="billetClientCleanClient"
                      src={image_clean} 
                      alt="Limpar"
                      onClick={cleanClient} />
                </div>
              </div>
            </div>
          </>
        :
          <div style={{marginTop: 10}}>
            <div style={{marginLeft: 10}}>
              <b>Selecione o cliente:</b>
            </div>

            <SearchClient
              modeSearch={true}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonCompany={jsonCompany}
              jsonLogin={jsonLogin}
              system={system}
              setAdd={() => alert("")}
              setSelected={fillClient} 
              setReturn={() => setShowClient(false)} 
              showMenu={showMenu}
              width={width} />
          </div>
      }
    </div>
  )
}

export default BilletClient