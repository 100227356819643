import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchRhPosition from "./Search"
import SearchRhSection from "../RhSection/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_search from "../../Images/Buttons/search.png"

const RhPosition = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")  
  const [rhSectionCode, setRhSectionCode] = useState("")
  const [rhSectionDescription, setRhSectionDescription] = useState("")
  const [executeOS, setExecuteOS] = useState(0)
  const [sge, setSge] = useState("")

  const [jsonRHPosition, setJsonRHPosition] = useState("")

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showRHSection, setShowRHSection] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setDescription("")
      setExecuteOS(0)
      setSge("")

      setRhSectionCode("")
      setRhSectionDescription("")
    }
    else {
      setDescription(jsonRHPosition.DESCRIPTION)
      setExecuteOS(jsonRHPosition.EXECUTE_OS)
      if ((jsonRHPosition.SGE !== null) && (jsonRHPosition.SGE !== undefined)) setSge(jsonRHPosition.SGE)

      setRhSectionCode(jsonRHPosition.RH_SECTION.ID)
      setRhSectionDescription(jsonRHPosition.RH_SECTION.DESCRIPTION)
    }

    document.getElementById("rhPositionDescription") && document.getElementById("rhPositionDescription").focus()
  }, [code, jsonRHPosition, updateFocu]) 

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
        setDescription("")
        setExecuteOS(0)
        setSge("")

        setRhSectionCode("")
        setRhSectionDescription("")
    }
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const cleanRHSection = () => {
    setRhSectionCode("")
    setRhSectionDescription("")
  }  

  const enableRHSection = () => {
    setShowRHSection(!showRHSection)
  }

  const fillRHSection = (bank) => {
    setRhSectionCode(bank.ID)
    setRhSectionDescription(bank.DESCRIPTION)
    setShowRHSection(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")
    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonRHPosition_New = {}

    let cRHSection = 0
    if (rhSectionCode !== "") cRHSection = rhSectionCode

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")
     
      if (((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)){
        jsonRHPosition_New = "{ \"DESCRIPTION\": \""+ description +"\", \"EXECUTE_OS\": "+ executeOS
        if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHPosition_New += ", \"SGE\": "+ sge
        jsonRHPosition_New += ", \"RH_SECTION\": { \"ID\": "+ cRHSection +", \"DESCRIPTION\": \""+ rhSectionDescription + "\""
        jsonRHPosition_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } } }"
      }
      else{
        jsonRHPosition_New = "{ \"DESCRIPTION\": \""+ description +"\", \"EXECUTE_OS\": 2 "
        if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHPosition_New += ", \"SGE\": "+ sge
        jsonRHPosition_New += ", \"RH_SECTION\": { \"ID\": "+ cRHSection +", \"DESCRIPTION\": \""+ rhSectionDescription + "\""
        jsonRHPosition_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } } }"
      }

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonRHPosition_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "RH_POSITION/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o cargo do RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhPositionDescription") && document.getElementById("rhPositionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonRHPosition(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      if (((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)){
        jsonRHPosition_New = "{ \"ID\": "+ code +", \"DESCRIPTION\": \""+ description +"\", \"EXECUTE_OS\": "+ executeOS
        if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHPosition_New += ", \"SGE\": "+ sge
        jsonRHPosition_New += ", \"RH_SECTION\": { \"ID\": "+ cRHSection +", \"DESCRIPTION\": \""+ rhSectionDescription + "\""
        jsonRHPosition_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } } }"
      }
      else{
        jsonRHPosition_New = "{ \"ID\": "+ code +", \"DESCRIPTION\": \""+ description +"\", \"EXECUTE_OS\": 2 "
        if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHPosition_New += ", \"SGE\": "+ sge
        jsonRHPosition_New += ", \"RH_SECTION\": { \"ID\": "+ cRHSection +", \"DESCRIPTION\": \""+ rhSectionDescription + "\""
        jsonRHPosition_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } } }"
      }

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonRHPosition))
      formData.append("JSON_NEW_1", jsonRHPosition_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "RH_POSITION/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o cargo RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhPositionDescription") && document.getElementById("rhPositionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonRHPosition(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  } 

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonRHPosition(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10} 
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Cargo RH"
            titleSearch="Pesquisa dos Cargos do RH"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchRhPosition
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showRHSection ?
                          <>
                            <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                            </div>
                          
                            <div style={{paddingLeft: 20, paddingRight: 20}}>
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                paddingLeft: 10}}>
                                { warning && 
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                  </div> 
                                }

                                { error &&
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                  </div>
                                }
                              </div>

                              {/* Múltipla / Detetização / Licitação */}
                              { (((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) &&
                                <>
                                  { (code === "") &&
                                    <div style={(showMenu) ?
                                        (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : 
                                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="rhPositionDescription"
                                          description="Descrição"
                                          planceHolder=""
                                          value={description}
                                          maxLength={50}
                                          disabled={false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setDescription(event.target.value)} />
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                        <Select
                                          name="rhPositionExecuteOS"
                                          description="Executa OS"
                                          value={executeOS}
                                          disabled={false}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setExecuteOS}
                                          mostrarTitulo={true} />
                                      </div>
                                    </div>
                                  }

                                  { (code !== "") &&
                                    <div style={(showMenu) ?
                                        (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      :
                                        (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                          <Input
                                            type="text"
                                            name="rhPositionCode"
                                            description="Código"
                                            value={code}
                                            maxLength={11}
                                            disabled={true}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setCode(event.target.value)} />
                                        </div>
                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                          <Input
                                            type="text"
                                            name="rhPositionDescription"
                                            description="Descrição"
                                            planceHolder=""
                                            value={description}
                                            maxLength={50}
                                            disabled={false}
                                            require={true}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setDescription(event.target.value)} />
                                        </div>
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 1000) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                        <Select
                                          name="rhPositionExecuteOS"
                                          description="Executa OS"
                                          value={executeOS}
                                          disabled={false}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setExecuteOS}
                                          mostrarTitulo={true} />
                                      </div>
                                    </div>
                                  }
                                </>
                              }

                              {/* Geral / Feira / Lene / Jm3d */}
                              { ((((jsonCompany.TYPE === 2) || (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) || (jsonCompany.TYPE === 7)) &&
                                <>
                                  { (code === "") &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>                                        
                                        <Input
                                          type="text"
                                          name="rhPositionDescription"
                                          description="Descrição"
                                          planceHolder=""
                                          value={description}
                                          maxLength={50}
                                          disabled={false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setDescription(event.target.value)} />
                                      </div>
                                    </div>
                                  }

                                  { (code !== "") &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                        <Input
                                          type="text"
                                          name="rhPositionCode"
                                          description="Código"
                                          value={code}
                                          maxLength={11}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCode(event.target.value)} />
                                      </div>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="rhPositionDescription"
                                          description="Descrição"
                                          planceHolder=""
                                          value={description}
                                          maxLength={50}
                                          disabled={false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setDescription(event.target.value)} />
                                      </div>
                                    </div>
                                  }
                                </>
                              }

                              <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                                <Titulo description="Setor RH" />
                              </div>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 180}
                                : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                                <div style={{display: "flex", flexGrow: 1}}>
                                  <Input
                                    type="search"
                                    name="rhPositionRhSectionDescription"
                                    description="Descrição"
                                    planceHolder=""
                                    value={rhSectionDescription}
                                    maxLength={100}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setRhSectionDescription(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                  <img
                                    style={{height: 42, width: 42}}
                                    name="rhPositionSearchRHSectionDescription"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={enableRHSection} />

                                  <img
                                    style={{marginLeft: 10, height: 42, width: 42}}
                                    name="rhPositionCleanRHSectionDescription"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanRHSection} />
                                </div>
                              </div>
                            </div>
                          </>
                        :
                          <div style={{marginTop: 10}}>
                            <div style={{marginLeft: 10}}>
                              <b>Selecione o setor:</b>
                            </div>

                            <SearchRhSection
                              modeSearch={true}
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system}
                              setAdd={() => alert("")}
                              setSelected={fillRHSection} 
                              setReturn={() => setShowRHSection(false)} />
                          </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default RhPosition