import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import SearchProduct from "../Product/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const ProductCompanyComposition = ({databaseType, databasePath, jsonProductCompanyComposition, jsonCompany, jsonLogin, system, save, back, showMenu,
  width}) => {

  const [code, setCode] = useState("")

  const [productCompanyCodeO, setProductCompanyCodeO] = useState("")
  const [productCompanyProductCodeO, setProductCompanyProductCodeO] = useState("")
  const [productCompanyProductDescriptionO, setProductCompanyProductDescriptionO] = useState("")
  const [productCompanyProductCommercialUnitWeighableO, setProductCompanyProductCommercialUnitWeighableO] = useState("")

  const [productCompanyCodeD, setProductCompanyCodeD] = useState("")
  const [productCompanyProductCodeD, setProductCompanyProductCodeD] = useState("")
  const [productCompanyProductDescriptionD, setProductCompanyProductDescriptionD] = useState("")
  const [productCompanyProductCommercialUnitWeighableD, setProductCompanyProductCommercialUnitWeighableD] = useState("")

  const [quantity, setQuantity] = useState("")
  const [sge, setSge] = useState("")

  const [showProduct, setShowProduct] = useState(false)

  useEffect(() => {    
    setCode(jsonProductCompanyComposition.ID)
    setProductCompanyCodeO(jsonProductCompanyComposition.PRODUCT_COMPANY_O.ID)
    setProductCompanyProductCodeO(jsonProductCompanyComposition.PRODUCT_COMPANY_O.PRODUCT.ID)
    setProductCompanyProductDescriptionO(jsonProductCompanyComposition.PRODUCT_COMPANY_O.PRODUCT.DESCRIPTION)
    setProductCompanyProductCommercialUnitWeighableO(jsonProductCompanyComposition.PRODUCT_COMPANY_O.PRODUCT.COMMERCIAL_UNIT.WEIGHABLE)

    if (jsonProductCompanyComposition.PRODUCT_COMPANY_D !== undefined){
      setProductCompanyCodeD(jsonProductCompanyComposition.PRODUCT_COMPANY_D.ID)
      setProductCompanyProductCodeD(jsonProductCompanyComposition.PRODUCT_COMPANY_D.PRODUCT.ID)
      setProductCompanyProductDescriptionD(jsonProductCompanyComposition.PRODUCT_COMPANY_D.PRODUCT.DESCRIPTION)
      setProductCompanyProductCommercialUnitWeighableD(jsonProductCompanyComposition.PRODUCT_COMPANY_D.PRODUCT.COMMERCIAL_UNIT.WEIGHABLE)
    }
    else{
      setProductCompanyCodeD("")
      setProductCompanyProductCodeD("")
      setProductCompanyProductDescriptionD("")
      setProductCompanyProductCommercialUnitWeighableD("")
    }

    setQuantity(formatNumeric(jsonProductCompanyComposition.QUANTITY, 3))

    if (((jsonProductCompanyComposition.SGE !== undefined) && (jsonProductCompanyComposition.SGE !== null)) && (jsonProductCompanyComposition.SGE !== 0)) 
      setSge(jsonProductCompanyComposition.SGE)

  }, [jsonProductCompanyComposition])

  const cleanProduct = () => {
    setProductCompanyCodeD("")
    setProductCompanyProductCodeD("")
    setProductCompanyProductDescriptionD("")
    setProductCompanyProductCommercialUnitWeighableD("")
    setShowProduct(false);
  }

  const enableProduct = () => {
    setShowProduct(!showProduct)
  }

  const fillProduct = (product) => {
    if (product.PRODUCT_COMPANY !== null){
      setProductCompanyCodeD(product.PRODUCT_COMPANY.ID)
      setProductCompanyProductCodeD(product.ID)
      setProductCompanyProductDescriptionD(product.DESCRIPTION)
      setProductCompanyProductCommercialUnitWeighableD(product.COMMERCIAL_UNIT.WEIGHABLE)
    }
    else{
      alert("Esse produto não pertence a filial selecionada.")
  
      setProductCompanyCodeD("")
      setProductCompanyProductCodeD("")
      setProductCompanyProductDescriptionD("")
      setProductCompanyProductCommercialUnitWeighableD("")
    }

    setShowProduct(false);
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.000"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.000"
    return parsed
  }

  const handleQuantity = (event) => {
    setQuantity(formatNumeric(event.target.value, 3))
  }

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","

    json += " \"PRODUCT_COMPANY_O\": { \"ID\": " + productCompanyCodeO + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
      ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }" +
        ", \"PRODUCT\": { \"ID\": "+ productCompanyProductCodeO +", \"DESCRIPTION\": \""+ productCompanyProductDescriptionO +
          "\", \"COMMERCIAL_UNIT\": { \"WEIGHABLE\": "+ productCompanyProductCommercialUnitWeighableO +" } } }"

    if (((productCompanyCodeD !== undefined) && (productCompanyCodeD !== null)) && (productCompanyCodeD !== ""))
      json += ", \"PRODUCT_COMPANY_D\": { \"ID\": " + productCompanyCodeD + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
      ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }" +
        ", \"PRODUCT\": { \"ID\": "+ productCompanyProductCodeD +", \"DESCRIPTION\": \""+ productCompanyProductDescriptionD +
          "\", \"COMMERCIAL_UNIT\": { \"WEIGHABLE\": "+ productCompanyProductCommercialUnitWeighableD +" } } }"
    
    json += ", \"QUANTITY\": "+ quantity

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showProduct ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <b>Produto Composição:</b>
              </div>

              <div style={showMenu ?
                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                :  
                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}}>

                <div style={showMenu ?
                    (width < 1230) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                  :  
                    (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                  <div style={showMenu ?
                      (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    :  
                      (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                    <div style={showMenu ?
                        (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                      :
                        (width < 770) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                      <Input
                        type="text"
                        name="productCompanyCompositionProductCompanyProductCodeD"
                        description="Código"
                        value={productCompanyProductCodeD}
                        maxLength={11}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setProductCompanyProductCodeD(event.target.value)} />
                    </div>
                    <div style={showMenu ?
                        (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Input
                        type="text"
                        name="productCompanyCompositionProductCompanyProductDescriptionD"
                        description="Descrição"
                        value={productCompanyProductDescriptionD}
                        maxLength={120}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setProductCompanyProductDescriptionD(event.target.value)} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 1230) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                    :
                      (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                    <InputNumeric
                      type="text"
                      name="productCompanyCompositionQuantity"
                      description="Quantidade"
                      value={quantity || ""}
                      maxLength={13}
                      disabled={false}
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={handleQuantity} />
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 580) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                    : (width < 840) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                  :
                    (width < 580) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 10}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productCompanyCompositionSearchProductD"
                      src={image_search}
                      alt="Pesquisar"
                      onClick={(() => enableProduct(true))} />
                  </div>
                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productCompanyCompositionCleanProductD"
                      src={image_clean}
                      alt="Limpar"
                      onClick={cleanProduct} />
                  </div>
                </div>
              </div>
            </div>
          </>
        :
          <div>
            <div>
              <b>Selecione a composição:</b>
              <SearchProduct
                modeSearch={true}
                process={1}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                type={"'01','02','07','11'"}
                system={system}
                setAdd={() => alert("")}
                setSelected={fillProduct} 
                setReturn={() => setShowProduct(false)}
                showMenu={showMenu}
                width={width} />
            </div>
          </div>
      }

    </div>
  )
}

export default ProductCompanyComposition