import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import InputMasked from "../../../Components/InputMasked"
import Questionnaire from "../../../Components/Questionnaire"
import Select from "../../../Components/Select"
import SearchPagination from "../../../Components/SearchPagination"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchPriceTable = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, periodic,
  showMenu, width}) => {

  const [selectedField, setSelectedField] = useState("")

  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [situation, setSituation] = useState("")

  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    let jPriceTable = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"

    if (code !== ""){
      let codeN = String(code).replaceAll("_","")
      if (codeN !== "") jPriceTable += ", \"ID\": "+ codeN
    }

    if (description !== "") jPriceTable += ", \"DESCRIPTION\": \""+ description + "\""
    if (situation !== "") jPriceTable += ", \"SITUATION\": "+ situation
    jPriceTable += " }"

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jPriceTable)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRICE_TABLE/TotalRecords", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar o total de tabelas de preços. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")

        if (selectedField === "searchPriceTableDescription") {
          document.getElementById("searchPriceTableDescription") &&
            document.getElementById("searchPriceTableDescription").focus()
        }
        else if (selectedField === "searchPriceTableSituation") {
          document.getElementById("searchPriceTableSituation") &&
            document.getElementById("searchPriceTableSituation").focus()
        }
        else {
          document.getElementById("searchPriceTableCode") &&
            document.getElementById("searchPriceTableCode").focus() 
        }

        return
      }

      const tRecord = returnJson.OBJECT
      if (tRecord === 0)
      {
        setWarning("Nenhum registro encontrado.")
        setShowResult(false)
        setLoading("")

        if (selectedField === "searchPriceTableDescription") {
          document.getElementById("searchPriceTableDescription") &&
            document.getElementById("searchPriceTableDescription").focus()
        }
        else if (selectedField === "searchPriceTableSituation") {
          document.getElementById("searchPriceTableSituation") &&
            document.getElementById("searchPriceTableSituation").focus()
        }
        else {
          document.getElementById("searchPriceTableCode") &&
            document.getElementById("searchPriceTableCode").focus() 
        }

        return
      }

      const operation = tRecord / recordPage
      const numberR = Math.floor(operation)
      const decimal = operation % 1

      let tPage = 0
      if (decimal > 0) tPage = numberR + 1
      else tPage = numberR

      if (tRecord > 600)
        setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
          ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

      setTotalRecord(tRecord)
      setTotalPage(tPage)
      if (tPage < pageCurrent) setPageCurrent(1)

      returnJson.WARNING && setWarning(returnJson.WARNING)
    
      let qtdeInitial = 0
      if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jPriceTable)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", qtdeInitial)
      formData.append("QUANTITY_RECORDS", recordPage)

      axios.post(BASE_URL + "PRICE_TABLE/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as tabelas de preços. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (selectedField === "searchPriceTableDescription") {
            document.getElementById("searchPriceTableDescription") &&
              document.getElementById("searchPriceTableDescription").focus()
          }
          else if (selectedField === "searchPriceTableSituation") {
            document.getElementById("searchPriceTableSituation") &&
              document.getElementById("searchPriceTableSituation").focus()
          }
          else {
            document.getElementById("searchPriceTableCode") &&
              document.getElementById("searchPriceTableCode").focus() 
          }
        
          return
        }
        
        setResult(returnJson)
        setShowResult(true)
        setLoading("")

        if (selectedField === "searchPriceTableDescription") {
          document.getElementById("searchPriceTableDescription") &&
            document.getElementById("searchPriceTableDescription").focus()
        }
        else if (selectedField === "searchPriceTableSituation") {
          document.getElementById("searchPriceTableSituation") &&
            document.getElementById("searchPriceTableSituation").focus()
        }
        else {
          document.getElementById("searchPriceTableCode") &&
            document.getElementById("searchPriceTableCode").focus() 
        }
      })
    })
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, code, description, situation, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir a Tabela de Preço (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode("")
  }

  const clean = () => {
    setCode("")
    setDescription("")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jPriceTable = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jPriceTable)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRICE_TABLE/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir a tabela de preço. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode("")
        setLoading("")

        document.getElementById("searchPriceTableCode") && document.getElementById("searchPriceTableCode").focus() 
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode("")
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const handleCode = (event) => {
    let codeN = String(event.target.value).replaceAll("_","")
    if (codeN !== "") setCode(event.target.value)
    else setCode("")
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    { !modeSearch &&
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                        width: 147}} onClick={() => setAdd()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                        </div>
                      </div>
                    }

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                      width: 147}} onClick={clean}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                      width: 147}} onClick={setReturn}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div> 
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div> 
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={showMenu ?
                          (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                          : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                        :
                          (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                          : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                        <div style={showMenu ?
                            (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                            : (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                          :
                            (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={showMenu ?
                              (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                              : (width < 770) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                            :
                              (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                            <InputMasked
                              name="searchPriceTableCode"
                              description="Código"
                              planceHolder=""
                              mask="99999999999"
                              value={code}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setSelectedField}
                              onChange={handleCode} />
                          </div>
                          <div style={showMenu ?
                              (width < 520) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            :
                              (width < 520) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="search"
                              name="searchPriceTableDescription"
                              description="Descrição"
                              planceHolder=""
                              value={description}
                              maxLength={80}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setSelectedField}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                          :
                            (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                          <Select
                            name="searchPriceTableSituation"
                            description="Situação"
                            value={situation || "0"}
                            require={false}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\" }, {\"ID\": \"1\", \"VALUE\": \"Ativo\" }"+
                              ", {\"ID\": \"2\", \"VALUE\": \"Inativo\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setSelectedField}
                            set={setSituation} />
                        </div>
                      </div>

                      { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                                borderBottomStyle: "solid", marginTop: 20, marginBottom: 4, minWidth: 180}}>

                                <div style={showMenu ?
                                    (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={showMenu ?
                                      (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                        : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>

                                    <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Descrição</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 990) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                      <label style={{fontWeight: 600}}>Validade</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 70, maxWidth: 70}
                                      :
                                        (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 70, maxWidth: 70}}>
                                      <label style={{fontWeight: 600}}>Situação</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={(width < 450) ? {display: "none"}
                                  : {display: "flex", flexGrow: 0, marginRight: 8}}>

                                  <div style={{height: 32, width: 32}} />
                                  { !modeSearch && <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} /> }
                                </div>
                              </div>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, paddingBottom: 8,
                                  marginTop: 4, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                    marginTop: 4, minWidth: 180}}>

                                  <div style={showMenu ?
                                      (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={showMenu ?
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                          : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                        <label>{item.ID}</label>
                                      </div>

                                      <div style={{display: "flex", flexGrow: 1, marginLeft: 5, minWidth: 180}}>
                                        <label>{item.DESCRIPTION}</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 990) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                        <label>{String(item.EXPIRATION_DATE).substr(8,2) + "/" + String(item.EXPIRATION_DATE).substr(5,2) + "/" +
                                            String(item.EXPIRATION_DATE).substr(0,4)}</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 800) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 70, maxWidth: 70}
                                        :
                                          (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 70, maxWidth: 70}}>
                                        <label>{String(item.SITUATION) === "1" ? "Ativo" : "Inativo"}</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexGrow: 0}}>

                                    <img
                                      style={{height: 32, width: 32}}
                                      src={image_select}
                                      name="searchPriceTableSelect"
                                      alt="Selecionar"
                                      onClick={() => setSelected(item)} />

                                    { !modeSearch &&
                                      <img
                                        style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}}
                                        src={image_delete}
                                        name="searchPriceTableDelete"
                                        alt="Deletar"
                                        onClick={() => callDelete(item)} />
                                    }
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination 
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setSelectedField}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}  

export default SearchPriceTable