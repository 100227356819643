import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import SearchServiceCnae from "./Search"
import SearchServiceCodeCnae from "../ServiceCodeCnae/Search"
import Titulo from "../../Components/Titulo"
import image_loading from "../../Images/spinner.gif"

const ServiceCnae = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [originCode, setOriginCode] = useState("")
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [standard, setStandard] = useState(2)
  const [sge, setSge] = useState("")

  const [jServiceCnae, setJServiceCnae] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (originCode === "") {
      setDescription("")
      setStandard(2)
      setSge("")
    }
    else
    {
      setDescription(jServiceCnae.DESCRIPTION)
      setStandard(jServiceCnae.DEFAULT)

      if ((jServiceCnae.SGE !== null) && (jServiceCnae.SGE !== undefined)) setSge(jServiceCnae.SGE)
    }

    if (originCode === "") document.getElementById("serviceCnaeCode") && document.getElementById("serviceCnaeCode").focus()
    else document.getElementById("serviceCnaeDescription") && document.getElementById("serviceCnaeDescription").focus()
  }, [originCode, jServiceCnae, updateFocu])


  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setDescription("")
      setStandard(2)
      setSge("")
    }
    else {
      setOriginCode("")
      setCode("")
    }

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")
    setOriginCode("")    

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonServiceCnae_New = {}
        
    setWarning("")
    setError("")

    jsonServiceCnae_New = "{"
    if (code !== "") jsonServiceCnae_New += "\"CODE\": "+ code

    if (description !== "") {
      if (jsonServiceCnae_New !== "{ ") jsonServiceCnae_New += ", "
      jsonServiceCnae_New += "\"DESCRIPTION\": \""+ description +"\""
    }

    if (jsonServiceCnae_New !== "{ ") jsonServiceCnae_New += ", "
    jsonServiceCnae_New += "\"DEFAULT\": "+ standard

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonServiceCnae_New += ", \"SGE\": "+ sge
    jsonServiceCnae_New += " }"

    if (originCode === ""){
      setLoading("Aguarde incluindo o registro...")
            
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonServiceCnae_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CNAE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o CNAE de serviço. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("serviceCnaeDescription") && document.getElementById("serviceCnaeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJServiceCnae(returnJson.OBJECT)  

        console.log(returnJson.OBJECT)

        setOriginCode(returnJson.OBJECT.CODE)
        setCode(returnJson.OBJECT.CODE)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jServiceCnae))
      formData.append("JSON_NEW_1", jsonServiceCnae_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "SERVICE_CNAE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o CNAE de serviço. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("serviceCnaeDescription") && document.getElementById("serviceCnaeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJServiceCnae(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }    

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJServiceCnae(event)
    setCode(event.CODE)
    setOriginCode(event.CODE)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="CNAE do serviço"
            titleSearch="Pesquisa dos CNAEs do serviço"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchServiceCnae
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={(showMenu) ?
                            (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={(showMenu) ? 
                              (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                            <div style={(showMenu) ?
                                (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                : (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                              :
                                (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                              <Input
                                type="text"
                                name="serviceCnaeCode"
                                description="Código"
                                value={code}
                                maxLength={7}
                                disabled={(originCode === "") ? false : true}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={(showMenu) ? 
                                (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                : (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                              : 
                                (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                              <Input
                                type="text"
                                name="serviceCnaeDescription"
                                description="Descrição"
                                value={description}
                                maxLength={255}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                          <div style={(showMenu) ?
                              (width < 1090) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                              : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                            :
                              (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                              : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                            <Select
                              name="serviceCnaeStandard"
                              description="Padrão"
                              value={standard || 2}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setStandard} />
                          </div>
                        </div>

                        { originCode &&
                          <div style={{marginTop: 10}}>
                            <Titulo description="Código do CNAE"/>
                            <SearchServiceCodeCnae
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonServiceCnae={jServiceCnae}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system} 
                              showMenu={showMenu}
                              width={width} />
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ServiceCnae