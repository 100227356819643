import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import img_arrow from "../../Images/Menu/arrow.png"
import img_billing from "../../Images/Menu/billing.png"
import img_billingWhite from "../../Images/Menu/billingWhite.png"
import img_cashierControl from "../../Images/Menu/cashierControl.png"
import img_cashierControlWhite from "../../Images/Menu/cashierControlWhite.png"
import img_exit from "../../Images/Menu/exit.png"
import img_financial from "../../Images/Menu/financial.png"
import img_financialWhite from "../../Images/Menu/financialWhite.png"
import img_fiscal from "../../Images/Menu/fiscal.png"
import img_fiscalWhite from "../../Images/Menu/fiscalWhite.png"
import img_managerial from "../../Images/Menu/managerial.png"
import img_managerialWhite from "../../Images/Menu/managerialWhite.png"
import img_menu from "../../Images/Menu/menu.png"
import img_requisition from "../../Images/Menu/requisition.png"
import img_requisitionWhite from "../../Images/Menu/requisitionWhite.png"
import img_rh from "../../Images/Menu/rh.png"
import img_rhWhite from "../../Images/Menu/rhWhite.png"
import img_shadow from "../../Images/Menu/shadow.png"
import img_stock from "../../Images/Menu/stock.png"
import img_stockWhite from "../../Images/Menu/stockWhite.png"
import img_telemarketing from "../../Images/Menu/telemarketing.png"
import img_telemarketingWhite from "../../Images/Menu/telemarketingWhite.png"
import img_treasury from "../../Images/Menu/treasury.png"
import img_treasuryWhite from "../../Images/Menu/treasuryWhite.png"

const Menu = ({jsonCompany, jsonLogin, showMenu, setShowMenu, width, logoff}) => {
  const [firstAccess, setFirstAccess] = useState(true)

  const [arrayPermission, setArrayPermission] = useState([])
  const [arrayPermissionGroup, setArrayPermissionGroup] = useState([])

  const [showBilling, setShowBilling] = useState(false)
  const [showCashierControl, setShowCashierControl] = useState(false)
  const [showExit, setShowExit] = useState(false)
  const [showFinancial, setShowFinancial] = useState(false)
  const [showFiscal, setShowFiscal] = useState(false)
  const [showManagerial, setShowManagerial] = useState(false)
  const [showRh, setShowRh] = useState(false)
  const [showRequisition, setShowRequisition] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const [showTelemarketing, setShowTelemarketing] = useState(false)
  const [showTreasury, setShowTreasury] = useState(false)

  const [showProcessBilling, setShowProcessBilling] = useState(false)
  const [showProcessCashierControl, setShowProcessCashierControl] = useState(false)
  const [showProcessFinancial, setShowProcessFinancial] = useState(false)
  const [showProcessFiscal, setShowProcessFiscal] = useState(false)
  const [showProcessManagerial, setShowProcessManagerial] = useState(false)
  const [showProcessRh, setShowProcessRh] = useState(false)
  const [showProcessRequisition, setShowProcessRequisition] = useState(false)
  const [showProcessStock, setShowProcessStock] = useState(false)
  const [showProcessTelemarketing, setShowProcessTelemarketing] = useState(false)  
  const [showProcessTreasury, setShowProcessTreasury] = useState(false)

  useEffect(() => {
    if (firstAccess){
      const permission = jsonLogin.ACCESS
      setArrayPermission(String(permission).split('/'))

      let permissionGroup = "F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F"
      if ((jsonLogin.RH_POSITION !== undefined) && (jsonLogin.RH_POSITION !== null)){
        if ((jsonLogin.RH_POSITION.RH_SECTION !== undefined) && (jsonLogin.RH_POSITION.RH_SECTION !== null)){
          if ((jsonLogin.RH_POSITION.RH_SECTION.PERMISSION !== undefined) && 
            (jsonLogin.RH_POSITION.RH_SECTION.PERMISSION !== null)){
              permissionGroup = jsonLogin.RH_POSITION.RH_SECTION.PERMISSION
            }
        }
      }

      setArrayPermissionGroup(String(permissionGroup).split('/'))
      setFirstAccess(false)
    }
    else{
      if (showMenu) {
        setShowTelemarketing(true)
        setShowRh(true)
        setShowStock(true)
        setShowFinancial(true)
        setShowTreasury(true)
        setShowManagerial(true)
        setShowBilling(true)
        setShowCashierControl(true)
        setShowFiscal(true)
        setShowRequisition(true)
        setShowExit(true)
      }
      else {
        setShowTelemarketing(false)
        setShowProcessTelemarketing(false)
        setShowRh(false)
        setShowProcessRh(false)
        setShowStock(false)
        setShowProcessStock(false)
        setShowFinancial(false)
        setShowProcessFinancial(false)
        setShowTreasury(false)
        setShowProcessTreasury(false)
        setShowManagerial(false)
        setShowProcessManagerial(false)
        setShowBilling(false)
        setShowProcessBilling(false)
        setShowCashierControl(false)
        setShowProcessCashierControl(false)
        setShowFiscal(false)
        setShowProcessFiscal(false)
        setShowRequisition(false)
        setShowProcessRequisition(false)
        setShowExit(false)
      }
    }
  }, [showMenu, firstAccess])

  const callBilling = () => {
    callModule(7)
    setShowProcessBilling(true)
  }

  const callCashierControl = () => {
    callModule(8)
    setShowProcessCashierControl(true)
  }

  const callFinancial = () => {
    callModule(4)
    setShowProcessFinancial(true)
  }

  const callFiscal = () => {
    callModule(9)
    setShowProcessFiscal(true)
  }

  const callManagerial = () => {
    callModule(6)
    setShowProcessManagerial(true)
  }

  const callModule = (type) => {
    cleanModule()

    ///
    // Telemarketing
    ///
    if (type === 1){
      setShowTelemarketing(true)
      setShowProcessTelemarketing(true)
    }
    
    ///
    // RH
    ///
    else if (type === 2){
      setShowRh(true)
      setShowProcessRh(true)
    }

    ///
    // Stock
    ///
    else if (type === 3){
      setShowStock(true)
      setShowProcessStock(true)
    }

    ///
    // Financial
    ///
    else if (type === 4){
      setShowFinancial(true)
      setShowProcessFinancial(true)
    }

    ///
    // Treasury
    ///
    else if (type === 5){
      setShowTreasury(true)
      setShowProcessTreasury(true)
    }

    ///
    // Managerial
    ///
    else if (type === 6){
      setShowManagerial(true)
      setShowProcessManagerial(true)
    }

    ///
    // Billing
    ///
    else if (type === 7){
      setShowBilling(true)
      setShowProcessBilling(true)
    }

    ///
    // Cashier Control
    ///
    else if (type === 8){
      setShowCashierControl(true)
      setShowProcessCashierControl(true)
    }

    ///
    // Fiscal
    ///
    else if (type === 9){
      setShowFiscal(true)
      setShowProcessFinancial(true)
    }

    ///
    // Requisition
    ///
    else if (type === 10){
      setShowRequisition(true)
      setShowProcessRequisition(true)
    }    
  }

  const callRequisition = () => {
    callModule(10)
    setShowProcessRequisition(true)
  }

  const callRh = () => {
    callModule(2)
    setShowProcessRh(true)
  }

  const callStock = () => {
    callModule(3)
    setShowProcessStock(true)
  }
    
  const callTelemarketing = () => {
    callModule(1)
    setShowProcessTelemarketing(true)
  }
    
  const callTreasury = () => {
    callModule(5)
    setShowProcessTreasury(true)
  }

  const cleanModule = () => {
    setShowTelemarketing(false)
    setShowProcessTelemarketing(false)
    setShowRh(false)
    setShowProcessRh(false)
    setShowStock(false)
    setShowProcessStock(false)
    setShowFinancial(false)
    setShowProcessFinancial(false)
    setShowTreasury(false)
    setShowProcessTreasury(false)
    setShowManagerial(false)
    setShowProcessManagerial(false)
    setShowBilling(false)
    setShowProcessBilling(false)
    setShowCashierControl(false)
    setShowProcessCashierControl(false)
    setShowFiscal(false)
    setShowProcessFiscal(false)
    setShowRequisition(false)
    setShowProcessRequisition(false)
    setShowExit(false)
  }

  const previousModule = () => {
    setShowTelemarketing(true)
    setShowRh(true)
    setShowStock(true)
    setShowFinancial(true)
    setShowTreasury(true)
    setShowManagerial(true)
    setShowBilling(true)
    setShowCashierControl(true)
    setShowFiscal(true)
    setShowRequisition(true)
    setShowExit(true)

    setShowProcessTelemarketing(false)
    setShowProcessRh(false)
    setShowProcessStock(false)
    setShowProcessFinancial(false)
    setShowProcessTreasury(false)
    setShowProcessManagerial(false)
    setShowProcessBilling(false)
    setShowProcessCashierControl(false)
    setShowProcessFiscal(false)
    setShowProcessRequisition(false)
  }

  return(
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 5}}>
      <div style={(showMenu) ? (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column", color: "white", marginBottom: 5} :
        {width: 270, color: "white"} : {width: 0}}>
        <div style={{display: "flex", flexGrow: 1, justifyContent: "flexStart", alignItems: "center", backgroundColor: "rgb(0, 177, 171)", 
          height: 74, paddingLeft: 10}}>
          <img 
            src={img_menu}
            alt="Retrair" 
            style={(width < 360) ? {height: "auto", width: 30} : {height: "auto", width: 35}}
            onClick={(() => { setShowMenu(false) })} />
        </div>

        { showTelemarketing &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessTelemarketing ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50} : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
              marginRight: 5, height: 50}}>
              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 28, width: 23}}
                  src={ showProcessTelemarketing ? img_telemarketingWhite : img_telemarketing }
                  alt="Telemarketing"
                  onClick={(() => { callTelemarketing() })} />
              </div>
              <div style={showProcessTelemarketing ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white", 
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callTelemarketing}>Telemarketing</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessTelemarketing &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                {/* { ((arrayPermission[0] === "T") || (arrayPermissionGroup[0] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/country">País</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[1] === "T") || (arrayPermissionGroup[1] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/state">Estado</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[2] === "T") || (arrayPermissionGroup[2] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/city">Cidade</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[3] === "T") || (arrayPermissionGroup[3] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/neighborhood">Bairro</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[4] === "T") || (arrayPermissionGroup[4] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/address">Logradouro</Link>
                      </div>
                    </div>
                  </div>
                } */}

                { ((arrayPermission[5] === "T") || (arrayPermissionGroup[5] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/clientClass">Classe do Cliente</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[6] === "T") || (arrayPermissionGroup[6] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/clientType">Tipo do Cliente</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((jsonCompany.TYPE) === 7) &&
                    ((arrayPermission[7] === "T") || (arrayPermissionGroup[7] === "T")) &&

                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                    borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                    height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/branch">Ramo do Cliente</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[8] === "T") || (arrayPermissionGroup[8] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/clientRoute">Rota do Cliente</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[9] === "T") || (arrayPermissionGroup[9] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/client">Cliente</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[10] === "T") || (arrayPermissionGroup[10] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/carrier">Transportadora</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Relatório</label>
                  </div>
                </div>

                { ((jsonCompany.TYPE) === 1) &&
                  ((arrayPermission[11] === "T") || (arrayPermissionGroup[11] === "T")) &&
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <div style={{height: 5}} />
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                        borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                        height: 40}}>
                        <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                          <Link style={{textDecoration: "none", fontWeight: 600}} to="/reportExpirationClientBillet">Vencimento Boleto X Cliente</Link>
                        </div>
                      </div>
                    </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showRh &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessRh ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 29, width: 29}}
                  src={ showProcessRh ? img_rhWhite : img_rh }
                  alt="Recursos Humanos"
                  onClick={(() => { callRh() })} />
              </div>
              <div style={showProcessRh ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callRh}>Recursos Humanos</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessRh &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[12] === "T") || (arrayPermissionGroup[12] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/rhSection">Setor</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[13] === "T") || (arrayPermissionGroup[13] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/rhPosition">Cargo</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[14] === "T") || (arrayPermissionGroup[14] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/rhShift">Turno</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[15] === "T") || (arrayPermissionGroup[15] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/employee">Funcionário</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }
        
        { showStock &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessStock ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 28, width: 28}}
                  src={ showProcessStock ? img_stockWhite : img_stock }
                  alt="Estoque"
                  onClick={(() => { callStock() })} />
              </div>
              <div style={showProcessStock ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callStock}>Estoque</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessStock &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[16] === "T") || (arrayPermissionGroup[16] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/supplier">Fornecedor</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[17] === "T") || (arrayPermissionGroup[17] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/manufacturer">Fabricante</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[18] === "T") || (arrayPermissionGroup[18] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/unit">Unidade</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[19] === "T") || (arrayPermissionGroup[19] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/department">Departamento</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[20] === "T") || (arrayPermissionGroup[20] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/box">Box</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[21] === "T") || (arrayPermissionGroup[21] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/stock">Estoque</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[22] === "T") || (arrayPermissionGroup[22] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                        <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                          <Link style={{textDecoration: "none", fontWeight: 600}} to="/product">Produto</Link>
                        </div>
                      </div>
                  </div>
                }

                { ((arrayPermission[66] === "T") || (arrayPermissionGroup[66] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                        <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                          <Link style={{textDecoration: "none", fontWeight: 600}} to="/priceTable">Tabela Preço</Link>
                        </div>
                      </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Processo</label>
                  </div>
                </div>

                { ((arrayPermission[22] === "T") || (arrayPermissionGroup[22] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                        <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                          <Link style={{textDecoration: "none", fontWeight: 600}} to="/searchPriceMarkdown">Remarcação de Preço</Link>
                        </div>
                      </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showFinancial &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessFinancial ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 25, width: 29}}
                  src={ showProcessFinancial ? img_financialWhite : img_financial }
                  alt="Financeiro"
                  onClick={(() => { callFinancial() })} />
              </div>
              <div style={showProcessFinancial ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callFinancial}>Financeiro</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessFinancial &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[23] === "T") || (arrayPermissionGroup[23] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/documentType">Tipo do documento</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[24] === "T") || (arrayPermissionGroup[24] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/paymentForm">Forma de pagamento</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Processo</label>
                  </div>
                </div>

                { ((arrayPermission[25] === "T") || (arrayPermissionGroup[25] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/billPay">Conta a pagar</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[26] === "T") || (arrayPermissionGroup[26] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/bordereauPay">Borderô a pagar</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[27] === "T") || (arrayPermissionGroup[27] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/dapPay">DAP a pagar</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[28] === "T") || (arrayPermissionGroup[28] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/billReceive">Conta a receber</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[29] === "T") || (arrayPermissionGroup[29] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/bordereauReceive">Borderô a receber</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[30] === "T") || (arrayPermissionGroup[30] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/dapReceive">DAP a receber</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[31] === "T") || (arrayPermissionGroup[31] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/billetLot">Lote do Boleto</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[25] === "T") || (arrayPermissionGroup[25] === "T")) && jsonCompany.INTEGRATE_SGE === 1 &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/billPayImport">Importar Conta a pagar</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[28] === "T") || (arrayPermissionGroup[28] === "T")) && jsonCompany.INTEGRATE_SGE === 1 &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/billReceiveImport">Importar Conta a receber</Link>
                      </div>
                    </div>
                  </div>
                }                

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Relatório</label>
                  </div>
                </div>

                { ((arrayPermission[32] === "T") || (arrayPermissionGroup[32] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/reportBillPay">Conta a pagar</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[33] === "T") || (arrayPermissionGroup[33] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/reportBillReceive">Conta a receber</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showTreasury &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessTreasury ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 21, width: 31}}
                  src={ showProcessTreasury ? img_treasuryWhite : img_treasury }
                  alt="Tesouraria"
                  onClick={(() => { callTreasury() })} />
              </div>
              <div style={showProcessTreasury ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callTreasury}>Tesouraria</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessTreasury &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[34] === "T") || (arrayPermissionGroup[34] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/bank">Banco</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[35] === "T") || (arrayPermissionGroup[35] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/agency">Agência</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[36] === "T") || (arrayPermissionGroup[36] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/account">Conta</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[37] === "T") || (arrayPermissionGroup[37] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/accountGroup">Grupo de Conta</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[38] === "T") || (arrayPermissionGroup[38] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/accountPlan">Plano de Conta</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Processo</label>
                  </div>
                </div>

                { ((arrayPermission[39] === "T") || (arrayPermissionGroup[39] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/accountTransfer">Transferência entre Contas</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Relatório</label>
                  </div>
                </div>

                { ((arrayPermission[40] === "T") || (arrayPermissionGroup[40] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/reportBankStatement">Extrato da Conta</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[41] === "T") || (arrayPermissionGroup[41] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/reportAccountBalance">Saldo da Conta</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }
                  
          </div>
        }

        { showManagerial &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessManagerial ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 24, width: 29}}
                  src={ showProcessManagerial ? img_managerialWhite : img_managerial }
                  alt="Gerencial"
                  onClick={(() => { callManagerial() })} />
              </div>
              <div style={showProcessManagerial ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callManagerial}>Gerencial</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessManagerial &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[42] === "T") || (arrayPermissionGroup[42] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/establishment">Estabelecimento</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[43] === "T") || (arrayPermissionGroup[43] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/company">Filial</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[44] === "T") || (arrayPermissionGroup[44] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/accounting">Contabilidade</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((jsonCompany.TYPE) === 1) &&
                    ((arrayPermission[7] === "T") || (arrayPermissionGroup[7] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/participant">Participante</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[45] === "T") || (arrayPermissionGroup[45] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/digitalCertificate">Certificado Digital</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Processo</label>
                  </div>
                </div>

                { ((arrayPermission[46] === "T") || (arrayPermissionGroup[46] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/mentesPensam">Mentes que Pensam</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[46] === "T") || (arrayPermissionGroup[46] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/bancoBrasil">Banco do Brasil</Link>
                      </div>
                    </div>
                  </div>
                }                

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showBilling &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessBilling ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 28, width: 28}}
                  src={ showProcessBilling ? img_billingWhite : img_billing }
                  alt="Faturamento"
                  onClick={(() => { callBilling() })} />
              </div>
              <div style={showProcessBilling ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callBilling}>Faturamento</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessBilling &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>
              
                { ((arrayPermission[48] === "T") || (arrayPermissionGroup[48] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/registerTable">Mesa</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Processo</label>
                  </div>
                </div>

                { ((arrayPermission[47] === "T") || (arrayPermissionGroup[47] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/noteService">Nota Fiscal de Serviço</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[48] === "T") || (arrayPermissionGroup[48] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/centralTables">Central das Mesas</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[48] === "T") || (arrayPermissionGroup[48] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/launchRestaurant">Lançamento Restaurante</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Relatório</label>
                  </div>
                </div>

                { ((arrayPermission[40] === "T") || (arrayPermissionGroup[40] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/generateQrCodeTable">Gerar QrCode Mesa</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showCashierControl &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessCashierControl ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 28, width: 28}}
                  src={ showProcessCashierControl ? img_cashierControlWhite : img_cashierControl }
                  alt="Controle de Caixa"
                  onClick={(() => { callCashierControl() })} />
              </div>
              <div style={showProcessCashierControl ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callCashierControl}>Controle de Caixa</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessCashierControl &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[49] === "T") || (arrayPermissionGroup[49] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/finisher">Finalizadora</Link>
                      </div>
                    </div>
                  </div>
                }
                  
                { ((arrayPermission[50] === "T") || (arrayPermissionGroup[50] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/terminal">Terminal</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showFiscal &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessFiscal ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 27, width: 27}}
                  src={ showProcessFiscal ? img_fiscalWhite : img_fiscal }
                  alt="Fiscal"
                  onClick={(() => { callFiscal() })} />
              </div>
              <div style={showProcessFiscal ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callFiscal}>Fiscal</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessFiscal &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[51] === "T") || (arrayPermissionGroup[51] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/aliquot">Alíquota</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[52] === "T") || (arrayPermissionGroup[52] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/anp">ANP</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[53] === "T") || (arrayPermissionGroup[53] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cest">CEST</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[54] === "T") || (arrayPermissionGroup[54] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/ncm">NCM</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[55] === "T") || (arrayPermissionGroup[55] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cstIpi">CST IPI</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[56] === "T") || (arrayPermissionGroup[56] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cstPis">CST PIS</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[57] === "T") || (arrayPermissionGroup[57] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cstCofins">CST COFINS</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[58] === "T") || (arrayPermissionGroup[58] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cfop">CFOP</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[59] === "T") || (arrayPermissionGroup[59] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/cstCsosn">CST/CSOSN</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[60] === "T") || (arrayPermissionGroup[60] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/fisClass">Classe Fiscal</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[61] === "T") || (arrayPermissionGroup[61] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/typeTaxOperation">Tipo Operação Fiscal</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[62] === "T") || (arrayPermissionGroup[62] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/nfModel">Modelo</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[63] === "T") || (arrayPermissionGroup[63] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/serviceCNAE">CNAE Serviço</Link>
                      </div>
                    </div>
                  </div>
                }

                { ((arrayPermission[64] === "T") || (arrayPermissionGroup[64] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/taxDefault">Imposto Padrão</Link>
                      </div>
                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }

          </div>
        }

        { showRequisition &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={showProcessRequisition ? {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",
              borderLeftWidth: 6, borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(0, 177, 171)", marginLeft: 5,
              marginRight: 5, height: 50}
              : {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 21, width: 25}}
                  src={ showProcessRequisition ? img_requisitionWhite : img_requisition }
                  alt="Requisição"
                  onClick={(() => { callRequisition() })} />
              </div>
              <div style={showProcessRequisition ? {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "white",
                marginLeft: 10} : {display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={callRequisition}>Requisição</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>

            { showProcessRequisition &&
              <>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", borderRightWidth: 6,
                    borderRightStyle: "solid", borderRightColor: "rgb(0, 177, 171)", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5,
                    marginRight: 5, height: 40}}>
                    <label style={{paddingRight: 10, color: "rgb(0, 177, 171)", fontWeight: 600}}>Cadastro</label>
                  </div>
                </div>

                { ((arrayPermission[65] === "T") || (arrayPermissionGroup[65] === "T")) &&
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: 5}} />
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 6,
                      borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
                      height: 40}}>
                      <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                        <Link style={{textDecoration: "none", fontWeight: 600}} to="/printerSection">Impressora Setor</Link>
                      </div>
                    </div>
                  </div>                            
                }

                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{height: 5}} />
                  <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 0}}>
                    <img style={{height: 38, width: 38}} src={img_arrow} alt="Retornar" onClick={(() => { previousModule() })} />
                  </div>
                </div>
              </>
            }
                
          </div>
        }

        { showExit &&
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: 5}} />
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeftWidth: 6,
              borderLeftStyle: "solid", borderLeftColor: "black", backgroundColor: "rgb(241, 242, 242)", marginLeft: 5, marginRight: 5,
              height: 50}}>

              <div style={{display: "flex", flexGrow: 0, paddingLeft: 10}}>
                <img
                  style={{height: 21, width: 25}}
                  src={img_exit}
                  alt="Sair"
                  onClick={(() => { logoff() })} />
              </div>
              <div style={{display: "flex", flexGrow: 1, marginRight: 5, fontWeight: 600, color: "black", marginLeft: 10}}>
                <label onClick={logoff}>Sair</label>
              </div>
              <div>
                <img style={{marginTop: 6, height: 50, width: 11}} src={img_shadow} alt="Sombra" />
              </div>
            </div>
          </div>
        }

      </div>
    </div>    
  )
}

export default Menu