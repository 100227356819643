import "./style.css"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams, useNavigate } from "react-router-dom"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import SaleQuantity from "../SaleQuantity"
import ShowItensTable from "../ShowItensTable"
import AdditionDiscount from "../AdditionDiscount"

import image_loading from "../../Images/spinner.gif";
import image_logo from "../../Images/logo_rodape.png"
import image_search from "../../Images/Buttons/lupa.png"
import image_clean from "../../Images/clean.png"
import image_noPhoto from "../../Images/sem-foto.png"

const api = axios.create({
    baseURL: "https://localhost:5001/api/",
    headers: {                
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=utf-8"
    }
  })

const TableItensTouch = ({typeDatabase, pathDatabase, jsonCompany, jsonLogin, system, logoff, height, width, setReturn}) => {
  const { numberTable } = useParams()

  const [firstAcess, setFirstAcess] = useState(true)

  const [returnDepartments, setReturnDepartments] = useState("[]")
  const [departments, setDepartments] = useState("[]")

  const [products, setProducts] = useState("[]")

  const [capa, setCapa] = useState({})
  const [item, setItem] = useState({})
  const [itens, setItens] = useState([])

  const [typeFilterProduct, setTypeFilterProduct] = useState("1")
  const [filterProduct, setFilterProduct] = useState("")
  
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [showAdditionDiscount, setShowAdditionDiscount] = useState(false)
  const [itemAdditionDiscount, setItemAdditionDiscount] = useState({})

  const [showFilter, setShowFilter] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showQuantity, setShowQuantity] = useState(false)
  
  useEffect(() => {
    var returnJson = []
    var jFil = JSON.parse(jsonCompany)

    if (firstAcess){
      var arrDepartment = "["

      // Search the company's product departments of products the company
      api.get("PRODUTO_FILIAL/PesquisarDepartamentos?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase +
        "&jsonProdutoFilial={ \"FILIAL\": { \"ID\": "+ jFil.ID +" }}&jsonLogin=" + jsonLogin + "&bSistema=" + system)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os departamentos dos produtos ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.CONCRETIZADO === false){
          alert("Não foi possível obter os departamentos dos produtos. Motivo:"+ returnJson.ERRO)
          navigate("/")
          return
        }
        else
        {
            returnJson.RESULTADO && returnJson.RESULTADO.map(item => 
            (arrDepartment === "[") ? arrDepartment += prepareDepartment(item)
            : arrDepartment += "," + prepareDepartment(item)
          )

          arrDepartment += "]"
          setReturnDepartments(arrDepartment)
          reloadDepartment(arrDepartment)
        }
      })

      setFirstAcess(false)
    }

    capaNew()

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()
  }, [])

  const callAdditionDiscount = (item) => {
    console.log("Victor:")
    console.log(item)

    setItemAdditionDiscount(item)
    setShowAdditionDiscount(true)
  }

  const confirmAdditionDiscount = (item) => {
    console.log("asdadlaksjdalksdj:")
    console.log(item)



    

    let vContabil = 0

    let aItens = itens
    let oCapa  = capa

    for(let i = 0; i < aItens.length; i++){
      if (aItens[i].ITEM === item.ITEM){
        aItens[i].TIPO_DESCONTO = item.TIPO_DESCONTO
        aItens[i].PC_DESCONTO_COMPOSICAO = item.PC_DESCONTO_COMPOSICAO
        aItens[i].VL_DESCONTO_COMPOSICAO = item.VL_DESCONTO_COMPOSICAO
        aItens[i].VL_DESCONTO = item.VL_DESCONTO
        
        aItens[i].TIPO_ACRESCIMO = item.TIPO_ACRESCIMO
        aItens[i].PC_ACRESCIMO_COMPOSICAO = item.PC_ACRESCIMO_COMPOSICAO
        aItens[i].VL_ACRESCIMO_COMPOSICAO = item.VL_ACRESCIMO_COMPOSICAO
        aItens[i].VL_OUTRAS_DESPESAS = item.VL_OUTRAS_DESPESAS
        
        aItens[i].VL_DESCONTO = aItens[i].VL_DESCONTO_COMPOSICAO
        aItens[i].VL_OUTRAS_DESPESAS = aItens[i].VL_ACRESCIMO_COMPOSICAO
        aItens[i].VL_CONTABIL = parseFloat(formatNumeric(((aItens[i].VL_BRUTO + aItens[i].VL_ACRESCIMO_COMPOSICAO) - aItens[i].VL_DESCONTO_COMPOSICAO),2))

        vContabil += parseFloat(formatNumeric((vContabil + aItens[i].VL_CONTABIL),2))
      }
      else vContabil += parseFloat(formatNumeric((vContabil + aItens[i].VL_CONTABIL),2))      
    }

    oCapa.VL_CONTABIL = vContabil
    setItens(aItens)
    setCapa(oCapa)

    console.log("CAPA:")
    console.log(oCapa)

    console.log("ITENS:")
    console.log(aItens)
    
    setItemAdditionDiscount({})
    setShowAdditionDiscount(false)
  }

  const returnAdditionDiscount = () => {
    setItemAdditionDiscount({})
    setShowAdditionDiscount(false)    
  }

  function deleteItem(item){
    var dateServer = new Date()
    var day = String(dateServer.getDate()).padStart(2, "0")
    var month = String(dateServer.getMonth() + 1).padStart(2, "0")
    var year = dateServer.getFullYear()
    dateServer = new Date(year + "-" + month + "-" + day + "T00:00:00")

    var valueRemoveAccounting = 0

    var arrNew = itens
    for(let i = 0; i < arrNew.length; i++) {
      if (arrNew[i].ITEM === item.ITEM){
        arrNew[i].DATA_CANCELAMENTO = dateServer
        arrNew[i].FUNCIONARIO_CANCELAMENTO = JSON.parse("{\"NOME\":\""+ jsonLogin.NOME +"\"}")

        valueRemoveAccounting = parseFloat(formatNumeric(valueRemoveAccounting + arrNew[i].VL_CONTABIL, 2))
      }
    }

    capa.VL_CONTABIL = parseFloat(formatNumeric(capa.VL_CONTABIL - valueRemoveAccounting, 2))
    setItens(arrNew)

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()    
  }

  function cancelQuantity(){
    setShowQuantity(false)

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()    
  }

  const searchProduct = () => {
    var returnJson = []
    var jFil = JSON.parse(jsonCompany)

    if (filterProduct === ""){
      alert("É necessário informar um filtro para pesquisa.")

      document.getElementById("filterProduct") &&
        document.getElementById("filterProduct").focus()

      return
    }

    if ((typeFilterProduct === "2") || (typeFilterProduct === "3")){
      if (filterProduct.length <= 2) {
        alert("É necessário informar ao menos 3 caracteres para pesquisa.")

        document.getElementById("filterProduct") &&
          document.getElementById("filterProduct").focus()

        return
      }
    }

    let jsonProduct = "{ \"FILIAL\": { \"ID\": "+ jFil.ID +" }, \"PRODUTO\": {"

    if (typeFilterProduct === "1"){
      if (filterProduct.length < 7) jsonProduct += " \"ID\": "+ filterProduct
      else jsonProduct += " \"BARRAS_TRIBUTAVEL\": \""+ filterProduct +"\""
    }

    if (typeFilterProduct === "2") jsonProduct += " \"DESCRICAO\": \""+ filterProduct +"\""
    if (typeFilterProduct === "3") jsonProduct += " \"REFERENCIA\": \""+ filterProduct +"\""
    jsonProduct += " } }"

    setLoading(true)

    api.get("PRODUTO_FILIAL/PesquisarProdutoTerminal?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase +
        "&jsonProdutoFilial="+ jsonProduct + "&jsonLogin=" + jsonLogin + "&bSistema=" + system)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os departamentos dos produtos ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.CONCRETIZADO === false){
        alert("Não foi possível obter os produtos. Motivo:"+ returnJson.ERRO)
        setLoading(false)

        document.getElementById("filterProduct") &&
          document.getElementById("filterProduct").focus()

        return
      }
      else
      {
        var arrProduct = "["
        returnJson.RESULTADO && returnJson.RESULTADO.map(item => 
          (arrProduct === "[") ? arrProduct += prepareProduct(item)
          : arrProduct += "," + prepareProduct(item)
        )
        arrProduct += "]"

        setProducts(arrProduct)
        setLoading(false)
      }
    })

    setShowSearch(true)

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()    
  }

  function setFieldSelected(){
  }  

  const cleanSearchProduct = () => {
    setTypeFilterProduct("1")
    setFilterProduct("")

    reloadDepartment(returnDepartments)
    setShowFilter(false)
    setShowSearch(false)

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()    
  }

  function callDepartment(dep){
    var jFil = JSON.parse(jsonCompany)

    setDepartments("[]")
    setProducts("[]")

    var arrDepartments = "["

    if (dep.ID === 1001){
      if (dep.PAI_ANTIGO === undefined) {
        reloadDepartment(returnDepartments)
        return
      }
      else {
        arrDepartments += "{ \"ID\": 1001, \"DESCRICAO\": \"VOLTAR\""

        var depFather = ""
        { returnDepartments && JSON.parse(returnDepartments).map(department =>
            (department.ID === dep.PAI_ANTIGO.ID) ?
            depFather = department
            : ""
          )
        }

        if (depFather.PAI !== undefined) arrDepartments += ", \"PAI_ANTIGO\": { \"ID\": "+ depFather.PAI.ID + " }"
        arrDepartments += " }"

        { returnDepartments && JSON.parse(returnDepartments).map(department =>
          (department.PAI !== undefined) && (department.PAI.ID === dep.PAI_ANTIGO.ID) ? arrDepartments += ","+ prepareDepartment(department)
            : arrDepartments += ""
          )
        }
        arrDepartments += "]"
        setDepartments(arrDepartments)
      }
    }
    else {
      // Button return
      arrDepartments += "{ \"ID\": 1001, \"DESCRICAO\": \"VOLTAR\""
      if (dep.PAI !== undefined) arrDepartments += ", \"PAI_ANTIGO\": { \"ID\": "+ dep.PAI.ID + " } "
      arrDepartments += "}"

      { returnDepartments && JSON.parse(returnDepartments).map(department =>
        (department.PAI !== undefined) &&
          (department.PAI.ID === dep.ID) ?
            arrDepartments += ","+ prepareDepartment(department)
           : arrDepartments += ""
        )
      }

      arrDepartments += "]"
      setDepartments(arrDepartments)

      var returnJson = ""

      // Search products
      api.get("PRODUTO_FILIAL/PesquisarDepartamentoTerminal?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase +
        "&jsonProdutoFilial={ \"FILIAL\": { \"ID\": "+ jFil.ID +" }, \"PRODUTO\": { \"DEPARTAMENTO\": { \"ID\": "+ dep.ID +" } } }"+
          "&jsonLogin=" + jsonLogin + "&bSistema=" + system)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar os departamentos dos produtos ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.CONCRETIZADO === false){
          alert("Não foi possível obter os produtos. Motivo:"+ returnJson.ERRO)

          document.getElementById("filterProduct") &&
            document.getElementById("filterProduct").focus()

          return
        }
        else
        {
          var arrProduct = "["

          returnJson.RESULTADO && returnJson.RESULTADO.map(item => 
            (arrProduct === "[") ? arrProduct += prepareProduct(item)
            : arrProduct += "," + prepareProduct(item)
          )

          arrProduct += "]"
          setProducts(arrProduct)
        }
      })
    }

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()
  }

  function callProduct(product){
    var returnJson = ""

    // Search the compositions
    var arrCompositions = "["
    api.get("PRODUTO_COMPOSICAO/PesquisarVenda?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase +
        "&jsonProdutoComposicao={ \"PRODUTO_FILIAL_O\": { \"ID\": "+ product.ID +" } }&jsonLogin=" + jsonLogin + "&bSistema=" + system)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as composições do produto ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.CONCRETIZADO === false){
        alert("Não foi possível obter as composições do produto. Motivo:"+ returnJson.ERRO)

        document.getElementById("filterProduct") &&
          document.getElementById("filterProduct").focus()

        return
      }
      else
      {
        returnJson.RESULTADO &&
          returnJson.RESULTADO.map(item => 
            (arrCompositions === "[") ? arrCompositions += prepareComposition(item)
              : arrCompositions += "," + prepareComposition(item)
          )
        arrCompositions += "]"

        var arrOptionsCompositions = "{ \"RESULTADO\": [{\"ID\":\"0\",\"VALOR\":\"Selecione...\"}"
        returnJson.RESULTADO &&
            returnJson.RESULTADO.map(item => 
                arrOptionsCompositions += "," + prepareCompositionOption(item)
            )
        arrOptionsCompositions += "]}"

        // Search the additions
        var arrAdditions = "["
        api.get("PRODUTO_ACRESCIMO/PesquisarVenda?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase +
          "&jsonProdutoAcrescimo={ \"PRODUTO_FILIAL_O\": { \"ID\": "+ product.ID +" } }&jsonLogin=" + jsonLogin + "&bSistema=" + system)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível obter as composições do produto ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.CONCRETIZADO === false){
            alert("Não foi possível obter os acréscimos do produto. Motivo:"+ returnJson.ERRO)
            return
          }
          else
          {
            returnJson.RESULTADO &&
            returnJson.RESULTADO.map(item => 
                (arrAdditions === "[") ? arrAdditions += prepareAddition(item)
                  : arrAdditions += "," + prepareAddition(item)
              )    
            arrAdditions += "]"

            setItem(prepareItem(product, arrCompositions, arrOptionsCompositions, arrAdditions))
            setShowSearch(false)
            setShowQuantity(true)
          }
        })
      }
    })

    document.getElementById("filterProduct") &&
      document.getElementById("filterProduct").focus()    
  }

  function formatNumeric(value, decimals) {
    if (!value) {
      return "0.00"
    }

    if (value === ".") {
      return value = "0."
    }
  
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0.00"
    }

    return parsed
  }

  function formatFilterProduct(filter){
    var texto = filter

    if (typeFilterProduct === "1")
      texto = String(texto).replace("-","").replace("+","").replace("*","").replace("/","").replace(".","").replace(",","").replace("(","").replace(")","").replace("=","").replace("#","")

    setFilterProduct(texto)
  }

  function insertItem(itensReleased){
    let valueAddAccounting = 0

    for(let i = 0; i < itensReleased.length; i++) {     
      valueAddAccounting = parseFloat(formatNumeric(valueAddAccounting + itensReleased[i].VL_CONTABIL, 2))
    }

    setItens(prepareItens(itens, itensReleased))
    capa.VL_CONTABIL = parseFloat(formatNumeric(capa.VL_CONTABIL + valueAddAccounting, 2))

    setTypeFilterProduct("1")
    setFilterProduct("")
    setShowQuantity(false)
    reloadDepartment(returnDepartments)

    document.getElementById("filterProduct") && document.getElementById("filterProduct").focus()    
  }

  function capaNew(){
    var jFil = JSON.parse(jsonCompany)

    let jsonCapa  = "{"
    jsonCapa     += "\"CADASTRO_MESA\":{\"NUMBER\":"+ numberTable
    jsonCapa     += ",\"COMPANY\":{\"ID\":"+ jFil.ID +",\"CPF_CNPJ\":\""+ jFil.CPF_CNPJ +"\",\"NOME_RAZAO\":\""+ jFil.NOME_RAZAO +"\"" +
    ",\"NOME_FANTASIA\":\""+ jFil.NOME_FANTASIA +"\",\"CEP\":\""+ jFil.CEP + "\",\"LOGRADOURO\":\""+ jFil.LOGRADOURO +
    "\",\"NUMERO\":\""+ jFil.NUMERO +"\",\"BAIRRO\":\""+ jFil.BAIRRO +"\"" + ",\"CIDADE\":\""+ jFil.CIDADE +
    "\",\"CIDADE_IBGE\":"+ jFil.CIDADE_IBGE +",\"UF\":\""+ jFil.UF +"\",\"UF_IBGE\":"+ jFil.UF_IBGE + ",\"PAIS_ID\": "+ jFil.PAIS_ID +
    ",\"PAIS_DESCRICAO\":\""+ jFil.PAIS_DESCRICAO + "\",\"PAIS_IBGE\":"+ jFil.PAIS_IBGE +",\"TELEFONE_PADRAO\":\""+ jFil.TELEFONE_PADRAO +
    "\",\"IE\":\""+ jFil.IE +"\"" + ",\"CULTURA\":"+ jFil.CULTURA +",\"REGIME\":"+ jFil.REGIME +",\"PC_COMISSAO\":"+ jFil.PC_COMISSAO +
    ",\"ALIQUOTA_PIS\":"+ jFil.ALIQUOTA_PIS + ",\"ALIQUOTA_COFINS\":"+ jFil.ALIQUOTA_COFINS
    if (jFil.COMPLEMENTO !== null) jsonCapa += ",\"COMPLEMENTO\":\""+ jFil.COMPLEMENTO +"\""
    if (jFil.CNAE !== null) jsonCapa += ",\"CNAE\":"+ jFil.CNAE
    jsonCapa     += "}},\"VL_CONTABIL\":0.00,\"MODALIDADE_FRETE\":9,\"SITUACAO\":2}"

    setCapa(JSON.parse(jsonCapa))
  }

  function prepareDepartment(department){
    var jDepartment = "{\"ID\": "+ department.ID +
    ",\"HIERARQUIA\": \""+ department.HIERARQUIA + "\"" +
    ",\"DESCRICAO\": \""+ department.DESCRICAO + "\"" +
    ",\"DESCRICAO_RESUMIDA\": \""+ department.DESCRICAO_RESUMIDA + "\""

    if (department.PAI !== undefined)
      if ((department.PAI !== null) && (department.PAI !== 0))
        jDepartment += ",\"PAI\": { \"ID\": "+ department.PAI.ID + " }"

    jDepartment += ",\"COMISSAO\": "+ department.COMISSAO +
    ",\"PERIODICO\": \""+ department.PERIODICO + "\"" +
    ",\"APARECER_VENDA\": \""+ department.APARECER_VENDA + "\""

    if (department.CAMINHO_IMAGEM !== undefined)
      if ((department.CAMINHO_IMAGEM !== null) && (department.CAMINHO_IMAGEM !== ""))
        jDepartment += ",\"CAMINHO_IMAGEM\": \""+ department.CAMINHO_IMAGEM + "\""

    jDepartment += ",\"SITUACAO\": \""+ department.SITUACAO + "\"}"
    return jDepartment
  }

  function prepareAddition(addition){
    var jLogin = JSON.parse(jsonLogin)

    var jAddition = "{\"ID\":"+ addition.ID + ",\"FUNCIONARIO_LANCAMENTO\": {\"NOME\":\"" + jLogin.NOME + "\"}" +
    ",\"FUNCIONARIO_VENDEDOR\": {\"NOME\":\"" + jLogin.NOME + "\"}"

    jAddition += ",\"PRODUTO_FILIAL_D\":{\"ID\":" + addition.PRODUTO_FILIAL_D.ID
    jAddition += ",\"PRODUTO\":{\"ID\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.ID
    jAddition += ",\"BARRAS_COMERCIAL\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.BARRAS_COMERCIAL +"\""
    jAddition += ",\"BARRAS_TRIBUTAVEL\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.BARRAS_TRIBUTAVEL +"\""
    jAddition += ",\"DESCRICAO\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.DESCRICAO +"\""    
    jAddition += ",\"DESCRICAO_RESUMIDA\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.DESCRICAO_RESUMIDA +"\""
    jAddition += ",\"TIPO\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.TIPO
    jAddition += ",\"ORIGEM\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.ORIGEM

    if (addition.PRODUTO_FILIAL_D.PRODUTO.FOTO !== null)
      if ((addition.PRODUTO_FILIAL_D.PRODUTO.FOTO !== null) && (addition.PRODUTO_FILIAL_D.PRODUTO.FOTO !== "")) jAddition += ",\"FOTO\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.FOTO +"\""

    jAddition += ",\"UNIDADE_TRIBUTAVEL\":{\"ID\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_TRIBUTAVEL.ID +",\"SIGLA\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_TRIBUTAVEL.SIGLA +"\",\"PESAVEL\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_TRIBUTAVEL.PESAVEL +"}"
    jAddition += ",\"UNIDADE_COMERCIAL\":{\"ID\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_COMERCIAL.ID +",\"SIGLA\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_COMERCIAL.SIGLA +"\",\"PESAVEL\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.UNIDADE_COMERCIAL.PESAVEL +"}"
    jAddition += ",\"DEPARTAMENTO\":{\"ID\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.DEPARTAMENTO.ID +",\"DESCRICAO\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.DEPARTAMENTO.DESCRICAO +"\",\"COMISSAO\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.DEPARTAMENTO.COMISSAO +"}"
    jAddition += ",\"TEMPO_PREPARO\":\""+ addition.PRODUTO_FILIAL_D.PRODUTO.TEMPO_PREPARO +"\""
    jAddition += ",\"TOTAL_FRACAO\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.TOTAL_FRACAO
    jAddition += ",\"PERMITE_OBS\":"+ addition.PRODUTO_FILIAL_D.PRODUTO.PERMITE_OBS
    jAddition += "}"

    if (addition.PRODUTO_FILIAL_D.RTS_IMPRESSORA !== null)
      if (addition.PRODUTO_FILIAL_D.RTS_IMPRESSORA.ID !== 0) 
        jAddition += ",\"RTS_IMPRESSORA\":{\"ID\":"+ addition.PRODUTO_FILIAL_D.RTS_IMPRESSORA.ID +"}"

    jAddition += ",\"PC_COMISSAO\":"+ addition.PRODUTO_FILIAL_D.PC_COMISSAO
    jAddition += ",\"PC_ACRESCIMO_MAX\":"+ addition.PRODUTO_FILIAL_D.PC_ACRESCIMO_MAX
    jAddition += ",\"PC_DESCONTO_MAX\":"+ addition.PRODUTO_FILIAL_D.PC_DESCONTO_MAX
    jAddition += ",\"PRECO_CUSTO\":"+ addition.PRODUTO_FILIAL_D.PRECO_CUSTO
    jAddition += ",\"PRECO_VENDA\":"+ addition.PRODUTO_FILIAL_D.PRECO_VENDA

    jAddition += ",\"NCM\":{\"CODIGO\":\""+ addition.PRODUTO_FILIAL_D.NCM.CODIGO + "\"" +
    ",\"CST_PIS_S\":{\"CODIGO\":\""+ addition.PRODUTO_FILIAL_D.NCM.CST_PIS_S.CODIGO + "\"}" +
    ",\"PIS_ALIQUOTA_S\":"+ addition.PRODUTO_FILIAL_D.NCM.PIS_ALIQUOTA_S + 
    ",\"PIS_NATUREZA_S\":\""+ addition.PRODUTO_FILIAL_D.NCM.PIS_NATUREZA_S + "\"" +
    ",\"CST_COFINS_S\":{\"CODIGO\":\""+ addition.PRODUTO_FILIAL_D.NCM.CST_COFINS_S.CODIGO + "\"}" +
    ",\"COF_ALIQUOTA_S\":"+ addition.PRODUTO_FILIAL_D.NCM.COF_ALIQUOTA_S + 
    ",\"COF_NATUREZA_S\":\""+ addition.PRODUTO_FILIAL_D.NCM.COF_NATUREZA_S + "\"" +
    ",\"FCP\":"+ addition.PRODUTO_FILIAL_D.NCM.FCP + ",\"ALIQUOTA_FCP\":"+ addition.PRODUTO_FILIAL_D.NCM.ALIQUOTA_FCP +
    ",\"PC_IBPT_N\":"+ addition.PRODUTO_FILIAL_D.NCM.PC_IBPT_N + ",\"PC_IBPT_I\":"+ addition.PRODUTO_FILIAL_D.NCM.PC_IBPT_I +
    ",\"PC_IBPT_E\":"+ addition.PRODUTO_FILIAL_D.NCM.PC_IBPT_E + ",\"PC_IBPT_M\":"+ addition.PRODUTO_FILIAL_D.NCM.PC_IBPT_M + "}"

    jAddition += ",\"BASE_ST_RETIDO\":"+ addition.PRODUTO_FILIAL_D.BASE_ST_RETIDO
    jAddition += ",\"ALIQ_ST_RETIDO\":"+ addition.PRODUTO_FILIAL_D.ALIQ_ST_RETIDO
    jAddition += ",\"ALIQ_FCP_ST_RETIDO\":"+ addition.PRODUTO_FILIAL_D.ALIQ_FCP_ST_RETIDO

    if (addition.PRODUTO_FILIAL_D.ANP !== null)
      if (addition.PRODUTO_FILIAL_D.ANP.CODIGO !== null) 
        jAddition += ",\"ANP\":{\"CODIGO\":"+ addition.PRODUTO_FILIAL_D.ANP.CODIGO +",\"DESCRICAO\":\""+ addition.PRODUTO_FILIAL_D.ANP.DESCRICAO +"\"}"

    jAddition += ",\"PC_GLP\":"+ addition.PRODUTO_FILIAL_D.PC_GLP
    jAddition += ",\"PC_GNV\":"+ addition.PRODUTO_FILIAL_D.PC_GNV
    jAddition += ",\"PC_PART\":"+ addition.PRODUTO_FILIAL_D.PC_PART + "}"
    jAddition += ",\"QUANTIDADE\":"+ addition.QUANTIDADE +",\"QUANTIDADE_ESCOLHIDA\": 0,\"PRECO\":"+ addition.PRECO + "}"
    return jAddition
  }

  function prepareComposition(composition){
    var jComposicao = "{\"ID\":"+ composition.ID
    jComposicao += ",\"PRODUTO\":{\"ID\":"+ composition.PRODUTO_FILIAL_D.PRODUTO.ID
    jComposicao += ",\"DESCRICAO\":\""+ composition.PRODUTO_FILIAL_D.PRODUTO.DESCRICAO +"\""
    jComposicao += ",\"DESCRICAO_RESUMIDA\":\""+ composition.PRODUTO_FILIAL_D.PRODUTO.DESCRICAO_RESUMIDA +"\"}"
    jComposicao += ",\"QUANTIDADE\":"+ composition.QUANTIDADE + ",\"EXCLUIDO\": " + composition.EXCLUIDO + "}"
    return jComposicao
  }

  function prepareCompositionOption(composition){
    var jComposition = "{\"ID\":\""+ composition.ID +"\",\"VALOR\":\""+ composition.PRODUTO_FILIAL_D.PRODUTO.DESCRICAO +"\"}"
    return jComposition
  }

  function prepareProduct(product){
    var jCompanyProduct = "{\"ID\":" + product.ID
    jCompanyProduct += ",\"PRODUTO\":{\"ID\":"+ product.PRODUTO.ID
    jCompanyProduct += ",\"BARRAS_COMERCIAL\":\""+ product.PRODUTO.BARRAS_COMERCIAL +"\""
    jCompanyProduct += ",\"BARRAS_TRIBUTAVEL\":\""+ product.PRODUTO.BARRAS_TRIBUTAVEL +"\""
    jCompanyProduct += ",\"DESCRICAO\":\""+ product.PRODUTO.DESCRICAO +"\""    
    jCompanyProduct += ",\"DESCRICAO_RESUMIDA\":\""+ product.PRODUTO.DESCRICAO_RESUMIDA +"\""
    jCompanyProduct += ",\"TIPO\":"+ product.PRODUTO.TIPO
    jCompanyProduct += ",\"ORIGEM\":"+ product.PRODUTO.ORIGEM

    if (product.PRODUTO.FOTO !== null)
      if ((product.PRODUTO.FOTO !== null) && (product.PRODUTO.FOTO !== "")) jCompanyProduct += ",\"FOTO\":\""+ product.PRODUTO.FOTO +"\""

    jCompanyProduct += ",\"UNIDADE_TRIBUTAVEL\":{\"ID\":"+ product.PRODUTO.UNIDADE_TRIBUTAVEL.ID +",\"SIGLA\":\""+ product.PRODUTO.UNIDADE_TRIBUTAVEL.SIGLA +"\",\"PESAVEL\":"+ product.PRODUTO.UNIDADE_TRIBUTAVEL.PESAVEL +"}"
    jCompanyProduct += ",\"UNIDADE_COMERCIAL\":{\"ID\":"+ product.PRODUTO.UNIDADE_COMERCIAL.ID +",\"SIGLA\":\""+ product.PRODUTO.UNIDADE_COMERCIAL.SIGLA +"\",\"PESAVEL\":"+ product.PRODUTO.UNIDADE_COMERCIAL.PESAVEL +"}"
    jCompanyProduct += ",\"DEPARTAMENTO\":{\"ID\":"+ product.PRODUTO.DEPARTAMENTO.ID +",\"DESCRICAO\":\""+ product.PRODUTO.DEPARTAMENTO.DESCRICAO +"\",\"COMISSAO\":"+ product.PRODUTO.DEPARTAMENTO.COMISSAO +"}"
    jCompanyProduct += ",\"TEMPO_PREPARO\":\""+ product.PRODUTO.TEMPO_PREPARO +"\""
    jCompanyProduct += ",\"TOTAL_FRACAO\":"+ product.PRODUTO.TOTAL_FRACAO
    jCompanyProduct += ",\"PERMITE_OBS\":"+ product.PRODUTO.PERMITE_OBS
    jCompanyProduct += "}"

    if (product.RTS_IMPRESSORA !== null)
      if (product.RTS_IMPRESSORA.ID !== 0) 
      jCompanyProduct += ",\"RTS_IMPRESSORA\":{\"ID\":"+ product.RTS_IMPRESSORA.ID +"}"

    jCompanyProduct += ",\"PC_COMISSAO\":"+ product.PC_COMISSAO
    jCompanyProduct += ",\"PC_ACRESCIMO_MAX\":"+ product.PC_ACRESCIMO_MAX
    jCompanyProduct += ",\"PC_DESCONTO_MAX\":"+ product.PC_DESCONTO_MAX
    jCompanyProduct += ",\"PRECO_CUSTO\":"+ product.PRECO_CUSTO
    jCompanyProduct += ",\"PRECO_VENDA\":"+ product.PRECO_VENDA

    jCompanyProduct += ",\"NCM\":{\"CODIGO\":\""+ product.NCM.CODIGO + "\"" +
    ",\"CST_PIS_S\":{\"CODIGO\":\""+ product.NCM.CST_PIS_S.CODIGO + "\"}" +
    ",\"PIS_ALIQUOTA_S\":"+ product.NCM.PIS_ALIQUOTA_S + 
    ",\"PIS_NATUREZA_S\":\""+ product.NCM.PIS_NATUREZA_S + "\"" +
    ",\"CST_COFINS_S\":{\"CODIGO\":\""+ product.NCM.CST_COFINS_S.CODIGO + "\"}" +
    ",\"COF_ALIQUOTA_S\":"+ product.NCM.COF_ALIQUOTA_S + 
    ",\"COF_NATUREZA_S\":\""+ product.NCM.COF_NATUREZA_S + "\"" +
    ",\"FCP\":"+ product.NCM.FCP + ",\"ALIQUOTA_FCP\":"+ product.NCM.ALIQUOTA_FCP +
    ",\"PC_IBPT_N\":"+ product.NCM.PC_IBPT_N + ",\"PC_IBPT_I\":"+ product.NCM.PC_IBPT_I +
    ",\"PC_IBPT_E\":"+ product.NCM.PC_IBPT_E + ",\"PC_IBPT_M\":"+ product.NCM.PC_IBPT_M + "}"

    jCompanyProduct += ",\"BASE_ST_RETIDO\":"+ product.BASE_ST_RETIDO
    jCompanyProduct += ",\"ALIQ_ST_RETIDO\":"+ product.ALIQ_ST_RETIDO
    jCompanyProduct += ",\"ALIQ_FCP_ST_RETIDO\":"+ product.ALIQ_FCP_ST_RETIDO

    if (product.ANP !== null)
      if (product.ANP.CODIGO !== null) 
        jCompanyProduct += ",\"ANP\":{\"CODIGO\":"+ product.ANP.CODIGO +",\"DESCRICAO\":\""+ product.ANP.DESCRICAO +"\"}"

    jCompanyProduct += ",\"PC_GLP\":"+ product.PC_GLP
    jCompanyProduct += ",\"PC_GNV\":"+ product.PC_GNV
    jCompanyProduct += ",\"PC_PART\":"+ product.PC_PART + "}"
    return jCompanyProduct
  }

  function prepareItem(product, arrCompositions, arrOptionsCompositions, arrAdditions){
    var jLogin = JSON.parse(jsonLogin)

    var numberItem = (itens.length + 1)    
    var jsonItem = "{\"ITEM\":"+ numberItem + ",\"FUNCIONARIO_LANCAMENTO\": {\"NOME\":\"" + jLogin.NOME + "\"}" +
    ",\"FUNCIONARIO_VENDEDOR\": {\"NOME\":\"" + jLogin.NOME + "\"}"

    var jCompanyProduct = "{\"ID\":" + product.ID
    jCompanyProduct += ",\"PRODUTO\":{\"ID\":"+ product.PRODUTO.ID
    jCompanyProduct += ",\"BARRAS_COMERCIAL\":\""+ product.PRODUTO.BARRAS_COMERCIAL +"\""
    jCompanyProduct += ",\"BARRAS_TRIBUTAVEL\":\""+ product.PRODUTO.BARRAS_TRIBUTAVEL +"\""
    jCompanyProduct += ",\"DESCRICAO\":\""+ product.PRODUTO.DESCRICAO +"\""    
    jCompanyProduct += ",\"DESCRICAO_RESUMIDA\":\""+ product.PRODUTO.DESCRICAO_RESUMIDA +"\""
    jCompanyProduct += ",\"TIPO\":"+ product.PRODUTO.TIPO
    jCompanyProduct += ",\"ORIGEM\":"+ product.PRODUTO.ORIGEM

    if (product.PRODUTO.FOTO !== undefined)
      if ((product.PRODUTO.FOTO !== null) && (product.PRODUTO.FOTO !== "")) jCompanyProduct += ",\"FOTO\":\""+ product.PRODUTO.FOTO +"\""

    jCompanyProduct += ",\"UNIDADE_TRIBUTAVEL\":{\"ID\":"+ product.PRODUTO.UNIDADE_TRIBUTAVEL.ID +",\"SIGLA\":\""+ product.PRODUTO.UNIDADE_TRIBUTAVEL.SIGLA +"\",\"PESAVEL\":"+ product.PRODUTO.UNIDADE_TRIBUTAVEL.PESAVEL +"}"
    jCompanyProduct += ",\"UNIDADE_COMERCIAL\":{\"ID\":"+ product.PRODUTO.UNIDADE_COMERCIAL.ID +",\"SIGLA\":\""+ product.PRODUTO.UNIDADE_COMERCIAL.SIGLA +"\",\"PESAVEL\":"+ product.PRODUTO.UNIDADE_COMERCIAL.PESAVEL +"}"
    jCompanyProduct += ",\"DEPARTAMENTO\":{\"ID\":"+ product.PRODUTO.DEPARTAMENTO.ID +",\"DESCRICAO\":\""+ product.PRODUTO.DEPARTAMENTO.DESCRICAO +"\",\"COMISSAO\":"+ product.PRODUTO.DEPARTAMENTO.COMISSAO +"}"
    jCompanyProduct += ",\"TEMPO_PREPARO\":\""+ product.PRODUTO.TEMPO_PREPARO +"\""
    jCompanyProduct += ",\"TOTAL_FRACAO\":"+ product.PRODUTO.TOTAL_FRACAO
    jCompanyProduct += ",\"PERMITE_OBS\":"+ product.PRODUTO.PERMITE_OBS + "}"

    if (product.RTS_IMPRESSORA !== undefined)
      if ((product.RTS_IMPRESSORA.ID !== null) && (product.RTS_IMPRESSORA.ID !== 0)) 
        jCompanyProduct += ",\"RTS_IMPRESSORA\":{\"ID\":"+ product.RTS_IMPRESSORA.ID +"}"

    jCompanyProduct += ",\"PC_COMISSAO\":"+ product.PC_COMISSAO
    jCompanyProduct += ",\"PC_ACRESCIMO_MAX\":"+ product.PC_ACRESCIMO_MAX
    jCompanyProduct += ",\"PC_DESCONTO_MAX\":"+ product.PC_DESCONTO_MAX
    jCompanyProduct += ",\"PRECO_CUSTO\":"+ product.PRECO_CUSTO
    jCompanyProduct += ",\"PRECO_VENDA\":"+ product.PRECO_VENDA

    jCompanyProduct += ",\"NCM\":{\"CODIGO\":\""+ product.NCM.CODIGO + 
    "\",\"CST_PIS_S\":{\"CODIGO\":\""+ product.NCM.CST_PIS_S.CODIGO + "\"}" +
    ",\"PIS_ALIQUOTA_S\":"+ product.NCM.PIS_ALIQUOTA_S + 
    ",\"PIS_NATUREZA_S\":\""+ product.NCM.PIS_NATUREZA_S + "\"" +
    ",\"CST_COFINS_S\":{\"CODIGO\":\""+ product.NCM.CST_COFINS_S.CODIGO + "\"}" +
    ",\"COF_ALIQUOTA_S\":"+ product.NCM.COF_ALIQUOTA_S + 
    ",\"COF_NATUREZA_S\":\""+ product.NCM.COF_NATUREZA_S + "\"" +
    ",\"FCP\":"+ product.NCM.FCP + ",\"ALIQUOTA_FCP\":"+ product.NCM.ALIQUOTA_FCP +
    ",\"PC_IBPT_N\":"+ product.NCM.PC_IBPT_N + ",\"PC_IBPT_I\":"+ product.NCM.PC_IBPT_I +
    ",\"PC_IBPT_E\":"+ product.NCM.PC_IBPT_E + ",\"PC_IBPT_M\":"+ product.NCM.PC_IBPT_M + "}"

    jCompanyProduct += ",\"BASE_ST_RETIDO\":"+ product.BASE_ST_RETIDO
    jCompanyProduct += ",\"ALIQ_ST_RETIDO\":"+ product.ALIQ_ST_RETIDO
    jCompanyProduct += ",\"ALIQ_FCP_ST_RETIDO\":"+ product.ALIQ_FCP_ST_RETIDO

    if (product.ANP !== undefined)
      if ((product.ANP.CODIGO !== null) && (product.ANP.CODIGO !== ""))
        jCompanyProduct += ",\"ANP\":{\"CODIGO\":"+ product.ANP.CODIGO +",\"DESCRICAO\":\""+ product.ANP.DESCRICAO +"\"}"

    jCompanyProduct += ",\"PC_GLP\":"+ product.PC_GLP
    jCompanyProduct += ",\"PC_GNV\":"+ product.PC_GNV
    jCompanyProduct += ",\"PC_PART\":"+ product.PC_PART + "}"
    jsonItem += ",\"PRODUTO_FILIAL\":"+ jCompanyProduct
    jsonItem += ",\"COMPOSICAO\":" + arrCompositions

    var arrParts = "["
    if (product.PRODUTO.TOTAL_FRACAO > 1){
      for (let i = 0; i < product.PRODUTO.TOTAL_FRACAO; i++) {        
        if (i === 0) arrParts += "{\"ID\":\""+ (i+1) + "\",\"COMPOSICAO\":\"0\"}"
        else arrParts += ",{\"ID\":\""+ (i+1) + "\",\"COMPOSICAO\":\"0\"}"
      }
    }
    arrParts += "]"

    jsonItem += ",\"COMPOSICAO_OPCAO\":" + arrOptionsCompositions
    jsonItem += ",\"COMPOSICAO_PARTE\":" + arrParts
    jsonItem += ",\"ACRESCIMO\":" + arrAdditions

    if (JSON.parse(arrCompositions).length > 0) jsonItem += ",\"QUANTIDADE\":1"
    else jsonItem += ",\"QUANTIDADE\":\"\""

    jsonItem += ",\"TIPO_DESCONTO\": 1,\"VL_DESCONTO_COMPOSICAO\": 0,\"PC_DESCONTO_COMPOSICAO\": 0" +
    ",\"TIPO_ACRESCIMO\": 1,\"VL_ACRESCIMO_COMPOSICAO\": 0,\"PC_ACRESCIMO_COMPOSICAO\": 0"

    jsonItem += ",\"OBSERVACAO\":\"\",\"VL_BRUTO\": 0.00,\"VL_DESCONTO\": 0.00,\"VL_OUTRAS_DESPESAS\": 0.00,\"VL_ACRESCIMO\": 0.00"+
    ",\"VL_CONTABIL\": 0.00,\"ESCONDER_ITEM\": 1,\"ESCONDER_COMPOSICAO\": 1,\"ESCONDER_ACRESCIMO\": 1}"

    return jsonItem
  }

  function prepareItens(itens, itensReleased){
    var arrNew = "["

    let aItensReleased = itensReleased
    for(let i = 0; i < aItensReleased.length; i++){      
      aItensReleased[i].ESCONDER_ITEM = 1
      aItensReleased[i].ESCONDER_COMPOSICAO = 1
      aItensReleased[i].ESCONDER_ACRESCIMO = 1
    }

    { aItensReleased.map(item => 
      (arrNew === "[") ? arrNew += JSON.stringify(item) : arrNew += "," + JSON.stringify(item))
    }

    let aItens = itens
    for(let i = 0; i < aItens.length; i++){      
      aItens[i].ESCONDER_ITEM = 1
      aItens[i].ESCONDER_COMPOSICAO = 1
      aItens[i].ESCONDER_ACRESCIMO = 1
    }

    { itens.map(item => 
      (arrNew === "[") ? arrNew += JSON.stringify(item) : arrNew += "," + JSON.stringify(item))
    }

    arrNew += "]"
    return JSON.parse(arrNew)
  }

  const finalizeRelease = async() => {
    if (itens.length === 0) {
      alert("Não é possível finalizar um pedido sem itens!!!")
      return
    }

    setLoading("Aguarde inserindo os itens...")

    capa.lstITEM_MESA = itens

    const formData = new FormData();
    formData.append("TYPE_DATABASE", typeDatabase);
    formData.append("PATH_DATABASE", pathDatabase);
    formData.append("LOGIN", jsonLogin);
    formData.append("JSON", JSON.stringify(capa));
    formData.append("SYSTEM", system);

    try
    {
      const res = await axios.post("https://localhost:5001/api/MESA/Incluir", formData);
      let retornoJson = JSON.parse(res.data)

      if (!retornoJson.CONCRETIZADO){
        setWarning("")
        setError(retornoJson.ERRO)
        return
      }
      else{
        alert("Lançamento da Mesa ("+ numberTable +") finalizada!!!")
        navigate("/tableCenter")
      }            
    } catch (ex) {
      setWarning("")
      setError("Não foi possível incluir a mesa ("+ ex +")")
      return
    }
    finally{
      setLoading("")
    }

    //capaNew();
    //setItens([])
    //reloadDepartment(returnDepartments)
  }

  function reloadDepartment(dep){
    setDepartments("[]")
    setProducts("[]")    

    var arrDepartments = "["
    { dep && JSON.parse(dep).map(department =>
      (department.PAI === undefined) ?
        (arrDepartments === "[") ? arrDepartments += prepareDepartment(department)
        : arrDepartments += ","+ prepareDepartment(department)
      : arrDepartments += ""
      )
    }
    
    // arrDepartments += "{ \"ID\": 1, \"HIERARQUIA\": \"1\", \"DESCRICAO\": \"DEPARTAMENTO 1\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 1\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 2, \"HIERARQUIA\": \"2\", \"DESCRICAO\": \"DEPARTAMENTO 2\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 2\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 3, \"HIERARQUIA\": \"3\", \"DESCRICAO\": \"DEPARTAMENTO 3\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 3\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 4, \"HIERARQUIA\": \"4\", \"DESCRICAO\": \"DEPARTAMENTO 4\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 4\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 5, \"HIERARQUIA\": \"5\", \"DESCRICAO\": \"DEPARTAMENTO 5\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 5\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 6, \"HIERARQUIA\": \"6\", \"DESCRICAO\": \"DEPARTAMENTO 6\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 6\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 7, \"HIERARQUIA\": \"7\", \"DESCRICAO\": \"DEPARTAMENTO 7\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 7\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 8, \"HIERARQUIA\": \"8\", \"DESCRICAO\": \"DEPARTAMENTO 8\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 8\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 9, \"HIERARQUIA\": \"9\", \"DESCRICAO\": \"DEPARTAMENTO 9\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 9\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 10, \"HIERARQUIA\": \"10\", \"DESCRICAO\": \"DEPARTAMENTO 10\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 10\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 11, \"HIERARQUIA\": \"11\", \"DESCRICAO\": \"DEPARTAMENTO 11\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 11\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 12, \"HIERARQUIA\": \"12\", \"DESCRICAO\": \"DEPARTAMENTO 12\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 12\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 13, \"HIERARQUIA\": \"13\", \"DESCRICAO\": \"DEPARTAMENTO 13\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 13\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 14, \"HIERARQUIA\": \"14\", \"DESCRICAO\": \"DEPARTAMENTO 14\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 14\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 15, \"HIERARQUIA\": \"15\", \"DESCRICAO\": \"DEPARTAMENTO 15\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 15\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 16, \"HIERARQUIA\": \"16\", \"DESCRICAO\": \"DEPARTAMENTO 16\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 16\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 17, \"HIERARQUIA\": \"17\", \"DESCRICAO\": \"DEPARTAMENTO 17\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 17\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 18, \"HIERARQUIA\": \"18\", \"DESCRICAO\": \"DEPARTAMENTO 18\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 18\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 19, \"HIERARQUIA\": \"19\", \"DESCRICAO\": \"DEPARTAMENTO 19\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 19\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 20, \"HIERARQUIA\": \"20\", \"DESCRICAO\": \"DEPARTAMENTO 20\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 20\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 21, \"HIERARQUIA\": \"21\", \"DESCRICAO\": \"DEPARTAMENTO 21\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 21\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 22, \"HIERARQUIA\": \"22\", \"DESCRICAO\": \"DEPARTAMENTO 22\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 22\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 23, \"HIERARQUIA\": \"23\", \"DESCRICAO\": \"DEPARTAMENTO 23\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 23\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 24, \"HIERARQUIA\": \"24\", \"DESCRICAO\": \"DEPARTAMENTO 24\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 24\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 25, \"HIERARQUIA\": \"25\", \"DESCRICAO\": \"DEPARTAMENTO 25\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 25\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 26, \"HIERARQUIA\": \"26\", \"DESCRICAO\": \"DEPARTAMENTO 26\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 26\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 27, \"HIERARQUIA\": \"27\", \"DESCRICAO\": \"DEPARTAMENTO 27\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 27\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 28, \"HIERARQUIA\": \"28\", \"DESCRICAO\": \"DEPARTAMENTO 28\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 28\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 29, \"HIERARQUIA\": \"29\", \"DESCRICAO\": \"DEPARTAMENTO 29\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 29\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 30, \"HIERARQUIA\": \"30\", \"DESCRICAO\": \"DEPARTAMENTO 30\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 30\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 31, \"HIERARQUIA\": \"31\", \"DESCRICAO\": \"DEPARTAMENTO 31\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 31\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 32, \"HIERARQUIA\": \"32\", \"DESCRICAO\": \"DEPARTAMENTO 32\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 32\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 33, \"HIERARQUIA\": \"33\", \"DESCRICAO\": \"DEPARTAMENTO 33\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 33\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 34, \"HIERARQUIA\": \"34\", \"DESCRICAO\": \"DEPARTAMENTO 34\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 34\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 35, \"HIERARQUIA\": \"35\", \"DESCRICAO\": \"DEPARTAMENTO 35\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 35\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"
    // arrDepartments += ", { \"ID\": 36, \"HIERARQUIA\": \"36\", \"DESCRICAO\": \"DEPARTAMENTO 36\", \"DESCRICAO_RESUMIDA\": \"DEPARTAMENTO 36\", \"COMISSAO\": 0, \"PERIODICO\": \"1\", \"SITUACAO\": \"1\" }"

    arrDepartments += "]"
    setDepartments(arrDepartments)

    document.getElementById("filterProduct") && document.getElementById("filterProduct").focus()    
  }

  const returnTable = () => {
    if (itens.length > 0){
      alert("Não é permitido retornar para as mesas com itens lançados.")
      return
    }

    navigate("/tableCenter")
  }

  const navigate = useNavigate()

  return (
    <div style={(width > 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}
      : {display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height, boxSizing: "border-box"}}>

      {/* Header */}
      <div style={ (width < 280) ? { display: "flex", flexGrow: 0, flexDirection: "column", justifyContent: "space-between", backgroundColor: "rgb(7, 169, 161)" }
                   : { display: "flex", flexGrow: 0, flexDirection: "row", justifyContent: "space-between", backgroundColor: "rgb(7, 169, 161)" }}>

        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <label style={{color: "white", fontWeight: "600", paddingTop: 10, paddingLeft: 10}}>
            { jsonCompany && JSON.parse(jsonCompany).NOME_RAZAO }
          </label>
          <label style={{color: "white", fontWeight: "600", paddingTop: 5, paddingBottom: 10, paddingLeft: 10}}>
            Operador: { jsonLogin && JSON.parse(jsonLogin).NOME }
          </label> 
        </div>
        <div style={{ display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
          <img style={{ marginTop: 4 }} src={image_logo} alt="logo" />
        </div>
      </div>

      {/* Body */}
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, padding: 5 }}>
        { loading ?
            <>
              <div style={{display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems : "center"}}>
                <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}} >{loading}</label>
              </div>
            </>
          :
            <>
              <div style={(width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                  { !showQuantity && !showAdditionDiscount &&
                    <div style={(width < 580) ? {display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10}}>

                      <div style={
                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 7, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginTop: 7, width: 180 }}>

                        <Select
                          name="typeFilterProduct"
                          description=""
                          value={typeFilterProduct}
                          disabled={false}
                          require={false}
                          options={JSON.parse("{\"RESULTADO\": [{\"ID\": \"1\", \"VALOR\": \"Código / EAN\"}, {\"ID\": \"2\", \"VALOR\": \"Descrição\"}, {\"ID\": \"3\", \"VALOR\": \"Referência\"}]}")}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={(event) => setTypeFilterProduct(event)} />
                      </div>

                      <div style={
                        (width < 330) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingTop: 7}
                        : (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "row", paddingTop: 7}
                        : {display: "flex", flexGrow: 1, flexDirection: "row", paddingTop: 7, paddingLeft: 5}}>

                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="3 "
                            description=""
                            value={filterProduct}
                            maxLength={120}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={0}
                            keyboardType={ (typeFilterProduct === 1) ? "numeric" : "default" }
                            onSelect={setFieldSelected}
                            onChange={(event) => formatFilterProduct(event.target.value)}  />
                        </div>
                        <div style={(width < 330) ? {display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 5,
                            marginBottom: 5}
                          : {display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 8 }}>

                          <img style={{display: "flex", flexGrow: 0, width: 38, height: 38, marginTop: 4 }} src={image_search} alt="Pesquisar Filtro" onClick={searchProduct}/>
                          <img style={{display: "flex", flexGrow: 0, width: 38, height: 38, marginTop: 4, marginLeft: 5, marginRight: 10 }} src={image_clean} alt="Limpar Filtro" onClick={cleanSearchProduct}/>
                        </div>
                      </div>
                    </div>
                  }

                  { !showSearch && !showQuantity && !showAdditionDiscount &&
                    <>
                      { departments && (JSON.parse(departments).length > 0) &&
                        <div id={"viewDepartments"+ departments.ID} style={{flexGrow: 1, flexBasis: 0, overflowY: "auto", minHeight: 125}}>
                          <div style={(width < 430) ? {display: "flex", flexDirection: "row", flexWrap: "wrap", paddingTop: 5, paddingLeft: 6,
                              paddingRight: 6}
                            : {display: "flex", flexDirection: "row", flexWrap: "wrap", paddingTop: 5, paddingLeft: 20, paddingRight: 6}}>

                            { departments && JSON.parse(departments).map(department =>
                              <div key={department.ID} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(114,112,111)", borderRadius: 10, margin: 5, paddingLeft: 5, paddingRight: 5, width: 109, height: 110 }} onClick={() => callDepartment(department)}>

                                { (department.ID === 1001) ? <img style={{display: "flex", marginTop: 4, width: 48, height: 48 }} src={"https://cdn-icons-png.flaticon.com/512/3588/3588230.png"} alt="Retornar Departamento" />
                                  :
                                    (department.CAMINHO_IMAGEM === undefined) ?
                                        <img style={{ display: "flex", marginTop: 4, width: 48, height: 48 }} src={image_noPhoto} alt="Sem Foto Departamento"/>
                                      :
                                        <img style={{ display: "flex", marginTop: 4, width: 48, height: 48 }} src={department.CAMINHO_IMAGEM} alt="Foto Departamento" />
                                }

                                <label style={{display: "flex", flexBasis: 0, paddingTop: 8, fontSize: 12, fontWeight: "600", color: "white", textAlign: "center"}}>{department.DESCRICAO}</label>
                              </div>
                            )}
                          </div>
                        </div>
                      }

                      { products && (JSON.parse(products).length > 0) &&
                        <div id="viewProducts" style={{ flexGrow: 1, flexBasis: 0, overflowY: "auto", minHeight: 125}}>
                          <div style={(width < 430) ? {display: "flex", flexDirection: "row", flexWrap: "wrap", paddingTop: 5, paddingLeft: 6,
                              paddingRight: 6}
                            : {display: "flex", flexDirection: "row", flexWrap: "wrap", paddingTop: 5, paddingLeft: 20, paddingRight: 6}}>

                            { products && JSON.parse(products).map(product =>
                              <div key={product.PRODUTO.ID} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(7, 169, 161)", borderRadius: 10, margin: 5, paddingLeft: 5, paddingRight: 5, width: 109, height: 110 }} onClick={() => callProduct(product)}>

                                { (product.PRODUTO.FOTO === undefined) ?
                                    <img style={{display: "flex", marginTop: 4, width: 48, height: 48 }} src={image_noPhoto} alt="Sem Foto Produto"/>
                                  :
                                    <img style={{display: "flex", marginTop: 4, width: 48, height: 48 }} src={product.PRODUTO.FOTO} alt="Foto Produto" />
                                }
                                
                                <label style={{display: "flex", flexBasis: 0, paddingTop: 8, fontSize: 12, fontWeight: "600", color: "white", textAlign: "center"}}>{product.PRODUTO.DESCRICAO_RESUMIDA}</label>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                    </>
                  }

                  { showSearch && !showQuantity && !showAdditionDiscount &&
                    <div style={{ display: "flex", flexGrow: 1 }}>
                      { loading && 
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center", alignContent: "center" }}>
                          <label style={{ textAlign: "center", fontSize: 18, fontWeight: "600", color: "rgb(7, 169, 161)" }}>Aguarde, buscando as produtos...</label>
                        </div>
                      }

                      { !loading && 
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center", alignContent: "center" }}>                    
                          { products && (JSON.parse(products).length > 0) &&
                            <div id="viewProducts" style={{display: "flex", flexGrow: 1, flexBasis: 0}}>
                              <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", paddingTop: 5, paddingLeft: 20, paddingRight: 6}}>
                                { products && JSON.parse(products).map(product =>
                                  <div key={product.PRODUTO.ID} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(7, 169, 161)", borderRadius: 10, margin: 5, paddingLeft: 5, paddingRight: 5, width: 109, height: 110 }} onClick={() => callProduct(product)}>

                                    { (product.PRODUTO.FOTO === undefined) ?
                                        <img style={{display: "flex", marginTop: 4, width: 48, height: 48 }} src={image_noPhoto} alt="Sem Foto Produto"/>
                                      :
                                        <img style={{display: "flex", marginTop: 4, width: 48, height: 48 }} src={product.PRODUTO.FOTO} alt="Foto Produto" />
                                    }

                                    <label style={{display: "flex", flexBasis: 0, paddingTop: 8, fontSize: 12, fontWeight: "600", color: "white", textAlign: "center", marginTop: 4}}>{product.PRODUTO.DESCRICAO_RESUMIDA}</label>
                                  </div>
                                )}
                              </div>
                            </div>
                          }

                          { products && (JSON.parse(products).length === 0) &&
                            <label style={{ textAlign: "center", fontSize: 18, fontWeight: "600", color: "rgb(7, 169, 161)" }}>Nenhum produto encontrado com o filtro "{filterProduct}" informado.</label>
                          }
                        </div>
                      }
                    </div>
                  }

                  { !showSearch && showQuantity && !showAdditionDiscount &&
                    <SaleQuantity typeDatabase={typeDatabase} pathDatabase={pathDatabase} jsonCompany={jsonCompany} jsonLogin={jsonLogin}
                      system={system} item={item} setItem={setItem} insertItem={insertItem} cancelQuantity={cancelQuantity} windowHeight={height}
                        windowWidth={width} />
                  }
                </div>

                { !showSearch && !showQuantity && !showAdditionDiscount &&
                  <div style={
                    (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, overflow: "auto"}
                    : (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 320, maxWidth: 320,
                      maxHeight: height - 235, overflow: "auto"}
                    : (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 360, maxWidth: 360,
                      maxHeight: height - 235, overflow: "auto"}
                    : (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 400, maxWidth: 400,
                      maxHeight: height - 235, overflow: "auto"}
                    : (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 440, maxWidth: 440,
                      maxHeight: height - 235, overflow: "auto"}
                    : {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 460, maxWidth: 46, maxHeight: height - 235,
                      overflow: "auto"}}>

                    <ShowItensTable origin={1} itens={itens} deleteItem={deleteItem} callAdditionDiscount={callAdditionDiscount}
                      windowWidth={width} />
                  </div>
                }

                { showAdditionDiscount &&
                  <AdditionDiscount typeDatabase={typeDatabase} pathDatabase={pathDatabase} jsonCompany={jsonCompany} jsonLogin={jsonLogin}
                    item={itemAdditionDiscount} setConfirm={confirmAdditionDiscount} setReturn={returnAdditionDiscount}
                    windowHeight={height} windowWidth={width} />
                }
              </div>
            </>
        }
      </div>

      <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
          <div style={{ display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 5, paddingRight: 5 }}>
            { !showQuantity && !showSearch && !showAdditionDiscount &&
              <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 5, height: 70 }}>
                <div style={{ display: "flex", flexGrow: 1, flexDirection: "row" }}>
                  <div style={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", backgroundColor: "rgb(7, 169, 161)", borderRadius: 10, width: 110, height: 67, marginRight: 5 }} onClick={finalizeRelease}>
                    <label style={{fontSize: 16, fontWeight: "600", color: "white", textAlign: "center"}}>Finalizar Lançamento</label>
                  </div>

                  <div style={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", backgroundColor: "rgb(7, 169, 161)", borderRadius: 10, width: 110, height: 67 }} onClick={finalizeRelease}>
                    <label style={{fontSize: 16, fontWeight: "600", color: "white", textAlign: "center"}}>Finalizar Mesa</label>
                  </div>
                </div>
              </div>
            }

            { !showQuantity && !showAdditionDiscount &&
              <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 5, paddingRight: 5, marginBottom: 5, height: 70 }}>
                <div style={{ display: "flex", flexGrow: 1, flexDirection: "row", backgroundColor: "rgb(114,112,111)", borderRadius: 10 }}>
                  <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 20 }}>
                    <label style={{fontSize: 26, fontWeight: "600", color: "white" }}>Mesa: {numberTable}</label>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", flexGrow: 0 }}>
                    <div style={{ display: "flex", flexGrow: 0, paddingLeft: 10, marginTop: 10, marginRight: 30 }}>
                      <label style={{fontSize: 22, fontWeight: "600", color: "white" }}>Total:</label>
                    </div>
                    <div style={{ display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "flex-end", marginBottom: 10, paddingRight: 20 }}>
                      <label style={{fontSize: 26, fontWeight: "600", color: "white" }}>{formatNumeric(capa.VL_CONTABIL,2)}</label>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexGrow: 0, flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(114,112,111)", borderRadius: 10, marginLeft: 5, width: 150 }} onClick={returnTable}>
                  <label style={{fontSize: 16, fontWeight: "600", color: "white", textAlign: "center"}}>Retornar</label>
                </div>                    
              </div>
            }
          </div>
        
        <div style={{ display: "flex", flexGrow: 0, backgroundColor: "rgb(7, 169, 161)", height: 5 }} />
      </div>
    </div>
  )
}

export default TableItensTouch