import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Questionnaire from "../../../Components/Questionnaire"
import ProductCompanyComposition from "../"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchProductCompanyComposition = ({databaseType, databasePath, jsonProductCompany, jsonCompany, jsonLogin, system, showMenu, width}) => {
  const [code, setCode] = useState(0)
  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonProductCompanyComposition, setJsonProductCompanyComposition] = useState("")
    
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jProductCompanyComposition = "{ \"PRODUCT_COMPANY_O\": { \"ID\": " + jsonProductCompany.ID + 
      ", \"COMPANY\": { \"ID\": "+ jsonProductCompany.COMPANY.ID +" } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jProductCompanyComposition)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT_COMPOSITION/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar as composições do produto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonProductCompany, jsonLogin, system, update])

  const add = () => {
    setJsonProductCompanyComposition(JSON.parse("{ \"PRODUCT_COMPANY_O\": { \"ID\": " + jsonProductCompany.ID + ", \"COMPANY\": { \"ID\": "+
      jsonProductCompany.COMPANY.ID +" }, \"PRODUCT\": { \"ID\": "+ jsonProductCompany.PRODUCT.ID +
        ", \"DESCRIPTION\": \""+ jsonProductCompany.PRODUCT.DESCRIPTION +"\""+
          ", \"COMMERCIAL_UNIT\": { \"WEIGHABLE\": "+ jsonProductCompany.PRODUCT.COMMERCIAL_UNIT.WEIGHABLE + " } } } }"))

    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setWarning("")
    setError("")
    setShowChange(false)
  }

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir a composição do produto (" + item.PRODUCT_COMPANY_D.PRODUCT.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {  
    let returnJson = []

    const jProductCompanyComposition = "{ \"ID\": "+ code + ", \"PRODUCT_COMPANY_O\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } } }"

    setLoading("Aguarde excluindo a composição do produto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jProductCompanyComposition)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT_COMPOSITION/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir a composição do produto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      const count = update + 1
      setUpdate(count)
    })
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const save = (productCompanyComposition) => {
    let returnJson = []

    if (JSON.parse(productCompanyComposition).ID === undefined)
    {
      setLoading("Aguarde incluindo a composição do produto...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", productCompanyComposition)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PRODUCT_COMPOSITION/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a composição do produto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
    else
    {
      setLoading("Aguarde alterando a composição do produto...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonProductCompanyComposition))
      formData.append("JSON_NEW_1", productCompanyComposition)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "PRODUCT_COMPOSITION/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a composição do produto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
  }

  const setSelected = (productCompanyComposition) => {
    setJsonProductCompanyComposition(productCompanyComposition)
    setWarning("")
    setError("")
    setShowChange(true)
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <ProductCompanyComposition
                databaseType={databaseType}
                databasePath={databasePath}
                jsonProductCompanyComposition={jsonProductCompanyComposition}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                save={save}
                back={back} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                          marginLeft: 5, width: 147}} onClick={() => add()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}>

                        { result.OBJECT ?
                            <>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}
                                  : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}
                                :
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 10, marginBottom: 4}}>

                                <div style={(showMenu) ?
                                    (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : 
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={(showMenu) ?
                                      (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 940) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                                      :
                                        (width < 670) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>

                                    <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Composição</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 1090) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                    :
                                      (width < 820) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>
                                    <label style={{fontWeight: 600}}>Quantidade</label>
                                  </div>
                                </div>

                                <div>
                                  { showMenu ?
                                      (width < 580) ? <></>
                                      : (width < 690) ?
                                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                            maxWidth: 32}} />

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32, 
                                            maxWidth: 32}}/>
                                        </div>

                                      : (width < 850) ? <></>
                                      :
                                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                            maxWidth: 32}} />

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32, 
                                            maxWidth: 32}}/>
                                        </div>
                                    :
                                      (width < 580) ? <></>
                                      :
                                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                            maxWidth: 32}} />

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32, 
                                            maxWidth: 32}}/>
                                        </div>
                                  }

                                </div>
                              </div>

                              <div style={showMenu ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}
                                : (width < 330) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, minWidth: 32, maxWidth: 32}
                                  : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />

                                { result.OBJECT.map(item => (
                                  <div key={item.ID} style={showMenu ?
                                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                      : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                    :
                                      (width < 580) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1,
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                      : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}}>

                                    <div style={(showMenu) ?
                                        (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ?
                                          (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={showMenu ?
                                            (width < 940) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                                          :
                                            (width < 670) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                          <label>{item.PRODUCT_COMPANY_D.PRODUCT.ID}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                                          <label>{item.PRODUCT_COMPANY_D.PRODUCT.DESCRIPTION}</label>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 1090) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                        :
                                          (width < 820) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>
                                        <label>{formatNumeric(item.QUANTITY, 3)}</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 580) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, justifyContent: "center"}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}
                                        : (width < 850) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, justifyContent: "center"}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 580) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, justifyContent: "center"}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                        <img
                                          style={{height: 32, width: 32}}
                                          src={image_select}
                                          name="searchProductCompanyCompositionSelected"
                                          alt="Selecionar"
                                          onClick={(() => setSelected(item))} />
                                      </div>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                        <img
                                          style={{height: 28, width: 28}}
                                          src={image_delete}
                                          name="searchProductCompanyCompositionDeleted"
                                          alt="Deletar"
                                          onClick={() => callDelete(item)} />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          :
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                        }

                      </div>
                    </>
                }
              </>
          }
        </>
      }
    </div>
  )
}

export default SearchProductCompanyComposition