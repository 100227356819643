import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Titulo from "../../Components/Titulo"
import SearchTerminal from "./Search"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_terminal from "../../Images/Buttons/terminal.png"

const Terminal = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, selectTerminal, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")  
  const [clientCode, setClientCode] = useState("")
  const [clientCorporateName, setClientCorporateName] = useState("")
  const [onlySimple, setOnlySimple] = useState(0)
  const [onlyRestaurant, setOnlyRestaurant] = useState(0)
  const [onlySection, setOnlySection] = useState(0)
  const [onlyOrder, setOnlyOrder] = useState(0)
  const [situation, setSituation] = useState(0)
  const [sge, setSge] = useState(0)

  const [jTerminal, setJTerminal] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === ""){
      setDescription("")
      setClientCode("")
      setClientCorporateName("")
      setOnlySimple(0)
      setOnlyRestaurant(0)
      setOnlySection(0)
      setOnlyOrder(0)
      setSituation(0)
      setSge("")
    }
    else {
      setDescription(jTerminal.DESCRIPTION)

      if ((jTerminal.CLIENT !== undefined) && (jTerminal.CLIENT !== null)) {
        if ((jTerminal.CLIENT.ID !== undefined) && (jTerminal.CLIENT.ID !== null))
          setClientCode(jTerminal.CLIENT.ID)
        if ((jTerminal.CLIENT.CORPORATE_NAME !== undefined) && (jTerminal.CLIENT.CORPORATE_NAME !== null))
          setClientCorporateName(jTerminal.CLIENT.CORPORATE_NAME)
      }

      setOnlySimple(jTerminal.ONLY_SIMPLE)
      setOnlyRestaurant(jTerminal.ONLY_RESTAURANT)
      setOnlySection(jTerminal.ONLY_SECTION)            
      setOnlyOrder(jTerminal.ONLY_ORDER)
      setSituation(jTerminal.SITUATION)

      if ((jTerminal.SGE !== undefined) && (jTerminal.SGE !== null)) setSge(jTerminal.SGE)
    }

    document.getElementById("terminalSituation") && document.getElementById("terminalSituation").focus()

  }, [code, jTerminal, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setDescription("")
      setClientCode("")
      setClientCorporateName("")
      setOnlySimple(0)
      setOnlyRestaurant(0)
      setOnlySection(0)
      setOnlyOrder(0)
      setSituation(0)
      setSge("")
    }
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonTerminal_New = {}

    jsonTerminal_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonTerminal_New += ", \"ID\": "+ code

    jsonTerminal_New += ", \"DESCRIPTION\": \""+ description + "\""

    if (clientCode !== "") jsonTerminal_New += ", \"CLIENT\": { \"ID\": "+ clientCode +", \"CORPORATE_NAME\": \""+ clientCorporateName + "\" }"
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonTerminal_New += ", \"SGE\": "+ sge
    
    jsonTerminal_New += ", \"ONLY_SIMPLE\": "+ onlySimple + ", \"ONLY_RESTAURANT\": "+ onlyRestaurant + ", \"ONLY_SECTION\": "+ onlySection + 
      ", \"ONLY_ORDER\": "+ onlyOrder + ", \"SITUATION\": "+ situation + " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonTerminal_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "TERMINAL/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o terminal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("terminalSituation") && document.getElementById("terminalSituation").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")   
        
        setJTerminal(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jTerminal))
      formData.append("JSON_NEW_1", jsonTerminal_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "TERMINAL/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o terminal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("terminalSituation") && document.getElementById("terminalSituation").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        const term = JSON.parse(localStorage.getItem("terminal"))
        if (term.ID == code){
          selectTerminal(JSON.parse("{ \"ID\": "+ code +", \"DESCRIPTION\": \""+ description +"\" }"))
          return
        }

        setJTerminal(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    
    setCode(event.ID)
    setDescription(event.DESCRIPTION)

    if ((event.CLIENT !== undefined) && (event.CLIENT !== null)) {
      if ((event.CLIENT.ID !== undefined) && (event.CLIENT.ID !== null)) setClientCode(event.CLIENT.ID)
      if ((event.CLIENT.CORPORATE_NAME !== undefined) && (event.CLIENT.CORPORATE_NAME !== null)) setClientCorporateName(event.CLIENT.CORPORATE_NAME)
    }

    setOnlySimple(event.ONLY_SIMPLE)
    setOnlyRestaurant(event.ONLY_RESTAURANT)
    setOnlySection(event.ONLY_SECTION)
    setOnlyOrder(event.ONLY_ORDER)            
    setSituation(event.SITUATION)

    if ((event.SGE !== undefined) && (event.SGE !== null)) setSge(event.SGE)

    setJTerminal(event)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Terminal"
            titleSearch="Pesquisa dos Terminais"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
            <SearchTerminal
              modeSearch={modeSearch}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonLogin={jsonLogin}
              jsonCompany={jsonCompany}
              system={system}
              setAdicionar={setAdd}
              setSelected={setSelected} 
              setReturn={setReturn}
              showMenu={showMenu}
              width={width} />
            :
              <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{loading}</label>
                    </div>
                  :
                    <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                          marginTop: 10, marginLeft: 5, width: 147}} onClick={() => save()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10,  paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                          </div>
                        </div>

                        { (code !== "") &&
                          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                            marginTop: 10, marginLeft: 5, width: 147}} 
                              onClick={() => selectTerminal(JSON.parse("{ \"ID\": "+ code +", \"DESCRIPTION\": \""+ description +"\" }"))}>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                              paddingRight: 10,  paddingBottom: 5, paddingLeft: 10}}>
                              <img style={{height: 32, width: 32}} src={image_terminal} alt="Ativar"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Ativar</label>
                            </div>
                          </div>
                        }

                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                          marginTop: 10, marginLeft: 5, width: 147}} onClick={() => previousAdd()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10}
                        : {paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={{marginTop: 10}}>
                          { (code !== "") ?
                              <div style={showMenu ?
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="terminalDescription"
                                    description="Descrição"
                                    value={description}
                                    maxLength={30}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                  :
                                    (width < 530) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                  <Select
                                    name="terminalSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            :
                              <div style={showMenu ? 
                                  (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                : 
                                  (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={showMenu ?
                                    (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                      : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    : 
                                      (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                    <Input
                                      type="text"
                                      name="terminalCode"
                                      description="Código"
                                      value={code}
                                      maxLength={11}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="terminalDescriptiom"
                                      description="Descrição"
                                      value={description}
                                      maxLength={30}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    :  {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                  :                                          
                                    (width < 670) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                  <Select
                                    name="terminalSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                          }    

                          <div style={showMenu ?
                              (width < 1280) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                            :
                              (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>

                            <div style={showMenu ?
                                (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1280) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                              :
                                (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                              <div style={showMenu ?
                                  (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}
                                :
                                  (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}}>
                                <Select
                                  name="onlySimple"
                                  description="Apenas Venda Simples"
                                  value={onlySimple || 0}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setOnlySimple} />
                              </div>
                              <div style={showMenu ?
                                  (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}
                                :
                                  (width < 530) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}}>
                                <Select
                                  name="onlyRestaurant"
                                  description="Apenas Venda Restaurante"
                                  value={onlyRestaurant || 0}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setOnlyRestaurant} />
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 1280) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                              <div style={showMenu ?
                                  (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}
                                :
                                  (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 230}}>
                                <Select
                                  name="onlySection"
                                  description="Apenas Setores"
                                  value={onlySection || 0}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setOnlySection} />
                              </div>
                              <div style={showMenu ?
                                  (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}
                                :
                                  (width < 530) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}}>
                                <Select
                                  name="onlyOrder"
                                  description="Apenas Pedido"
                                  value={onlyOrder || 0}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setOnlyOrder} />
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                            <div style={{marginTop: 10, minWidth: 180}}>
                              <Titulo description="Cliente" />
                            </div>
                            <div style={{display: "flex", flexGrow: 1, marginBottom: 10, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>
                              <Input
                                type="text"
                                name="terminalClientCorporateName"
                                description="Razão Social/Nome"
                                value={clientCorporateName}
                                maxLength={60}
                                disabled={true}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setClientCorporateName(event.target.value)} />                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Terminal