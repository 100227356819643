import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import SearchPagination from "../../../Components/SearchPagination"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchRegisterTable = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  useEffect(() => {
    let returnJson = []

    const jRegisterTable = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jRegisterTable)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "REGISTER_TABLE/TotalRecords", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar o total de mesas. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")
        return
      }

      const tRecord = returnJson.OBJECT
      if (tRecord === 0)
      {
        setWarning("Nenhum registro encontrado.")
        setShowResult(false)
        setLoading("")
        return
      }

      const operation = tRecord / recordPage
      const numberR = Math.floor(operation)
      const decimal = operation % 1

      let tPage = 0
      if (decimal > 0) tPage = numberR + 1
      else tPage = numberR

      if (tRecord > 600)
        setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
          ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

      setTotalRecord(tRecord)
      setTotalPage(tPage)
      if (tPage < pageCurrent) setPageCurrent(1)

      returnJson.WARNING && setWarning(returnJson.WARNING)
        
      let qtdeInitial = 0
      if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jRegisterTable)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", qtdeInitial)
      formData.append("QUANTITY_RECORDS", recordPage)

      axios.post(BASE_URL + "REGISTER_TABLE/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as mesas. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")
          return
        }
        
        setResult(returnJson)
        setShowResult(true)
        setLoading("")
      })
    })
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, updatePage])

  const clean = () => {
    setUpdatePage(updatePage + 1)
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              { !modeSearch &&
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                  width: 147}} onClick={() => setAdd()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                      paddingBottom: 5, paddingLeft: 10}}>
                      <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                  </div>
                  </div>
              }

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                  width: 147}} onClick={clean}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                  </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                  width: 147}} onClick={setReturn}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              <div style={{paddingLeft: 10, paddingRight: 10}}>

                { showResult && 
                  <>
                    { result.OBJECT &&
                      <>
                        <div style={(width < 450) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                            borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}                        
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                            borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, marginRight: 8, minWidth: 180}}>
                            
                          <div style={showMenu ?
                              (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                            :
                              (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                            <div style={(width < 440) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 150}}>
                              <label style={{fontWeight: 600}}>Número</label>
                            </div>
                            <div style={showMenu ?
                                (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 440) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                              : 
                                (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 440) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                              <div style={showMenu ?
                                  (width < 280) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : (width < 440) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                  : (width < 690) ? {display: "flex",  flexGrow: 0, minWidth: 70, maxWidth: 70}
                                  : (width < 720) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                  : {display: "flex",  flexGrow: 0, minWidth: 70, maxWidth: 70}
                                :
                                  (width < 280) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : (width < 440) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                  : {display: "flex",  flexGrow: 0, minWidth: 70, maxWidth: 70}}>
                                <label style={{fontWeight: 600}}>Situação</label>
                              </div>
                              <div style={showMenu ?
                                  (width < 280) ? {display: "flex",  flexGrow: 1, marginRight: 10, wordBreak: "break-all", minWidth: 180}
                                  : (width < 440) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                  : (width < 690) ? {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                  : (width < 720) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                  : {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                :
                                  (width < 280) ? {display: "flex",  flexGrow: 1, marginRight: 10, wordBreak: "break-all", minWidth: 180}
                                  : (width < 440) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                  : {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}}>
                                <label style={{fontWeight: 600}}>Status</label>
                              </div>
                            </div>
                          </div>

                          { (width >= 450) &&
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{height: 32, width: 32}}></div>
                            </div>
                          }
                        </div>

                        { result.OBJECT.map(item => (
                          <div key={item.ID} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                            backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                            borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, paddingBottom: 8,
                            marginTop: 4, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                              marginTop: 4, minWidth: 180}}>

                            <div style={showMenu ?
                                (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              :
                                (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                              <div style={(width < 440) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                <label>Mesa {item.NUMBER}</label>
                              </div>
                              <div style={showMenu ?
                                  (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 440) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                : 
                                  (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 440) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                <div style={showMenu ?
                                    (width < 280) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : (width < 440) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                    : (width < 690) ? {display: "flex",  flexGrow: 0, minWidth: 70, maxWidth: 70}
                                    : (width < 720) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                    : {display: "flex",  flexGrow: 0, minWidth: 70, maxWidth: 70}
                                  :
                                    (width < 280) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : (width < 440) ? {display: "flex",  flexGrow: 1, minWidth: 70}
                                    : {display: "flex",  flexGrow: 0, minWidth: 60, maxWidth: 70}}>
                                  <label>{parseInt(item.SITUATION) == 1 ? "Ativo" : "Inativo"}</label>
                                </div>
                                <div style={showMenu ?
                                    (width < 280) ? {display: "flex",  flexGrow: 1, marginRight: 10, wordBreak: "break-all", minWidth: 180}
                                    : (width < 440) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                    : (width < 690) ? {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                    : (width < 720) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                    : {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                  :
                                    (width < 280) ? {display: "flex",  flexGrow: 1, marginRight: 10, wordBreak: "break-all", minWidth: 180}
                                    : (width < 440) ? {display: "flex",  flexGrow: 1, marginLeft: 10, marginRight: 10, minWidth: 90}
                                    : {display: "flex",  flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}}>
                                  <label>
                                    {parseInt(item.STATUS) == 1 ? "Vazia" 
                                    : parseInt(item.STATUS) == 2 ? "Ocupada" 
                                    : parseInt(item.STATUS) == 3 ? "Finalizada" 
                                    : "Reservada" }
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                              : {display: "flex", flexGrow: 0}}>
                              <img
                                style={{height: 32, width: 32}}
                                src={image_select}
                                name="searchRegisterTableSelect"
                                alt="Selecionar"
                                onClick={() => setSelected(item)} />
                            </div>
                          </div>
                          ))
                        }
                        
                        <SearchPagination 
                          pageCurrent={pageCurrent}
                          totalPage={totalPage}
                          recordPage={recordPage}
                          optionsRegPage={JSON.parse(optionsRegPage)}
                          pagePrevious={pagePrevious}
                          pageNext={pageNext}
                          setSelectedField={setFieldSelected}
                          handleRecordPage={handleRecordPage}
                          width={width} />
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SearchRegisterTable