import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import SearchNfModel from "./Search"
import SearchNfSeries from "../NfSeries/Search"
import image_loading from "../../Images/spinner.gif";

const NfModel = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [codeOrigin, setCodeOrigin] = useState("")
  const [description, setDescription] = useState("")
    
  const [jNfModel, setJNfModel] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (codeOrigin === "") setDescription("")
    else setDescription(jNfModel.DESCRIPTION)

    if (codeOrigin === "") document.getElementById("nfModelCode") && document.getElementById("nfModelCode").focus()
    else document.getElementById("nfModelDescription") && document.getElementById("nfModelDescription").focus()

  }, [codeOrigin, jNfModel, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") setDescription("")
    else {
      setCodeOrigin("")
      setCode("")
    }

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")

    setCodeOrigin("")
    setCode("")

    setShowSearch(true)
  }
 
  const save = () => {
    let returnJson = []

    let jsonNfModel_New = "{"
    if (codeOrigin === "") jsonNfModel_New += " \"CODE\": \""+ code + "\""
    else jsonNfModel_New += " \"CODE\": \""+ codeOrigin + "\""
    jsonNfModel_New += ", \"DESCRIPTION\": \""+ description + "\""
    jsonNfModel_New += " }";

    if (codeOrigin === ""){ 
      setLoading("Aguarde incluindo o registro...")  
      setWarning("")
      setError("")
      
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonNfModel_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "NF_MODEL/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o modelo. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("nfModelDescription") && document.getElementById("nfModelDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJNfModel(returnJson.OBJECT)
        setCodeOrigin(returnJson.OBJECT.CODE)

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jNfModel))
      formData.append("JSON_NEW_1", jsonNfModel_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "NF_MODEL/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o modelo. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("nfModelDescription") && document.getElementById("nfModelDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJNfModel(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
       
    setJNfModel(event)
    setCode(event.CODE)
    setCodeOrigin(event.CODE)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Modelo"
            titleSearch="Pesquisa dos Modelos"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchNfModel
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}}>
                        { warning && 
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                          </div> 
                        }

                        { error &&
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                          </div>
                        }

                        <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                            <Input
                              type="text"
                              name="nfModelCode"
                              description="Código"
                              value={code}
                              maxLength={2}
                              disabled={ (codeOrigin === "") ? false : true }
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setCode(event.target.value)} />
                          </div>
                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="text"
                              name="nfModelDescription"
                              description="Descrição"
                              value={description}
                              maxLength={80}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                        </div>

                        { codeOrigin &&
                          <div style={{marginTop: 10}}>
                            <Titulo description="Série"/>
                            <SearchNfSeries
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonNfModel={jNfModel}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system} 
                              showMenu={showMenu}
                              height={height}
                              width={width} />
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>
         
      <Footer/>
    </div>
  )
}

export default NfModel