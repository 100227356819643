import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchClient = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [fieldSelected, setFieldSelected] = useState("")
  
  const [code, setCode] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [corporateName, setCorporateName] = useState("")
  const [situation, setSituation] = useState(0)
  const [establishmentCode, setEstablishmentCode] = useState(0)
  const [clientTypeCode, setClientTypeCode] = useState(0)
  const [clientClassCode, setClientClassCode] = useState(0)
  
  const [showResult, setShowResult] = useState(false)    
  const [result, setResult] = useState({})

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  const [errorImport, setErrorImport] = useState("")

  ////
  // Update records // Atualizar registros
  ////
  const [optionsClientClass, setOptionsClientClass] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")    
  const [optionsEstablishment, setOptionsEstablishment] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")    
  const [optionsClientType, setOptionsClientType] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")            

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [firstAcess, setFirstAcess] = useState(true)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    if (firstAcess){
      ////
      // Upload the information of the establishments // Atualiza as informações dos estabelecimentos
      ////
      setLoading("Aguarde buscando as informações dos estabelecimentos...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ESTABLISHMENT/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de estabelecimentos. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)
          document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord > 0)
        {
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", 0)
          formData.append("QUANTITY_RECORDS", tRecord)
  
          axios.post(BASE_URL + "ESTABLISHMENT/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os estabelecimentos. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setLoading("")
              setWarning("")
              setError(returnJson.ERROR)
              document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
              return
            }
              
            let clientEstablishment = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
            returnJson && returnJson.OBJECT.map(item => ( 
              clientEstablishment += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
            ))
            clientEstablishment += "]}"

            setOptionsEstablishment(clientEstablishment)  
          })
        }
      })

      ////
      // Upload the information of the client type // Atualiza as informações dos tipos
      ////
      setLoading("Aguarde buscando as informações dos tipos do cliente...")
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "CLIENT_TYPE/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de tipos do cliente. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)
          document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord > 0)
        {
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", 0)
          formData.append("QUANTITY_RECORDS", tRecord)
  
          axios.post(BASE_URL + "CLIENT_TYPE/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os tipos do cliente. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setLoading("")
              setWarning("")
              setError(returnJson.ERROR)
              document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
              return
            }
              
            let clientType = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
            returnJson && returnJson.OBJECT.map(item => (
              clientType += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
            ))
            clientType += "]}"

            setOptionsClientType(clientType)
          })
        }
      })

      ////
      // Upload the information of the client class // Atualiza as informações das classes
      ////
      setLoading("Aguarde buscando as informações das classes do cliente...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "CLIENT_CLASS/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de classes do cliente. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)
          document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord > 0)
        {
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", 0)
          formData.append("QUANTITY_RECORDS", tRecord)
  
          axios.post(BASE_URL + "CLIENT_CLASS/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar as classes do cliente. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setLoading("")
              setWarning("")
              setError(returnJson.ERROR)
              document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()  
              return
            }
              
            let clientClass = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
            returnJson && returnJson.OBJECT.map(item => (
              clientClass += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
            ))
            clientClass += "]}"

            setOptionsClientClass(clientClass)
            setLoading("")
          })
        }
      })
            
      setFirstAcess(false)
    }

    let jClient = "{ "

    if (cpfCnpj !== "") {
      jClient += "\"CPF_CNPJ\": \""+ cpfCnpj + "\""
      if ((cpfCnpj.length === 11) || (cpfCnpj.length === 14)) search = true
    }        

    if (corporateName !== ""){
      if (jClient !== "{ ") jClient += ", "
      jClient += "\"CORPORATE_NAME\": \""+ corporateName + "\""
      if (corporateName.length > 2) search = true
    }

    if (parseInt(situation) !== 0){
      if (jClient !== "{ ") jClient += ", "
      jClient += "\"SITUATION\": "+ situation
      search = true
    }

    if (parseInt(establishmentCode) !== 0){
      if (jClient !== "{ ") jClient += ", "
      jClient += "\"ESTABLISHMENT\": { \"ID\": "+ establishmentCode + " }"
      search = true
    }

    if (parseInt(clientTypeCode) !== 0){
      if (jClient !== "{ ") jClient += ", "
      jClient += "\"CLIENT_TYPE\": { \"ID\": "+ clientTypeCode + " }"
      search = true
    }

    if (parseInt(clientClassCode) !== 0){
      if (jClient !== "{ ") jClient += ", "
      jClient += "\"CLIENT_CLASS\": { \"ID\": "+ clientClassCode + " }"
      search = true
    }

    jClient += " }"

    if (search) {
      setLoading("Aguarde buscando as informações...")      
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jClient)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "CLIENT/TotalRecordsRegistration", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de clientes. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchClientCorporateName")
            document.getElementById("searchClientCorporateName") && document.getElementById("searchClientCorporateName").focus()
          else if (fieldSelected === "searchClientEstablishmentCode")
            document.getElementById("searchClientEstablishmentCode") && document.getElementById("searchClientEstablishmentCode").focus()
          else if (fieldSelected === "searchClientClientTypeCode")
            document.getElementById("searchClientClientTypeCode") && document.getElementById("searchClientClientTypeCode").focus()
          else if (fieldSelected === "searchClientClientClassCode")
            document.getElementById("searchClientClientClassCode") && document.getElementById("searchClientClientClassCode").focus()
          else if (fieldSelected === "searchClientSituation")
            document.getElementById("searchClientSituation") && document.getElementById("searchClientSituation").focus()
          else
            document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()

          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchClientCorporateName")
            document.getElementById("searchClientCorporateName") && document.getElementById("searchClientCorporateName").focus()
          else if (fieldSelected === "searchClientEstablishmentCode")
            document.getElementById("searchClientEstablishmentCode") && document.getElementById("searchClientEstablishmentCode").focus()
          else if (fieldSelected === "searchClientClientTypeCode")
            document.getElementById("searchClientClientTypeCode") && document.getElementById("searchClientClientTypeCode").focus()
          else if (fieldSelected === "searchClientClientClassCode")
            document.getElementById("searchClientClientClassCode") && document.getElementById("searchClientClientClassCode").focus()
          else if (fieldSelected === "searchClientSituation")
            document.getElementById("searchClientSituation") && document.getElementById("searchClientSituation").focus()
          else
            document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jClient)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "CLIENT/SearchRegistration", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os clientes. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchClientCorporateName")
              document.getElementById("searchClientCorporateName") && document.getElementById("searchClientCorporateName").focus()
            else if (fieldSelected === "searchClientEstablishmentCode")
              document.getElementById("searchClientEstablishmentCode") && document.getElementById("searchClientEstablishmentCode").focus()
            else if (fieldSelected === "searchClientClientTypeCode")
              document.getElementById("searchClientClientTypeCode") && document.getElementById("searchClientClientTypeCode").focus()
            else if (fieldSelected === "searchClientClientClassCode")
              document.getElementById("searchClientClientClassCode") && document.getElementById("searchClientClientClassCode").focus()
            else if (fieldSelected === "searchClientSituation")
              document.getElementById("searchClientSituation") && document.getElementById("searchClientSituation").focus()
            else
              document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()

            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchClientCorporateName")
            document.getElementById("searchClientCorporateName") && document.getElementById("searchClientCorporateName").focus()
          else if (fieldSelected === "searchClientEstablishmentCode")
            document.getElementById("searchClientEstablishmentCode") && document.getElementById("searchClientEstablishmentCode").focus()
          else if (fieldSelected === "searchClientClientTypeCode")
            document.getElementById("searchClientClientTypeCode") && document.getElementById("searchClientClientTypeCode").focus()
          else if (fieldSelected === "searchClientClientClassCode")
            document.getElementById("searchClientClientClassCode") && document.getElementById("searchClientClientClassCode").focus()
          else if (fieldSelected === "searchClientSituation")
            document.getElementById("searchClientSituation") && document.getElementById("searchClientSituation").focus()
          else
            document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()         
        })
      })
    }
    else 
    {
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      if (fieldSelected === "searchClientCorporateName")
        document.getElementById("searchClientCorporateName") && document.getElementById("searchClientCorporateName").focus()
      else if (fieldSelected === "searchClientEstablishmentCode")
        document.getElementById("searchClientEstablishmentCode") && document.getElementById("searchClientEstablishmentCode").focus()
      else if (fieldSelected === "searchClientClientTypeCode")
        document.getElementById("searchClientClientTypeCode") && document.getElementById("searchClientClientTypeCode").focus()
      else if (fieldSelected === "searchClientClientClassCode")
        document.getElementById("searchClientClientClassCode") && document.getElementById("searchClientClientClassCode").focus()
      else if (fieldSelected === "searchClientSituation")
        document.getElementById("searchClientSituation") && document.getElementById("searchClientSituation").focus()
      else
        document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, firstAcess, pageCurrent, recordPage, cpfCnpj, corporateName,
    establishmentCode, clientTypeCode, clientClassCode, situation, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o cliente (" + item.CORPORATE_NAME + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setCpfCnpj("")
    setCorporateName("")
    setSituation(0)
    setEstablishmentCode(0)
    setClientTypeCode(0)
    setClientClassCode(0)

    setFieldSelected("searchClientCpfCnpj")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jClient = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jClient)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "CLIENT/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o cliente. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "CLIENT/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os clientes do SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchClientCpfCnpj") && document.getElementById("searchClientCpfCnpj").focus()
        return
      }

      returnJson.WARNING && setErrorImport(returnJson.WARNING)        

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean}
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    { errorImport &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{errorImport}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                    <div style={(showMenu) ? 
                        (width < 800) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : 
                        (width < 520) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1}}>

                      <div style={(showMenu) ? 
                          (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 190, maxWidth: 190}
                        : 
                          (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 190, maxWidth: 190}}>

                        <Input
                          type="search"
                          name="searchClientCpfCnpj"
                          description="CPF/CNPJ"
                          planceHolder=""
                          value={cpfCnpj}
                          maxLength={14}
                          disabled={false}
                          require={false}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setCpfCnpj(event.target.value)} />
                      </div>
                      <div style={(showMenu) ? 
                          (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                        : 
                          (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                        <Input
                          type="search"
                          name="searchClientCorporateName"
                          description="Nome/Razão social"
                          planceHolder="Mínimo 3 caracteres"
                          value={corporateName}
                          maxLength={60}
                          disabled={false}
                          require={false}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setCorporateName(event.target.value)} />
                      </div>
                    </div>

                    <div style={(showMenu) ?
                        (width < 900) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : 
                        (width < 620) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>

                      <div style={(showMenu) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                        <Select
                          name="searchClientEstablishmentCode"
                          description="Estabelecimento"
                          value={establishmentCode || 0}
                          disabled={false}
                          require={false}
                          options={JSON.parse(optionsEstablishment)}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setEstablishmentCode} />
                      </div>                    
                      <div style={(showMenu) ? 
                          (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                        : 
                          (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                        <Select
                          name="searchClientCodeClientType"
                          description="Tipo Cliente"
                          value={clientTypeCode || 0}
                          disabled={false}
                          require={false}
                          options={JSON.parse(optionsClientType)}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setClientTypeCode} />
                      </div>
                    </div>

                    <div style={(showMenu) ? 
                        (width < 900) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}                
                      : 
                        (width < 620) ? { display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : { display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>

                      <div style={(showMenu) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                        <Select
                          name="searchClientClientClassCode"
                          description="Classe Cliente"
                          value={clientClassCode || 0}
                          disabled={false}
                          require={false}
                          options={JSON.parse(optionsClientClass)}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setClientClassCode} />
                      </div>
                      <div style={(showMenu) ? 
                          (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, marginBottom: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, marginBottom: 10}
                        : 
                          (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, marginBottom: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, marginBottom: 10}}>

                        <Select
                          name="searchClientSituation"
                          description="Situação"
                          value={situation || 0}
                          require={false}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                            ",{\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setSituation} />
                      </div>
                    </div>

                    { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={(width < 480) ?
                                  {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}}>

                                <div style={(showMenu) ? 
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : 
                                    (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={showMenu ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 140,
                                      maxWidth: 140}
                                    : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 140, maxWidth: 140}}>
                                    <label style={{fontWeight: 800}}>CPF/CNPJ</label>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ? {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}
                                      : {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}}>
                                      <label style={{fontWeight: 800}}>Razão social</label>
                                    </div>

                                    <div style={showMenu ? {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}
                                      : {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}}>
                                      <label style={{fontWeight: 800}}>Nome Fantasia</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={(width < 330) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10,
                                    justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}}>

                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                    maxWidth: 32}} />

                                  { !modeSearch &&
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                      maxWidth: 32}}/>
                                  }
                                </div>
                              </div> 

                              <div style={showMenu ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}
                                : (width < 330) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, minWidth: 32, maxWidth: 32}
                                  : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={(width < 480) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}
                                  : 
                                    {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 140,
                                        maxWidth: 140}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 140, maxWidth: 140}}>
                                      <label>{item.CPF_CNPJ}</label>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={showMenu ? {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}
                                        : {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}}>
                                        <label>{item.CORPORATE_NAME}</label>
                                      </div>

                                      <div style={showMenu ? {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}
                                        : {display: "flex", flexGrow: 1, alignItems: "center", flexBasis: 1, marginLeft: 10}}>
                                        <label>{item.FANTASY_NAME}</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={(width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10,
                                      justifyContent: "center"}
                                    : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                      <img
                                        style={{height: 32, width: 32}}
                                        src={image_select}
                                        name="searchClientSelected"
                                        alt="Selecionar"
                                        onClick={(() => setSelected(item))} />
                                    </div>

                                    { !modeSearch &&
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                        <img
                                          style={{height: 28, width: 28}}
                                          src={image_delete}
                                          name="searchClientDeleted"
                                          alt="Deletar"
                                          onClick={() => callDelete(item)} />
                                      </div>
                                    }
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination 
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchClient