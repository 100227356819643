import React, { useState, useEffect } from "react"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import SearchParticipant from "./Search"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"

const Participant = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [documentNumber, setDocumentNumber] = useState("")
  const [situation, setSituation] = useState(0)
  const [accountNumber, setAccountNumber] = useState("")
  const [agencyNumber, setAgencyNumber] = useState("")
  const [accountType, setAccountType] = useState(1)
  const [saveVariation, setSaveVariation] = useState(0)
  const [ispbCode, setIspbCode] = useState(0)

  const [jsonParticipant, setJsonParticipant] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") 
    {
      setName("")
      setDocumentNumber("")
      setSituation(0)
      setAccountNumber("")
      setAgencyNumber("")
      setAccountType(1)
      setSaveVariation(0)
      setIspbCode(0)

      document.getElementById("participantDocumentNumber") && document.getElementById("participantDocumentNumber").focus()
    }        
    else 
    {
      setName(jsonParticipant.nomeParticipante)

      if (String(jsonParticipant.numeroDocumento).length <= 11) setDocumentNumber(String(jsonParticipant.numeroDocumento).padStart(11,'0'))
      else setDocumentNumber(String(jsonParticipant.numeroDocumento).padStart(14,'0'))

      setSituation(jsonParticipant.codigoEstadoParticipante)
      setAccountNumber(jsonParticipant.numeroConta)
      setAgencyNumber(jsonParticipant.numeroAgencia)
      setAccountType(jsonParticipant.tipoConta)
      setSaveVariation(jsonParticipant.variacaoPoupanca)
      setIspbCode(jsonParticipant.codigoIspb)

      document.getElementById("participantName") && document.getElementById("participantName").focus()
    }  
  }, [code, jsonParticipant, updateFocu])

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonParticipant(event)
    setCode(event.numeroParticipante)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Participante"
            titleSearch="Pesquisa dos Participantes"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchParticipant
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                          width: 147}} onClick={() => returnAdd()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={showMenu ?
                            (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1040) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 770) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                            <div style={(width < 380) ? {display: "flex", flexGrow: 1}
                              : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                              <Input
                                type="text"
                                name="participantCode"
                                description="Código"
                                planceHolder=""
                                value={code}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={showMenu ?
                                (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : (width < 1040) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 190}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 190, maxWidth: 190}
                              :
                                (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : (width < 770) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 190}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 190, maxWidth: 190}}>
                              <Input
                                type="text"
                                name="participantDocumentNumber"
                                description="CPF/CNPJ"
                                planceHolder=""
                                value={documentNumber}
                                maxLength={14}
                                disabled={true}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDocumentNumber(event.target.value)} />
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            :
                              (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="text"
                              name="participantName"
                              description="Nome"
                              planceHolder=""
                              value={name}
                              maxLength={60}
                              disabled={true}
                              require={true}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setName(event.target.value)} />
                          </div>
                        </div>

                        <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={(width < 420) ? {display: "flex", flexGrow: 1}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="participantAccountNumber"
                              description="Número Conta"
                              planceHolder=""
                              value={accountNumber}
                              maxLength={11}
                              disabled={true}
                              require={true}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setAccountNumber(event.target.value)} />
                          </div>
                          <div style={(width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="text"
                              name="participantAgencyNumber"
                              description="Número Agência"
                              planceHolder=""
                              value={agencyNumber}
                              maxLength={11}
                              disabled={true}
                              require={true}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setAgencyNumber(event.target.value)} />
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 900) ? {display: "flex", flexGrow: 1}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 630) ? {display: "flex", flexGrow: 1}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Select
                              name="participantAccountType"
                              description="Tipo Conta"
                              value={accountType}
                              disabled={true}
                              require={false}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Conta Corrente\" }"+
                                ", {\"ID\": 2, \"VALUE\": \"Conta Poupança\" }, {\"ID\": 3, \"VALUE\": \"Conta Salário\" }"+
                                    ", {\"ID\": 4, \"VALUE\": \"Pré Paga\" }] }")}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}                              
                              set={setAccountType} />
                          </div>

                          <div style={showMenu ?
                              (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :
                              (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 420) ? {display: "flex", flexGrow: 1}
                                : (width < 900) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, maxWidth: 180}
                              :
                                (width < 420) ? {display: "flex", flexGrow: 1}
                                : (width < 630) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, maxWidth: 180}}>
                              <Input
                                type="text"
                                name="participantSaveVariation"
                                description="Var. Poupança"
                                planceHolder=""
                                value={saveVariation}
                                maxLength={11}
                                disabled={(parseInt(accountType) === 2) ? false : true}
                                require={(parseInt(accountType) === 2) ? true : false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setSaveVariation(event.target.value)} />
                            </div>
                            <div style={showMenu ?
                                (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : (width < 900) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180, maxWidth: 180}
                              :
                                (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : (width < 630) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                              <Input
                                type="text"
                                name="participantIspbCode"
                                description="ISPB"
                                planceHolder=""
                                value={ispbCode}
                                maxLength={11}
                                disabled={true}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setIspbCode(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1}}>
                            <Select
                              name="participantSituation"
                              description="Situação"
                              value={situation}
                              disabled={true}
                              require={false}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Ativo\" }"+
                                ", {\"ID\": 1, \"VALUE\": \"Pendente\" }, {\"ID\": 999, \"VALUE\": \"Cancelado\" }] }")}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setSituation} />
                          </div>
                        </div>
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}    

export default Participant