import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import SearchBilletClient from "../BilletClient/Search"
import SearchBilletConfiguration from "../BilletConfiguration/Search"
import SearchCompany from "./Search"
import SearchCompanyBank from "../CompanyBank/Search"
import SearchCompanyEmail from "../CompanyEmail/Search"
import SearchCompanyPhone from "../CompanyPhone/Search"
import SearchEstablishment from "../Establishment/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Company = ({modeSearch, databaseType, databasePath, jsonClientOrigin, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [corporateName, setCorporateName] = useState("")
  const [fantasyName, setFantasyName] = useState("")

  const [addressRelease, setAddressRelease] = useState(false)

  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [cityIBGE, setCityIBGE] = useState("")
  const [uf, setUf] = useState("")
  const [ufIBGE, setUfIBGE] = useState("")
  const [countryCode, setCountryCode] = useState(0)
  const [countryDescription, setCountryDescription] = useState("")
  const [countryIBGE, setCountryIBGE] = useState("")

  const [responsibleCpf, setResponsibleCpf] = useState("")
  const [responsibleName, setResponsibleName] = useState("")
  const [homePage, setHomePage] = useState("")
  const [ie, setIe] = useState("")
  const [im, setIm] = useState("")

  const [establishmentCode, setEstablishmentCode] = useState("")
  const [establishmentDescription, setEstablishmentDescription] = useState("")

  const [species, setSpecies] = useState("")
  const [percentageCommision, setPercentageCommision] = useState(0)
  const [identificationAgreement, setIdentificationAgreement] = useState(0)
  const [identificationNature, setIdentificationNature] = useState(0)
  const [identificationFinality, setIdentificationFinality] = useState(0)
  const [suframa, setSuframa] = useState("")
  const [profile, setProfile] = useState(0)
  const [activity, setActivity] = useState(0)
  const [aliquotPis, setAliquotPis] = useState(0)
  const [aliquotCofins, setAliquotCofins] = useState(0)
  const [aliquotSimples, setAliquotSimples] = useState(0)
  const [aliquotIss, setAliquotIss] = useState(0)
  const [regime, setRegime] = useState(0)
  const [cnae, setCnae] = useState("")
  const [culture, setCulture] = useState(0)
  const [regimeSpecial, setRegimeSpecial] = useState(0)
  const [logo, setLogo] = useState("")
  const [nfsObservation, setNfsObservation] = useState("")
  const [orderObservation, setOrderObservation] = useState("")
  const [environment, setEnvironment] = useState(0)
  const [emissionType, setEmissionType] = useState(0)
  const [sendNfe, setSendNfe] = useState(0)
  const [sendNfce, setSendNfce] = useState(0)
  const [sendNfse, setSendNfse] = useState(0)
  const [saleType, setSaleType] = useState(0)
  const [seriesIss, setSeriesIss] = useState("")
  const [certificatePassword, setCertificatePassword] = useState("")
  const [situation, setSituation] = useState(0)

  const [jCompany, setJCompany] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showEstablishment, setShowEstablishment] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [national, setNational] = useState(true)
  const [listCountry, setListCountry] = useState([])
  const [optionsCountries, setOptionsCountries] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (firstAcess) {
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "COUNTRY/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os países. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          let country = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              country += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\"}"
            ))

          country += "]}"

          setListCountry(returnJson)
          setOptionsCountries(country)
          setCountryCode(34)

          setLoading("")
          setFirstAcess(false)
        }
      })
    }
    else{
      (countryDescription === "BRASIL") ? setNational(true) : setNational(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setResponsibleCpf("")
      setResponsibleName("")
      setHomePage("")
      setIe("")
      setIm("")
    
      setEstablishmentCode("")
      setEstablishmentDescription("")

      setSpecies("")
      setPercentageCommision("")
      setIdentificationAgreement(0)
      setIdentificationNature(0)
      setIdentificationFinality(0)
      setSuframa("")
      setProfile(0)
      setActivity(0)
      setAliquotPis("")
      setAliquotCofins("")
      setAliquotSimples("")
      setAliquotIss("")
      setSeriesIss("")
      setRegime(0)
      setCnae("")
      setCulture(0)
      setRegimeSpecial(0)
      setLogo("")
      setNfsObservation("")
      setOrderObservation("")
      setEnvironment(0)
      setEmissionType(0)
      setSendNfe(0)
      setSendNfce(0)
      setSendNfse(0)
      setSaleType(0)
      setCertificatePassword("")
      setSituation(0)
    }
    else {
      // Múltipla, Geral, Detetização, Licitação, Feira, ou Lene
      if ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) || 
        (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) {

        setCpfCnpj(jCompany.CPF_CNPJ)
        setCorporateName(jCompany.CORPORATE_NAME)
        setFantasyName(jCompany.FANTASY_NAME)
        setCep(jCompany.CEP)
        setAddress(jCompany.ADDRESS)
        setNumber(jCompany.NUMBER)

        if ((jCompany.COMPLEMENT !== null) && (jCompany.COMPLEMENT !== undefined)) setComplement(jCompany.COMPLEMENT)
        else setComplement("")

        setNeighborhood(jCompany.NEIGHBORHOOD)
        setCity(jCompany.CITY)
        setCityIBGE(jCompany.CITY_IBGE)
        setUf(jCompany.UF)
        setUfIBGE(jCompany.UF_IBGE)
        
        if (jCompany.COUNTRY_DESCRIPTION === "BRASIL") setNational(true)
        else setNational(false)
                
        let codeP = 0;
        listCountry.OBJECT.map(item => (
          (item.NAME === jCompany.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
        ))
        
        setCountryCode(codeP)
        setCountryDescription(jCompany.COUNTRY_DESCRIPTION)
        setCountryIBGE(jCompany.COUNTRY_IBGE)

        setResponsibleCpf(jCompany.RESPONSIBLE_CPF)
        setResponsibleName(jCompany.RESPONSIBLE_NAME)        

        if ((jCompany.HOME_PAGE !== null) && (jCompany.HOME_PAGE !== undefined)) setHomePage(jCompany.HOME_PAGE)
        else setHomePage("")

        setIe(jCompany.IE)

        if ((jCompany.IM !== null) && (jCompany.IM !== undefined)) setIm(jCompany.IM)
        else setIm("")

        setEstablishmentCode(jCompany.ESTABLISHMENT.ID)
        setEstablishmentDescription(jCompany.ESTABLISHMENT.DESCRIPTION)
        setSpecies(jCompany.SPECIES)
        
        if (jCompany.PERCENTAGE_COMMISSION > 0) setPercentageCommision(formatNumeric(jCompany.PERCENTAGE_COMMISSION, 2))
        else setPercentageCommision("0.00")
                
        setIdentificationAgreement(jCompany.IDENTIFICATION_AGREEMENT)
        setIdentificationNature(jCompany.IDENTIFICATION_NATURE)
        setIdentificationFinality(jCompany.IDENTIFICATION_FINALITY)
        setSuframa(jCompany.SUFRAMA)
        setProfile(jCompany.PROFILE)
        setActivity(jCompany.ACTIVITY)
                
        if (jCompany.ALIQUOT_PIS > 0) setAliquotPis(formatNumeric(jCompany.ALIQUOT_PIS, 2))
        else setAliquotPis("0.00")
        
        if (jCompany.ALIQUOT_COFINS > 0) setAliquotCofins(formatNumeric(jCompany.ALIQUOT_COFINS, 2))
        else setAliquotCofins("0.00")
        
        if (jCompany.ALIQUOT_SIMPLES > 0) setAliquotSimples(formatNumeric(jCompany.ALIQUOT_SIMPLES, 2))
        else setAliquotSimples("0.00")
        
        if (jCompany.ALIQUOT_ISS > 0) setAliquotIss(formatNumeric(jCompany.ALIQUOT_ISS, 2))
        else setAliquotIss("0.00")
        
        if ((jCompany.SERIES_ISS !== null) &&  (jCompany.SERIES_ISS !== undefined)) setSeriesIss(jCompany.SERIES_ISS)
        else setSeriesIss("")

        setRegime(jCompany.REGIME)

        if ((jCompany.CNAE !== null) && (jCompany.CNAE !== undefined)) setCnae(jCompany.CNAE)
        else setCnae("")

        setCulture(jCompany.CULTURE)
        setRegimeSpecial(jCompany.REGIME_SPECIAL)

        if ((jCompany.LOGO !== null) && (jCompany.LOGO !== undefined)) setLogo(jCompany.LOGO)
        else setLogo("")

        if ((jCompany.NFS_OBSERVATION !== null) && (jCompany.NFS_OBSERVATION !== undefined)) setNfsObservation(jCompany.NFS_OBSERVATION)
        else setNfsObservation("")

        if ((jCompany.ORDER_OBSERVATION !== null) && (jCompany.ORDER_OBSERVATION !== undefined)) setOrderObservation(jCompany.ORDER_OBSERVATION)
        else setOrderObservation("")
        
        setEnvironment(jCompany.ENVIRONMENT)
        setEmissionType(jCompany.EMISSION_TYPE)
        setSendNfe(jCompany.SEND_NFE)
        setSendNfce(jCompany.SEND_NFCE)
        setSendNfse(jCompany.SEND_NFSE)
        setSaleType(jCompany.SALE_TYPE)

        if (jCompany.CERTIFICATE_PASSWORD !== null) setCertificatePassword(jCompany.CERTIFICATE_PASSWORD)
        else setCertificatePassword("")

        setSituation(jCompany.SITUATION)
      }

      // Jm3d
      if (jsonCompany.TYPE === 7) {
        setCpfCnpj(jCompany.CPF_CNPJ)
        setCorporateName(jCompany.CORPORATE_NAME)
        setFantasyName(jCompany.FANTASY_NAME)

        setCep(jCompany.CEP)
        setAddress(jCompany.ADDRESS)
        setNumber(jCompany.NUMBER)

        if ((jCompany.COMPLEMENT !== null) && (jCompany.COMPLEMENT !== undefined)) setComplement(jCompany.COMPLEMENT)
        else setComplement("")

        setNeighborhood(jCompany.NEIGHBORHOOD)
        setCity(jCompany.CITY)
        setCityIBGE(jCompany.CITY_IBGE)
        setUf(jCompany.UF)
        setUfIBGE(jCompany.UF_IBGE)
        
        if (jCompany.COUNTRY_DESCRIPTION === "BRASIL") setNational(true)
        else setNational(false)
                
        let codeP = 0;
        listCountry.OBJECT.map(item => (
          (item.NAME === jCompany.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
        ))
        
        setCountryCode(codeP)
        setCountryDescription(jCompany.COUNTRY_DESCRIPTION)
        setCountryIBGE(jCompany.COUNTRY_IBGE)
        setResponsibleCpf(jCompany.RESPONSIBLE_CPF)
        setResponsibleName(jCompany.RESPONSIBLE_NAME)

        if ((jCompany.HOME_PAGE !== null) && (jCompany.HOME_PAGE !== undefined)) setHomePage(jCompany.HOME_PAGE)
        else setHomePage("")

        setIe(jCompany.IE)

        if ((jCompany.IM !== null) && (jCompany.IM !== undefined)) setIm(jCompany.IM)
        else setIm("")

        setEstablishmentCode(jCompany.ESTABLISHMENT.ID)
        setEstablishmentDescription(jCompany.ESTABLISHMENT.DESCRIPTION)
        setSpecies(jCompany.SPECIES)
        setSuframa(jCompany.SUFRAMA)

        if ((jCompany.CNAE !== null) && (jCompany.CNAE !== undefined)) setCnae(jCompany.CNAE)
        else setCnae("")

        if ((jCompany.LOGO !== null) && (jCompany.LOGO !== undefined)) setLogo(jCompany.LOGO)
        else setLogo("")

        setSituation(jCompany.SITUATION)
      }
    }

    document.getElementById("companyCpfCnpj") && document.getElementById("companyCpfCnpj").focus()
    
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, jCompany, code, countryDescription, listCountry.OBJECT, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setResponsibleCpf("")
      setResponsibleName("")
      setHomePage("")
      setIe("")
      setIm("")

      setEstablishmentCode("")
      setEstablishmentDescription("")
    
      setSpecies("")
      setPercentageCommision("")
      setIdentificationAgreement(0)
      setIdentificationNature(0)
      setIdentificationFinality(0)
      setSuframa("")
      setProfile(0)
      setActivity(0)
      setAliquotPis("")
      setAliquotCofins("")
      setAliquotSimples("")
      setAliquotIss("")
      setSeriesIss("")
      setRegime(0)
      setCnae("")
      setCulture(0)
      setRegimeSpecial(0)
      setLogo("")
      setNfsObservation("")
      setOrderObservation("")
      setEnvironment(0)
      setEmissionType(0)
      setSendNfe(0)
      setSendNfce(0)
      setSendNfse(0)
      setSaleType(0)
      setCertificatePassword("")
      setSituation(0)
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const clearAddress = () => {
    setCep("")
    setAddress("")
    setNumber("")
    setComplement("")
    setNeighborhood("")
    setCity("")
    setCityIBGE("")
    setUf("")
    setUfIBGE("")

    setAddressRelease(false)
  }

  const cleanEstablishment = () => {
    setEstablishmentCode("")
    setEstablishmentDescription("")

    setShowEstablishment(false)
  }

  const enableEstablishment = () => {
    setShowEstablishment(!showEstablishment)
  }

  const fillEstablishment = (establishment) => {
    setEstablishmentCode(establishment.ID)
    setEstablishmentDescription(establishment.DESCRIPTION)
  
    setShowEstablishment(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }

  const handleFile = (e) => {
    const _file = e.target.files[0];
    uploadPhoto(_file)
  }  

  const handleAliquotCofins = (event) => {
    setAliquotCofins(formatNumeric(event.target.value, 2))
  }

  const handleAliquotIss = (event) => {
    setAliquotIss(formatNumeric(event.target.value, 2))
  }

  const handleAliquotPis = (event) => {
    setAliquotPis(formatNumeric(event.target.value, 2))
  }

  const handleAliquotSimples = (event) => {
    setAliquotSimples(formatNumeric(event.target.value, 2))
  }

  const handlePercentageCommision = (event) => {
    setPercentageCommision(formatNumeric(event.target.value, 0))
  }

  const previousAddGeral = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const previousAddJm3d = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const saveGeral = () => {
    let returnJson = []
    let jsonCompany_New = {}

    jsonCompany_New = "{ \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\""

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonCompany_New += ", \"ID\": "+ code    
    jsonCompany_New += ", \"CPF_CNPJ\": \""+ cpfCnpj +"\"" +
    ", \"CORPORATE_NAME\": \""+ corporateName +"\""

    if (((fantasyName !== undefined) && (fantasyName !== null)) && (fantasyName !== "")) 
      jsonCompany_New += ", \"FANTASY_NAME\": \""+ fantasyName +"\""
    else jsonCompany_New += ", \"FANTASY_NAME\": \""+ corporateName +"\""
        
    if (((cep !== undefined) && (cep !== null)) && (cep !== "")) jsonCompany_New += ", \"CEP\": "+ cep        
    jsonCompany_New += ", \"ADDRESS\": \""+ address +"\""
    jsonCompany_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== "")) 
      jsonCompany_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonCompany_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonCompany_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIBGE !== undefined) && (cityIBGE !== null)) && (cityIBGE !== ""))
        jsonCompany_New += ", \"CITY_IBGE\": "+ cityIBGE
    } else jsonCompany_New += ", \"CITY_IBGE\": "+ 9999999
        
    jsonCompany_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIBGE !== undefined) && (ufIBGE !== null)) && (ufIBGE !== ""))
        jsonCompany_New += ", \"UF_IBGE\": "+ ufIBGE
    } else jsonCompany_New += ", \"UF_IBGE\": "+ 99

    jsonCompany_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonCompany_New += ", \"COUNTRY_IBGE\": "+ countryIBGE
    else jsonCompany_New += ", \"COUNTRY_IBGE\": "+ 9999
        
    jsonCompany_New += ", \"RESPONSIBLE_CPF\": \""+ responsibleCpf +"\"" +
    ", \"RESPONSIBLE_NAME\": \""+ responsibleName +"\""

    if (((homePage !== undefined) && (homePage !== null)) && (homePage !== "")) jsonCompany_New += ", \"HOME_PAGE\": \""+ homePage +"\""

    if (((ie !== undefined) && (ie !== null)) && (ie !== "")) jsonCompany_New += ", \"IE\": \""+ ie +"\"";
    else jsonCompany_New += ", \"IE\": \"ISENTO\""

    if (((im !== undefined) && (im !== null)) && (im !== "")) jsonCompany_New += ", \"IM\": \""+ im +"\""

    let cEstablishment = 0
    if (((establishmentCode !== undefined) && (establishmentCode !== null)) && (establishmentCode !== "")) 
      cEstablishment = establishmentCode
    jsonCompany_New += ", \"ESTABLISHMENT\": { \"ID\": "+ cEstablishment +", \"DESCRIPTION\": \""+ establishmentDescription +"\" }"

    if (((species !== undefined) && (species !== null)) && (species !== "")) jsonCompany_New += ", \"SPECIES\": "+ species
    else jsonCompany_New += ", \"SPECIES\": 0"

    if (((percentageCommision !== undefined) && (percentageCommision !== null)) && (percentageCommision !== ""))
      jsonCompany_New += ", \"PERCENTAGE_COMMISSION\": "+ percentageCommision
    else jsonCompany_New += ", \"PERCENTAGE_COMMISSION\": "+ 0

    jsonCompany_New += ", \"IDENTIFICATION_AGREEMENT\": "+ identificationAgreement
    jsonCompany_New += ", \"IDENTIFICATION_NATURE\": "+ identificationNature
    jsonCompany_New += ", \"IDENTIFICATION_FINALITY\": "+ identificationFinality
        
    if (((suframa !== undefined) && (suframa !== null)) && (suframa !== "")) jsonCompany_New += ", \"SUFRAMA\": \""+ suframa +"\""

    jsonCompany_New += ", \"PROFILE\": "+ profile
    jsonCompany_New += ", \"ACTIVITY\": "+ activity

    if (((aliquotPis !== undefined) && (aliquotPis !== null)) && (aliquotPis !== "")) 
      jsonCompany_New += ", \"ALIQUOT_PIS\": "+ aliquotPis; else jsonCompany_New += ", \"ALIQUOT_PIS\": "+ 0
    if (((aliquotCofins !== undefined) && (aliquotCofins !== null)) && (aliquotCofins !== ""))
      jsonCompany_New += ", \"ALIQUOT_COFINS\": "+ aliquotCofins; else jsonCompany_New += ", \"ALIQUOT_COFINS\": "+ 0
    if (((aliquotSimples !== undefined) && (aliquotSimples !== null)) && (aliquotSimples !== ""))
      jsonCompany_New += ", \"ALIQUOT_SIMPLES\": "+ aliquotSimples; else jsonCompany_New += ", \"ALIQUOT_SIMPLES\": "+ 0
    if (((aliquotIss !== undefined) && (aliquotIss !== null)) && (aliquotIss !== ""))
      jsonCompany_New += ", \"ALIQUOT_ISS\": "+ aliquotIss; else jsonCompany_New += ", \"ALIQUOT_ISS\": "+ 0
    if (((seriesIss !== undefined) && (seriesIss !== null)) && (seriesIss !== ""))
      jsonCompany_New += ", \"SERIES_ISS\": \""+ seriesIss.replaceAll("_","") +"\""

    jsonCompany_New += ", \"REGIME\": "+ regime

    if (((cnae !== undefined) && (cnae !== null)) && (cnae !== "")) jsonCompany_New += ", \"CNAE\": \""+ cnae +"\""

    jsonCompany_New += ", \"CULTURE\": "+ culture
    jsonCompany_New += ", \"REGIME_SPECIAL\": "+ regimeSpecial

    if (((logo !== undefined) && (logo !== null)) && (logo !== "")) jsonCompany_New += ", \"LOGO\": \""+ logo.replaceAll("\\","\\\\") +"\""

    if (((nfsObservation !== undefined) && (nfsObservation !== null)) && (nfsObservation !== ""))
      jsonCompany_New += ", \"NFS_OBSERVATION\": \""+ nfsObservation +"\""
    if (((orderObservation !== undefined) && (orderObservation !== null)) && (orderObservation !== ""))
      jsonCompany_New += ", \"ORDER_OBSERVATION\": \""+ orderObservation +"\""

    jsonCompany_New += ", \"ENVIRONMENT\": "+ environment
    jsonCompany_New += ", \"EMISSION_TYPE\": "+ emissionType
    jsonCompany_New += ", \"SEND_NFE\": "+ sendNfe
    jsonCompany_New += ", \"SEND_NFCE\": "+ sendNfce
    jsonCompany_New += ", \"SEND_NFSE\": "+ sendNfse
    jsonCompany_New += ", \"SALE_TYPE\": "+ saleType

    if (((certificatePassword !== undefined) && (certificatePassword !== null)) && (certificatePassword !== ""))
      jsonCompany_New += ", \"CERTIFICATE_PASSWORD\": \""+ certificatePassword+ "\""

    jsonCompany_New += ", \"SITUATION\": "+ situation
    jsonCompany_New += " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonCompany_New)
      formData.append("JSON_NEW_2", jsonClientOrigin)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "COMPANY/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cpfCnpjCompany") && document.getElementById("cpfCnpjCompany").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJCompany(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })            
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jCompany))
      formData.append("JSON_NEW_1", jsonCompany_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "COMPANY/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cpfCnpjCompany") && document.getElementById("cpfCnpjCompany").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJCompany(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const saveJm3d = () => {
    let returnJson = []
    let jsonCompany_New = {}

    jsonCompany_New = "{ \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\""

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonCompany_New += ", \"ID\": "+ code    
    jsonCompany_New += ", \"CPF_CNPJ\": \""+ cpfCnpj +"\"" +
    ", \"CORPORATE_NAME\": \""+ corporateName +"\""

    if (((fantasyName !== undefined) && (fantasyName !== null)) && (fantasyName !== "")) 
      jsonCompany_New += ", \"FANTASY_NAME\": \""+ fantasyName +"\""
    else jsonCompany_New += ", \"FANTASY_NAME\": \""+ corporateName +"\""
        
    if (((cep !== undefined) && (cep !== null)) && (cep !== "")) jsonCompany_New += ", \"CEP\": "+ cep
    jsonCompany_New += ", \"ADDRESS\": \""+ address +"\""
    jsonCompany_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== "")) 
      jsonCompany_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonCompany_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonCompany_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIBGE !== undefined) && (cityIBGE !== null)) && (cityIBGE !== ""))
        jsonCompany_New += ", \"CITY_IBGE\": "+ cityIBGE
    } else jsonCompany_New += ", \"CITY_IBGE\": "+ 9999999
        
    jsonCompany_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIBGE !== undefined) && (ufIBGE !== null)) && (ufIBGE !== ""))
        jsonCompany_New += ", \"UF_IBGE\": "+ ufIBGE
    } else jsonCompany_New += ", \"UF_IBGE\": "+ 99

    jsonCompany_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonCompany_New += ", \"COUNTRY_IBGE\": "+ countryIBGE
    else jsonCompany_New += ", \"COUNTRY_IBGE\": "+ 9999
    
    jsonCompany_New += ", \"RESPONSIBLE_CPF\": \""+ responsibleCpf +"\"" +
    ", \"RESPONSIBLE_NAME\": \""+ responsibleName +"\""

    if (((homePage !== undefined) && (homePage !== null)) && (homePage !== "")) jsonCompany_New += ", \"HOME_PAGE\": \""+ homePage +"\""

    if (((ie !== undefined) && (ie !== null)) && (ie !== "")) jsonCompany_New += ", \"IE\": \""+ ie +"\"";
    else jsonCompany_New += ", \"IE\": \"ISENTO\""

    if (((im !== undefined) && (im !== null)) && (im !== "")) jsonCompany_New += ", \"IM\": \""+ im +"\""

    let cEstablishment = 0
    if (((establishmentCode !== undefined) && (establishmentCode !== null)) && (establishmentCode !== "")) 
      cEstablishment = establishmentCode
    jsonCompany_New += ", \"ESTABLISHMENT\": { \"ID\": "+ cEstablishment +", \"DESCRIPTION\": \""+ establishmentDescription +"\" }"

    if (((species !== undefined) && (species !== null)) && (species !== "")) jsonCompany_New += ", \"SPECIES\": "+ species
    else jsonCompany_New += ", \"SPECIES\": 0"

    if (((percentageCommision !== undefined) && (percentageCommision !== null)) && (percentageCommision !== ""))
      jsonCompany_New += ", \"PERCENTAGE_COMMISSION\": "+ percentageCommision
    else jsonCompany_New += ", \"PERCENTAGE_COMMISSION\": "+ 0

    jsonCompany_New += ", \"IDENTIFICATION_AGREEMENT\": 3" +
    ", \"IDENTIFICATION_NATURE\": 3" +
    ", \"IDENTIFICATION_FINALITY\": 1" +
    ", \"PROFILE\": 2" +
    ", \"ACTIVITY\": 2" +
    ", \"ALIQUOT_PIS\": 0" +
    ", \"ALIQUOT_COFINS\": 0" +
    ", \"ALIQUOT_SIMPLES\": 0" +
    ", \"ALIQUOT_ISS\": 0" +
    ", \"REGIME\": 1" +
    ", \"CULTURE\": 2" +
    ", \"REGIME_SPECIAL\": 1"

    if (((logo !== undefined) && (logo !== null)) && (logo !== "")) jsonCompany_New += ", \"LOGO\": \""+ logo.replaceAll("\\","\\\\") +"\""

    jsonCompany_New += ", \"ENVIRONMENT\": 1" +
    ", \"EMISSION_TYPE\": 1" +
    ", \"SEND_NFE\": 1" +
    ", \"SEND_NFCE\": 1" +
    ", \"SEND_NFSE\": 1" +
    ", \"SALE_TYPE\": 1" +
    ", \"SITUATION\": " + situation + "} "

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonCompany_New)
      formData.append("JSON_NEW_2", jsonClientOrigin)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "COMPANY/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cpfCnpjCompany") && document.getElementById("cpfCnpjCompany").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJCompany(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })            
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jCompany))
      formData.append("JSON_NEW_1", jsonCompany_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "COMPANY/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cpfCnpjCompany") && document.getElementById("cpfCnpjCompany").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJCompany(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const searchCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setWarning("")
      setError("É necessário informar o CEP.")

      return
    }

    setLoading("Aguarde buscando o CEP...")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {
      if (response.data.erro !== "true") {
        setWarning("")
        setError("")

        if ((response.data.logradouro === '') && (response.data.bairro === '')) setAddressRelease(true)
        else setAddressRelease(false)

        setAddress(response.data.logradouro)
        setNeighborhood(response.data.bairro)
        setCity(response.data.localidade)
        setCityIBGE(response.data.ibge)
        setUf(response.data.uf)

        if (response.data.uf === "RO") setUfIBGE(11)
        if (response.data.uf === "AC") setUfIBGE(12)
        if (response.data.uf === "AM") setUfIBGE(13)
        if (response.data.uf === "RR") setUfIBGE(14)
        if (response.data.uf === "PA") setUfIBGE(15)
        if (response.data.uf === "AP") setUfIBGE(16)
        if (response.data.uf === "TO") setUfIBGE(17)
        if (response.data.uf === "MA") setUfIBGE(21)
        if (response.data.uf === "PI") setUfIBGE(22)
        if (response.data.uf === "CE") setUfIBGE(23)
        if (response.data.uf === "RN") setUfIBGE(24)
        if (response.data.uf === "PB") setUfIBGE(25)
        if (response.data.uf === "PE") setUfIBGE(26)
        if (response.data.uf === "AL") setUfIBGE(27)
        if (response.data.uf === "SE") setUfIBGE(28)
        if (response.data.uf === "BA") setUfIBGE(29)
        if (response.data.uf === "MG") setUfIBGE(31)
        if (response.data.uf === "ES") setUfIBGE(32)
        if (response.data.uf === "RJ") setUfIBGE(33)
        if (response.data.uf === "SP") setUfIBGE(35)
        if (response.data.uf === "PR") setUfIBGE(41)
        if (response.data.uf === "SC") setUfIBGE(42)
        if (response.data.uf === "RS") setUfIBGE(43)
        if (response.data.uf === "MS") setUfIBGE(50)
        if (response.data.uf === "MT") setUfIBGE(51)
        if (response.data.uf === "GO") setUfIBGE(52)
        if (response.data.uf === "DF") setUfIBGE(53)
      } 
    else
    {
      setWarning("")
      setError("CEP não encontrado.")
      alert("CEP não encontrado.")

      setAddress("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }
    })
    .catch(() => {
      setWarning("")
      setError("Não foi possível obter CEP.")
      alert("Não foi possível obter CEP.")
    }) 
    .finally(() => { setLoading("") })
  }

  const selectCountry = (event) => {
    let itemP = {}
    listCountry.OBJECT.map(item => (
      (item.ID ===  parseInt(event)) ? itemP = item : ""
    ))

    setCountryCode(itemP.ID)
    setCountryDescription(itemP.NAME)
    setCountryIBGE(itemP.IBGE)
  }  

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJCompany(event)
    setCode(event.ID)
  }

  const uploadPhoto = async(e) => {
    let returnJson = []

    setLoading("Aguarde efetuando o upload da foto da filial...")
    setWarning("")
    setError("")

    const jsCompany = "{ \"ID\": "+ jCompany.ID +", \"CPF_CNPJ\": \""+ jCompany.CPF_CNPJ  +"\" }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", "{}")
    formData.append("JSON_NEW_1", jsCompany)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("FILE", e);
       
    axios.post(BASE_URL + "COMPANY/UploadPhoto", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar a filial. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("companyCpfCnpj") && document.getElementById("companyCpfCnpj").focus()
        return
      }

      returnJson.WARNING ? setWarning("Foto inserida com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Foto inserida com sucesso!")

      let sCompany = ""

      if ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) ||  
        (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) {

        sCompany += "{ \"ID\": "+ jCompany.ID +
        ", \"CPF_CNPJ\": \"" + jCompany.CPF_CNPJ + "\"" +
        ", \"CORPORATE_NAME\": \"" + jCompany.CORPORATE_NAME + "\"" +
        ", \"FANTASY_NAME\": \"" + jCompany.FANTASY_NAME + "\"" +
        ", \"CEP\": \"" + jCompany.CEP + "\"" +
        ", \"ADDRESS\": \"" + jCompany.ADDRESS + "\"" +
        ", \"NUMBER\": \"" + jCompany.NUMBER + "\""
          
        if ((jCompany.COMPLEMENT !== undefined) && (jCompany.COMPLEMENT !== null)) sCompany += ", \"COMPLEMENT\": \"" + jCompany.COMPLEMENT + "\""
          
        sCompany += ", \"NEIGHBORHOOD\": \"" + jCompany.NEIGHBORHOOD + "\"" +
        ", \"CITY\": \"" + jCompany.CITY + "\"" +
        ", \"CITY_IBGE\": \"" + jCompany.CITY_IBGE + "\"" +
        ", \"UF\": \"" + jCompany.UF + "\"" +
        ", \"UF_IBGE\": \"" + jCompany.UF_IBGE + "\"" +
        ", \"COUNTRY_DESCRIPTION\": \"" + jCompany.COUNTRY_DESCRIPTION + "\"" +
        ", \"COUNTRY_IBGE\": \"" + jCompany.COUNTRY_IBGE + "\""
          
        sCompany += ", \"RESPONSIBLE_CPF\": \"" + jCompany.RESPONSIBLE_CPF + "\"" +
        ", \"RESPONSIBLE_NAME\": \"" + jCompany.RESPONSIBLE_NAME + "\""

        if ((jCompany.HOME_PAGE !== undefined) && (jCompany.HOME_PAGE !== null)) sCompany += ", \"HOME_PAGE\": \"" + jCompany.HOME_PAGE + "\""
          
        sCompany += ", \"IE\": \"" + jCompany.IE + "\""
          
        if ((jCompany.IM !== undefined) && (jCompany.IM !== null)) sCompany += ", \"IM\": \"" + jCompany.IM + "\""
          
        sCompany += ", \"ESTABLISHMENT\": { \"ID\": "+ jCompany.ESTABLISHMENT.ID +
        ", \"DESCRIPTION\": \""+ jCompany.ESTABLISHMENT.DESCRIPTION +"\" }" +
        ", \"SPECIES\": \"" + jCompany.SPECIES + "\"" +
        ", \"PERCENTAGE_COMMISSION\": " + jCompany.PERCENTAGE_COMMISSION +
        ", \"IDENTIFICATION_AGREEMENT\": " + jCompany.IDENTIFICATION_AGREEMENT +
        ", \"IDENTIFICATION_NATURE\": " + jCompany.IDENTIFICATION_NATURE +
        ", \"IDENTIFICATION_FINALITY\": " + jCompany.IDENTIFICATION_FINALITY
          
        if ((jCompany.SUFRAMA !== undefined) && (jCompany.SUFRAMA !== null)) sCompany += ", \"SUFRAMA\": \"" + jCompany.SUFRAMA + "\""
          
        sCompany += ", \"PROFILE\": " + jCompany.PROFILE +
        ", \"ACTIVITY\": " + jCompany.ACTIVITY +
        ", \"ALIQUOT_PIS\": " + jCompany.ALIQUOT_PIS +
        ", \"ALIQUOT_COFINS\": " + jCompany.ALIQUOT_COFINS +
        ", \"ALIQUOT_SIMPLES\": " + jCompany.ALIQUOT_SIMPLES +
        ", \"ALIQUOT_ISS\": " + jCompany.ALIQUOT_ISS
          
        if ((jCompany.SERIES_ISS !== undefined) && (jCompany.SERIES_ISS !== null)) sCompany += ", \"SERIES_ISS\": \"" + jCompany.SERIES_ISS + "\""
          
        sCompany += ", \"REGIME\": " + jCompany.REGIME
          
        if ((jCompany.CNAE !== undefined) && (jCompany.CNAE !== null)) sCompany += ", \"CNAE\": \"" + jCompany.CNAE + "\""
          
        sCompany += ", \"CULTURE\": " + jCompany.CULTURE +
        ", \"REGIME_SPECIAL\": " + jCompany.REGIME_SPECIAL +
        ", \"LOGO\": \"" +  String(returnJson.OBJECT) + "\""

        if ((jCompany.NFS_OBSERVATION !== undefined) && (jCompany.NFS_OBSERVATION !== null))
          sCompany += ", \"NFS_OBSERVATION\": \"" + jCompany.NFS_OBSERVATION + "\""

        if ((jCompany.ORDER_OBSERVATION !== undefined) && (jCompany.ORDER_OBSERVATION !== null))
          sCompany += ", \"ORDER_OBSERVATION\": \"" + jCompany.ORDER_OBSERVATION + "\""
          
        sCompany += ", \"ENVIRONMENT\": " + jCompany.ENVIRONMENT +
        ", \"EMISSION_TYPE\": " + jCompany.EMISSION_TYPE +
        ", \"SEND_NFE\": " + jCompany.SEND_NFE +
        ", \"SEND_NFCE\": " + jCompany.SEND_NFCE +
        ", \"SEND_NFSE\": " + jCompany.SEND_NFSE +
        ", \"SALE_TYPE\": " + jCompany.SALE_TYPE

        if ((jCompany.CERTIFICATE_PASSWORD !== undefined) && (jCompany.CERTIFICATE_PASSWORD !== null))
          sCompany += ", \"CERTIFICATE_PASSWORD\": \""+ jCompany.CERTIFICATE_PASSWORD + "\""
        
        sCompany += ", \"SITUATION\": " + jCompany.SITUATION + "} "
      }

      // Jm3d
      if (jsonCompany.TYPE === 7) {
         sCompany += "{ \"ID\": "+ jCompany.ID +
        ", \"CPF_CNPJ\": \"" + jCompany.CPF_CNPJ + "\"" +
        ", \"CORPORATE_NAME\": \"" + jCompany.CORPORATE_NAME + "\"" +
        ", \"FANTASY_NAME\": \"" + jCompany.FANTASY_NAME + "\"" +
        ", \"CEP\": \"" + jCompany.CEP + "\"" +
        ", \"ADDRESS\": \"" + jCompany.ADDRESS + "\"" +
        ", \"NUMBER\": \"" + jCompany.NUMBER + "\""
      
        if ((jCompany.COMPLEMENT !== undefined) && (jCompany.COMPLEMENT !== null)) sCompany += ", \"COMPLEMENT\": \"" + jCompany.COMPLEMENT + "\""
      
        sCompany += ", \"NEIGHBORHOOD\": \"" + jCompany.NEIGHBORHOOD + "\"" +
        ", \"CITY\": \"" + jCompany.CITY + "\"" +
        ", \"CITY_IBGE\": \"" + jCompany.CITY_IBGE + "\"" +
        ", \"UF\": \"" + jCompany.UF + "\"" +
        ", \"UF_IBGE\": \"" + jCompany.UF_IBGE + "\"" +
        ", \"COUNTRY_DESCRIPTION\": \"" + jCompany.COUNTRY_DESCRIPTION + "\"" +
        ", \"COUNTRY_IBGE\": \"" + jCompany.COUNTRY_IBGE + "\""
      
        sCompany += ", \"RESPONSIBLE_CPF\": \"" + jCompany.RESPONSIBLE_CPF + "\"" +
        ", \"RESPONSIBLE_NAME\": \"" + jCompany.RESPONSIBLE_NAME + "\""

        if ((jCompany.HOME_PAGE !== undefined) && (jCompany.HOME_PAGE !== null)) sCompany += ", \"HOME_PAGE\": \"" + jCompany.HOME_PAGE + "\""
      
        sCompany += ", \"IE\": \"" + jCompany.IE + "\""
      
        if ((jCompany.IM !== undefined) && (jCompany.IM !== null)) sCompany += ", \"IM\": \"" + jCompany.IM + "\""
      
        sCompany += ", \"ESTABLISHMENT\": { \"ID\": "+ jCompany.ESTABLISHMENT.ID +
        ", \"DESCRIPTION\": \""+ jCompany.ESTABLISHMENT.DESCRIPTION +"\" }" +
        ", \"SPECIES\": \"" + jCompany.SPECIES + "\"" +
        ", \"PERCENTAGE_COMMISSION\": " + jCompany.PERCENTAGE_COMMISSION +
        ", \"IDENTIFICATION_AGREEMENT\": " + jCompany.IDENTIFICATION_AGREEMENT +
        ", \"IDENTIFICATION_NATURE\": " + jCompany.IDENTIFICATION_NATURE +
        ", \"IDENTIFICATION_FINALITY\": " + jCompany.IDENTIFICATION_FINALITY
      
        if ((jCompany.SUFRAMA !== undefined)  && (jCompany.SUFRAMA !== null)) sCompany += ", \"SUFRAMA\": \"" + jCompany.SUFRAMA + "\""
      
        sCompany += ", \"PROFILE\": " + jCompany.PROFILE +
        ", \"ACTIVITY\": " + jCompany.ACTIVITY +
        ", \"ALIQUOT_PIS\": " + jCompany.ALIQUOT_PIS +
        ", \"ALIQUOT_COFINS\": " + jCompany.ALIQUOT_COFINS +
        ", \"ALIQUOT_SIMPLES\": " + jCompany.ALIQUOT_SIMPLES +
        ", \"ALIQUOT_ISS\": " + jCompany.ALIQUOT_ISS
          
        if ((jCompany.SERIES_ISS !== undefined) && (jCompany.SERIES_ISS !== null)) sCompany += ", \"SERIES_ISS\": \"" + jCompany.SERIES_ISS + "\""
          
        sCompany += ", \"REGIME\": " + jCompany.REGIME
      
        if ((jCompany.CNAE !== undefined) && (jCompany.CNAE !== null)) sCompany += ", \"CNAE\": \"" + jCompany.CNAE + "\""
      
        sCompany += ", \"CULTURE\": " + jCompany.CULTURE +
        ", \"REGIME_SPECIAL\": " + jCompany.REGIME_SPECIAL
      
        sCompany += ", \"LOGO\": \"" + String(returnJson.OBJECT) + "\"" +
        ", \"ENVIRONMENT\": " + jCompany.ENVIRONMENT +
        ", \"EMISSION_TYPE\": " + jCompany.EMISSION_TYPE +
        ", \"SEND_NFE\": " + jCompany.SEND_NFE +
        ", \"SEND_NFCE\": " + jCompany.SEND_NFCE +
        ", \"SEND_NFSE\": " + jCompany.SEND_NFSE +
        ", \"SALE_TYPE\": " + jCompany.SALE_TYPE +
        ", \"SITUATION\": " + jCompany.SITUATION + "} "      
      }

      setJCompany(sCompany)
      setLoading("")
      setUpdateFocu(updateFocu + 1)
    })
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Filial"
            titleSearch="Pesquisa das Filiais"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          {/* Múltipla, Geral, Detetização, Licitação, Feira, ou Lene */}
          { ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) || 
            (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) &&

            <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}}>
              { showSearch ?
                  <SearchCompany
                    modeSearch={modeSearch}
                    databaseType={databaseType}
                    databasePath={databasePath}
                    jsonLogin={jsonLogin}
                    jsonCompany={jsonCompany}
                    system={system}
                    setAdd={setAdd}
                    setSelected={setSelected}
                    setReturn={setReturn} 
                    showMenu={showMenu}
                    width={width} />
                :
                  <>
                    { loading ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                          <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                        </div>
                      :
                        <>
                          { !showEstablishment ?
                              <>
                                <ButtonsCadastre save={() => saveGeral()} add={() => add()} returnAdd={() => previousAddGeral()} />

                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}}>
                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10,
                                    paddingBottom: 0, paddingLeft: 10}}>
                                    { warning && 
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center",
                                        marginTop: 10, marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                      </div> 
                                    }

                                    { error &&
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center",
                                        marginTop: 10, marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                      </div>
                                    }
                                  </div>

                                  { (code === "") ?
                                      <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <Input
                                            type="text"
                                            name="companyCpfCnpj"
                                            description="CPF/CNPJ"
                                            value={cpfCnpj}
                                            maxLength={14}
                                            disabled={false}
                                            require={true}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setCpfCnpj(event.target.value)} />
                                        </div>
                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companySituation"
                                            description="Situação"
                                            value={situation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSituation} />
                                        </div>
                                      </div>
                                    :
                                      <>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                          <Input 
                                            type="file"
                                            name="companyLogo"
                                            title=""
                                            maxLength={255}
                                            typeImage={1}
                                            disabled={false}
                                            placeholder=""
                                            grow={1}
                                            accept=".jpg,.jpeg,.png,.bmp"
                                            pathPhoto={logo}
                                            onChange={handleFile} />
                                        </div>
                                      
                                        <div style={showMenu ?
                                            (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                            : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                          :
                                            (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 180}}>
                                              <Input
                                                type="text"
                                                name="companyCode"
                                                description="Código"
                                                value={code}
                                                maxLength={11}
                                                disabled={true}
                                                require={false}
                                                image={false}
                                                typeInput={2}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setCode(event.target.value)} />
                                            </div>
                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                              : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                              <Input
                                                type="text"
                                                name="companyCpfCnpj"
                                                description="CPF/CNPJ"
                                                value={cpfCnpj}
                                                maxLength={14}
                                                disabled={false}
                                                require={true}
                                                image={false}
                                                typeInput={2}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setCpfCnpj(event.target.value)} />
                                            </div>
                                          </div>
                                          <div style={showMenu ?
                                              (width < 620) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                                              : (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                              : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                                            :
                                              (width < 620) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                              : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                                            <Select
                                              name="companySituation"
                                              description="Situação"
                                              value={situation || 0}
                                              require={false}
                                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                                ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              set={setSituation} />
                                          </div>
                                        </div>
                                      </>
                                  }

                                  <div style={showMenu ?
                                      (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={showMenu ?
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyCorporateName"
                                        description="Nome/Razão social"
                                        value={corporateName}
                                        maxLength={60}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCorporateName(event.target.value)} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <Input
                                        type="text"
                                        name="companyFantasyName"
                                        description="Nome fantasia"
                                        value={fantasyName}
                                        maxLength={60}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setFantasyName(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyResponsibleCpf"
                                        description="CPF Reponsável"
                                        value={responsibleCpf}
                                        maxLength={11}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setResponsibleCpf(event.target.value)} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyResponsibleName"
                                        description="Nome Responsável"
                                        value={responsibleName}
                                        maxLength={60}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setResponsibleName(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyHomePage"
                                        description="Home Page"
                                        value={homePage}
                                        maxLength={70}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setHomePage(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyIe"
                                        description="IE"
                                        value={ie}
                                        maxLength={14}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setIe(event.target.value)} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <Input
                                        type="text"
                                        name="companyIm"
                                        description="IM"
                                        value={im}
                                        maxLength={15}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setIm(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companySuframa"
                                          description="SUFRAMA"
                                          value={suframa}
                                          maxLength={9}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setSuframa(event.target.value)} />
                                      </div>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Input
                                          type="text"
                                          name="companyCnae"
                                          description="CNAE"
                                          value={cnae}
                                          maxLength={9}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCnae(event.target.value)} />
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                      :
                                        (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <InputNumeric
                                        type="text"
                                        name="companyPercentageCommision"
                                        description="Comissão"
                                        value={percentageCommision || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handlePercentageCommision} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                    <Titulo description="Endereço" />
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                      : {paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                      : {paddingLeft: 10, paddingRight: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <Select
                                            name="companyCountryCode"
                                            description="País"
                                            value={countryCode || 0}
                                            disabled={false}
                                            require={false}
                                            options={JSON.parse(optionsCountries)}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={selectCountry} />
                                        </div>
                                        <div style={(showMenu) ?
                                            (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                            : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                          :   
                                            (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                          <Input
                                            type="text"
                                            name="companyCep"
                                            description="CEP"
                                            value={cep}
                                            maxLength={8}
                                            disabled={false}
                                            require={national ? true : false}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setCep(event.target.value)} />
                                        </div>
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                        : 
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                                        { (national && (!addressRelease)) &&
                                          <img
                                            style={{height: 42, width: 42, marginLeft: 10}}
                                            name="companySearchAddress"
                                            src={image_search} 
                                            alt="Pesquisar" 
                                            onClick={() => searchCEP()} />
                                        }

                                        <img
                                          style={{height: 42, width: 42, marginLeft: 10}}
                                          name="companyClearAddress"
                                          src={image_clean} 
                                          alt="Limpar" 
                                          onClick={clearAddress} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : 
                                        (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyAddress"
                                          description="Logradouro"
                                          value={address}
                                          maxLength={60}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setAddress(event.target.value)} />
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                        :
                                          (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                        <div style={(showMenu) ?
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                          :
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                          <Input
                                            type="text"
                                            name="companyNumber"
                                            description="Número"
                                            value={number}
                                            maxLength={60}
                                            disabled={false}
                                            require={true}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setNumber(event.target.value)} />
                                        </div>
                                        <div style={(showMenu) ?
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                          :
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                          <Input
                                            type="text"
                                            name="companyComplement"
                                            description="Complemento"
                                            value={complement}
                                            maxLength={60}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setComplement(event.target.value)} />
                                        </div>
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyNeighborhood"
                                          description="Bairro"
                                          value={neighborhood}
                                          maxLength={60}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setNeighborhood(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="companyCity"
                                          description="Cidade"
                                          value={city}
                                          maxLength={60}
                                          disabled={national ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setCity(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyUf"
                                          description="Estado"
                                          value={uf}
                                          maxLength={2}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setUf(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            
                                        <Input
                                          type="text"
                                          name="companyCountryDescription"
                                          description="País"
                                          value={countryDescription}
                                          maxLength={60}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setCountryDescription(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}}>

                                    <Titulo description="Estabelecimento" />

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                        <Input
                                          type="text"
                                          name="companyEstablishmentDescription"
                                          description="Descrição"
                                          planceHolder=""
                                          value={establishmentDescription}
                                          maxLength={80}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setEstablishmentDescription(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                            marginTop: 10}
                                          : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                            marginTop: 10}
                                          : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>

                                        <img 
                                          style={{height: 42, width: 42}}
                                          name="companyEstablishmentSearch"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={enableEstablishment} />

                                        <img 
                                          style={{marginLeft: 10, height: 42, width: 42}}
                                          name="companyEstablishmentClean"
                                          src={image_clean} 
                                          alt="Limpar"
                                          onClick={cleanEstablishment} />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}}>

                                    <Titulo description="Fiscal" />

                                    <div style={showMenu ?
                                        (width < 450) ? { paddingLeft: 0, paddingRight: 0 }
                                        : (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                      : 
                                        (width < 450) ? { paddingLeft: 0, paddingRight: 0 }
                                        : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="companySpecies"
                                            description="Espécie"
                                            value={species || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Matriz\" }, {\"ID\": 2, \"VALUE\": \"Filial\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSpecies} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companyProfile"
                                            description="Perfil"
                                            value={profile || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"A\" }, {\"ID\": 2, \"VALUE\": \"B\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setProfile} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1260) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Select
                                          name="companyActivity"
                                          description="Atividade"
                                          value={activity || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrial ou equiparado\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Outros\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setActivity} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 1240) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}}>

                                      <div style={showMenu ?
                                          (width < 520) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 780) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 520) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                        <div style={showMenu ?
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="companyIdentificationAgreement"
                                            description="Identificação do Convênio"
                                            value={identificationAgreement || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"57/95 31/99\" }"+
                                              ", {\"ID\": 2, \"VALUE\": \"57/95 69/02\" }"+
                                              ", {\"ID\": 3, \"VALUE\": \"57/95 76/03\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setIdentificationAgreement} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companyIdentificationNature"
                                            description="Identificação da Natureza"
                                            value={identificationNature || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Interestaduais ST\" }"+
                                              ", {\"ID\": 2, \"VALUE\": \"Interestaduais Total\" }"+
                                              ", {\"ID\": 3, \"VALUE\": \"Total\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setIdentificationNature} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1240) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Select
                                          name="companyIdentificationFinality"
                                          description="Identificação da Finalidade"
                                          value={identificationFinality || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Normal\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Retificação Total\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Retificação Aditiva\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Desfazimento\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setIdentificationFinality} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}                                    
                                        : (width < 1280) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 1220) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, 
                                          marginTop: 10}}>

                                      <div style={showMenu ?
                                          (width < 520) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 520) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                        <div style={showMenu ?
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="companyCulture"
                                            description="Incentivo a Cultura"
                                            value={culture || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setCulture} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          : 
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companyRegime"
                                            description="Regime"
                                            value={regime || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Simples\" }, {\"ID\": 2, \"VALUE\": \"Lucro Presumido\" }"+
                                              ", {\"ID\": 3, \"VALUE\": \"Lucro Real\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setRegime} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1280) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 1220) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Select
                                          name="companyRegimeSpecial"
                                          description="Regime Especial"
                                          value={regimeSpecial || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Microempresa Municipal\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Estimativa\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Sociedade de Profissionais\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Cooperativa\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"MEI Simples Nacional\" }"+
                                            ", {\"ID\": 6, \"VALUE\": \"ME EPP Simples Nacional\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setRegimeSpecial} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0,
                                        marginTop: 10}
                                        : (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                        paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}
                                      :   
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0,
                                        marginTop: 10}
                                        : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                        paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}}>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                          <Select
                                            name="companySaleType"
                                            description="Tipo venda"
                                            value={saleType || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Simples\" }, {\"ID\": 2, \"VALUE\": \"Pedido\" }"+
                                              ", {\"ID\": 3, \"VALUE\": \"Restaurante\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSaleType} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                              
                                          <Select
                                            name="companyEnvironment"
                                            description="Tipo ambiente"
                                            value={environment || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Produção\" }, {\"ID\": 2, \"VALUE\": \"Homologacao\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEnvironment} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1160) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Select
                                          name="emissionType"
                                          description="Tipo emissão"
                                          value={emissionType || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Normal\" }, {\"ID\": 2, \"VALUE\": \"FS-IA\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"SCAN\" }, {\"ID\": 4, \"VALUE\": \"DPEC\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"FS-DA\" }, {\"ID\": 6, \"VALUE\": \"SVC-AN\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"SVC-RS\" }, {\"ID\": 9, \"VALUE\": \"Contingência NFCe\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmissionType} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                        paddingRight: 0, marginTop: 10}
                                        : (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                        paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                        paddingRight: 0, marginTop: 10}
                                        : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                        paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}}>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="companySendNfe"
                                            description="Emite NFe"
                                            value={sendNfe || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSendNfe} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companySendNfce"
                                            description="Emite NFCe"
                                            value={sendNfce || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSendNfce} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        : 
                                          (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Select
                                          name="companySendNfse"
                                          description="Emite NFSe"
                                          value={sendNfse || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setSendNfse} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}}>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="companyAliquotPis"
                                            description="Alíquota PIS"
                                            value={aliquotPis || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleAliquotPis} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <InputNumeric
                                            type="text"
                                            name="companyAliquotCofins"
                                            description="Alíquota COFINS"
                                            value={aliquotCofins || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleAliquotCofins} />
                                        </div>
                                      </div>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                                          : (width < 1160) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                                          : (width < 860) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 1160) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>

                                          <InputNumeric
                                            type="text"
                                            name="companyAliquotSimples"
                                            description="Alíquota Simples"
                                            value={aliquotSimples || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleAliquotSimples} />
                                        </div>
                                        <div style={showMenu ?
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                          <InputNumeric
                                            type="text"
                                            name="companyAliquotIss"
                                            description="Alíquota ISS"
                                            value={aliquotIss || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleAliquotIss} />
                                        </div>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}}>
                                      <div style={showMenu ?
                                          (width < 1160) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        :
                                          {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyNfsObservation"
                                          description="Observação NFS"
                                          value={nfsObservation}
                                          maxLength={65535}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setNfsObservation(event.target.value)} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1160) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 860) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Input
                                          type="text"
                                          name="companyOrderObservation"
                                          description="Observação Pedido"
                                          value={orderObservation}
                                          maxLength={65535}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setOrderObservation(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                          paddingRight: 10, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                          marginTop: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0,
                                          paddingRight: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                                        marginTop: 10}}>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyCertificatePassword"
                                          description="Senha Certificado"
                                          value={certificatePassword}
                                          maxLength={8}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCertificatePassword(event.target.value)} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                          : (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Input
                                          type="text"
                                          name="companySeriesIss"
                                          description="Série ISS"
                                          value={seriesIss}
                                          maxLength={6}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setSeriesIss(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>

                                  {/* telefones */}
                                  { code &&
                                    <div style={{marginTop: 10}}>
                                      <Titulo description="Telefones" />

                                      <SearchCompanyPhone
                                        databaseType={databaseType}
                                        databasePath={databasePath}
                                        jsonEmployee={jsonLogin}
                                        jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                        jsonLogin={jsonLogin}
                                        system={system} 
                                        showMenu={showMenu}
                                        width={width} />
                                    </div>
                                  }

                                  {/* email */}
                                  { code &&
                                    <div>
                                      <Titulo description="Emails" />

                                      <SearchCompanyEmail
                                        databaseType={databaseType}
                                        databasePath={databasePath}
                                        jsonEmployee={jsonLogin}
                                        jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                        jsonLogin={jsonLogin}
                                        system={system} 
                                        showMenu={showMenu}
                                        width={width} />
                                    </div>
                                  }    

                                  {/* configuração dos boletos */}
                                  { code &&
                                    <div className="titulo_filial">
                                      <div className="paddingLeftRight10_filial marginTop10_filial">
                                        <Titulo description="Configuração dos Boletos" />
                                      </div>

                                      <div className="marginBottom10_filial">
                                        <SearchBilletConfiguration
                                          databaseType={databaseType}
                                          databasePath={databasePath}
                                          jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                          jsonLogin={jsonLogin}
                                          system={system}
                                          showMenu={showMenu}
                                          width={width} />
                                      </div>
                                    </div>
                                  }

                                  {/* configuração dos bancos */}
                                  { code &&
                                    <div className="titulo_filial">
                                      <div className="paddingLeftRight10_filial marginTop10_filial">
                                        <Titulo description="Configuração dos Bancos" />
                                      </div>

                                      <div className="marginBottom10_filial">
                                        <SearchCompanyBank
                                          databaseType={databaseType}
                                          databasePath={databasePath}
                                          jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                          jsonLogin={jsonLogin}
                                          system={system}
                                          showMenu={showMenu}
                                          width={width} />
                                      </div>
                                    </div>
                                  }

                                  {/* Múltipla */}
                                  { (jsonCompany.TYPE === 1) && code &&
                                    <div className="titulo_filial">
                                      <div className="paddingLeftRight10_filial marginTop10_filial">
                                        <Titulo description="Clientes do Boleto" />
                                      </div>

                                      <div className="marginBottom10_filial">
                                        <SearchBilletClient
                                          databaseType={databaseType}
                                          databasePath={databasePath}
                                          jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                          jsonLogin={jsonLogin}
                                          system={system}
                                          showMenu={showMenu}
                                          width={width} />
                                      </div>
                                    </div>
                                  }                                  
                                </div>
                              </>
                            :
                              <div style={{marginTop: 10}}>
                                <div style={{marginLeft: 10}}>
                                  <b>Selecione o estabelecimento:</b>
                                </div>

                                <SearchEstablishment
                                  modeSearch={true}
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonLogin={jsonLogin}
                                  jsonCompany={jsonCompany}
                                  system={system}
                                  setAdd={() => alert("")}
                                  setSelected={fillEstablishment} 
                                  setReturn={() => setShowEstablishment(false)} 
                                  width={width} />
                              </div>
                          }
                        </>
                    }
                  </>
              }
            </div>
          }

          {/* Jm3d */}
          { jsonCompany.TYPE === 7 &&
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
              { showSearch ?
                  <SearchCompany
                    modeSearch={modeSearch}
                    databaseType={databaseType}
                    databasePath={databasePath}
                    jsonLogin={jsonLogin}
                    jsonCompany={jsonCompany}
                    system={system}
                    setAdd={setAdd}
                    setSelected={setSelected}
                    setReturn={setReturn} 
                    showMenu={showMenu}
                    width={width} />
                :
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                    { loading ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                          <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                        </div>
                      :
                        <>
                          { !showEstablishment ?
                              <>
                                <ButtonsCadastre save={() => saveJm3d()} add={() => add()} returnAdd={() => previousAddJm3d()} />

                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                                </div>

                                <div style={{paddingLeft: 20, paddingRight: 20}}>
                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10,
                                    paddingBottom: 0, paddingLeft: 10}}>
                                    { warning && 
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center",
                                        marginTop: 10, marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                      </div> 
                                    }

                                    { error &&
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center",
                                        marginTop: 10, marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                      </div>
                                    }
                                  </div>

                                  { (code === "") ?
                                      <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <Input
                                            type="text"
                                            name="companyCpfCnpj"
                                            description="CPF/CNPJ"
                                            value={cpfCnpj}
                                            maxLength={14}
                                            disabled={false}
                                            require={true}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setCpfCnpj(event.target.value)} />
                                        </div>
                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companySituation"
                                            description="Situação"
                                            value={situation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSituation} />
                                        </div>
                                      </div>
                                    :
                                      <div style={showMenu ?
                                          (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                          : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        :
                                          (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                        <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 180}}>
                                            <Input
                                              type="text"
                                              name="companyCode"
                                              description="Código"
                                              value={code}
                                              maxLength={11}
                                              disabled={true}
                                              require={false}
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setCode(event.target.value)} />
                                          </div>
                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                            <Input
                                              type="text"
                                              name="companyCpfCnpj"
                                              description="CPF/CNPJ"
                                              value={cpfCnpj}
                                              maxLength={14}
                                              disabled={false}
                                              require={true}
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setCpfCnpj(event.target.value)} />
                                          </div>
                                        </div>
                                        <div style={showMenu ?
                                            (width < 620) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                                            : (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                                          :
                                            (width < 620) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                            : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                                          <Select
                                            name="companySituation"
                                            description="Situação"
                                            value={situation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setSituation} />
                                        </div>
                                      </div>
                                  }

                                  <div style={showMenu ?
                                      (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={showMenu ?
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyCorporateName"
                                        description="Nome/Razão social"
                                        value={corporateName}
                                        maxLength={60}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCorporateName(event.target.value)} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <Input
                                        type="text"
                                        name="companyFantasyName"
                                        description="Nome fantasia"
                                        value={fantasyName}
                                        maxLength={60}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setFantasyName(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyResponsibleCpf"
                                        description="CPF Reponsável"
                                        value={responsibleCpf}
                                        maxLength={11}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setResponsibleCpf(event.target.value)} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyResponsibleName"
                                        description="Nome Responsável"
                                        value={responsibleName}
                                        maxLength={60}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setResponsibleName(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyHomePage"
                                        description="Home Page"
                                        value={homePage}
                                        maxLength={70}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setHomePage(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="companyIe"
                                        description="IE"
                                        value={ie}
                                        maxLength={14}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setIe(event.target.value)} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <Input
                                        type="text"
                                        name="companyIm"
                                        description="IM"
                                        value={im}
                                        maxLength={15}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setIm(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companySuframa"
                                          description="SUFRAMA"
                                          value={suframa}
                                          maxLength={9}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setSuframa(event.target.value)} />
                                      </div>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                        <Input
                                          type="text"
                                          name="companyCnae"
                                          description="CNAE"
                                          value={cnae}
                                          maxLength={9}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCnae(event.target.value)} />
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                      :
                                        (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      <Select
                                        name="companySpecies"
                                        description="Espécie"
                                        value={species || 0}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Matriz\" }, {\"ID\": 2, \"VALUE\": \"Filial\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setSpecies} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                    <Titulo description="Endereço" />
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                      : {paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                      : {paddingLeft: 10, paddingRight: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <Select
                                            name="companyCountryCode"
                                            description="País"
                                            value={countryCode || 0}
                                            disabled={false}
                                            require={false}
                                            options={JSON.parse(optionsCountries)}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={selectCountry} />
                                        </div>
                                        <div style={(showMenu) ?
                                            (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                            : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                          :   
                                            (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                          <Input
                                            type="text"
                                            name="companyCep"
                                            description="CEP"
                                            value={cep}
                                            maxLength={8}
                                            disabled={false}
                                            require={national ? true : false}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setCep(event.target.value)} />
                                        </div>
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                        : 
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                                        { (national && (!addressRelease)) &&
                                          <img
                                            style={{height: 42, width: 42, marginLeft: 10}}
                                            name="companySearchAddress"
                                            src={image_search} 
                                            alt="Pesquisar" 
                                            onClick={() => searchCEP()} />
                                        }

                                        <img
                                          style={{height: 42, width: 42, marginLeft: 10}}
                                          name="companyClearAddress"
                                          src={image_clean} 
                                          alt="Limpar" 
                                          onClick={clearAddress} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      : 
                                        (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyAddress"
                                          description="Logradouro"
                                          value={address}
                                          maxLength={60}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setAddress(event.target.value)} />
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                        :
                                          (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                        <div style={(showMenu) ?
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                          :
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                          <Input
                                            type="text"
                                            name="companyNumber"
                                            description="Número"
                                            value={number}
                                            maxLength={60}
                                            disabled={false}
                                            require={true}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setNumber(event.target.value)} />
                                        </div>
                                        <div style={(showMenu) ?
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                          :
                                            (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                          <Input
                                            type="text"
                                            name="companyComplement"
                                            description="Complemento"
                                            value={complement}
                                            maxLength={60}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeImage={0}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setComplement(event.target.value)} />
                                        </div>
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyNeighborhood"
                                          description="Bairro"
                                          value={neighborhood}
                                          maxLength={60}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setNeighborhood(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="companyCity"
                                          description="Cidade"
                                          value={city}
                                          maxLength={60}
                                          disabled={national ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setCity(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                      :
                                        (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="companyUf"
                                          description="Estado"
                                          value={uf}
                                          maxLength={2}
                                          disabled={(national && (!addressRelease)) ? true : false}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setUf(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            
                                        <Input
                                          type="text"
                                          name="companyCountryDescription"
                                          description="País"
                                          value={countryDescription}
                                          maxLength={60}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setCountryDescription(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginLeft: 10}}>

                                    <Titulo description="Estabelecimento" />

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10, 
                                          marginBottom: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10,
                                          marginBottom: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                        <Input
                                          type="text"
                                          name="companyEstablishmentDescription"
                                          description="Descrição"
                                          planceHolder=""
                                          value={establishmentDescription}
                                          maxLength={80}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setEstablishmentDescription(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                            marginTop: 10}
                                          : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                            marginTop: 10}
                                          : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>

                                        <img 
                                          style={{height: 42, width: 42}}
                                          name="companyEstablishmentSearch"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={enableEstablishment} />

                                        <img 
                                          style={{marginLeft: 10, height: 42, width: 42}}
                                          name="companyEstablishmentClean"
                                          src={image_clean} 
                                          alt="Limpar"
                                          onClick={cleanEstablishment} />
                                      </div>
                                    </div>
                                  </div>

                                  {/* telefones */}
                                  { code &&
                                    <div>
                                      <Titulo description="Telefones" />

                                      <SearchCompanyPhone
                                        databaseType={databaseType}
                                        databasePath={databasePath}
                                        jsonEmployee={jsonLogin}
                                        jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                        jsonLogin={jsonLogin}
                                        system={system}
                                        showMenu={showMenu}
                                        width={width} />
                                    </div>
                                  }

                                  {/* email */}
                                  { code &&
                                    <div>
                                      <Titulo description="Emails" />

                                      <SearchCompanyEmail
                                        databaseType={databaseType}
                                        databasePath={databasePath}
                                        jsonEmployee={jsonLogin}
                                        jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                        jsonLogin={jsonLogin}
                                        system={system} 
                                        showMenu={showMenu}
                                        width={width} />
                                    </div>
                                  }

                                  {/* configuração dos boletos */}
                                  { code &&
                                    <div className="titulo_filial">
                                      <div className="paddingLeftRight10_filial marginTop10_filial">
                                        <Titulo description="Configuração dos Boletos" />
                                      </div>

                                      <div className="marginBottom10_filial">
                                        <SearchBilletConfiguration
                                          databaseType={databaseType}
                                          databasePath={databasePath}
                                          jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                          jsonLogin={jsonLogin}
                                          system={system}
                                          showMenu={showMenu}
                                          width={width} />
                                      </div>
                                    </div>
                                  }

                                  {/* configuração dos bancos */}
                                  { code &&
                                    <div className="titulo_filial">
                                      <div className="paddingLeftRight10_filial marginTop10_filial">
                                        <Titulo description="Configuração dos Bancos" />
                                      </div>

                                      <div className="marginBottom10_filial">
                                        <SearchCompanyBank
                                          databaseType={databaseType}
                                          databasePath={databasePath}
                                          jsonCompany={JSON.parse("{ \"ID\": "+ code +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
                                          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\"}")}
                                          jsonLogin={jsonLogin}
                                          system={system}
                                          showMenu={showMenu}
                                          width={width} />
                                      </div>
                                    </div>
                                  }

                                </div>
                              </>
                            :
                              <div style={{marginTop: 10}}>
                                <div style={{marginLeft: 10}}>
                                  <b>Selecione o estabelecimento:</b>
                                </div>

                                <SearchEstablishment
                                  modeSearch={true}
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonLogin={jsonLogin}
                                  jsonCompany={jsonCompany}
                                  system={system}
                                  setAdd={() => alert("")}
                                  setSelected={fillEstablishment} 
                                  setReturn={() => setShowEstablishment(false)} 
                                  width={width} />
                              </div>
                          }
                        </>
                    }
                  </div>
              }
            </div>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Company