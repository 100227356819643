import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import NfSeries from "../"
import Questionnaire from "../../../Components/Questionnaire"
import SearchPagination from "../../../Components/SearchPagination"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchNfSeries = ({databaseType, databasePath, jsonNfModel, jsonLogin, jsonCompany, system, showMenu, height, width}) => {
  const [code, setCode] = useState("")

  const [showUpdate, setShowUpdate] = useState(false)

  const [jsonNfSeries, setJsonNfSeries] = useState("")

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jNfSerie = "{ \"NF_MODEL\": { \"CODE\": \""+ jsonNfModel.CODE +"\" } }"

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jNfSerie)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "NF_SERIES/TotalRecords", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar o total de Séries. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      const tRecord = returnJson.OBJECT
      if (tRecord === 0)
      {
        setWarning("Nenhum registro encontrado.")
        setLoading("")

        setResult({})
        return
      }

      const operation = tRecord / recordPage
      const numberR = Math.floor(operation)
      const decimal = operation % 1

      let tPage = 0
      if (decimal > 0) tPage = numberR + 1
      else tPage = numberR

      if (tRecord > 600)
        setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
          ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

      setTotalRecord(tRecord)
      setTotalPage(tPage)
      if (tPage < pageCurrent) setPageCurrent(1)

      returnJson.WARNING && setWarning(returnJson.WARNING)
    
      let qtdeInitial = 0
      if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jNfSerie)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", qtdeInitial)
      formData.append("QUANTITY_RECORDS", recordPage)

      axios.post(BASE_URL + "NF_SERIES/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as Séries. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
          
        setResult(returnJson)
        setLoading("")
      })
    })
  }, [databaseType, databasePath, jsonNfModel, jsonLogin, jsonCompany, system, pageCurrent, recordPage, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)

    let sMessage = "Deseja realmente excluir a série (Modelo: "+ item.NF_MODEL.CODE    
    sMessage += ", Série: " + item.SERIES 
    if (item.SUBSERIES != null) sMessage += ", Sub-Série: "+ item.SUBSERIES
    sMessage += ") ?"

    setAskQuestionnaire(sMessage)
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []
    let jNfSeries  = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jNfSeries)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "NF_SERIES/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir a série. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const previous = () => {
    setError("")
    setWarning("")

    setShowUpdate(false)
  }

  const save = (nfSeries) => {
    let returnJson = []    

    if (JSON.parse(nfSeries).ID === undefined)
    {
      setLoading("Aguarde incluindo o registro...")  
      setWarning("")
      setError("")
      
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", nfSeries)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "NF_SERIES/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a série. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        let sMessage = "Inclusão efetuada com sucesso (Modelo: "+ returnJson.OBJECT.NF_MODEL.CODE
        sMessage += ", Série: " + returnJson.OBJECT.SERIES
        if (returnJson.OBJECT.SUBSERIES != null) sMessage += ", Sub-Série: "+ returnJson.OBJECT.SUBSERIES
        sMessage += ") !"

        alert(sMessage)

        setLoading("")
        setShowUpdate(false)
        setUpdatePage(updatePage + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonNfSeries))
      formData.append("JSON_NEW_1", nfSeries)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "NF_SERIES/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a série. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        let sMessage = "Alteração efetuada com sucesso (Modelo: "+ returnJson.OBJECT.NF_MODEL.CODE        
        sMessage += ", Série: " + returnJson.OBJECT.SERIES
        if (returnJson.OBJECT.SUBSERIES != null) sMessage += ", Sub-Série: "+ returnJson.OBJECT.SUBSERIES
        sMessage += ") !"

        alert(sMessage)

        setLoading("")
        setShowUpdate(false)
        setUpdatePage(updatePage + 1)
      })
    }
  }

  const setAdd = () => {
    const jNfModel = JSON.parse(jsonNfModel)
    const jNfSeries = "{ \"NF_MODEL\": { \"CODE\": \""+ jNfModel.CODE +"\", \"DESCRIPTION\": \""+ jNfModel.DESCRIPTION +"\" }, \"TIPO\": 0 }"

    console.log(jNfSeries)

    setJsonNfSeries(JSON.parse(jNfSeries))
    setWarning("")
    setError("")

    setShowUpdate(true)
  }

  const setSelected = (nfSeries) => {
    setShowUpdate(true)
    setJsonNfSeries(nfSeries)
    setCode(nfSeries.CODE)
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  { warning && 
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", justifyContent: "center", marginTop: 10,
                      marginBottom: 10}}>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                    </div> 
                  }

                  { error &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", justifyContent: "center", marginTop: 10,
                      marginBottom: 10}}>
                      <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                    </div>
                  }

                  {showUpdate ?
                      <>
                        <NfSeries
                          jsonNfSeries={jsonNfSeries}
                          save={save}
                          previous={previous} 
                          showMenu={showMenu}
                          width={width} />
                      </>
                    :
                      <>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                            marginTop: 10, marginLeft: 5, width: 147}} onClick={() => setAdd()}>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                              paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                              <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                            </div>
                          </div>
                        </div>

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                        </div>
                        
                        <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                          { result.OBJECT &&
                            <>
                              {result.OBJECT &&
                                <>
                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                    borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}}>
                                    <div style={(showMenu) ? 
                                        (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ?
                                          (width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                          : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        :
                                          (width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80, marginLeft: 10}}>
                                          <labe style={{fontWeight: 600}}>Série</labe>
                                        </div>
                                        <div style={(showMenu) ? 
                                            (width < 290) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                            : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                            : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                            : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                          :
                                            (width < 290) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                            : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}}>
                                          <label style={{fontWeight: 600}}>Sub-Série</label>
                                        </div>
                                      </div>    
                                      <div style={(showMenu) ?
                                          (width < 920) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                        :
                                          (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                        <labe style={{fontWeight: 600}}>Modelo</labe>
                                      </div>
                                    </div>
                                  </div>

                                  { result.OBJECT.map(item => (
                                    <div key={item.ID} style={
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                      minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                      marginTop: 4, minWidth: 180}}>

                                      <div style={(showMenu) ? 
                                          (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                        <div style={showMenu ?
                                            (width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                            : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          : 
                                            (width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={{display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80, marginLeft: 12}}>
                                            <label>{item.SERIES}</label>
                                          </div>
                                          <div style={(showMenu) ? 
                                              (width < 290) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 12}
                                              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                              : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 12}
                                              : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                            : 
                                              (width < 290) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 12}
                                              : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}}>
                                            <label>{item.SUBSERIES}</label>
                                          </div>

                                        </div>
                                        <div style={(showMenu) ?
                                            (width < 920) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                            : {display: "flex", flexGrow: 1, minWidth: 180}
                                          :
                                            (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                            : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <label>
                                            {item.NF_MODEL.CODE + " - " + item.NF_MODEL.DESCRIPTION}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={(width < 490) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10,
                                          marginBottom: 10}
                                        : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                        <img
                                          style={{height: 32, width: 32}}
                                          src={image_select}
                                          name="searchNfSeriesSelect"
                                          alt="Selecionar"
                                          onClick={(() => setSelected(item))} />

                                        <img
                                          style={{height: 28, width: 28, marginTop: 2, marginLeft: 5}}
                                          name="searchNfSeriesDelete"
                                          src={image_delete}
                                          alt="Excluir"
                                          onClick={(() => callDelete(item))} />
                                      </div>
                                    </div>
                                    ))
                                  }

                                  <SearchPagination
                                    pageCurrent={pageCurrent}
                                    totalPage={totalPage}
                                    recordPage={recordPage}
                                    optionsRegPage={JSON.parse(optionsRegPage)}
                                    pagePrevious={pagePrevious}
                                    pageNext={pageNext}
                                    setSelectedField={setFieldSelected}
                                    handleRecordPage={handleRecordPage}
                                    width={width} />
                                </>
                              }
                            </>
                          }
                        </div>
                      </>
                  }
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchNfSeries