import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import InputMasked from "../../Components/InputMasked"
import ScanQrCode from "../ScanQrCode";
import image_check_circle from "../../Images/Buttons/check_circle.png"
import image_keyboard from "../../Images/Buttons/keyboard.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import DigitalKeyboardNumeric from "../../Components/DigitalKeyboardNumeric";

const LauchRestaurantNumber = ({databaseType, databasePath, jsonCompany, jsonLogin, system, typeLaunch, setSelect, setReturn, height, 
  width}) => {

  const [manual, setManual] = useState(false)
  const [updatePage, setUpdatePage] = useState(0)

  const [number, setNumber] = useState(false)  

  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")

  useEffect(() => {
    if (manual) document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
  }, [manual, updatePage])

  const confirm = (numb) => {
    let returnJson = []
    let nu = 0

    setError("")
    setLoading("Verificando a existência da mesa, Aguarde...")

    if (manual){
      if (number == ""){
        setLoading("")
        setError("É necessário informar o número!!!")
        document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
        return
      }
  
      nu = String(number).replaceAll("_","").replaceAll(" ","")
      if (nu == ""){
        setLoading("")
        setError("É necessário informar o número!!!")
        document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
        return
      }
  
      if (parseInt(nu) <= 0){
        setLoading("")
        setError("Número da mesa inválido!!!")
        document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
        return
      }

      const json = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"CPF_CNPJ\": \""+ jsonCompany.CPF_CNPJ +"\" }, \"NUMBER\": "+ nu +" }"

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", json)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 1)
  
      axios.post(BASE_URL + "REGISTER_TABLE/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível verificar a mesa. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setError("Nenhuma mesa cadastrada com o número informado!!!")
          setLoading("")
          document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()
          return
        }

        if (returnJson.OBJECT[0].SITUATION == 4)
        {
          setError("Essa mesa encontra-se reservada!!!")
          return
        }

        setSelect(returnJson.OBJECT[0].NUMBER)
        setLoading("")
      })
    }
    else{
      const json = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"CPF_CNPJ\": \""+ jsonCompany.CPF_CNPJ +"\" }"+
        ", \"QR_CODE\": \""+ numb +"\" }"

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", json)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
  
      axios.post(BASE_URL + "REGISTER_TABLE/CheckQrCode", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível verificar a mesa. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("lauchRestaurantNumber") && document.getElementById("lauchRestaurantNumber").focus()          
          return
        }

        if (returnJson.OBJECT.SITUATION == 4)
        {
          setError("Essa mesa encontra-se reservada!!!")
          return
        }

        setSelect(returnJson.OBJECT.NUMBER)
        setLoading("")
      })
    }
  }

  const pressEnter = () => {
    confirm()
  }

  const pressButton = (value) => {
    setNumber(number + value)
    setUpdatePage(updatePage +1)
  }

  const pressBackspace = () => {
    if (number != ""){
      const str = String(number).substring(0, number.length - 1);
      setNumber(str)      
    }

    setUpdatePage(updatePage +1)
  }

  const pressManual = () => {
    setManual(true)
  }

  const returnManual = () => {
    setNumber("")
    setError()
    setManual(false)
  }

  const selectError = (err) => {
    setError(err)
  }

  const selectNumber = (numb) => {
    if (numb == ""){
      setError("QrCode da mesa não informado!!!")
      return
    }

    confirm(numb)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",
              backgroundColor: "rgb(0, 177, 171)", height: 50}} />

            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
              <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
            </div>

            <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
              <Footer />
            </div>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      
            { manual ?
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",
                    backgroundColor: "rgb(0, 177, 171)", height: 50}}>
                    <label style={{textAlign: "center", fontWeight: 600, color: "white", paddingLeft: 10, paddingRight: 10}}>
                      Digite o Número da {parseInt(typeLaunch) === 1 ? "Mesa": "Comanda"}
                    </label>
                  </div>

                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                    paddingLeft: 10}}>
                    { error &&
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                        marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }
                  </div>

                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", 
                    alignItems: "center"}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                    <div style={(width < 450) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                      : {display: "flex", flexGrow: 0, marginTop: 10, paddingLeft: 20, paddingRight: 20, minWidth: 180, maxWidth: 180}}>
                      <InputMasked
                        name="lauchRestaurantNumber"
                        description="Número:"
                        planceHolder=""
                        mask="99999"
                        value={number || ""}
                        disabled={false}
                        require={true}
                        image={false}
                        typeImage={0}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setNumber(event.target.value)} />
                    </div>

                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>

                      <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                        <DigitalKeyboardNumeric 
                          field={"lauchRestaurantNumber"}
                          pressButton={pressButton}
                          pressBackSpace={pressBackspace}
                          pressEnter={pressEnter}
                          width={width} />
                      </div>
                    </div>
                  </div>
                </div>
              :
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>        
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",
                    backgroundColor: "rgb(0, 177, 171)", height: 50}}>
                    <label style={{textAlign: "center", fontWeight: 600, color: "white", paddingLeft: 10, paddingRight: 10}}>
                      Escaneie o QrCode da {parseInt(typeLaunch) === 1 ? "Mesa": "Comanda"} :
                    </label>
                  </div>

                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                    paddingLeft: 10}}>
                    { error &&
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                        marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }        
                  </div>

                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                    <ScanQrCode
                      setValue={selectNumber}
                      setError={selectError}
                      height={height}
                      width={width}
                    />
                  </div>
                </div>
            }

            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginBottom: 20}}>
              { !manual &&
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={() => pressManual()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_keyboard} alt="Manual"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Manual</label>
                  </div>
                </div>
              }

              { manual &&
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={() => confirm()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_check_circle} alt="Confirmar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Confirmar</label>
                  </div>
                </div>
              }
            
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={() => (manual) ? returnManual(false) : setReturn()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
              <Footer />
            </div>
          </div>
      }
    </div>
  )
}

export default LauchRestaurantNumber