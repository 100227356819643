import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_select from "../../../Images/Buttons/select_circle.png"

// Type: 1 - Crédito, 2 - Débito, 3 - Título, 98 - Crédito + Título, 99 - Débito + Título
const SearchAccountPlan = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, type, periodic,
  showMenu, width}) => {  

  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState(0)
  const [companyCode, setCompanyCode] = useState("")
  const [number, setNumber] = useState("")
  const [description, setDescription] = useState("")

  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0) 

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    setCompanyCode(jsonCompany.ID)

    let jAccountPlan  = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"

    if (number !== "") {
      jAccountPlan += ", \"NUMBER\": \""+ number + "\""
      search = true
    }

    if (description !== "") {
      jAccountPlan += ", \"DESCRIPTION\": \""+ description + "\""
      if (description.length > 2) search = true
    }
        
    if (type === 1) jAccountPlan += ", \"TYPE\": 1"
    if (type === 2) jAccountPlan += ", \"TYPE\": 2"
    if (type === 3) jAccountPlan += ", \"TYPE\": 3"
    if (type === 98) jAccountPlan += ", \"TYPE\": 98"
    if (type === 99) jAccountPlan += ", \"TYPE\": 99"

    if (periodic === 1) jAccountPlan += ", \"PERIODIC\": 1"
    if (periodic === 2) jAccountPlan += ", \"PERIODIC\": 2"
        
    jAccountPlan += " }"

    if ((((((((((((((((((jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }") ||    
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 2 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 3 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 98 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 99 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"PERIODIC\": 2 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 1, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 1, \"PERIODIC\": 2 }")) ||      
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 2, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 2, \"PERIODIC\": 2 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 3, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 3, \"PERIODIC\": 2 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 98, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 98, \"PERIODIC\": 2 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 99, \"PERIODIC\": 1 }")) ||
    (jAccountPlan === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 99, \"PERIODIC\": 2 }")) search = true

    if (search){
      setLoading("Aguarde buscando as informações...")      
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jAccountPlan)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ACCOUNT_PLAN/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de planos de conta. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchAccountPlanDescription") 
            document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
          else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()

          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchAccountPlanDescription")
            document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
          else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jAccountPlan)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "ACCOUNT_PLAN/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os planos de conta. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchAccountPlanDescription")
              document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
            else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()
            
            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchAccountPlanDescription")
            document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
          else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      if (fieldSelected === "searchAccountPlanDescription")
        document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
      else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()
    }
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, number, description, type, periodic, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o plano de conta (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setDescription("")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    let jAccountPlan = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ companyCode +" } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jAccountPlan)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT_PLAN/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o plano de conta. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchAccountPlanDescription")
          document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
        else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT_PLAN/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os planos de conta no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchAccountPlanDescription")
          document.getElementById("searchAccountPlanDescription") && document.getElementById("searchAccountPlanDescription").focus()
        else document.getElementById("searchAccountPlanNumber") && document.getElementById("searchAccountPlanNumber").focus()

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean} 
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={(showMenu) ?
                          (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        : 
                          (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexGrow: 0, marginTop: 10}}>

                        <div style={(showMenu) ?
                            (width < 610) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                            : (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                          : 
                            (width < 610) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                          <Input
                            type="search"
                            name="searchAccountPlanNumber"
                            description="Número"
                            planceHolder=""
                            value={number}
                            maxLength={50}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setNumber(event.target.value)} />
                        </div>
                        <div style={(showMenu) ?
                            (width < 610) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            : (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                          : 
                            (width < 610) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                          <Input
                            type="search"
                            name="searchAccountPlanDescription"
                            description="Descrição"
                            planceHolder="Mínimo 3 caracteres"
                            value={description}
                            maxLength={255}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setDescription(event.target.value)} />
                        </div>
                      </div>

                      { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                                borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}}>

                                <div style={(showMenu) ? 
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                  : 
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                  <div style={(showMenu) ? 
                                      (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}}>
                                      <label style={{fontWeight: 600}}>Número</label>                                      
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                        
                                      <label style={{fontWeight: 600}}>Descrição</label>
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                                    :                    
                                      (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>

                                    <label style={{fontWeight: 600}}>Tipo</label>
                                  </div>
                                </div>

                                { (width >= 450) &&
                                  <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginRight: 8}}>
                                    <div style={{marginTop: 2, height: 28, width: 28}} />
                                    <div style={{marginLeft: 10, height: 32, width: 32}} />

                                    { !modeSearch &&
                                      <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} />
                                    }                                    
                                  </div>
                                }                                
                              </div>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={
                                  (item.NUMBER_SUBGROUPS === 0) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 178, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}

                                  : (item.NUMBER_SUBGROUPS === 1) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 168, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 168, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 2) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 158, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 158, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 3) ?
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 148, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 148, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 4) ?
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 138, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 138, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 5) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 128, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 128, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 6) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 118, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 118, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 7) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 108, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 108, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 8) ? 
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 98, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 98, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                  
                                  : (item.NUMBER_SUBGROUPS === 9) ?
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 88, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 88, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                                                    
                                  : (item.NUMBER_SUBGROUPS === 10) &&
                                    (width < 450) ? 
                                      {display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 78, 177)",
                                      paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center",
                                      backgroundColor: "rgb(0, 78, 177)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    : 
                                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                    <div style={(showMenu) ? 
                                        (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}}>{item.NUMBER}</div>
                                      <div style={(showMenu) ?
                                          (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>{item.DESCRIPTION}</div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                                      :                                      
                                        (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>

                                      { item.TYPE === 1 && "Crédito" }
                                      { item.TYPE === 2 && "Débito" }
                                      { item.TYPE === 3 && "Título" }
                                    </div>
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10} 
                                    : {display: "flex", flexGrow: 0}}>

                                    { !modeSearch && (item.PERIODIC === 1) &&
                                      <div style={{marginTop: 2, height: 28, width: 28}} />
                                    }
                                    
                                    { !modeSearch && (item.PERIODIC === 2) &&
                                      <img
                                        style={{marginTop: 2, height: 28, width: 28}}
                                        src={image_add}
                                        name="searchAccountPlanNew"
                                        alt="Adicionar"
                                        onClick={(() => setAdd(item))} />
                                    }

                                    <img
                                      style={{marginLeft: 10, height: 32, width: 32}}
                                      src={image_select}
                                      name="searchAccountPlanSelect"
                                      alt="Selecionar"
                                      onClick={() => setSelected(item)} />

                                    { !modeSearch &&
                                      <img
                                        style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}}
                                        src={image_delete}
                                        name="searchAccountPlanDelete"
                                        alt="Deletar"
                                        onClick={() => callDelete(item)} />
                                    }
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchAccountPlan