import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import InputMasked from "../../../Components/InputMasked"
import InputNumeric from "../../../Components/InputNumeric"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import Titulo from "../../../Components/Titulo"
import PriceTableItem from "../"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_downArrow from "../../../Images/Buttons/down_arrow.png"
import image_loading from "../../../Images/spinner.gif";
import image_save from "../../../Images/Buttons/save.png"
import image_select from "../../../Images/Buttons/select_circle.png"
import image_upArrow from "../../../Images/Buttons/up_arrow.png"

const SearchPriceTableItem = ({databaseType, databasePath, jsonLogin, system, jsonPriceTable, showMenu, width}) => {

  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [commercialBarCode, setCommercialBarCode] = useState("")
  const [taxableBarCode, setTaxableBarCode] = useState("")
  const [reference, setReference] = useState("")
  const [type, setType] = useState(0)

  const [showPercentage, setShowPercentage] = useState(false)
  const [percentageType, setPercentageType] = useState(1)
  const [percentage, setPercentage] = useState("")

  const [showChange, setShowChange] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})

  const [manufacturerName, setManufacturerName] = useState("")
  const [manufacturerNameSelected, setManufacturerNameSelected] = useState("")
  const [manufacturerReturn, setManufacturerReturn] = useState({})
  const [manufacturerReturnFilter, setManufacturerReturnFilter] = useState({})

  const [departmentDescription, setDepartmentDescription] = useState("")
  const [departmentDescriptionSelected, setDepartmentDescriptionSelected] = useState("")
  const [departmentReturn, setDepartmentReturn] = useState({})
  const [departmentReturnFilter, setDepartmentReturnFilter] = useState({})

  const [showManufacturer, setShowManufacturer] = useState(false)
  const [showDepartment, setShowDepartment] = useState(false)

  const [jsonPriceTableItem, setJsonPriceTableItem] = useState("")

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [firstAccess, setFirstAccess] = useState(true)

  useEffect(() => {
    let returnJson = []

    if (firstAccess){
      ////
      // Load informations of Departments // Carrega as informações dos departamentos
      ////
      setLoading("Aguarde buscando as informações dos departamentos...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "DEPARTMENT/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de departamentos. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning(false)
          setLoading("")

          return
        }

        const tRecord = returnJson.OBJECT
        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{}")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", tRecord)

        axios.post(BASE_URL + "DEPARTMENT/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os departamentos. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setWarning(false)
            setLoading("")
            
            return
          }
            
          var arrDepartment = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrDepartment.push(item)
            ))
          setDepartmentReturn(arrDepartment)

          var arrDepartmentFilter = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrDepartmentFilter.push(item)
            ))
          setDepartmentReturnFilter(arrDepartmentFilter)

          setLoading("")
        })
      })

      ////
      // Load informations of Manufacturer // Carrega as informações dos fabricantes
      ////
      returnJson = []

      setLoading("Aguarde buscando as informações dos fabricantes...")
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "MANUFACTURER/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de fabricantes. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning(false)
          setLoading("")

          return
        }

        const tRecord = returnJson.OBJECT
        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{}")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", tRecord)

        axios.post(BASE_URL + "MANUFACTURER/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os fabricantes. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setWarning(false)
            setLoading("")
            
            return
          }
            
          var arrManufacturer = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrManufacturer.push(item)
            ))
          setManufacturerReturn(arrManufacturer)

          var arrManufacturerFilter = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrManufacturerFilter.push(item)
            ))
          setManufacturerReturnFilter(arrManufacturerFilter)

          setLoading("")
        })
      })

      setFirstAccess(false)
    }

    if (showDepartment){
      var arrDepartment = []
  
      if (departmentDescription === "") departmentReturn.map(item => (arrDepartment.push(item)))
      else departmentReturn.map(item => ((item.DESCRIPTION.indexOf(departmentDescription.toUpperCase()) !== -1) && arrDepartment.push(item)))

      setDepartmentReturnFilter(arrDepartment)
    }

    if (showManufacturer){
      var arrManufacturer = []
  
      if (manufacturerName === "") manufacturerReturn.map(item => (arrManufacturer.push(item)))
      else manufacturerReturn.map(item => ((item.NAME.indexOf(manufacturerName.toUpperCase()) !== -1) && arrManufacturer.push(item)))

      setManufacturerReturnFilter(arrManufacturer)
    }

    let search = false

    let jPriceTableItem = "{ \"PRICE_TABLE\": { \"ID\": "+ jsonPriceTable.ID + ", \"COMPANY\": { \"ID\": "+ jsonPriceTable.COMPANY.ID + " } }"

    if ((((((((code !== "") || (description !== "")) || (String(type) !== "0")) || (commercialBarCode !== "")) || (taxableBarCode !== "")) || (reference !== "")) ||
      (departmentDescriptionSelected !== "")) || (manufacturerNameSelected !== ""))
    {
      jPriceTableItem += ", \"PRODUCT_COMPANY\": { \"PRODUCT\": {"

      if (code !== ""){
        let codeN = String(code).replaceAll("_","")
        if (codeN !== "") jPriceTableItem += " \"ID\": "+ codeN
        search = true
      }

      if (description !== ""){
        if ((description.length > 2) || (code !== "")) {
          let codeN = String(code).replaceAll("_","")
          if (codeN !== "") jPriceTableItem += ","

          jPriceTableItem += " \"DESCRIPTION\": \""+ description + "\""
          search = true
        }
      }

      if (String(type) !== "0"){
        let codeN = String(code).replaceAll("_","")
        if ((codeN !== "") || (description !== "")) jPriceTableItem += ","

        jPriceTableItem += " \"TYPE\": "+ type
        search = true
      }

      if (commercialBarCode !== "") {
        let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
        if (commercialBarCodeN.length > 6){
          let codeN = String(code).replaceAll("_","")
          if (((codeN !== "") || (description !== "")) || (String(type) !== "0")) jPriceTableItem += ","

          jPriceTableItem += " \"COMMERCIAL_BARCODE\": \""+ commercialBarCodeN + "\""
          search = true
        }
      }

      if (taxableBarCode !== "") {
        let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
        if (taxableBarCodeN.length > 6){
          let codeN = String(code).replaceAll("_","")
          let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
          if ((((codeN !== "") || (description !== "")) || (String(type) !== "0")) || (commercialBarCodeN !== "")) jPriceTableItem += ","

          jPriceTableItem += " \"TAXABLE_BARCODE\": \""+ taxableBarCodeN + "\""
          search = true
        }
      }

      if (reference !== "") {
        let codeN = String(code).replaceAll("_","")
        let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
        let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
        if (((((codeN !== "") || (description !== "")) || (String(type) !== "0"))  || (commercialBarCodeN !== "")) || (taxableBarCodeN !== "")) 
          jPriceTableItem += ","

        jPriceTableItem += " \"REFERENCE\": \""+ reference + "\""
        search = true
      }

      if (departmentDescriptionSelected !== ""){
        let codeN = String(code).replaceAll("_","")
        let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
        let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
        if ((((((codeN !== "") || (description !== "")) || (String(type) !== "0")) || (commercialBarCodeN !== "")) || (taxableBarCodeN !== "")) ||
          (reference !== "")) jPriceTableItem += ","

        jPriceTableItem += " \"DEPARTMENTS\": \""+ departmentDescriptionSelected +"\""
        search = true          
      }
      
      if (manufacturerNameSelected !== ""){
        let codeN = String(code).replaceAll("_","")
        let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
        let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
        if (((((((codeN !== "") || (description !== "")) || (String(type) !== "0")) || (commercialBarCodeN !== "")) || (taxableBarCodeN !== "")) ||
          (reference !== "")) ||  (departmentDescriptionSelected !== "")) jPriceTableItem += ","        

        jPriceTableItem += " \"MANUFACTURERS\": \""+ manufacturerNameSelected +"\""
        search = true
      } 

      jPriceTableItem += " }"
    }

    jPriceTableItem += " } }"

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jPriceTableItem)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "PRICE_TABLE_ITEM/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de itens da tabela de preço. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "priceTableItemDescription")
            document.getElementById("priceTableItemDescription") && document.getElementById("priceTableItemDescription").focus()
          else if (fieldSelected === "priceTableItemType")
            document.getElementById("priceTableItemType") && document.getElementById("priceTableItemType").focus()
          else if (fieldSelected === "priceTableItemCommercialBarCode") 
            document.getElementById("priceTableItemCommercialBarCode") && document.getElementById("priceTableItemCommercialBarCode").focus()
          else if (fieldSelected === "priceTableItemTaxableBarCode")
            document.getElementById("priceTableItemTaxableBarCode") && document.getElementById("priceTableItemTaxableBarCode").focus()
          else if (fieldSelected === "priceTableItemReference")
            document.getElementById("priceTableItemReference") &&document.getElementById("priceTableItemReference").focus()
          else if (fieldSelected === "priceTableItemDepartmentDescription")
            document.getElementById("priceTableItemDepartmentDescription") &&document.getElementById("priceTableItemDepartmentDescription").focus()
          else if (fieldSelected === "priceTableItemManufacturerName")
            document.getElementById("priceTableItemManufacturerName") &&document.getElementById("priceTableItemManufacturerName").focus()
          else
            document.getElementById("priceTableItemCode") && document.getElementById("priceTableItemCode").focus()
          
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "priceTableItemDescription")
            document.getElementById("priceTableItemDescription") && document.getElementById("priceTableItemDescription").focus()
          else if (fieldSelected === "priceTableItemType")
            document.getElementById("priceTableItemType") && document.getElementById("priceTableItemType").focus()
          else if (fieldSelected === "priceTableItemCommercialBarCode") 
            document.getElementById("priceTableItemCommercialBarCode") && document.getElementById("priceTableItemCommercialBarCode").focus()
          else if (fieldSelected === "priceTableItemTaxableBarCode")
            document.getElementById("priceTableItemTaxableBarCode") && document.getElementById("priceTableItemTaxableBarCode").focus()
          else if (fieldSelected === "priceTableItemReference")
            document.getElementById("priceTableItemReference") &&document.getElementById("priceTableItemReference").focus()
          else if (fieldSelected === "priceTableItemDepartmentDescription")
            document.getElementById("priceTableItemDepartmentDescription") &&document.getElementById("priceTableItemDepartmentDescription").focus()
          else if (fieldSelected === "priceTableItemManufacturerName")
            document.getElementById("priceTableItemManufacturerName") &&document.getElementById("priceTableItemManufacturerName").focus()
          else
            document.getElementById("priceTableItemCode") && document.getElementById("priceTableItemCode").focus()
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
    
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jPriceTableItem)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "PRICE_TABLE_ITEM/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os itens da tabela de preço. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "priceTableItemDescription")
              document.getElementById("priceTableItemDescription") && document.getElementById("priceTableItemDescription").focus()
            else if (fieldSelected === "priceTableItemType")
              document.getElementById("priceTableItemType") && document.getElementById("priceTableItemType").focus()
            else if (fieldSelected === "priceTableItemCommercialBarCode") 
              document.getElementById("priceTableItemCommercialBarCode") && document.getElementById("priceTableItemCommercialBarCode").focus()
            else if (fieldSelected === "priceTableItemTaxableBarCode")
              document.getElementById("priceTableItemTaxableBarCode") && document.getElementById("priceTableItemTaxableBarCode").focus()
            else if (fieldSelected === "priceTableItemReference")
              document.getElementById("priceTableItemReference") &&document.getElementById("priceTableItemReference").focus()
            else if (fieldSelected === "priceTableItemDepartmentDescription")
              document.getElementById("priceTableItemDepartmentDescription") &&document.getElementById("priceTableItemDepartmentDescription").focus()
            else if (fieldSelected === "priceTableItemManufacturerName")
              document.getElementById("priceTableItemManufacturerName") &&document.getElementById("priceTableItemManufacturerName").focus()
            else
              document.getElementById("priceTableItemCode") && document.getElementById("priceTableItemCode").focus()

            return
          }
          
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "priceTableItemDescription")
            document.getElementById("priceTableItemDescription") && document.getElementById("priceTableItemDescription").focus()
          else if (fieldSelected === "priceTableItemType")
            document.getElementById("priceTableItemType") && document.getElementById("priceTableItemType").focus()
          else if (fieldSelected === "priceTableItemCommercialBarCode") 
            document.getElementById("priceTableItemCommercialBarCode") && document.getElementById("priceTableItemCommercialBarCode").focus()
          else if (fieldSelected === "priceTableItemTaxableBarCode")
            document.getElementById("priceTableItemTaxableBarCode") && document.getElementById("priceTableItemTaxableBarCode").focus()
          else if (fieldSelected === "priceTableItemReference")
            document.getElementById("priceTableItemReference") && document.getElementById("priceTableItemReference").focus()
          else if (fieldSelected === "priceTableItemDepartmentDescription")
            document.getElementById("priceTableItemDepartmentDescription") &&document.getElementById("priceTableItemDepartmentDescription").focus()
          else if (fieldSelected === "priceTableItemManufacturerName")
            document.getElementById("priceTableItemManufacturerName") &&document.getElementById("priceTableItemManufacturerName").focus()
          else
            document.getElementById("priceTableItemCode") && document.getElementById("priceTableItemCode").focus()
        })
      })
    }
    else
    {
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)
      
      if (fieldSelected === "priceTableItemDescription")
        document.getElementById("priceTableItemDescription") && document.getElementById("priceTableItemDescription").focus()
      else if (fieldSelected === "priceTableItemType")
        document.getElementById("priceTableItemType") && document.getElementById("priceTableItemType").focus()
      else if (fieldSelected === "priceTableItemCommercialBarCode") 
        document.getElementById("priceTableItemCommercialBarCode") && document.getElementById("priceTableItemCommercialBarCode").focus()
      else if (fieldSelected === "priceTableItemTaxableBarCode")
        document.getElementById("priceTableItemTaxableBarCode") && document.getElementById("priceTableItemTaxableBarCode").focus()
      else if (fieldSelected === "priceTableItemReference")
        document.getElementById("priceTableItemReference") && document.getElementById("priceTableItemReference").focus()
      else if (fieldSelected === "priceTableItemDepartmentDescription")
        document.getElementById("priceTableItemDepartmentDescription") &&document.getElementById("priceTableItemDepartmentDescription").focus()
      else if (fieldSelected === "priceTableItemManufacturerName")
        document.getElementById("priceTableItemManufacturerName") && document.getElementById("priceTableItemManufacturerName").focus()
      else
        document.getElementById("priceTableItemCode") && document.getElementById("priceTableItemCode").focus()
    }
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, code, description, type, commercialBarCode, taxableBarCode, reference,
    departmentDescription, manufacturerName, departmentDescriptionSelected, manufacturerNameSelected, updatePage])

  const addDepartment = () => {  
    cleanDepartment()
    setShowDepartment(true)
  }

  const addManufacturer = () => {
    cleanManufacturer()
    setShowManufacturer(true)
  }  

  const back = () => {
    setWarning("")
    setError("")
    setShowChange(false)
  }      

  const cleanDepartment = () => {
    departmentReturn.map(item => (item.MARKED = false))

    setDepartmentReturnFilter(departmentReturn)

    setDepartmentDescriptionSelected("")
    setDepartmentDescription("")
    setShowDepartment(false)
  }

  const cleanManufacturer = () => {
    manufacturerReturn.map(item => (item.MARKED = false))

    setManufacturerReturnFilter(manufacturerReturn)

    setManufacturerNameSelected("")
    setManufacturerName("")
    setShowManufacturer(false)
  }  

  const formatNumeric = (value, decimals) => {
    if (!value) return '0'
    if (value === '.') return value = '0.'
        
    let regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0    
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return '0'
    return parsed
  }

  const handleProductCommercialBarCode = (event) => {
    let productCommercialBarCodeN = String(event.target.value).replaceAll("_","")
    if (productCommercialBarCodeN !== "") setCommercialBarCode(event.target.value)
    else setCommercialBarCode("")
  }

  const handleProductCode = (event) => {
    let productCodeN = String(event.target.value).replaceAll("_","")
    if (productCodeN !== "") setCode(event.target.value)
    else setCode("")
  }  

  const handleProductTaxableBarCode = (event) => {
    let productTaxableBarCodeN = String(event.target.value).replaceAll("_","")
    if (productTaxableBarCodeN !== "") setTaxableBarCode(event.target.value)
    else setTaxableBarCode("")
  }

  const handlePercentage = (event) => {
    setPercentage(formatNumeric(event.target.value, 2))
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const markedAllDepartment = () => {
    let selected = ""

    departmentReturn.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDepartmentDescriptionSelected(selected)
    setShowDepartment(false)
  }

  const markedAllManufacturer = () => {
    let selected = ""

    manufacturerReturn.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.NAME)
      : selected += ("," + item.ID + "-" + item.NAME)
    ))
    
    setManufacturerNameSelected(selected)
    setShowManufacturer(false)
  }  

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const save = (priceTableItem) => {
    let returnJson = []

    setLoading("Aguarde alterando o item da tabela de preço...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", JSON.stringify(jsonPriceTableItem))
    formData.append("JSON_NEW_1", priceTableItem)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "PRICE_TABLE_ITEM/Update", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar o item da tabela de preço. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Alteração efetuada com sucesso!")

      setLoading("")
      setError("")
      setShowChange(false)

      const count = updatePage + 1
      setUpdatePage(count)
    })
  }

  const savePercentage = () => {
    if (percentage === ""){
      alert("Valor da porcentagem inválido!")
      document.getElementById("searchPriceTableItemPrice") && document.getElementById("searchPriceTableItemPrice").focus()
      return      
    }

    if (parseFloat(percentage) <= 0){
      alert("Valor da porcentagem inválido!")
      document.getElementById("searchPriceTableItemPrice") && document.getElementById("searchPriceTableItemPrice").focus()
      return
    }

    let returnJson = []

    const jsonPriceTableItemPercentage = "{ \"TYPE\": "+ percentageType +", \"PERCENTAGE\": "+ percentage + 
      ", \"lstPRICE_TABLE\": "+ JSON.stringify(result.OBJECT) + "}"

    setLoading("Aguarde alterando os itens da tabela de preço...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonPriceTableItemPercentage)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "PRICE_TABLE_ITEM/UpdatePercentage", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar o item da tabela de preço. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Alteração efetuada com sucesso!")

      setLoading("")
      setError("")
      setShowChange(false)

      const count = updatePage + 1
      setUpdatePage(count)
    })
  }

  const selectedDepartment = () => {
    setShowDepartment(false)
  }

  const selectedManufacturer = () => {
    setShowManufacturer(false)
  }  

  const setSelected = (priceTableItem) => {
    setJsonPriceTableItem(priceTableItem)
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const updateShowDepartment = (id) => {
    departmentReturn.map(item => (
      (item.ID === id) ? item.MARKED = !item.MARKED : ""
    ))

    let selected = ""
    departmentReturn.map(item => (
      (item.MARKED) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setDepartmentDescriptionSelected(selected)    
  }

  const updateShowManufacturer= (id) => {
    manufacturerReturn.map(item => (
      (item.ID === id) ? item.MARKED = !item.MARKED : ""
    ))

    let selected = ""
    manufacturerReturn.map(item => (
      (item.MARKED) ? (selected === "") ? selected = item.ID + "-" + item.NAME
                       : selected = selected + "," + item.ID + "-" + item.NAME
      : ""
    ))
    setManufacturerNameSelected(selected)
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              { showChange ?
                  <PriceTableItem
                    jsonPriceTableItem={jsonPriceTableItem}
                    setJsonPriceTableItem={setJsonPriceTableItem}
                    jsonLogin={jsonLogin}
                    save={save}
                    back={back} 
                    showMenu={showMenu}
                    width={width} />
                :
                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                      marginBottom: 10, marginLeft: 0}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                    </div>

                    <div style={showMenu ? 
                        (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                      <div style={showMenu ?
                          (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :
                          (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={showMenu ?
                            (width < 1060) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                          :
                            (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                          <InputMasked
                            name="priceTableItemCode"
                            description="Código"
                            planceHolder=""
                            mask="99999999999"
                            value={code}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleProductCode} />
                        </div>
                        <div style={showMenu ?
                            (width < 1060) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                          :
                            (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                          <Input
                            type="search"
                            name="priceTableItemDescription"
                            description="Descrição"
                            planceHolder="Mínimo 3 caracteres"
                            value={description}
                            maxLength={50}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setDescription(event.target.value)} />
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}
                        :   
                          (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}}>
                        <Select
                            name="priceTableItemType"
                            description="Tipo"
                            value={type}
                            require={false}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                              ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                              ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                              ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                              ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                              ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                              ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                              ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                              ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setType} />
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                      <div style={showMenu ?
                          (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                          : (width < 830) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                          : (width < 1080) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}
                        :
                          (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                        <div style={showMenu ?
                            (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 200}
                            : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                          :
                            (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 200}
                            : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                          <InputMasked
                            name="priceTableItemCommercialBarCode"
                            description="Barras Comercial"
                            planceHolder=""
                            mask="99999999999999"
                            value={commercialBarCode}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleProductCommercialBarCode} />
                        </div>
                        <div style={showMenu ?
                            (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                            : (width < 830) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 200}
                            : (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}
                          :
                            (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 820) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                          <InputMasked
                            name="priceTableItemTaxableBarCode"
                            description="Barras Tributável"
                            planceHolder=""
                            mask="99999999999999"
                            value={taxableBarCode}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleProductTaxableBarCode} />
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :
                          (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="search"
                          name="priceTableItemReference"
                          description="Referência"
                          value={reference}
                          maxLength={15}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setReference(event.target.value)} />
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 1610) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 1330) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                      <div style={showMenu ?
                          (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :  
                          (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <Input
                            type="search"
                            name="priceTableItemDepartmentDescriptionSelected"
                            description="Departamentos"
                            value={departmentDescriptionSelected}
                            maxLength={6000}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected} />
                        </div>
                        <div style={showMenu ?
                            (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                          :
                            (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                          <div style={showMenu ?
                              (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", paddingLeft: 5,
                                paddingRight: 5}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 5, paddingRight: 5}
                            :
                              (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", paddingLeft: 5,
                                paddingRight: 5}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 5, paddingRight: 5}}>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0}} onClick={(() => { addDepartment() })}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 5,
                                paddingBottom: 5, paddingLeft: 5}}>
                                <img style={{height: 38, width: 38}} src={image_add} alt="Adicionar"/>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}
                              onClick={(() => { cleanDepartment() })}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 5,
                                paddingBottom: 5, paddingLeft: 5}}>
                                <img style={{height: 38, width: 38}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 1610) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                        :
                          (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 1330) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <Input
                          type="search"
                          name="priceTableItemManufacturerNameSelected"
                          description="Fabricantes"
                          value={manufacturerNameSelected}
                          maxLength={6000}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                        </div>
                        <div style={showMenu ?
                            (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                          :
                            (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                          <div style={showMenu ?
                              (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", paddingLeft: 5,
                                paddingRight: 5}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 5, paddingRight: 5}
                            :
                              (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", paddingLeft: 5,
                                paddingRight: 5}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 5, paddingRight: 5}}>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}
                              onClick={(() => { addManufacturer() })}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 5,
                                paddingBottom: 5, paddingLeft: 5}}>
                                <img style={{height: 38, width: 38}} src={image_add} alt="Adicionar"/>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}} onClick={(() => { cleanManufacturer() })}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 5,
                                paddingBottom: 5, paddingLeft: 5}}>
                                <img style={{height: 38, width: 38}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    { showDepartment &&
                      <div style={{marginTop: 10}}>
                        <div style={{display: "flex", flexGrow: 1}}>
                          <div style={{width: "100%"}}>
                            <Titulo description="Departamento" />
                          </div>
                        </div>

                        <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => selectedDepartment()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => markedAllDepartment()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todo"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todo</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => cleanDepartment()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
              
                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="priceTableItemDepartmentDescription"
                                description="Descrição"
                                value={departmentDescription}
                                maxLength={500}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDepartmentDescription(event.target.value)} />
                            </div>
                          </div>

                          { departmentReturnFilter && (departmentReturnFilter.length > 0) &&
                            <>
                              <div style={showMenu ?
                                  (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                :
                                  (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}}>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :  
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                    <div style={(width < 360) ? {display: "flex", flexGrow: 1, fontWeight: 600, marginLeft: 15, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, fontWeight: 600, marginLeft: 15, minWidth: 30, maxWidth: 30}}/>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, fontWeight: 600, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, fontWeight: 600, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, fontWeight: 600, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, fontWeight: 600, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                    <label style={{fontWeight: 600}}>Número</label>
                                  </div>
                                </div>    
                                <div style={showMenu ?
                                    (width < 910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 620) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                  <label style={{fontWeight: 600}}>Descrição</label>
                                </div>
                              </div>
                              <hr/>
                            </>
                          }

                          { departmentReturnFilter && departmentReturnFilter.map(item => (
                            <div key={item.ID + "_" + item.HIERARCHY + "_" + item.DESCRIPTION} style={showMenu ?
                                (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 5, marginBottom: 5, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                              :
                                (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5, marginBottom: 5,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                  borderLeftColor: "rgb(0, 177, 171)"}}>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                  <div style={(width < 360) ? {display: "flex", flexGrow: 1, marginTop: 5, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 30, maxWidth: 30}}>
                                    <input type="checkbox" checked={item.MARKED} onChange={ () => updateShowDepartment(item.ID) } />
                                  </div>
                                  <div style={(width < 360) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                                    : (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                    <label>{item.ID}</label>
                                  </div>
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                  <label>{item.HIERARCHY}</label>
                                </div>
                              </div>    
                              <div style={showMenu ?
                                  (width < 910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 620) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                <label>{item.DESCRIPTION}</label>
                              </div>
                            </div>
                            ))
                          }

                          { departmentReturnFilter && (departmentReturnFilter.length === 0) &&
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                          }

                        </div>
                      </div>
                    }

                    { showManufacturer &&
                      <div style={{marginTop: 10}}>
                        <div style={{display: "flex", flexGrow: 1}}>
                          <div style={{width: "100%"}}>
                            <Titulo description="Fabricante" />
                          </div>
                        </div>

                        <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => selectedManufacturer()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => markedAllManufacturer()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todo"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todo</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 210}} onClick={() => cleanManufacturer()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="priceTableItemManufacturerName"
                                description="Nome"
                                value={manufacturerName}
                                maxLength={500}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setManufacturerName(event.target.value)} />
                            </div>
                          </div>

                          { manufacturerReturnFilter && (manufacturerReturnFilter.length > 0) &&
                            <div style={showMenu ?
                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}
                              :
                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15, marginBottom: 5}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}}>

                              <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                <div style={(width < 360) ? {display: "flex", flexGrow: 1, fontWeight: 600, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, fontWeight: 600, marginLeft: 15, minWidth: 30, maxWidth: 30}}/>
                                <div style={(width < 360) ? {display: "flex", flexGrow: 1, fontWeight: 600, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, fontWeight: 600, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                  <label style={{fontWeight: 600}}>Código</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 520) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 520) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                <label style={{fontWeight: 600}}>Nome</label>
                              </div>
                              <hr/>
                            </div>
                          }

                          { manufacturerReturnFilter && manufacturerReturnFilter.map(item => (
                            <div key={item.ID + "_" + item.NAME} style={showMenu ?
                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 5, marginBottom: 5, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                              :
                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 5, marginBottom: 5, 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 5, marginBottom: 5, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)"}}>

                              <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                <div style={(width < 360) ? {display: "flex", flexGrow: 1, marginTop: 5, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 30, maxWidth: 30}}>
                                  <input type="checkbox" checked={item.MARKED} onChange={ () => updateShowManufacturer(item.ID) } />
                                </div>
                                <div style={(width < 360) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                                  <label>{item.ID}</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 520) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 520) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                <label>{item.NAME}</label>
                              </div>
                            </div>
                            ))
                          }

                          { manufacturerReturnFilter && (manufacturerReturnFilter.length === 0) &&
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                          }

                        </div>
                      </div>
                    }

                    { showResult && 
                      <>
                        { result.OBJECT &&
                          <>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 15}}>
                              <div style={(showMenu) ? 
                                  (width < 1170) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                : 
                                  (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                <div style={(showMenu) ?
                                    (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={(showMenu) ?
                                      (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                    : 
                                      (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}}>
                                    <label style={{fontWeight: 600}}>Código</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>Descrição</label>
                                  </div>
                                </div>
                                  
                                <div style={(showMenu) ? 
                                    (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                  : 
                                    (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                    <label style={{fontWeight: 600}}>Preço</label>
                                </div>
                              </div>

                              <div style={(width < 450) ? {display: "none"}
                                : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 45}}>

                                <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                  paddingLeft: 5, height: 32, width: 32}}/>
                              </div>
                            </div>
                            <hr/>

                            { result.OBJECT.map(item => (
                              <div key={item.ID} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4}}>

                                <div style={(showMenu) ? 
                                    (width < 1170) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  : 
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}
                                      : 
                                        (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}}>
                                      <label>{item.PRODUCT_COMPANY.PRODUCT.ID}</label>
                                    </div>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <label>{item.PRODUCT_COMPANY.PRODUCT.DESCRIPTION}</label>
                                    </div>
                                  </div>
                                    
                                  <div style={(showMenu) ? 
                                      (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                    : 
                                      (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label>{formatNumeric(item.PRICE, 2) }</label>
                                  </div>
                                </div>

                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                  : {display: "flex", justifyContent: "flex-end", marginRight: 5}}>
                                            
                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 5}}>
                                    <img
                                      style={{height: 32, width: 32}}
                                      name="searchProductSelected"
                                      src={image_select}
                                      alt="Selecionar"
                                      onClick={(() => setSelected(item))} />
                                  </div>
                                </div>
                              </div>
                            ))}

                            <SearchPagination 
                              pageCurrent={pageCurrent}
                              totalPage={totalPage}
                              recordPage={recordPage}
                              optionsRegPage={JSON.parse(optionsRegPage)}
                              pagePrevious={pagePrevious}
                              pageNext={pageNext}
                              setSelectedField={setFieldSelected}
                              handleRecordPage={handleRecordPage}
                              width={width} />

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 20}}>
                              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 5,
                                marginTop: 0, marginBottom: 5, textAlign: "center", paddingTop: 15, paddingBottom: 15, color:"#fff",
                                backgroundColor: "rgb(0, 177, 171)"}} onClick={() => setShowPercentage(!showPercentage)}>

                                <h4>Porcentagem</h4>

                                { showPercentage ? <img style={{marginLeft: 10, height: 14, width: 14}} src={image_upArrow} alt="Recolher"/>
                                  : <img style={{marginLeft: 10, height: 14, width: 14}} src={image_downArrow} alt="Estender"/> }                                
                              </div>

                              { showPercentage &&
                                <>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                                      width: 147}} onClick={() => savePercentage()}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                        paddingBottom: 5, paddingLeft: 10}}>
                                        <img style={{height: 32, width: 32}} src={image_save} alt="Reajustar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Reajustar</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={showMenu ?
                                        {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :   
                                        {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                      <Select
                                        name="searchPriceTableItemPercentageType"
                                        description="Tipo"
                                        value={percentageType || 1}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Desconto\" }"+
                                          ", {\"ID\": \"2\", \"VALUE\": \"Acréscimo\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setPercentageType} />
                                    </div>

                                    <div style={showMenu ? 
                                        {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}
                                      :
                                        {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="searchPriceTableItemPercentage"
                                        description="Valor (%)"
                                        value={percentage || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={true}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handlePercentage} />
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          </>
                        }
                      </>
                    }
                  </div>
              }

            </div>
          </div>
      }
    </div>
  )
}

export default SearchPriceTableItem