import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DataPicker from "../../Components/DataPicker"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Titulo from "../../Components/Titulo"
import BillReceiveDap from "../BillReceiveDap"
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select_circle.png"

const DapReceive = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [quantity, setQuantity] = useState("")

  ////
  // Search Bills // Buscar Títulos
  ////
  const [dueDateIni, setDueDateIni] = useState(null)
  const [dueDateEnd, setDueDateEnd] = useState(null)

  const [accountPlanDescription, setAccountPlanDescription] = useState("")
  const [accountPlanDescriptionSelected, setAccountPlanDescriptionSelected] = useState("")
  const [resultAccountPlan, setResultAccountPlan] = useState({})
  const [resultAccountPlanFilter, setResultAccountPlanFilter] = useState({})

  const [jBillReceive, setJBillReceive] = useState({ CODE: 0 })

  ////
  // Insert // Inserir
  ////
  const [billReceiveFilter, setBillReceiveFilter] = useState(true)
  const [billReceiveSelected, setBillReceiveSelected] = useState([])

  const [showMenu, setShowMenu] = useState(true)
  const [showAccountPlan, setShowAccountPlan] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)

  useEffect(() => {
    if (firstAccess){
      let returnJson = []
    
      ////
      // Load informations of Account Plan // Carrega as informações do plano de conta
      ////
      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações dos Planos de Conta...")
    
      const formData = new FormData();
      formData.append("DATABASE_TYPE", databaseType);
      formData.append("DATABASE_PATH", databasePath);
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"SITUATION\": \"98\" }");
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin));
      formData.append("SISTEMA", system);

      axios.post(BASE_URL + "BILL_RECEIVE/SearchAccountPlan", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Planos de Conta. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }
  
        let arrAccountPlan = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrAccountPlan.push(item)
          ))
        setResultAccountPlan(arrAccountPlan)
  
        let arrAccountPlanFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrAccountPlanFilter.push(item)
          ))
        setResultAccountPlanFilter(arrAccountPlanFilter)
          
        setLoading("")
      })

      setFirstAccess(false)
    }

    if (showAccountPlan){
      let arrAccountPlan = []
  
      if (accountPlanDescription === ""){
        resultAccountPlan.map(item => (
          arrAccountPlan.push(item)
        ))
      }
      else{
        resultAccountPlan.map(item => (
          (item.DESCRIPTION.indexOf(accountPlanDescription.toUpperCase()) !== -1) &&
            arrAccountPlan.push(item)
        ))
      }
  
      setResultAccountPlanFilter(arrAccountPlan)
    }
  }, [accountPlanDescription])

  const addAccountPlan = () => {  
    cleanAccountPlan()
    setShowAccountPlan(true)
  }

  const clean = () => {
    setJBillReceive({ CODE: 0 })

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setDueDateIni(null)
    setDueDateEnd(null)

    setResultAccountPlanFilter(resultAccountPlan)

    setShowAccountPlan(false)
  }

  const cleanAccountPlan = () => {
    resultAccountPlan.map(item => (
      item.MARKET = false
    ))

    setResultAccountPlanFilter(resultAccountPlan)

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setShowAccountPlan(false)
  }  

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const markAllAccountPlan = () => {
    let selected = ""

    resultAccountPlan.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setAccountPlanDescriptionSelected(selected)
    setShowAccountPlan(false)
  }  

  const returnAdd = () => {
    setWarning("")
    setError("")

    ////
    // Fields DAP // Campos do DAP
    ////
    setQuantity("")

    ////
    // Fields Search Bills // Campos buscar títulos
    ////
    setDueDateIni(null)
    setDueDateEnd(null)
    setAccountPlanDescription("")
    setAccountPlanDescriptionSelected("")

    setJBillReceive({ CODE: 0 })

    setBillReceiveFilter(true)
    setBillReceiveSelected([])
        
    setShowAccountPlan(false)
  }

  const returnFilter = () => {
    let search = false

    if (validateDate(dueDateIni) && validateDate(dueDateEnd)) search = true
    if (!search) if (accountPlanDescriptionSelected !== "") search = true

    if (!search){
      if (jBillReceive.CODE !== 0) setJBillReceive({ CODE: 0 })
    }        
    else        
    {            
      let jBill = "{"
      jBill += " \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"SITUATION\": 98"
      if (accountPlanDescriptionSelected !== "") jBill += ", \"ACCOUNTS_PLAN\": \""+ accountPlanDescriptionSelected +"\""

      if (validateDate(dueDateIni) && validateDate(dueDateEnd)){
        const dueI = dueDateIni.toLocaleString()
        const dueE = dueDateEnd.toLocaleString()

        const dueIni = String(dueI).substr(6,4) + "-" + String(dueI).substr(3,2) + "-" + String(dueI).substr(0,2) + "T00:00:00"
        const dueEnd = String(dueE).substr(6,4) + "-" + String(dueE).substr(3,2) + "-" + String(dueE).substr(0,2) + "T23:59:59"
        jBill += ", \"DUE_DATE_START\": \"" + dueIni + "\", \"DUE_DATE_END\": \"" + dueEnd + "\""
      }

      jBill += " }"
      setJBillReceive(jBill)
    }
  }

  const save = (billReceiveSelected) => {
    let returnJson = []

    setWarning("")
    setError("")

    let quant = quantity
    quant = String(quant).replace("_","")

    if (quant === ""){
      setWarning("É necessário informar a quantidade!")
      return
    }

    setLoading("Aguarde incluindo o registro...")

    const jsonDapReceive_New = "{ \"QUANTITY\": "+ quant +", \"lstBILL_RECEIVE\": "+ JSON.stringify(billReceiveSelected) + "}"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType);
    formData.append("DATABASE_PATH", databasePath);
    formData.append("JSON_NEW_1", jsonDapReceive_New);
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin));
    formData.append("SISTEMA", system);

    axios.post(BASE_URL + "DAP_RECEIVE/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível inserir o DAP a Receber. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }

      setWarning(returnJson.OBJECT)

      alert(returnJson.OBJECT)
      returnAdd()      

      setLoading("")
    })    
  }

  const saveBillReceive = (bills) => {
    if (bills.length === 0){
      setWarning("É necessário selecionar as contas receber.")
      return
    }

    let aSelected = []
    bills.map(item => (
      item.MARKET && aSelected.push(item)
    ))

    if (aSelected.length === 0){
      setWarning("É necessário selecionar as contas receber.")
      return
    }

    setBillReceiveSelected(aSelected)
    setBillReceiveFilter(false)
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  const setFieldSelected = () => {
  }

  const updateShowAccountPlan = (id) => {
    resultAccountPlan.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccountPlan.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setAccountPlanDescriptionSelected(selected)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="DAP a Receber"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { loading ?
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
                <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
              </div>
            :
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

                { billReceiveFilter &&
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={() => returnFilter()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 147}} onClick={() => clean()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 147}} onClick={setReturn}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                        paddingLeft: 10}}>
                        { warning && 
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                          </div> 
                        }

                        { error &&
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                          </div>
                        }
                      </div>                      

                      <div style={(showMenu) ?
                          (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        :
                          (width < 1250) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                        <div style={(showMenu) ? 
                            (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "end"}
                            : {display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "end"}
                          : 
                            (width < 1250) ? {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "end"}
                            : {display: "flex", flexDirection: "column", flexGrow: 0, flexBasis: 0, justifyContent: "end"}}>

                          <b>Data Vencimento</b>
                          <div style={(showMenu) ? 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1250) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1400) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              : 
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1250) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <DataPicker
                                name="dapReceiveDueDateIni"
                                description="Inicial"
                                value={dueDateIni}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDueDateIni(event)} 
                                intervalo={true}
                                inicial={true}
                                endDate={dueDateEnd} />
                            </div>

                            <div style={(showMenu) ? 
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                minWidth: 25, maxWidth: 25}
                              : 
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                minWidth: 25, maxWidth: 25}}>
                              <label style={{fontWeight: 600}}>à</label>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1400) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              : 
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1250) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <DataPicker
                                name="dapReceiveDueDateEnd"
                                description="Final"
                                value={dueDateEnd}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDueDateEnd(event)}
                                intervalo={true}
                                inicial={false}
                                startDate={dueDateIni} />
                            </div>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 1400) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10, marginLeft: 10}
                          :
                            (width < 1250) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10, marginLeft: 10}}>

                          <Titulo description="Plano de Conta" />

                          <div style={showMenu ?
                              (width < 1060) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="dapReceiveAccountPlanDescriptionSelected"
                                description="Plano de Contas Selecionados"
                                value={accountPlanDescriptionSelected}
                                maxLength={6000}
                                disabled={true}
                                require={false}
                                image={false}
                                typeInput={2} 
                                onSelect={setFieldSelected} />
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1060) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addAccountPlan() })}>
                                <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanAccountPlan() })}>
                                <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      { !showAccountPlan &&
                        <BillReceiveDap
                          databaseType={databaseType}
                          databasePath={databasePath}
                          jsonCompany={jsonCompany}
                          jsonLogin={jsonLogin}
                          system={system}
                          jBillReceive={jBillReceive}
                          saveBillReceive={saveBillReceive} 
                          height={height}
                          width={width}
                          showMenu={showMenu} />
                      }

                      { showAccountPlan &&
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 0}}>
                            <div style={{width: "100%"}}>
                              <Titulo description="Plano de Conta" />
                            </div>
                          </div>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                              marginLeft: 5, width: 210}} onClick={() => setShowAccountPlan(false)}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                              marginLeft: 5, width: 210}} onClick={() => markAllAccountPlan()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                              marginLeft: 5, width: 210}} onClick={() => cleanAccountPlan()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                          </div>

                          <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                              <Input
                                type="search"
                                name="searchBillReceiveAccountPlanDescription"
                                description="Descrição"
                                value={accountPlanDescription}
                                maxLength={500}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setAccountPlanDescription(event.target.value)} />
                            </div>

                            { resultAccountPlanFilter && (resultAccountPlanFilter.length > 0) &&
                              <div style={(showMenu) ?
                                (width < 1170) ?
                                  {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 5}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}
                                :
                                  (width < 900) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 5}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 4}}>

                                <div style={(showMenu) ? 
                                    (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : 
                                    (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(showMenu) ? 
                                      (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                    <div style={showMenu ?
                                        (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                    :  
                                      (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                    <label style={{fontWeight: 600}}>Número</label>
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}
                                  :
                                    (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <label style={{fontWeight: 600}}>Descrição</label>
                                </div>
                              </div>
                            }

                            { resultAccountPlanFilter && resultAccountPlanFilter.map(item => (
                              <div key={item.ID + "_" + item.NUMBER + "_" + item.DESCRIPTION} style={showMenu ?
                                  (width < 1170) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}
                                  : 
                                    {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}
                                :
                                  (width < 900) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}
                                  : 
                                    {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                    marginTop: 4, minWidth: 180}}>

                                <div style={(showMenu) ? 
                                    (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : 
                                    (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(showMenu) ? 
                                      (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                      <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccountPlan(item.ID) } />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label>{item.ID}</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                    :  
                                      (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                    <label>{item.NUMBER}</label>
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}
                                  :
                                    (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <label>{item.DESCRIPTION}</label>
                                </div>
                              </div>
                              ))
                            }

                            { resultAccountPlanFilter && (resultAccountPlanFilter.length === 0) &&
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                              </div>
                            }

                          </div>
                        </div>
                      }

                    </div>
                  </div>
                }

                { !billReceiveFilter &&
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={() => save(billReceiveSelected)}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_save} alt="Gerar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Gerar</label>
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={returnAdd}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>                        
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                        paddingLeft: 10}}>
                        { warning && 
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                          </div> 
                        }

                        { error &&
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                          </div>
                        }
                      </div>                      

                      <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10, marginTop: 10} : { paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
                        <Titulo description="Contas a receber selecionadas" />
                      </div>

                      <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10, marginTop: 10} : { paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                        { billReceiveSelected && (billReceiveSelected.length > 0) &&
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2, 
                            borderBottomStyle: "solid", marginTop: 30, marginBottom: 4}}>

                            <div style={(showMenu) ? 
                                (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 1870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={showMenu ?
                                  (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 1870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={showMenu ?
                                    (width < 2170) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 500, maxWidth: 500}
                                  :
                                    (width < 1870) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 500, maxWidth: 500}}>
                                  <label style={{fontWeight: 600}}>Número/Parcela</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 790) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 480) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                    <label style={{fontWeight: 600}}>Lancto.</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 790) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 480) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                    <label style={{fontWeight: 600}}>Vencto.</label>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 1870) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                  :
                                    (width < 1870) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                  <label style={{fontWeight: 600}}>Valor</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1280) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                <div style={showMenu ?
                                    (width < 1280) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}
                                  :
                                    (width < 1010) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordBreak: "break-all", minWidth: 430}}>
                                  <label style={{fontWeight: 600}}>Cliente</label>
                                </div>
                                <div style={showMenu ?
                                    (width < 1280) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}
                                  :
                                    (width < 1010) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 430}}>
                                  <label style={{fontWeight: 600}}>Plano Conta</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        { billReceiveSelected && billReceiveSelected.map(item => (
                          <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                            backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                            borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                            marginTop: 4, minWidth: 180}}>
          
                            <div style={(showMenu) ? 
                                (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 1870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
          
                              <div style={showMenu ?
                                  (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 1870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                   
                                <div style={showMenu ?
                                    (width < 2170) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                  :
                                    (width < 1870) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                  <label>{item.NUMBER + "/" + item.PORTION}</label>
                                </div>
                              </div>
          
                              <div style={showMenu ?
                                  (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>
          
                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>
          
                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 790) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 480) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                    <label>
                                      { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                        String(item.RELEASE_DATE).substr(0,4)  }
                                    </label>
                                  </div>
          
                                  <div style={showMenu ?
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 790) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 480) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                    <label>
                                      { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" +
                                        String(item.DUE_DATE).substr(0,4)  }
                                    </label>
                                  </div>
                                </div>
          
                                <div style={showMenu ?
                                    (width < 1870) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                  :
                                    (width < 1870) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                  <label>{formatNumeric(item.VALUE,2)}</label>
                                </div>
                              </div>
          
                              <div style={showMenu ?
                                  (width < 1280) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
          
                                <div style={showMenu ?
                                    (width < 1280) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}
                                  :
                                    (width < 1010) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordBreak: "break-all", minWidth: 430}}>
                                  <label>{item.CLIENT && item.CLIENT.CORPORATE_NAME}</label>
                                </div>
                                <div style={showMenu ?
                                    (width < 1280) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}
                                  :
                                    (width < 1010) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 430}}>
                                  <label>{item.ACCOUNT_PLAN && item.ACCOUNT_PLAN.DESCRIPTION}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          ))
                        }

                        <div style={{marginTop: 20}}>
                          <b>Para quantos mêses você deseja replicar ?</b>
                        </div>

                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                          <InputMasked
                            type="text"
                            name="dapReceiveQuantity"
                            description="Quantidade:"
                            mask="99"
                            value={quantity || ""}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected}
                            onChange={(event) => setQuantity(event.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                
              </div>
          }

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default DapReceive