import React, { useState, useEffect } from "react"

const AccessControlRequisition = ({permissionShiftPrinter, setShiftPrinter, showMenu, width}) => { 
  const [shiftPrinterF, setShiftPrinterF] = useState(1)  

  const handleShiftPrinter = () => {
    setShiftPrinterF(2)
    setShiftPrinter("F")
  }

  const handleShiftPrinterC = () => {
    setShiftPrinterF(1)
    setShiftPrinter("T")
  }

  useEffect(() => {
    if (permissionShiftPrinter === "T") setShiftPrinterF(1)
    else setShiftPrinterF(2)

  }, [permissionShiftPrinter])

  return(
    <div style={{marginTop: 10}}>
      <h3>Requisição</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Shift Printer */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Impressora Setor</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlShiftPrinter"
                checked={(shiftPrinterF === 1) ? true : false}
                onChange={(shiftPrinterF === 1) ? handleShiftPrinter : handleShiftPrinterC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlRequisition