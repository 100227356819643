import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const ClientContact = ({jsonClientContact, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [clientCode, setClientCode] = useState("")
  const [contact, setContact] = useState("")
  const [date, setDate] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  useEffect(() => {
    if ((jsonClientContact.ID !== undefined) && (jsonClientContact.ID != null)) setCode(jsonClientContact.ID)

    setClientCode(jsonClientContact.CLIENT.ID)

    if ((jsonClientContact.CONTACT !== undefined) && (jsonClientContact.CONTACT != null)) setContact(jsonClientContact.CONTACT)

    console.log(jsonClientContact.BIRTH_DATE)

    if (((jsonClientContact.BIRTH_DATE !== undefined) && (jsonClientContact.BIRTH_DATE != null)) && (jsonClientContact.BIRTH_DATE !== "0001-01-01T00:00:00"))
      setDate(String(jsonClientContact.BIRTH_DATE).substr(8,2) + "/" + String(jsonClientContact.BIRTH_DATE).substr(5,2) + "/" +
        String(jsonClientContact.BIRTH_DATE).substr(0,4))

      if ((jsonClientContact.EMAIL !== undefined) && (jsonClientContact.EMAIL != null)) setEmail(jsonClientContact.EMAIL)
    if ((jsonClientContact.PHONE !== undefined) && (jsonClientContact.PHONE != null)) setPhone(jsonClientContact.PHONE)

    document.getElementById("clientContactContact") && document.getElementById("clientContactContact").focus()  

  }, [jsonClientContact])


  function callSave(){
    let json = "{"
    json += " \"CLIENT\": { \"ID\": " + clientCode + " }"
    if (code !== "") json += ", \"ID\": "+ code    
    if (contact !== "") json += ", \"CONTACT\": \""+ contact +"\""
    if (date !== "") json += ", \"BIRTH_DATE\": \""+ date.substr(6,4) + "-" + date.substr(3,2) + "-" + date.substr(0,2) +"T00:00:00\""
    if (email !== "") json += ", \"EMAIL\": \""+ email +"\""
    if (phone !== "") json += ", \"PHONE\": \""+ phone.replaceAll("(","").replaceAll(")","").replaceAll("_","") +"\""
    json += " }"

    save(json)
  }

  function setFieldSelected(){
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        <div style={(showMenu) ?
            (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
          :
            (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Input
              type="text"
              name="clientContactContact"
              description="Contato"
              planceHolder=""
              value={contact}
              maxLength={60}
              disabled={false}
              require={true}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setContact(event.target.value)} />
          </div>
          <div style={(showMenu) ?
              (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
            :
              (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

            <InputMasked
              type="text"
              name="clientContactDate"
              description="Dt. Nascimento"
              planceHolder=""
              mask="99/99/9999"
              value={date}
              disabled={false}
              require={false}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setDate(event.target.value)} />
          </div>
        </div>

        <div style={(showMenu) ?
            (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
          :
            (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Input
              type="text"
              name="clientContactEmail"
              description="Email"
              planceHolder=""
              value={email}
              maxLength={70}
              disabled={false}
              require={false}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setEmail(event.target.value)} />
          </div>
          <div style={(showMenu) ?
              (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
            :
              (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

            <InputMasked
              type="text"
              name="clientContactPhone"
              description="Telefone"
              planceHolder=""
              mask="(999)999999999"
              value={phone}
              disabled={false}
              require={false}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setPhone(event.target.value)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientContact