import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import image_loading from "../../../Images/spinner.gif";
import image_delete from "../../../Images/Buttons/cancel.png"

const SearchBillReceiveReceipt = ({databaseType, databasePath,  jsonBillReceive, jsonLogin, system, callReversal, showFunctions, showMenu, width}) => {

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  
  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jBillReceiveReceipt = "{ \"BILL_RECEIVE\": { \"ID\": " + jsonBillReceive.ID + ", \"COMPANY\": { \"ID\": "+ jsonBillReceive.COMPANY.ID +" } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillReceiveReceipt)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILL_RECEIVE_RECEIPT/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar os Recebimentos da Conta a Receber. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })

  }, [databaseType, databasePath, jsonLogin, system, jsonBillReceive])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10, paddingLeft: 5, paddingRight: 5}
      : {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10, paddingLeft: 10, paddingRight: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { result.OBJECT &&
            <>
              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 2, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 10}}>

                <div style={(showMenu) ? 
                    (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                  : 
                    (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                    :
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                      <label style={{fontWeight: 600}}>Data</label>
                    </div>

                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 90}
                        : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                      :
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 90}
                        : (width < 1020) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                      <label style={{fontWeight: 600}}>Conta</label>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 1290) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                      : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                    :
                      (width < 1020) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                      : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                    <label style={{fontWeight: 600}}>Titular</label>
                  </div>

                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                    :
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1290) ? {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                      :
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1020) ? {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                      <label style={{fontWeight: 600}}>Valor</label>
                    </div>

                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                      :
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1020) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                      <label style={{fontWeight: 600}}>Borderô</label>
                    </div>
                  </div>
                </div>

                { (showFunctions) &&
                  <div style={(width < 450) ? {display: "none"}
                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>

                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                      paddingLeft: 5, height: 32, width: 32}}/>
                  </div>
                }

              </div>
              <hr/>

              { result.OBJECT && result.OBJECT.map(item => (
                  <div key={item.ID} style={
                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                      minWidth: 180}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                      paddingTop: 4, paddingBottom: 4, marginTop: 4, alignItems: "center"}}>
                  
                    <div style={(showMenu) ? 
                        (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                      : 
                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                          <label>
                            { String(item.RECEIPT_DATE).substr(8,2) + "/" + String(item.RECEIPT_DATE).substr(5,2) + "/" + 
                              String(item.RECEIPT_DATE).substr(0,4) }
                          </label>
                        </div>

                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 90}
                            : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 90}
                            : (width < 1020) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                          <label>{item.ACCOUNT.NUMBER}</label>
                        </div>
                      </div>

                      <div style={showMenu ?
                          (width < 1290) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                          : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                        :
                          (width < 1020) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                          : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                        <label>{item.ACCOUNT.OWNER}</label>
                      </div>

                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1290) ? {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1020) ? {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                          <label>{formatNumeric(item.VALUE,2)}</label>
                        </div>

                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1020) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                          <label>{(item.BORDEREAU_RECEIVE.ID !== 0) ? item.BORDEREAU_RECEIVE.ID : "-"}</label>
                        </div>
                      </div>
                    </div>

                    { (item.BORDEREAU_RECEIVE.ID === 0) && (jsonBillReceive.SITUATION !== 3) && (showFunctions) &&
                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 5,
                          marginTop: 5}
                        : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                            
                        <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                          paddingLeft: 5}}>
                          <img
                            style={{height: 32, width: 32}}
                            src={image_delete}
                            name="searchBillReceiveReceiptReversal"
                            alt="Estornar"
                            onClick={(() => callReversal(item))} />
                        </div>
                      </div>
                    }

                  </div>
                ))
              }
            </>
          }

          { !result.OBJECT &&
            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
              marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
            </div>
          }

        </>
      }

    </div>
  )
}

export default SearchBillReceiveReceipt