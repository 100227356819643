import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import SearchCfop from "./Search"
import image_loading from "../../Images/spinner.gif"

const Cfop = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [codeOrigin, setCodeOrigin] = useState("")
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")

  const [operationType, setOperationType] = useState(0)
  const [generateStock, setGenerateStock] = useState(0)
  const [generateFinancial, setGenerateFinancial] = useState(0)
  const [mandadorySeller, setMandadorySeller] = useState(0)
  const [productType, setProductType] = useState(0)
    
  const [jCfop, setJCfop] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (codeOrigin === "") {
      setDescription("")
      setOperationType(0)
      setGenerateStock(0)
      setGenerateFinancial(0)
      setMandadorySeller(0)
      setProductType(0)
    } 
    else {
      setDescription(jCfop.DESCRIPTION)

      setOperationType(jCfop.OPERATION_TYPE)
      setGenerateStock(jCfop.GENERATE_STOCK)
      setGenerateFinancial(jCfop.GENERATE_FINANCIAL)
      setMandadorySeller(jCfop.MANDADORY_SELLER)
      setProductType(jCfop.PRODUCT_TYPE)
    }

    if (codeOrigin === "") document.getElementById("cfopCode") && document.getElementById("cfopCode").focus()    
    else document.getElementById("cfopDescription") && document.getElementById("cfopDescription").focus()

  }, [codeOrigin, jCfop, updateFocu])     

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setDescription("")
      setOperationType(0)
      setGenerateStock(0)
      setGenerateFinancial(0)
      setMandadorySeller(0)
      setProductType(0)      
    }
    else{
      setCodeOrigin("")
      setCode("")
    }
    
    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCodeOrigin("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
        
    setWarning("")
    setError("")

    let jsonCfop_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonCfop_New += ", \"ID\": "+ code
    if (description !== "") jsonCfop_New += ", \"DESCRIPTION\": \""+ description + "\""
    if (operationType !== 0) jsonCfop_New += ", \"OPERATION_TYPE\": "+ operationType
    if (generateStock !== 0) jsonCfop_New += ", \"GENERATE_STOCK\": "+ generateStock
    if (generateFinancial !== 0) jsonCfop_New += ", \"GENERATE_FINANCIAL\": "+ generateFinancial
    if (mandadorySeller !== 0) jsonCfop_New += ", \"MANDADORY_SELLER\": "+ mandadorySeller
    if (productType !== 0) jsonCfop_New += ", \"PRODUCT_TYPE\": "+ productType
        
    jsonCfop_New += " }";

    if (codeOrigin === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonCfop_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "CFOP/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o CFOP. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")          
          document.getElementById("cfopDescription") && document.getElementById("cfopDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJCfop(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setCodeOrigin(returnJson.OBJECT.ID)

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jCfop))
      formData.append("JSON_NEW_1", jsonCfop_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "CFOP/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o CFOP. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cfopDescription") && document.getElementById("cfopDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJCfop(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }  

  const setSelected = (event) => {
    setShowSearch(false)
    setJCfop(event)
    setCode(event.ID)
    setCodeOrigin(event.ID)
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="CFOP"
            titleSearch="Pesquisa dos CFOPs"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchCfop
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={showMenu ?
                            (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                            :
                              (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                            <Input
                              type="text"
                              name="cfopCode"
                              description="Código"
                              value={code}
                              maxLength={4}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setCode(event.target.value)} />
                          </div>
                          <div style={showMenu ?
                              (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            :   
                              (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="text"
                              name="cfopDescription"
                              description="Descrição"
                              value={description}
                              maxLength={255}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                        </div>

                        <div style={(width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                            <Select
                              name="cfopOperationType"
                              description="Tipo Operação"
                              value={operationType || 0}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                ", {\"ID\": 1, \"VALUE\": \"Entrada dentro do estado\" }, {\"ID\": 2, \"VALUE\": \"Entrada fora do estado\" }"+
                                  ", {\"ID\": 3, \"VALUE\": \"Importação\" }, {\"ID\": 4, \"VALUE\": \"Saída dentro do estado\" }"+
                                    ", {\"ID\": 5, \"VALUE\": \"Saída fora do estado\" }, {\"ID\": 6, \"VALUE\": \"Exportação\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setOperationType} />
                          </div>
                          <div style={(width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                            <Select
                              name="cfopGenerateStock"
                              description="Gerar Estoque"
                              value={generateStock || 0}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                ", {\"ID\": 1, \"VALUE\": \"Aumentar estoque\" }, {\"ID\": 2, \"VALUE\": \"Diminuir estoque\" }"+
                                  ", {\"ID\": 3, \"VALUE\": \"Nenhum\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setGenerateStock} />
                          </div>
                        </div> 

                        <div style={showMenu ?
                            (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Select
                                name="cfopGenerateFinancial"
                                description="Gerar Financeiro"
                                value={generateFinancial || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setGenerateFinancial} />
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <Select
                                name="cfopMandadorySeller"
                                description="Vendedor Obrigatório"
                                value={mandadorySeller || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setMandadorySeller} />
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}
                            :
                              (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 230}}>
                            <Select
                              name="cfopProductType"
                              description="Tipo Produto"
                              value={productType || 0}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                ", {\"ID\": 1, \"VALUE\": \"Industrializacao\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                  ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Servico\" }"+
                                    ", {\"ID\": 5, \"VALUE\": \"Combustivel\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                      ", {\"ID\": 7, \"VALUE\": \"Materia\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                        ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                          ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setProductType} />
                          </div>
                        </div>
                  </div>
                </>
              }

            </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Cfop