import React from "react"
import Footer from "../../Components/Footer"
import image_command from "../../Images/Buttons/command.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_table from "../../Images/Buttons/table.png"

const LauchRestaurantType = ({setTypeLaunch, setReturn, height, width}) => {
  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, backgroundColor: "rgb(0, 177, 171)", height: 50}}>
      </div>

      <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        </div>

        <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "end", alignItems: "center", paddingBottom: 20, 
            minWidth: 280, maxWidth: 280}}>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "end", marginBottom: 20}}>
              <label style={{fontWeight: 600, color: "white"}}>Escolha a opção desejada:</label>        
            </div>

            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, width: 230}} 
              onClick={() => setTypeLaunch(1)}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_table} alt="Lançamento Mesa"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Mesa</label>
              </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, width: 230}} 
                onClick={() => setTypeLaunch(2)}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_command} alt="Lançamento Mesa"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Comanda</label>
              </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, width: 230}} 
              onClick={setReturn}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_previous} alt="Sair"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Sair</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
        <Footer />
      </div>
    </div>
  )
}

export default LauchRestaurantType