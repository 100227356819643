import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const ProductCompanyNve = ({jsonProductCompanyNve, jsonCompany, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [productCompanyCode, setProductCompanyCode] = useState("")
  const [number, setNumber] = useState("")

  useEffect(() => {
    setCode(jsonProductCompanyNve.ID)
    setProductCompanyCode(jsonProductCompanyNve.PRODUCT_COMPANY.ID)
    setNumber(jsonProductCompanyNve.NUMBER)

    document.getElementById("productCompanyNveNumber") && document.getElementById("productCompanyNveNumber").focus()

  }, [jsonProductCompanyNve])

  const callSave = () => {
    let json = "{"

    if ((code !== undefined) && (code !== 0)){
      let codeN = String(code).replaceAll("_","")
      if (codeN !== "") json += " \"ID\": "+ codeN + ","
    }
 
    json += " \"PRODUCT_COMPANY\": { \"ID\": " + productCompanyCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
      ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }, \"NUMBER\": \""+ 
        number +"\" }"

    save(json)
  }

  const handleCode = (event) => {
    let codeN = String(event.target.value).replaceAll("_","")
    if (codeN !== "") setCode(event.target.value)
    else setCode("")
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

        <div style={showMenu ?
            (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
          :  
            (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}}>

          <div style={showMenu ?
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
              : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
            :
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
            <InputMasked
              name="productCompanyNveCode"
              description="Código"
              planceHolder=""
              mask="99999999999"
              value={code}
              disabled={true}
              require={false}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={handleCode} />
          </div>
          <div style={showMenu ?
              (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
              : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
            :
              (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
            <Input
                type="text"
                name="productCompanyNveNumber"
                description="Número"
                value={number || ""}
                maxLength={6}
                disabled={false}
                require={true}
                negative={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setNumber(event.target.value)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCompanyNve