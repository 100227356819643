import "./style.css"
import React from "react"

const Questionnaire = ({ask, setConfirm, setCancel}) => {
  function response(value){
    if (value === 1) setConfirm()
    else setCancel()
  }

  return(
    <>
      <div id="main_Questionnaire">
        <div id="containerAsk_Questionnaire">
          <label id="ask_Questionnaire">{ask}</label>
        </div>
        <div>
          <div id="buttonConfirm_Questionnaire" onClick={() => response(1)}>
            <label className="textButton_Questionnaire">Sim</label>
          </div>
          <div id="buttonCancel_Questionnaire" onClick={() => response(2)}>
            <label className="textButton_Questionnaire">Não</label>
          </div>
        </div>
      </div>    
    </>
  )
}

export default Questionnaire