import React, { useState, useEffect } from "react"

const AccessControlStock = ({permissionBox, permissionDepartment, permissionManufacturer, permissionPriceTable, permissionProduct, permissionStock,
  permissionSupplier, permissionUnit, setBox, setDepartment, setManufacturer, setPriceTable, setProduct, setStock, setSupplier, setUnit, showMenu,
  width}) => {

  const [boxF, setBoxF] = useState(1)
  const [departmentF, setDepartmentF] = useState(1)  
  const [manufacturerF, setManufacturerF] = useState(1)
  const [priceTableF, setPriceTableF] = useState(1)
  const [productF, setProductF] = useState(1)
  const [stockF, setStockF] = useState(1)
  const [supplierF, setSupplierF] = useState(1)
  const [unitF, setUnitF] = useState(1)

  const handleBox = () => {
    setBoxF(2)   
    setBox("F")
  }

  const handleBoxC = () => {
    setBoxF(1)
    setBox("T")
  }

  const handleDepartment = () => {
    setDepartmentF(2)
    setDepartment("F")
  }

  const handleDepartmentC = () => {
    setDepartmentF(1)
    setDepartment("T")
  }

  const handleManufacturer = () => {
    setManufacturerF(2)
    setManufacturer("F")
  }

  const handleManufacturerC = () => {
    setManufacturerF(1)
    setManufacturer("T")
  }

  const handlePriceTable = () => {
    setPriceTableF(2)
    setPriceTable("F")
  }

  const handlePriceTableC = () => {
    setPriceTableF(1)
    setPriceTable("T")
  }

  const handleProduct = () => {
    setProductF(2)
    setProduct("F")
  }

  const handleProductC = () => {
    setProductF(1)
    setProduct("T")
  }

  const handleStock = () => {
    setStockF(2)
    setStock("F")
  }

  const handleStockC = () => {
    setStockF(1)
    setStock("T")
  }

  const handleSupplier = () => {
    setSupplierF(2)
    setSupplier("F")
  }

  const handleSupplierC = () => {
    setSupplierF(1)
    setSupplier("T")
  }

  const handleUnit = () => {
    setUnitF(2)
    setUnit("F")
  }

  const handleUnitC = () => {
    setUnitF(1)
    setUnit("T")
  }

  useEffect(() => {
    if (permissionBox === "T") setBoxF(1)
    else setBoxF(2)

    if (permissionDepartment === "T") setDepartmentF(1)
    else setDepartmentF(2)

    if (permissionManufacturer === "T") setManufacturerF(1)
    else setManufacturerF(2)

    if (permissionPriceTable === "T") setPriceTableF(1)
    else setPriceTableF(2)

    if (permissionProduct === "T") setProductF(1)
    else setProductF(2)

    if (permissionStock === "T") setStockF(1)
    else setStockF(2)

    if (permissionSupplier === "T") setSupplierF(1)
    else setSupplierF(2)

    if (permissionUnit === "T") setUnitF(1)
    else setUnitF(2)

  }, [permissionBox, permissionDepartment, permissionManufacturer, permissionPriceTable, permissionProduct, permissionStock, permissionSupplier,
    permissionUnit])

  return(
    <div style={{marginTop: 10}}>
      <h3>Estoque</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Supplier */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Fornecedor</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockSupplier"
                checked={(supplierF === 1) ? true : false}
                onChange={(supplierF === 1) ? handleSupplier : handleSupplierC} />
            </div>
          </div>
        </div>
      </div>

      {/* Manufacturer */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Fabricante</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockManufacturer"
                checked={(manufacturerF === 1) ? true : false}
                onChange={(manufacturerF === 1) ? handleManufacturer : handleManufacturerC} />
            </div>
          </div>
        </div>
      </div>

      {/* Unit */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Unidade</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockUnit"
                checked={(unitF === 1) ? true : false}
                onChange={(unitF === 1) ? handleUnit : handleUnitC} />
            </div>
          </div>
        </div>
      </div>

      {/* Department */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Departamento</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockDepartment"
                checked={(departmentF === 1) ? true : false}
                onChange={(departmentF === 1) ? handleDepartment : handleDepartmentC} />
            </div>
          </div>
        </div>
      </div>

      {/* Box */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Box</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockBox"
                checked={(boxF === 1) ? true : false}
                onChange={(boxF === 1) ? handleBox : handleBoxC} />
            </div>
          </div>
        </div>
      </div>

      {/* Stock */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Estoque</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockStock"
                checked={(stockF === 1) ? true : false}
                onChange={(stockF === 1) ? handleStock : handleStockC} />
            </div>
          </div>
        </div>
      </div>

      {/* Product */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Produto</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockProduct"
                checked={(productF === 1) ? true : false}
                onChange={(productF === 1) ? handleProduct : handleProductC} />
            </div>
          </div>
        </div>
      </div>

      {/* Price Table */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Tabela de Preço</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="stockPriceTable"
                checked={(priceTableF === 1) ? true : false}
                onChange={(priceTableF === 1) ? handlePriceTable : handlePriceTableC} />
            </div>
          </div>
        </div>
      </div>      
    </div>
  )
}

export default AccessControlStock