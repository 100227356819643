import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import image_loading from "../../../Images/spinner.gif";

const ProductAlterationSituation = ({databaseType, databasePath, jsonProductCompany, jsonCompany, jsonLogin, system, showMenu, width}) => {
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  
  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jProductAlterationSituation = "{ \"PRODUCT_COMPANY\": { \"ID\": " + jsonProductCompany.ID + " } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jProductAlterationSituation)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT_ALTERATION_SITUATION/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar as alterações das situações do produto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonProductCompany, jsonLogin, system])

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }
          
          <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
            : {paddingLeft: 10, paddingRight: 10}}>

            { result.OBJECT ?
                <>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                    borderBottomStyle: "solid", marginTop: 10, marginBottom: 4}}>

                    <div style={(showMenu) ?
                        (width < 1190) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      : 
                        (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        :                            
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 80, maxWidth: 80}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 80, maxWidth: 80}}>
                          <label style={{fontWeight: 600}}>Código</label>
                        </div>

                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                          <label style={{fontWeight: 600}}>Anterior</label>
                        </div>
                      </div>

                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                          <label style={{fontWeight: 600}}>Nova</label>
                        </div>


                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                            : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>

                          <label style={{fontWeight: 600}}>Data</label>
                        </div>
                      </div>

                      <div style={showMenu ?
                          (width < 1190) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 15, minWidth: 180}
                          : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                        :
                          (width < 920) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 15, minWidth: 180}
                          : {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>

                        <label style={{fontWeight: 600}}>Reponsável</label>
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}
                    : (width < 330) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, minWidth: 32, maxWidth: 32}
                      : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />

                    { result.OBJECT.map(item => (
                      <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                        marginTop: 4, minWidth: 180}}>

                        <div style={(showMenu) ?
                            (width < 1190) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                          : 
                            (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :                            
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                              <label>{item.ID}</label>
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                              <label>{item.SITUATION_OLD}</label>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 80, maxWidth: 80}}>
                              <label>{item.SITUATION_NEW}</label>
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1190) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>

                              <label>{ String(item.DATE).substr(8,2) + "/" + String(item.DATE).substr(5,2) + "/" + String(item.DATE).substr(0,4) + " " +
                                String(item.DATE).substr(11,8) }</label>
                            </div>
                          </div>

                          <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}}>
                            <label>{item.NAME}</label>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              :
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                  marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                </div>
            }

          </div>
        </>
      }
    </div>
  )
}

export default ProductAlterationSituation