import React, {useEffect} from "react"

const DigitalKeyboardNumeric = ({field, pressButton, pressBackSpace, pressEnter, width}) => {  
  useEffect(() => {
  }, [field])

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "7")}>
          <label style={{color: "white", fontWeight: 600}}>7</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "8")}>
          <label style={{color: "white", fontWeight: 600}}>8</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "9")}>
          <label style={{color: "white", fontWeight: 600}}>9</label>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "4")}>
          <label style={{color: "white", fontWeight: 600}}>4</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "5")}>
          <label style={{color: "white", fontWeight: 600}}>5</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "6")}>
          <label style={{color: "white", fontWeight: 600}}>6</label>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)",  justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "1")}>
          <label style={{color: "white", fontWeight: 600}}>1</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "2")}>
          <label style={{color: "white", fontWeight: 600}}>2</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "3")}>
          <label style={{color: "white", fontWeight: 600}}>3</label>
        </div>
      </div>
    
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressBackSpace(field)}>
          <label style={{color: "white", fontWeight: 600}}>{"<"}</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressButton(field, "0")}>
          <label style={{color: "white", fontWeight: 600}}>0</label>
        </div>
        <div style={{display: "flex", flexGrow: 0, height: 50, width: 50, backgroundColor: "rgb(128, 130, 133)", justifyContent: "center", 
          alignItems: "center"}} onClick={() => pressEnter(field)}>
          <label style={{color: "white", fontWeight: 600}}>{">"}</label>
        </div>
      </div>        
    </div>
  )
}

export default DigitalKeyboardNumeric
