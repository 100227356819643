import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import SearchDepartment from "../Department/Search"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const PrinterSectionDepartment = ({databaseType, databasePath, jsonPrinterSectionDepartment, jsonCompany, jsonLogin, system, save, back, showMenu, 
  width}) => {

  const [code, setCode] = useState("")
  const [printerSectionCode, setPrinterSectionCode] = useState("")
  const [printerSectionDescription, setPrinterSectionDescription] = useState("")

  const [departmentCode, setDepartmentCode] = useState("")
  const [departmentDescription, setDepartmentDescription] = useState("")
  const [showDepartment, setShowDepartment] = useState(false)

  const [sge, setSge] = useState("")

  useEffect(() => {       
    setCode(jsonPrinterSectionDepartment.ID)
    setPrinterSectionCode(jsonPrinterSectionDepartment.RTS_PRINTER.ID)
    setPrinterSectionDescription(jsonPrinterSectionDepartment.RTS_PRINTER.DESCRIPTION)

    if (jsonPrinterSectionDepartment.DEPARTMENT != null){
      setDepartmentCode(jsonPrinterSectionDepartment.DEPARTMENT.ID)
      setDepartmentDescription(jsonPrinterSectionDepartment.DEPARTMENT.DESCRIPTION)
    }
    else
    {
      setDepartmentCode("")
      setDepartmentDescription("")
    }

    if ((jsonPrinterSectionDepartment.SGE !== null) && (jsonPrinterSectionDepartment.SGE !== undefined)) setSge(jsonPrinterSectionDepartment.SGE)

  }, [jsonPrinterSectionDepartment])

  const cleanDepartment = () => {
    setDepartmentCode("")
    setDepartmentDescription("")

    setShowDepartment(false)
  }

  const enableDepartment = () => {
    setShowDepartment(!showDepartment)
  }  

  const fillDepartment = (section) => {
    setDepartmentCode(section.ID)
    setDepartmentDescription(section.DESCRIPTION)
  
    setShowDepartment(false)
  }  

  const callSave = () => {
    let json = "{"

    if (code !== undefined){
      json += " \"ID\": "+ code
      json += ", \"RTS_PRINTER\": { \"ID\": " + printerSectionCode + ", \"DESCRIPTION\": \""+ printerSectionDescription +"\""+
        ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
          ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }"
    } else json += " \"RTS_PRINTER\": { \"ID\": " + printerSectionCode + ", \"DESCRIPTION\": \""+ printerSectionDescription +"\""+
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }"

    if (departmentCode !== "") json += ", \"DEPARTMENT\": { \"ID\": " + departmentCode + ", \"DESCRIPTION\": \""+ departmentDescription +"\" }"
    if ((sge !== undefined) && (sge !== "")) json += ", \"SGE\": "+ sge 

    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showDepartment &&
        <>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={callSave}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={() => back()}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
          </div>
        
          <div style={(showMenu) ? (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "column", flexGrow: 1}
            : (width < 601) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "column", flexGrow: 1}}>

            <div style={(width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginBottom: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                <Input
                  type="text"
                  name="printerSectionDepartmentDepartmentDescription"
                  description="Descrição"
                  value={departmentDescription}
                  maxLength={150}
                  disabled={true}
                  require={true}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  onChange={(event) => setDepartmentDescription(event.target.value)} />
              </div>
              <div style={(width < 540) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                  <img
                    style={{height: 42, width: 42}}
                    name="printerSectionDepartmentSearchDepartment"
                    src={image_search} 
                    alt="Pesquisar" 
                    onClick={enableDepartment} />
                </div>
                <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                  <img
                    style={{height: 42, width: 42}}
                    name="printerSectionDepartmentCleanDepartment"
                    src={image_clean}
                    alt="Limpar"
                    onClick={cleanDepartment} />
                </div>
              </div>
            </div>
          </div>
        </>
      }

      { showDepartment &&
        <div style={{marginTop: 10}}>
          <div style={{marginLeft: 10}}>
            <b>Selecione o departamento:</b>
          </div>

          <SearchDepartment
            modeSearch={true}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonCompany={jsonCompany}
            jsonLogin={jsonLogin}
            system={system}
            setAdd={() => alert("")}
            setSelected={fillDepartment} 
            setReturn={() => setShowDepartment(false)} 
            periodic={0}
            showMenu={showMenu}
            width={width} />
        </div>
      }

    </div>
  )
}

export default PrinterSectionDepartment