import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import CompanyEmail from ".."
import Questionnaire from "../../../Components/Questionnaire"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchCompanyEmail = ({databaseType, databasePath, jsonCompany, jsonLogin, system, showMenu, width}) => {
  const [code, setCode] = useState(0)  
  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonCompanyEmail, setJsonCompanyEmail] = useState("")

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jCompanyEmail = "{ \"RH_SECTION\": { \"COMPANY\": { \"ID\": \""+ jsonCompany.ID + "\" } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jCompanyEmail)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "COMPANY_EMAIL/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar os emails da filial. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.CONCRETIZADO === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, update])

  const add = () => {
    const jCompanyEmail = "{ \"RH_SECTION\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } } }"    
  
    setJsonCompanyEmail(JSON.parse(jCompanyEmail))
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setError("")
    setWarning("")

    setShowChange(false)
  }

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o email (" + item.EMAIL + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jPhone = "{ \"ID\": "+ code +", \"RH_SECTION\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } } }"

    setLoading("Aguarde excluido o email...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jPhone)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "COMPANY_EMAIL/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir o email da filial. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      const count = update + 1
      setUpdate(count)
    })
  }

  const save = (email) => {
    let returnJson = []

    setWarning("")
    setError("")

    if (JSON.parse(email).ID === undefined)
    {
      setLoading("Aguarde incluindo o email...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", email)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "COMPANY_EMAIL/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o email da filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
    else
    {
      setLoading("Aguarde alterando o email...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonCompanyEmail))
      formData.append("JSON_NEW_1", email)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "COMPANY_EMAIL/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o email da filial. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
  }

  const select = (email) => {
    setWarning("")
    setError("")
    setJsonCompanyEmail(email)
    setShowChange(true)
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <CompanyEmail
                databaseType={databaseType}
                databasePath={databasePath}
                jsonCompanyEmail={jsonCompanyEmail}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                save={save}
                back={back} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                          marginLeft: 5, width: 147}} onClick={() => add()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}>

                        { result.OBJECT ?
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 10}}>
                                <div style={(showMenu) ? 
                                    (width < 1190) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  : 
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                    <label style={{fontWeight: 600}}>Setor</label>
                                  </div>      
                                  <div style={showMenu ?
                                      (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                    :
                                      (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>

                                    <label style={{fontWeight: 600}}>Email</label>
                                  </div>
                                </div>
                                <div style={(width < 550) ? {display: "none"} 
                                  : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 5, height: 32, width: 32}}/>

                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 0, marginTop: 2, height: 28, width: 2}} />
                                </div>
                              </div>
                              <hr/>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={
                                  (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                    minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4, alignItems: "center"}}>

                                  <div style={(showMenu) ? 
                                      (width < 1190) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    : 
                                      (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                      <label>{item.RH_SECTION.DESCRIPTION}</label>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 1190) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                                      :
                                        (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                                      {item.EMAIL}
                                    </div>
                                  </div>
                                  <div style={(width < 550) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 5,
                                    marginTop: 5}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                        
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>
                                      <img
                                        style={{height: 32, width: 32}}
                                        src={image_select}
                                        name="searchCompanyEmailSelected"
                                        alt="Selecionar"
                                        onClick={(() => select(item))} />
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>
                                      <img
                                        style={{height: 28, width: 28, marginTop: 2}}
                                        name="searchCompanyEmailDelete"
                                        src={image_delete}
                                        alt="Excluir"
                                        onClick={() => callDelete(item)} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          :
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </>
      }
    </div>
  )
}

export default SearchCompanyEmail