import React, { useState, useEffect } from "react"

const AccessControlBilling = ({permissionCentralTables, permissionServiceInvoice, setCentralTables, setServiceInvoice, showMenu, width}) => {
  const [centralTablesF, setCentralTablesF] = useState(1)
  const [serviceInvoiceF, setServiceInvoiceF] = useState(1)

  const handleCentralTables = () => {
    setCentralTablesF(2)
    setCentralTables("F")
  }

  const handleCentralTablesC = () => {
    setCentralTablesF(1)
    setCentralTables("T")
  }

  const handleServiceInvoice = () => {
    setServiceInvoiceF(2)
    setServiceInvoice("F")
  }

  const handleServiceInvoiceC = () => {
    setServiceInvoiceF(1)
    setServiceInvoice("T")
  }

  useEffect(() => {
    if (permissionCentralTables === "T") setCentralTablesF(1)
    else setCentralTablesF(2)

    if (permissionServiceInvoice === "T") setServiceInvoiceF(1)
    else setServiceInvoiceF(2)
  }, [permissionCentralTables, permissionServiceInvoice])

  return(
    <div style={{marginTop: 10}}>
      <h3>Faturamento</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Processo</h4>
      </div>      

      {/* Service Invoice */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Nota de Serviço</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="billingServiceInvoice"
                checked={(serviceInvoiceF === 1) ? true : false}
                onChange={(serviceInvoiceF === 1) ? handleServiceInvoice : handleServiceInvoiceC} />
            </div>
          </div>
        </div>
      </div>

      {/* Central Tables */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Central das Mesas</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="billingCentralTables"
                checked={(centralTablesF === 1) ? true : false}
                onChange={(centralTablesF === 1) ? handleCentralTables : handleCentralTablesC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlBilling