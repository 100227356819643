import "./style.css"
import React, { useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import img_boneco from "../../Images/usuario.png"
import img_cadeado from "../../Images/cadeado.png"

const InputNumeric = ({type, name, description, value, maxLength, disabled, require, negative, image, typeImage, typeInput, onSelect, onChange}) => {
  const [obrigatorio, setObrigatorio] = useState("")
  const [colorFocu, setColorFocu] = useState( (typeInput === 1) ? "corpoUnFocuLogin_inputNumeric" : "corpoUnFocuTela_inputNumeric" )
  const descricaoInputNumeric = (typeInput === 1) ? "descricaoLogin_inputNumeric" : "descricaoTela_inputNumeric"

  useEffect(() => {
    if (String(value) !== "") setObrigatorio("naoObrigatorio_inputNumeric")
    else
    {        
      if (require){
        if (String(value) !== "") setObrigatorio("naoObrigatorio_inputNumeric")
        else setObrigatorio("obrigatorio_inputNumeric")
      } else setObrigatorio("naoObrigatorio_inputNumeric")
    }
  }, [require, value])

  function handleBlur(event) {
    if (event.target.value === "") {
      if (require) setObrigatorio("obrigatorio_inputNumeric"); else setObrigatorio("naoObrigatorio_inputNumeric")        
    } else setObrigatorio("naoObrigatorio_inputNumeric")

    if (typeInput === 1) setColorFocu("corpoUnFocuLogin_inputNumeric")
    else if (typeInput === 2) setColorFocu("corpoUnFocuTela_inputNumeric")
  }

  function handleFocu(){
    if (typeInput === 1) setColorFocu("corpoFocuLogin_inputNumeric")
    else if (typeInput === 2) setColorFocu("corpoFocuTela_inputNumeric")

    onSelect(name)
  }

  return(
    <div style={{ width: "100%" }}>
      <div id="inputNumeric">

        { description &&
          <div id={descricaoInputNumeric}>
            <label>{description}</label>
          </div>
        }

        <div id={colorFocu}>

          { image &&
            <>

              { (typeImage === 1) && 
                <div className="corpoImagem_inputNumeric">
                  <img src={img_boneco} alt="boneco" /> 
                </div>
              }

              { (typeImage === 2) && 
                <div className="corpoImagem_inputNumeric">
                  <img src={img_cadeado} alt="cadeado" /> 
                </div>
              }

              <div id="corpoCampoImagem_inputNumeric">
                <NumberFormat
                  type={type}
                  id={name}
                  name={name}
                  className={obrigatorio}                  
                  allowNegative={negative}
                  value={value || ""}
                  maxLength={maxLength}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onFocus={handleFocu}
                  onChange={onChange} />  
              </div>

            </>
          }

          { !image &&
            <>
              <div id="corpoCampoSemImagem_inputNumeric">
                <NumberFormat
                  type={type}
                  id={name}
                  name={name}                
                  className={obrigatorio}                  
                  allowNegative={negative}
                  value={value || ""}
                  maxLength={maxLength}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onFocus={handleFocu}
                  onChange={onChange} /> 
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default InputNumeric