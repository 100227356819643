import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import BilletClient from "../"
import Questionnaire from "../../../Components/Questionnaire"
import SearchPagination from "../../../Components/SearchPagination"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";

const SearchBilletClient = ({databaseType, databasePath, jsonCompany, jsonLogin, system, showMenu, width}) => {
  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState(0)
  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonBilletClient, setJsonBilletClient] = useState("")
    
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)    
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0) 

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jBilletClient = "{ \"COMPANY\": { \"ID\": " + jsonCompany.ID + " } }"

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBilletClient)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILLET_CLIENT/TotalRecords", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar o total de clientes do boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      const tRecord = returnJson.OBJECT
      if (tRecord === 0)
      {
        setWarning("Nenhum registro encontrado.")
        setLoading("")
        return
      }

      const operation = tRecord / recordPage
      const numberR = Math.floor(operation)
      const decimal = operation % 1

      let tPage = 0
      if (decimal > 0) tPage = numberR + 1
      else tPage = numberR

      if (tRecord > 600) setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
        ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

      setTotalRecord(tRecord)
      setTotalPage(tPage)
      if (tPage < pageCurrent) setPageCurrent(1)

      returnJson.WARNING && setWarning(returnJson.WARNING)
    
      let qtdeInitial = 0
      if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jBilletClient)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", qtdeInitial)
      formData.append("QUANTITY_RECORDS", recordPage)

      axios.post(BASE_URL + "BILLET_CLIENT/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os clientes do boleto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
          
        setResult(returnJson)
        setLoading("")
      })
    })
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, pageCurrent, recordPage, update])

  const add = () => {
    const jBilletClient = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }"
  
    setJsonBilletClient(JSON.parse(jBilletClient))
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setError("")
    setWarning("")

    setShowChange(false)
  }

  const callDelete = (item) => {
    setCode(item.ID)

    setAskQuestionnaire("Deseja realmente excluir o Cliente ("+ item.CLIENT.CORPORATE_NAME +") do Boleto ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jBilletClient = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }"

    setLoading("Aguarde excluido o Cliente do Boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBilletClient)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILLET_CLIENT/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir o Cliente do Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setResult({})

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      const count = update + 1
      setUpdate(count)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const save = (billetClient) => {
    let returnJson = []

    setWarning("")
    setError("")

    setLoading("Aguarde incluindo o Cliente do Boleto...")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", billetClient)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "BILLET_CLIENT/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível incluir o Cliente do Boleto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Inclusão efetuada com sucesso!")

      setLoading("")
      setError("")
      setShowChange(false)

      const count = update + 1
      setUpdate(count)
    })
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <BilletClient
                databaseType={databaseType}
                databasePath={databasePath}
                jsonBilletClient={jsonBilletClient}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                save={save}
                back={back} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                          marginTop: 5, marginLeft: 5, width: 147}} onClick={() => add()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}>

                        { result.OBJECT &&
                          <>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 10}}>
                              <div style={showMenu ?
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                :
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>
                                <div style={showMenu ?
                                    (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                  :
                                    (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                  <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                                </div>
                                <div style={showMenu ?
                                    (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 380}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 380}
                                  :
                                    (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 380}}>
                                  <label style={{fontWeight: 600}}>Razão Social / Nome</label>
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 980) ? {display: "none"} 
                                  : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}
                                :
                                  (width < 690) ? {display: "none"} 
                                  : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}}>
                                <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                  paddingLeft: 0, marginTop: 2, height: 28, width: 2}} />
                              </div>
                            </div>
                            <hr/>

                            { result.OBJECT.map(item => (
                              <div key={item.ID} style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                    minWidth: 180}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4, alignItems: "center"}
                                :
                                    (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                    minWidth: 180}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4, alignItems: "center"}}>

                                <div style={showMenu ?
                                    (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  :
                                    (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>
                                  <div style={showMenu ?
                                      (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                      : (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                    :
                                      (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                    <label>
                                      { (String(item.CLIENT.CPF_CNPJ).length === 11) ?
                                          String(item.CLIENT.CPF_CNPJ).padStart(11,'0').substring(0,3) + "." + 
                                            String(item.CLIENT.CPF_CNPJ).padStart(11,'0').substring(3,6) + "." + 
                                              String(item.CLIENT.CPF_CNPJ).padStart(11,'0').substring(6,9) + "-" + 
                                                String(item.CLIENT.CPF_CNPJ).padStart(11,'0').substring(9,11)
                                        :
                                          String(item.CLIENT.CPF_CNPJ).padStart(14,'0').substring(0,2) + "." + 
                                            String(item.CLIENT.CPF_CNPJ).padStart(14,'0').substring(2,5) + "." + 
                                              String(item.CLIENT.CPF_CNPJ).padStart(14,'0').substring(5,8) + "/" + 
                                                String(item.CLIENT.CPF_CNPJ).padStart(14,'0').substring(8,12) + "-" +
                                                  String(item.CLIENT.CPF_CNPJ).padStart(14,'0').substring(12,14)
                                      }  
                                    </label>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 380}
                                      : (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 1, minWidth: 380}
                                    :
                                      (width < 640) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 1, minWidth: 380}}>
                                    <label>{item.CLIENT.CORPORATE_NAME}</label>
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 5, marginTop: 5}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}
                                  :
                                    (width < 690) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 5, marginTop: 5}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}}>
                                    
                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 5}}>
                                    <img
                                      style={{height: 28, width: 28, marginTop: 2}}
                                      name="searchBilletClientDelete"
                                      src={image_delete}
                                      alt="Excluir"
                                      onClick={() => callDelete(item)} />
                                  </div>
                                </div>
                              </div>
                            ))}

                            <SearchPagination
                            pageCurrent={pageCurrent}
                            totalPage={totalPage}
                            recordPage={recordPage}
                            optionsRegPage={JSON.parse(optionsRegPage)}
                            pagePrevious={pagePrevious}
                            pageNext={pageNext}
                            setSelectedField={setFieldSelected}
                            handleRecordPage={handleRecordPage}
                            width={width} />
                          </>
                        }
                      </div>
                    </>
                }
              </>
          }
        </>
      }
    </div>
  )
}

export default SearchBilletClient