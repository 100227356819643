import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import SearchRhSection from "../RhSection/Search"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const CompanyPhone = ({databaseType, databasePath, jsonCompanyPhone, jsonCompany, jsonLogin, system, save, back, width}) => {
  const [code, setCode] = useState("")
  const [rhSectionCode, setRhSectionCode] = useState("")
  const [rhSectionDescription, setRhSectionDescription] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [number, setNumber] = useState("")
  const [extension, setExtension] = useState("")
    
  const [showRhSection, setShowRhSection] = useState(false)

  useEffect(() => {
    setCode(jsonCompanyPhone.ID)
    setRhSectionCode(jsonCompanyPhone.RH_SECTION.ID)
    setRhSectionDescription(jsonCompanyPhone.RH_SECTION.DESCRIPTION)
    setCompanyCode(jsonCompanyPhone.RH_SECTION.COMPANY.ID)
    setNumber(jsonCompanyPhone.NUMBER)

    if (jsonCompanyPhone.EXTENSION === "0") setExtension("")
    else setExtension(jsonCompanyPhone.EXTENSION)

  }, [jsonCompanyPhone])

  const cleanRhSection = () => {
    setRhSectionCode("")
    setRhSectionDescription("")

    setShowRhSection(false)
  }

  const enableRhSection = () => {
    setShowRhSection(!showRhSection)
  }  

  const fillRhSection = (section) => {
    setRhSectionCode(section.ID)
    setRhSectionDescription(section.DESCRIPTION)
  
    setShowRhSection(false)
  }  

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"RH_SECTION\": {"
    if ((rhSectionCode !== undefined) && (rhSectionCode !== 0)){
      json += " \"ID\": "+ rhSectionCode + ", \"DESCRIPTION\": \""+ rhSectionDescription + "\","
    }
    json += " \"COMPANY\": { \"ID\": "+ companyCode + " } }"
    if (number !== undefined) json += ", \"NUMBER\": \""+ number.replaceAll("(","").replaceAll(")","").replaceAll("_","") +"\""
    else json += ", \"NUMBER\": \"\""
    if ((extension !== undefined) && (extension !== "")) json += ", \"EXTENSION\": "+ parseInt(String(extension).replaceAll("_",""))
    else json += ", \"EXTENSION\": "+ 0
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showRhSection &&
        <>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={callSave}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={() => back()}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
          </div>

          <div style={(width < 540) ? { marginTop: 10}
            : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
            <label style={{fontWeight: 600}}>Setor RH</label>
          </div>

          <div style={(width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 20, paddingRight: 20}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
              <Input
                type="text"
                name="rhSectionDescription"
                description="Descrição"
                value={rhSectionDescription}
                maxLength={60}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setRhSectionDescription(event.target.value)} />
            </div>
            <div style={(width < 540) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
              marginTop: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="companyPhoneRhSectionSearch"
                  src={image_search} 
                  alt="Pesquisar" 
                  onClick={(() => enableRhSection(true))} />
              </div>
              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="companyPhoneRhSectionClean"
                  src={image_clean} 
                  alt="Limpar"
                  onClick={cleanRhSection} />
              </div>
            </div>
          </div>

          <div style={
            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
            : (width < 540) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginBottom: 10}}>
            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
              <InputMasked
                type="text"
                name="companyPhoneNumber"
                description="Telefone"
                mask="(999)999999999"
                value={number}
                disabled={false}
                require={true}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setNumber(event.target.value)} />
            </div>
            <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
              <InputMasked
                type="text"
                name="companyPhoneExtension"
                description="Ramal"
                mask="999"
                value={extension}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setExtension(event.target.value)} />
            </div>
          </div>
        </>
      }

      { showRhSection &&
        <div style={(width < 540) ? {paddingLeft: 0, paddingRight: 0}
          : {paddingLeft: 10, paddingRight: 10}}>
          <b>Selecione o Setor RH:</b>
          <SearchRhSection
            modeSearch={true}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonLogin={jsonLogin}
            jsonCompany={jsonCompany}
            system={system}
            setAdd={() => alert("")}
            setSelected={fillRhSection} 
            setReturn={() => setShowRhSection(false)} 
            width={width} />
        </div>
      }
    </div>
  )
}

export default CompanyPhone