import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Menu from "../Menu"
import SearchRHShift from "./Search"
import image_loading from "../../Images/spinner.gif"

const RhShift = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [workLoad, setWorkLoad] = useState("")
  const [entrance, setEntrance] = useState("")
  const [entranceTolerance, setEntranceTolerance] = useState("")
  const [meal, setMeal] = useState("")
  const [mealTolerance, setMealTolerance] = useState("")
  const [output, setOutput] = useState("")
  const [outputTolerance, setOutputTolerance] = useState("")
  const [sge, setSge] = useState("")

  const [jsonRHShift, setJsonRHShift] = useState("")

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setDescription("")
      setWorkLoad("")
      setEntrance("")
      setEntranceTolerance("")
      setMeal("")
      setMealTolerance("")
      setOutput("")
      setOutputTolerance("")
      setSge("")
    }
    else {
      setDescription(jsonRHShift.DESCRIPTION)
      setWorkLoad(jsonRHShift.WORKLOAD)
      setEntrance(jsonRHShift.ENTRANCE)
      setEntranceTolerance(jsonRHShift.ENTRANCE_TOLERANCE)
      setMeal(jsonRHShift.MEAL)
      setMealTolerance(jsonRHShift.MEAL_TOLERANCE)
      setOutput(jsonRHShift.EXIT)
      setOutputTolerance(jsonRHShift.EXIT_TOLERANCE)

      if ((jsonRHShift.SGE !== null) && (jsonRHShift.SGE !== undefined)) setSge(jsonRHShift.SGE)      
    }

    document.getElementById("rhShiftDescription") && document.getElementById("rhShiftDescription").focus()
  }, [code, jsonRHShift, updateFocu]) 

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setDescription("")
      setWorkLoad("")
      setEntrance("")
      setEntranceTolerance("")
      setMeal("")
      setMealTolerance("")
      setOutput("")
      setOutputTolerance("")
      setSge("")
    }    
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonRHShift_New = {}

    jsonRHShift_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonRHShift_New += ", \"ID\": "+ code
    if (description !== "") jsonRHShift_New += ", \"DESCRIPTION\": \""+ description +"\""
    if (workLoad !== "") jsonRHShift_New += ", \"WORKLOAD\": \""+  workLoad +"\""
    if (entrance !== "") jsonRHShift_New += ", \"ENTRANCE\": \""+ entrance +"\""
    if (entranceTolerance !== "") jsonRHShift_New += ", \"ENTRANCE_TOLERANCE\": \""+ entranceTolerance +"\""
    if (meal !== "") jsonRHShift_New += ", \"MEAL\": \""+ meal +"\""
    if (mealTolerance !== "") jsonRHShift_New += ", \"MEAL_TOLERANCE\": \""+ mealTolerance +"\""
    if (output !== "") jsonRHShift_New += ", \"EXIT\": \""+ output +"\""
    if (outputTolerance !== "") jsonRHShift_New += ", \"EXIT_TOLERANCE\": \""+ outputTolerance +"\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHShift_New += ", \"SGE\": "+ sge
    jsonRHShift_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonRHShift_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "RH_SHIFT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o turno do RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhShiftDescription") && document.getElementById("rhShiftDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonRHShift(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonRHShift))
      formData.append("JSON_NEW_1", jsonRHShift_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "RH_SHIFT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o turno RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhShiftDescription") && document.getElementById("rhShiftDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonRHShift(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  } 

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonRHShift(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Turno RH"
            titleSearch="Pesquisa dos Turnos do RH"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchRHShift
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="rhShiftDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description || ""}
                                maxLength={80}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                  
                              <Input
                                type="text"
                                name="rhShiftCode"
                                description="Código"
                                planceHolder=""
                                value={code || ""}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                              <Input
                                type="text"
                                name="rhShiftDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description || ""}
                                maxLength={80}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={(showMenu) ?
                            (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          : 
                            (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <InputMasked
                              name="rhShiftWorkLoad"
                              description="Carga Horária"
                              planceHolder=""
                              mask="99:99"
                              value={workLoad || ""}
                              disabled={false}
                              require={true}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setWorkLoad(event.target.value)} />
                          </div>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            : 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={(showMenu) ?
                                (width < 900) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  
                              <InputMasked
                                name="rhShiftEntrance"
                                description="Entrada"
                                planceHolder=""
                                mask="99:99"
                                value={entrance || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setEntrance(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 710) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <InputMasked
                                name="rhShiftEntranceTolerance"
                                description="Tolerância de entrada"
                                planceHolder=""
                                mask="99:99"
                                value={entranceTolerance || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setEntranceTolerance(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div style={(showMenu) ?
                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :      
                            (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <InputMasked
                                name="rhShiftMeal"
                                description="Refeição"
                                planceHolder=""
                                mask="99:99"
                                value={meal || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setMeal(event.target.value)} />
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 710) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <InputMasked
                                name="rhShiftMealTolerance"
                                description="Tolerância da refeição"
                                planceHolder=""
                                mask="99:99"
                                value={mealTolerance || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setMealTolerance(event.target.value)} />
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 800) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <InputMasked
                                name="rhShiftOutput"
                                description="Saída"
                                planceHolder=""
                                mask="99:99"
                                value={output || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setOutput(event.target.value)} />
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 710) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              : 
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <InputMasked
                                name="rhShiftOutputTolerance"
                                description="Tolerância da saída"
                                planceHolder=""
                                mask="99:99"
                                value={outputTolerance || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setOutputTolerance(event.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default RhShift