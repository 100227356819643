import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const PriceMarkdown = ({jsonProductCompany, setJsonProductCompany, jsonCompany, save, back, showMenu, width}) => {

  const [code, setCode] = useState("")
  const [productCode, setProductCompanyProductCode] = useState("")
  const [productDescription, setProductCompanyProductDescription] = useState("")
  const [costPrice, setCostPrice] = useState("")
  const [salePrice, setSalePrice] = useState("")
  const [newSalePrice, setNewSalePrice] = useState("")
  const [sge, setSge] = useState("")

  useEffect(() => {   
    setCode(jsonProductCompany.ID)
    setProductCompanyProductCode(jsonProductCompany.PRODUCT.ID)
    setProductCompanyProductDescription(jsonProductCompany.PRODUCT.DESCRIPTION)
    setCostPrice(formatNumeric(jsonProductCompany.COST_PRICE, 2))
    setSalePrice(formatNumeric(jsonProductCompany.SALE_PRICE, 2))
    setNewSalePrice(formatNumeric(jsonProductCompany.SALE_PRICE, 2))

    if ((jsonProductCompany.SGE !== null) && (jsonProductCompany.SGE !== undefined)) setSge(jsonProductCompany.SGE)

    setJsonProductCompany(jsonProductCompany)
    document.getElementById("priceMarkdownNewSalePrice") && document.getElementById("priceMarkdownNewSalePrice").focus()

  }, [jsonProductCompany])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }
    
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }

  const callSave = () => {
    if (salePrice === newSalePrice){
        alert("O novo preço deve ser diferente do preço original.")
        return
    }

    const jCom = JSON.parse(jsonCompany)

    let json = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jCom.ID + ", \"INTEGRATE_SGE\": "+ jCom.INTEGRATE_SGE +
      ", \"DATABASE_SGE_PATH\": \""+ jCom.DATABASE_SGE_PATH +"\" }, \"COST_PRICE\": "+ costPrice + ", \"SALE_PRICE\": " + newSalePrice 
    
    if (sge !== "") json += ", \"SGE\": "+ sge
    json += " }"

    console.log(json)

    save(json)
  }  

  const handleSalePrice = (event) => {
    setSalePrice(formatNumeric(event.target.value, 2))
  }

  const handleNewSalePrice = (event) => {
    setNewSalePrice(formatNumeric(event.target.value, 2))
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        <div style={showMenu ?
            (width < 1410) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
          :
            (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

          <div style={showMenu ?
              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}
            :
              (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

            <div style={showMenu ?
                (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}
              :  
                (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
              <Input
                type="text"
                name="priceMarkdownProductCode"
                description="Código"
                value={productCode}
                maxLength={11}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setProductCompanyProductCode(event.target.value)} />
            </div>

            <div style={showMenu ?
                (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
              :
                (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
              <Input
                type="text"
                name="priceMarkdownProductDescription"
                description="Descrição"
                value={productDescription}
                maxLength={120}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setProductCompanyProductDescription(event.target.value)} />
            </div>
          </div>

          <div style={showMenu ?
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}
            : 
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

            <div style={showMenu ?
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1410) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}
              : 
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}}>
              <InputNumeric
                type="text"
                name="priceMarkdownSalePrice"
                description="Preço Original"
                value={salePrice || ""}
                maxLength={13}
                disabled={true}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handleSalePrice} />
            </div>

            <div style={showMenu ?
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1410) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}
              : 
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}}>
              <InputNumeric
                type="text"
                name="priceMarkdownNewSalePrice"
                description="Novo Preço"
                value={newSalePrice || ""}
                maxLength={13}
                disabled={false}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handleNewSalePrice} />
            </div>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default PriceMarkdown