import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const PriceTableItem = ({jsonPriceTableItem, setJsonPriceTableItem, jsonLogin, save, back, showMenu, width}) => {

  const [code, setCode] = useState("")
  const [priceTableCode, setPriceTableCode] = useState("")
  const [priceTableCompanyCode, setPriceTableCompanyCode] = useState("")
  const [productCompanyCode, setProductCompanyCode] = useState("")
  const [productCompanyProductCode, setProductCompanyProductCode] = useState("")
  const [productCompanyProductDescription, setProductCompanyProductDescription] = useState("")
  const [price, setPrice] = useState("")
  const [newPrice, setNewPrice] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {   
    setCode(jsonPriceTableItem.ID)
    setPriceTableCode(jsonPriceTableItem.PRICE_TABLE.ID)
    setPriceTableCompanyCode(jsonPriceTableItem.PRICE_TABLE.COMPANY.ID)
    setProductCompanyCode(jsonPriceTableItem.PRODUCT_COMPANY.ID)
    setProductCompanyProductCode(jsonPriceTableItem.PRODUCT_COMPANY.PRODUCT.ID)
    setProductCompanyProductDescription(jsonPriceTableItem.PRODUCT_COMPANY.PRODUCT.DESCRIPTION)
    setPrice(jsonPriceTableItem.PRICE)
    setNewPrice(jsonPriceTableItem.PRICE)
    setName(jsonPriceTableItem.NAME)

    setJsonPriceTableItem(jsonPriceTableItem)
    document.getElementById("priceTableItemNewPrice") && document.getElementById("priceTableItemNewPrice").focus()

  }, [jsonPriceTableItem])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0    
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }

  const callSave = () => {
    if (price === newPrice){
        alert("O novo preço deve ser diferente do preço original.")
        return
    }

    let json = "{ \"ID\": "+ code +", \"PRICE_TABLE\": { \"ID\": "+ priceTableCode + ", \"COMPANY\": { \"ID\": "+ priceTableCompanyCode + " } }"+
      ", \"PRODUCT_COMPANY\": { \"ID\": "+ productCompanyCode + " }, \"PRICE\": "+ newPrice + ", \"NAME\": \"" + jsonLogin.NAME + "\" }"

    save(json)
  }  

  const handlePrice = (event) => {
    setPrice(formatNumeric(event.target.value, 2))
  }

  const handleNewPrice = (event) => {
    setNewPrice(formatNumeric(event.target.value, 2))
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        <div style={showMenu ?
            (width < 1410) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
          :
            (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

          <div style={showMenu ?
              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}
            :
              (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

            <div style={showMenu ?
                (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}
              :  
                (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
              <Input
                type="text"
                name="priceTableItemProductCompanyProductCode"
                description="Código"
                value={productCompanyProductCode}
                maxLength={11}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setProductCompanyProductCode(event.target.value)} />
            </div>

            <div style={showMenu ?
                (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
              :
                (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
              <Input
                type="text"
                name="priceTableItemProductCompanyProductDescription"
                description="Descrição"
                value={productCompanyProductDescription}
                maxLength={120}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setProductCompanyProductDescription(event.target.value)} />
            </div>
          </div>

          <div style={showMenu ?
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 1410) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}
            : 
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

            <div style={showMenu ?
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1410) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}
              : 
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}}>
              <InputNumeric
                type="text"
                name="priceTableItemPrice"
                description="Preço Original"
                value={price || ""}
                maxLength={13}
                disabled={true}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handlePrice} />
            </div>

            <div style={showMenu ?
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1410) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}
              : 
                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, maxWidth: 180, minWidth: 180}}>
              <InputNumeric
                type="text"
                name="priceTableItemNewPrice"
                description="Novo Preço"
                value={newPrice || ""}
                maxLength={13}
                disabled={false}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handleNewPrice} />
            </div>
          </div>            
        </div>

        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
            <Input
              type="text"
              name="priceTableItemName"
              description="Responsável Alteração"
              value={name}
              maxLength={50}
              disabled={true}
              require={true}
              image={false}
              typeInput={2} 
              onSelect={setFieldSelected}
              onChange={(event) => setName(event.target.value)} />
          </div>
        </div>          
      </div> 
    </div>
  )
}

export default PriceTableItem