import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import SearchBranch from "../Branch/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const ClientBranch = ({databaseType, databasePath,  jsonClientBranch, jsonCompany, jsonLogin, system, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [branchCode, setBranchCode] = useState("")
  const [branchDescription, setBranchDescription] = useState("")
  const [clientCode, setClientCode] = useState("")

  const [showBranch, setShowBranch] = useState(false)

  useEffect(() => {
    if (((jsonClientBranch.ID !== undefined) && (jsonClientBranch.ID !== null)) && (jsonClientBranch.ID !== "")) setCode(jsonClientBranch.ID)

    setClientCode(jsonClientBranch.CLIENT.ID)

    if (((jsonClientBranch.BRANCH !== undefined) && (jsonClientBranch.BRANCH !== null)) && (jsonClientBranch.BRANCH !== "")){
      setBranchCode(jsonClientBranch.BRANCH.ID)
      setBranchDescription(jsonClientBranch.BRANCH.DESCRIPTION)
    }
    else{
      setBranchCode("")
      setBranchDescription("")
    }
  }, [jsonClientBranch])

  const callSave = () => {
    let json = "{"
    if ((((code !== undefined) && (code !== null)) && (code !== "")) && (code !== 0)) json += " \"ID\": "+ code + ", "
    json += " \"CLIENT\": { \"ID\": " + clientCode + " }"

    if (((branchCode !== undefined) && (branchCode !== null)) && (branchCode !== "")) 
      json += ", \"BRANCH\": { \"ID\": " + branchCode + ", \"DESCRIPTION\": \""+ branchDescription +"\" } "

    json += " }"
    save(json)
  }

  const cleanBranch = () => {
    setShowBranch(false)

    setBranchCode("")
    setBranchDescription("")
  }

  const selectBranch = (event) => {
    setShowBranch(false)    
    setBranchCode(event.ID)
    setBranchDescription(event.DESCRIPTION)
  }

  function setFieldSelected(){
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      { !showBranch ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={callSave}>

                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              { code === "" ? <b>Inclusão:</b> : <b>Alteração:</b>  }

              <div style={{marginTop: 10}}>
                <label style={{fontWeight: 600}}>Ramo:</label>
              </div>

              <div style={(showMenu) ?
                  (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 5}
                :
                  (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 5}}>

                <div style={(showMenu) ?
                    (width < 950) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                  :
                    (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                  <Input
                    type="text"
                    name="branchCode_clientBranch"
                    description="Código"
                    planceHolder=""
                    value={branchCode}
                    maxLength={11}
                    disabled={true}
                    require={true}
                    image={false}
                    typeImage={0}
                    typeInput={2} 
                    onSelect={setFieldSelected}
                    onChange={(event) => setBranchCode(event.target.value)} />
                </div>

                <div style={(showMenu) ?
                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                  :
                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                  <div style={(showMenu) ?
                      (width < 950) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>

                    <Input
                      type="text"
                      name="branchDescription_clientBranch"
                      description="Descrição"
                      planceHolder=""
                      value={branchDescription}
                      maxLength={60}
                      disabled={true}
                      require={true}
                      image={false}
                      typeImage={0}
                      typeInput={2} 
                      onSelect={setFieldSelected}
                      onChange={(event) => setBranchDescription(event.target.value)} />
                  </div>
                  <div style={(showMenu) ?
                      (width < 580) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10, marginRight: 10}
                      : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10, marginRight: 10}
                    :
                      (width < 580) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10, marginRight: 10}}>

                    <img
                      style={{height: 42, width: 42}}
                      name="searchBranch_clientBranch"
                      src={image_search}
                      alt="Pesquisar"
                      onClick={(() => setShowBranch(!showBranch))} />

                    <img 
                      style={{height: 42, width: 42, marginLeft: 10}}
                      src={image_clean}
                      alt="cleanBranch_clientBranch"
                      onClick={cleanBranch} />
                  </div>
                </div>
              </div>
            </div>
          </>
        :
          <>
            <b>Selecione o ramo:</b>

            <SearchBranch
              modeSearch={true}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonCompany={jsonCompany}
              jsonLogin={jsonLogin}
              system={system}
              setAdd={() => alert("")}
              setSelected={selectBranch}
              setReturn={() => setShowBranch(false)} 
              width={width} />
          </>
      }
    </div>
  )
}

export default ClientBranch