import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchCfop from "../Cfop/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const CstCsosnCfop = ({databaseType, databasePath, jsonCstCsosnCfop, setJsonCstCsosnCfop, jsonCompany, jsonLogin, system, save, back, showMenu,
  width}) => {

  const [code, setCode] = useState("")
  const [cstCsosnCode, setCstCsosnCode] = useState("")
  const [cstCsosnDescription, setCstCsosnDescription] = useState("")
  const [cfopCode, setCfopCode] = useState("")
  const [cfopDescription, setCfopDescription] = useState("")
  const [productType, setProductType] = useState(0)
  const [clientType, setClientType] = useState(0)
  const [primary, setPrimary] = useState(0)
  const [highlightSt, setHighlightSt] = useState(0)
  const [highlightIcms, setHighlightIcms] = useState(0)
  const [highlightDifal, setHighlightDifal] = useState(0)
  const [highlightIpi, setHighlightIpi] = useState(0)
  const [sge, setSge] = useState("")

  const [showCfop, setShowCfop] = useState(false)

  useEffect(() => {
    if (jsonCstCsosnCfop.ID !== undefined) setCode(jsonCstCsosnCfop.ID)

    setCstCsosnCode(jsonCstCsosnCfop.CST_CSOSN.CODE)
    setCstCsosnDescription(jsonCstCsosnCfop.CST_CSOSN.DESCRIPTION)

    if (jsonCstCsosnCfop.CFOP !== undefined){
      setCfopCode(jsonCstCsosnCfop.CFOP.ID)
      setCfopDescription(jsonCstCsosnCfop.CFOP.DESCRIPTION)
    }

    if (jsonCstCsosnCfop.PRODUCT_TYPE !== undefined) setProductType(jsonCstCsosnCfop.PRODUCT_TYPE)
    if (jsonCstCsosnCfop.CLIENT_TYPE !== undefined) setClientType(jsonCstCsosnCfop.CLIENT_TYPE)
    if (jsonCstCsosnCfop.PRIMARY !== undefined) setPrimary(jsonCstCsosnCfop.PRIMARY)
    if (jsonCstCsosnCfop.HIGHLIGHT_ST !== undefined) setHighlightSt(jsonCstCsosnCfop.HIGHLIGHT_ST)
    if (jsonCstCsosnCfop.HIGHLIGHT_ICMS !== undefined) setHighlightIcms(jsonCstCsosnCfop.HIGHLIGHT_ICMS)
    if (jsonCstCsosnCfop.HIGHLIGHT_DIFAL !== undefined) setHighlightDifal(jsonCstCsosnCfop.HIGHLIGHT_DIFAL)
    if (jsonCstCsosnCfop.HIGHLIGHT_IPI !== undefined) setHighlightIpi(jsonCstCsosnCfop.HIGHLIGHT_IPI)
    if (jsonCstCsosnCfop.SGE !== undefined) setSge(jsonCstCsosnCfop.SGE)

    setJsonCstCsosnCfop(jsonCstCsosnCfop)
  }, [jsonCstCsosnCfop])

  const callSave = () => {
    let json = "{ \"CST_CSOSN\": { \"CODE\": \"" + cstCsosnCode + "\", \"DESCRIPTION\": \""+ cstCsosnDescription +
      "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }"

    if (code !== "") json += ", \"ID\": " + code
    if (cfopCode !== "") json += ", \"CFOP\": { \"ID\": "+ cfopCode + ", \"DESCRIPTION\": \""+ cfopDescription +"\" }"

    json += ", \"PRODUCT_TYPE\": "+ productType
    json += ", \"CLIENT_TYPE\": "+ clientType
    json += ", \"PRIMARY\": "+ primary
    json += ", \"HIGHLIGHT_ST\": "+ highlightSt
    json += ", \"HIGHLIGHT_ICMS\": "+ highlightIcms
    json += ", \"HIGHLIGHT_DIFAL\": "+ highlightDifal
    json += ", \"HIGHLIGHT_IPI\": "+ highlightIpi
    if (sge !== "") json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const cleanCfop = () => {
    setCfopCode("")
    setCfopDescription("")
    setShowCfop(false)
  }

  const fillCfop = (cfop) => {
    setCfopCode(cfop.ID)
    setCfopDescription(cfop.DESCRIPTION)
    setShowCfop(false)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      { !showCfop ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              <div>
                <label style={{fontWeight: 600}}>{ (code === "") ? "Inclusão:" : "Alteração:" }</label>
              </div>

              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10} : {paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                  <Titulo description="CFOP" />
                </div>

                <div style={showMenu ?
                    (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 180}
                    : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}
                  : 
                    (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 180}
                    : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                  <div style={showMenu ?
                      (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    :
                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={showMenu ?
                        (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                      :
                        (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                      <Input
                        type="text"
                        name="cstCsosnCfopCfopCfopCode"
                        description="Código"
                        value={cfopCode}
                        maxLength={4}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setCfopCode(event.target.value)} />
                    </div>
                    <div style={showMenu ?
                        (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Input
                        type="text"
                        name="cstCsosnCfopCfopDescription"
                        description="Descrição"
                        value={cfopDescription}
                        maxLength={200}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setCfopDescription(event.target.value)} />
                    </div>
                  </div>

                  { (code === "") &&
                    <div style={showMenu ?
                        (width < 940) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                      :
                        (width < 640) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                      <img 
                        style={{height: 42, width: 42}}
                        name="cstCsosnCfopSearchCfop"
                        src={image_search} 
                        alt="Pesquisar" 
                        onClick={() => setShowCfop(!showCfop)} />

                      <img 
                        style={{marginLeft: 10, height: 42, width: 42}}
                        name="cstCsosnCfopCleanCfop"
                        src={image_clean} 
                        alt="Limpar" 
                        onClick={cleanCfop} />
                    </div>
                  }
                </div>
              </div>
            
              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
                  : (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                :
                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
                  : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                  <Select
                    name="cstCsosnCfopProductType"
                    description="Tipo Produto"
                    value={productType || 0}
                    disabled={(code === "") ? false : true}
                    require={true}
                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                      ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                      ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                      ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                      ", {\"ID\": 7, \"VALUE\": \"Materia\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                      ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                      ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                    image={false}
                    typeInput={2}
                    onSelect={setFieldSelected}
                    set={setProductType} />
                </div>

                <div style={showMenu ?
                    (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                  :
                    (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                  <div style={showMenu ?
                      (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 840) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <Select
                      name="cstCsosnCfopClientType"
                      description="Tipo Cliente"
                      value={clientType || 0}
                      disabled={(code === "") ? false : true}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Consumidor\" }, {\"ID\": 2, \"VALUE\": \"Revendedor\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setClientType} />
                  </div>
                  <div style={showMenu ?
                      (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 840) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <Select
                      name="cstCsosnCfopPrimary"
                      description="Primário"
                      value={primary || 0}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setPrimary} />
                  </div>
                </div>
              </div>

              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
                  : (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                :
                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0, marginTop: 10}
                  : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                <div style={showMenu ?
                    (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                    : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                  :
                    (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                  <div style={showMenu ?
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <Select
                      name="cstCsosnCfopHighlightSt"
                      description="Destaca ST"
                      value={highlightSt || 0}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setHighlightSt} />
                  </div>
                  <div style={showMenu ?
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <Select
                      name="cstCsosnCfopHighlightIcms"
                      description="Destaca ICMS"
                      value={highlightIcms || 0}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setHighlightIcms} />
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                  :
                    (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                  <div style={showMenu ?
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <Select
                      name="cstCsosnCfopHighlightDifal"
                      description="Destaca DIFAL"
                      value={highlightDifal || 0}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setHighlightDifal} />
                  </div>
                  <div style={showMenu ?
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <Select
                      name="highlightIpi"
                      description="Destaca IPI"
                      value={highlightIpi || 0}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setHighlightIpi} />
                  </div>
                </div>
              </div>
            </div>
          </>
        :
          <div name="CstCsosnCfop_PesquisaCfop" className="margem_cstCsosnCfop">
            <b>Selecione o CFOP:</b>
            <SearchCfop
              modeSearch={true}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonLogin={jsonLogin}
              jsonCompany={jsonCompany}
              system={system}
              setAdd={() => alert("")}
              setSelected={fillCfop}
              setReturn={() => setShowCfop(false)} 
              showMenu={showMenu}
              width={width} />
          </div>
      }
    </div>
  )
}

export default CstCsosnCfop