import React from "react";
import image_add from "../../Images/Buttons/add.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const CadastreButtons = ({save, add, returnAdd}) => {  
  return(
    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
        width: 147}} onClick={() => save()}>
        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
          paddingBottom: 5, paddingLeft: 10}}>
          <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
        </div>
        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
        width: 147}} onClick={() => add()}>
        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
          paddingBottom: 5, paddingLeft: 10}}>
          <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
        </div>
        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
        </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
        width: 147}} onClick={() => returnAdd()}>
        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
          paddingBottom: 5, paddingLeft: 10}}>
          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
        </div>
        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
        </div>
      </div>
    </div>
  )
}

export default CadastreButtons