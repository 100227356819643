import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchAccounting from "./Search"
import SearchAccountingPhone from "../AccountingPhone/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Accounting = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [crc, setCrc] = useState("")

  const [addressRelease, setAddressRelease] = useState(false)

  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [cityIBGE, setCityIBGE] = useState("")
  const [uf, setUf] = useState("")
  const [ufIBGE, setUfIBGE] = useState("")
  const [countryCode, setCountryCode] = useState(0)
  const [countryDescription, setCountryDescription] = useState("")
  const [countryIBGE, setCountryIBGE] = useState("")

  const [email, setEmail] = useState("")
  const [sge, setSge] = useState("")

  const [national, setNational] = useState(true)
  const [listCountry, setListCountry] = useState([])
  const [optionsCountries, setOptionsCountries] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [jAccounting, setJAccounting] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (firstAcess) {
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "COUNTRY/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os países. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          let country = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              country += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\"}"
            ))

          country += "]}"

          setListCountry(returnJson)
          setOptionsCountries(country)
          setCountryCode(34)

          setLoading("")
          setFirstAcess(false)
        }
      })
    }
    else{
      (countryDescription === "BRASIL") ? setNational(true) : setNational(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }

    if (code === "") {
      setName("")
      setCpf("")
      setCnpj("")
      setCrc("")
    
      setAddressRelease(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)
    
      setEmail("")
      setSge("")
    }
    else {
      setName(jAccounting.NAME)
      setCpf(jAccounting.CPF)

      if ((jAccounting.CNPJ !== undefined) && (jAccounting.CNPJ !== null)) setCnpj(jAccounting.CNPJ); else setCnpj("")

      setCrc(jAccounting.CRC)
      setCep(jAccounting.CEP)
      setAddress(jAccounting.ADDRESS)
      setNumber(jAccounting.NUMBER)

      if ((jAccounting.COMPLEMENT !== undefined) && (jAccounting.COMPLEMENT !== null)) setComplement(jAccounting.COMPLEMENT)

      setNeighborhood(jAccounting.NEIGHBORHOOD)
      setCity(jAccounting.CITY)
      setCityIBGE(jAccounting.CITY_IBGE)
      setUf(jAccounting.UF)
      setUfIBGE(jAccounting.UF_IBGE)

      if (jAccounting.COUNTRY_DESCRIPTION === "BRASIL") setNational(true); else setNational(false)

      let codeP = 0;
      listCountry.OBJECT.map(item => (
        (item.NAME === jAccounting.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
      ))

      setCountryCode(codeP)
      setCountryDescription(jAccounting.COUNTRY_DESCRIPTION)
      setCountryIBGE(jAccounting.COUNTRY_IBGE)      

      if ((jAccounting.EMAIL !== undefined) && (jAccounting.EMAIL !== null)) setEmail(jAccounting.EMAIL);
      if ((jAccounting.SGE !== undefined) && (jAccounting.SGE !== null)) setSge(jAccounting.SGE)
    }

    document.getElementById("accountingCpf") && document.getElementById("accountingCpf").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, code, jAccounting, countryDescription, listCountry.OBJECT,
    updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setName("")
      setCpf("")
      setCnpj("")
      setCrc("")
        
      setAddressRelease(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)
        
      setEmail("")
      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }    

  const clearAddress = () => {
    setCep("")
    setAddress("")
    setNumber("")
    setComplement("")
    setNeighborhood("")
    setCity("")
    setCityIBGE("")
    setUf("")
    setUfIBGE("")

    setAddressRelease(false)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonAccounting_New = {}

    jsonAccounting_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonAccounting_New += ", \"ID\": "+ code
    if (((name !== undefined) && (name !== null)) && (name !== "")) jsonAccounting_New += ", \"NAME\": \""+ name + "\""
    if (((cpf !== undefined) && (cpf !== null)) && (cpf !== "")) jsonAccounting_New += ", \"CPF\": \""+ cpf + "\""
    if (((cnpj !== undefined) && (cnpj !== null)) && (cnpj !== "")) jsonAccounting_New += ", \"CNPJ\": \""+ cnpj + "\""
    if (((crc !== undefined) && (crc !== null)) && (crc !== "")) jsonAccounting_New += ", \"CRC\": \""+ crc + "\""
    if (((cep !== undefined) && (cep !== null)) && (cep !== "")) jsonAccounting_New += ", \"CEP\": "+ cep

    jsonAccounting_New += ", \"ADDRESS\": \""+ address +"\""
    jsonAccounting_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== "")) 
      jsonAccounting_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonAccounting_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonAccounting_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIBGE !== undefined) && (cityIBGE !== null)) && (cityIBGE !== ""))
        jsonAccounting_New += ", \"CITY_IBGE\": "+ cityIBGE
    }
    else jsonAccounting_New += ", \"CITY_IBGE\": "+ 9999999
        
    jsonAccounting_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIBGE !== undefined) && (ufIBGE !== null)) && (ufIBGE !== "")) jsonAccounting_New += ", \"UF_IBGE\": "+ ufIBGE
    }        
    else jsonAccounting_New += ", \"UF_IBGE\": "+ 99

    jsonAccounting_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonAccounting_New += ", \"COUNTRY_IBGE\": "+ countryIBGE
    else jsonAccounting_New += ", \"COUNTRY_IBGE\": "+ 9999

    if (((email !== undefined) && (email !== null)) && (email !== "")) jsonAccounting_New += ", \"EMAIL\": \""+ email + "\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonAccounting_New += ", \"SGE\": "+ sge

    jsonAccounting_New += " }"

    console.log(jsonAccounting_New)

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonAccounting_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "ACCOUNTING/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o contabilista. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountingCpf") && document.getElementById("accountingCpf").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJAccounting(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jAccounting))
      formData.append("JSON_NEW_1", jsonAccounting_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "ACCOUNTING/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o contabilista. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountingCpf") && document.getElementById("accountingCpf").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")        

        setJAccounting(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const searchCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setWarning("")
      setError("É necessário informar o CEP.")

      return
    }

    setLoading("Aguarde buscando o CEP...")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {
      if (response.data.erro !== "true") {
        setWarning("")
        setError("")

        if ((response.data.logradouro === '') && (response.data.bairro === '')) setAddressRelease(true)
        else setAddressRelease(false)

        setAddress(response.data.logradouro)
        setNeighborhood(response.data.bairro)
        setCity(response.data.localidade)
        setCityIBGE(response.data.ibge)
        setUf(response.data.uf)

        if (response.data.uf === "RO") setUfIBGE(11)
        if (response.data.uf === "AC") setUfIBGE(12)
        if (response.data.uf === "AM") setUfIBGE(13)
        if (response.data.uf === "RR") setUfIBGE(14)
        if (response.data.uf === "PA") setUfIBGE(15)
        if (response.data.uf === "AP") setUfIBGE(16)
        if (response.data.uf === "TO") setUfIBGE(17)
        if (response.data.uf === "MA") setUfIBGE(21)
        if (response.data.uf === "PI") setUfIBGE(22)
        if (response.data.uf === "CE") setUfIBGE(23)
        if (response.data.uf === "RN") setUfIBGE(24)
        if (response.data.uf === "PB") setUfIBGE(25)
        if (response.data.uf === "PE") setUfIBGE(26)
        if (response.data.uf === "AL") setUfIBGE(27)
        if (response.data.uf === "SE") setUfIBGE(28)
        if (response.data.uf === "BA") setUfIBGE(29)
        if (response.data.uf === "MG") setUfIBGE(31)
        if (response.data.uf === "ES") setUfIBGE(32)
        if (response.data.uf === "RJ") setUfIBGE(33)
        if (response.data.uf === "SP") setUfIBGE(35)
        if (response.data.uf === "PR") setUfIBGE(41)
        if (response.data.uf === "SC") setUfIBGE(42)
        if (response.data.uf === "RS") setUfIBGE(43)
        if (response.data.uf === "MS") setUfIBGE(50)
        if (response.data.uf === "MT") setUfIBGE(51)
        if (response.data.uf === "GO") setUfIBGE(52)
        if (response.data.uf === "DF") setUfIBGE(53)
      } 
    else
    {
      setWarning("")
      setError("CEP não encontrado.")
      alert("CEP não encontrado.")

      setAddress("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }
    })
    .catch(() => {
      setWarning("")
      setError("Não foi possível obter CEP.")
      alert("Não foi possível obter CEP.")
    }) 
    .finally(() => { setLoading("") })
  }        
        
  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJAccounting(event)
    setCode(event.ID)
  }

  const selectCountry = (event) => {
    let itemP = {}
    listCountry.OBJECT.map(item => (
      (item.ID ===  parseInt(event)) ? itemP = item : ""
    ))

    setCountryCode(itemP.ID)
    setCountryDescription(itemP.NAME)
    setCountryIBGE(itemP.IBGE)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Contabilista"
            titleSearch="Pesquisa dos Contabilistas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchAccounting
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingCpf"
                                description="CPF"
                                value={cpf}
                                maxLength={11}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCpf(event.target.value)} />
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingCnpj"
                                description="CNPJ"
                                value={cnpj}
                                maxLength={14}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCnpj(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={showMenu ?
                              (width < 890) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                            :
                              (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                              <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="accountingCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="accountingCpf"
                                  description="CPF"
                                  value={cpf}
                                  maxLength={11}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCpf(event.target.value)} />
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingCnpj"
                                description="CNPJ"
                                value={cnpj}
                                maxLength={14}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCnpj(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={showMenu ?
                            (width < 880) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                          :
                            (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="accountingName"
                              description="Nome"
                              value={name}
                              maxLength={60}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setName(event.target.value)} />
                          </div>
                          <div style={showMenu ?
                              (width < 880) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                            : 
                              (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                            <Input
                              type="text"
                              name="accountingCrc"
                              description="CRC"
                              value={crc}
                              maxLength={15}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setCrc(event.target.value)} />
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                          <Titulo description="Endereço" />
                        </div>

                        <div style={(showMenu) ?
                            (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                            : {paddingLeft: 10, paddingRight: 10}
                          : 
                            (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                            : {paddingLeft: 10, paddingRight: 10}}>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ?
                                (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={{display: "flex", flexGrow: 1}}>
                                <Select
                                  name="accountingCountryCode"
                                  description="País"
                                  value={countryCode || 0}
                                  disabled={false}
                                  require={false}
                                  options={JSON.parse(optionsCountries)}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={selectCountry} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                  : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                :   
                                  (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                <Input
                                  type="text"
                                  name="accountingCep"
                                  description="CEP"
                                  value={cep}
                                  maxLength={8}
                                  disabled={false}
                                  require={national ? true : false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCep(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                              : 
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                              { (national && (!addressRelease)) &&
                                <img
                                  style={{height: 42, width: 42, marginLeft: 10}}
                                  name="accountingSearchAddress"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={() => searchCEP()} />
                              }

                              <img
                                style={{height: 42, width: 42, marginLeft: 10}}
                                name="accountingClearAddress"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={clearAddress} />
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingAddress"
                                description="Logradouro"
                                value={address}
                                maxLength={60}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected} 
                                onChange={(event) => setAddress(event.target.value)} />
                            </div>

                            <div style={(showMenu) ?
                                (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                              :
                                (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                              <div style={(showMenu) ?
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                :
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                <Input
                                  type="text"
                                  name="accountingNumber"
                                  description="Número"
                                  value={number}
                                  maxLength={60}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setNumber(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                :
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                <Input
                                  type="text"
                                  name="accountingComplement"
                                  description="Complemento"
                                  value={complement}
                                  maxLength={60}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setComplement(event.target.value)} />
                              </div>
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingNeighborhood"
                                description="Bairro"
                                value={neighborhood}
                                maxLength={60}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setNeighborhood(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Input
                                type="text"
                                name="accountingCity"
                                description="Cidade"
                                value={city}
                                maxLength={60}
                                disabled={national ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setCity(event.target.value)} />
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingUf"
                                description="Estado"
                                value={uf}
                                maxLength={2}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setUf(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  
                              <Input
                                type="text"
                                name="accountingCountryDescription"
                                description="País"
                                value={countryDescription}
                                maxLength={60}
                                disabled={true}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setCountryDescription(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 10}}>
                          <Titulo description="Outras informações" />
                        </div>

                        <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="accountingEmail"
                                description="Email"
                                value={email}
                                maxLength={70}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setEmail(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        {/* Phones */}
                        { code &&
                          <div>
                            <div style={{marginTop: 10}}>
                              <Titulo description="Telefones" />
                            </div>

                            <SearchAccountingPhone 
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonAccounting={jAccounting}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system} 
                              width={width} />
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Accounting