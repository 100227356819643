import "./style.css"
import React, { useEffect, useState } from "react"
import img_boneco from "../../Images/usuario.png"
import img_cadeado from "../../Images/cadeado.png"
import img_sem_foto from "../../Images/sem_foto.png"
import img_no_picture from "../../Images/no_picture.png"

const Input = ({type, name, description, planceHolder, value, maxLength, disabled, require, accept, pathPhoto, image, typeImage, typeInput,
  onSelect, onChange}) => {

  const [obrigatorio, setObrigatorio] = useState("")
  const [colorFocu, setColorFocu] = useState( (typeInput === 1) ? "corpoUnFocuLogin_input" : "corpoUnFocuTela_input" )
  const descricaoInput = (typeInput === 1) ? "descricaoLogin_input" : "descricaoTela_input"

  const hiddenFileInput = React.useRef(null)

  useEffect(() => {
    if (String(value) !== "") setObrigatorio("naoObrigatorio_input")
    else
    {        
      if (require){
        if (String(value) !== "") setObrigatorio("naoObrigatorio_input")
        else setObrigatorio("obrigatorio_input")
      } else setObrigatorio("naoObrigatorio_input")
    }
  }, [require, value])

  const handleClickFileInput = () => {
    hiddenFileInput.current.click();
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      if (require) setObrigatorio("obrigatorio_input"); else setObrigatorio("naoObrigatorio_input")
    } else setObrigatorio("naoObrigatorio_input")

    if (typeInput === 1) setColorFocu("corpoUnFocuLogin_input")
    else if (typeInput === 2) setColorFocu("corpoUnFocuTela_input")    
  }

  const handleFocu = () => {
    if (typeInput === 1) setColorFocu("corpoFocuLogin_input")
    else if (typeInput === 2) setColorFocu("corpoFocuTela_input")

    console.log(name)

    onSelect(name)
  }

  return(
    <div style={{ width: "100%" }}>
          
      {/* Não for File */}
      { type !== "file" &&
        <div id="input">
                
          { description &&
            <div id={descricaoInput}>
              <label>{description}</label>
            </div>
          }

          <div id={colorFocu}>

            { image &&
              <>                
                { (typeImage === 1) && 
                  <div className="corpoImagem_input">
                    <img src={img_boneco} alt="boneco" style={{ width: 23, height: 30 }} /> 
                  </div>
                }

                { (typeImage === 2) && 
                  <div className="corpoImagem_input">
                    <img src={img_cadeado} alt="cadeado" style={{ width: 20, height: 30 }} /> 
                  </div>
                }         

                <div id="corpoCampoImagem_input">
                  <input
                    type={type}
                    id={name}
                    name={name}   
                    className={obrigatorio}
                    placeholder={planceHolder}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocu}
                    onChange={onChange} />
                </div>
              </>
            }

            { !image &&
              <>
                <div id="corpoCampoSemImagem_input">
                  <input
                    type={type}
                    id={name}
                    name={name}
                    className={obrigatorio}
                    placeholder={planceHolder}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={handleFocu}
                    onChange={onChange} />
                </div>
              </>
            }

          </div>
        </div>
      }

      {/* File */}
      { type === "file" &&
        <div className="espacoInput_input">
          <input
            type={type}
            id={name}
            ref={hiddenFileInput}
            name={name}          
            className={(typeImage !== 2) ? "file_Input" : ""} 
            value={value}
            maxLength={maxLength}
            accept={accept}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocu}
            onChange={onChange} />

          <div id="login_Input">
            <div>
                { (typeImage === 1) && 
                  <>
                    { ((pathPhoto ===  null) || (pathPhoto ===  ""))  && <img src={img_sem_foto} className="foto_Input" alt="Sem foto" onClick={handleClickFileInput} /> }
                    { ((pathPhoto !== null) && (pathPhoto !== "")) && <img src={pathPhoto} className="foto_Input" alt="Foto" onClick={handleClickFileInput} /> }
                  </>
                }

                { (typeImage === 3) && 
                  <>
                    { ((pathPhoto ===  null) || (pathPhoto ===  ""))  && <img src={img_no_picture} className="image_Input" alt="Sem foto" onClick={handleClickFileInput} /> }
                    { ((pathPhoto !== null) && (pathPhoto !== "")) && <img src={pathPhoto} className="image_Input" alt="Foto" onClick={handleClickFileInput} /> }
                  </>
                }
            </div>
          </div>            
        </div>
      }

    </div>
  )
}

export default Input