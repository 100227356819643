import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchTypeTaxOperation from "./Search"
import SearchTypeTaxOperationCfop from "../TypeTaxOperationCfop/Search"
import image_loading from "../../Images/spinner.gif"

const TypeTaxOperation = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [number, setNumber] = useState("")
  const [description, setDescription] = useState("")
  const [descriptionNf, setDescriptionNf] = useState("")
  const [standand, setStandand] = useState(0)
  const [origin, setOrigin] = useState(0)
  const [sge, setSge] = useState("")

  const [jTypeTaxOperation, setJTypeTaxOperation] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setNumber("")
      setDescription("")
      setDescriptionNf("")
      setOrigin(0)
      setStandand(0)
      setSge("")
    }
    else {
      setNumber(jTypeTaxOperation.NUMBER)
      setDescription(jTypeTaxOperation.DESCRIPTION)
      setDescriptionNf(jTypeTaxOperation.DESCRIPTION_NF)
      setStandand(jTypeTaxOperation.DEFAULT)
      setOrigin(jTypeTaxOperation.ORIGIN)

      if ((jTypeTaxOperation.SGE !== null) && (jTypeTaxOperation.SGE !== undefined)) setSge(jTypeTaxOperation.SGE)
    }

    document.getElementById("typeTaxOperationNumber") && document.getElementById("typeTaxOperationNumber").focus()

  }, [code, jTypeTaxOperation, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setNumber("")
      setDescription("")
      setDescriptionNf("")
      setOrigin(0)
      setStandand(0)
      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
        
    setWarning("")
    setError("")

    let jsonTypeTaxOperation_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonTypeTaxOperation_New += ", \"ID\": "+ code
    if (number !== "") jsonTypeTaxOperation_New += ", \"NUMBER\": \""+ number + "\""
    if (description !== "") jsonTypeTaxOperation_New += ", \"DESCRIPTION\": \""+ description + "\""
    if (descriptionNf !== "") jsonTypeTaxOperation_New += ", \"DESCRIPTION_NF\": \""+ descriptionNf + "\""
    if (origin !== 0) jsonTypeTaxOperation_New += ", \"ORIGIN\": \""+ origin + "\""
    if (standand !== 0) jsonTypeTaxOperation_New += ", \"DEFAULT\": \""+ standand + "\""
    if (sge !== "") jsonTypeTaxOperation_New += ", \"SGE\": \""+ sge + "\""

    jsonTypeTaxOperation_New += " }";

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonTypeTaxOperation_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "TYPE_TAX_OPERATION/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o tipo de operação fiscal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("typeTaxOperationDescription") && document.getElementById("typeTaxOperationDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJTypeTaxOperation(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jTypeTaxOperation))
      formData.append("JSON_NEW_1", jsonTypeTaxOperation_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "TYPE_TAX_OPERATION/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o tipo de operação fiscal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("cstCsosnDescription") && document.getElementById("cstCsosnDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJTypeTaxOperation(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }  

  const setSelected = (event) => {
    setShowSearch(false)
    setJTypeTaxOperation(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Tipo Operação Fiscal"
            titleSearch="Pesquisa dos Tipos de Operações Fiscais"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchTypeTaxOperation
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                            <div style={(width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                              <Input
                                  type="text"
                                  name="typeTaxOperationNumber"
                                  description="Número"
                                  value={number}
                                  maxLength={12}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setNumber(event.target.value)} />
                            </div>
                            <div style={(width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10,
                                minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                              <Input
                                  type="text"
                                  name="typeTaxOperationDescription"
                                  description="Descrição"
                                  value={description}
                                  maxLength={50}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={showMenu ?
                              (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0,  minWidth: 150, maxWidth: 150}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0,  minWidth: 150, maxWidth: 150}}>
                                <Input
                                  type="text"
                                  name="typeTaxOperationCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                : 
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 780) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                <Input
                                    type="text"
                                    name="typeTaxOperationNumber"
                                    description="Número"
                                    value={number}
                                    maxLength={12}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setNumber(event.target.value)} />
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : 
                                (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                              <Input
                                  type="text"
                                  name="typeTaxOperationDescription"
                                  description="Descrição"
                                  value={description}
                                  maxLength={50}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="typeTaxOperationDescriptionNf"
                              description="Descrição NF"
                              value={descriptionNf}
                              maxLength={25}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescriptionNf(event.target.value)} />
                          </div>
                          
                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 780) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}                              
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                              <Select
                                name="typeTaxOperationOrigin"
                                description="Tipo"
                                value={origin || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                  ", {\"ID\": 1, \"VALUE\": \"Entrada\" }, {\"ID\": 2, \"VALUE\": \"Saída\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setOrigin} />
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10,
                                  minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 780) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10,
                                  minWidth: 180}                              
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                              <Select
                                name="typeTaxOperationDefault"
                                description="Padrão"
                                value={standand || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                  ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setStandand} />
                            </div>
                          </div>
                        </div>

                        { code &&
                          <div style={{marginTop: 10}}>
                            <Titulo description="CFOP / CST CSOSN"/>

                            <SearchTypeTaxOperationCfop
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonTypeTaxOperation={jTypeTaxOperation}
                              jsonLogin={jsonLogin}
                              jsonCompany={jsonCompany}
                              system={system}
                              showMenu={showMenu}
                              width={width} />
                          </div>
                        }
                  </div>
                </>
              }

            </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default TypeTaxOperation