import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import SearchAliquot from "./Search"
import image_loading from "../../Images/spinner.gif"

const Aliquot = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [acronym, setAcronym] = useState("")
  const [value, setValue] = useState(0)

  const [jAliquot, setJAliquot] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setAcronym("")
      setValue(0)
    }
    else {
      setAcronym(jAliquot.ACRONYM)
      setValue(jAliquot.VALUE)
    }

    document.getElementById("aliquotAcronym") && document.getElementById("aliquotAcronym").focus()

  }, [code, jAliquot, updateFocu])    

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setAcronym("")
      setValue("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
        
    setWarning("")
    setError("")

    let jsonAliquot_New = "{ "
    if (code !== "") jsonAliquot_New += "\"ID\": "+ code

    if (jsonAliquot_New !== "{ ") jsonAliquot_New += ", "
    if (acronym !== "") jsonAliquot_New += "\"ACRONYM\": \""+ acronym +"\""
    if (jsonAliquot_New !== "{ ") jsonAliquot_New += ", "
    if (value !== "") jsonAliquot_New += "\"VALUE\": "+ value

    jsonAliquot_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonAliquot_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "ALIQUOT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a alíquota. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("aliquotAcronym") && document.getElementById("aliquotAcronym").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJAliquot(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jAliquot))
      formData.append("JSON_NEW_1", jsonAliquot_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "ALIQUOT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a aliquota. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("aliquotAcronym") && document.getElementById("aliquotAcronym").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJAliquot(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJAliquot(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Alíquota"
            titleSearch="Pesquisa das Alíquotas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchAliquot
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="aliquotAcronym"
                                description="Sigla"
                                value={acronym}
                                maxLength={4}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setAcronym(event.target.value)} />
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <InputNumeric
                                type="text"
                                name="aliquotValue"
                                description="Valor"
                                value={value || ""}
                                maxLength={13}
                                disabled={false}
                                require={true}
                                negative={false} 
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={handleValue} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={showMenu ?
                              (width < 890) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                            :
                              (width < 590) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                            <div style={(showMenu) ?
                                (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                              :
                                (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                              <Input
                                type="text"
                                name="aliquotCode"
                                description="Código"
                                value={code}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 590) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                              <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="aliquotAcronym"
                                  description="Sigla"
                                  value={acronym}
                                  maxLength={4}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setAcronym(event.target.value)} />
                              </div>
                              <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                <InputNumeric
                                  type="text"
                                  name="aliquotValue"
                                  description="Valor"
                                  value={value || ""}
                                  maxLength={13}
                                  disabled={false}
                                  require={true}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handleValue} />
                              </div>
                            </div>
                          </div>
                        }

                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Aliquot