import React, { useState, useEffect } from "react"
import DataPicker from "../../Components/DataPicker"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import image_save from "../../Images/Buttons/save.png"
import image_previous from "../../Images/Buttons/previous.png"

const RegisterTableReservation = ({jsonLogin, jsonRegisterTable, save, back, showMenu, width}) => {
  const [clientName, setClientName] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState("00:00")
  const [amountPeople, setAmountPeople] = useState("")

  const [error, setError] = useState("")

  useEffect(() => {
    document.getElementById("registerTableReservationClientName") && document.getElementById("registerTableReservationClientName").focus()
  }, [])

  const callSave = () => {
    const tim = time.replaceAll("_","0").replaceAll(" ","0")
    const aTim = time.split(":")

    if (tim == "00:00")
    {
      setError("É necessário informar a Hora!")
      document.getElementById("registerTableReservationTime") && document.getElementById("registerTableReservationTime").focus()
      return
    }

    if (parseInt(aTim[0]) > 23)
    {
      setError("Hora inválida!")
      document.getElementById("registerTableReservationTime") && document.getElementById("registerTableReservationTime").focus()
      return
    }
    
    if (parseInt(aTim[1]) > 59)
    {
      setError("Minutos inválidos!")
      document.getElementById("registerTableReservationTime") && document.getElementById("registerTableReservationTime").focus()
      return
    }

    let da = ""
    if (date == "")
    {
      setError("É necessário informar a Data!")
      document.getElementById("registerTableReservationDate") && document.getElementById("registerTableReservationDate").focus()
      return
    }
    else
    {
      if (validateDate(date))
      {
        const dat = date.toLocaleString()
        da = String(dat).substr(6,4) + "-" + String(dat).substr(3,2) + "-" + String(dat).substr(0,2) + "T" + tim + ":00";
      }
      else
      {
        setError("Data inválida!")
        document.getElementById("registerTableReservationDate") && document.getElementById("registerTableReservationDate").focus()
        return
      }
    }

    let amountPeopl = amountPeople.replaceAll("_"," ")
    if (amountPeople == "")
    {
      setError("É necessário informar a Quantidade de Pessoas!")
      document.getElementById("registerTableReservationAmountPeople") && document.getElementById("registerTableReservationAmountPeople").focus()
      return
    }
    else
    {
      if ((parseInt(amountPeopl) == 0) || (parseInt(amountPeopl) > 99))
      {
        setError("Quantidade de Pessoas inválida!")
        document.getElementById("registerTableReservationAmountPeople") && document.getElementById("registerTableReservationAmountPeople").focus()
        return
      }
    }

    let json = "{ \"REGISTER_TABLE\": { \"NUMBER\": "+ jsonRegisterTable.NUMBER +
      ", \"COMPANY\": { \"ID\": \""+ jsonRegisterTable.COMPANY.ID +"\" } }"

    if ((clientName !== undefined) && (clientName !== "")) json += ", \"CLIENT\": \""+ clientName +"\""

    if ((clientPhone !== undefined) && (clientPhone !== "")) 
      json += ", \"CLIENT_PHONE\": \""+ clientPhone.replaceAll("(","").replaceAll(")","").replaceAll("_","") +"\""
    
    json += ", \"EMPLOYEE_RELEASE\": { \"NAME\": \""+ jsonLogin.NAME +"\" }"
    json += ", \"DATE\": \""+ da +"\""
    json += ", \"AMOUNT_PEOPLE\": "+ amountPeopl
    json += ", \"IMPORTED\": "+ 2
    json += ", \"SITUATION\": "+ 1 + " }"
    save(json)
  }  

  const setFieldSelected = () => {
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0] > 28) && ((ardt[2]%4) !== 0))
        error = true;
      if ((ardt[0] > 29) && ((ardt[2]%4) === 0))
        error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
          marginLeft: 5, width: 147}} onClick={back}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
        paddingLeft: 10}}>

        { error &&
          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
            marginBottom: 10}}>
            <label style={{fontWeight: 600, color: "red"}}>{error}</label>
          </div>
        }
      </div>

      <div style={showMenu ?
          (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, paddingLeft: 20, paddingRight: 20}
          : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, paddingLeft: 20, paddingRight: 20}
        :
          (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, paddingLeft: 20, paddingRight: 20}
          : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, paddingLeft: 20, paddingRight: 20}}>
        <div style={showMenu ?
            (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
            : {display: "flex", flexGrow: 1, minWidth: 180}
          :
            (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
            : {display: "flex", flexGrow: 1, minWidth: 510}}>
          <Input
            type="text"
            name="registerTableReservationClientName"
            description="Cliente"
            value={clientName}
            maxLength={60}
            disabled={false}
            require={true}
            image={false}
            typeInput={2} 
            onSelect={setFieldSelected}
            onChange={(event) => setClientName(event.target.value)} />
        </div>
        <div style={showMenu ?
            (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
          :
            (width < 810) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
          <InputMasked
            type="text"
            name="registerTableReservationClientName"
            description="Telefone"
            mask="(999)999999999"
            value={clientPhone}
            disabled={false}
            require={true}
            image={false}
            typeInput={2}
            onSelect={setFieldSelected}
            onChange={(event) => setClientPhone(event.target.value)} />
        </div>
      </div>

      <div style={showMenu ?
          (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10, marginTop: 10, paddingLeft: 20, 
            paddingRight: 20}
          : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, marginTop: 10, paddingLeft: 20, paddingRight: 20}
        :
          (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10, marginTop: 10, paddingLeft: 20, 
            paddingRight: 20}
          : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, marginTop: 10, paddingLeft: 20, paddingRight: 20}}>

        <div style={showMenu ?
            (width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
            : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
            : {display: "flex", flexDirection: "row", flexGrow: 0}
          :
            (width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
            : (width < 490) ? {display: "flex", flexDirection: "row", flexGrow: 1}
            : {display: "flex", flexDirection: "row", flexGrow: 0}}>
          <div style={showMenu ?
              (width < 360) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 150}
              : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
            : 
              (width < 360) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 150}
              : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
            <DataPicker
              name="registerTableReservationDate"
              description="Data"
              value={date}
              require={true}
              onSelect={setFieldSelected}
              onChange={(event) => setDate(event)} />
          </div>
          <div style={(width < 360) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
            <InputMasked
              type="text"
              name="registerTableReservationTime"
              mask="99:99"
              description="Hora"
              value={time}
              disabled={false}
              require={false}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setTime(event.target.value)} />
          </div>
        </div>

        <div style={showMenu ?
            (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 90}
            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}
          :
            (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 90}
            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 90}}>
          <InputMasked
            type="text"
            name="registerTableReservationAmountPeople"
            mask="99"
            description="Pessoas"
            value={amountPeople}
            disabled={false}
            require={false}
            image={false}
            typeInput={2}
            onSelect={setFieldSelected}
            onChange={(event) => setAmountPeople(event.target.value)} />
        </div>
      </div>
    </div>
  )
}

export default RegisterTableReservation