import React, { useState } from "react"
import LauchRestaurantType from "../LauchRestaurantType";
import LauchRestaurantNumber from "../LauchRestaurantNumber";
import SaleMain from "../SaleMain";

const LauchRestaurant = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width}) => {  
  const [typeLaunch, setTypeLaunch] = useState(0)
  const [number, setNumber] = useState("")
    
  const [showTypeLauch, setShowTypeLauch] = useState(true)
  const [showReadNumber, setShowReadNumber] = useState(false)
  const [showLauch, setShowLauch] = useState(false)

  const selectReadNumber = (number) => {
    setNumber(number)
    setShowReadNumber(false)
    setShowLauch(true)
  }

  const returnReadNumber = () => {
    setTypeLaunch(0)
    setNumber("")
    setShowReadNumber(false)
    setShowTypeLauch(true)
  }

  const selectTypeLaunch = (value) => {
    setTypeLaunch(value)
    setShowTypeLauch(false)
    setShowReadNumber(true)
  }

  const returnTypeLaunch = () => {
    setReturn()
    return
  }

  const returnSaleMain = () => {
    setTypeLaunch(0)
    setNumber("")
    setShowLauch(false)    
    setShowTypeLauch(true)   
  }

  return(
    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minHeight: height}}>

      { showTypeLauch &&
        <LauchRestaurantType 
          setTypeLaunch={selectTypeLaunch}
          setReturn={returnTypeLaunch}
          height={height}
          width={width}          
        />
      }

      { showReadNumber &&
        <LauchRestaurantNumber
          databaseType={databaseType}
          databasePath={databasePath}
          jsonCompany={jsonCompany}
          jsonLogin={jsonLogin}
          system={system}
          typeLaunch={typeLaunch}
          setSelect={selectReadNumber}
          setReturn={returnReadNumber}
          height={height}
          width={width}
        />
      }

      { showLauch &&
        <SaleMain 
          databaseType={databaseType}
          databasePath={databasePath}
          jsonCompany={jsonCompany}
          jsonLogin={jsonLogin}
          system={system}
          typeSale={typeLaunch}
          number={number}
          setReturn={returnSaleMain}
          height={height}
          width={width}
        />
      }

    </div>
  )
}

export default LauchRestaurant