import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import SearchCountry from "./Search"
import image_add from "../../Images/Buttons/add.png"
import image_loading from "../../Images/spinner.gif"
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const Country = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [IBGE, setIBGE] = useState("")

  const [jsonCountry, setJsonCountry] = useState({})
  
  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === ""){
      setName("")
      setIBGE("")
    }
    else {
      setName(jsonCountry.NAME)
      setIBGE(jsonCountry.IBGE)
    }

    document.getElementById("countryName") && document.getElementById("countryName").focus()
  }, [code, jsonCountry, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setName("")
      setIBGE("")
    }
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonCountry_New = {}

    setWarning("")
    setError("")    

    jsonCountry_New = "{ "
    if (code !== "") jsonCountry_New += "\"ID\": "+ code
    
    if (name !== "") {
      if (jsonCountry_New !== "{ ") jsonCountry_New += ", "
      jsonCountry_New += "\"NAME\": \""+ name +"\""
    }
        
    if (IBGE !== "") {
      if (jsonCountry_New !== "{ ") jsonCountry_New += ", "
      jsonCountry_New += "\"IBGE\": "+ String(IBGE).replaceAll("_","")
    }
  
    jsonCountry_New += " }"

    if (code === "")
    {
      setLoading("Aguarde, incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonCountry_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)
  
      axios.post(BASE_URL + "COUNTRY/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o país. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("countryName") && document.getElementById("countryName").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonCountry(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde, alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonCountry))
      formData.append("JSON_NEW_1", jsonCountry_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "COUNTRY/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o país. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("countryName") && document.getElementById("countryName").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonCountry(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonCountry(event)
    setCode(event.ID)
  }   

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          {/* Begin - Header */}
          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", paddingTop: 15, paddingBottom: 15}}>
            { (width < 470) ?
                <>
                  <div style={(showMenu) ?
                      {display: "flex", flexGrow: 1, flexDirection: "row"}
                    :
                      (width < 260) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                    { !showMenu &&
                      <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", paddingLeft: 10}}>
                        <img
                          src={image_menu}
                          style={{height: "auto", width: 35}}
                          alt="Retrair"
                          onClick={(() => {setShowMenu(!showMenu)})} />
                      </div>
                    }

                    <div style={(showMenu) ?
                        (width < 270) ? {display: "flex",  flexGrow: 1, flexDirection: "column", alignItems: "center"}
                        : {display: "flex",  flexGrow: 1, flexDirection: "row", alignItems: "center"}
                      :
                        (showSearch) ? 
                          (width < 260) ? {display: "flex",  flexGrow: 1, flexDirection: "column", alignItems: "center", marginTop: 10}
                          : (width < 370) ? {display: "flex",  flexGrow: 1, flexDirection: "column", alignItems: "center"}
                          : {display: "flex",  flexGrow: 1, flexDirection: "row", alignItems: "center"}
                        : 
                          (width < 260) ? {display: "flex",  flexGrow: 1, flexDirection: "column", alignItems: "center", marginTop: 10}
                          : (width < 350) ? {display: "flex",  flexGrow: 1, flexDirection: "column", alignItems: "center"}
                          : {display: "flex",  flexGrow: 1, flexDirection: "row", alignItems: "center"}}>

                      <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, 
                        paddingRight: 5}}>
                        <label style={{color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>
                          <label>{(showSearch) ? "Pesquisa dos Países" : "País"}</label>
                        </label>
                      </div>

                      <div style={(showMenu) ?
                          (width < 270) ? {paddingRight: 20, marginTop: 10}
                          : {paddingRight: 20}
                        :
                          (showSearch) ? 
                            (width < 470) ? {paddingRight: 20, marginTop: 10}
                            : {paddingRight: 20}
                          : 
                            (width < 350) ? {paddingRight: 20, marginTop: 10}
                            : {paddingRight: 20}}>

                        <img 
                          src={image_multiplaWhite}
                          style={showMenu ? 
                            (showSearch) ? { height: 40, width: "auto" }
                            : { height: 40, width: "auto" }
                          : 
                            { height: "auto", width: 150 }} alt="Logomarca" />
                      </div>
                    </div>
                  </div>
                </>
              :
                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center",
                  paddingLeft: 10, paddingRight: 10}}>

                  { !showMenu &&
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "flex-start", alignItems: "center",
                      paddingLeft: 10, paddingRight: 10}}>
                      <img
                        src={image_menu}
                        style={(width < 360) ? {height: "auto", width: 30} : {height: "auto", width: 35}}
                        alt="Retrair"
                        onClick={(() => {setShowMenu(!showMenu)})} />
                    </div>
                  }

                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5,
                    marginTop: 5}}>

                    <label style={showMenu ?
                        (showSearch) ? 
                          (width < 690) ? {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}
                          : (width < 730) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"}
                          : (width < 770) ? {color: "white", fontSize: 16, fontWeight: 600, textAlign: "center"}
                          : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}
                      :
                        {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>

                      <label>{(showSearch) ? "Pesquisa dos Países" : "País"}</label>
                    </label>
                  </div>

                  <img src={image_multiplaWhite} 
                    style={{ height: "auto", width: 150 }}
                    alt="Logomarca" />
                </div>
            }
          </div>
          {/* End - Header */}

          { showSearch ?
              <SearchCountry
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      {/* Begin - Buttons */}
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={() => save()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", 
                            paddingTop: 5, paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                          </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={() => add()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", 
                            paddingTop: 5, paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                          marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", 
                            paddingTop: 5, paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>
                      {/* End - Buttons */}

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingRight: 10, paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: "600", color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(showMenu) ?
                              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="countryName"
                                description="Nome"
                                planceHolder=""
                                value={name}
                                maxLength={60}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setName(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                              :
                                (width < 620) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>

                              <InputMasked
                                name="countryIBGE"
                                description="IBGE"
                                planceHolder=""
                                mask="9999"
                                value={IBGE || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setIBGE(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(showMenu) ?
                              (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :                     
                              (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(showMenu) ?
                                (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={(showMenu) ?
                                  (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                : 
                                  (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                <Input
                                  type="text"
                                  name="countryCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :   
                                  (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                <Input
                                  type="text"
                                  name="countryName"
                                  description="Nome"
                                  planceHolder=""
                                  value={name}
                                  maxLength={60}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setName(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                              :
                                (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>

                              <InputMasked
                                name="countryIBGE"
                                description="IBGE"
                                planceHolder=""
                                mask="9999"
                                value={IBGE || ""}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setIBGE(event.target.value)} />
                            </div>
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Country