import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import AccessControl from "../AccessControl"
import SearchRHSection from "./Search"
import image_add from "../../Images/Buttons/add.png"
import image_loading from "../../Images/spinner.gif"

const RHSection = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [permission, setPermission] = useState("")
  const [sge, setSge] = useState("")

  const [jRHSection, setJRHSection] = useState({})

  const [showPermission, setShowPermission] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)
   
  useEffect(() => {
    if (code === "") {
      setDescription("")
      setPermission("")
      setSge("")
    }
    else 
    {
      setDescription(jRHSection.DESCRIPTION)
      if ((jRHSection.PERMISSION !== null) && (jRHSection.PERMISSION !== undefined)) setPermission(jRHSection.PERMISSION)
      if ((jRHSection.SGE !== null) && (jRHSection.SGE !== undefined)) setSge(jRHSection.SGE)
    }

    document.getElementById("rhSectionDescription") && document.getElementById("rhSectionDescription").focus()
  }, [code, jRHSection, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setDescription("")
      setPermission("F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F")
      setSge("")
    }    
    else setCode("")

    setShowPermission(false)
    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")
    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonRHSection_New = {}

    setWarning("")
    setError("")

    jsonRHSection_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
      ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonRHSection_New += ", \"ID\": "+ code

    if (((description !== undefined) && (description !== null)) && (description !== ""))
      jsonRHSection_New += ",\"DESCRIPTION\": \""+ description +"\""

    if (((permission !== undefined) && (permission !== null)) && (permission !== "")) jsonRHSection_New += ", \"PERMISSION\": \""+ permission +"\""
    else jsonRHSection_New += ", \"PERMISSION\": \"F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F/F\""

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonRHSection_New += ", \"SGE\": \""+ sge +"\""
    jsonRHSection_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonRHSection_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "RH_SECTION/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o setor do RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhSectionDescription") && document.getElementById("rhSectionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJRHSection(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jRHSection))
      formData.append("JSON_NEW_1", jsonRHSection_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "RH_SECTION/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o setor RH. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("rhSectionDescription") && document.getElementById("rhSectionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJRHSection(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setShowPermission(false)
    setJRHSection(event)
    setCode(event.ID)
  }

  const setSelectedAccess = (event) => {
    setPermission(event)
    setShowPermission(false)
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Setor RH"
            titleSearch="Pesquisa dos Setores do RH"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchRHSection
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="rhSectionDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description}
                                maxLength={100}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                              <Input
                                type="text"
                                name="rhSectionCode"
                                description="Código"
                                planceHolder=""
                                value={code}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={(width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="rhSectionDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description}
                                maxLength={100}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={{marginTop: 10, width: "100%"}}>
                          <Titulo description="Acessos" />
                        </div>

                        { !showPermission &&
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <img 
                                  style={{height: 38, width: 38}}
                                  name="employeeAdd"
                                  src={image_add}
                                  alt="Adicionar" 
                                  onClick={(() => { setShowPermission(!showPermission) })} />
                              </div>
                            </div>
                          </div>
                        }

                        { showPermission &&
                          <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
                            <AccessControl
                              jsonCompany={jsonCompany}
                              access={permission}
                              setSelected={setSelectedAccess} 
                              showMenu={showMenu} 
                              width={width} />
                          </div>
                        }

                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default RHSection