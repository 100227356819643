import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Menu from "../Menu"
import SearchBox from "./Search"
import image_loading from "../../Images/spinner.gif"

const Box = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [hierarchy, setHierarchy] = useState("")
  const [description, setDescription] = useState("")

  const [fatherCode, setFatherCode] = useState("")
  const [fatherDescription, setFatherDescription] = useState("")
  const [fatherSge, setFatherSge] = useState("")

  const [periodic, setPeriodic] = useState(1)
  const [sge, setSge] = useState("")

  const [jBox, setJBox] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setHierarchy("")
      setDescription("")
      
      setPeriodic(1)
      setSge("")
    }
    else {
      setHierarchy(jBox.HIERARCHY)
      setDescription(jBox.DESCRIPTION)


      if ((jBox.FATHER !== undefined) && (jBox.FATHER !== null)){
        setFatherCode(jBox.FATHER.ID)
        setFatherDescription(jBox.FATHER.DESCRIPTION)
        if (jBox.SGE !== 0) setFatherSge(jBox.FATHER.SGE)
      }
      else
      {
        setFatherCode("")
        setFatherDescription("")
        setFatherSge("")
      }

      setPeriodic(jBox.PERIODIC)

      if ((jBox.SGE !== null) && (jBox.SGE !== undefined)) setSge(jBox.SGE)
    }

    document.getElementById("boxDescription") && document.getElementById("boxDescription").focus()

  }, [code, jBox, updateFocu])

  const add = (item) => {
    let returnJson = []
    let jsonBox = ""
    let nextNumber = ""
        
    setError("")
    setWarning("")
    setLoading("Aguarde buscando as informações...")

    ////
    // New Box
    ////
    if ((item === undefined) || ((item.HIERARCHY === undefined) && (item.ID === undefined)))
    {
      jsonBox = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"
      setFatherCode("")
      setFatherDescription("")
      setFatherSge("")
    }
    else
    {
      jsonBox = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" },"
      jsonBox += " \"HIERARCHY\": \""+ item.HIERARCHY +"\","
      jsonBox += " \"FATHER\": { \"ID\": "+ item.ID +" } }"
      
      setFatherCode(item.ID)
      setFatherDescription(item.DESCRIPTION)
      if ((item.SGE !== null) && (item.SGE !== undefined)) setFatherSge(item.SGE)      
    }

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonBox)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "BOX/NextNumber", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter o proximo número do box. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("boxDescription") && document.getElementById("boxDescription").focus()
        return
      }
  
      returnJson.WARNING && setWarning(returnJson.WARNING)

      nextNumber = returnJson.OBJECT
      setHierarchy(nextNumber)
    })
    
    setShowSearch(false)

    if (code === "") {
      setDescription("")
      setPeriodic(1)
      setSge("")
    }
    else setCode("")

    setError("")
    setWarning("")
    setLoading("")
    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonBox_New = {}

    setWarning("")
    setError("")

    jsonBox_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonBox_New += ", \"ID\": "+ code    
    if (hierarchy !== "") jsonBox_New += ", \"HIERARCHY\": \""+ hierarchy +"\""   
    if (description !== "") jsonBox_New += ", \"DESCRIPTION\": \""+ description +"\""    
    
    let cFather = 0
    if (fatherCode !== "") cFather = fatherCode
    if (cFather !== 0){
      jsonBox_New += ", \"FATHER\": { \"ID\": "+ cFather +", \"DESCRIPTION\": \""+ fatherDescription +"\""    
      if (fatherSge !== "") jsonBox_New += ", \"SGE\": "+ fatherSge
      jsonBox_New += " }"
    }    

    jsonBox_New += ", \"PERIODIC\": "+ periodic
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonBox_New += ", \"SGE\": "+ sge
    jsonBox_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonBox_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "BOX/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o box. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("boxDescription") && document.getElementById("boxDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJBox(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD",  JSON.stringify(jBox))
      formData.append("JSON_NEW_1", jsonBox_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "BOX/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o box. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("boxDescription") && document.getElementById("boxDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJBox(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }
  
  const setAdd = (item) => {
    setShowSearch(false)
    add(item)
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJBox(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Box"
            titleSearch="Pesquisa dos Boxes"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchBox
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                periodic={0}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={
                            (showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="boxHierarchy"
                                description="Hierarquia"
                                value={hierarchy}
                                maxLength={20}
                                disabled={true}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setHierarchy(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 750) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="boxPeriodic"
                                description="Periódico"
                                value={periodic || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" },"+
                                  " {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setPeriodic} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(showMenu) ?
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  : (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                <Input
                                  type="text"
                                  name="boxCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                <Input
                                  type="text"
                                  name="boxHierarchy"
                                  description="Hierarquia"
                                  value={hierarchy}
                                  maxLength={20}
                                  disabled={true}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setHierarchy(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                                : (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                              :
                                (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="boxPeriodic"
                                description="Periódico"
                                value={periodic || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" },"+
                                  " {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setPeriodic} />
                            </div>
                          </div>
                        }

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="boxDescription"
                              description="Descrição"
                              value={description}
                              maxLength={150}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                        </div>

                        <div style={{marginTop: 20}}>
                          <label style={{fontWeight: 600}}>Pai:</label>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="boxFatherDescription"
                              description=""
                              value={fatherDescription}
                              maxLength={150}
                              disabled={true}
                              require={false}
                              image={false}
                              typeInput={2} 
                              onSelect={setFieldSelected} />
                          </div>
                        </div> 
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Box