import React, { useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Titulo from "../../Components/Titulo"
import BillPayPortions from "../BillPayPortions"
import SearchAccount from "../Account/Search"
import SearchAccountPlan from "../AccountPlan/Search"
import SearchBillPay from "./Search"
import SearchBillPayPayment from "../BillPayPayment/Search"
import SearchSupplier from "../Supplier/Search"
import SearchDocumentType from "../DocumentType/Search"
import SearchPaymentForm from "../PaymentForm/Search"
import image_cancel from "../../Images/Buttons/cancel.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_receive from "../../Images/Buttons/receive.png"
import image_reversal from "../../Images/Buttons/reversal.png";
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const BillPay = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")

  const [accountPlanCode, setAccountPlanCode] = useState("")
  const [accountPlanNumber, setAccountPlanNumber] = useState("")
  const [accountPlanDescription, setAccountPlanDescription] = useState("")

  const [documentTypeCode, setDocumentTypeCode] = useState("")
  const [documentTypeDescription, setDocumentTypeDescription] = useState("")

  const [paymentFormCode, setPaymentFormCode] = useState("")
  const [paymentFormDescription, setPaymentFormDescription] = useState("")
  const [paymentFormTerm, setPaymentFormTerm] = useState("")

  const [supplierCode, setSupplierCode] = useState("")
  const [supplierCpfCnpj, setSupplierCpfCnpj] = useState("")
  const [supplierCorporateName, setSupplierCorporateName] = useState("")
  const [supplierFantasyName, setSupplierFantasyName] = useState("")
  const [supplierCep, setSupplierCep] = useState("")
  const [supplierAddress, setSupplierAddress] = useState("")
  const [supplierNumber, setSupplierNumber] = useState("")
  const [supplierComplement, setSupplierComplement] = useState("")
  const [supplierNeighborhood, setSupplierNeighborhood] = useState("")
  const [supplierCity, setSupplierCity] = useState("")
  const [supplierCityIBGE, setSupplierCityIBGE] = useState("")
  const [supplierUf, setSupplierUf] = useState("")
  const [supplierPhone, setSupplierPhone] = useState("")
  const [supplierEmail, setSupplierEmail] = useState("")
  const [supplierIm, setSupplierIm] = useState("")

  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")

  const [number, setNumber] = useState("")
  const [paymentNumber, setPaymentNumber] = useState(0)
  const [portion, setPortion] = useState("")
  const [totalPortion, setTotalPortion] = useState("")
  const [emissionDate, setEmissionDate] = useState("")
  const [releaseDate, setReleaseDate] = useState("")
  const [dueDate, setDueDate] = useState("")

  const [cancellationDate, setCancellationDate] = useState("")
  const [cancellationDatePrevious, setCancellationDatePrevious] = useState("")

  const [paymentDate, setPaymentDate] = useState("")
  const [paymentDatePrevious, setPaymentDatePrevious] = useState("")

  const [value, setValue] = useState("")
  const [valuePaid, setValuePaid] = useState("") // Valor Pago
  const [valuePaidPrevious, setValuePaidPrevious] = useState("")
  const [valueAmountPay, setValueAmountPay] = useState("") // Valor à Pagar

  const [schedulingDate, setSchedulingDate] = useState("")
  const [barCode, setBarCode] = useState("")
  const [nfe, setNfe] = useState("")
  const [os, setOs] = useState("")
  const [origin, setOrigin] = useState(0)
  const [observation, setObservation] = useState("")

  const [situation, setSituation] = useState(0)
  const [situationDescription, setSituationDescription] = useState("")  
  const [situationPrevious, setSituationPrevious] = useState(0)
  const [situationPreviousDescription, setSituationPreviousDescription] = useState("")

  const [sge, setSge] = useState("")

  const [jBillPay, setJBillPay] = useState({})
  const [jBillPayReversal, setJBillPayReversal] = useState("{}")

  const [showMenu, setShowMenu] = useState(true)
  const [showFunctions, setShowFunctions] = useState(false)

  const [showCancellation, setShowCancellation] = useState(false)
  const [showAccount, setShowAccount] = useState(false)
  const [showReversal, setShowReversal] = useState(false)
  const [showCancellationReversal, setShowCancellationReversal] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  const [showSupplier, setShowSupplier] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)

  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const add = () => {
    setShowSearch(false)
        
    setError("")
    setWarning("")
        
    setCode("")
    setAccountPlanCode("")
    setAccountPlanNumber("")
    setAccountPlanDescription("")

    setDocumentTypeCode("")
    setDocumentTypeDescription("")

    setPaymentFormCode("")
    setPaymentFormDescription("")
    setPaymentFormTerm("")

    setSupplierCode("")
    setSupplierCpfCnpj("")
    setSupplierCorporateName("")
    setSupplierFantasyName("")
    setSupplierCep("")
    setSupplierAddress("")
    setSupplierNumber("")
    setSupplierComplement("")
    setSupplierNeighborhood("")
    setSupplierCity("")
    setSupplierCityIBGE("")
    setSupplierUf("")
    setSupplierPhone("")
    setSupplierEmail("")
    setSupplierIm("")

    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setNumber("")
    setPaymentNumber("")
    setPortion("")
    setTotalPortion("")
    setEmissionDate("")
    setReleaseDate("")
    setDueDate("")

    setCancellationDate("")
    setCancellationDatePrevious("")

    setPaymentDate("")
    setPaymentDatePrevious("")

    setValue("")
    setValuePaid("")
    setValuePaidPrevious("")
    setValueAmountPay("")

    setSchedulingDate("")
    setBarCode("")
    setNfe("")
    setOs("")
    setOrigin(1)
    setObservation("")
  
    setSituation(1)
    setSituationDescription("Lançamento")  
    setSituationPrevious(0)
    setSituationPreviousDescription("")
 
    setSge("")
    
    setShowFunctions(true)
  }

  const callCancellation = () => {
    let date = new Date()
    date = date.toLocaleDateString()
    setCancellationDate(date)

    setShowCancellation(true)

    setSituationPrevious(situation)
    setSituation(3)
    setSituationPreviousDescription(situationDescription)
    setSituationDescription("Cancelado")
  }

  const callCancellationReversal = () => {
    setShowCancellationReversal(true)
    
    setCancellationDatePrevious(cancellationDate)
    setCancellationDate("")

    if (paymentNumber === 0)
    {
      setSituationPrevious(situation)
      setSituation(1)
      setSituationPreviousDescription(situationDescription)
      setSituationDescription("Pendente")
    }
    else
    {
      setSituationPrevious(situation)
      setSituation(4)
      setSituationPreviousDescription(situationDescription)
      setSituationDescription("Pago parcialmente")
    }
  }

  const callPayment = () => {
    setPaymentDatePrevious(paymentDate)
    setValuePaidPrevious(valuePaid)

    setPaymentDate("")
    setValuePaid("")
    setShowPayment(true)
  }

  const callReversal = (item) => {
    const jsonReversal = "{ \"ID\": "+ item.ID +", \"ACCOUNT\": { \"ID\": "+ item.ACCOUNT.ID +" }, \"BILL_PAY\": { \"COMPANY\": { \"ID\": "+ 
      item.BILL_PAY.COMPANY.ID +" } }, \"VALUE\": "+ item.VALUE +" }"

    setJBillPayReversal(jsonReversal)

    setValuePaidPrevious(valuePaid)
    setValuePaid(valuePaid - item.VALUE)

    setShowReversal(true)

    setSituationPrevious(situation)
    setSituation(2)
    setSituationPreviousDescription(situationDescription)
    setSituationDescription("Pago")
  }

  const cancel = () => {
    save()
  }

  const cleanAccount = () => {
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    setAccountPlanCode("")
    setAccountPlanNumber("")
    setAccountPlanDescription("")

    setShowAccountPlan(false)
  }

  const cleanDocumentType = () => {
    setDocumentTypeCode("")
    setDocumentTypeDescription("")

    setShowDocumentType(false)
  }

  const cleanPaymentForm = () => {
    setPaymentFormCode("")
    setPaymentFormDescription("")

    setShowPaymentForm(false)
  }

  const cleanSupplier = () => {
    setSupplierCode("")
    setSupplierCpfCnpj("")
    setSupplierCorporateName("")
    setSupplierFantasyName("")
    setSupplierCep("")
    setSupplierAddress("")
    setSupplierNumber("")
    setSupplierComplement("")
    setSupplierNeighborhood("")
    setSupplierCity("")
    setSupplierCityIBGE("")
    setSupplierUf("")
    setSupplierPhone("")
    setSupplierEmail("")
    setSupplierIm("")

    setShowSupplier(false)
  }

  const enableAccount = () => {
    setShowAccount(!showAccount)
  }

  const enableAccountPlan = () => {
    setShowAccountPlan(!showAccountPlan)
  }

  const enableDocumentType = () => {
    setShowDocumentType(!showDocumentType)
  }

  const enablePaymentForm = () => {
    setShowPaymentForm(!showPaymentForm)
  }  

  const enableSupplier = () => {
    setShowSupplier(!showSupplier)
  }

  const fieldSelected = () => {
  }

  const fillAccount = (account) => {
    setAccountCode(account.ID)
    setAccountNumber(account.NUMBER)
    setAccountOwner(account.OWNER)

    setShowAccount(false)
  }

  const fillAccountPlan = (accountPlan) => {
    setAccountPlanCode(accountPlan.ID)
    setAccountPlanNumber(accountPlan.NUMBER)
    setAccountPlanDescription(accountPlan.DESCRIPTION)

    setShowAccountPlan(false)
  }  

  const fillDocumentType = (documentType) => {
    setDocumentTypeCode(documentType.ID)
    setDocumentTypeDescription(documentType.DESCRIPTION)

    setShowDocumentType(false)
  }  

  const fillPaymentForm = (paymentForm) => {
    setPaymentFormCode(paymentForm.ID)
    setPaymentFormDescription(paymentForm.DESCRIPTION)

    let term = "0"
    if (jsonCompany.TYPE === 7){
      if (paymentForm.TERM_PAY == null) term = "0"
      else term = paymentForm.TERM_PAY
    }
    else {
      if (paymentForm.TERM_RECEIVE == null) term = "0"
      else term = paymentForm.TERM_RECEIVE
    }

    setPaymentFormTerm(term)
    setShowPaymentForm(false)
  }

  const fillPortion = (number, portion, totalPortion, company, accountPlan, documentType, paymentForm, supplier, employee) => {
    let portionNew = ""

    if (portion.PORTION > 1) portionNew += ", {"
    else portionNew += "{"

    portionNew += company
    portionNew += accountPlan
    portionNew += documentType
    portionNew += paymentForm
    portionNew += supplier
    portionNew += employee
    portionNew += ", \"NUMBER\": "+ number + ","
    portionNew += " \"PORTION\": "+ portion.PORTION + ","
    portionNew += " \"TOTAL_PORTION\": "+ totalPortion + ","

    let date = new Date()
    date = date.toLocaleDateString()

    portionNew += " \"EMISSION_DATE\": \""+ date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2) + "T00:00:00\","
    portionNew += " \"RELEASE_DATE\": \""+ date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2) + "T00:00:00\","
    portionNew += " \"DUE_DATE\": \""+ portion.DUE_DATE.substr(6, 4) + "-" + portion.DUE_DATE.substr(3, 2) + "-" + portion.DUE_DATE.substr(0, 2) + "T00:00:00\","
    portionNew += " \"VALUE\": "+ portion.VALUE + ","
    portionNew += " \"VALUE_PAID\": 0,"
    portionNew += " \"SITUATION\": 1,"
    portionNew += " \"ORIGIN\": 1,"
    portionNew += " \"OBSERVATION\": \""+ portion.OBSERVATION +"\""
    portionNew += " }"

    return portionNew
  }

  const fillSupplier = (supplier) => {
    setSupplierCode(supplier.ID)
    setSupplierCpfCnpj(supplier.CPF_CNPJ)
    setSupplierCorporateName(supplier.CORPORATE_NAME)
    setSupplierFantasyName(supplier.FANTASY_NAME)
    setSupplierCep(supplier.CEP)
    setSupplierAddress(supplier.ADDRESS)
    setSupplierNumber(supplier.NUMBER)

    if (supplier.COMPLEMENT != null) setSupplierComplement(supplier.COMPLEMENT)

    setSupplierNeighborhood(supplier.NEIGHBORHOOD)
    setSupplierCity(supplier.CITY)
    setSupplierCityIBGE(supplier.CITY_IBGE)
    setSupplierUf(supplier.UF)

    if (supplier.STANDARD_PHONE != null) setSupplierPhone(supplier.STANDARD_PHONE)
    if (supplier.STANDARD_EMAIL != null) setSupplierEmail(supplier.STANDARD_EMAIL)
    if (supplier.IM != null) setSupplierIm(supplier.IM)

    setShowSupplier(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleObservation = (event) => {
    setObservation(event.target.value.replaceAll("\\",""))
  }
  
  const handleValueReceived = (event) => {
    if (event.target.value !== "")
      formatNumeric(setValuePaid(event.target.value), 2)
  }

  const paid = () => { 
    if (!validateDate(paymentDate)){
      setError("Data do pagamento inválida!")
      return
    }

    if (accountCode === ""){
      setError("É necessário informar a conta!")
      return
    }

    if (valuePaid === ""){
      setError("É necessário informar o valor!")
      return
    }

    if (parseFloat(valuePaid) <= 0){
      setError("É necessário informar o valor!")
      return
    }

    save()
  }

  const returnAdd = () => {
    setWarning("")
    setError("")

    setJBillPayReversal("{}")

    setShowAccountPlan(false)
    setShowCancellation(false)
    setShowCancellationReversal(false)
    setShowSupplier(false)
    setShowDocumentType(false)
    setShowPaymentForm(false)
    setShowPayment(false)
    setShowReversal(false)

    setShowSearch(true)
  }

  const returnCancellation = () => {
    setCancellationDate("")
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowCancellation(false)
  }

  const returnCancellationReversal = () => {
    setCancellationDate(cancellationDatePrevious)
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowCancellationReversal(false)
  }

  const returnPayment = () => {
    setPaymentDate(paymentDatePrevious)
    setValuePaid(valuePaidPrevious)

    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setShowPayment(false)
  }

  const returnReversal = () => {
    setPaymentDate(paymentDatePrevious)
    setValuePaid(valuePaidPrevious)
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowReversal(false)
  }

  const reversal = () => {
    save()
  }

  const reversalCancellation = () => {
    save()
  }

  const save = (portions) => {
    let returnJson = []
    let jsonBillPay_New = {}

    setWarning("")
    setError("")

    if (number === ""){
      setError("Número não informado.")
      return
    }

    if (supplierCode === ""){
      setError("Fornecedor não informado.")
      return
    }

    if (accountPlanCode === ""){
      setError("Plano de conta não informado.")
      return
    }

    if (documentTypeCode === ""){
      setError("Tipo de documento não informado.")
      return
    }

    if (code === ""){
      if (paymentFormCode === ""){
        setError("Forma de pagamento não informada.")
        return
      }
    }

    const jsonBillPayCompany_New = " \"COMPANY\": "+ JSON.stringify(jsonCompany)

    const jsonBillPayAccountPlan_New = ", \"ACCOUNT_PLAN\": { \"ID\": "+ accountPlanCode +", \"NUMBER\": \""+ accountPlanNumber +
      "\", \"DESCRIPTION\": \""+ accountPlanDescription +"\" }"

    const jsonBillPayDocumentType_New = ", \"DOCUMENT_TYPE\": { \"ID\": "+ documentTypeCode +", \"DESCRIPTION\": \""+ documentTypeDescription +"\" }"

    let jsonBillPayPaymentForm_New = ""
    if (paymentFormCode !== "") 
      jsonBillPayPaymentForm_New = ", \"PAYMENT_FORM\": { \"ID\": "+ paymentFormCode +", \"DESCRIPTION\": \""+ paymentFormDescription +"\" }"
    else jsonBillPayPaymentForm_New = ", \"PAYMENT_FORM\": { \"ID\": 0, \"DESCRIPTION\": \"\" }"

    let supplierIbge = 0
    if (supplierCityIBGE !== "") supplierIbge = supplierCityIBGE

    let jsonBillPaySupplier_New = ", \"SUPPLIER\": { \"ID\": "+ supplierCode +", \"CPF_CNPJ\": \""+ supplierCpfCnpj +
      "\", \"CORPORATE_NAME\": \""+ supplierCorporateName +"\", \"FANTASY_NAME\": \""+ supplierFantasyName +"\", \"CEP\": \""+ supplierCep +
      "\", \"ADDRESS\": \""+ supplierAddress +"\", \"NUMBER\": \""+ supplierNumber +"\", \"NEIGHBORHOOD\": \""+ supplierNeighborhood +
      "\", \"CITY\": \""+ supplierCity +"\", \"CITY_IBGE\": "+ supplierIbge  +", \"UF\": \""+ supplierUf + "\""
    if (supplierComplement !== "") jsonBillPaySupplier_New += ", \"COMPLEMENT\": \""+ supplierComplement +"\""
    if (supplierPhone !== "") jsonBillPaySupplier_New += ", \"STANDARD_PHONE\": \""+ supplierPhone +"\""
    if (supplierEmail !== "") jsonBillPaySupplier_New += ", \"STANDARD_EMAIL\": \""+ supplierEmail +"\""
    if (supplierIm !== "") jsonBillPaySupplier_New += ", \"IM\": \""+ supplierIm +"\""
    jsonBillPaySupplier_New += " }"

    const jsonBillPayEmployee_New = ", \"EMPLOYEE\": { \"ID\": "+ jsonLogin.ID +", \"NAME\": \""+ jsonLogin.NAME +"\" }"
    
    if (code === ""){
      setLoading("Aguarde incluindo o registro...")

      jsonBillPay_New = "[ "

      portions.map(item => (
        jsonBillPay_New += fillPortion(number, item, portions.length, jsonBillPayCompany_New, jsonBillPayAccountPlan_New, 
          jsonBillPayDocumentType_New, jsonBillPayPaymentForm_New, jsonBillPaySupplier_New, jsonBillPayEmployee_New)))

      jsonBillPay_New += " ]"
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      jsonBillPay_New = "{ \"ID\": \""+ code +"\","
      jsonBillPay_New += jsonBillPayCompany_New
      jsonBillPay_New += jsonBillPaySupplier_New
      jsonBillPay_New += jsonBillPayAccountPlan_New
      jsonBillPay_New += jsonBillPayDocumentType_New
      jsonBillPay_New += jsonBillPayPaymentForm_New
      jsonBillPay_New += jsonBillPayEmployee_New

      if ((nfe !== "") && (nfe !== 0)) jsonBillPay_New += ", \"NFS\": "+ nfe
      if ((os !== "") && (os !== 0)) jsonBillPay_New += ", \"OS\": "+ nfe

      if (accountCode !== "") jsonBillPay_New += ", \"ACCOUNT\": { \"ID\": "+ accountCode +", \"NUMBER\": \""+ accountNumber +
        "\", \"OWNER\": \""+ accountOwner +"\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

      jsonBillPay_New += ", \"NUMBER\": \""+ number +"\""
      jsonBillPay_New += ", \"PORTION\": "+ portion
      jsonBillPay_New += ", \"TOTAL_PORTION\": "+ totalPortion

      if (emissionDate !== "")
        if (String(emissionDate).replace("_","").length >= 10){
          const emission = String(emissionDate).substr(6,4) + "-" + String(emissionDate).substr(3,2) + "-" + String(emissionDate).substr(0,2) + "T00:00:00"
          jsonBillPay_New += ", \"EMISSION_DATE\": \""+ emission + "\""
        }

      const release = String(releaseDate).substr(6,4) + "-" + String(releaseDate).substr(3,2) + "-" + String(releaseDate).substr(0,2) + "T00:00:00"
      jsonBillPay_New += ", \"RELEASE_DATE\": \""+ release + "\""

      if (dueDate !== "")
        if (String(dueDate).replace("_","").length >= 10){
          const due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00"
          jsonBillPay_New += ", \"DUE_DATE\": \""+ due + "\""
        }

      if (cancellationDate !== "")
        if (String(cancellationDate).replace("_","").length >= 10){
          const cancellation = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" +
            String(cancellationDate).substr(0,2) + "T00:00:00";
          jsonBillPay_New += ", \"CANCELLATION_DATE\": \""+ cancellation + "\""
        }

      if (paymentDate !== "")
        if (String(paymentDate).replace("_","").length >= 10){
          const receiving = String(paymentDate).substr(6,4) + "-" + String(paymentDate).substr(3,2) + "-" + String(paymentDate).substr(0,2) + "T00:00:00"
          jsonBillPay_New += ", \"PAYMENT_DATE\": \""+ receiving + "\""
        }

      if (value !== "") jsonBillPay_New += ", \"VALUE\": "+ value
      if (valuePaid !== "") jsonBillPay_New += ", \"VALUE_PAID\": "+ valuePaid
      if (origin !== 0) jsonBillPay_New += ", \"ORIGIN\": "+ origin

      if (schedulingDate !== "")
        if (String(schedulingDate).replace("_","").length >= 10){
          const scheduling = String(schedulingDate).substr(6,4) + "-" + String(schedulingDate).substr(3,2) + "-" + String(schedulingDate).substr(0,2) + "T00:00:00"
          jsonBillPay_New += ", \"SCHEDULING_DATE\": \""+ scheduling + "\""
        }

      if (barCode !== "") jsonBillPay_New += ", \"BARCODE_BILLET\": \""+ barCode + "\""
      if (observation !== "") jsonBillPay_New += ", \"OBSERVATION\": \""+ observation + "\""
      if (situation !== 0) jsonBillPay_New += ", \"SITUATION\": "+ situation
      if ((sge !== "") && (sge !== 0)) jsonBillPay_New += ", \"SGE\": "+ sge
      jsonBillPay_New += " }"
    }
    
    if (code === ""){
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonBillPay_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "BILL_PAY/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Conta a Pagar. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setError("")
        setLoading("")
        setWarning(returnJson.WARNING)

        alert(returnJson.OBJECT)
        returnAdd()
      })
    }
    else
    {
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD",  JSON.stringify(jBillPay))
      formData.append("JSON_NEW_1", jsonBillPay_New)
      formData.append("JSON_NEW_2", jBillPayReversal)
      formData.append("JSON_LOGIN",  JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.put(BASE_URL + "BILL_PAY/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Conta a Pagar. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }

        setError("")
        setLoading("")
        setWarning(returnJson.WARNING)
      
        setJBillPayReversal("{}")

        setShowAccountPlan(false)
        setShowCancellation(false)
        setShowCancellationReversal(false)
        setShowSupplier(false)
        setShowDocumentType(false)
        setShowPaymentForm(false)
        setShowPayment(false)
        setShowReversal(false)

        setAccountCode("")
        setAccountNumber("")
        setAccountOwner("")        

        updateRecord()
      })
    }
  }

  const savePortions = (portions) => {
    save(portions)
  }

  const selected = (event) => {
    setShowSearch(false)

    setShowAccountPlan(false)
    setShowSupplier(false)
    setShowDocumentType(false)
    setShowPaymentForm(false)

    setCode(event.ID)
    setAccountPlanCode(event.ACCOUNT_PLAN.ID)
    setAccountPlanNumber(event.ACCOUNT_PLAN.NUMBER)
    setAccountPlanDescription(event.ACCOUNT_PLAN.DESCRIPTION)
    setDocumentTypeCode(event.DOCUMENT_TYPE.ID)
    setDocumentTypeDescription(event.DOCUMENT_TYPE.DESCRIPTION)

    if (event.PAYMENT_FORM != null){
      if (event.PAYMENT_FORM.ID != null) setPaymentFormCode(event.PAYMENT_FORM.ID); else setPaymentFormCode("")
      if (event.PAYMENT_FORM.DESCRIPTION != null) setPaymentFormDescription(event.PAYMENT_FORM.DESCRIPTION); else setPaymentFormDescription("")
    }

    setSupplierCode(event.SUPPLIER.ID)
    setSupplierCpfCnpj(event.SUPPLIER.CPF_CNPJ)
    setSupplierCorporateName(event.SUPPLIER.CORPORATE_NAME)
    setSupplierFantasyName(event.SUPPLIER.FANTASY_NAME)

    if (((event.NFE !== undefined) && (event.NFE !== null)) && (event.NFE !== 0)) setNfe(event.NFE)
    if (((event.OS !== undefined) && (event.OS !== null)) && (event.OS !== 0)) setOs(event.OS)

    if (event.ACCOUNT != null){
      if (event.ACCOUNT.ID != null) setAccountCode(event.ACCOUNT.ID); else setAccountCode("")
      if (event.ACCOUNT.NUMBER != null) setAccountNumber(event.ACCOUNT.NUMBER); else setAccountNumber("")
      if (event.ACCOUNT.OWNER != null) setAccountOwner(event.ACCOUNT.OWNER); else setAccountOwner("")
    }

    setNumber(event.NUMBER)
    setPortion(event.PORTION)
    setTotalPortion(event.TOTAL_PORTION)

    const sEmissionDate = event.EMISSION_DATE.substr(8, 2) + "-" + event.EMISSION_DATE.substr(5, 2) + "-" + event.EMISSION_DATE.substr(0, 4)
    setEmissionDate(sEmissionDate)

    const sReleaseDate = event.RELEASE_DATE.substr(8, 2) + "-" + event.RELEASE_DATE.substr(5, 2) + "-" + event.RELEASE_DATE.substr(0, 4)
    setReleaseDate(sReleaseDate)

    const sDueDate = event.DUE_DATE.substr(8, 2) + "-" + event.DUE_DATE.substr(5, 2) + "-" + event.DUE_DATE.substr(0, 4)
    setDueDate(sDueDate)

    if (event.CANCELLATION_DATE !== "0001-01-01T00:00:00"){
      const sCancellationDate = event.CANCELLATION_DATE.substr(8, 2) + "-" +  event.CANCELLATION_DATE.substr(5, 2) + "-" + 
        event.CANCELLATION_DATE.substr(0, 4)

      setCancellationDate(sCancellationDate);
    } else setCancellationDate("")

    setPaymentDate("")
    setPaymentDatePrevious("")
    setValue(formatNumeric(event.VALUE, 2))

    setValuePaid(formatNumeric(event.VALUE_PAID, 2))
    setValuePaidPrevious("")

    setValueAmountPay(formatNumeric((event.VALUE - event.VALUE_PAID), 2))

    if (event.SCHEDULING_DATE !== "0001-01-01T00:00:00"){
      const sSchedulingDate = event.SCHEDULING_DATE.substr(8, 2) + "-" +  event.SCHEDULING_DATE.substr(5, 2) + "-" + 
        event.SCHEDULING_DATE.substr(0, 4)

      setSchedulingDate(sSchedulingDate);
    } else setSchedulingDate("")

    if (event.BARCODE_BILLET != null) setBarCode(event.BARCODE_BILLET); else setBarCode("")

    setOrigin(event.ORIGIN)

    if (event.OBSERVATION != null) setObservation(event.OBSERVATION); else setObservation("")
    
    setSituation(event.SITUATION)
    setSituationPrevious("")

    setSituationDescription(event.DESCRIPTION_SITUATION)
    setSituationPreviousDescription("")

    if (((event.SGE !== undefined) && (event.SGE !== null)) && (event.SGE !== 0)) setSge(event.SGE)

    setPaymentNumber(event.PAYMENT_NUMBER)

    setShowFunctions(true)
    setJBillPay(event)
  }

  const updateRecord = () => {
    let returnJson = []
    const jBillPay  = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID  +" } }"

    setLoading("")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillPay)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILL_PAY/SearchUpdate", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível atualizar a Conta a Pagar. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }
      else
      {
        selected(returnJson.OBJECT)
        setLoading("")
      }
    })
  }

  const validateDate = (value) => {
    let error = false;
    const date = value;
    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Conta a Pagar"
            titleSearch="Pesquisa das Contas a Pagar"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchBillPay
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={add}
                setSelected={selected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>                    
                      { !showAccount && !showAccountPlan && !showCancellation && !showCancellationReversal && !showSupplier && !showDocumentType &&
                        !showPaymentForm && !showPayment && !showReversal && code &&

                        <> 
                          { situation === 1 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              { showFunctions &&
                                <>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={save}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callPayment()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_receive} alt="Pagar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pagar</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callCancellation()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_cancel  } alt="Cancelar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                                    </div>
                                  </div>
                                </>
                              }

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,                                  
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 2 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 3 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              { showFunctions &&
                                <>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callCancellationReversal()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_cancel} alt="Estornar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Estornar</label>
                                    </div>
                                  </div>
                                </>
                              }

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 4 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => callPayment()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_receive} alt="Pagar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pagar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => callCancellation()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_cancel  } alt="Cancelar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }
                          
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={showMenu ?
                                (width < 1150) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                :
                                  (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :                                  
                                    (width < 660) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billPayCode"
                                    description="Código"
                                    value={code}
                                    maxLength={11}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :                                  
                                    (width < 660) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billPayNumber"
                                    description="Documento"
                                    value={number}
                                    maxLength={50}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1150) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                :
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1150) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                  <Input
                                    type="text"
                                    name="billPayPortion"
                                    description="Parcela"
                                    value={portion}
                                    maxLength={6}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1150) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                  <Input
                                    type="text"
                                    name="billPayTotalPortion"
                                    description="Tot. Parcela"
                                    value={totalPortion}
                                    maxLength={6}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :  
                                (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billPayValue"
                                    description="Valor"
                                    value={value}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billPayValuePai"
                                    description="Valor à Pagar"
                                    value={((value - valuePaid) < 0) ? 
                                              0 : (situationDescription === "Pago") ? 0 : formatNumeric(value - valuePaid, 2) || ''}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor Pago"
                                    value={valuePaid}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}}>
                                  <Input
                                    type="text"
                                    name="billPaySituationDescription"
                                    description="Situação"
                                    value={situationDescription} 
                                    maxLength={10}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                  <InputMasked
                                    type="text"
                                    name="billPayEmissionDate"
                                    description="Data Emissão"
                                    mask="99/99/9999"
                                    value={emissionDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billPayReleaseDate"
                                    description="Data Lançamento"
                                    mask="99/99/9999"
                                    value={releaseDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                  
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  { situation === 1 &&
                                    <InputMasked
                                      type="text"
                                      name="billPayDueDate"
                                      description="Data Vencimento"
                                      mask="99/99/9999"
                                      value={dueDate}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setDueDate(event.target.value)} />
                                  }

                                  { situation !== 1 &&
                                    <InputMasked
                                      type="text"
                                      name="billPayDueDate"
                                      description="Data Vencimento"
                                      mask="99/99/9999"
                                      value={dueDate}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  }

                                </div>
                                
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billPayCancellationDate"
                                    description="Data Cancelamento"
                                    mask="99/99/9999"
                                    value={cancellationDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>

                                { situation === 1 &&
                                  <Input
                                    type="text"
                                    name="billPayObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={handleObservation} />
                                }

                                { situation !== 1 &&
                                  <Input
                                    type="text"
                                    name="billPayObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                }

                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                              <Titulo description="Agendamento das Contas" />

                              <div style={(showMenu) ?
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                :  
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}                              
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                <div style={(showMenu) ?
                                    (showMenu < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (showMenu < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                    : (showMenu < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                  :
                                    (showMenu < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>

                                  <InputMasked
                                    type="text"
                                    name="billPaySchedulingDate"
                                    description="Data:"
                                    mask="99/99/9999"
                                    value={schedulingDate}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setSchedulingDate(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Input
                                    type="text"
                                    name="billPayBarCode"
                                    description="Código de Barras:"
                                    value={barCode}
                                    maxLength={255}
                                    disabled={situation === 1 ? false : true}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setBarCode(event.target.value)} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                <Titulo description="Fornecedor" />
                                <div style={showMenu ?
                                    (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}
                                    : 
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}}>
                                    <Input
                                      type="text"
                                      name="billPaySupplierCpfCnpj"
                                      description="CPF/CNPJ"
                                      value={12345678912345}
                                      maxLength={14}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="billPaySupplierCorporateName"
                                      description="Nome"
                                      value={supplierCorporateName}
                                      maxLength={60}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :  
                                  (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Forma de Pagamento" />
                                <div style={showMenu ?
                                    (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}}>
                                    <Input
                                      type="text"
                                      name="billPayPaymentFormCode"
                                      description="Código"
                                      value={paymentFormCode}
                                      maxLength={14}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="billPayPaymentFormDescription"
                                      description="Descrição"
                                      value={paymentFormDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Plano de Conta" />
                                
                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      {display: "flex", flexGrow: 1, minWidth: 180}
                                    : 
                                      {display: "flex", flexGrow: 1, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="billPayAccountPlanDescription"
                                      description="Descrição"
                                      value={accountPlanDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { (String(situation) === "1") && showFunctions &&
                                    <div style={(showMenu) ? 
                                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccountPlan}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccountPlan}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>
                              
                              <div style={showMenu ?
                                  (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :  
                                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Tipo Documento" />

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      {display: "flex", flexGrow: 1, minWidth: 180}
                                    : 
                                      {display: "flex", flexGrow: 1, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="billPayDocumentTypeDescription"
                                      description="Descrição"
                                      value={documentTypeDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { (String(situation) === "1") && showFunctions &&
                                    <div style={(showMenu) ? 
                                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }
        
                                </div>
                              </div>
                            </div>

                            { ((situation === 2) || (situation === 4)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Pagamentos" />

                                <SearchBillPayPayment
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonBillPay={jBillPay}
                                  jsonLogin={jsonLogin}
                                  system={system}
                                  callReversal={callReversal} 
                                  showFunctions={showFunctions} 
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                            { ((situation === 3) && (valuePaid > 0)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Pagamentos" />
                              
                                <SearchBillPayPayment
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonBillPay={jBillPay}
                                  jsonLogin={jsonLogin}
                                  system={system}
                                  callReversal={callReversal}
                                  showFunctions={showFunctions}
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                          </div>
                        </>
                      }

                      { !showAccountPlan && !showSupplier && !showDocumentType && !showPaymentForm && !code &&
                        <>

                          { !paymentFormCode &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,                                  
                                    paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                              </div>
                            </>
                          }

                          <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={showMenu ?
                                (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="billPayNumber"
                                  description="Documento"
                                  value={number}
                                  maxLength={50}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={fieldSelected}
                                  onChange={(event) => setNumber(event.target.value)} />
                              </div>

                              <div style={showMenu ? 
                                  (width < 990) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 230, maxWidth: 230}
                                : 
                                  (width < 730) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 230, maxWidth: 230}}>

                                <Input
                                  type="text"
                                  name="billPaySituationDescription"
                                  description="Situação"
                                  value={situationDescription}
                                  maxLength={21}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2} 
                                  onSelect={fieldSelected} />
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Fornecedor" />
                                
                                <div style={showMenu ?
                                    (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?                                    
                                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="billPaySupplierCpfCnpj"
                                        description="CPF/CNPJ"
                                        value={supplierCpfCnpj}
                                        maxLength={14}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : 
                                        (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="billPaySupplierCorporateName"
                                        description="Nome"
                                        value={supplierCorporateName}
                                        maxLength={60}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableSupplier}>
                                      <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanSupplier}>
                                      <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={showMenu ?
                                  (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :
                                  (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Plano de Conta" />
                                
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="billPayAccountPlanDescription"
                                      description="Descrição"
                                      value={accountPlanDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccountPlan}>
                                      <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccountPlan}>
                                      <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 50) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Tipo Documento" />

                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="billPayDocumentTypeDescription"
                                      description="Descrição"
                                      value={documentTypeDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { situation === 1 &&
                                    <div style={(showMenu) ? 
                                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :
                                  (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Forma de Pagamento" />

                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>                                    
                                    <Input
                                      type="text"
                                      name="billPayPaymentFormDescription"
                                      description="Descrição"
                                      value={paymentFormDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected} />
                                  </div>

                                  { situation === 1 &&
                                    <div style={(showMenu) ? 
                                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enablePaymentForm}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanPaymentForm}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }
 
                                </div>
                              </div>
                            </div>    
                          
                            { paymentFormCode && 
                              <div style={{marginTop: 10}}>
                                <Titulo description="Parcelas" />

                                <BillPayPortions
                                  jsonCompany={jsonCompany}
                                  paymentFormTerm={paymentFormTerm}
                                  savePortions={savePortions}
                                  returnAdd={returnAdd}
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                          </div>
                        </>
                      }

                      { showCancellation &&
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Cancelamento ( Documento: "+ number + " Parcela: "+ portion + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente cancelar?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={cancel}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_cancel} alt="Cancelar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnCancellation}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showAccount &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione a Conta:</label>
                          </div>

                          <SearchAccount
                            modoSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}                            
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccount} 
                            setReturn={() => setShowAccount(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showReversal &&
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Estorno ( Documento: "+ number + " Parcela: "+ portion + " Valor: "+ 
                            JSON.parse(jBillPayReversal).VALUE + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente estornar?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={reversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_reversal} alt="Estornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Estornar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnReversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                          
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showCancellationReversal &&
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"( Documento: "+ number + " Parcela: "+ portion + " Valor: "+ value + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente reabrir?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={reversalCancellation}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_reversal} alt="Reabrir"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Reabrir</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnCancellationReversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                          
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showPaymentForm &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione a Forma de Pagamento:</label>
                          </div>

                          <SearchPaymentForm
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillPaymentForm} 
                            setReturn={() => setShowPaymentForm(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showSupplier &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Fornecedor:</label>
                          </div>

                          <SearchSupplier
                            modeSearch={true}
                            process={1}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillSupplier} 
                            setReturn={() => setShowSupplier(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showPayment && !showAccount &&
                    
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Pagamento ( Documento: "+ number + " Parcela: "+ portion + " )"} />

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={paid}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_receive} alt="Pagar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pagar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnPayment}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            { showMenu && (width > 890) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billPayReceivingDate"
                                    description="Data do pagamento"
                                    value={paymentDate || setPaymentDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setPaymentDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValueAmountPay"
                                    description="Valor em aberto"
                                    value={valueAmountPay || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor a pagar"
                                    value={valuePaid || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { showMenu && (width <= 890) && (width > 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputMasked
                                      type="text"
                                      name="billPayReceivingDate"
                                      description="Data do pagamento"
                                      value={paymentDate || setPaymentDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                        String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setPaymentDate(event.target.value)} />
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="billPayValueAmountPay"
                                      description="Valor em aberto"
                                      value={valueAmountPay || ""}
                                      maxLength={13}
                                      disabled={true}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor a pagar"
                                    value={valuePaid || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }       

                            { !showMenu && (width > 620) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billPayReceivingDate"
                                    description="Data do pagamento"
                                    value={paymentDate || setPaymentDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setPaymentDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValueAmountPay"
                                    description="Valor em aberto"
                                    value={valueAmountPay || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor a pagar"
                                    value={valuePaid || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { !showMenu && (width <= 620) && (width > 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputMasked
                                      type="text"
                                      name="billPayReceivingDate"
                                      description="Data do pagamento"
                                      value={paymentDate || setPaymentDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                        String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setPaymentDate(event.target.value)} />
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="billPayValueAmountPay"
                                      description="Valor em aberto"
                                      value={valueAmountPay || ""}
                                      maxLength={13}
                                      disabled={true}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor a pagar"
                                    value={valuePaid || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { (width <= 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billPayReceivingDate"
                                    description="Data do pagamento"
                                    value={paymentDate || setPaymentDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setPaymentDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValueAmountPay"
                                    description="Valor em aberto"
                                    value={valueAmountPay || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="billPayValuePaid"
                                    description="Valor a pagar"
                                    value={valuePaid || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            <div style={{marginTop: 10}}>       
                              <Titulo description="Conta" />
                            </div>                            

                            <div style={showMenu ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                              :
                                (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                              <div style={showMenu ?                                  
                                  (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={showMenu ?
                                    (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                  :
                                    (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                  <Input
                                    type="text"
                                    name="BillPayAccountNumber"
                                    description="Número"
                                    value={accountNumber}
                                    maxLength={10}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                  :
                                    (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="BillPayAccountOwner"
                                    description="Titular"
                                    value={accountOwner}
                                    maxLength={50}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccount}>
                                  <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccount}>
                                  <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      { showAccountPlan &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Plano de Conta:</label>
                          </div>

                          <SearchAccountPlan
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccountPlan}
                            setReturn={() => setShowAccountPlan(false)}
                            type={99} 
                            periodic={1} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showDocumentType &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Tipo do Documento:</label>
                          </div>
         
                          <SearchDocumentType
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}                            
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillDocumentType} 
                            setReturn={() => setShowDocumentType(false)}
                            width={width} />
                        </div>
                      }

                    </>
                }
              </>
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BillPay