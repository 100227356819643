import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchServiceItemCnae from "../ServiceItemCnae/Search"
import image_save from "../../Images/Buttons/save.png"
import image_previous from "../../Images/Buttons/previous.png"

const ServiceCodeCnae = ({databaseType, databasePath, jsonCompany, jsonLogin, system, jsonServiceCodeCnae, save, returnAdd, showMenu, 
  width}) => {

  const [originCode, setOriginCode] = useState("")
  const [code, setCode] = useState("")  
  const [serviceCnaeCode, setServiceCnaeCode] = useState("")
  const [description, setDescription] = useState("")
  const [standard, setStandard] = useState(2)
  const [sge, setSge] = useState("")

  useEffect(() => {       
    if (jsonServiceCodeCnae.CODE !== undefined){
      setOriginCode(jsonServiceCodeCnae.CODE)
      setCode(jsonServiceCodeCnae.CODE)
      document.getElementById("serviceCodeCnaeDescription") && document.getElementById("serviceCodeCnaeDescription").focus()      
    } else document.getElementById("serviceCodeCnaeCode") && document.getElementById("serviceCodeCnaeCode").focus()

    setServiceCnaeCode(jsonServiceCodeCnae.SERVICE_CNAE.CODE)
    setDescription(jsonServiceCodeCnae.DESCRIPTION)

    if (jsonServiceCodeCnae.DEFAULT !== undefined) setStandard(parseInt(jsonServiceCodeCnae.DEFAULT))
    else setStandard(2)

    if ((jsonServiceCodeCnae.SGE !== null) && (jsonServiceCodeCnae.SGE !== undefined)) setSge(jsonServiceCodeCnae.SGE)

  }, [jsonServiceCodeCnae])

  const onSave = () => {
    var json = "{"
    if (originCode === "") json += " \"TYPE\": 1,"; else json += " \"TYPE\": 2,"
    json += " \"CODE\": \""+ code + "\","
    json += " \"SERVICE_CNAE\": { \"CODE\": \"" + serviceCnaeCode + "\" }"
    if (description !== undefined) json += ", \"DESCRIPTION\": \""+ description +"\""
    else json += ", \"DESCRIPTION\": \"\""
    json += ", \"DEFAULT\": \""+ standard + "\""    
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      <div div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
          width: 147}} onClick={() => onSave()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
          width: 147}} onClick={() => returnAdd()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      { originCode === "" ? <b>Inclusão:</b> : <b>Alteração:</b>  }

      <div style={(showMenu) ?
          (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
        :
          (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
        <div style={(showMenu) ? 
            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
            : (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
            : {display: "flex", flexDirection: "row", flexGrow: 1}
          :
            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
            : {display: "flex", flexDirection: "row", flexGrow: 1}}>
          <div style={(showMenu) ?
              (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}
              : (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}
            :
              (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}}>

            <Input
              type="text"
              name="serviceCodeCnaeCode"
              description="Código"
              value={code || ""}
              maxLength={20}
              disabled={(originCode === "") ? false : true}
              require={false}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setCode(event.target.value)} />
          </div>
          <div style={(showMenu) ? 
              (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
              : (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
            : 
              (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>

            <Input
              type="text"
              name="serviceCodeCnaeDescription"
              description="Descrição"
              value={description}
              maxLength={255}
              disabled={false}
              require={false}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setDescription(event.target.value)} />
          </div>
        </div>
        <div style={(showMenu) ?
            (width < 1280) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
            : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
          :
            (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
            : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
          <Select
            name="serviceCodeCnaeStandard"
            description="Padrão"
            value={standard || 2}
            require={false}
            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
              ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
            image={true}
            typeInput={2}
            onSelect={setFieldSelected}
            set={setStandard} />
        </div>
      </div>

      { (originCode !== "") &&
        <div style={{marginTop: 10}}>
          <br/>
          <Titulo description="Item do CNAE"/>
          <SearchServiceItemCnae
            modeSearch={false}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonServiceCodeCnae={jsonServiceCodeCnae}
            jsonCompany={jsonCompany}
            jsonLogin={jsonLogin}
            sistema={system} 
            showMenu={showMenu}
            width={width} />
        </div>
      }

    </div>
  )
}

export default ServiceCodeCnae