import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Footer from "../../../Components/Footer"
import Header from "../../../Components/Header"
import Input from "../../../Components/Input"
import Select from "../../../Components/Select"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_save from "../../../Images/Buttons/save.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SelectTerminal = ({databaseType, databasePath, jsonLogin, jsonCompany, system, terminal, setTerminal, setSelected, setReturnMain, logoff,
  height, width}) => {

  const [description, setDescription] = useState("")
  
  const [terminalCode, setTerminalCode] = useState(0)
  const [terminalDescription, setTerminalDescription] = useState("")
  const [optionsTerminal, setOptionsTerminal] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [showChange, setShowChange] = useState(false)  
  const [showMenu, setShowMenu] = useState(true)  

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)
  const [updatePage, setUpdatePage] = useState(0)

  useEffect(() => {
    if (firstAccess){
      let returnJson = []
      let jTerminal = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }, \"SITUATION\": 1, \"SEARCH_TYPE\": 1 }"
 
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")
  
      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jTerminal)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
  
      axios.post(BASE_URL + "TERMINAL/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de terminais. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          alert(returnJson.ERROR)
          logoff()
          return
        }
  
        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setLoading("")

          if (terminal.ID !== undefined){
            localStorage.removeItem("terminal")
            setTerminal({})
          }

          return
        }
        else{
          returnJson.WARNING && setWarning(returnJson.WARNING)
        
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", jTerminal)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", 0)
          formData.append("QUANTITY_RECORDS", tRecord)
    
          axios.post(BASE_URL + "TERMINAL/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar as terminais. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              alert(returnJson.ERROR)
              logoff()
              return
            }
    
            let optionsTerminal = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
            returnJson.OBJECT && 
              returnJson.OBJECT.map(it => (
                optionsTerminal += ", {\"ID\": \""+ it.ID +"\", \"VALUE\": \""+ it.DESCRIPTION +"\"}"
              ))
            optionsTerminal += "]}"
    
            setOptionsTerminal(optionsTerminal)

            if (terminal.ID !== undefined){
              let id = ""

              returnJson &&
                returnJson.OBJECT.map(it => (
                  (parseInt(it.ID) === parseInt(terminal.ID)) ? id = it.ID : ""
                ))

              let description = ""
      
              returnJson &&
                returnJson.OBJECT.map(it => (
                  (parseInt(it.ID) === parseInt(terminal.ID)) ? description = it.DESCRIPTION : ""
                ))

              if (id !== ""){
                setTerminalCode(parseInt(id))
                setTerminalDescription(description)
              }
              else 
              {
                setTerminalCode(0)
                setTerminalDescription("")

                localStorage.removeItem("terminal")
                setTerminal({})
              }
            }
    
            setResult(returnJson.OBJECT)

            setLoading("")
            setFirstAccess(false)
          })
        }
      })
    }

    if (!showChange) document.getElementById("selectTerminalTerminalCode") && document.getElementById("selectTerminalTerminalCode").focus()
    else document.getElementById("selectTerminalDescription") && document.getElementById("selectTerminalDescription").focus()

  }, [updatePage])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    setDescription("")
    setShowChange(true)

    setUpdatePage(updatePage + 1)
  }

  const clean = () => {
    setDescription("")
    setUpdatePage(updatePage + 1)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")

    setShowChange(false)
  }

  const save = () => {
    let returnJson = []
    let jsonTerminal_New = {}

    jsonTerminal_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    jsonTerminal_New += ", \"DESCRIPTION\": \""+ description + "\", \"SITUATION\": 1 }"

    setLoading("Aguarde incluindo o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonTerminal_New)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "TERMINAL/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível incluir o terminal. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("selectTerminalDescription") && document.getElementById("selectTerminalDescription").focus()
        return
      }

      returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Inclusão efetuada com sucesso!")   
      
      const jsonTerm = JSON.parse("{ \"ID\": "+ returnJson.OBJECT.ID +", \"DESCRIPTION\": \""+ returnJson.OBJECT.DESCRIPTION +"\" }")
      setTerminal(jsonTerm)
      setSelected(jsonTerm)

      setLoading("")
    })
  }

  const select = () => {
    if (parseInt(terminalCode) == 0)
    {
      setError("É necessário informar ou cadastrar um terminal.")
      return
    }

    const term = "{ \"ID\": "+ terminalCode +", \"DESCRIPTION\": \""+ terminalDescription +"\" }"
    setSelected(JSON.parse(term))
  }

  const selectTerminal = (item) => {
    if (item == "0"){
      setTerminalCode(0)
      setTerminalDescription("")
    }
    else 
    {
      result &&
        result.map(it => (
          (parseInt(it.ID) === parseInt(item)) ? setTerminalDescription(it.DESCRIPTION) : ""
        ))

      setTerminalCode(item)
    }    
  }

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
        <Header
          title="Terminal"
          titleSearch=""
          showSearch={false}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          width={width} />

        { loading ?
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center", marginTop: 10,
              marginBottom: 10}}>
              <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15, textAlign: "center"}}>{loading}</label>
            </div>
          :
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

              { !showChange ?
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={add}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                        marginLeft: 5, width: 147}} onClick={(terminal.ID === undefined) ? () => logoff() : () => setReturnMain() }>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      { warning &&
                        <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                        </div>
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                        </div>
                      }

                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}> 
                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                          <Select
                            name="selectTerminalTerminalCode"
                            description="Terminal"
                            value={terminalCode || 0}
                            require={false}
                            options={JSON.parse(optionsTerminal)}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={selectTerminal} />
                        </div>
                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10}}>
                          <div style={{display: "flex", alignItems: "flex-end"}}>
                            <img
                              style={{height: 42, width: 42}}
                              name="selectTerminalSelect"
                              src={image_select} 
                              alt="Pesquisar" 
                              onClick={(() => select(true))} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                        marginTop: 10, marginLeft: 5, width: 147}} onClick={() => save()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                          paddingRight: 10,  paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={clean}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={previousAdd}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>
                    
                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      { warning &&
                        <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                        </div>
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                        </div>
                      }

                      <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="selectTerminalDescription"
                            description="Descrição"
                            value={description}
                            maxLength={30}
                            disabled={false}
                            require={true}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setDescription(event.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
              }

            </div>
        }
      </div>

      <Footer/>
    </div>
  )
}

export default SelectTerminal