import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Titulo from "../../Components/Titulo"
import SearchSupplier from "./Search"
import SearchSupplierPhone from "../SupplierPhone/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

////
// process: 1 - Parent // Matriz 2 - Bill Pay // Conta Pagar
////

const Supplier = ({modeSearch, process, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [corporateName, setCorporateName] = useState("")
  const [fantasyName, setFantasyName] = useState("")
  const [indIE, setIndIE] = useState(9)
  const [IE, setIE] = useState("")
  const [IM, setIM] = useState("")

  const [addressRelease, setAddressRelease] = useState(false)

  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [cityIBGE, setCityIBGE] = useState("")
  const [uf, setUf] = useState("")
  const [ufIBGE, setUfIBGE] = useState("")    
  const [countryCode, setCountryCode] = useState(0)
  const [countryDescription, setCountryDescription] = useState("")
  const [countryIBGE, setCountryIBGE] = useState("")

  const [responsible, setResponsible] = useState("")
  const [email, setEmail] = useState("")
  const [homePage, setHomePage] = useState("")
  const [representative, setRepresentative] = useState(2)
  const [cstCsosn, setCstCsosn] = useState(1)
  const [suframa, setSuframa] = useState("")
  const [observation, setObservation] = useState("")
  const [sge, setSge] = useState("")

  const [national, setNational] = useState(true)
  const [listCountry, setListCountry] = useState([])
  const [optionsCountries, setOptionsCountries] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [jSupplier, setJSupplier] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (firstAcess){
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "COUNTRY/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os países. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          let country = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              country += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\"}"
            ))

          country += "]}"

          setListCountry(returnJson)
          setOptionsCountries(country)
          setCountryCode(34)

          setLoading("")
          setFirstAcess(false)
        }
      })
    }
    else{
      (countryDescription === "BRASIL") ? setNational(true) : setNational(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")
      setIndIE(9)
      setIE("ISENTO")
      setIM("")

      setAddressRelease(false)
    
      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setResponsible("")
      setEmail("")
      setHomePage("")
      setRepresentative(2)
      setCstCsosn(1)
      setSuframa("")
      setObservation("")
      setSge("")
    }
    else {
      setCpfCnpj(jSupplier.CPF_CNPJ)
      setCorporateName(jSupplier.CORPORATE_NAME)
      setFantasyName(jSupplier.FANTASY_NAME)
      setIndIE(jSupplier.IND_IE)
      setIE(jSupplier.IE)

      if ((jSupplier.IM !== null) && (jSupplier.IM !== undefined)) setIM(jSupplier.IM)
      if ((jSupplier.CEP !== null) && (jSupplier.CEP !== undefined)) setCep(jSupplier.CEP)

      setAddress(jSupplier.ADDRESS)
      setNumber(jSupplier.NUMBER)

      if ((jSupplier.COMPLEMENT !== null) && (jSupplier.COMPLEMENT !== undefined)) 
        setComplement(jSupplier.COMPLEMENT)

      setNeighborhood(jSupplier.NEIGHBORHOOD)
      setCity(jSupplier.CITY)
      setCityIBGE(jSupplier.CITY_IBGE)
      setUf(jSupplier.UF)
      setUfIBGE(jSupplier.UF_IBGE)

      if (jSupplier.COUNTRY_DESCRIPTION === "BRASIL") setNational(true)
      else setNational(false)

      let codeP = 0;
      listCountry.OBJECT.map(item => (
        (item.NAME === jSupplier.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
      ))

      setCountryCode(codeP)
      setCountryDescription(jSupplier.COUNTRY_DESCRIPTION)
      setCountryIBGE(jSupplier.COUNTRY_IBGE)

      if ((jSupplier.RESPONSIBLE_NAME !== null) && (jSupplier.RESPONSIBLE_NAME !== undefined)) 
        setResponsible(jSupplier.RESPONSIBLE_NAME)

      if ((jSupplier.EMAIL !== null) && (jSupplier.EMAIL !== undefined)) setEmail(jSupplier.EMAIL)

      if ((jSupplier.HOME_PAGE !== null) && (jSupplier.HOME_PAGE !== undefined)) 
        setHomePage(jSupplier.HOME_PAGE)

      setRepresentative(jSupplier.REPRESENTATIVE)
      setCstCsosn(jSupplier.CST_CSOSN)

      if ((jSupplier.SUFRAMA !== null) && (jSupplier.SUFRAMA !== undefined)) setSuframa(jSupplier.SUFRAMA)

      if ((jSupplier.OBSERVATION !== null) && (jSupplier.OBSERVATION !== undefined)) 
        setObservation(jSupplier.OBSERVATION)

      if ((jSupplier.SGE !== null) && (jSupplier.SGE !== undefined)) setSge(jSupplier.SGE)
    }

    document.getElementById("supplierCpfCnpj") && document.getElementById("supplierCpfCnpj").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, code, jSupplier, countryDescription, listCountry.OBJECT, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")
      setIndIE(9)
      setIE("ISENTO")
      setIM("")

      setAddressRelease(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
      setNational(true)
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIBGE(1058)

      setResponsible("")
      setEmail("")
      setHomePage("")
      setRepresentative(2)
      setCstCsosn(1)
      setSuframa("")
      setObservation("")
      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const clearAddress = () => {
    setCep("")
    setAddress("")
    setNumber("")
    setComplement("")
    setNeighborhood("")
    setCity("")
    setCityIBGE("")
    setUf("")
    setUfIBGE("")

    setAddressRelease(false)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonSupplier_New = {}

    jsonSupplier_New = "{"

    if (((code !== undefined) && (code !== null)) && (code !== ""))
    {
      jsonSupplier_New += " \"ID\": \""+ code +"\""
      jsonSupplier_New += ", \"CPF_CNPJ\": \""+ cpfCnpj +"\""
    } else jsonSupplier_New += " \"CPF_CNPJ\": \""+ cpfCnpj +"\""

    jsonSupplier_New += ", \"CORPORATE_NAME\": \""+ corporateName +"\""

    if (((fantasyName !== undefined) && (fantasyName !== null)) && (fantasyName !== ""))
      jsonSupplier_New += ", \"FANTASY_NAME\": \""+ fantasyName +"\""
    else jsonSupplier_New += ", \"FANTASY_NAME\": \""+ corporateName +"\""

    jsonSupplier_New += ", \"IND_IE\": "+ indIE

    if (((IE !== undefined) && (IE !== null)) && (IE !== "")) jsonSupplier_New += ", \"IE\": \""+ IE +"\""
    else jsonSupplier_New += ", \"IE\": \"ISENTO\""

    if (((IM !== undefined) && (IM !== null)) && (IM !== "")) jsonSupplier_New += ", \"IM\": \""+ IM +"\""
    if (((cep !== undefined) && (cep !== null)) && (cep !== "")) jsonSupplier_New += ", \"CEP\": "+ cep

    jsonSupplier_New += ", \"ADDRESS\": \""+ address +"\""
    jsonSupplier_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== "")) jsonSupplier_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonSupplier_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonSupplier_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIBGE !== undefined) && (cityIBGE !== null)) && (cityIBGE !== "")) jsonSupplier_New += ", \"CITY_IBGE\": "+ cityIBGE
    } else jsonSupplier_New += ", \"CITY_IBGE\": "+ 9999999

    jsonSupplier_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIBGE !== undefined) && (ufIBGE !== null)) && (ufIBGE !== "")) 
      jsonSupplier_New += ", \"UF_IBGE\": "+ ufIBGE
    } else jsonSupplier_New += ", \"UF_IBGE\": "+ 99

    jsonSupplier_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonSupplier_New += ", \"COUNTRY_IBGE\": "+ countryIBGE
    else jsonSupplier_New += ", \"COUNTRY_IBGE\": "+ 9999

    if (((responsible !== undefined) && (responsible !== null)) && (responsible !== ""))
      jsonSupplier_New += ", \"RESPONSIBLE_NAME\": \""+ responsible +"\""
      
    if (((email !== undefined) && (email !== null)) && (email !== "")) jsonSupplier_New += ", \"EMAIL\": \""+ email +"\""
    if (((homePage !== undefined) && (homePage !== null)) && (homePage !== "")) jsonSupplier_New += ", \"HOME_PAGE\": \""+ homePage +"\""
    if (representative !== 0) jsonSupplier_New += ", \"REPRESENTATIVE\": "+ representative; else jsonSupplier_New += ", \"REPRESENTATIVE\": 2"
    if (cstCsosn !== 0) jsonSupplier_New += ", \"CST_CSOSN\": "+ cstCsosn; else jsonSupplier_New += ", \"CST_CSOSN\": 1"
    if (((suframa !== undefined) && (suframa !== null)) && (suframa !== "")) jsonSupplier_New += ", \"SUFRAMA\": \""+ suframa +"\""

    if (((observation !== undefined) && (observation !== null)) && (observation !== ""))
      jsonSupplier_New += ", \"OBSERVATION\": \""+ observation +"\""

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonSupplier_New += ", \"SGE\": "+ sge
    jsonSupplier_New += " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonSupplier_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "SUPPLIER/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o fornecedor. ("+ response +")\"}") })

      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("supplierCpfCnpj") && document.getElementById("supplierCpfCnpj").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJSupplier(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jSupplier))
      formData.append("JSON_NEW_1", jsonSupplier_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "SUPPLIER/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o fornecedor. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("supplierCpfCnpj") && document.getElementById("supplierCpfCnpj").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJSupplier(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const searchCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setWarning("")
      setError("É necessário informar o CEP.")

      return
    }

    setLoading("Aguarde buscando o CEP...")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {
      if (response.data.erro !== "true") {
        setWarning("")
        setError("")

        if ((response.data.logradouro === '') && (response.data.bairro === '')) setAddressRelease(true)
        else setAddressRelease(false)

        setAddress(response.data.logradouro)
        setNeighborhood(response.data.bairro)
        setCity(response.data.localidade)
        setCityIBGE(response.data.ibge)
        setUf(response.data.uf)

        if (response.data.uf === "RO") setUfIBGE(11)
        if (response.data.uf === "AC") setUfIBGE(12)
        if (response.data.uf === "AM") setUfIBGE(13)
        if (response.data.uf === "RR") setUfIBGE(14)
        if (response.data.uf === "PA") setUfIBGE(15)
        if (response.data.uf === "AP") setUfIBGE(16)
        if (response.data.uf === "TO") setUfIBGE(17)
        if (response.data.uf === "MA") setUfIBGE(21)
        if (response.data.uf === "PI") setUfIBGE(22)
        if (response.data.uf === "CE") setUfIBGE(23)
        if (response.data.uf === "RN") setUfIBGE(24)
        if (response.data.uf === "PB") setUfIBGE(25)
        if (response.data.uf === "PE") setUfIBGE(26)
        if (response.data.uf === "AL") setUfIBGE(27)
        if (response.data.uf === "SE") setUfIBGE(28)
        if (response.data.uf === "BA") setUfIBGE(29)
        if (response.data.uf === "MG") setUfIBGE(31)
        if (response.data.uf === "ES") setUfIBGE(32)
        if (response.data.uf === "RJ") setUfIBGE(33)
        if (response.data.uf === "SP") setUfIBGE(35)
        if (response.data.uf === "PR") setUfIBGE(41)
        if (response.data.uf === "SC") setUfIBGE(42)
        if (response.data.uf === "RS") setUfIBGE(43)
        if (response.data.uf === "MS") setUfIBGE(50)
        if (response.data.uf === "MT") setUfIBGE(51)
        if (response.data.uf === "GO") setUfIBGE(52)
        if (response.data.uf === "DF") setUfIBGE(53)
      }
    else
    {
      setWarning("")
      setError("CEP não encontrado.")
      alert("CEP não encontrado.")

      setAddress("")
      setNeighborhood("")
      setCity("")
      setCityIBGE("")
      setUf("")
      setUfIBGE("")
    }
    })
    .catch(() => {
      setWarning("")
      setError("Não foi possível obter CEP.")
      alert("Não foi possível obter CEP.")
    }) 
    .finally(() => { setLoading("") })
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJSupplier(event)
    setCode(event.ID)
  }

  const selectCountry = (event) => {
    let itemP = {}
    listCountry.OBJECT.map(item => (
      (item.ID ===  parseInt(event)) ? itemP = item : ""
    ))

    setCountryCode(itemP.ID)
    setCountryDescription(itemP.NAME)
    setCountryIBGE(itemP.IBGE)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Fornecedor"
            titleSearch="Pesquisa dos Fornecedores"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
            <SearchSupplier
              modeSearch={modeSearch}
              process={process}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonLogin={jsonLogin}
              jsonCompany={jsonCompany}
              system={system}
              setAdd={setAdd}
              setSelected={setSelected}
              setReturn={setReturn} 
              showMenu={showMenu}
              width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 350) ? {display: "flex", flexGrow: 1, minWidth: 180 }
                              : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200 }}>

                              <Input
                                type="text"
                                name="supplierCpfCnpj"
                                description="CPF/CNPJ"
                                planceHolder=""
                                value={cpfCnpj}
                                maxLength={14}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCpfCnpj(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(showMenu) ?                            
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 736) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>

                              <Input
                                type="text"
                                name="supplierCode"
                                description="Código"
                                planceHolder=""
                                value={code}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}
                                : (width < 736) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 200, maxWidth: 200}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>

                              <Input
                                type="text"
                                name="supplierCpfCnpj"
                                description="CPF/CNPJ"
                                planceHolder=""
                                value={cpfCnpj}
                                maxLength={14}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCpfCnpj(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="supplierCorporateName"
                              description="Nome/Razão social"
                              planceHolder=""
                              value={corporateName}
                              maxLength={60}
                              disabled={false}
                              require={true}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setCorporateName(event.target.value)} />
                          </div>
                          <div style={(width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180, marginLeft: 10}}>

                            <Input
                              type="text"
                              name="supplierFantasyName"
                              description="Nome fantasia"
                              planceHolder=""
                              value={fantasyName}
                              maxLength={60}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setFantasyName(event.target.value)} />
                          </div>
                        </div>

                        <div style={
                          (showMenu) ?
                            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(showMenu) ?
                              (width < 820) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}
                            :
                              (width < 550) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Select
                                name="supplierIndIE"
                                description="Indicador IE"
                                value={indIE || 0}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Contribuinte ICMS\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Contribuinte Isento\" }, {\"ID\": 9, \"VALUE\": \"Não Contribuinte\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setIndIE} />
                            </div>

                            <div style={(showMenu) ?
                                (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10}
                              : 
                                (width < 550) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="supplierIe"
                                description="IE"
                                planceHolder=""
                                value={IE}
                                maxLength={14}
                                disabled={false}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setIE(event.target.value)} />
                            </div>
                          </div>

                          <div style={
                            (showMenu) ?
                              (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                            :
                              (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="supplierIm"
                                description="IM"
                                planceHolder=""
                                value={IM}
                                maxLength={15}
                                disabled={false}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setIM(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10}
                              :
                                (width < 550) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="supplierSuframa"
                                description="SUFRAMA"
                                planceHolder=""
                                value={suframa}
                                maxLength={9}
                                disabled={false}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setSuframa(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                          <Titulo description="Endereço" />
                        </div>

                        <div style={(showMenu) ?
                            (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                            : {paddingLeft: 10, paddingRight: 10}
                          : 
                            (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                            : {paddingLeft: 10, paddingRight: 10}}>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ?
                                (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={{display: "flex", flexGrow: 1}}>
                                <Select
                                  name="supplierCountryCode"
                                  description="País"
                                  value={countryCode || 0}
                                  disabled={false}
                                  require={false}
                                  options={JSON.parse(optionsCountries)}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={selectCountry} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                  : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                :   
                                  (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}                                 
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                <Input
                                  type="text"
                                  name="supplierCep"
                                  description="CEP"
                                  planceHolder=""
                                  value={cep}
                                  maxLength={8}
                                  disabled={false}
                                  require={national ? true : false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCep(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                              : 
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                              { (national && (!addressRelease)) &&
                                <img
                                  style={{height: 42, width: 42, marginLeft: 10}}
                                  name="supplierSearchAddress"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={() => searchCEP()} />
                              }

                              <img
                                style={{height: 42, width: 42, marginLeft: 10}}
                                name="supplierClearAddress"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={clearAddress} />
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="supplierAddress"
                                description="Logradouro"
                                planceHolder=""
                                value={address}
                                maxLength={60}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected} 
                                onChange={(event) => setAddress(event.target.value)} />
                            </div>

                            <div style={(showMenu) ?
                                (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                              :
                                (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                              <div style={(showMenu) ?
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                :
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                <Input
                                  type="text"
                                  name="supplierNumber"
                                  description="Número"
                                  planceHolder=""
                                  value={number}
                                  maxLength={60}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setNumber(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                :
                                  (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                <Input
                                  type="text"
                                  name="supplierComplement"
                                  description="Complemento"
                                  planceHolder=""
                                  value={complement}
                                  maxLength={60}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setComplement(event.target.value)} />
                              </div>
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="supplierNeighborhood"
                                description="Bairro"
                                planceHolder=""
                                value={neighborhood}
                                maxLength={60}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setNeighborhood(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Input
                                type="text"
                                name="supplierCity"
                                description="Cidade"
                                planceHolder=""
                                value={city}
                                maxLength={60}
                                disabled={national ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setCity(event.target.value)} />
                            </div>
                          </div>

                          <div style={(showMenu) ?
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="supplierUf"
                                description="Estado"
                                planceHolder=""
                                value={uf}
                                maxLength={2}
                                disabled={(national && (!addressRelease)) ? true : false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setUf(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  
                              <Input
                                type="text"
                                name="supplierCountryDescription"
                                description="País"
                                planceHolder=""
                                value={countryDescription}
                                maxLength={60}
                                disabled={true}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2} 
                                onSelect={setFieldSelected} 
                                onChange={(event) => setCountryDescription(event.target.value)} />
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 10, width: "100%"}}>
                          <Titulo description="Outras informações" />
                        </div>

                        <div style={
                          (showMenu) ?
                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                          :
                            (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="supplierResponsible"
                              description="Responsável"
                              planceHolder=""
                              value={responsible}
                              maxLength={60}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setResponsible(event.target.value)} />
                          </div>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1,  marginTop: 10}
                              : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1,  marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :                             
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1,  marginTop: 10}
                              : {display: "flex", flexGrow: 0, marginLeft: 10}}>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 180}
                              : 
                                (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 180}}>

                              <Select
                                name="supplierRepresentative"
                                description="Representante"
                                value={representative || 2}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setRepresentative} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1100) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                              : 
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}                              
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="cstCsos"
                                description="CST/CSOSN"
                                value={cstCsosn || 1}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"CST\" }, {\"ID\": 2, \"VALUE\": \"CSOSN\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setCstCsosn} />
                            </div>
                          </div>
                        </div>

                        <div style={
                          (showMenu) ?
                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                          :
                            (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="email"
                              description="Email"
                              planceHolder=""
                              value={email}
                              maxLength={70}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setEmail(event.target.value)} />
                          </div>
                          <div style={(showMenu) ?
                              (width < 1100) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10}
                            :
                              (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                            <Input
                              type="text"
                              name="homePage"
                              description="Home Page"
                              planceHolder=""
                              value={homePage}
                              maxLength={70}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setHomePage(event.target.value)} />
                          </div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="observacoes"
                              description="Observações"
                              planceHolder=""
                              value={observation}
                              maxLength={65535}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setObservation(event.target.value)} />
                          </div>
                        </div>

                        {/* Phones */}
                        { code &&
                          <div>
                            <div style={{marginTop: 10}}>
                              <Titulo description="Telefones" />
                            </div>

                            <SearchSupplierPhone
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonSupplier={jSupplier}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system} 
                              width={width} />
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Supplier