import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DataPicker from "../../Components/DataPicker"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select.png"
import image_ticket from "../../Images/Buttons/ticket.png"

const BilletLot = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, logoff, height, width}) => {
  const [number, setNumber] = useState("")
  const [value, setValue] = useState("")
  const [situation, setSituation] = useState("0")

  const [descriptionDocumentType, setDescriptionDocumentType] = useState("")
  const [descriptionDocumentTypeSelected, setDescriptionDocumentTypeSelected] = useState("")
  const [returnDocumentType, setReturnDocumentType] = useState({})
  const [returnDocumentTypeFilter, setReturnDocumentTypeFilter] = useState({})

  const [descriptionBillPlan, setDescriptionBillPlan] = useState("")
  const [descriptionBillPlanSelected, setDescriptionBillPlanSelected] = useState("")
  const [returnBillPlan, setReturnBillPlan] = useState({})
  const [returnBillPlanFilter, setReturnBillPlanFilter] = useState({})

  const [corporateNameClient, setCorporateNameClient] = useState("")
  const [corporateNameClientSelected, setCorporateNameClientSelected] = useState("")
  const [returnClient, setReturnClient] = useState({})
  const [returnClientFilter, setReturnClientFilter] = useState({})

  const [dateEmissionIni, setDateEmissionIni] = useState(null)
  const [dateEmissionEnd, setDateEmissionEnd] = useState(null)
  const [dateReleaseIni, setDateReleaseIni] = useState(null)
  const [dateReleaseEnd, setDateReleaseEnd] = useState(null)
  const [dateDueIni, setDateDueIni] = useState(null)
  const [dateDueEnd, setDateDueEnd] = useState(null)
  const [totalValue, setTotalValue] = useState(0)
  const [order, setOrder] = useState(1)

  const [billetLotSelected, setBilletLotSelected] = useState([])

  const [showMenu, setShowMenu] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)
  const [showBillPlan, setShowBillPlan] = useState(false)
  const [showClient, setShowClient] = useState(false)
  const [showResult, setShowResult] = useState(false)
   
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)

  const [success, setSuccess] = useState([])
  const [warning, setWarning] = useState([])
  const [error, setError] = useState([])

  const [updatePage, setUpdatePage] = useState(0)
  const [firstAcess, setFirstAcess] = useState(true)

  const [resultBilletConfiguration, setResultBilletConfiguration] = useState({})
  const [optionsBilletConfiguration, setOptionsBilletConfiguration] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")    
  const [billetConfiguration, setBilletConfiguration] = useState({})
  const [billetConfigurationCode, setBilletConfigurationCode] = useState(0)
  const [billetConfigurationDefault, setBilletConfigurationDefault] = useState(0)

  useEffect(() => {
    if (firstAcess){
      let returnJson = []

      ///
      // Upload document types informations // Obtem as informações dos tipos de documentos
      ///
      setLoading("Aguarde buscando as informações dos tipos de documentos...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"SITUATION\": 1 }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)      

      axios.post(BASE_URL + "BILL_RECEIVE/SearchDocumentType", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os tipos de documentos das Contas a Receber. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          alert(returnJson.ERROR)
          setReturn()
          return
        }

        let arrDocumentType = []
        returnJson.OBJECT && returnJson.OBJECT.map(item => (arrDocumentType.push(item)))
        setReturnDocumentType(arrDocumentType)

        let arrDocumentTypeFilter = []
        returnJson.OBJECT && returnJson.OBJECT.map(item => (arrDocumentTypeFilter.push(item)))
        setReturnDocumentTypeFilter(arrDocumentTypeFilter)

        ///
        // Upload bill plan informations
        ///
        setLoading("Aguarde buscando as informações dos planos de conta...")      
        setWarning("")
        setError("")

        axios.post(BASE_URL + "BILL_RECEIVE/SearchAccountPlan", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os planos de contas das Contas a Receber. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            alert(returnJson.ERROR)
            setReturn()
            return
          }

          let arrBillPlan = []
          returnJson.OBJECT && returnJson.OBJECT.map(item => (arrBillPlan.push(item)))
          setReturnBillPlan(arrBillPlan)

          let arrBillPlanFilter = []
          returnJson.OBJECT && returnJson.OBJECT.map(item => (arrBillPlanFilter.push(item)))
          setReturnBillPlanFilter(arrBillPlanFilter)

          ///
          // Upload client informations
          ///
          setLoading("Aguarde buscando as informações dos clientes...")      
          setWarning("")
          setError("")

          axios.post(BASE_URL + "BILL_RECEIVE/SearchClient", formData) 
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os clientes das Contas a Receber. ("+ response +")\" }") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              alert(returnJson.ERROR)
              setReturn()
              return
            }

            let arrClient = []
            returnJson.OBJECT && returnJson.OBJECT.map(item => (arrClient.push(item)))
            setReturnClient(arrClient)

            let arrClientFilter = []
            returnJson.OBJECT && returnJson.OBJECT.map(item => (arrClientFilter.push(item)))
            setReturnClientFilter(arrClientFilter)

            ///
            // Upload billet configuration
            ///            
            setLoading("Aguarde buscando as configurações dos boletos...")
            setWarning("")
            setError("")

            formData.append("DATABASE_TYPE", databaseType)
            formData.append("DATABASE_PATH", databasePath)
            formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": " + jsonLogin.ID + " } }")
            formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
            formData.append("SYSTEM", system)
        
            axios.post(BASE_URL + "BILLET_CONFIGURATION/Search", formData)
            .then((response) => { returnJson = JSON.parse(response.data) })
            .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar as Configurações dos Boletos. ("+ response +")\" }") })
            .finally(() => {
              if (returnJson.SUCCESS === false){
                alert(returnJson.ERROR)
                setReturn()
                return
              }
        
              ////
              // Check for registration // Verifica se há registro
              ////
              if (Array(returnJson.OBJECT).length === 0)
              {
                alert("Nenhuma configuração de boleto encontrada!")
                setReturn()
                return
              }
              setResultBilletConfiguration(returnJson.OBJECT)

              ///
              // Fill listbox // Preenhe listbox
              ///
              let billetConfiguration = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
              returnJson.OBJECT.map(item => (
                billetConfiguration += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
              ))
              billetConfiguration += "]}"
              setOptionsBilletConfiguration(billetConfiguration)

              ////
              // Capture the pattern // Captura o padrão
              ////
              let defaultSetting = {}
              returnJson.OBJECT.map(item => (
                (String(item.DEFAULT) === "1") ? defaultSetting = item : ""
              ))

              setBilletConfiguration(defaultSetting)
              if (Object.keys(defaultSetting).length !== 0){
                setBilletConfigurationCode(defaultSetting.ID)
                setBilletConfigurationDefault(defaultSetting.ID)
              }
                
              setLoading("")
              setFirstAcess(false)
            })
          })
        })
      })      
    }

    if (updatePage){
      search()
      setUpdatePage(false)
    }    
    
    if (showDocumentType){
      let arrDocumentType = []
      if (descriptionDocumentType === "") returnDocumentType && returnDocumentType.map(item => (arrDocumentType.push(item)))
      else 
        returnDocumentType && 
          returnDocumentType.map(item => ((item.DESCRIPTION.indexOf(descriptionDocumentType.toUpperCase()) !== -1) && arrDocumentType.push(item)))

      setReturnDocumentTypeFilter(arrDocumentType)
    }

    if (showBillPlan){
      let arrBillPlan = []
      if (descriptionBillPlan === "") returnBillPlan && returnBillPlan.map(item => (arrBillPlan.push(item)))
      else 
        returnBillPlan && 
          returnBillPlan.map(item => ((item.DESCRIPTION.indexOf(descriptionBillPlan.toUpperCase()) !== -1) && arrBillPlan.push(item)))

      setReturnBillPlanFilter(arrBillPlan)
    }

    if (showClient){
      let arrClient = []  
      if (corporateNameClient === "") returnClient &&returnClient.map(item => ( arrClient.push(item)))
      else 
        returnClient && 
          returnClient.map(item => ((item.CORPORATE_NAME.indexOf(corporateNameClient.toUpperCase()) !== -1) && arrClient.push(item)))

      setReturnClientFilter(arrClient)
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, updatePage, descriptionDocumentType, descriptionBillPlan, corporateNameClient])

  const addBillPlan = () => {
    cleanBillPlan()
    setShowBillPlan(true)
  }

  const addDocumentType = () => {
    cleanDocumentType()
    setShowDocumentType(true)
  }

  const addClient = () => {
    cleanClient()
    setShowClient(true)
  }

  const clean = () => {
    setNumber("")
    setValue("")
    setSituation("0")

    setDateEmissionIni(null)
    setDateEmissionEnd(null)
    setDateReleaseIni(null)
    setDateReleaseEnd(null)
    setDateDueIni(null)
    setDateDueEnd(null)

    setDescriptionDocumentType("")
    setDescriptionDocumentTypeSelected("")
    setDescriptionBillPlan("")
    setDescriptionBillPlanSelected("")
    setCorporateNameClient("")
    setCorporateNameClientSelected("")

    setReturnDocumentTypeFilter(returnDocumentType)
    setReturnBillPlanFilter(returnBillPlan)    
    setReturnClientFilter(returnClient)

    setTotalValue(0)

    setOrder(1)
    setBilletConfigurationCode(billetConfigurationDefault)

    setShowResult(false)
  }

  const cleanBillPlan = () => {
    returnBillPlan && returnBillPlan.map(item => (item.MARKET = false))

    setReturnBillPlanFilter(returnBillPlan)

    setDescriptionBillPlanSelected("")
    setDescriptionBillPlan("")
    setShowBillPlan(false)
  }

  const cleanDocumentType = () => {
    returnDocumentType && returnDocumentType.map(item => (item.MARKET = false))

    setReturnDocumentTypeFilter(returnDocumentType)

    setDescriptionDocumentTypeSelected("")
    setDescriptionDocumentType("")
    setShowDocumentType(false)
  }  

  const cleanClient = () => {
    returnClient && returnClient.map(item => (item.MARKET = false))

    setReturnClientFilter(returnClient)

    setCorporateNameClientSelected("")
    setCorporateNameClient("")
    setShowClient(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) {
      return ""
    }

    if (value === ".") {
      return value = "0."
    }
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }  

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const markAllBillPlan = () => {
    let selected = ""

    returnBillPlan && returnBillPlan.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDescriptionBillPlanSelected(selected)
    setShowBillPlan(false)
  }

  const markAllDocumentType = () => {
    let selected = ""

    returnDocumentType && returnDocumentType.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDescriptionDocumentTypeSelected(selected)
    setShowDocumentType(false)
  }  

  const markAllClient = () => {
    let selected = ""

    returnClient && returnClient.map(item => (
      (selected === "") ? selected = (item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
      : selected += ("," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
    ))
    
    setCorporateNameClientSelected(selected)
    setShowClient(false)
  }

  const markAllBilletLot = () => {
    result.OBJECT && result.OBJECT.map(item => item.MARKET = true)

    let selected = []
    result.OBJECT && result.OBJECT.map(item => selected.push(item))
    setBilletLotSelected(selected)
  }

  const markOffAllBilletLot = () => {
    result.OBJECT && result.OBJECT.map(item => item.MARKET = false)
    setBilletLotSelected([])
  }

  const onSelectConfiguration = (code) => {
    if (String(code) !== "0") {
      let configurationDefault = {}
      resultBilletConfiguration.map(item => (
        (item.ID === Number(code)) ? configurationDefault = item : ""
      ))

      setBilletConfiguration(configurationDefault)
      setBilletConfigurationCode(code)
    }
    else setBilletConfigurationCode(0)
  }  

  const returnSearch = () => {
    setNumber("")
    setValue("")
    setSituation("0")
  
    setDescriptionDocumentType("")
    setDescriptionDocumentTypeSelected("")
    setReturnDocumentTypeFilter(returnDocumentType)
  
    setDescriptionBillPlan("")
    setDescriptionBillPlanSelected("")
    setReturnBillPlanFilter(returnBillPlan)
  
    setCorporateNameClient("")
    setCorporateNameClientSelected("")
    setReturnClientFilter(returnClient)
  
    setDateEmissionIni(null)
    setDateEmissionEnd(null)
    setDateReleaseIni(null)
    setDateReleaseEnd(null)
    setDateDueIni(null)
    setDateDueEnd(null)
    setTotalValue(0)
    setOrder(1)
  
    setBilletLotSelected([])
  
    setShowDocumentType(false)  
    setShowBillPlan(false)
    setShowClient(false)  
    setShowResult(false)
     
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")

    setShowMessage(false)
  }

  const returnMessage = (result) => {
    return result && result.map(item => 
      (item !== "") && 
        <div style={{marginTop: 10, marginBottom: 10, marginLeft: "5%", marginRight: "5%"}}>
          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{item}</label>
        </div>
      )
  }

  const search = () => {
    let returnJson = []

    let search = false
    if (number !== "") search = true
    if (!search) if (value !== "") search = true
    if (!search) if (situation !== "0") search = true
    if (!search) if (descriptionDocumentTypeSelected !== "") search = true
    if (!search) if (descriptionBillPlanSelected !== "") search = true
    if (!search) if (corporateNameClientSelected !== "") search = true
    if (!search) if ((validateDate(dateEmissionIni)) && (validateDate(dateEmissionEnd))) search = true
    if (!search) if ((validateDate(dateReleaseIni)) && (validateDate(dateReleaseEnd))) search = true
    if (!search) if ((validateDate(dateDueIni)) && (validateDate(dateDueEnd))) search = true

    if (!search) 
    {
      setLoading(null)
      setWarning("")
      setError("")

      setShowDocumentType(false)
      setShowBillPlan(false)
      setShowClient(false)
      setShowResult(false)
    }

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let jBilletLot = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +" }"
      if (number !== "") jBilletLot += ", \"NUMBER\": \""+ number + "\""
      if (value !== "") jBilletLot += ", \"VALUE\": "+ value
      if (descriptionDocumentTypeSelected !== "") jBilletLot += ", \"DOCUMENT_TYPES\": \""+ descriptionDocumentTypeSelected +"\""
      if (descriptionBillPlanSelected !== "") jBilletLot += ", \"ACCOUNTS_PLAN\": \""+ descriptionBillPlanSelected +"\""      
      if (corporateNameClientSelected !== "") jBilletLot += ", \"CLIENTS\": \""+ corporateNameClientSelected +"\""

      if (validateDate(dateEmissionIni) && validateDate(dateEmissionEnd)){
        const emissionI = dateEmissionIni.toLocaleString()
        const emissionE = dateEmissionEnd.toLocaleString()

        const emissionIni = String(emissionI).substr(6,4) + "-" + String(emissionI).substr(3,2) + "-" + String(emissionI).substr(0,2) + "T00:00:00";
        const emissionEnd = String(emissionE).substr(6,4) + "-" + String(emissionE).substr(3,2) + "-" + String(emissionE).substr(0,2) + "T23:59:59";
        jBilletLot += ", \"EMISSION_DATE_START\": \""+ emissionIni + "\""
        jBilletLot += ", \"EMISSION_DATE_END\": \""+ emissionEnd + "\""
      }

      if (validateDate(dateReleaseIni) && validateDate(dateReleaseEnd)){
        const releaseI = dateReleaseIni.toLocaleString()
        const releaseE = dateReleaseEnd.toLocaleString()

        const releaseIni = String(releaseI).substr(6,4) + "-" + String(releaseI).substr(3,2) + "-" + String(releaseI).substr(0,2) + "T00:00:00";
        const releaseEnd = String(releaseE).substr(6,4) + "-" + String(releaseE).substr(3,2) + "-" + String(releaseE).substr(0,2) + "T23:59:59";
        jBilletLot += ", \"RELEASE_DATE_START\": \""+ releaseIni + "\""
        jBilletLot += ", \"RELEASE_DATE_END\": \""+ releaseEnd + "\""
      }

      if (validateDate(dateDueIni) && validateDate(dateDueEnd)){
        const dueI = dateDueIni.toLocaleString()
        const dueE = dateDueEnd.toLocaleString()

        const dueIni = String(dueI).substr(6,4) + "-" + String(dueI).substr(3,2) + "-" + String(dueI).substr(0,2) + "T00:00:00";
        const dueEnd = String(dueE).substr(6,4) + "-" + String(dueE).substr(3,2) + "-" + String(dueE).substr(0,2) + "T23:59:59";
        jBilletLot += ", \"DUE_DATE_START\": \""+ dueIni + "\""
        jBilletLot += ", \"DUE_DATE_END\": \""+ dueEnd + "\""
      }

      jBilletLot += " }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jBilletLot)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("ORDER", order)

      axios.post(BASE_URL + "BILL_RECEIVE/SearchBilletLot", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as Contas a Receber do Lote. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setWarning("")
          setError(returnJson.ERROR)
          setLoading("")

          setShowDocumentType(false)
          setShowBillPlan(false)
          setShowClient(false)
          setShowResult(false)
          
          return
        }

        let valueOpen = 0
        returnJson.OBJECT && returnJson.OBJECT.map(item => (valueOpen += item.VALUE));
        setTotalValue(valueOpen)

        setResult(returnJson)

        setShowDocumentType(false)
        setShowBillPlan(false)
        setShowClient(false)
        setShowResult(true)

        setLoading("")
      })
    }
    else setError("Nenhum filtro informado!")
  }

  const selectedBillPlan = () => {
    setShowBillPlan(false)
  }

  const selectedClient = () => {
    setShowClient(false)
  }

  const selectedDocumentType = () => {
    setShowDocumentType(false)
  }

  const selectedBilletLot = async() => {
    let returnJson = []

    if (String(billetConfigurationCode) === "0"){
      setWarning("É necessário selecionar a configuração do boleto.")
      return
    }

    if (billetLotSelected.length === 0){
      setWarning("É necessário selecionar pelo menos uma conta a receber.")
      return
    }

    setLoading("Gerando os boletos das contas a receber, Aguarde....")
    setSuccess("")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", JSON.stringify(billetLotSelected))
    formData.append("JSON_NEW_2", JSON.stringify(billetConfiguration))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)   

    axios.post(BASE_URL + "BILLET/InsertBilletLot", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível inserir o Lote de Boletos. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setWarning("")
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      if (returnJson.OBJECT !== null) setSuccess(String(returnJson.OBJECT).split("#"))
      if (returnJson.WARNING !== null) setWarning(String(returnJson.WARNING).split("#"))
      if (returnJson.ERROR !== null) setError(String(returnJson.ERROR).split("#"))

      setShowMessage(true)
      setLoading("")
    })
  }

  const setFieldSelected = () => {
  }  

  const updateShowBillPlan = (id) => {
    returnBillPlan && returnBillPlan.map(item => ((item.ID === id) ? item.MARKET = !item.MARKET : ""))

    let selected = ""
    returnBillPlan && returnBillPlan.map(item => (
      (item.MARKET) ?
        (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
        : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
      ))
    setDescriptionBillPlanSelected(selected)
  }

  const updateShowBillLot = (item) => {
    result.OBJECT && result.OBJECT.map(it => ((it.ID === item.ID) ? item.MARKET = !item.MARKET : ""))

    let selected = []
    result.OBJECT && result.OBJECT.map(it => ((it.MARKET) && selected.push(it)))
    setBilletLotSelected(selected);
  }

  const updateShowDocumentType = (id) => {
    returnDocumentType && returnDocumentType.map(item => ((item.ID === id) ? item.MARKET = !item.MARKET : ""))

    let selected = ""
    returnDocumentType && returnDocumentType.map(item => 
      ((item.MARKET) ? 
        (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
        : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
      ))
    setDescriptionDocumentTypeSelected(selected)
  }

  const updateShowClient = (id) => {
    returnClient && returnClient.map(item => ((item.ID === id) ? item.MARKET = !item.MARKET : ""))

    let selected = ""
    returnClient && returnClient.map(item => (
      (item.MARKET) ?
        (selected === "") ? selected = item.CPF_CNPJ + "-" + item.CORPORATE_NAME
        : selected = selected + "," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME
      : ""
      ))
    setCorporateNameClientSelected(selected)
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Lote do Boleto"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { loading ?
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
                <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
              </div>
            :
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

                { showMessage ?
                    <>
                      {/* Buttons */}
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 167}} onClick={() => returnSearch()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>
                      {/* Buttons */}

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      {returnMessage(success)}
                      {returnMessage(warning)}
                      {returnMessage(error)}
                    </>
                  :
                    <>
                      {/* Buttons */}
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 167}} onClick={search}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                          </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 167}} onClick={clean}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                          </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                          marginLeft: 5, width: 167}} onClick={setReturn}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                            paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                          </div>
                        </div>
                      </div>
                      {/* Buttons */}

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>

                        { !result.OBJECT &&
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        }

                        <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}> 
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                            marginBottom: 10, marginLeft: 0}}>
                            <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                          </div>

                          <div style={(showMenu) ?
                              (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={showMenu ?
                                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 450}
                              :
                                (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 450}}>
                              <Input
                                type="search"
                                name="billetLotNumber"
                                description="Número"
                                value={number}
                                maxLength={50}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setNumber(event.target.value)} />
                            </div>

                            <div style={showMenu ?
                                (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                              :
                                (width < 710) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                              <InputNumeric
                                type="text"
                                name="billetLotValue"
                                description="Valor"
                                value={value || ""}
                                maxLength={13}
                                disabled={false}
                                require={false}
                                negative={false} 
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={handleValue} />
                            </div>
                          </div>

                          { showMenu && (width > 1340) &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                <b>Data Emissão</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateEmissionIni"
                                      description="Inicial"
                                      value={dateEmissionIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateEmissionIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateEmissionEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateEmissionEnd"
                                      description="Final"
                                      value={dateEmissionEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateEmissionEnd(event)} 
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateEmissionIni} />
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                <b>Data Lançamento</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateReleaseIni"
                                      description="Inicial"
                                      value={dateReleaseIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateReleaseIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateReleaseEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateReleaseEnd"
                                      description="Final"
                                      value={dateReleaseEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateReleaseEnd(event)} 
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateReleaseIni} />
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                <b>Data Vencimento</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueIni"
                                      description="Inicial"
                                      value={dateDueIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateDueEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueEnd"
                                      description="Final"
                                      value={dateDueEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueEnd(event)}
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateDueIni} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          { showMenu && (width <= 1340) &&
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                              <div style={(width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={(width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <b>Data Emissão</b>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateEmissionIni"
                                        description="Inicial"
                                        value={dateEmissionIni}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateEmissionIni(event)} 
                                        intervalo={true}
                                        inicial={true}
                                        endDate={dateEmissionEnd} />
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                        minWidth: 25, maxWidth: 25}}>
                                      <label style={{fontWeight: 600}}>à</label>
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateEmissionEnd"
                                        description="Final"
                                        value={dateEmissionEnd}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateEmissionEnd(event)} 
                                        intervalo={true}
                                        inicial={false}
                                        startDate={dateEmissionIni} />
                                    </div>
                                  </div>
                                </div>

                                <div style={(width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                  <b>Data Lançamento</b>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateReleaseIni"
                                        description="Inicial"
                                        value={dateReleaseIni}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateReleaseIni(event)} 
                                        intervalo={true}
                                        inicial={true}
                                        endDate={dateReleaseEnd} />
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                        minWidth: 25, maxWidth: 25}}>
                                      <label style={{fontWeight: 600}}>à</label>
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateReleaseEnd"
                                        description="Final"
                                        value={dateReleaseEnd}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateReleaseEnd(event)} 
                                        intervalo={true}
                                        inicial={false}
                                        startDate={dateReleaseIni} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                <b>Data Vencimento</b>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueIni"
                                      description="Inicial"
                                      value={dateDueIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateDueEnd} />
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                      minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueEnd"
                                      description="Final"
                                      value={dateDueEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueEnd(event)}
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateDueIni} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          { !showMenu && (width > 1070) &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                <b>Data Emissão</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateEmissionIni"
                                      description="Inicial"
                                      value={dateEmissionIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateEmissionIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateEmissionEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateEmissionEnd"
                                      description="Final"
                                      value={dateEmissionEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateEmissionEnd(event)} 
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateEmissionIni} />
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                <b>Data Lançamento</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateReleaseIni"
                                      description="Inicial"
                                      value={dateReleaseIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateReleaseIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateReleaseEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateReleaseEnd"
                                      description="Final"
                                      value={dateReleaseEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateReleaseEnd(event)} 
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateReleaseIni} />
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                <b>Data Vencimento</b>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueIni"
                                      description="Inicial"
                                      value={dateDueIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateDueEnd} />
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                    marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueEnd"
                                      description="Final"
                                      value={dateDueEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueEnd(event)}
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateDueIni} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          { !showMenu && (width <= 1070) &&
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                              <div style={(width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={(width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <b>Data Emissão</b>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateEmissionIni"
                                        description="Inicial"
                                        value={dateEmissionIni}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateEmissionIni(event)} 
                                        intervalo={true}
                                        inicial={true}
                                        endDate={dateEmissionEnd} />
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                        minWidth: 25, maxWidth: 25}}>
                                      <label style={{fontWeight: 600}}>à</label>
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateEmissionEnd"
                                        description="Final"
                                        value={dateEmissionEnd}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateEmissionEnd(event)} 
                                        intervalo={true}
                                        inicial={false}
                                        startDate={dateEmissionIni} />
                                    </div>
                                  </div>
                                </div>

                                <div style={(width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                  <b>Data Lançamento</b>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateReleaseIni"
                                        description="Inicial"
                                        value={dateReleaseIni}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateReleaseIni(event)} 
                                        intervalo={true}
                                        inicial={true}
                                        endDate={dateReleaseEnd} />
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                        minWidth: 25, maxWidth: 25}}>
                                      <label style={{fontWeight: 600}}>à</label>
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                      <DataPicker
                                        name="billetLotDateReleaseEnd"
                                        description="Final"
                                        value={dateReleaseEnd}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDateReleaseEnd(event)} 
                                        intervalo={true}
                                        inicial={false}
                                        startDate={dateReleaseIni} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                <b>Data Vencimento</b>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueIni"
                                      description="Inicial"
                                      value={dateDueIni}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueIni(event)} 
                                      intervalo={true}
                                      inicial={true}
                                      endDate={dateDueEnd} />
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",  marginTop: 20, 
                                      minWidth: 25, maxWidth: 25}}>
                                    <label style={{fontWeight: 600}}>à</label>
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 150}}>
                                    <DataPicker
                                      name="billetLotDateDueEnd"
                                      description="Final"
                                      value={dateDueEnd}
                                      require={false}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDateDueEnd(event)}
                                      intervalo={true}
                                      inicial={false}
                                      startDate={dateDueIni} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }

                          <div style={(showMenu) ? 
                              (width < 1870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 1600) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={showMenu ? 
                                (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 190}
                              : 
                                (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 190}}>

                              <Input
                                type="search"
                                name="billetLotDescricaoTipoDocumentoSelecionado"
                                description="Tipos de Documentos Selecionados:"
                                value={descriptionDocumentTypeSelected}
                                maxLength={6000}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2} 
                                onSelect={setFieldSelected} />

                              <div style={showMenu ? 
                                  (width < 1110) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}
                                : 
                                  (width < 840) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}}>

                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_add} alt="Adicionar" 
                                  onClick={(() => { addDocumentType() })}/>

                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_clean} alt="Limpar" 
                                  onClick={(() => { cleanDocumentType() })}/>
                              </div>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1870) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, minWidth: 190}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginLeft: 10, minWidth: 190}
                              : 
                                (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1600) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, minWidth: 190}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginLeft: 10, minWidth: 190}}>

                              <Input
                                type="search"
                                name="billetLotDescriptionBillPlanSelected"
                                description="Plano de Contas Selecionados:"
                                value={descriptionBillPlanSelected}
                                maxLength={6000}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2} 
                                onSelect={setFieldSelected} />

                              <div style={showMenu ? 
                                  (width < 1110) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}
                                : 
                                  (width < 840) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}}>
                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_add} alt="Adicionar" onClick={(() => { addBillPlan() })}/>
                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_clean} alt="Limpar" onClick={(() => { cleanBillPlan() })}/>
                              </div>
                            </div>
                          </div>

                          { showDocumentType &&
                            <div style={{marginTop: 10}}>
                              <Titulo description="Tipo do Documento" />

                              {/* Buttons */}
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 217}} onClick={() => selectedDocumentType()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 217}} onClick={() => markAllDocumentType()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 217}} onClick={() => cleanDocumentType()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                  </div>
                                </div>
                              </div>
                              {/* Buttons */}

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 260) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}
                                : 
                                  (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="billetLotDescriptionDocumentType"
                                    description="Descrição"
                                    value={descriptionDocumentType}
                                    maxLength={50}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescriptionDocumentType(event.target.value)} />
                                </div>

                                { returnDocumentTypeFilter && (returnDocumentTypeFilter.length > 0) &&
                                  <div style={showMenu ?
                                      (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column", borderBottomColor: "black", 
                                        borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 10, marginBottom: 4}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row", alignItems: "center", borderBottomColor: "black", 
                                        borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 10, marginBottom: 4}
                                    :
                                      (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", borderBottomColor: "black", 
                                        borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 10, marginBottom: 4}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row", alignItems: "center", borderBottomColor: "black", 
                                        borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 10, marginBottom: 4}}>

                                    <div style={showMenu ?
                                        (width < 790) ? {display: "flex", flexGrow: 1, marginLeft: 17, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 17, minWidth: 60, maxWidth: 60}
                                      :
                                        (width < 540) ? {display: "flex", flexGrow: 1, marginLeft: 17, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 17, minWidth: 60, maxWidth: 60}}>
                                      <label style={{fontWeight: 600, marginLeft: 10, marginBottom: 5}}>Código</label>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 790) ? {display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 27}
                                        : {display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 20}
                                      :
                                        (width < 540) ? {display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 27}
                                        : {display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 20}}>
                                      <label style={{fontWeight: 600, marginBottom: 5}}>Descrição</label>
                                    </div>
                                  </div>
                                }

                                { returnDocumentTypeFilter && returnDocumentTypeFilter.map(item => (
                                  <div key={item.ID + "_" + item.DESCRIPTION} style={{display: "flex", flexDirection: "row", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8, marginTop: 4,
                                    minWidth: 180}}>

                                    <div style={showMenu ? 
                                        (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 10}
                                      : 
                                        (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                        : {display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 10}}>

                                      <div style={showMenu ?
                                          (width < 790) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 60, maxWidth: 60}
                                        :
                                          (width < 540) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 60, maxWidth: 60}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowDocumentType(item.ID) } />
                                        <label style={{marginLeft: 10}}>{item.ID}</label>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10, minWidth: 180}}>
                                        <label>{item.DESCRIPTION}</label>
                                      </div>
                                    </div>
                                  </div>
                                  ))
                                }

                                { returnDocumentTypeFilter && (returnDocumentTypeFilter.length === 0) &&
                                  <div style={{marginTop: 10}}>
                                    <label>Nenhum registro encontrado.</label>
                                  </div>
                                }
                              </div>
                            </div>
                          }

                          { showBillPlan &&
                            <div style={{marginTop: 10}}>
                              <Titulo description="Plano de Conta" />

                              {/* Buttons */}
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                  marginLeft: 5, width: 217}} onClick={() => selectedBillPlan()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 217}} onClick={() => markAllBillPlan()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 217}} onClick={() => cleanBillPlan()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                  </div>
                                </div>
                              </div>
                              {/* Buttons */}

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 260) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}
                                : 
                                  (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="billetLotDescriptionBillPlan"
                                    description="Descrição"
                                    value={descriptionBillPlan}
                                    maxLength={500}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescriptionBillPlan(event.target.value)} />
                                </div>

                                { returnBillPlanFilter && (returnBillPlanFilter.length > 0) &&
                                  <div style={showMenu ?
                                      (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10,
                                        marginTop: 10, marginBottom: 4}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", borderBottomColor: "black",
                                        borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}
                                    : 
                                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                        borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", borderBottomColor: "black",
                                        borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600, marginLeft: 10}}>Código</label>
                                    </div>

                                    <div style={showMenu ? (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                      : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                        <label style={{fontWeight: 600}}>Número</label>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 10}}>
                                        <label style={{fontWeight: 600}}>Descrição</label>
                                      </div>
                                    </div>
                                  </div>
                                }

                                { returnBillPlanFilter && returnBillPlanFilter.map(item => (
                                  <div key={item.ID + "_" + item.NUMERO + "_" + item.DESCRICAO} style={{display: "flex", flexDirection: "row", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8, marginTop: 4,
                                    minWidth: 180}}>

                                    <div style={showMenu ? (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 10}
                                      : (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 10}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowBillPlan(item.ID) } />
                                        <label style={{marginLeft: 10}}>{item.ID}</label>
                                      </div>

                                      <div style={showMenu ? (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                          {item.NUMBER}
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 10}}>
                                          <label>{item.DESCRIPTION}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  ))
                                }

                                { returnBillPlanFilter && (returnBillPlanFilter.length === 0) &&
                                  <div style={{marginTop: 10}}>
                                    <label>Nenhum registro encontrado.</label>
                                  </div>
                                }
                              </div>
                            </div>
                          }

                          <div style={(showMenu) ? 
                              (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={showMenu ? 
                                (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 190}
                              : 
                                (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 190}}>
                              <Input
                                type="search"
                                name="corporateNameClientSelected_billetLot"
                                description="Clientes Selecionados:"
                                value={corporateNameClientSelected}
                                maxLength={6000}
                                disabled={true}
                                require={false}
                                image={false}
                                typeInput={2} 
                                onSelect={setFieldSelected} />

                              <div style={showMenu ? 
                                  (width < 1110) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}
                                : 
                                  (width < 840) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, justifyContent: "center"}}>
                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_add} alt="Adicionar" onClick={(() => { addClient() })}/>
                                <img style={{height: 42, width: 42, marginLeft: 10}} src={image_clean} alt="Limpar" onClick={(() => { cleanClient() })}/>
                              </div>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "flex-end", marginLeft: 10, minWidth: 190, maxWidth: 190}
                              : 
                                (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, minWidth: 180}
                                : (width < 1040) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, minWidth: 190}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "flex-end", marginLeft: 10, minWidth: 190, maxWidth: 190}}>
                              <Select
                                name="order_searchBillPay"
                                description="Ordenar:"
                                value={order}
                                require={false}
                                options={JSON.parse("{\"RESULT\": [{\"ID\": 1, \"VALUE\": \"Data emissão\"}, {\"ID\": 2, \"VALUE\": \"Data vencimento\"}"+
                                  ", {\"ID\": 3, \"VALUE\": \"Cliente\"}, {\"ID\": 4, \"VALUE\": \"Valor\"}]}")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setOrder} />
                            </div>
                          </div>
                         
                          { showClient &&
                            <div style={{marginTop: 10}}>
                              <Titulo description="Cliente" />

                              {/* Buttons */}
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                  marginLeft: 5, width: 217}} onClick={() => selectedClient()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                  marginLeft: 5, width: 217}} onClick={() => markAllClient()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                  </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                  marginLeft: 5, width: 217}} onClick={() => cleanClient()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                  </div>
                                </div>
                              </div>
                              {/* Buttons */}

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 260) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}
                                : 
                                  (width < 280) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="corporateNameClient_billetLot"
                                    description="Nome/Razão"
                                    value={corporateNameClient}
                                    maxLength={60}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCorporateNameClient(event.target.value)} />
                                </div>

                                { returnClientFilter && (returnClientFilter.length > 0) &&
                                  <div style={showMenu ? (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1, marginLeft: 10,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10,
                                      marginTop: 10, marginBottom: 4}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10,
                                      marginTop: 10, marginBottom: 4}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}
                                    : (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", paddingLeft: 10, marginTop: 10, marginBottom: 4}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 5, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600, marginLeft: 10}}>Código</label>
                                    </div>

                                    <div style={showMenu ? (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                      : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                      : (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                        <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 10}}>
                                        <label style={{fontWeight: 600}}>Nome/Razão Social</label>
                                      </div>
                                    </div>
                                  </div>
                                }

                                { returnClientFilter && returnClientFilter.map(item => (
                                  <div key={item.CPF_CNPJ + "_" + item.CORPORATE_NAME} style={{display: "flex", flexDirection: "row", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8, marginTop: 4, 
                                    minWidth: 180}}>

                                    <div style={showMenu ? 
                                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1, marginLeft: 10}
                                        : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 10}
                                      : 
                                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", marginLeft: 10}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowClient(item.ID) } />
                                        <label style={{marginLeft: 10}}>{item.ID}</label>
                                      </div>

                                      <div style={showMenu ? 
                                          (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                          : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                          { item.CPF_CNPJ.length === 11 &&
                                            item.CPF_CNPJ.substr(0,3) + "." + item.CPF_CNPJ.substr(4,3) + "." + item.CPF_CNPJ.substr(6,3) + "-" + 
                                              item.CPF_CNPJ.substr(9,2)
                                          }

                                          { item.CPF_CNPJ.length === 14 &&
                                            item.CPF_CNPJ.substr(0,2) + "." + item.CPF_CNPJ.substr(2,3) + "." + item.CPF_CNPJ.substr(5,3) + "/" + 
                                              item.CPF_CNPJ.substr(8,4) + "-" + item.CPF_CNPJ.substr(12,4)
                                          }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", marginLeft: 10}}>
                                          <label>{item.CORPORATE_NAME}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  ))
                                }

                                { returnClientFilter && (returnClientFilter.length === 0) &&
                                  <div style={{marginTop: 10}}>
                                    <label>Nenhum registro encontrado.</label>
                                  </div>
                                }
                              </div>
                            </div>
                          }

                          { showResult && 
                            <>
                              { result.OBJECT &&
                                <>
                                  {/* Buttons */}
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", 
                                    marginTop: 10}}>
                                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                      marginTop: 10, marginLeft: 5, width: 217}} onClick={() => selectedBilletLot()}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                        paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                        <img style={{height: 32, width: 32}} src={image_ticket} alt="Gerar Boletos"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Gerar Boletos</label>
                                      </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                                      marginTop: 10, marginLeft: 5, width: 217}} onClick={() => markAllBilletLot()}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                        paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                        <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                      </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                      marginTop: 10, marginLeft: 5, width: 217}} onClick={() => markOffAllBilletLot()}>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                        paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                        <img style={{height: 32, width: 32}} src={image_previous} alt="Desmarcar Todos"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Desmarcar Todos</label>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Buttons */}

                                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                                  </div>

                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                    paddingLeft: 10}}>
                                    { warning &&
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                        marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                      </div> 
                                    }

                                    { error &&
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                        marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                      </div>
                                    }
                                  </div>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}}>
                                    <Select
                                      name="billetLotBilletConfigurationCode"
                                      description="Configuração do Boleto"
                                      value={billetConfigurationCode}
                                      require={false}
                                      options={JSON.parse(optionsBilletConfiguration)}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={onSelectConfiguration} />
                                  </div>
                                
                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}}>

                                    <div style={showMenu ?
                                        (width < 1850) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :  
                                        (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={showMenu ? 
                                          (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1850) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        :
                                          (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1570) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={showMenu ?
                                            (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                          : 
                                            (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                          <div style={{display: "flex", flexGrow: 0, minWidth: 20, maxWidth: 15}} />

                                          <div style={showMenu ? 
                                              (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 95}
                                              : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 95, maxWidth: 95}
                                            : 
                                              (width < 620) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 95}
                                              : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 95, maxWidth: 95}}>
                                            <label style={{fontWeight: 600}}>Código</label>
                                          </div>
                                        </div>
                                            
                                        <div style={showMenu ?
                                            (width < 1850) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                          : 
                                            (width < 1570) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                          <label style={{fontWeight: 600}}>Docto/Parc</label>
                                        </div>

                                        <div style={showMenu ? 
                                            (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                          : 
                                            (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                          <div style={showMenu ? 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                            : 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                            <label style={{fontWeight: 600}}>Lancto.</label>
                                          </div>

                                          <div style={showMenu ? 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                            : 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                            <label style={{fontWeight: 600}}>Vencto.</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={showMenu ? 
                                          (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                        : 
                                          (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexGrow: 1, marginLeft: 5}}>

                                        <div style={showMenu ? 
                                            (width < 1000) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                            : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                                          : 
                                            (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                            : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={showMenu ? 
                                            (width < 1000) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 520}
                                          : 
                                            (width < 720) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 520}}>
                                          <label style={{fontWeight: 600}}>Cliente</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu ? 
                                      {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}
                                    : 
                                      {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />

                                    { result.OBJECT && result.OBJECT.map(item => (
                                      <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}}>

                                        <div style={showMenu ?
                                            (width < 1850) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :  
                                            (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={showMenu ? 
                                              (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1850) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1570) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                              : 
                                                (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                              <div style={{display: "flex", flexGrow: 0, minWidth: 20, maxWidth: 15}}>
                                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowBillLot(item) } />
                                              </div>
                                              <div style={showMenu ? 
                                                  (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 95}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 95, maxWidth: 95}
                                                : 
                                                  (width < 620) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 95}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 95, maxWidth: 95}}>
                                                <label>{item.ID}</label>
                                              </div>
                                            </div>
                                                
                                            <div style={showMenu ?
                                                (width < 1850) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                              : 
                                                (width < 1570) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                              <label>{item.NUMBER + "/" + item.PORTION}</label>
                                            </div>

                                            <div style={showMenu ? 
                                                (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                                : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                              : 
                                                (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                                : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                              <div style={showMenu ? 
                                                  (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                                  : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                                  : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                                : 
                                                  (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                                  : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                                  : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                                <label>
                                                  { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" +
                                                    String(item.RELEASE_DATE).substr(0,4) }
                                                </label>
                                              </div>

                                              <div style={showMenu ? 
                                                  (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                                  : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                                : 
                                                  (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                                  : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                                <label>
                                                  { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" +
                                                    String(item.DUE_DATE).substr(0,4) }
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <div style={showMenu ? 
                                              (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : 
                                              (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                                            <div style={showMenu ? 
                                                (width < 1000) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                                : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                                              : 
                                                (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                                : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                              <label>{formatNumeric(item.VALUE, 2)}</label>
                                            </div>

                                            <div style={showMenu ? 
                                                (width < 1000) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}
                                              : 
                                                (width < 720) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}}>
                                              <label>{item.CLIENT.CORPORATE_NAME}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  }

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderTopColor: "black", borderTopWidth: 2, borderTopStyle: "solid", 
                                    marginTop: 10, marginBottom: 4, paddingLeft: 8}}>

                                    <div style={showMenu ?
                                        (width < 1850) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :  
                                        (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={showMenu ? 
                                          (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1850) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        :
                                          (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1570) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={showMenu ?
                                            (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                          : 
                                            (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                          <div style={{display: "flex", flexGrow: 0, minWidth: 20, maxWidth: 15}} />

                                          <div style={showMenu ? 
                                              (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 95}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 95, maxWidth: 95}
                                            : 
                                              (width < 620) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 95}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 95, maxWidth: 95}}>
                                            <label>{""}</label>
                                          </div>
                                        </div>
                                            
                                        <div style={showMenu ?
                                            (width < 1850) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                          : 
                                            (width < 1570) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                          <label>{""}</label>
                                        </div>

                                        <div style={showMenu ? 
                                            (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                          : 
                                            (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : (width < 620) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                          <div style={showMenu ? 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}
                                            : 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                            <label>{""}</label>
                                          </div>

                                          <div style={showMenu ? 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                            : 
                                              (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 90}
                                              : (width < 620) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 90}
                                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                            <label>{""}</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={showMenu ? 
                                          (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                        : 
                                          (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexGrow: 1, marginLeft: 5}}>

                                        <div style={showMenu ? 
                                            (width < 1000) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                            : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                                          : 
                                            (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                            : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{formatNumeric(totalValue, 2)}</label>
                                        </div>

                                        <div style={showMenu ? 
                                            (width < 1000) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}
                                          : 
                                            (width < 720) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}}>
                                          <label>{""}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }

                              { !result.OBJECT &&
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", marginTop: 20}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                </div>
                              }

                            </>
                          }
                        </div>
                      </div>
                    </>
                }

              </div>
          }

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BilletLot