import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import image_loading from "../../Images/spinner.gif";
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"
import image_search from "../../Images/search.svg"
import image_unoccupied from "../../Images/livre.png"
import image_ccupied from "../../Images/ocupado.png"

import Mensagem from "../../Components/Mensagem"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import Img_adicionar from "../../Images/add.svg"
import Img_limpar from "../../Images/clean.svg"
import Img_retornar from "../../Images/previous.svg"
import Img_salvar from "../../Images/save.svg"
import Img_search from "../../Images/search.svg"
import { useNavigate } from "react-router-dom"

const api = axios.create({
  baseURL: "https://localhost:5001/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const TableCenter = ({typeDatabase, pathDatabase, jsonCompany, jsonLogin, system, logoff, setReturn}) => {
  const [numberTable, setNumberTable] = useState("")

  const [results, setResults] = useState([])

  const [showMenu, setShowMenu] = useState(false)
  const [showResults, setShowResults] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    const interval = setInterval(
      () => searchTables(), 5000,
    );

    return () => clearInterval(interval);
  }, [])

  const searchTables = (number) => {
    let returnJson = []    
    let objFilial  = JSON.parse(jsonCompany)

    let jRegisterTable = "{ \"COMPANY\": { \"ID\": "+ objFilial.ID + " }"
    if (number !== undefined) jRegisterTable += ", \"NUMBER\": "+ number
    jRegisterTable += "}"

    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações...")
  
    api.get("REGISTER_TABLE/SearchToSale?iTypeDatabase="+ typeDatabase + "&sPathDatabase="+ pathDatabase +
      "&jsonRegisterTable=" + jRegisterTable + "&jsonLogin=" + jsonLogin + "&bSystem=" + system)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível pesquisar as agências ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.CONCRETIZADO === false){
        setError(returnJson.ERRO)
        setWarning("")
        setLoading("")
        setShowResults(false)

        document.getElementById("numberTable") &&
          document.getElementById("numberTable").focus()

        return
      }
      else
      {
        setResults(returnJson)
        setWarning(returnJson.AVISOS)
        setLoading("")
        setShowResults(true)

        document.getElementById("numberTable") &&
          document.getElementById("numberTable").focus()             
      }
    })

    setLoading("")
  }

  const selectTable = (table) => {
    
    navigate('/tableItensTouch/'+ table.NUMBER);
  }
    
  const inputSelected = () => {    
  }

  const navigate = useNavigate()

  return(
    <div id="tableCenter">
      <div id="body_tableCenter">

        { showMenu && 
          <div id="bodyMenu_tableCenter">
            <Menu jsonFilial={jsonCompany} logoff={logoff} mostrarMenu={showMenu} setMostrarMenu={setShowMenu} />
          </div>
        }

        <div id="bodyContent_tableCenter">
          <div id="bodyHeader_tableCenter">

            { !showMenu &&
              <div id="bodyHeaderMenu_tableCenter">
                <img
                  src={image_menu}
                  alt="Retrair"
                  onClick={(() => { setShowMenu(!showMenu) })} />
              </div>
            }

            <div id="bodyHeaderTitle_tableCenter">
              <label>Central das Mesas</label>
            </div>

            <div id="bodyHeaderLogo_tableCenter">
              <img src={image_multiplaWhite} alt="Logomarca" />
            </div>
          </div>

          { loading &&
            <div id="loading_tableCenter">
              <img src={image_loading} alt="Carregando"/>
              <label>{loading}</label>
            </div>
          }

          { !loading &&
            <div id="content_tableCenter">
              { warning && 
                <div id="bodyMessageWarning_tableCenter">
                  <label id="messageWarning_tableCenter">{warning}</label>
                </div> 
              }

              { error &&
                <div id="bodyMessageError_tableCenter">
                  <label id="messageError_tableCenter">{error}</label>
                </div> 
              }

              { showResults &&
                <>
                  <div id="bodySearch_tableCenter">
                    <div id="bodySearchNumber_tableCenter">
                      <Input 
                        type="text"
                        name="numberTable"
                        description="Número da Mesa"
                        planceHolder=""
                        value={numberTable}
                        maxLength={3}
                        disabled={false}
                        require={false}
                        typeImage={0}
                        onSelect={() => inputSelected}
                        onChange={(event) => setNumberTable(event.target.value)} />
                    </div>
                    <div id="bodySearchButton_tableCenter">
                      <img
                        className="sizeButton44_tableCenter"
                        name="searchTable"
                        src={image_search} 
                        alt="Pesquisar" 
                        onClick={() => numberTable(numberTable) } />
                    </div>
                  </div>

                  <div id="bodyRecords_tableCenter">
                    { results.RESULTADO &&
                        results.RESULTADO.map(item => (
                          <div key={item.NUMBER} className="record_tableCenter" onClick={(() => selectTable(item))}>
                            <div className="recordBodyImagem_tableCenter">
                              <img                                
                                className="recordImagem_tableCenter"
                                name="imageTable"
                                src={ item.STATUS === 1 ? image_unoccupied : image_ccupied }
                                alt="Mesa Ocupada" />
                            </div>
                            <div className="recordBodyText_tableCenter">
                              <label className="recordText_tableCenter">Mesa {" "} {item.NUMBER}</label>
                            </div>
                          </div>
                        ))
                    }

                  </div>
                </>
              }
            </div>
          }
        </div>
      </div>
      <div id="footer_tableCenter">
        <Footer/>
      </div>
    </div>
  )
}

export default TableCenter