import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchAccount from "../Account/Search"
import SearchAccountPlan from "../AccountPlan/Search"
import SearchFinisher from "./Search"
import SearchSupplier from "../Supplier/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Finisher = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [nature, setNature] = useState(0)
  const [type, setType] = useState(0)
  const [coin, setCoin] = useState(0)
  const [passwordRestriction, setPasswordRestriction] = useState(0)
  const [seeBalance, setSeeBalance] = useState(0)
  const [integrateTef, setIntegrateTef] = useState(0)
  const [transactionType, setTransactionType] = useState(0)
  const [intermediary, setIntermediary] = useState(0)
  const [situation, setSituation] = useState(0)
  const [sge, setSge] = useState("")

  const [accountPlanCode, setAccountPlanCode] = useState("")
  const [accountPlanDescription, setAccountPlanDescription] = useState("")

  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")

  const [supplierCode, setSupplierCode] = useState("")
  const [supplierCorporateName, setSupplierCorporateName] = useState("")
   
  const [jFinisher, setJFinisher] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showAccount, setShowAccount] = useState(false)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showSupplier, setShowSupplier] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setDescription("")
      setNature(0)
      setType(0)
      setCoin(0)
      setPasswordRestriction(0)
      setSeeBalance(0)
      setIntegrateTef(0)
      setTransactionType(0)
      setIntermediary(0)
      setSituation(0)
      setSge("")

      setAccountPlanCode("")
      setAccountPlanDescription("")

      setAccountCode("")
      setAccountNumber("")
      setAccountOwner("")

      setSupplierCode("")
      setSupplierCorporateName("")
    }
    else {
      setDescription(jFinisher.DESCRIPTION)
      setNature(jFinisher.NATURE)
      setType(jFinisher.TYPE)
      setCoin(jFinisher.COIN)
      setPasswordRestriction(jFinisher.PASSWORD_RESTRICTION)
      setSeeBalance(jFinisher.SEE_BALANCE)
      setIntegrateTef(jFinisher.INTEGRATE_TEF)

      if ((jFinisher.TRANSACTION_TYPE !== null) && (jFinisher.TRANSACTION_TYPE !== undefined)) setTransactionType(jFinisher.TRANSACTION_TYPE)
      else setTransactionType(0)

      if ((jFinisher.SGE !== null) && (jFinisher.SGE !== undefined)) setSge(jFinisher.SGE)

      setIntermediary(jFinisher.INTERMEDIARY)
      setSituation(jFinisher.SITUATION)

      ////
      // Account Plan // Plano de Conta
      ////
      setAccountPlanCode(jFinisher.ACCOUNT_PLAN.ID)
      setAccountPlanDescription(jFinisher.ACCOUNT_PLAN.DESCRIPTION)

      ////
      // Account // Conta
      ////
      setAccountCode(jFinisher.ACCOUNT.ID)
      setAccountNumber(jFinisher.ACCOUNT.NUMBER)
      setAccountOwner(jFinisher.ACCOUNT.OWNER)

      ////
      // Supplier // Fornecedor
      ////
      if ((jFinisher.SUPPLIER !== null) && (jFinisher.SUPPLIER !== undefined)){
        setSupplierCode(jFinisher.SUPPLIER.ID)
        setSupplierCorporateName(jFinisher.SUPPLIER.CORPORATE_NAME)
      }
    }

    document.getElementById("finisherDescription") && document.getElementById("finisherDescription").focus()
  }, [code, jFinisher, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setDescription("")
      setNature(0)
      setType(0)
      setCoin(0)
      setPasswordRestriction(0)
      setSeeBalance(0)
      setIntegrateTef(0)
      setTransactionType(0)
      setIntermediary(0)
      setSituation(0)
      setSge("")

      setAccountPlanCode("")
      setAccountPlanDescription("")

      setAccountCode("")
      setAccountNumber("")
      setAccountOwner("")

      setSupplierCode("")
      setSupplierCorporateName("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const cleanAccount = () => {
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    setAccountPlanCode("")
    setAccountPlanDescription("")

    setShowAccountPlan(false)
  }

  const cleanSupplier = () => {
    setSupplierCode("")
    setSupplierCorporateName("")

    setShowSupplier(false)
  }

  const enableAccount = () => {
    setShowAccount(!showAccount)
  }

  const enableAccountPlan = () => {  
    setShowAccountPlan(!showAccountPlan)
  }

  const enableSupplier = () => {  
    setShowSupplier(!showSupplier)
  }

  const fillAccount = (account) => {
    setAccountCode(account.ID)
    setAccountNumber(account.NUMBER)
    setAccountOwner(account.OWNER)

    setShowAccount(false)
  }
  
  const fillAccountPlan = (account) => {
    setAccountPlanCode(account.ID)
    setAccountPlanDescription(account.DESCRIPTION)
  
    setShowAccountPlan(false)
  }

  const fillSupplier = (supplier) => {
    setSupplierCode(supplier.ID)
    setSupplierCorporateName(supplier.CORPORATE_NAME)

    setShowSupplier(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonFinisher_New = {}

    jsonFinisher_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonFinisher_New += ", \"ID\": "+ code
    jsonFinisher_New += ", \"DESCRIPTION\": \""+ description + "\""
    jsonFinisher_New += ", \"NATURE\": "+ nature
    jsonFinisher_New += ", \"TYPE\": "+ type
    jsonFinisher_New += ", \"COIN\": "+ coin
    jsonFinisher_New += ", \"PASSWORD_RESTRICTION\": "+ passwordRestriction
    jsonFinisher_New += ", \"SEE_BALANCE\": "+ seeBalance
    jsonFinisher_New += ", \"INTEGRATE_TEF\": "+ integrateTef

    if ((((transactionType !== undefined) && (transactionType !== null)) && (transactionType !== "")) && (transactionType !== 0))
      jsonFinisher_New += ", \"TRANSACTION_TYPE\": "+ transactionType

    if (((accountPlanCode !== undefined) && (accountPlanCode !== null)) && (accountPlanCode !== "")){      
      jsonFinisher_New += ", \"ACCOUNT_PLAN\": { \"ID\": "+ accountPlanCode +
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }" +
      ", \"DESCRIPTION\": \""+ accountPlanDescription +"\" }"
    }

    if (((accountCode !== undefined) && (accountCode !== null)) && (accountCode !== "")){
      jsonFinisher_New += ", \"ACCOUNT\": { \"ID\": "+ accountCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"+
      ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" }"
    }

    if (((supplierCode !== undefined) && (supplierCode !== null)) && (supplierCode !== "")){
      jsonFinisher_New += ", \"SUPPLIER\": { \"ID\": "+ supplierCode +
      ", \"CORPORATE_NAME\": \""+ supplierCorporateName +"\" }"
    }

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonFinisher_New += ", \"SGE\": "+ sge

    jsonFinisher_New += ", \"INTERMEDIARY\": "+ intermediary +
    ", \"SITUATION\": "+ situation + " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")  
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonFinisher_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "FINISHER/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a finalizadora. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("finisherDescription") && document.getElementById("finisherDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")      

        setJFinisher(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jFinisher))
      formData.append("JSON_NEW_1", jsonFinisher_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "FINISHER/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a finalizadora. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("finisherDescription") && document.getElementById("finisherDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJFinisher(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }    
  
  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJFinisher(event)
    setCode(event.ID)
  }   

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Finalizadora"
            titleSearch="Pesquisa das Finalizadoras"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchFinisher
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showAccountPlan && !showAccount && !showSupplier &&
                        <>
                          <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                        
                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            { (code === "") &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="finisherDescription"
                                    description="Descrição"
                                    planceHolder=""
                                    value={description}
                                    maxLength={20}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>

                                <div style={(width < 450) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                  <Select
                                    name="finisherSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            { (code !== "") &&
                              <div style={(showMenu) ?
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 90, maxWidth: 90}}>
                                    <Input
                                      type="text"
                                      name="finisherCode"
                                      description="Código"
                                      planceHolder=""
                                      value={code}
                                      maxLength={11}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="finisherDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={description}
                                      maxLength={20}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                  <Select
                                    name="finisherSituation"
                                    description="Situação"
                                    value={situation || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            <div style={(showMenu) ?
                                (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={showMenu ?
                                    (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Select
                                    name="finisherNature"
                                    description="Natureza"
                                    value={nature || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Débito\" }, {\"ID\": 2, \"VALUE\": \"Crédito\" }"+
                                      ", {\"ID\": 3, \"VALUE\": \"Neutro\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setNature} />
                                </div>
                                
                                <div style={showMenu ?
                                    (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 520) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Select
                                    name="type_finisher"
                                    description="Tipo"
                                    value={type || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Ambos\" }, {\"ID\": 2, \"VALUE\": \"Apenas fechamento\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setType} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1260) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={showMenu ?
                                    (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1260) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 980) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Select
                                    name="finisherCoin"
                                    description="Moeda"
                                    value={coin || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Dinheiro\" }, {\"ID\": 2, \"VALUE\": \"Cheque\" }"+
                                      ", {\"ID\": 3, \"VALUE\": \"Cartao\" }, {\"ID\": 4, \"VALUE\": \"Ticket\" }"+
                                      ", {\"ID\": 5, \"VALUE\": \"Vale\" }, {\"ID\": 6, \"VALUE\": \"À Prazo\" }"+
                                      ", {\"ID\": 7, \"VALUE\": \"Caderneta\" }, {\"ID\": 8, \"VALUE\": \"Sangria\" }"+
                                      ", {\"ID\": 9, \"VALUE\": \"Suprimento\" }, {\"ID\": 10, \"VALUE\": \"PIX Automático\" }"+
                                      ", {\"ID\": 11, \"VALUE\": \"PIX Manual\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setCoin} />                                  
                                </div>

                                <div style={showMenu ?
                                    (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 520) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Select
                                    name="finisherIntermediary"
                                    description="Intermediario"
                                    value={intermediary || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setIntermediary} />                                  
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Select
                                    name="finisherPasswordRestriction"
                                    description="Restrição de senha"
                                    value={passwordRestriction || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setPasswordRestriction} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Select
                                    name="finisherSeeBalance"
                                    description="Ver saldo"
                                    value={seeBalance || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSeeBalance} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}     
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Select
                                    name="finisherIntegrateTef"
                                    description="Integra TEF"
                                    value={integrateTef || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setIntegrateTef} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Select
                                    name="finisherTipoTransacao"
                                    description="Tipo da transação"
                                    value={transactionType || 0}
                                    disabled={false}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Cheque\" }, {\"ID\": 2, \"VALUE\": \"Débito\" }"+
                                      ", {\"ID\": 3, \"VALUE\": \"Crédito\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setTransactionType} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexDirection: "column", flexBasis: 1, flexGrow: 1}}>
                                <div style={{minWidth: 180}}>
                                  <Titulo description="Plano de contas" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="finisherAccountPlanDescription"
                                        description="Descrição"
                                        planceHolder=""
                                        value={accountPlanDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setAccountPlanDescription(event.target.value)} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="finisherSearchAccountPlan"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableAccountPlan} />

                                      <img 
                                        style={{marginLeft: 10, height: 42, width: 42}}
                                        name="finisherCleanAccountPlan"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={cleanAccountPlan} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={(showMenu) ? 
                                  {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                : 
                                  (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <div style={{minWidth: 180}}>
                                  <Titulo description="Conta" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ?
                                          (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="finisherAccountNumber"
                                          description="Número"
                                          planceHolder=""
                                          value={accountNumber}
                                          maxLength={10}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setAccountNumber(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : 
                                          (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="finisherAccountOwner"
                                          description="Titular"
                                          planceHolder=""
                                          value={accountOwner}
                                          maxLength={50}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setAccountOwner(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                          marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                          marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="finisherSearchAccount"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableAccount} />

                                      <img 
                                        style={{marginLeft: 10, height: 42, width: 42}}
                                        name="finisherCleanAccount"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={cleanAccount} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            { (((parseInt(coin) === 3) && (parseInt(integrateTef) === 1)) || (parseInt(intermediary) === 1)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                  <div style={{minWidth: 180}}>
                                    <Titulo description="Fornecedor" />
                                  </div>

                                  <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                    : {paddingLeft: 10, paddingRight: 10}}>

                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="finisherSupplierCorporateName"
                                          description="Nome \ Razão Social"
                                          planceHolder=""
                                          value={supplierCorporateName}
                                          maxLength={60}
                                          disabled={true}
                                          require={true}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} 
                                          onChange={(event) => setSupplierCorporateName(event.target.value)} />
                                      </div>
                                      <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                          marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                        <img 
                                          style={{height: 42, width: 42}}
                                          name="finisherSearchSupplier"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={enableSupplier} />

                                        <img 
                                          style={{marginLeft: 10, height: 42, width: 42}}
                                          name="finisherCleanSupplier"
                                          src={image_clean} 
                                          alt="Limpar" 
                                          onClick={cleanSupplier} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                          </div>
                        </>
                      }

                      { showAccountPlan &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o plano de contas:</b>
                          </div>
                          
                          <SearchAccountPlan
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccountPlan}
                            setReturn={() => setShowAccountPlan(false)}
                            type={0}
                            periodic={1} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showAccount &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione a conta:</b>
                          </div>

                          <SearchAccount
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccount} 
                            setReturn={() => setShowAccount(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showSupplier &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o fornecedor:</b>
                          </div>

                          <SearchSupplier
                            modeSearch={true}
                            process={1}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillSupplier} 
                            setReturn={() => setShowSupplier(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>
         
      <Footer/>
    </div>
  )
}

export default Finisher