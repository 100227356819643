import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Barcode from 'react-barcode';
import Footer from "../../Components/Footer"
import InputMasked from "../../Components/InputMasked"
import Menu from "../Menu"
import QRcode from 'qrcode.react'
import Select from "../../Components/Select"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_printer from "../../Images/Buttons/printer.png"
import image_search from "../../Images/Buttons/search.png"

const GenerateQrCodeTable = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [type, setType] = useState(1)
  const [numberIni, setNumberIni] = useState("")
  const [numberEnd, setNumberEnd] = useState("")

  const [marginTopImage, setMarginTopImage] = useState(10)
  const [heightImage, setHeightImage] = useState(160)
  const [widthImage, setWidthImage] = useState(310)

  const [showMenu, setShowMenu] = useState(true)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setNumberIni(null)
    setNumberEnd(null)
  }

  const handleHeightImage = (event) => {
    let heightImag = String(event.target.value).replaceAll("_","").replaceAll(" ","")
    if (heightImag !== "") setHeightImage(parseInt(heightImag))
    else setHeightImage("")
  }

  const handleMarginTopImage = (event) => {
    let marginTopImag = String(event.target.value).replaceAll("_","").replaceAll(" ","")
    if (marginTopImag !== "") setMarginTopImage(parseInt(marginTopImag))
    else setMarginTopImage("")
  }  

  const handleWidthImage = (event) => {
    let widthImag = String(event.target.value).replaceAll("_","").replaceAll(" ","")
    if (widthImag !== "") setWidthImage(parseInt(widthImag))
    else setWidthImage("")
  }

  const print = () => {
    window.print()
  }

  const returnSearch = () => {
    setNumberIni("")
    setNumberEnd("")

    setShowResult(false)
   
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")
  }

  const search = () => {
    let returnJson = []
    
    let numberIn = String(numberIni).replaceAll("_","").replaceAll(" ","")
    if ((numberIn == "") || (parseInt(numberIn) <= 0))
    {
      setError("Número inicial inválido")
      document.getElementById("generateQrCodeTableNumberIni") && document.getElementById("generateQrCodeTableNumberIni").focus()
      return
    }

    let numberEn = String(numberEnd).replaceAll("_","").replaceAll(" ","")
    if ((numberEn == "") || (parseInt(numberEn) <= 0))
    {
      setError("Número final inválido")
      document.getElementById("generateQrCodeTableNumberEnd") && document.getElementById("generateQrCodeTableNumberEnd").focus()
      return
    }

    if (parseInt(numberEn) < parseInt(numberIn))
    {
      setError("Número inicial deve ser maior ou igual ao Número final")
      document.getElementById("generateQrCodeTableNumberIni") && document.getElementById("generateQrCodeTableNumberIni").focus()
      return
    }

    let widthImag = String(widthImage).replaceAll("_","").replaceAll(" ","")
    if (parseInt(widthImag) <= 0) setWidthImage(310)

    let heightImag = String(heightImage).replaceAll("_","").replaceAll(" ","")    
    if (parseInt(heightImag) <= 0) setHeightImage(160)

    let marginTopImag = String(marginTopImage).replaceAll("_","").replaceAll(" ","")
    if (parseInt(marginTopImag) <= 0) setMarginTopImage(160)

    setLoading("")
    setError("")
    setLoading("Aguarde buscando as informações...") 

    const jGenerateQrCodeTable = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"CPF_CNPJ\": \""+ jsonCompany.CPF_CNPJ +"\" }"+
      ", \"NUMBER_INI\": "+ numberIn + ", \"NUMBER_END\": "+ numberEn + " }"
      
    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jGenerateQrCodeTable)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "REGISTER_TABLE/GenerateQrCode", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível gerar os QRCodes das mesas. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")
        return
      }

      setResult(returnJson)
      setShowResult(true)
      setLoading("")
    })
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, borderCollapse: "collapse", minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu && 
          <div style={{display: "flex", flexGrow: 0, flexDirection: "row"}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} width={width} logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", paddingTop: 15, paddingBottom: 15}}>
            { showMenu ?
                <div style={(width < 520) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", paddingLeft: 10, paddingRight: 10}
                  :
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", paddingLeft: 10,
                    paddingRight: 15}}>

                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5,
                    paddingRight: 5, marginTop: 5}}>
                      <label style={
                        (width < 520) ? {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>
                      Gerador QrCode/Barras da Mesa</label>
                  </div>

                  { (width < 520) ?
                      <div style={{marginTop: 10}}>
                        <img src={image_multiplaWhite} 
                          style={{ height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={{ height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
              :
                <div style={(width < 560) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                  : 
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center",
                    paddingLeft: 10, paddingRight: 15}}>

                  <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "flex-start", alignItems: "center",
                      paddingLeft: 10, paddingRight: 10}}>
                        <img src={image_menu}
                        style={(width < 360) ? { height: "auto", width: 30 } : { height: "auto", width: 35 }}
                        alt="Retrair"
                        onClick={(() => { setShowMenu(!showMenu) })} />
                    </div>

                    <div style={(width < 420) ? 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5,
                          marginTop: 15}
                      : 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5,
                          marginTop: 5}}>
                      <label style={
                        (width < 420) ? {color: "white", fontSize: 16, fontWeight: 600, textAlign: "center"} 
                        : (width < 760) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>
                        Gerar QrCode/Barras da Mesa
                      </label>
                    </div>
                  </div>

                  { (width < 560) ?
                      <div style={{display: "flex", flex: 1, marginTop: 10, justifyContent: "center"}}>
                        <img src={image_multiplaWhite} 
                          style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
            }
          </div>

          { !showResult &&
            <>
              { loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems: "center",
                  minHeight: 495}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              }

              { !loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => search()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => clean()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => setReturn()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning && 
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div> 
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }
                    
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0, 
                      marginBottom: 10, marginLeft: 0}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                    </div>

                    <div style={showMenu ?
                        (width < 540) ? {display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10}
                        : (width < 800) ? {display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10}
                      :
                        (width < 540) ? {display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10}}>

                      <div style={showMenu ?
                          (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180}
                          : (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180}
                        :
                          (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180}}>
                        <Select
                          name="generateQrCodeTableType"
                          description="Tipo"
                          value={type || 1}
                          disabled={false}
                          require={false}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"QrCode\" }, {\"ID\": 2, \"VALUE\": \"Barras\" }"+
                            ", {\"ID\": 3, \"VALUE\": \"Código\" }] }")}
                          image={false}
                          typeImage={0}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setType} />
                      </div>

                      <div style={showMenu ?
                          (width < 350) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 540) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                          : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                        :
                          (width < 350) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 540) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                          : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                        <div style={(width < 350) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableNumberIni"
                            description="Inicial"
                            planceHolder=""
                            mask="99999"
                            value={numberIni}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setNumberIni(event.target.value)} />
                        </div>
                        <div style={(width < 350) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableNumberEnd"
                            description="Final"
                            planceHolder=""
                            mask="99999"
                            value={numberEnd}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setNumberEnd(event.target.value)} />
                        </div>
                      </div>
                    </div>

                    { showMenu && (width >= 780) &&
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableHeightImage"
                            description="Largura"
                            planceHolder=""
                            mask="99999"
                            value={heightImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleHeightImage} />
                        </div>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableWidthImage"
                            description="Comprimento"
                            planceHolder=""
                            mask="99999"
                            value={widthImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleWidthImage} />
                        </div>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableMarginTopImage"
                            description="Margem"
                            planceHolder=""
                            mask="99999"
                            value={marginTopImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMarginTopImage} />
                        </div>
                      </div>
                    }

                    { showMenu && (width < 780) &&
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                        <div style={(width < 350) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <div style={(width < 350) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 140}}>
                            <InputMasked
                              name="generateQrCodeTableHeightImage"
                              description="Largura"
                              planceHolder=""
                              mask="99999"
                              value={heightImage}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleHeightImage} />
                          </div>
                          <div style={(width < 350) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                            <InputMasked
                              name="generateQrCodeTableWidthImage"
                              description="Comprimento"
                              planceHolder=""
                              mask="99999"
                              value={widthImage}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleWidthImage} />
                          </div>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                          <InputMasked
                            name="generateQrCodeTableMarginTopImage"
                            description="Margem"
                            planceHolder=""
                            mask="99999"
                            value={marginTopImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMarginTopImage} />
                        </div>
                      </div>
                    }

                    { !showMenu && (width >= 500) &&
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableHeightImage"
                            description="Largura"
                            planceHolder=""
                            mask="99999"
                            value={heightImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleHeightImage} />
                        </div>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableWidthImage"
                            description="Comprimento"
                            planceHolder=""
                            mask="99999"
                            value={widthImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleWidthImage} />
                        </div>
                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                          <InputMasked
                            name="generateQrCodeTableMarginTopImage"
                            description="Margem"
                            planceHolder=""
                            mask="99999"
                            value={marginTopImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMarginTopImage} />
                        </div>
                      </div>
                    }

                    { !showMenu && (width < 500) &&
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                        <div style={(width < 350) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <div style={(width < 350) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 140}}>
                            <InputMasked
                              name="generateQrCodeTableHeightImage"
                              description="Largura"
                              planceHolder=""
                              mask="99999"
                              value={heightImage}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleHeightImage} />
                          </div>
                          <div style={(width < 350) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 140}}>
                            <InputMasked
                              name="generateQrCodeTableWidthImage"
                              description="Comprimento"
                              planceHolder=""
                              mask="99999"
                              value={widthImage}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleWidthImage} />
                          </div>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                          <InputMasked
                            name="generateQrCodeTableMarginTopImage"
                            description="Margem"
                            planceHolder=""
                            mask="99999"
                            value={marginTopImage}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMarginTopImage} />
                        </div>
                      </div>
                    }

                  </div>
                </div>
              }
            </>
          }

          { showResult &&
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => print()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => returnSearch()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>

              <div id="print">
                { (parseInt(type) === 1) || (parseInt(type) === 2) ?
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", marginTop: 10, justifyContent: "center"}}>
                      { result.OBJECT.map(item => (
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 0, borderColor: "black", borderWidth: 1, 
                          borderStyle: "solid", borderRadius: 20, backgroundColor: "rgb(0, 178, 177)", width: widthImage, height: heightImage,
                          webkitPrintColorAdjust: "exact", marginTop: marginTopImage, marginLeft: 10, marginRight: 10}}>

                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", wordBreak: "break-all", paddingTop: 5, 
                            paddingBottom: 5}}>
                            <label style={
                              (heightImage < 200) ? {fontSize: 8, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 300) ? {fontSize: 10, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 400) ? {fontSize: 12, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 500) ? {fontSize: 14, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 600) ? {fontSize: 16, fontWeight: 600, textAlign: "center"}
                              : {fontSize: 18, fontWeight: 600, textAlign: "center"}}>{jsonCompany.CORPORATE_NAME}
                            </label>
                          </div>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                            backgroundColor: "rgb(128, 130, 133)", webkitPrintColorAdjust: "exact", paddingTop: 5, paddingBottom: 5}}>
                            <label style={
                              (heightImage < 200) ? {color: "white", fontSize: 7, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 300) ? {color: "white", fontSize: 9, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 400) ? {color: "white", fontSize: 11, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 500) ? {color: "white", fontSize: 13, fontWeight: 600, textAlign: "center"}
                              : (heightImage < 600) ? {color: "white", fontSize: 15, fontWeight: 600, textAlign: "center"}
                              : {color: "white", fontSize: 17, fontWeight: 600, textAlign: "center"}}>MULTIPLA SISTEMAS</label>
                            <label style={
                              (heightImage < 200) ? {color: "white", fontSize: 7, fontWeight: 600, textAlign: "center", paddingLeft: 5}
                              : (heightImage < 300) ? {color: "white", fontSize: 9, fontWeight: 600, textAlign: "center", paddingLeft: 5}
                              : (heightImage < 400) ? {color: "white", fontSize: 11, fontWeight: 600, textAlign: "center", paddingLeft: 5}
                              : (heightImage < 500) ? {color: "white", fontSize: 13, fontWeight: 600, textAlign: "center", paddingLeft: 5}
                              : (heightImage < 500) ? {color: "white", fontSize: 15, fontWeight: 600, textAlign: "center", paddingLeft: 5}
                              : {color: "white", fontSize: 17, fontWeight: 600, textAlign: "center", paddingLeft: 5}}>(031) 2511-0909</label>
                          </div>                      
                        
                          { parseInt(type) === 1 ?
                              <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", backgroundColor: "white", 
                                paddingTop: 5, paddingBottom: 5}}>
                                <QRcode 
                                  value={item.QR_CODE}
                                  size={
                                    (heightImage < 200) ? heightImage - 80 
                                    : (heightImage < 300) ? heightImage - 100 
                                    : (heightImage < 400) ? heightImage - 120 
                                    : (heightImage < 500) ? heightImage - 220 
                                    : (heightImage < 600) ? heightImage - 310 
                                    : heightImage - 400 }
                                  includeMargin={false}
                                />
                              </div>
                            :
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 10, paddingRight: 10, 
                                backgroundColor: "white"}}>
                                <Barcode 
                                  value={item.QR_CODE}
                                  displayValue={false}
                                  margin={0}
                                  marginTop={0}
                                  marginBottom={0}
                                  marginLeft={0}
                                  marginRight={0}
                                />
                              </div>
                          }

                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", paddingTop: 5, paddingBottom: 5}}>
                            <label style={
                              (heightImage < 200) ? {fontSize: 8, fontWeight: 600}
                              : (heightImage < 300) ? {fontSize: 10, fontWeight: 600}
                              : (heightImage < 400) ? {fontSize: 12, fontWeight: 600}
                              : (heightImage < 500) ? {fontSize: 14, fontWeight: 600}
                              : (heightImage < 600) ? {fontSize: 16, fontWeight: 600}
                              : {fontSize: 18, fontWeight: 600}}>Mesa:</label>
                            <label style={
                              (heightImage < 200) ? {fontSize: 8, fontWeight: 600, marginLeft: 5}
                              : (heightImage < 300) ? {fontSize: 10, fontWeight: 600, marginLeft: 5}
                              : (heightImage < 400) ? {fontSize: 12, fontWeight: 600, marginLeft: 5}
                              : (heightImage < 500) ? {fontSize: 14, fontWeight: 600, marginLeft: 5}
                              : (heightImage < 500) ? {fontSize: 16, fontWeight: 600, marginLeft: 5}
                              : {fontSize: 18, fontWeight: 600, marginLeft: 5}}>{item.NUMBER}</label>
                          </div>
                        </div>
                      ))}
                    </div>
                  :
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, justifyContent: "center"}}>
                      { result.OBJECT.map(item => (
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: marginTopImage, marginLeft: 20, 
                          marginRight: 20}}>

                          <div style={{display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                            <label style={{fontWeight: 600}} >Mesa {item.NUMBER}:</label>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, marginLeft: 5}}>
                            <label>{item.QR_CODE}</label>
                          </div>
                        </div>
                      ))}
                    </div>
                }

              </div> 
            </div>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default GenerateQrCodeTable