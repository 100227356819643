import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Select from "../../Components/Select"
import SearchDepartment from "./Search"
import image_loading from "../../Images/spinner.gif"

const Department = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [hierarchy, setHierarchy] = useState("")
  const [description, setDescription] = useState("")
  const [shortDescription, setShortDescription] = useState("")

  const [fatherCode, setFatherCode] = useState("")
  const [fatherDescription, setFatherDescription] = useState("")
  const [fatherSge, setFatherSge] = useState("")

  const [comission, setComission] = useState(0)
  const [periodic, setPeriodic] = useState(1)
  const [appearSaler, setAppearSaler] = useState(1)
  const [situation, setSituation] = useState(1)
  const [pathPhoto, setPathPhoto] = useState("")
  const [sge, setSge] = useState("")

  const [jDepartment, setJDepartment] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setHierarchy("")
      setDescription("")
      setShortDescription("")

      setComission(0)
      setPeriodic(1)
      setAppearSaler(1)
      setSituation(1)
      setPathPhoto("")
      setSge("")
    }
    else {
      setHierarchy(jDepartment.HIERARCHY)
      setDescription(jDepartment.DESCRIPTION)
      setShortDescription(jDepartment.SHORT_DESCRIPTION)

      if ((jDepartment.FATHER !== undefined) && (jDepartment.FATHER !== null)){
        setFatherCode(jDepartment.FATHER.ID)
        setFatherDescription(jDepartment.FATHER.DESCRIPTION)
        if (jDepartment.SGE !== 0) setFatherSge(jDepartment.FATHER.SGE)
      }
      else
      {
        setFatherCode("")
        setFatherDescription("")
        setFatherSge("")
      }

      setComission(jDepartment.COMMISSION)
      setPeriodic(jDepartment.PERIODIC)
      setAppearSaler(jDepartment.APPEAR_SALER)
      setSituation(jDepartment.SITUATION)

      if ((jDepartment.PATH_PHOTO !== null) && (jDepartment.PATH_PHOTO !== undefined))
        setPathPhoto(jDepartment.PATH_PHOTO)

      if ((jDepartment.SGE !== null) && (jDepartment.SGE !== undefined)) setSge(jDepartment.SGE)
    }

    document.getElementById("departmentDescription") && document.getElementById("departmentDescription").focus()

  }, [code, jDepartment, updateFocu])

  const add = (item) => {
    let returnJson = []
    var jsonDepartment = ""
    var nextNumber = ""
        
    setError("")
    setWarning("")
    setLoading("Aguarde buscando as informações...")

    ////
    // New Department
    ////
    if ((item === undefined) || ((item.HIERARCHY === undefined) && (item.ID === undefined)))
    {
      jsonDepartment = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"
      setFatherCode("")
      setFatherDescription("")
      setFatherSge("")
    }
    else
    {
      jsonDepartment = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" },"
      jsonDepartment += " \"HIERARCHY\": \""+ item.HIERARCHY +"\","
      jsonDepartment += " \"FATHER\": { \"ID\": "+ item.ID +" } }"
      
      setFatherCode(item.ID)
      setFatherDescription(item.DESCRIPTION)
      if ((item.SGE !== null) && (item.SGE !== undefined)) setFatherSge(item.SGE)      
    }

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonDepartment)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "DEPARTMENT/NextNumber", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter o proximo número do departamento. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("departmentDescription") && document.getElementById("departmentDescription").focus()
        return
      }
  
      returnJson.WARNING && setWarning(returnJson.WARNING)

      nextNumber = returnJson.OBJECT
      setHierarchy(nextNumber)
    })
    
    setShowSearch(false)

    if (code === "") {
      setDescription("")
      setShortDescription("")
      setComission(0)
      setPeriodic(1)
      setAppearSaler(1)
      setSituation(1)
      setPathPhoto("")
      setSge("")
    }
    else setCode("")

    setError("")
    setWarning("")
    setLoading("")
    setUpdateFocu(updateFocu + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
    
    var regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }

  const handleComission = (event) => {
    setComission(formatNumeric(event.target.value, 2))
  }
  
  const handleFile = (e) => {
    const _file = e.target.files[0];
    uploadPhoto(_file)
  }    

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonDepartment_New = {}
        
    setWarning("")
    setError("")

    jsonDepartment_New = "{ "
    if (code !== "") jsonDepartment_New += "\"ID\": "+ code
    
    if (hierarchy !== ""){
      if (jsonDepartment_New !== "{ ") jsonDepartment_New += ", "
      jsonDepartment_New += "\"HIERARCHY\": \""+ hierarchy +"\""
    }
    
    if (description !== ""){
      if (jsonDepartment_New !== "{ ") jsonDepartment_New += ", "
      jsonDepartment_New += "\"DESCRIPTION\": \""+ description +"\""    
    }
        
    if (shortDescription !== ""){
      if (jsonDepartment_New !== "{ ") jsonDepartment_New += ", "
      jsonDepartment_New += "\"SHORT_DESCRIPTION\": \""+ shortDescription +"\""
    }
    
    var cFather = 0
    if (fatherCode !== "") cFather = fatherCode
    if (cFather !== 0){
      if (jsonDepartment_New !== "{ ") jsonDepartment_New += ", "
      jsonDepartment_New += "\"FATHER\": { \"ID\": "+ cFather +", \"DESCRIPTION\": \""+ fatherDescription +"\""    
      if (fatherSge !== "") jsonDepartment_New += ", \"SGE\": "+ fatherSge
      jsonDepartment_New += " }"
    }    

    jsonDepartment_New += ", \"COMMISSION\": "+ comission +
    ", \"PERIODIC\": "+ periodic +
    ", \"APPEAR_SALER\": "+ appearSaler +
    ", \"SITUATION\": "+ situation

    if (((pathPhoto !== undefined) && (pathPhoto !== null)) && (pathPhoto !== "")) jsonDepartment_New += ", \"PATH_PHOTO\": \""+ pathPhoto + "\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonDepartment_New += ", \"SGE\": "+ sge

    jsonDepartment_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonDepartment_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "DEPARTMENT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o departamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("departmentDescription") && document.getElementById("departmentDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJDepartment(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jDepartment))
      formData.append("JSON_NEW_1", jsonDepartment_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "DEPARTMENT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o departamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("departmentDescription") && document.getElementById("departmentDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJDepartment(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = (item) => {
    setShowSearch(false)
    add(item)
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJDepartment(event)
    setCode(event.ID)
  }  

  const uploadPhoto = async(e) => {
    let returnJson = []

    setLoading("Aguarde efetuando o upload da foto do departamento...")
    setWarning("")
    setError("")

    let jsonDepartment = {}
    jsonDepartment = "{ \"ID\": "+ code +", \"HIERARCHY\": \""+ hierarchy +"\" }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", "{}")
    formData.append("JSON_NEW_1", jsonDepartment)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("FILE", e);
       
    axios.post(BASE_URL + "DEPARTMENT/UploadPhoto", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar o departamento. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("departmentDescription") && document.getElementById("departmentDescription").focus()
        return
      }

      returnJson.WARNING ? setWarning("Foto inserida com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Foto inserida com sucesso!")

      jDepartment.PATH_PHOTO = String(returnJson.OBJECT)

      setLoading("")
      setUpdateFocu(updateFocu + 1)
    })
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Departamento"
            titleSearch="Pesquisa dos Departamentos"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchDepartment
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                periodic={0}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={
                            (showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            : 
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="departmentHierarchy"
                                description="Hierarquia"
                                value={hierarchy}
                                maxLength={20}
                                disabled={true}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setHierarchy(event.target.value)} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 750) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="departmentSituation"
                                description="Situação"
                                value={situation || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, "+
                                  "{\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}                                  
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setSituation} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={{display: "flex", justifyContent: "center"}}>
                            <Input 
                              type="file"
                              name="departmentPhoto"
                              title=""
                              maxLength={255}
                              typeImage={3}
                              disabled={false}
                              placeholder=""
                              grow={1}
                              accept=".jpg,.jpeg,.png,.bmp"
                              pathPhoto={pathPhoto}
                              onChange={handleFile} />
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(showMenu) ?
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  : (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                <Input
                                  type="text"
                                  name="departmentCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                <Input
                                  type="text"
                                  name="departmentHierarchy"
                                  description="Hierarquia"
                                  value={hierarchy}
                                  maxLength={20}
                                  disabled={true}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setHierarchy(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                                : (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                              :
                                (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="departmentSituation"
                                description="Situação"
                                value={situation || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, "+
                                  " {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setSituation} />
                            </div>
                          </div>
                        }

                        <div style={(showMenu) ?
                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          : 
                            (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="departmentDescription"
                              description="Descrição"
                              value={description}
                              maxLength={150}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                          <div style={(showMenu) ?
                              (width < 1100) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 280, maxWidth: 280}
                            :
                              (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 280, maxWidth: 280}}>

                            <Input
                              type="text"
                              name="departmentShortDescription"
                              description="Descrição Resumida"
                              value={shortDescription}
                              maxLength={50}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setShortDescription(event.target.value)} />
                          </div>
                        </div>

                        <div style={(showMenu) ?
                            (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(showMenu) ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <InputNumeric
                                type="text"
                                name="departmentComission"
                                description="Comissão"
                                value={comission || ""}
                                maxLength={13}
                                disabled={false}
                                require={false}
                                negative={false} 
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={handleComission} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              : 
                                (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="departmentPeriodic"
                                description="Periódico"
                                value={periodic || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" },"+
                                  " {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setPeriodic} />
                            </div>
                          </div>
                          <div style={(showMenu) ?
                              (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180}
                            :
                              (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180}}>

                            <Select
                              name="departmentAppearSaler"
                              description="Aparecer Venda"
                              value={appearSaler || 0}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" },"+
                                " {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setAppearSaler} />
                          </div>
                        </div>

                        <div style={{marginTop: 20}}>
                          <label style={{fontWeight: 600}}>Pai:</label>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="text"
                              name="departmentFatherDescription"
                              description=""
                              value={fatherDescription}
                              maxLength={150}
                              disabled={true}
                              require={false}
                              image={false}
                              typeInput={2} 
                              onSelect={setFieldSelected} />
                          </div>
                        </div> 
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Department