import React, { useState, useEffect } from "react"

const AccessControlManagement = ({permissionAccounting, permissionCompany, permissionDigitalCertificate, permissionEstablishment,  
  permissionMentesPensam, setAccounting, setCompany, setDigitalCertificate, setEstablishment, setMentesPensam, showMenu, width}) => {

  const [accountingF, setAccountingF] = useState(1)
  const [companyF, setCompanyF] = useState(1)
  const [digitalCertificateF, setDigitalCertificateF] = useState(1)
  const [establishmentF, setEstablishmentF] = useState(1)
  const [mentesPensamF, setMentesPensamF] = useState(1)

  const handleAccounting = () => {
    setAccountingF(2)
    setAccounting("F")
  }

  const handleAccountingC = () => {
    setAccountingF(1)
    setAccounting("T")
  }

  const handleCompany = () => {
    setCompanyF(2)
    setCompany("F")
  }

  const handleCompanyC = () => {
    setCompanyF(1)
    setCompany("T")
  }

  const handleDigitalCertificate = () => {
    setDigitalCertificate(2)
    setDigitalCertificate("F")
  }

  const handleDigitalCertificateC = () => {
    setDigitalCertificateF(1)
    setDigitalCertificate("T")
  }

  const handleEstablishment = () => {
    setEstablishmentF(2)
    setEstablishment("F")
  }

  const handleEstablishmentC = () => {
    setEstablishmentF(1)
    setEstablishment("T")
  }

  const handleMentesPensam = () => {
    setMentesPensamF(2)
    setMentesPensam("F")
  }

  const handleMentesPensamC = () => {
    setMentesPensamF(1)
    setMentesPensam("T")
  }

  useEffect(() => {
    if (permissionAccounting === "T") setAccountingF(1)
    else setAccountingF(2)

    if (permissionCompany === "T") setCompanyF(1)
    else setCompanyF(2)

    if (permissionDigitalCertificate === "T") setDigitalCertificateF(1)
    else setDigitalCertificateF(2)

    if (permissionEstablishment === "T") setEstablishmentF(1)
    else setEstablishmentF(2)

    if (permissionMentesPensam === "T") setMentesPensamF(1)
    else setMentesPensamF(2)

  }, [permissionAccounting, permissionCompany, permissionEstablishment, permissionDigitalCertificate, permissionMentesPensam])

  return(
    <div style={{marginTop: 10}}>
      <h3>Gerencial</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Establishment */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Estabelecimento</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlEstablishment"
                checked={(establishmentF === 1) ? true : false}
                onChange={(establishmentF === 1) ? handleEstablishment : handleEstablishmentC} />
            </div>
          </div>
        </div>
      </div>

      {/* Company */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Filial</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCompany"
                checked={(companyF === 1) ? true : false}
                onChange={(companyF === 1) ? handleCompany : handleCompanyC} />
            </div>
          </div>
        </div>
      </div>

      {/* Accounting */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Contabilidade</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAccounting"
                checked={(accountingF === 1) ? true : false}
                onChange={(accountingF === 1) ? handleAccounting : handleAccountingC} />
            </div>
          </div>
        </div>
      </div>

      {/* Digital Certificate */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Certificado Digital</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlDigitalCertificate"
                checked={(digitalCertificateF === 1) ? true : false}
                onChange={(digitalCertificateF === 1) ? handleDigitalCertificate : handleDigitalCertificateC} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 2}}>
        <h4>Relatório</h4>
      </div>

      {/* Mentes que Pensam */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Mentes que Pensam</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlMentesPensam"
                checked={(mentesPensamF === 1) ? true : false}
                onChange={(mentesPensamF === 1) ? handleMentesPensam : handleMentesPensamC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlManagement