import "./style.css"
import React, { useEffect, useState } from "react"
import img_boneco from "../../Images/usuario.png"

const Select = ({name, description, value, disabled, require, options, image, typeImage, typeInput, onSelect, set}) => {
  const [obrigatorio, setObrigatorio] = useState("")
  const [colorFocu, setColorFocu] = useState( (typeInput === 1) ? "corpoUnFocuLogin_select" : "corpoUnFocuTela_select" )
  const descricaoSelect = (typeInput === 1) ? "descricaoLogin_select" : "descricaoTela_select"

  useEffect(() => {
    if (String(value) !== "0"){
      setObrigatorio("naoObrigatorio_select")
    }
    else
    {
      if (require){
        if (String(value) !== "0") setObrigatorio("naoObrigatorio_select")
        else setObrigatorio("obrigatorio_select")
      } else setObrigatorio("naoObrigatorio_select")
    }
  }, [require, value])

  function handleChange(event){
    set(event.target.value)

    if (event.target.value === "0")
    {
      if (require) setObrigatorio("obrigatorio_select");
      else setObrigatorio("naoObrigatorio_select")
    } else setObrigatorio("naoObrigatorio_select")
  }

  function handleBlur(){
    if (typeInput === 1) setColorFocu("corpoUnFocuLogin_select")
    else if (typeInput === 2) setColorFocu("corpoUnFocuTela_select")    
  }

  function handleFocu(){
    if (typeInput === 1) setColorFocu("corpoFocuLogin_select")
    else if (typeInput === 2) setColorFocu("corpoFocuTela_select")

    onSelect(name)
  }  

  return(
    <div style={{ width: "100%" }}>

      <div id="select">

        { description &&
          <div id={descricaoSelect}>
            <label>{description}</label>
          </div>
        }

        <div id={colorFocu}>

          { image &&
            <>
              { (typeImage === 1) && 
                <div className="corpoImagem_select">
                  <img src={img_boneco} alt="boneco" style={{ width: 23, height: 30 }} /> 
                </div>
              }

              <div id="corpoCampoImagem_select">
                <select
                  id={name}
                  className={obrigatorio}
                  value={value}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocu} >

                  {options.RESULT.map((index) => (
                    <option key={"opt"+ name + index.ID} value={index.ID}>{index.VALUE}</option>
                  ))}
                </select>
              </div>
            </>
          }

          { !image &&
            <>
              <div id="corpoCampoSemImagem_select">
                <select
                  id={name}
                  className={obrigatorio}
                  value={value}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={handleFocu} >

                  {options.RESULT.map((index) => (
                    <option key={"opt"+ name + index.ID} value={index.ID}>{index.VALUE}</option>
                  ))}
                </select>
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default Select