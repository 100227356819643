import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import SearchAgency from "./Search"
import SearchBank from "../Bank/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_search from "../../Images/Buttons/search.png"

const Agency = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [number, setNumber] = useState("")
  const [description, setDescription] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [bankDescription, setBankDescription] = useState("")

  const [jsonAgency, setJsonAgency] = useState("")

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showBank, setShowBank] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setNumber("")
      setDescription("")

      setBankCode("")
      setBankDescription("")
    }
    else {
      setNumber(jsonAgency.NUMBER)
      setDescription(jsonAgency.DESCRIPTION)

      setBankCode(jsonAgency.BANK.ID)
      setBankDescription(jsonAgency.BANK.DESCRIPTION)
    }

    document.getElementById("agencyNumber") && document.getElementById("agencyNumber").focus()
  }, [code, jsonAgency, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")
    
    if (code === "") {
      setNumber("")
      setBankCode("")
      setBankDescription("")
    } 
    else setCode("")
    
    setUpdateFocu(updateFocu + 1)
  }

  const cleanBank = () => {
    setBankCode("")
    setBankDescription("")
  }  

  const enableBank = () => {
    setShowBank(!showBank)
  }

  const fillBank = (bank) => {
    setBankCode(bank.ID)
    setBankDescription(bank.DESCRIPTION)
    setShowBank(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")
    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonAgency_New = {}

    setWarning("")
    setError("")

    jsonAgency_New = "{"
    if (code !== "") jsonAgency_New += " \"ID\": \""+ code + "\""

    if (jsonAgency_New !== "{") jsonAgency_New += ","
    if (bankCode !== "") jsonAgency_New += " \"BANK\": { \"ID\": "+ bankCode + ", \"DESCRIPTION\": \""+  bankDescription +"\" }"
    
    if (jsonAgency_New !== "{") jsonAgency_New += ","
    jsonAgency_New += " \"NUMBER\": \""+ number +"\", \"DESCRIPTION\": \""+ description +"\" }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonAgency_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "AGENCY/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a agência. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("agencyNumber") && document.getElementById("agencyNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonAgency(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonAgency))
      formData.append("JSON_NEW_1", jsonAgency_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "AGENCY/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a agência. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("agencyNumber") && document.getElementById("agencyNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonAgency(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  } 

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonAgency(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Agência"
            titleSearch="Pesquisa das Agências"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchAgency
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showBank ?
                          <>
                            <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                            </div>
                          
                            <div style={{paddingLeft: 20, paddingRight: 20}}>
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                paddingLeft: 10}}>
                                { warning && 
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                  </div> 
                                }

                                { error &&
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                  </div>
                                }
                              </div>

                              { (code === "") &&
                                <div style={(showMenu) ?
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 640) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        
                                    <Input
                                      type="text"
                                      name="agencyNumber"
                                      description="Número"
                                      planceHolder=""
                                      value={number}
                                      maxLength={6}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setNumber(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 640) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="agencyDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={description}
                                      maxLength={50}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>
                              }

                              { (code !== "") &&
                                <div style={(showMenu) ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexBasis: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                      <Input
                                        type="text"
                                        name="agencyCode"
                                        description="Código"
                                        value={code}
                                        maxLength={11}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCode(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 980) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="agencyNumber"
                                        description="Número"
                                        planceHolder=""
                                        value={number}
                                        maxLength={6}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setNumber(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : 
                                      (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="agencyDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={description}
                                      maxLength={50}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>
                              }

                              <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                                <Titulo description="Banco" />
                              </div>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, minWidth: 180}
                                : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                                <div style={{display: "flex", flexGrow: 1}}>
                                  <Input
                                    type="search"
                                    name="agencyBankDescription"
                                    description="Descrição"
                                    planceHolder=""
                                    value={bankDescription}
                                    maxLength={255}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setBankDescription(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                  <img
                                    style={{height: 42, width: 42}}
                                    name="agencySearchBank"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={enableBank} />

                                  <img
                                    style={{marginLeft: 10, height: 42, width: 42}}
                                    name="agencyCleanBank"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanBank} />
                                </div>
                              </div>
                            </div>
                          </>
                        :
                          <div style={{marginTop: 10}}>
                            <div style={{marginLeft: 10}}>
                              <b>Selecione o banco:</b>
                            </div>

                            <SearchBank
                              modeSearch={true}
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system}
                              setAdd={() => alert("")}
                              setSelected={fillBank} 
                              setReturn={() => setShowBank(false)} 
                              showMenu={showMenu}
                              width={width} />
                          </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Agency