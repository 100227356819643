import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Questionnaire from "../../../Components/Questionnaire"
import ServiceItemCnae from "../"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchServiceItemCnae = ({modeSearch, databaseType, databasePath, jsonServiceCodeCnae, jsonCompany, jsonLogin, system, setSelectedSearch, setReturn,
  showMenu, width}) => {

  const [code, setCode] = useState("")

  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonServiceItemCnae, setJsonServiceItemCnae] = useState("")
    
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações...")

    let jServiceItemCnae = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (!modeSearch) jServiceItemCnae += ", \"SERVICE_CODE_CNAE\": { \"CODE\": \"" + jsonServiceCodeCnae.CODE + "\" } }"
    else jServiceItemCnae += " }"

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jServiceItemCnae)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os itens do serviços CNAE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonLogin, system, jsonServiceCodeCnae, update])

  const add = () => {
    const jServiceItemCnae = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"+
      ", \"SERVICE_CODE_CNAE\": { \"CODE\": \"" + jsonServiceCodeCnae.CODE + "\" } }"

    setJsonServiceItemCnae(JSON.parse(jServiceItemCnae))
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const callDelete = (item) => {
    setCode(item.CODE)
    setAskQuestionnaire("Deseja realmente excluir o item do serviço CNAE (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []

    const jServiceItemCnae = "{ \"CODE\": \""+ code +"\", \"COMPANY\": { \"ID\": \""+ jsonCompany.ID +"\" }"+
      ", \"SERVICE_CODE_CNAE\": { \"CODE\": \""+ jsonServiceCodeCnae.CODE +"\" } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jServiceItemCnae)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o item do serviço CNAE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      setUpdate(update + 1)
    })
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const returnAdd = () => {
    setError("")
    setWarning("")
    setShowChange(false)
  }

  const save = (serviceItemCnae) => {  
    let returnJson = []

    setWarning("")
    setError("")

    if (JSON.parse(serviceItemCnae).TYPE === 1)
    {
      setLoading("Aguarde incluindo o item do serviço CNAE...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", serviceItemCnae)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o item do serviço CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        alert("Inclusão efetuada com sucesso (" + returnJson.OBJECT.DESCRIPTION +")!")
        setShowChange(false)
        setShowChange(false)
        setLoading("")
        
        setUpdate(update + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o item do serviço CNAE...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonServiceItemCnae))
      formData.append("JSON_NEW_1", serviceItemCnae)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "SERVICE_ITEM_CNAE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o item do serviço CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        alert("Alteração efetuada com sucesso (" + returnJson.OBJECT.DESCRIPTION +")!")
        setShowChange(false)
        setShowChange(false)
        setLoading("")
        
        setUpdate(update + 1)
      })
    }
  }

  const setSelected = (event) => {
    setJsonServiceItemCnae(event)
    setShowChange(true)
  }  

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1 }
      : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <div style={{marginTop: 10}}>
                  <Questionnaire
                    ask={askQuestionnaire}
                    setConfirm={() => confirmDelete()}
                    setCancel={() => cancelDelete()} />
                </div>
              :
                <>
                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div> 
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div> 
                    }

                    { showChange ?
                        <ServiceItemCnae
                          jsonServiceItemCnae={jsonServiceItemCnae}
                          save={save}
                          returnAdd={returnAdd} 
                          showMenu={showMenu}
                          width={width} />
                      :
                        <>
                          { !modeSearch ?
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                                  marginTop: 10, marginLeft: 5, width: 147}} onClick={(() => add())}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                                  </div>
                                </div>
                              </div>
                            :
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                                    marginTop: 10, marginLeft: 5, width: 147}} onClick={(() => setReturn())}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                    </div>
                                  </div>
                                </div>
                          }                         

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                          </div>            

                          { result.OBJECT &&
                            <>
                              <div style={!modeSearch ?
                                  {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                  borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, marginLeft: 5,
                                  minWidth: 180}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                  borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 10, marginBottom: 4, marginLeft: 5,
                                  minWidth: 180}}>

                                <div style={(showMenu) ?
                                    (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 140, maxWidth: 140}
                                    :
                                      (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 140, maxWidth: 140}}>
                                    <label style={{fontWeight: 600}}>Código</label>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Descrição</label>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120}
                                      :
                                        (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120}}>
                                      <label style={{fontWeight: 600}}>Alíquota</label>
                                    </div>
                                  </div>
                                </div>

                                { (width >= 450) &&
                                  <div style={{display: "flex", flexGrow: 0, marginRight: 7}}>
                                    <div style={{height: 32, width: 32}} />

                                    { !modeSearch &&
                                      <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} />
                                    }
                                    
                                  </div>
                                }
                              </div>

                              { result.OBJECT.map(item => (
                                <div key={item.CODE} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, paddingBottom: 8,
                                  marginTop: 4, minWidth: 180} 
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                  marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ?
                                      (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    :
                                      (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 140, maxWidth: 140}
                                      :
                                        (width < 880) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 140, maxWidth: 140}}>
                                      <label>{item.CODE}</label>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                        <label>{item.DESCRIPTION}</label>
                                      </div>

                                      <div style={(showMenu) ?
                                          (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120}
                                        :
                                          (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120}}>
                                        <label>{formatNumeric(item.ALIQUOT, 2)}</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexGrow: 0}}>

                                    <img
                                      style={{height: 32, width: 32}}
                                      src={image_select}
                                      name="searchServiceItemCnaeSelect"
                                      alt="Selecionar"
                                      onClick={!modeSearch ? () => setSelected(item) : () => setSelectedSearch(item)} />

                                    { !modeSearch &&
                                      <img
                                        style={{height: 28, width: 28, marginTop: 2, marginLeft: 10}}
                                        src={image_delete}
                                        name="searchServiceItemCnaeDelete"
                                        alt="Deletar"
                                        onClick={() => callDelete(item)} />
                                    }
                                    
                                  </div>
                                </div>
                                ))
                              }
                            </>
                          }
                          
                          { !result.OBJECT && 
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                          }

                        </>
                    }
                  </div>
                </>

            }
          </div>

      }
    </div>
  )
}

export default SearchServiceItemCnae