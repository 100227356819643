import React, { useState, useEffect } from "react"
import InputMasked from "../../Components/InputMasked"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const AccountingPhone = ({jsonAccountingPhone, save, back, width}) => {
  const [fistAccess, setFistAccess] = useState(true)

  const [code, setCode] = useState("")
  const [accountingCode, setAccountingCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [number, setNumber] = useState("")

  useEffect(() => {       
    if (fistAccess){
      setCode(jsonAccountingPhone.ID)
      setCompanyCode(jsonAccountingPhone.ACCOUNTING.COMPANY.ID)
      setAccountingCode(jsonAccountingPhone.ACCOUNTING.ID)      
      setNumber(jsonAccountingPhone.NUMBER)      
      setFistAccess(false)      
    }
    else document.getElementById("accountingPhoneNumber") && document.getElementById("accountingPhoneNumber").focus()

  }, [fistAccess, jsonAccountingPhone])

  const callSave = () => {
    let json = "{"        
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"ACCOUNTING\": { \"ID\": " + accountingCode + ", \"COMPANY\": { \"ID\": "+ companyCode +" } }"
    if (number !== undefined) json += ", \"NUMBER\": \""+ number.replaceAll("(","").replaceAll(")","").replaceAll("_","") +"\""
    else json += ", \"NUMBER\": \"\""
    json += " }"

    save(JSON.parse(json))
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={callSave}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }
        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
            <InputMasked
              name="accountingPhoneNumber"
              description="Telefone"
              planceHolder=""
              mask="(999)999999999"
              value={number}
              disabled={false}
              require={true}
              image={false}
              typeImage={0}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setNumber(event.target.value)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountingPhone