import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DataPicker from "../../Components/DataPicker"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import image_07824993000165 from '../../Images/logos/07824993000165.png';
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_printer from "../../Images/Buttons/printer.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select_circle.png"

const ReportBillPay = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [dataType, setDataType] = useState("0")
  const [periodIni, setPeriodIni] = useState(null)
  const [periodEnd, setPeriodEnd] = useState(null)
  const [order, setOrder] = useState("1")
  const [situation, setSituation] = useState("0")
  const [type, setType] = useState("0")

  const [documentTypeDescription, setDocumentTypeDescription] = useState("")
  const [documentTypeDescriptionSelected, setDocumentTypeDescriptionSelected] = useState("")
  const [resultDocumentType, setResultDocumentType] = useState({})
  const [resultDocumentTypeFilter, setResultDocumentTypeFilter] = useState({})

  const [accountPlanDescription, setAccountPlanDescription] = useState("")
  const [accountPlanDescriptionSelected, setAccountPlanDescriptionSelected] = useState("")
  const [resultAccountPlan, setResultAccountPlan] = useState({})
  const [resultAccountPlanFilter, setResultAccountPlanFilter] = useState({})

  const [supplierCorporateName, setSupplierCorporateName] = useState("")
  const [supplierCorporateNameSelected, setSupplierCorporateNameSelected] = useState("")
  const [resultSupplier, setResultSupplier] = useState({})
  const [resultSupplierFilter, setResultSupplierFilter] = useState([])

  const [accountOwner, setAccountOwner] = useState("")
  const [accountOwnerSelected, setAccountOwnerSelected] = useState("")
  const [resultAccount, setResultAccount] = useState({})
  const [resultAccountFilter, setResultAccountFilter] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showDocumentType, setShowDocumentType] = useState(false)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showSupplier, setShowSupplier] = useState(false)
  const [showAccount, setShowAccount] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)

  useEffect(() => {
    if (firstAccess){
      let returnJson = []

      ////
      // Get Document Type information // Carrega as informações dos Tipos de Documento
      ////
      setLoading("Aguarde buscando as informações dos Tipos de Documentos...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_PAY/SearchDocumentType", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Tipos de Documentos. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          alert(returnJson.ERROR)
          setReturn()
          return
        }

        let arrDocumentType = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrDocumentType.push(item)
          ))
        setResultDocumentType(arrDocumentType)

        let arrDocumentTypeFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrDocumentTypeFilter.push(item)
          ))
        setResultDocumentTypeFilter(arrDocumentTypeFilter)

        ////
        // Get Account Plan information // Carrega as informações dos Planos de Conta
        ////
        setLoading("Aguarde buscando as informações dos Planos de Conta...")
        setWarning("")
        setError("")

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)

        axios.post(BASE_URL + "BILL_PAY/SearchAccountPlan", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os Planos de Conta. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            alert(returnJson.ERROR)
            setReturn()
            return
          }

          let arrAccountPlan = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrAccountPlan.push(item)
            ))
          setResultAccountPlan(arrAccountPlan)

          let arrAccountPlanFilter = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrAccountPlanFilter.push(item)
            ))
          setResultAccountPlanFilter(arrAccountPlanFilter)

          ////
          // Get Supplier information // Carrega as informações dos Fornecedores
          ////
          setLoading("Aguarde buscando as informações dos Fornecedores...")
          setWarning("")
          setError("")

          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)

          axios.post(BASE_URL + "BILL_PAY/SearchSupplier", formData) 
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os Fornecedores. ("+ response +")\" }") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              alert(returnJson.ERROR)
              setReturn()
              return
            }

            let arrSupplier = []
            returnJson.OBJECT &&
              returnJson.OBJECT.map(item => (
                arrSupplier.push(item)
              ))
            setResultSupplier(arrSupplier)

            let arrSupplierFilter = []
            returnJson.OBJECT &&
              returnJson.OBJECT.map(item => (
                arrSupplierFilter.push(item)
              ))
            setResultSupplierFilter(arrSupplierFilter)

            ////
            // Get Account information // Carrega as informações dos Contas
            ////
            setLoading("Aguarde buscando as informações das Contas...")
            setWarning("")
            setError("")

            formData = new FormData()
            formData.append("DATABASE_TYPE", databaseType)
            formData.append("DATABASE_PATH", databasePath)
            formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
            formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
            formData.append("SYSTEM", system)

            axios.post(BASE_URL + "BILL_PAY/SearchAccount", formData) 
            .then((response) => { returnJson = JSON.parse(response.data) })
            .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
              ", \"ERROR\": \"Não foi possível pesquisar as Contas. ("+ response +")\" }") })
            .finally(() => {
              if (returnJson.SUCCESS === false){
                alert(returnJson.ERROR)
                setReturn()
                return
              }

              let arrAccount = []
              returnJson.OBJECT &&
                returnJson.OBJECT.map(item => (
                  arrAccount.push(item)
                ))
              setResultAccount(arrAccount)

              let arrAccountFilter = []
              returnJson.OBJECT &&
                returnJson.OBJECT.map(item => (
                  arrAccountFilter.push(item)
                ))
              setResultAccountFilter(arrAccountFilter)
            })

            setFirstAccess(false)
            setLoading("")
          })
        })
      })
    }

    if (showDocumentType){
      let arrDocumentType = []

      if (documentTypeDescription === ""){
        resultDocumentType.map(item => (
          arrDocumentType.push(item)
        ))
      }
      else{
        resultDocumentType.map(item => (
          (item.DESCRIPTION.indexOf(documentTypeDescription.toUpperCase()) !== -1) &&
            arrDocumentType.push(item)
        ))
      }
      setResultDocumentTypeFilter(arrDocumentType)
    }

    if (showAccountPlan){
      let arrAccountPlan = []

      if (accountPlanDescription === ""){
        resultAccountPlan.map(item => (
          arrAccountPlan.push(item)
        ))
      }
      else{
        resultAccountPlan.map(item => (
          (item.DESCRIPTION.indexOf(accountPlanDescription.toUpperCase()) !== -1) &&
            arrAccountPlan.push(item)
        ))
      }
      setResultAccountPlanFilter(arrAccountPlan)
    }

    if (showSupplier){
      let arrSupplier = []
  
      if (supplierCorporateName === ""){
        resultSupplier.map(item => (
          arrSupplier.push(item)
        ))
      }
      else{
        resultSupplier.map(item => (
          (item.CORPORATE_NAME.indexOf(supplierCorporateName.toUpperCase()) !== -1) &&
            arrSupplier.push(item)
        ))
      }
      setResultSupplierFilter(arrSupplier)
    }

    if (showAccount){
      let arrAccount = []
  
      if (accountOwner === ""){
        resultAccount.map(item => (
          arrAccount.push(item)
        ))
      }
      else{
        resultAccount.map(item => (
        (item.OWNER.indexOf(accountOwner.toUpperCase()) !== -1) &&
          arrAccount.push(item)
        ))
      }
      setResultAccountFilter(arrAccount)
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, documentTypeDescription, accountPlanDescription, supplierCorporateName, accountOwner])
 
  const addAccount = () => {  
    cleanAccount()
    setShowAccount(true)
  }

  const addAccountPlan = () => {  
    cleanAccountPlan()
    setShowAccountPlan(true)
  }

  const addDocumentType = () => {
    cleanDocumentType()
    setShowDocumentType(true)
  }

  const addSupplier = () => {
    cleanSupplier()
    setShowSupplier(true)
  }

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setDataType("0")
    setPeriodIni(null)
    setPeriodEnd(null)
    setOrder("1")
    setSituation("0")
    setType("0")

    setShowAccountPlan(false)
    setShowDocumentType(false)
    setShowSupplier(false)
    setShowAccount(false)

    setResultSupplierFilter(resultSupplierFilter)
    setResultDocumentTypeFilter(resultDocumentTypeFilter)
    setResultAccountPlanFilter(resultAccountPlanFilter)
    setResultAccountFilter(resultAccountFilter)

    setDocumentTypeDescriptionSelected("")
    setDocumentTypeDescription("")
    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setSupplierCorporateNameSelected("")
    setSupplierCorporateName("")
    setAccountOwnerSelected("")
    setAccountOwner("")
  }

  const cleanAccount = () => {
    resultAccount.map(item => (
      item.MARKET = false
    ))

    setResultAccountFilter(resultAccount)

    setAccountOwnerSelected("")
    setAccountOwner("")
    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    resultAccountPlan.map(item => (
      item.MARKET = false
    ))

    setResultAccountPlanFilter(resultAccountPlan)

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setShowAccountPlan(false)
  }

  const cleanDocumentType = () => {
    resultDocumentType.map(item => (
      item.MARKET = false
    ))

    setResultDocumentTypeFilter(resultDocumentType)

    setDocumentTypeDescriptionSelected("")
    setDocumentTypeDescription("")
    setShowDocumentType(false)
  }

  const cleanSupplier = () => {
    resultSupplier.map(item => (
      item.MARKET = false
    ))

    setResultSupplierFilter(resultSupplier)

    setSupplierCorporateNameSelected("")
    setSupplierCorporateName("")
    setShowSupplier(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const getDate = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    return today.toLocaleString();
  }

  const markAllAccount = () => {
    let selected = ""

    resultAccount.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.OWNER)
      : selected += ("," + item.ID + "-" + item.OWNER)
    ))
    
    setAccountOwnerSelected(selected)
    setShowAccount(false)
  }

  const markAllAccountPlan = () => {
    let selected = ""

    resultAccountPlan.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setAccountPlanDescriptionSelected(selected)
    setShowAccountPlan(false)
  }

  const markAllDocumentType = () => {
    let selected = ""

    resultDocumentType.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDocumentTypeDescriptionSelected(selected)
    setShowDocumentType(false)
  }

  const markAllSupplier = () => {
    let selected = ""

    resultSupplier.map(item => (
      (selected === "") ? selected = (item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
      : selected += ("," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
    ))
    
    setSupplierCorporateNameSelected(selected)
    setShowSupplier(false)
  }

  const print = () => {
    window.print()
  }

  const returnSearch = () => {
    setDataType("0")    
    setPeriodIni(null)
    setPeriodEnd(null)
    setOrder("1")
    setSituation("0")
    setType("0")

    setShowAccount(false)
    setShowAccountPlan(false)
    setShowDocumentType(false)
    setShowSupplier(false)
    setShowResult(false)

    setResultAccountFilter(resultAccount)
    setResultAccountPlanFilter(resultAccountPlan)
    setResultDocumentTypeFilter(resultDocumentType)
    setResultSupplierFilter(resultSupplier)

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setDocumentTypeDescriptionSelected("")
    setDocumentTypeDescription("")
    setSupplierCorporateNameSelected("")
    setSupplierCorporateName("")
    setAccountOwnerSelected("")
    setAccountOwner("")
    
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")
  }  

  const search = () => {
    let returnJson = []

    if (dataType === "0") {
      setError("É necessário informar o tipo do período.")
      return
    }

    if (periodIni === "") {
      setError("É necessário informar o período inicial.")
      return
    }

    if (periodEnd === "") {
      setError("É necessário informar o período final.")
      return
    }

    if (type === "0") {
      setError("É necessário informar o tipo.")
      return
    }

    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações...")        

    let jBillPay = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"

    if (dataType === "1")
    {
      if ((validateDate(periodIni)) && (validateDate(periodEnd)))
      {
        const periodIniE = periodIni.toLocaleString()
        const periodEndE = periodEnd.toLocaleString()

        const periodI = String(periodIniE).substr(6,4) + "-" + String(periodIniE).substr(3,2) + "-" + String(periodIniE).substr(0,2) + "T00:00:00";
        const periodE = String(periodEndE).substr(6,4) + "-" + String(periodEndE).substr(3,2) + "-" + String(periodEndE).substr(0,2) + "T00:00:00";
        jBillPay += ", \"EMISSION_DATE_START\": \""+ periodI + "\""
        jBillPay += ", \"EMISSION_DATE_END\": \""+ periodE + "\""
      }
    } 
    else if (dataType === "2")
    {
      if ((validateDate(periodIni)) && (validateDate(periodEnd)))
      {
        const periodIniD = periodIni.toLocaleString()
        const periodEndD = periodEnd.toLocaleString()
        
        const periodI = String(periodIniD).substr(6,4) + "-" + String(periodIniD).substr(3,2) + "-" + String(periodIniD).substr(0,2) + "T00:00:00";
        const periodE = String(periodEndD).substr(6,4) + "-" + String(periodEndD).substr(3,2) + "-" + String(periodEndD).substr(0,2) + "T00:00:00";
        jBillPay += ", \"DUE_DATE_START\": \""+ periodI + "\""
        jBillPay += ", \"DUE_DATE_END\": \""+ periodE + "\""
      }
    }
    else if (dataType === "3")
    {
      if ((validateDate(periodIni)) && (validateDate(periodEnd)))
      {
        const periodIniP = periodIni.toLocaleString()
        const periodEndP = periodEnd.toLocaleString()

        const periodI = String(periodIniP).substr(6,4) + "-" + String(periodIniP).substr(3,2) + "-" + String(periodIniP).substr(0,2) + "T00:00:00";
        const periodE = String(periodEndP).substr(6,4) + "-" + String(periodEndP).substr(3,2) + "-" + String(periodEndP).substr(0,2) + "T00:00:00";
        jBillPay += ", \"PAYMENT_DATE_START\": \""+ periodI + "\""
        jBillPay += ", \"PAYMENT_DATE_END\": \""+ periodE + "\""
      }
    }

    if (documentTypeDescriptionSelected !== "") jBillPay += ", \"DOCUMENT_TYPES\": \""+ documentTypeDescriptionSelected +"\""
    if (accountPlanDescriptionSelected !== "") jBillPay += ", \"ACCOUNTS_PLAN\": \""+ accountPlanDescriptionSelected +"\""      
    if (supplierCorporateNameSelected !== "") jBillPay += ", \"SUPPLIERS\": \""+ supplierCorporateNameSelected +"\""
    if (accountOwnerSelected !== "") jBillPay += ", \"ACCOUNTS\": \""+ accountOwnerSelected +"\""
    if (situation !== "0") jBillPay += ", \"SITUATION\": \""+ situation + "\""

    jBillPay += " }"

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillPay)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("TYPE", type)
    formData.append("ORDER", order)

    axios.post(BASE_URL + "BILL_PAY/Report", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar as Contas a Pagar. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        setShowResult(false)
        return
      }

      setLoading("")
      setError("")

      if (returnJson.WARNING === "Nenhum registro encontrado.") {
        setWarning("Nenhum registro encontrado com o filtro informado.")
        return
      }

      setResult(returnJson)
      setLoading("")
      setShowResult(true)
    })
  }

  const selectAccount = () => {
    setShowAccount(false)
  }

  const selectAccountPlan = () => {
    setShowAccountPlan(false)
  }

  const selectDocumentType = () => {
    setShowDocumentType(false)
  }

  const selectSupplier = () => {
    setShowSupplier(false)
  }

  const setFieldSelected = () => {
  }

  const updateShowAccount = (id) => {
    resultAccount.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccount.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.OWNER
                       : selected = selected + "," + item.ID + "-" + item.OWNER
      : ""
    ))
    setAccountOwnerSelected(selected)
  }

  const updateShowAccountPlan = (id) => {
    resultAccountPlan.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccountPlan.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setAccountPlanDescriptionSelected(selected)
  }

  const updateShowDocumentType = (id) => {
    resultDocumentType.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultDocumentType.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setDocumentTypeDescriptionSelected(selected)    
  }

  const updateShowSupplier = (id) => {
    resultSupplier.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultSupplier.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.CPF_CNPJ + "-" + item.CORPORATE_NAME
                       : selected = selected + "," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME
      : ""
    ))
    setSupplierCorporateNameSelected(selected)
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");        

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Relatório Contas a Pagar"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { !showResult &&
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

              { loading ?
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                    <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                  </div>
                :
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={search}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={clean}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={setReturn}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                    </div>

                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                      paddingLeft: 10}}>
                      { warning && 
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                        </div> 
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                        </div>
                      }
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10, marginTop: 10, marginBottom: 10}
                      : {paddingLeft: 20, paddingRight: 20, marginTop: 10, marginBottom: 10}}>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={(width < 450) ? {paddingRight: 0, paddingLeft: 0}
                        : {paddingRight: 10, paddingLeft: 10}}>

                        <div style={showMenu ?
                            (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Select
                              name="reportBillPayDataType"
                              description="Critério da data:"
                              value={dataType || "0"}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Emissão\" }"+
                                ", {\"ID\": 2, \"VALUE\": \"Vencimento\" }, {\"ID\": 3, \"VALUE\": \"Pagamento\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setDataType} />
                          </div>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <DataPicker
                                name="reportBillPayPeriodIni"
                                description="Inicial"
                                value={periodIni}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setPeriodIni(event)} 
                                intervalo={true}
                                inicial={true}
                                endDate={periodEnd} />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 150}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 150, maxWidth: 150}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 760) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 150}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 150, maxWidth: 150}}>
                              <DataPicker
                                name="reportBillPayPeriodEnd"
                                description="Final"
                                value={periodEnd}
                                require={false}
                                onSelect={setFieldSelected}
                                onChange={(event) => setPeriodEnd(event)} 
                                intervalo={true}
                                inicial={false}
                                startDate={periodIni} />
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                            :
                              (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                            <Select
                              name="reportBillPayOrder"
                              description="Ordenar:"
                              value={order || "1"}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Vencimento\" }, {\"ID\": 2, \"VALUE\": \"Documento\" }"+
                                ", {\"ID\": 3, \"VALUE\": \"Atraso\" }, {\"ID\": 4, \"VALUE\": \"Valor\" }, {\"ID\": 5, \"VALUE\": \"Pagamento\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setOrder} />
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 930) ?  {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 660) ?  {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                            <Input
                              type="search"
                              name="reportBillPaySupplierCorporateNameSelected"
                              description="Fornecedores Selecionados"
                              value={supplierCorporateNameSelected}
                              maxLength={6000}
                              disabled={true}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected} />
                          </div>

                          <div style={(showMenu) ? 
                              (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addSupplier() })}>
                              <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanSupplier() })}>
                              <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                            </div>
                          </div>
                        </div>

                        { showSupplier &&
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 20, marginBottom: 20}}>
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{width: "100%"}}>
                                <Titulo description="Fornecedor" />
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => selectSupplier()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => markAllSupplier()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => cleanSupplier()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                                <Input
                                  type="search"
                                  name="reportBillPaySupplierCorporateName"
                                  description="Nome/Razão"
                                  value={supplierCorporateName}
                                  maxLength={500}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setSupplierCorporateName(event.target.value)} />
                              </div>

                              { resultSupplierFilter && (resultSupplierFilter.length > 0) &&
                                <div style={(showMenu) ?
                                    (width < 1170) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label style={{fontWeight: 600}}>Código</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>Nome/Razão Social</label>
                                  </div>
                                </div>
                              }

                              { resultSupplierFilter && resultSupplierFilter.map(item => (
                                <div key={item.CPF_CNPJ + "_" + item.CORPORATE_NAME} style={showMenu ?
                                    (width < 1170) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowSupplier(item.ID) } />
                                      </div>

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label>{item.ID}</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label>
                                        { item.CPF_CNPJ.length === 11 &&
                                          item.CPF_CNPJ.substr(0,3) + "." + item.CPF_CNPJ.substr(4,3) + "." + item.CPF_CNPJ.substr(6,3) + "-" + 
                                            item.CPF_CNPJ.substr(9,2)
                                        }

                                        { item.CPF_CNPJ.length === 14 &&
                                          item.CPF_CNPJ.substr(0,2) + "." + item.CPF_CNPJ.substr(2,3) + "." + item.CPF_CNPJ.substr(5,3) + "/" + 
                                            item.CPF_CNPJ.substr(8,4) + "-" + item.CPF_CNPJ.substr(12,4)
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label>
                                      {item.CORPORATE_NAME}
                                    </label>
                                  </div>
                                </div>
                                ))
                              }

                              { resultSupplierFilter && (resultSupplierFilter.length === 0) &&
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                </div>
                              }

                            </div>
                          </div>
                        }

                        <div style={(showMenu) ?
                            (width < 1540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          : 
                            (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                            :
                              (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="reportBillPayDocumentTypeDescriptionSelected"
                                description="Tipos de Documentos Selecionados"
                                value={documentTypeDescriptionSelected}
                                maxLength={6000}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected} />
                            </div>

                            <div style={(showMenu) ? 
                                (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addDocumentType() })}>
                                <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanDocumentType() })}>
                                <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>
                          
                          <div style={showMenu ?
                              (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                            :
                              (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="reportBillPayAccountPlanDescriptionSelected"
                                description="Plano de Contas Selecionados"
                                value={accountPlanDescriptionSelected}
                                maxLength={6000}
                                disabled={true}
                                require={false}
                                image={false}
                                typeInput={2} 
                                onSelect={setFieldSelected} />
                            </div>

                            <div style={(showMenu) ? 
                                (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addAccountPlan() })}>
                                <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanAccountPlan() })}>
                                <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>
                        </div>

                        { showDocumentType &&
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 20, marginBottom: 20}}>
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{width: "100%"}}>
                                <Titulo description="Tipo do Documento" />
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => selectDocumentType()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => markAllDocumentType()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => cleanDocumentType()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                                <Input
                                  type="search"
                                  name="reportBillPayDocumentTypeDescription"
                                  description="Descrição"
                                  value={documentTypeDescription}
                                  maxLength={500}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDocumentTypeDescription(event.target.value)} />
                              </div>

                              { resultDocumentTypeFilter && (resultDocumentTypeFilter.length > 0) &&
                                <div style={(showMenu) ?
                                    (width < 1030) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}
                                  :
                                    (width < 760) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                    <div style={showMenu ?
                                        (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>Descrição</label>
                                  </div>
                                </div>
                              }

                              { resultDocumentTypeFilter && resultDocumentTypeFilter.map(item => (
                                <div key={item.ID + "_" + item.DESCRIPTION} style={showMenu ?
                                    (width < 1030) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                  :
                                    (width < 760) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                      <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowDocumentType(item.ID) } />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label>{item.ID}</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label>
                                      {item.DESCRIPTION}
                                    </label>
                                  </div>
                                </div>
                                ))
                              }
                                
                              { resultDocumentTypeFilter && (resultDocumentTypeFilter.length === 0) &&
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                </div>
                              }

                            </div>
                          </div>
                        }

                        { showAccountPlan &&
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 20, marginBottom: 20}}>
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{width: "100%"}}>
                                <Titulo description="Plano de Conta" />
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => selectAccountPlan()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => markAllAccountPlan()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => cleanAccountPlan()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                                <Input
                                  type="search"
                                  name="reportBillPayAccountPlanDescription"
                                  description="Descrição"
                                  value={accountPlanDescription}
                                  maxLength={500}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setAccountPlanDescription(event.target.value)} />
                              </div>

                              { resultAccountPlanFilter && (resultAccountPlanFilter.length > 0) &&
                                <div style={(showMenu) ?
                                  (width < 1170) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 5}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 4}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label style={{fontWeight: 600}}>Código</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label style={{fontWeight: 600}}>Número</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>Descrição</label>
                                  </div>
                                </div>
                              }

                              { resultAccountPlanFilter && resultAccountPlanFilter.map(item => (
                                <div key={item.ID + "_" + item.NUMBER + "_" + item.DESCRIPTION} style={showMenu ?
                                    (width < 1170) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccountPlan(item.ID) } />
                                      </div>

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label>{item.ID}</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label>{item.NUMBER}</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label>{item.DESCRIPTION}</label>
                                  </div>
                                </div>
                                ))
                              }

                              { resultAccountPlanFilter && (resultAccountPlanFilter.length === 0) &&
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                </div>
                              }

                            </div>
                          </div>
                        }

                        <div style={(showMenu) ?
                            (width < 1540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          : 
                            (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={showMenu ?
                              (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                            :
                              (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                              : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="search"
                                name="reportBillPayAccountOwnerSelected"
                                description="Contas Selecionadas"
                                value={accountOwnerSelected}
                                maxLength={6000}
                                disabled={false}
                                require={false}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected} />
                            </div>

                            <div style={(showMenu) ? 
                                (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addAccount() })}>
                                <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanAccount() })}>
                                <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                              </div>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 820) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                            :
                              (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                              : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                              :
                                (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Select
                                name="reportBillPaySituation"
                                description="Situação:"
                                value={situation || "0"}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Pendente\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Pago\" }, {\"ID\": 3, \"VALUE\": \"Cancelado\" }"+
                                    ", {\"ID\": 4, \"VALUE\": \"Pago Parcialmente\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setSituation} />
                            </div>
                            <div style={showMenu ?
                                (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              :
                                (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                              <Select
                                name="reportBillPayType"
                                description="Tipo Exibição:"
                                value={type || "0"}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Analítico\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Sintético\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setType} />
                            </div>
                          </div>
                        </div>

                        { showAccount &&
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 20, marginBottom: 20}}>
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{width: "100%"}}>
                                <Titulo description="Conta" />
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => selectAccount()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => markAllAccount()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => cleanAccount()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                                <Input
                                  type="search"
                                  name="reportBillPayAccountOwner"
                                  description="Descrição"
                                  value={accountOwner}
                                  maxLength={50}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setAccountOwner(event.target.value)} />
                              </div>

                              { resultAccountFilter && (resultAccountFilter.length > 0) &&
                                <div style={(showMenu) ?
                                  (width < 1170) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 5}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1,
                                      borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                      marginTop: 30, marginBottom: 4}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 5}
                                    :
                                      {display: "flex", flexDirection: "row", flexGrow: 1,
                                        borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                        marginTop: 30, marginBottom: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label style={{fontWeight: 600}}>Código</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label style={{fontWeight: 600}}>Número</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>Descrição</label>
                                  </div>
                                </div>
                              }

                              { resultAccountFilter && resultAccountFilter.map(item => (
                                <div key={item.ID + "_" + item.NUMBER + "_" + item.OWNER} style={showMenu ?
                                    (width < 1170) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                  :
                                    (width < 900) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                  <div style={(showMenu) ? 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ? 
                                        (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                        <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccount(item.ID) } />
                                      </div>

                                      <div style={showMenu ?
                                          (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label>{item.ID}</label>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                      :  
                                        (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                      <label>{item.NUMBER}</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label>{item.OWNER}</label>
                                  </div>
                                </div>
                                ))
                              }

                              { resultAccountFilter && (resultAccountFilter.length === 0) &&
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                </div>
                              }

                            </div>
                          </div>
                        }
                 
                      </div>
                    </div>
                  </div>
              }

            </div>
          }

          { showResult &&
            <>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={print}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={returnSearch}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>
               
              <div id="print">
                <div style={showMenu ?
                    (width < 980) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                  :
                    (width < 720) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}}>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}
                    :
                      (width < 720) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}}>

                      { jsonCompany.CPF_CNPJ === "07824993000165" && 
                      <img src={image_07824993000165} alt="AVATAR" /> }
                  </div>

                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                    : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>CONTA A PAGAR ({ type ? "Analítico" : "Sintético" })</label>
                    </div>

                    <div style={showMenu ?
                        (width < 980) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}
                      :
                        (width < 720) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>{getDate()}</label>
                    </div>
                  </div>
                </div>

                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginLeft: 20, marginRight: 20}}>

                  { (parseInt(type) === 1) ?
                      <>

                        { result.OBJECT.map(capa => (
                            capa.ListITEM.map((item, index) => (
                              item.TYPE === 1 ?
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", borderRightStyle: "solid", 
                                    borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", fontStretch: 600, 
                                    wordBreak: "break-all", minWidth: 180}}>
                                    <label>{capa.SUPPLIER.CPF_CNPJ} {" - "} {capa.SUPPLIER.CORPORATE_NAME}</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 1900) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :  
                                      (width < 1620) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        :
                                          (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={showMenu ?
                                            (width < 950) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", 
                                              color: "white", webkitPrintColorAdjust: "exact", wordBreak: "break-all", minWidth: 180}
                                            : 
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 110, 
                                              maxWidth: 110}
                                          :
                                            (width < 650) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", 
                                              color: "white", webkitPrintColorAdjust: "exact", wordBreak: "break-all", minWidth: 180}
                                            : 
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 110, 
                                              maxWidth: 110}}>
                                          <label>Código</label>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 950) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                              webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 1360) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                              webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 500}
                                            : (width < 1900) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                              minWidth: 500}
                                            : 
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 500, 
                                              maxWidth: 500}
                                          :
                                            (width < 650) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                              webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 1090) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                              webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 500}
                                            : (width < 1620) ? 
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                              minWidth: 500}
                                            : 
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                              paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                              backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 500, 
                                              maxWidth: 500}}>
                                          <label>Nr. / Parcela</label>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        :
                                          (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={showMenu ?
                                            (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                            : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                            : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1360) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 100, 
                                                maxWidth: 100}
                                            :
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1090) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 100, 
                                                maxWidth: 100}}>
                                            <label>Emissão</label>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", 
                                                color: "white", webkitPrintColorAdjust: "exact", wordBreak: "break-all", minWidth: 180}
                                              : (width < 790) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}

                                              : (width < 1360) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100,  maxWidth: 100}
                                            :
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", 
                                                color: "white", webkitPrintColorAdjust: "exact", wordBreak: "break-all", minWidth: 180}
                                              : (width < 520) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : (width < 1090) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100, maxWidth: 100}}>
                                            <label>Vencto</label>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                            : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                            : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1360) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100, maxWidth: 100}
                                            :
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1090) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black",
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100, maxWidth: 100}}>
                                            <label>Pagto</label>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1360) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : (width < 1900) ? 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 100, maxWidth: 100}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100, maxWidth: 100}
                                            :
                                              (width < 450) ? 
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1090) ? 
                                                {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 110}
                                              : (width < 1620) ? 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                borderRightColor: "black", borderRightWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                                webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 100, maxWidth: 100}
                                              : 
                                                {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                                minWidth: 100, maxWidth: 100}}>
                                            <label>Valor</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 450) ? 
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 690) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 200}
                                          : (width < 730) ? 
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 1900) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 200}
                                          : 
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            minWidth: 200, maxWidth: 200}
                                        :
                                          (width < 450) ? 
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 1620) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 200}
                                          : 
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            minWidth: 200, maxWidth: 200}}>
                                        <label>Situação</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ? 
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 690) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 200}
                                          : (width < 730) ? 
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 1900) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 200}
                                          : 
                                            {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1,  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            minWidth: 110, maxWidth: 110}
                                        :
                                          (width < 450) ? 
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            wordBreak: "break-all", minWidth: 180}
                                          : (width < 1620) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            minWidth: 200}
                                          : 
                                            {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, 
                                            minWidth: 110, maxWidth: 110}}>
                                        <label>Atraso</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1900) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 250}
                                        :
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 250}
                                      :
                                        (width < 450) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1620) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 250}
                                        :
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                          webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 250}}>
                                      <label>Observação</label>
                                    </div>
                                  </div>
                                </div>
                              : item.TYPE === 2 ?
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

                                  { (index % 2 === 0) ?
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                        backgroundColor: "#c9c9c9"}}>

                                        <div style={showMenu ?
                                            (width < 1900) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :  
                                            (width < 1620) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                              <div style={showMenu ?
                                                  (width < 950) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 650) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}}>
                                                <label>{item.CODE}</label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 950) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1360) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 500}
                                                  : (width < 1900) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 500}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 500, maxWidth: 500}
                                                :
                                                  (width < 650) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1090) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 500}
                                                  : (width < 1620) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 500}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 500, maxWidth: 500}}>
                                                <label>{item.DOCUMENT + "/" + item.PORTION}</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                :
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{ String(item.EMISSION_DATE).substr(8,2) + "/" +
                                                      String(item.EMISSION_DATE).substr(5,2) + "/" +
                                                        String(item.EMISSION_DATE).substr(0,4) }</label>
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 790) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}

                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 520) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{ String(item.DUE_DATE).substr(8,2) + "/" +
                                                      String(item.DUE_DATE).substr(5,2) + "/" +
                                                        String(item.DUE_DATE).substr(0,4) }</label>
                                                </div>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                :
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                      borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                      borderBottomWidth: 1, paddingLeft: 5, minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                      borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                      borderBottomWidth: 1, paddingLeft: 5, minWidth: 100, maxWidth: 100}}>
                                                  <label>
                                                    { item.PAYMENT_DATE !== "0001-01-01T00:00:00" ? String(item.PAYMENT_DATE).substr(8,2) + "/" +
                                                        String(item.PAYMENT_DATE).substr(5,2) + "/" + String(item.PAYMENT_DATE).substr(0,4)
                                                      : "-" }
                                                  </label>
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1900) ? 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1620) ? 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{formatNumeric(item.VALUE, 2)}</label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                               
                                          <div style={showMenu ?
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 690) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : (width < 730) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1900) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}
                                              :
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1620) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}}>
                                              <label>{item.SITUATION}</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 690) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 730) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1900) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                  borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1620) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                  borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                  borderBottomWidth: 1, paddingLeft: 5, minWidth: 110, maxWidth: 110}}>
                                              <label>{item.DELAY}</label>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1900) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                              :
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                            :
                                              (width < 450) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1620) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                              :
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                            <label>{ item.OBSERVATION ? item.OBSERVATION : "-" }</label>
                                          </div>
                                        </div>

                                      </div>
                                    :
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                        backgroundColor: "#dcdcdc"}}>

                                        <div style={showMenu ?
                                            (width < 1900) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :  
                                            (width < 1620) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                              <div style={showMenu ?
                                                  (width < 950) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 650) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}}>
                                                <label>{item.CODE}</label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 950) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1360) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 500}
                                                  : (width < 1900) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 500}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 500, maxWidth: 500}
                                                :
                                                  (width < 650) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1090) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, borderRightStyle: "solid", 
                                                    borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 500}
                                                  : (width < 1620) ? 
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 500}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 500, maxWidth: 500}}>
                                                <label>{item.DOCUMENT + "/" + item.PORTION}</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                :
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      paddingLeft: 5, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{ String(item.EMISSION_DATE).substr(8,2) + "/" +
                                                      String(item.EMISSION_DATE).substr(5,2) + "/" +
                                                        String(item.EMISSION_DATE).substr(0,4) }</label>
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 790) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}

                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all",
                                                      minWidth: 180}
                                                    : (width < 520) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black",  borderRightWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5,
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{ String(item.DUE_DATE).substr(8,2) + "/" +
                                                      String(item.DUE_DATE).substr(5,2) + "/" +
                                                        String(item.DUE_DATE).substr(0,4) }</label>
                                                </div>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                :
                                                  (width < 450) ? {display: "column", flexDirection: "column", flexGrow: 1}
                                                  : (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1,
                                                      paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                      borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                      borderBottomWidth: 1, paddingLeft: 5, minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      paddingLeft: 5, minWidth: 110}
                                                    : 
                                                      {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                      borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                      borderBottomWidth: 1, paddingLeft: 5, minWidth: 100, maxWidth: 100}}>
                                                  <label>
                                                    { item.PAYMENT_DATE !== "0001-01-01T00:00:00" ? String(item.PAYMENT_DATE).substr(8,2) + "/" +
                                                        String(item.PAYMENT_DATE).substr(5,2) + "/" + String(item.PAYMENT_DATE).substr(0,4)
                                                      : "-" }
                                                  </label>
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1360) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1900) ? 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                  :
                                                    (width < 450) ? 
                                                      {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", 
                                                      minWidth: 180}
                                                    : (width < 1090) ? 
                                                      {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                      borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 110}
                                                    : (width < 1620) ? 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                      borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}
                                                    : 
                                                      {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                      borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                      minWidth: 100, maxWidth: 100}}>
                                                  <label>{formatNumeric(item.VALUE, 2)}</label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
  
                                          <div style={showMenu ?
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1620) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 690) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : (width < 730) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}

                                                : (width < 1900) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}
                                              :
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1620) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  paddingLeft: 5, wordBreak: "break-all", minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}}>
                                              <label>{item.SITUATION}</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 690) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 730) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1900) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                  borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? 
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1620) ? 
                                                  {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : 
                                                  {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                  borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                  borderBottomWidth: 1, paddingLeft: 5, minWidth: 110, maxWidth: 110}}>
                                              <label>{item.DELAY}</label>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1900) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                              :
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                            :
                                              (width < 450) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                              : (width < 1620) ?
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                              :
                                                {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                            <label>{ item.OBSERVATION ? item.OBSERVATION : "-" }</label>
                                          </div>
                                        </div>
                                      </div>
                                  }

                                </div>
                              : item.TYPE === 3 ?
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact"}}>
                                  <div style={showMenu ?
                                      (width < 1350) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                      :
                                        (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                      <div style={showMenu ?
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 840) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                        :
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 570) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}}>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                          <label>Total Vencido:</label>
                                        </div>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                          <label>{formatNumeric(item.TOTAL_DUE, 2)}</label>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 840) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                          : (width < 1350) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                            borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          :
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                        :
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 570) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                          : (width < 1080) ? 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                            borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          :
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                          <label>Total a Vencer:</label>
                                        </div>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                          <label>{formatNumeric(item.TOTAL_WIN, 2)}</label>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                      :
                                        (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                      <div style={showMenu ?
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 840) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 1350) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1}
                                          : 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                        :
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 570) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 1080) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1}
                                          : 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                          <label>Total em Aberto:</label>
                                        </div>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                          <label>{formatNumeric(item.TOTAL_OPEN, 2)}</label>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 840) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          :
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", 
                                            borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                        :
                                          (width < 300) ?
                                            {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          : (width < 570) ?
                                            {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}                                        
                                          : 
                                            {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                            borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}}>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                          <label>Total Pago:</label>
                                        </div>
                                        <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                          <label>{formatNumeric(item.TOTAL_PAID, 2)}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                        borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                      : (width < 840) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                        borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                        borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}
                                    :
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                        borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                      : (width < 570) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                        borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                        borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>

                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                      <label>Total Cancelado:</label>
                                    </div>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                      <label>{formatNumeric(item.TOTAL_CANCELED, 2)}</label>
                                    </div>
                                  </div>
                                </div>
                              :
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                  webkitPrintColorAdjust: "exact"}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>
                                    <label>Totalização</label>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                    webkitPrintColorAdjust: "exact"}}>
                                    <div style={showMenu ?
                                        (width < 1350) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        :
                                          (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                        <div style={showMenu ?
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 840) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                              borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                          :
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 570) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                              borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}}>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                            <label>Total Vencido:</label>
                                          </div>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                            <label>{formatNumeric(item.TOTAL_GENERAL_DUE, 2)}</label>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 840) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                              borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                            : (width < 1350) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            :
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                          :
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 570) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                              borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                            : (width < 1080) ? 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            :
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                            <label>Total a Vencer:</label>
                                          </div>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                            <label>{formatNumeric(item.TOTAL_GENERAL_WIN, 2)}</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        :
                                          (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                        <div style={showMenu ?
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 840) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 1350) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                              borderLeftColor: "black", borderLeftWidth: 1}
                                            : 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                          :
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 570) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 1080) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                              borderLeftColor: "black", borderLeftWidth: 1}
                                            : 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                            <label>Total em Aberto:</label>
                                          </div>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                            <label>{formatNumeric(item.TOTAL_GENERAL_OPEN, 2)}</label>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 840) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            :
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                          :
                                            (width < 300) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : (width < 570) ?
                                              {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                              borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                            : 
                                              {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                              borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}}>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                            <label>Total Pago:</label>
                                          </div>
                                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                            : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                            <label>{formatNumeric(item.TOTAL_GENERAL_PAID, 2)}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 300) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                        : (width < 840) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                          borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                          borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}
                                      :
                                        (width < 300) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                        : (width < 570) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                          borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                          borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>

                                      <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                        <label>Total Cancelado:</label>
                                      </div>
                                      <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                        <label>{formatNumeric(item.TOTAL_GENERAL_CANCELED, 2)}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            ))
                          ))
                        }

                      </>
                    :
                      <>
                        { result.OBJECT.ListITEM.map((item, index) => (
                          item.TYPE === 1 ?
                            <div style={showMenu ?
                                (width < 2470) ?
                                  {display: "flex", flexDirection: "column", flexGrow: 0}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 2200) ?
                                  {display: "flex", flexDirection: "column", flexGrow: 0}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={showMenu ?
                                  (width < 1970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 2470) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row" , flexGrow: 0}
                                :
                                  (width < 1700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 2200) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row" , flexGrow: 0}}>

                                <div style={showMenu ?
                                    (width < 1970) ?
                                      {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                                      borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                      borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                    : (width < 2470) ?
                                      {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                    :
                                      {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, maxWidth: 520}
                                  :  
                                    (width < 1700) ?
                                      {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                                      borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",  borderBottomColor: "black", 
                                      borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                    : (width < 2200) ?
                                      {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                    :
                                      {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                      borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                      borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, maxWidth: 520}}>
                                  <label>Fornecedor</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 1030) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1970) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 500}
                                        :
                                          {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 500,
                                          maxWidth: 500}
                                      :
                                        (width < 760) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        :
                                        (width < 1970) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 500}
                                        :
                                          {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 500, 
                                          maxWidth: 500}}>
                                      <label>Nr. / Parcela</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 1030) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110}
                                          :
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 100, 
                                            maxWidth: 110}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black",
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110}
                                          : 
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 100, 
                                            maxWidth: 110}}>
                                        <label>Emissão</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 1030) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110}
                                          : (width < 1450) ?
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                          :
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110,
                                            maxWidth: 110}
                                        :   
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110}
                                          : (width < 1180) ?
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                          :
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110,
                                            maxWidth: 110}}>
                                        <label>Vencto</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 740) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid",
                                            borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                            borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                          : 
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, 
                                            maxWidth: 110}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 500) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 110}
                                          :
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", justifyContent: "center", borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, minWidth: 110, 
                                            maxWidth: 110}}>
                                        <label>Pagto</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 740) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                          :
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 110,
                                            maxWidth: 110}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 500) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black",  borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                          : 
                                            {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                            webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 110, 
                                            maxWidth: 110}}>
                                        <label>Valor</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1450) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                        : (width < 2470) ?
                                          {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                        :
                                          {display: "flex", flexGrow: 0, 
                                            backgroundColor: "rgb(0, 178, 177)", color: "white",
                                            webkitPrintColorAdjust: "exact",
                                            borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                          maxWidth: 200}
                                      :
                                        (width < 1180) ?
                                          {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                        : (width < 2200) ?
                                          {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                        :
                                          {display: "flex", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                          webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                          maxWidth: 200}}>
                                      <label>Situação</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 2470) ?
                                    {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                    borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                                    borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                :
                                  (width < 2200) ?
                                    {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                    borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                                    borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                <label>Plano de Conta</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 2470) ?
                                    {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid",  borderBottomColor: "black", 
                                    borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                    borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                :
                                  (width < 2200) ?
                                    {display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                    borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                  <label>Observação</label>
                              </div>
                            </div>
                          : item.TYPE === 2 ?
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

                              { (index % 2 === 0) ?
                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                    backgroundColor: "#c9c9c9"}}>

                                    <div style={showMenu ?
                                        (width < 2470) ? 
                                          {display: "flex", flexDirection: "column", flexGrow: 0}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 2200) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 0}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 1970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 2470) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row" , flexGrow: 0}
                                        :
                                          (width < 1700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 2200) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row" , flexGrow: 0}}>

                                        <div style={showMenu ?
                                            (width < 1970) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                              borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 2470) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                            :
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, 
                                              maxWidth: 520}
                                          :  
                                            (width < 1700) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                              borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 2200) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                            :
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, 
                                              maxWidth: 520}}>
                                          <label>{item.NAME}</label>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 1030) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1970) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5,
                                                  minWidth: 500, maxWidth: 500}
                                              :
                                                (width < 760) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                :
                                                (width < 1970) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500, maxWidth: 500}}>
                                              <label>{item.DOCUMENT}{"/"}{item.PORTION}</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1030) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid",
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                    borderBottomWidth: 1, minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 100, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 760) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid",
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                    borderBottomWidth: 1, minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 100, maxWidth: 110}}>
                                                <label>{ String(item.EMISSION_DATE).substr(8,2) + "/" + String(item.EMISSION_DATE).substr(5,2) + "/" +
                                                  String(item.EMISSION_DATE).substr(0,4) }</label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1030) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                    minWidth: 110}
                                                  : (width < 1450) ?
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                                :   
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 760) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                    minWidth: 110}
                                                  : (width < 1180) ?
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}}>
                                                <label>{ String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                                  String(item.DUE_DATE).substr(0,4) }</label>
                                              </div>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 740) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 500) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}}>
                                                <label>
                                                  { item.PAYMENT_DATE !== "0001-01-01T00:00:00" ? String(item.PAYMENT_DATE).substr(8,2) + "/" +
                                                      String(item.PAYMENT_DATE).substr(5,2) + "/" + String(item.PAYMENT_DATE).substr(0,4)
                                                    : "-" }
                                                </label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 740) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 500) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110, maxWidth: 110}}>
                                                <label>{formatNumeric(item.VALUE, 2)}</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1450) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 2470) ?
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}
                                              :
                                                (width < 1180) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 2200) ?
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}}>
                                              <label>{item.SITUATION}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 2470) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 250}
                                        :
                                          (width < 2200) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 250}}>
                                        <label>{item.ACCOUNT_PLAN}</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 2470) ?
                                            {display: "flex", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                            borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                        :
                                          (width < 2200) ?
                                            {display: "flex", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                            borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                          <label>{ item.OBSERVATION ? item.OBSERVATION : "-" }</label>
                                      </div>
                                    </div>

                                  </div>
                                :
                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                    backgroundColor: "#dcdcdc"}}>

                                    <div style={showMenu ?
                                        (width < 2470) ? 
                                          {display: "flex", flexDirection: "column", flexGrow: 0}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 2200) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 0}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={showMenu ?
                                          (width < 1970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 2470) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row" , flexGrow: 0}
                                        :
                                          (width < 1700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 2200) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row" , flexGrow: 0}}>

                                        <div style={showMenu ?
                                            (width < 1970) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                              borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 2470) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                            :
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, 
                                              maxWidth: 520}
                                          :  
                                            (width < 1700) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                              borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                            : (width < 2200) ?
                                              {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520}
                                            :
                                              {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                              borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 520, 
                                              maxWidth: 520}}>
                                          <label>{item.NAME}</label>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1700) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 1030) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1970) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5,
                                                  minWidth: 500, maxWidth: 500}
                                              :
                                                (width < 760) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                :
                                                (width < 1970) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 500, maxWidth: 500}}>
                                              <label>{item.DOCUMENT}{"/"}{item.PORTION}</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1030) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid",
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                    borderBottomWidth: 1, minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 100, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 760) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid",
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                                    borderBottomWidth: 1, minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 100, maxWidth: 110}}>
                                                <label>{ String(item.EMISSION_DATE).substr(8,2) + "/" + String(item.EMISSION_DATE).substr(5,2) + "/" +
                                                  String(item.EMISSION_DATE).substr(0,4) }</label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1030) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                    minWidth: 110}
                                                  : (width < 1450) ?
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                                :   
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 760) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,
                                                    minWidth: 110}
                                                  : (width < 1180) ?
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                                    minWidth: 110, maxWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}}>
                                                <label>{ String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                                  String(item.DUE_DATE).substr(0,4) }</label>
                                              </div>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 740) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 500) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, paddingLeft: 5, minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, justifyContent: "center", borderLeftStyle: "solid", 
                                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                                    borderBottomWidth: 1, minWidth: 110, maxWidth: 110}}>
                                                <label>
                                                  { item.PAYMENT_DATE !== "0001-01-01T00:00:00" ? String(item.PAYMENT_DATE).substr(8,2) + "/" +
                                                      String(item.PAYMENT_DATE).substr(5,2) + "/" + String(item.PAYMENT_DATE).substr(0,4)
                                                    : "-" }
                                                </label>
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 740) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110}
                                                  :
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5,
                                                    minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 450) ?
                                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid",
                                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 500) ?
                                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110}
                                                  : 
                                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                    minWidth: 110, maxWidth: 110}}>
                                                <label>{formatNumeric(item.VALUE, 2)}</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                                : (width < 1450) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 2470) ?
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}
                                              :
                                                (width < 1180) ?
                                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                                : (width < 2200) ?
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                                  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, maxWidth: 200}
                                                :
                                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1,
                                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                                  minWidth: 200, maxWidth: 200}}>
                                              <label>{item.SITUATION}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 2470) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 250}
                                        :
                                          (width < 2200) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                            borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 250}}>
                                        <label>{item.ACCOUNT_PLAN}</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 2470) ?
                                            {display: "flex", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                            borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}
                                        :
                                          (width < 2200) ?
                                            {display: "flex", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                            borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          :
                                            {display: "flex", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid",  borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 250}}>
                                          <label>{ item.OBSERVATION ? item.OBSERVATION : "-" }</label>
                                      </div>
                                    </div>
                                  </div>
                              }

                            </div>
                          : 
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                              webkitPrintColorAdjust: "exact"}}>

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>
                                <label>Totalização Plano de Contas</label>
                              </div>


                              { result.OBJECT.ListPLAN.map(account => (
                                  <div style={{display: "flex", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, padding: 5}}>
                                    <label>{account.DESCRIPTION}{" : "}{ formatNumeric(account.VALUE, 2) }</label>
                                  </div>
                                ))
                              }

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                borderTopStyle: "solid", borderTopColor: "black", borderTopWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                borderBottomWidth: 1}}>
                                <label>Totalização</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 1350) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  :
                                    (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                  <div style={showMenu ?
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 840) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                    :
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 570) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}}>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                      <label>Total Vencido:</label>
                                    </div>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                      <label>{formatNumeric(item.TOTAL_DUE, 2)}</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 840) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                        borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                      : (width < 1350) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                        borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                    :
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 570) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                        borderRightWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, paddingLeft: 5}
                                      : (width < 1080) ? 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                        borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                      <label>Total a Vencer:</label>
                                    </div>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                      <label>{formatNumeric(item.TOTAL_WIN, 2)}</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1350) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  :
                                    (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                  <div style={showMenu ?
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 840) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 1350) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1}
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}
                                    :
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 570) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 1080) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5, borderLeftStyle: "solid", 
                                        borderLeftColor: "black", borderLeftWidth: 1}
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  paddingLeft: 5}}>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                      <label>Total em Aberto:</label>
                                    </div>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                      <label>{formatNumeric(item.TOTAL_OPEN, 2)}</label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 840) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      :
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, borderRightStyle: "solid", 
                                        borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                    :
                                      (width < 300) ?
                                        {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : (width < 570) ?
                                        {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                        borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1,  borderRightStyle: "solid", 
                                        borderRightColor: "black", borderRightWidth: 1, paddingLeft: 5}}>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                      <label>Total Pago:</label>
                                    </div>
                                    <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 110}}>
                                      <label>{formatNumeric(item.TOTAL_PAID, 2)}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 300) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                  : (width < 840) ?
                                    {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}
                                :
                                  (width < 300) ?
                                    {display: "flex", flexDirection: "column", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                  : (width < 570) ?
                                    {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1,
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>

                                <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                  <label>Total Cancelado:</label>
                                </div>
                                <div style={(width < 300) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                  <label>{formatNumeric(item.TOTAL_CANCELED, 2)}</label>
                                </div>
                              </div>
                            </div>
                        ))}

                      </>

                  }

                  <div style={{display: "flex", flexGrow: 0, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", color: "white",
                    webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                    borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>

                    { (parseInt(type) === 1) ?
                        <label>Total de Contas a Pagar: ({result.OBJECT[0].TOTAL_RECORDS})</label>
                      : <label>Total de Contas a Pagar: ({result.OBJECT.TOTAL_RECORDS})</label>
                    }
                  </div>

                  <div style={{display: "flex", flexGrow: 0, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", color: "white",
                   webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                   borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>
                    <label>DESENVOLVEDORA DE SISTEMAS - SGE - MULTIPLA SISTEMAS - (031) 2511-0909 - www.multiplasistemas.com.br</label>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ReportBillPay