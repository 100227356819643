import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import InputMasked from "../../../Components/InputMasked"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchProduct = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, type, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [commercialBarCode, setCommercialBarCode] = useState("")
  const [taxableBarCode, setTaxableBarCode] = useState("")
  const [reference, setReference] = useState("")
  const [situation, setSituation] = useState("0")
  
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  const [errorImport, setErrorImport] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    let jProduct = "{ \"PRODUCT_COMPANY\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"
    
    if (situation !== "0") {
      jProduct += ", \"SITUATION\": "+ situation + " }"
      search = true
    } else jProduct += " }"

    if (code !== "") {
      let codeN = String(code).replaceAll("_","")
      if (codeN !== "") jProduct += ", \"ID\": "+ codeN
      search = true
    }
    if (description !== "") {
      if (description.length > 2){
        jProduct += ", \"DESCRIPTION\": \""+ description + "\""
        search = true
      }      
    }
    if (commercialBarCode !== "") {
      let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
      if (commercialBarCodeN.length > 6){
        jProduct += ", \"COMMERCIAL_BARCODE\": \""+ commercialBarCodeN + "\""
        search = true
      }      
    }
    if (taxableBarCode !== "") {
      let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
      if (taxableBarCodeN.length > 6){
        jProduct += ", \"TAXABLE_BARCODE\": \""+ taxableBarCodeN + "\""
        search = true
      }      
    }
    if (reference !== "") {
      jProduct += ", \"REFERENCE\": \""+ reference + "\""
      search = true
    }

    if ((modeSearch) && (type !== "")) jProduct += ", \"LIST_TYPES\": \""+ type + "\""

    jProduct += " }"

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jProduct)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "PRODUCT/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de produtos. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchProductDescription") {
            document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
          }
          else if (fieldSelected === "searchProductSituation") {
            document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
          }
          else if (fieldSelected === "searchProductCommercialBarCode") {
            document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
          }
          else if (fieldSelected === "searchProductTaxableBarCode") {
            document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
          }
          else if (fieldSelected === "searchProductReference") {
            document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
          }
          else {
            document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
          }
          
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchProductDescription") {
            document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
          }
          else if (fieldSelected === "searchProductSituation") {
            document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
          }
          else if (fieldSelected === "searchProductCommercialBarCode") {
            document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
          }
          else if (fieldSelected === "searchProductTaxableBarCode") {
            document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
          }
          else if (fieldSelected === "searchProductReference") {
            document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
          }
          else {
            document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
          }
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jProduct)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "PRODUCT/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os produtos. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchProductDescription") {
              document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
            }
            else if (fieldSelected === "searchProductSituation") {
              document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
            }
            else if (fieldSelected === "searchProductCommercialBarCode") {
              document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
            }
            else if (fieldSelected === "searchProductTaxableBarCode") {
              document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
            }
            else if (fieldSelected === "searchProductReference") {
              document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
            }
            else {
              document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
            }

            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchProductDescription") {
            document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
          }
          else if (fieldSelected === "searchProductSituation") {
            document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
          }
          else if (fieldSelected === "searchProductCommercialBarCode") {
            document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
          }
          else if (fieldSelected === "searchProductTaxableBarCode") {
            document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
          }
          else if (fieldSelected === "searchProductReference") {
            document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
          }
          else {
            document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
          }
        })
      })
    }
    else
    {
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)
      
      if (fieldSelected === "searchProductDescription") {
        document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
      }
      else if (fieldSelected === "searchProductSituation") {
        document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
      }
      else if (fieldSelected === "searchProductCommercialBarCode") {
        document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
      }
      else if (fieldSelected === "searchProductTaxableBarCode") {
        document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
      }
      else if (fieldSelected === "searchProductReference") {
        document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
      }
      else {
        document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
      }
    }

  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, pageCurrent, recordPage, code, description, situation, commercialBarCode, taxableBarCode,
    reference, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o produto (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setCode("")
    setDescription("")
    setCommercialBarCode("")
    setTaxableBarCode("")
    setReference("")
    setSituation("0")
  
    setShowResult(false)

    setErrorImport("")
    setFieldSelected("searchProductCode")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jProduct = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jProduct)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o produto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchProductDescription") {
          document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
        }
        else if (fieldSelected === "searchProductSituation") {
          document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
        }
        else if (fieldSelected === "searchProductCommercialBarCode") {
          document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
        }
        else if (fieldSelected === "searchProductTaxableBarCode") {
          document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
        }
        else if (fieldSelected === "searchProductReference") {
          document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
        }
        else {
          document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
        }

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleCode = (event) => {
    let codeN = String(event.target.value).replaceAll("_","")
    if (codeN !== "") setCode(event.target.value)
    else setCode("")
  }

  const handleCommercialBarCode = (event) => {
    let commercialBarCodeN = String(event.target.value).replaceAll("_","")
    if (commercialBarCodeN !== "") setCommercialBarCode(event.target.value)
    else setCommercialBarCode("")
  }

  const handleTaxableBarCode = (event) => {
    let taxableBarCodeN = String(event.target.value).replaceAll("_","")
    if (taxableBarCodeN !== "") setTaxableBarCode(event.target.value)
    else setTaxableBarCode("")
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os produtos do SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchProductDescription") {
          document.getElementById("searchProductDescription") && document.getElementById("searchProductDescription").focus()
        }
        else if (fieldSelected === "searchProductSituation") {
          document.getElementById("searchProductSituation") && document.getElementById("searchProductSituation").focus()
        }
        else if (fieldSelected === "searchProductCommercialBarCode") {
          document.getElementById("searchProductCommercialBarCode") && document.getElementById("searchProductCommercialBarCode").focus()
        }
        else if (fieldSelected === "searchProductTaxableBarCode") {
          document.getElementById("searchProductTaxableBarCode") && document.getElementById("searchProductTaxableBarCode").focus()
        }
        else if (fieldSelected === "searchProductReference") {
          document.getElementById("searchProductReference") && document.getElementById("searchProductReference").focus()
        }
        else {
          document.getElementById("searchProductCode") && document.getElementById("searchProductCode").focus()
        }

        return
      }

      returnJson.WARNING && setErrorImport(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }  

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean}
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    { errorImport &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{errorImport}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={showMenu ? 
                          (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        :
                          (width < 830) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                        <div style={showMenu ?
                            (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                            : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                          :
                            (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <div style={showMenu ?
                              (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                              : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                            :
                              (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                            <InputMasked
                              name="searchProductCode"
                              description="Código"
                              planceHolder=""
                              mask="99999999999"
                              value={code}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleCode} />
                          </div>
                          <div style={showMenu ?
                              (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            :
                              (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                            <Input
                              type="search"
                              name="searchProductDescription"
                              description="Descrição"
                              planceHolder="Mínimo 3 caracteres"
                              value={description}
                              maxLength={50}
                              disabled={false}
                              require={false}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDescription(event.target.value)} />
                          </div>
                        </div>
                        <div style={showMenu ?
                            (width < 1090) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                          :
                            (width < 830) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                          <Select
                            name="searchProductSituation"
                            description="Situação"
                            value={situation || "0"}
                            require={false}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\" }, {\"ID\": \"1\", \"VALUE\": \"Ativo\" }"+
                              ", {\"ID\": \"2\", \"VALUE\": \"Inativo\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setSituation} />
                        </div>
                      </div>

                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        :
                          (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                        <div style={showMenu ?
                            (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                            : (width < 830) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : (width < 1080) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row"}
                          :
                            (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                          <div style={showMenu ?
                              (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 200}
                              : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                            :
                              (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 200}
                              : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                            <InputMasked
                              name="searchProductCommercialBarCode"
                              description="Barras Comercial"
                              planceHolder=""
                              mask="99999999999999"
                              value={commercialBarCode}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleCommercialBarCode} />
                          </div>
                          <div style={showMenu ?
                              (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                              : (width < 830) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 200}
                              : (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}
                            :
                              (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 820) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}
                              : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                            <InputMasked
                              name="searchProductTaxableBarCode"
                              description="Barras Tributável"
                              planceHolder=""
                              mask="99999999999999"
                              value={taxableBarCode}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleTaxableBarCode} />
                          </div>
                        </div>
                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                          :
                            (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                          <Input
                            type="search"
                            name="searchProductReference"
                            description="Referência"
                            value={reference}
                            maxLength={15}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setReference(event.target.value)} />
                        </div>
                      </div>

                      { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 15}}>
                                <div style={(showMenu) ? 
                                    (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  : 
                                    (width < 1130) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                  <div style={(showMenu) ? 
                                      (width < 820) ? {display: "flex", flexDirection: "column"}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 550) ? {display: "flex", flexDirection: "column"}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}
                                      : 
                                        (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}}>

                                      <label style={{fontWeight: 600}}>Código</label>
                                    </div>

                                    <div style={(showMenu) ? 
                                        (width < 820) ? {display: "flex", flexGrow: 1, paddingLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5, minWidth: 305}
                                      : 
                                        (width < 550) ? {display: "flex", flexGrow: 1, paddingLeft: 5, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5, minWidth: 305}}>

                                      <label style={{fontWeight: 600}}>Descrição</label>
                                    </div>
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 1060) ? {display: "flex", flexDirection: "column"}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 790) ? {display: "flex", flexDirection: "column"}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ? 
                                        (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}
                                      : 
                                        (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}}>

                                      <label style={{fontWeight: 600}}>Departamento</label>
                                    </div>

                                    <div style={(showMenu) ? 
                                        (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1060) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 1060) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : 
                                          (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <label style={{fontWeight: 600}}>Tipo</label>
                                      </div>
                                      
                                      <div style={(showMenu) ? 
                                          (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                        : 
                                          (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>

                                        <label style={{fontWeight: 600}}>Preço</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ? 
                                    (width < 390) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                  : 
                                    (width < 500) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>

                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 5, height: 32, width: 32}}/>

                                  <div style={(modeSearch) ? {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5,
                                      paddingBottom: 5, paddingLeft: 0}
                                    : {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 0,
                                      marginTop: 2, height: 28, width: 2}} />
                                </div>
                              </div>
                              <hr/>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={(showMenu) ?
                                    (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                  : 
                                    (width < 268) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                    : (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    : 
                                      (width < 1130) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                    <div style={(showMenu) ? 
                                        (width < 820) ? {display: "flex", flexDirection: "column"}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 550) ? {display: "flex", flexDirection: "column"}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ?
                                          (width < 820) ? {display: "flex", flexGrow: 1, paddingLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 60, paddingLeft: 5, maxWidth: 60}
                                        : 
                                          (width < 550) ? {display: "flex", flexGrow: 1, paddingLeft: 5, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 60, paddingLeft: 5, maxWidth: 60}}>

                                        <label>{item.ID}</label>
                                      </div>

                                      <div style={(showMenu) ? 
                                          (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}
                                        : 
                                          (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}}>

                                        <label>{item.DESCRIPTION}</label>
                                      </div>
                                    </div>

                                    <div style={(showMenu) ? 
                                        (width < 1060) ? {display: "flex", flexDirection: "column"}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 790) ? {display: "flex", flexDirection: "column"}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ? 
                                          (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}
                                        : 
                                          (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 305}}>

                                        <label>{item.DEPARTMENT.DESCRIPTION}</label>
                                      </div>

                                      <div style={(showMenu) ? 
                                          (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1060) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={(showMenu) ?
                                            (width < 1060) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                          : 
                                            (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                          <label>
                                            { item.TYPE === 1 && "Industrialização" }
                                            { item.TYPE === 2 && "Revenda" }
                                            { item.TYPE === 3 && "Consumo" }
                                            { item.TYPE === 4 && "Serviço" }
                                            { item.TYPE === 5 && "Combustível" }
                                            { item.TYPE === 6 && "Embalagem" }
                                            { item.TYPE === 7 && "Materia prima" }
                                            { item.TYPE === 8 && "Ativo imobilizado" }
                                            { item.TYPE === 9 && "Outros insumos" }
                                            { item.TYPE === 10 && "Outras" }
                                            { item.TYPE === 11 && "Revenda (Substituto)" }
                                          </label>
                                        </div>
                                        
                                        <div style={(showMenu) ? 
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                            : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                          : 
                                            (width < 520) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>

                                          <label>{ item.PRODUCT_COMPANY && formatNumeric(item.PRODUCT_COMPANY.SALE_PRICE, 2) }</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 390) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                    :
                                      (width < 500) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                        
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      <img
                                        style={{height: 32, width: 32}}
                                        name="searchProductSelected"
                                        src={image_select}
                                        alt="Selecionar"
                                        onClick={(() => setSelected(item))} />
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      { !modeSearch &&
                                        <img 
                                          style={{height: 28, width: 28, marginTop: 2}}
                                          name="searchProductDelete"
                                          src={image_delete}
                                          alt="Deletar"
                                          onClick={() => callDelete(item)} />
                                      }
                                    </div>
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination 
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchProduct