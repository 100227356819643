import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import SearchAgency from "../Agency/Search"
import SearchAccount from "./Search"
import image_add from "../../Images/Buttons/add.png"
import image_check from "../../Images/Buttons/refresh.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_delete from "../../Images/Buttons/cancel.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const Account = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [number, setNumber] = useState("")
  const [owner, setOwner] = useState("")
  const [bankType, setBankType] = useState(0)
  const [type, setType] = useState(1)
  const [saveVariation, setSaveVariation] = useState(0)
  const [ispb, setIspb] = useState(0)
  const [participantCode, setParticipantCode] = useState("")
  const [bankStandard, setBankStandard] = useState(2)
  const [sge, setSge] = useState("")
    
  const [agencyCode, setAgencyCode] = useState("")
  const [agencyNumber, setAgencyNumber] = useState("")
  const [agencyDescription, setAgencyDescription] = useState("")

  const [redemptionCode, setRedemptionCode] = useState("")
  const [redemptionNumber, setRedemptionNumber] = useState("")
  const [redemptionOwner, setRedemptionOwner] = useState("")

  const [jsonAccounts, setJsonAccounts] = useState([])
  const [optionsAccount, setOptionsAccount] = useState("{\"RESULT\":[{\"ID\": \"0\",\"VALUE\":\"Selecione...\"}]}")  

  const [jsonAccount, setJsonAccount] = useState("")

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showAgency, setShowAgency] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)
    
  useEffect(() => {
    if (firstAcess){
      let returnJson = []

      ///
      // Updload account information // Atualiza as informações das contas
      ///
      setLoading("Aguarde buscando as informações das contas...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +"} }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 1000)

      axios.post(BASE_URL + "ACCOUNT/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          if (returnJson.WARNING) setWarning(returnJson.WARNING)          
          setError(returnJson.ERROR)
          setLoading("")

          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        setJsonAccounts(returnJson.OBJECT)

        let optionAccount = "{\"RESULT\":[{\"ID\":\"0\",\"VALUE\":\"Selecione...\"}"
        returnJson.OBJECT && returnJson.OBJECT.map(item => ( 
          optionAccount += ",{\"ID\":\""+ item.ID +"\",\"VALUE\":\""+ item.NUMBER + "-" + item.OWNER +"\" }"
        ))
        optionAccount += "]}"
        setOptionsAccount(optionAccount)

        setLoading("")
      })

      setFirstAcess(false)
    }
    else{
      if (code === "") {
        setNumber("")
        setOwner("")
        setBankType(0)
        setType(1)
        setSaveVariation(0)
        setIspb(0)
        setParticipantCode("")
        setBankStandard(2)
        setSge("")
  
        setAgencyCode("")
        setAgencyNumber("")
        setAgencyDescription("")
      
        setRedemptionCode("")
        setRedemptionNumber("")
        setRedemptionOwner("")
      }
      else {
        setNumber(jsonAccount.NUMBER)
        setOwner(jsonAccount.OWNER)

        if (jsonAccount.BANK_TYPE !== 0) setBankType(jsonAccount.BANK_TYPE); else setBankType(0)

        setType(jsonAccount.TYPE)
        setSaveVariation(jsonAccount.SAVE_VARIATION)
        setIspb(jsonAccount.ISPB)

        if (jsonAccount.PARTICIPANT_CODE !== 0) setParticipantCode(jsonAccount.PARTICIPANT_CODE); else setParticipantCode("")

        setBankStandard(jsonAccount.BANK_STANDARD)
  
        if (jsonAccount.SGE !== 0) setSge(jsonAccount.SGE); else setSge("")
  
        setAgencyCode(jsonAccount.AGENCY.ID)
        setAgencyNumber(jsonAccount.AGENCY.NUMBER)
        setAgencyDescription(jsonAccount.AGENCY.DESCRIPTION)
  
        if (jsonAccount.REDEMPTION_ACCOUNT !== null){
          if (jsonAccount.REDEMPTION_ACCOUNT.ID !== 0){
            setRedemptionCode(jsonAccount.REDEMPTION_ACCOUNT.ID)
            setRedemptionNumber(jsonAccount.REDEMPTION_ACCOUNT.NUMBER)
            setRedemptionOwner(jsonAccount.REDEMPTION_ACCOUNT.OWNER)
          }
          else {
            setRedemptionCode("")
            setRedemptionNumber("")
            setRedemptionOwner("")
          }
        }
        else {
          setRedemptionCode("")
          setRedemptionNumber("")
          setRedemptionOwner("")
        }
      }
  
      document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
    }   
  }, [firstAcess, databasePath, databaseType, jsonCompany.ID, jsonLogin, system, code, jsonAccount, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setNumber("")
      setOwner("")
      setBankType(0)
      setType(1)
      setSaveVariation(0)
      setIspb(0)
      setParticipantCode("")
      setBankStandard(2)
      setSge("")
        
      setAgencyCode("")
      setAgencyNumber("")
      setAgencyDescription("")
    
      setRedemptionCode("")
      setRedemptionNumber("")
      setRedemptionOwner("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const checkParticipant = () => {
    let returnJson = []

    setWarning("")
    setError("")

    let jsonParticipant_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +" }"
    if (participantCode !== "") jsonParticipant_New += ", \"numeroParticipante\": "+ participantCode
    jsonParticipant_New += ", \"nomeParticipante\": \""+ jsonCompany.CORPORATE_NAME +"\""

    if (jsonCompany.CPF_CNPJ.length === 11) jsonParticipant_New += ", \"tipoDocumento\": 1"
    else if (jsonCompany.CPF_CNPJ.length === 14) jsonParticipant_New += ", \"tipoDocumento\": 2"

    if (jsonCompany.CPF_CNPJ !== "") jsonParticipant_New += ", \"numeroDocumento\": \""+ jsonCompany.CPF_CNPJ + "\""

    if (number !== "") jsonParticipant_New += ", \"numeroConta\": "+ number.replace(".","").replace("-","").replace("/","")
    if (agencyNumber !== "") jsonParticipant_New += ", \"numeroAgencia\": "+ agencyNumber.replace(".","").replace("-","").replace("/","")
    if (type !== "") jsonParticipant_New += ", \"tipoConta\": "+ type

    if (saveVariation !== "") jsonParticipant_New += ", \"variacaoPoupanca\": "+ saveVariation
    else jsonParticipant_New += ", \"variacaoPoupanca\": "+ 0

    if (ispb !== "") jsonParticipant_New += ", \"codigoIspb\": "+ ispb
    jsonParticipant_New += " }"

    let jsonAccount_New = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +" } }"

    if (participantCode === "")
    {
      setLoading("Aguarde incluindo o participante...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonParticipant_New)
      formData.append("JSON_NEW_2", jsonAccount_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PARTICIPANT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o participante. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão do participante efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão do participante efetuada com sucesso!")

        jsonAccount.PARTICIPANT_CODE =  returnJson.OBJECT.numeroParticipante

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o participante...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonParticipant_New)
      formData.append("JSON_NEW_2", jsonAccount_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "PARTICIPANT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o participante. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração do participante efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração do participante efetuada com sucesso!")

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const cleanAgency = () => {
    setAgencyCode("")
    setAgencyDescription("")
  }

  const deleteParticipant = () =>
  {
    if (participantCode !== "")
    {    
      let returnJson = []

      setWarning("")
      setError("")

      let jsonParticipant_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +" }"
      jsonParticipant_New += ", \"numeroParticipante\": "+ participantCode 
      jsonParticipant_New += " }"

      let jsonAccount_New = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +" } }"

      setLoading("Aguarde excluindo o participante...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonParticipant_New)
      formData.append("JSON_NEW_2", jsonAccount_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PARTICIPANT/Delete", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível excluir o participante. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Exclusão do participante efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Exclusão do participante efetuada com sucesso!")

        jsonAccount.PARTICIPANT_CODE =  0

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const enableAgency = () => {
    setShowAgency(!showAgency)
  }

  const fillAgency = (agency) => {
    setAgencyCode(agency.ID)
    setAgencyDescription(agency.DESCRIPTION)
    setShowAgency(false)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
    setFirstAcess(true)
  }

  const save = () => {
    let returnJson = []
    let jsonAccount_New = {}

    jsonAccount_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonAccount_New += ", \"ID\": "+ code
    if (((number !== undefined) && (number !== null)) && (number !== "")) jsonAccount_New += ", \"NUMBER\": \""+ number + "\""
    if (((owner !== undefined) && (owner !== null)) && (owner !== "")) jsonAccount_New += ", \"OWNER\": \""+ owner + "\""

    if (((agencyCode !== undefined) && (agencyCode !== null)) && (agencyCode !== "")){
      jsonAccount_New += ", \"AGENCY\": { \"ID\": "+ agencyCode
      jsonAccount_New += ", \"NUMBER\": \""+ agencyNumber + "\""
      jsonAccount_New += ", \"DESCRIPTION\": \""+ agencyDescription + "\" }"
    }

    if (parseInt(bankType) !== 0) jsonAccount_New += ", \"BANK_TYPE\": "+ bankType

    if (((participantCode !== undefined) && (participantCode !== null)) && (participantCode !== "")) 
      jsonAccount_New += ", \"PARTICIPANT_CODE\": "+ participantCode

    jsonAccount_New += ", \"TYPE\": "+ type +
    ", \"SAVE_VARIATION\": "+ saveVariation +
    ", \"ISPB\": "+ ispb

    if (parseInt(bankStandard) === 1) jsonAccount_New += ", \"BANK_STANDARD\": 1"
    else if (parseInt(bankStandard) === 2) jsonAccount_New += ", \"BANK_STANDARD\": 2"

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonAccount_New += ", \"SGE\": "+ sge

    if ((((redemptionCode !== undefined) && (redemptionCode !== null)) && (redemptionCode !== "")) && (redemptionCode !== "0")){
      jsonAccount_New += ", \"REDEMPTION_ACCOUNT\": { \"ID\": "+ redemptionCode
      jsonAccount_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
      jsonAccount_New += ", \"NUMBER\": \""+ redemptionNumber + "\""
      jsonAccount_New += ", \"OWNER\": \""+ redemptionOwner + "\" }"
    }
   
    jsonAccount_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonAccount_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "ACCOUNT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a conta. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonAccount(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonAccount))
      formData.append("JSON_NEW_1", jsonAccount_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "ACCOUNT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a conta. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountNumber") && document.getElementById("accountNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonAccount(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const selectRedemption = (id) => {
    let bFound = false

    for(let i = 0; i < jsonAccounts.length; i++){
      if (jsonAccounts[i].ID === parseInt(id)){
        setRedemptionCode(jsonAccounts[i].ID)
        setRedemptionNumber(jsonAccounts[i].NUMBER)
        setRedemptionOwner(jsonAccounts[i].OWNER)

        if (parseInt(bankType) === 0) setBankType(2)
        setBankStandard(1)

        bFound = true
      }
    }

    if (!bFound){
      setRedemptionCode("")
      setRedemptionNumber("")
      setRedemptionOwner("")
      setBankType(0)
      setBankStandard(2)
    }
  }

  const selectType = (item) => {
    if (parseInt(item) !== 2) setSaveVariation(0)
    setType(item)
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  } 

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonAccount(event)
    setCode(event.ID)
  }   

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Conta"
            titleSearch="Pesquisa das Contas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchAccount
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showAgency ?
                          <>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                marginTop: 10, marginLeft: 5, width: 147}} onClick={() => save()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                marginTop: 10, marginLeft: 5, width: 147}} onClick={() => add()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                                </div>
                              </div>
                              
                              { (code !== "") && (parseFloat(bankType) === 2) &&
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                  marginTop: 10, marginLeft: 5, width: 237}} onClick={() => checkParticipant()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_check} alt="Checar Participante"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Checar Participante</label>
                                  </div>
                                </div>
                              }

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                marginTop: 10, marginLeft: 5, width: 147}} onClick={() => previousAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}}>
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                paddingLeft: 10}}>
                                { warning && 
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                                  </div> 
                                }

                                { error &&
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                                  </div>
                                }
                              </div>

                              { (code === "") ?
                                  <>
                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      :
                                        (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="accountNumber"
                                          description="Número:"
                                          value={number}
                                          maxLength={10}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setNumber(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="accountOwner"
                                          description="Titular:"
                                          planceHolder=""
                                          value={owner}
                                          maxLength={50}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setOwner(event.target.value)} />
                                      </div>
                                    </div>
                                  </>
                                :
                                  <div style={(showMenu) ?
                                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    :
                                      (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                        <Input
                                          type="text"
                                          name="accountCode"
                                          description="Código"
                                          value={code}
                                          maxLength={11}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCode(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : (width < 980) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="accountNumber"
                                          description="Número:"
                                          value={number}
                                          maxLength={10}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setNumber(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="accountOwner"
                                        description="Titular:"
                                        planceHolder=""
                                        value={owner}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setOwner(event.target.value)} />
                                    </div>
                                  </div>
                              }

                              <div style={showMenu ?
                                  (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : 
                                  (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>

                                <div style={showMenu ?
                                    (width < 940) ? {display: "flex", flexGrow: 1}
                                    : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                                  : 
                                    (width < 610) ? {display: "flex", flexGrow: 1}
                                    : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                                  <Select
                                    name="accountBankType"
                                    description="Banco"
                                    value={bankType || 0}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Mentes\" }, {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setBankType} />
                                </div>

                                <div style={showMenu ?
                                    (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 940) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 610) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                  <div style={showMenu ?
                                      (width < 440) ? {display: "flex", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 710) ? {display: "flex", flexGrow: 1}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      (width < 440) ? {display: "flex", flexGrow: 1}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Select
                                      name="accountType"
                                      description="Tipo"
                                      value={type}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Conta Corrente\" }"+
                                        ", {\"ID\": 2, \"VALUE\": \"Conta Poupança\" }, {\"ID\": 3, \"VALUE\": \"Conta Salário\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Pré Paga\" }] }")}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={selectType} />
                                  </div>
                                
                                  <div style={showMenu ?
                                      (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                      : (width < 710) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                    : 
                                      (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>
                                    <Select
                                      name="accountBankStandard"
                                      description="Padrão"
                                      value={bankStandard || 0}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setBankStandard} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : 
                                  // (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  // : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="accountSaveVariation"
                                      description="Var. Poupança"
                                      planceHolder=""
                                      value={saveVariation}
                                      maxLength={11}
                                      disabled={(parseInt(type) === 2) ? false : true}
                                      require={(parseInt(type) === 2) ? true : false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setSaveVariation(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="accountIspb"
                                      description="ISPB"
                                      planceHolder=""
                                      value={ispb}
                                      maxLength={11}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setIspb(event.target.value)} />
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 260) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 232, maxWidth: 232}
                                    : (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 232, maxWidth: 232}
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 232, maxWidth: 232}}>

                                  <div style={showMenu ?
                                      (width < 260) ? {display: "flex", flexGrow: 1}
                                      : (width < 660) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 930) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, minWidth: 180}
                                    :
                                      {display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="accountParticipantCode"
                                      description="Cód. Participante"
                                      planceHolder=""
                                      value={participantCode}
                                      maxLength={11}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setParticipantCode(event.target.value)} />
                                  </div>
                                  <div style={(width < 260) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexGrow: 0, width: 52}}>
                                    <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                                      <img
                                        style={{height: 42, width: 42}}
                                        name="accountDelete"
                                        src={image_delete} 
                                        alt="Excluir" 
                                        onClick={(() => deleteParticipant(true))} />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1}}>
                                  <Select
                                    name="accountRedemptionCode"
                                    description="Conta Resgate:"
                                    value={redemptionCode}
                                    require={false}
                                    options={JSON.parse(optionsAccount)}
                                    image={false}
                                    typeImage={0}
                                    typeInput={0}
                                    onSelect={setFieldSelected}
                                    set={selectRedemption} />
                                </div>
                              </div>

                              <div style={(showMenu) ? (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 601) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1}}>

                                <div style={{marginTop: 10}}>
                                  <Titulo description="Agência" />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="search"
                                      name="accountAgencyDescription"
                                      description="Descrição:"
                                      value={agencyDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setAgencyDescription(event.target.value)} />
                                  </div>
                                  <div style={(width < 540) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                    marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                                    <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                                      <img
                                        style={{height: 42, width: 42}}
                                        name="accountSearch"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={(() => enableAgency(true))} />
                                    </div>
                                    <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                                      <img
                                        style={{height: 42, width: 42}}
                                        name="accountCleanAgency"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={cleanAgency} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        :
                          <div style={{marginTop: 10}}>
                            <div style={{marginLeft: 10}}>
                              <b>Selecione a agência:</b>
                            </div>

                            <SearchAgency
                              modeSearch={true}
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonLogin={jsonLogin}
                              jsonCompany={jsonCompany}
                              system={system}
                              setAdd={() => alert("")}
                              setSelected={fillAgency} 
                              setReturn={() => setShowAgency(false)} 
                              showMenu={showMenu}
                              width={width} />
                          </div>
                      }
                  </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Account