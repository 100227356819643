import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchCfop from "../Cfop/Search"
import SearchCstCsosn from "../CstCsosn/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const TypeTaxOperationCfop = ({databaseType, databasePath, jsonTypeTaxOperationCfop, setJsonTypeTaxOperationCfop, jsonCompany, 
  jsonLogin, system, save, back, showMenu, width}) => {

  const [code, setCode] = useState("")
  const [typeTaxOperationCode, setTypeTaxOperationCode] = useState("")
  const [typeTaxOperationDescription, setTypeTaxOperationDescription] = useState("")
  const [cfopCode, setCfopCode] = useState("")
  const [cfopDescription, setCfopDescription] = useState("")
  const [cstCsosnCode, setCstCsosnCode] = useState("")
  const [cstCsosnDescription, setCstCsosnDescription] = useState("")
  const [type, setType] = useState(0)
  const [standard, setStandard] = useState(0)
  const [sge, setSge] = useState("")

  const [showCfop, setShowCfop] = useState(false)
  const [showCstCsosn, setShowCstCsosn] = useState(false)

  useEffect(() => {
    if (jsonTypeTaxOperationCfop.ID !== undefined) setCode(jsonTypeTaxOperationCfop.ID)
    
    setTypeTaxOperationCode(jsonTypeTaxOperationCfop.TYPE_TAX_OPERATION.ID)        
    setTypeTaxOperationDescription(jsonTypeTaxOperationCfop.TYPE_TAX_OPERATION.DESCRIPTION)

    if (jsonTypeTaxOperationCfop.CFOP !== undefined){
      setCfopCode(jsonTypeTaxOperationCfop.CFOP.ID)
      setCfopDescription(jsonTypeTaxOperationCfop.CFOP.DESCRIPTION)
    }

    if (jsonTypeTaxOperationCfop.CST_CSOSN !== undefined){
      setCstCsosnCode(jsonTypeTaxOperationCfop.CST_CSOSN.CODE)
      setCstCsosnDescription(jsonTypeTaxOperationCfop.CST_CSOSN.DESCRIPTION)
    }

    setType(jsonTypeTaxOperationCfop.TYPE)

    if (jsonTypeTaxOperationCfop.DEFAULT !== undefined) setStandard(jsonTypeTaxOperationCfop.DEFAULT)
    if (jsonTypeTaxOperationCfop.SGE !== undefined) setSge(jsonTypeTaxOperationCfop.SGE)

    setJsonTypeTaxOperationCfop(jsonTypeTaxOperationCfop)
  }, [jsonTypeTaxOperationCfop])

  const callSave = () => {
    let json = "{ \"TYPE_TAX_OPERATION\": { \"ID\": \"" + typeTaxOperationCode + "\", \"DESCRIPTION\": \""+ typeTaxOperationDescription +
      "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }"

    if (code !== "") json += ", \"ID\": " + code

    if (cfopCode !== "") json += ", \"CFOP\": { \"ID\": "+ cfopCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "}"+
      ", \"DESCRIPTION\": \""+ cfopDescription +"\" }"

    if (cstCsosnCode !== "") json += ", \"CST_CSOSN\": { \"CODE\": \""+ cstCsosnCode + "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "}"+
      ", \"DESCRIPTION\": \""+ cstCsosnDescription +"\" }"

    json += ", \"TYPE\": "+ type
    json += ", \"DEFAULT\": "+ standard
    if (sge !== "") json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const clean = () => {
    setCfopCode("")
    setCfopDescription("")
    setCstCsosnCode("")
    setCstCsosnDescription("")
    setType(0)
    setStandard(0)
  }

  const cleanCfop = () => {
    setCfopCode("")
    setCfopDescription("")
    setShowCfop(false)
  }

  const cleanCstCsosn = () => {
    setCstCsosnCode("")
    setCstCsosnDescription("")
    setShowCstCsosn(false)
  }

  const fillCfop = (cfop) => {
    setCfopCode(cfop.ID)
    setCfopDescription(cfop.DESCRIPTION)
    setShowCfop(false)
  }

  const fillCstCsosn = (cstCsosn) => {
    setCstCsosnCode(cstCsosn.CODE)
    setCstCsosnDescription(cstCsosn.DESCRIPTION)
    setShowCstCsosn(false)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      { !showCfop && !showCstCsosn ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={() => clean()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10} : {paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
              <div>
                <label style={{fontWeight: 600}}>{ (code === "") ? "Inclusão:" : "Alteração:" }</label>
              </div>

              <div style={showMenu ?
                  (width < 1680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                :   
                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                  <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                    <Titulo description="CFOP" />
                  </div>
                  
                  <div style={showMenu ?
                      (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                        minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}
                    : 
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                        minWidth: 180}
                      : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :
                        (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                        :
                          (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                        <Input
                          type="text"
                          name="typeTaxOperationCfopCfopCode"
                          description="Código"
                          value={cfopCode}
                          maxLength={11}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setCfopCode(event.target.value)} />
                      </div>
                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :
                          (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                        <Input
                          type="text"
                          name="typeTaxOperationCfopCfopDescription"
                          description="Descrição"
                          value={cfopDescription}
                          maxLength={200}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCfopDescription(event.target.value)} />
                      </div>
                    </div>

                    { code === "" &&
                      <div style={showMenu ?
                          (width < 880) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                        :
                          (width < 580) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                        <img 
                          style={{height: 42, width: 42}}
                          name="typeTaxOperationCfopSearchCfop"
                          src={image_search} 
                          alt="Pesquisar" 
                          onClick={() => setShowCfop(!showCfop)} />

                        <img 
                          style={{marginLeft: 10, height: 42, width: 42}}
                          name="typeTaxOperationCfopCleanCfop"
                          src={image_clean} 
                          alt="Limpar" 
                          onClick={cleanCfop} />
                      </div>
                    }
                  </div>
                </div>
                <div style={showMenu ?
                    (width < 1680) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                  :
                    (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                  <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                    <Titulo description="CST/CSOSN" />
                  </div>

                  <div style={showMenu ?
                      (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                        minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}
                    : 
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10,
                        minWidth: 180}
                      : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, minWidth: 180}}>

                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :
                        (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                        :
                          (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                        <Input
                          type="text"
                          name="typeTaxOperationCfopCstCsosnCode"
                          description="Código"
                          value={cstCsosnCode}
                          maxLength={3}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnCode(event.target.value)} />
                      </div>
                      <div style={showMenu ?
                          (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :
                          (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                        <Input
                          type="text"
                          name="typeTaxOperationCfopCstCsosnDescription"
                          description="Descrição"
                          value={cstCsosnDescription}
                          maxLength={80}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnDescription(event.target.value)} />
                      </div>
                    </div>

                    { code === "" &&
                      <div style={showMenu ?
                          (width < 880) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                        :
                          (width < 580) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                        <img 
                          style={{height: 42, width: 42}}
                          name="typeTaxOperationCfopSearchCstCsosn"
                          src={image_search} 
                          alt="Pesquisar" 
                          onClick={() => setShowCstCsosn(!showCstCsosn)} />

                        <img 
                          style={{marginLeft: 10, height: 42, width: 42}}
                          name="typeTaxOperationCfopCleanCstCsosn"
                          src={image_clean} 
                          alt="Limpar"
                          onClick={cleanCstCsosn} />
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div style={showMenu ?
                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 20}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 20}
                :
                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 20}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 20}}>

                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                  <Select
                    name="typeTaxOperationCfopType"
                    description="Tipo"
                    value={type || 0}
                    disabled={code === "" ? false : true}
                    require={true}
                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                      ", {\"ID\": 1, \"VALUE\": \"Entrada Dentro UF\" }, {\"ID\": 2, \"VALUE\": \"Entrada Fora UF\" }"+
                        ", {\"ID\": 3, \"VALUE\": \"Importação\" }, {\"ID\": 4, \"VALUE\": \"Saída Dentro UF\" }"+
                          ",{\"ID\": 5, \"VALUE\": \"Saída Fora UF\" }, {\"ID\": 6, \"VALUE\": \"Exportação\" }] }")}
                    image={false}
                    typeInput={2}
                    onSelect={setFieldSelected}
                    set={setType} />
                </div>

                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                  <Select
                    name="typeTaxOperationCfopStandard"
                    description="Padrão"
                    value={standard || 0}
                    require={true}
                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                      ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                    image={false}
                    typeInput={2}
                    onSelect={setFieldSelected}
                    set={setStandard} />
                </div>
              </div>
            </div>
          </>
        :
          <>
            { showCfop &&
              <div>
                <b>Selecione o CFOP:</b>
                <SearchCfop
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillCfop}
                  setReturn={() => setShowCfop(false)} 
                  showMenu={showMenu}
                  width={width} />
              </div>
            }

            { showCstCsosn &&
              <div>
                <b>Selecione o CST/CSOSN:</b>
                <SearchCstCsosn
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillCstCsosn}
                  setReturn={() => setShowCstCsosn(false)} 
                  showMenu={showMenu}
                  width={width} />
              </div>
            }
          </>
      }
    </div>
  )
}

export default TypeTaxOperationCfop