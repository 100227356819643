import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchParticipant = ({databaseType, databasePath, jsonLogin, jsonCompany, system, setSelected, setReturn, showMenu, width}) => {
  const [name, setName] = useState("")

  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [resultFilter, setResultFilter] = useState([])

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("") 

  const [firstAcess, setFirstAcess] = useState(true)
  const [updatePage, setUpdatePage] = useState(0)

  useEffect(() => {
    if (firstAcess){
      let returnJson = []

      setLoading("Aguarde buscando os participantes...")
      setWarning("")
      setError("")

      let jParticipant = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.ENVIRONMENT +"} }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jParticipant)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "PARTICIPANT/SearchList", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar a lista de participantes. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          document.getElementById("searchParticipantName") && document.getElementById("searchParticipantName").focus()
          return
        }

        setResult(returnJson)
        setResultFilter(returnJson.OBJECT)
        setShowResult(true)
        setLoading("")
      })

      setFirstAcess(false)
    }

    document.getElementById("searchManufacturerName") && document.getElementById("searchManufacturerName").focus()
  }, [databaseType, databasePath, jsonLogin, system, updatePage, firstAcess])

  const clean = () => {
    setName("")
    setResultFilter(result.OBJECT)
    setUpdatePage(updatePage + 1)
  }

  const handleName = (event) => {
    let listItens = [{}]

    result.OBJECT ? listItens = result.OBJECT.filter(item => String(item.nomeParticipante).includes(event.target.value))
    : listItens = result.OBJECT

    setResultFilter(listItens)

    setName(event.target.value)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {/* ////////////////
            // Begin Buttons  //
            //////////////////// */}
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={clean}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={setReturn}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>
            {/* ////////////////
            // End Buttons  //
            //////////////////// */}

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              <div style={{paddingLeft: 10, paddingRight: 10}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                  marginBottom: 10, marginLeft: 0}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                </div>

                <div style={{display: "flex", flexGrow: 0}}>
                  <div style={{display: "flex", flexGrow: 1}}>
                    <Input
                      type="search"
                      name="searchManufacturerName"
                      description="Nome"
                      planceHolder="Mínimo 3 caracteres"
                      value={name}
                      maxLength={50}
                      disabled={false}
                      require={false}
                      image={false}
                      typeImage={0}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => handleName(event)} />
                  </div>
                </div>

                { showResult && 
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, marginBottom: 10}}>
                    { (resultFilter.length > 0) ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                            borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}}>
                            <div style={showMenu ?
                                (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                              :
                                (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}}>

                              <div style={showMenu ?
                                  (width < 470) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                  : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                :
                                  (width < 470) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                <label style={{fontWeight : 600}}>CPF/CNPJ</label>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}}>
                                <label style={{fontWeight : 600}}>Nome</label>
                              </div>
                            </div>
                          </div>

                          { resultFilter.map(item => (
                            <div key={item.numeroParticipante} style={showMenu ?                                      
                                (width < 1140) ? 
                                  {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, 
                                  paddingTop: 8, paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                  marginTop: 4, minWidth: 180}
                              : 
                                (width < 870) ? 
                                  {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, 
                                  paddingTop: 8, paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", 
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                  marginTop: 4, minWidth: 180}}>

                              <div style={showMenu ?
                                  (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                  : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                <div style={showMenu ?
                                    (width < 470) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                    : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 160}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                  :
                                    (width < 470) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                  <label>
                                    { (item.tipoDocumento === 1) ?
                                        String(item.numeroDocumento).padStart(11,'0').substring(0,3) + "." + 
                                          String(item.numeroDocumento).padStart(11,'0').substring(3,6) + "." + 
                                            String(item.numeroDocumento).padStart(11,'0').substring(6,9) + "-" + 
                                              String(item.numeroDocumento).padStart(11,'0').substring(9,11)
                                      :
                                        String(item.numeroDocumento).padStart(14,'0').substring(0,2) + "." + 
                                          String(item.numeroDocumento).padStart(14,'0').substring(2,5) + "." + 
                                            String(item.numeroDocumento).padStart(14,'0').substring(5,8) + "/" + 
                                              String(item.numeroDocumento).padStart(14,'0').substring(8,12) + "-" +
                                                  String(item.numeroDocumento).padStart(14,'0').substring(12,14)
                                    }                                            
                                  </label>
                                </div>
                                <div style={(width < 470) ? {display: "flex", flexGrow: 1, wordBreak: "break-all"}
                                  : {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}}>
                                  <label>{item.nomeParticipante}</label>
                                </div>
                              </div>
                              <div style={showMenu ?                                     
                                  (width < 1140) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0}
                                :
                                  (width < 870) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexGrow: 0}}>

                                <img
                                  style={{height: 32, width: 32}}
                                  src={image_select}
                                  name="searchParticipantSelect"
                                  alt="Selecionar"
                                  onClick={() => setSelected(item)} />
                              </div>
                            </div>
                          ))}
                        </div>
                      :
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum participante encontrado.</label>
                        </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SearchParticipant