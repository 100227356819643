import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchAccountGroup from "../AccountGroup/Search"
import SearchAccountPlan from "./Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const AccountPlan = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [number, setNumber] = useState("")
  const [description, setDescription] = useState("")
  const [type, setType] = useState(0)
  const [periodic, setPeriodic] = useState(0)
  const [nature, setNature] = useState(0)
  const [date, setDate] = useState("")
  const [sge, setSge] = useState("")

  const [fatherCode, setFatherCode] = useState("")
  const [fatherDescription, setFatherDescription] = useState("")
  const [fatherSge, setFatherSge] = useState("")

  const [accountGroupCode, setAccountGroupCode] = useState("")
  const [accountGroupDescription, setAccountGroupDescription] = useState("")  

  const [jAccountPlan, setJAccountPlan] = useState({})

  const [showMenu, setShowMenu] = useState(true)  
  const [showSearch, setShowSearch] = useState(true)
  const [showAccountGroup, setShowAccountGroup] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")    

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setNumber("")
      setDescription("")
      setType(0)
      setPeriodic(0)
      setNature(0)
      setDate("01/01/2021")
      setSge("")

      setAccountGroupCode("")
      setAccountGroupDescription("")      
    }
    else {
      setNumber(jAccountPlan.NUMBER)
      setDescription(jAccountPlan.DESCRIPTION)
      setType(jAccountPlan.TYPE)
      setPeriodic(jAccountPlan.PERIODIC)
      setNature(jAccountPlan.COD_NAT_CC)       
      setDate(jAccountPlan.DATE.substr(8,2) + "\\" + jAccountPlan.DATE.substr(5,2) + "\\" + jAccountPlan.DATE.substr(0,4))

      if ((jAccountPlan.FATHER !== undefined) && (jAccountPlan.FATHER !== null)){
        if (jAccountPlan.FATHER.ID !== 0) {
          setFatherCode(jAccountPlan.FATHER.ID)
          setFatherDescription(jAccountPlan.FATHER.DESCRIPTION)
          if (jAccountPlan.SGE !== 0) setFatherSge(jAccountPlan.FATHER.SGE)
        }
      }

      if ((jAccountPlan.ACCOUNT_GROUP !== null) && (jAccountPlan.ACCOUNT_GROUP !== undefined)){ 
        if (jAccountPlan.ACCOUNT_GROUP.ID !== 0) {
          setAccountGroupCode(jAccountPlan.ACCOUNT_GROUP.ID)
          setAccountGroupDescription(jAccountPlan.ACCOUNT_GROUP.DESCRIPTION)
        }
      }

      if ((jAccountPlan.SGE !== null) && (jAccountPlan.SGE !== undefined)) setSge(jAccountPlan.SGE)
    }    

    document.getElementById("accountPlanDescription") && document.getElementById("accountPlanDescription").focus()

  }, [code, jAccountPlan, updateFocu])

  const add = (item) => {
    let returnJson = []
    let jsonAccountPlan = ""
    let nextNumber  = ""

    setError("")
    setWarning("")
    setLoading("Aguarde buscando as informações...")

    ///
    // New Category
    ///
    if ((item === undefined) || ((item.NUMBER === undefined) && (item.ID === undefined)))
    {
      jsonAccountPlan = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"
      setFatherCode("")
      setFatherDescription("")
      setFatherSge("")
      setType(0)
    }
    else
    {        
      jsonAccountPlan = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" },"
      jsonAccountPlan += " \"NUMBER\": \""+ item.NUMBER +"\","
      jsonAccountPlan += " \"FATHER\": { \"ID\": "+ item.ID +" } }"
        
      setFatherCode(item.ID)
      setFatherDescription(item.DESCRIPTION)
      if ((item.SGE !== null) && (item.SGE !== undefined)) setFatherSge(item.SGE)      
      setType(item.TYPE)
    }

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonAccountPlan)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "ACCOUNT_PLAN/NextNumber", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter o proximo número do plano de conta. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("accountPlanDescription") && document.getElementById("accountPlanDescription").focus()
        return
      }
  
      returnJson.WARNING && setWarning(returnJson.WARNING)

      nextNumber = returnJson.OBJECT
      setNumber(nextNumber)
    })

    setShowSearch(false)

    if (code === "") {
      setNumber("")
      setDescription("")
      setPeriodic(0)
      setNature(0)
      setDate("01/01/2021")
      setSge("")

      setAccountGroupCode("")
      setAccountGroupDescription("")
    } 
    else setCode("")

    setError("")
    setWarning("")
    setLoading("")
    setUpdateFocu(updateFocu + 1)
  }

  const cleanAccountGroup = () => {
    setAccountGroupCode("")
    setAccountGroupDescription("")

    setShowAccountGroup(false)
  }

  const enableAccountGroup = () => {
    setShowAccountGroup(!showAccountGroup)
  }    

  const fillAccountGroup = (group) => {
    setAccountGroupCode(group.ID)
    setAccountGroupDescription(group.DESCRIPTION)
  
    setShowAccountGroup(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []

    setWarning("")
    setError("")

    let jsonAccountPlan_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonAccountPlan_New += ", \"ID\": "+ code

    jsonAccountPlan_New += ", \"NUMBER\": \""+ number +"\""
    jsonAccountPlan_New += ", \"DESCRIPTION\": \""+ description +"\""
    jsonAccountPlan_New += ", \"TYPE\": "+ type
    jsonAccountPlan_New += ", \"PERIODIC\": "+ periodic
    jsonAccountPlan_New += ", \"COD_NAT_CC\": "+ nature

    if (((date !== undefined) && (date !== null)) && (date !== "")) {
      const sDate = date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2) + "T00:00:00"        
      jsonAccountPlan_New += ", \"DATE\": \""+ sDate + "\""
    }

    let cFather = 0
    if (((fatherCode !== undefined) && (fatherCode !== null)) && (fatherCode !== "")) cFather = fatherCode
    if (cFather !== 0){
      jsonAccountPlan_New += ", \"FATHER\": { \"ID\": "+ cFather +", \"DESCRIPTION\": \""+ fatherDescription +"\""    
      if (fatherSge !== "") jsonAccountPlan_New += ", \"SGE\": "+ fatherSge
      jsonAccountPlan_New += " }"
    }

    let cGroup = 0
    if (((accountGroupCode !== undefined) && (accountGroupCode !== null)) && (accountGroupCode !== "")) cGroup = accountGroupCode

    if (cGroup !== 0) 
      jsonAccountPlan_New += ", \"ACCOUNT_GROUP\": { \"ID\": "+ cGroup +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }" +
        ", \"DESCRIPTION\": \""+ accountGroupDescription +"\" }"

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonAccountPlan_New += ", \"SGE\": "+ sge

    jsonAccountPlan_New += " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonAccountPlan_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "ACCOUNT_PLAN/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o plano de conta. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountPlanDescription") && document.getElementById("accountPlanDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJAccountPlan(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })            
    }
    else{
      setLoading("Aguarde alterando o registro...")
      
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jAccountPlan))
      formData.append("JSON_NEW_1", jsonAccountPlan_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "ACCOUNT_PLAN/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o plano de conta. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("accountPlanDescription") && document.getElementById("accountPlanDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")
        
        setJAccountPlan(returnJson.OBJECT) 
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = (item) => {
    setShowSearch(false)
    add(item)
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJAccountPlan(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Plano de Conta"
            titleSearch="Pesquisa dos Planos de Contas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchAccountPlan
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                type={0}
                periodic={0} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showAccountGroup ?
                          <>
                            <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                            </div>
                          
                            <div style={{paddingLeft: 20, paddingRight: 20}}>
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                paddingLeft: 10}}>
                                { warning && 
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                  </div> 
                                }

                                { error &&
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                  </div>
                                }
                              </div>

                              { (code === "") &&
                                <div style={(showMenu) ?
                                    (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 850) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="accountPlanNumber"
                                      description="Número"
                                      planceHolder=""
                                      value={number}
                                      maxLength={50}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 850) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 590) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="accountPlanDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={description}
                                      maxLength={255}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>
                              }

                              { (code !== "") &&
                                <div style={(showMenu) ?
                                    (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                      <Input
                                        type="text"
                                        name="accountPlanCode"
                                        description="Código"
                                        planceHolder=""
                                        value={code}
                                        maxLength={11}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setCode(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 1050) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 750) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="accountPlanNumber"
                                        description="Número"
                                        planceHolder=""
                                        value={number}
                                        maxLength={50}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setNumber(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1050) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="accountPlanDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={description}
                                      maxLength={255}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>
                              }

                              <div style={(showMenu) ?
                                  (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Select
                                      name="accountPlanType"
                                      description="Tipo"
                                      value={type}
                                      require={true}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Crédito\" }, {\"ID\": 2, \"VALUE\": \"Débito\" }"+
                                        ", {\"ID\": 3, \"VALUE\": \"Título\" }, {\"ID\": 98, \"VALUE\": \"Título Crédito\" }"+
                                        ", {\"ID\": 99, \"VALUE\": \"Título Débito\" }] }")}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setType} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Select
                                      name="accountPlanPeriodic"
                                      description="Periódico"
                                      value={periodic || 0}
                                      disabled={false}
                                      require={true}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setPeriodic} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 950) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Select
                                    name="accountPlanNature"
                                    description="Natureza"
                                    value={nature || 0}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Conta Ativo\" }, {\"ID\": 2, \"VALUE\": \"Conta Passiva\" }"+
                                      ", {\"ID\": 3, \"VALUE\": \"Patrimonio Liquido\" }, {\"ID\": 4, \"VALUE\": \"Conta Resultado\" }"+
                                      ", {\"ID\": 5, \"VALUE\": \"Conta Compensacao\" }, {\"ID\": 9, \"VALUE\": \"Outras\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setNature} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <div style={{minWidth: 180}}>
                                    <Titulo description="Pai" />
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, paddingLeft: 10, paddingRight: 10}}>

                                    <Input
                                      type="text"
                                      name="accountPlanFatherDescription"
                                      description="Descrição"
                                      planceHolder=""
                                      value={fatherDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setFatherDescription(event.target.value)} />
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <div style={{minWidth: 180}}>
                                    <Titulo description="Grupo de contas" />
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                      : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    :
                                      (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="accountPlanAccountGroupDescription"
                                        description="Descrição"
                                        planceHolder=""
                                        value={accountGroupDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setAccountGroupDescription(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 500) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                        : (width < 780) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 500) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="accountPlanSearchAccountGroup"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableAccountGroup} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="accountPlanCleanAccountGroup"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={cleanAccountGroup} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        :
                          <div style={{marginTop: 20}}>                            
                            <div style={{marginLeft: 10}}>
                              <label style={{fontWeight: 600}}>Selecione o grupo de contas:</label>
                            </div>

                            <SearchAccountGroup
                              modeSearch={true}
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonLogin={jsonLogin}
                              jsonCompany={jsonCompany}
                              system={system}
                              setAdd={() => alert("")}
                              setSelected={fillAccountGroup}
                              setReturn={() => setShowAccountGroup(false)} />
                          </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default AccountPlan