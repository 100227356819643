import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import image_save from "../../Images/Buttons/save.png"
import image_previous from "../../Images/Buttons/previous.png"

const ServiceItemCnae = ({jsonServiceItemCnae, save, returnAdd, showMenu, width}) => {

  const [aliquot, setAliquot] = useState(0)
  const [originCode, setOriginCode] = useState("")
  const [code, setCode] = useState("")  
  const [serviceCodeCnaeCode, setServiceCodeCnaeCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [description, setDescription] = useState("")
  const [standard, setStandard] = useState(2)
  const [sge, setSge] = useState("")

  useEffect(() => {
    if (jsonServiceItemCnae.CODE !== undefined){
      setOriginCode(jsonServiceItemCnae.CODE)
      setCode(jsonServiceItemCnae.CODE)
      document.getElementById("serviceItemCnaeDescription") && document.getElementById("serviceItemCnaeDescription").focus()
    } else document.getElementById("serviceItemCnaeCode") && document.getElementById("serviceItemCnaeCode").focus()

    setCompanyCode(jsonServiceItemCnae.COMPANY.ID)
    setServiceCodeCnaeCode(jsonServiceItemCnae.SERVICE_CODE_CNAE.CODE)
    setDescription(jsonServiceItemCnae.DESCRIPTION)

    if (jsonServiceItemCnae.DEFAULT !== undefined) setStandard(parseInt(jsonServiceItemCnae.DEFAULT))
    else setStandard(2)

    if (jsonServiceItemCnae.ALIQUOT !== undefined) setAliquot(formatNumeric(jsonServiceItemCnae.ALIQUOT, 2))
    else setAliquot(0)

    if ((jsonServiceItemCnae.SGE !== null) && (jsonServiceItemCnae.SGE !== undefined)) setSge(jsonServiceItemCnae.SGE)

  }, [jsonServiceItemCnae])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleAliquot = (event) => {
    setAliquot(formatNumeric(event.target.value, 2))
  }

  const onSave = () => {
    var json = "{"
    if (originCode === "") json += " \"TYPE\": 1"; else json += " \"TYPE\": 2"
    json += ", \"CODE\": \""+ code + "\""
    json += ", \"COMPANY\": { \"ID\": \"" + companyCode + "\" }"    
    if (description !== undefined) json += ", \"DESCRIPTION\": \""+ description +"\""
    else json += ", \"DESCRIPTION\": \"\""
    json += ", \"ALIQUOT\": "+ aliquot
    json += ", \"SERVICE_CODE_CNAE\": { \"CODE\": \"" + serviceCodeCnaeCode + "\" }"    
    json += ", \"DEFAULT\": \""+ standard + "\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      <div div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
          marginLeft: 5, width: 147}} onClick={() => onSave()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
          width: 147}} onClick={() => returnAdd()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      { originCode === "" ? <b>Inclusão:</b> : <b>Alteração:</b>  }

      <div style={(showMenu) ?
          (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
        :
          (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

        <div style={(showMenu) ?
            (width < 1020) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
            : {display: "flex", flexGrow: 1, flexDirection: "row"}
          : 
            (width < 720) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
            : {display: "flex", flexGrow: 1,  flexDirection: "row"}}>

          <div style={(showMenu) ? 
              (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
            : 
              (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

            <Input
              type="text"
              name="serviceItemCnaeCode"
              description="Código"
              value={code || ""}
              maxLength={5}
              disabled={(originCode === "") ? false : true}
              require={true}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setCode(event.target.value)} />
          </div>

          <div style={(showMenu) ? 
              (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
            : 
              (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>

            <Input
              type="text"
              name="serviceItemCnaeDescription"
              description="Descrição"
              value={description}
              maxLength={255}
              disabled={false}
              require={true}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setDescription(event.target.value)} />
          </div>
        </div>

        <div style={(showMenu) ? 
            (width < 480) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
            : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
            : (width < 780) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
            : (width < 1360) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
            : {display: "flex", flexGrow: 0, flexDirection: "row"}
          : 
            (width < 480) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
            : (width < 1060) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
            : {display: "flex", flexGrow: 0,  flexDirection: "row"}}>

          <div style={(showMenu) ? 
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
              : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 1360) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
            : 
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : (width < 1060) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

            <InputNumeric
              type="text"
              name="serviceItemCnaeAliquot"
              description="Alíquota"
              value={aliquot || ""}
              maxLength={13}
              disabled={false}
              require={true}
              negative={false} 
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={handleAliquot} />                        
          </div>            

          <div style={(showMenu) ? 
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : (width < 1360) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
            : 
              (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
              : (width < 1060) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

            <Select
              name="serviceItemCnaeStandard"
              description="Padrão"
              value={standard || 2}
              require={true}
              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              set={setStandard} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceItemCnae