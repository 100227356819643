import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchRegisterTable from "./Search"
import SearchRegisterTableReservation from "../RegisterTableReservation/Search"
import image_loading from "../../Images/spinner.gif"

const RegisterTable = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [number, setNumber] = useState("")
  const [numberOrigin, setNumberOrigin] = useState("")
  const [situation, setSituation] = useState(1)
  const [status, setStatus] = useState(1)
  const [code, setCode] = useState("")
  const [codeLocal, setCodeLocal] = useState("")

  const [jsonRegisterTable, setJsonRegisterTable] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (numberOrigin === ""){
      setSituation(1)
      setStatus(1)
      setCode("")
      setCodeLocal("")

      document.getElementById("registerTableNumber") && document.getElementById("registerTableNumber").focus()
    }
    else{
      setSituation(jsonRegisterTable.SITUATION)
      setStatus(jsonRegisterTable.STATUS)

      if ((jsonRegisterTable.CODE !== null) && (jsonRegisterTable.CODE !== 0)) setCode(jsonRegisterTable.CODE)
      if ((jsonRegisterTable.CODE_LOCAL !== null) && (jsonRegisterTable.CODE_LOCAL !== 0)) setCodeLocal(jsonRegisterTable.CODE_LOCAL)

      document.getElementById("registerTableSituation") && document.getElementById("registerTableSituation").focus()
    }
  }, [number, jsonRegisterTable, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (number === ""){
      setSituation(1)
      setStatus(1)
      setCode("")
      setCodeLocal("")
    }
    else{
      setNumberOrigin("")
      setNumber("")
    }

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setNumberOrigin("")
    setNumber("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonRegisterTable_New = {}

    setWarning("")
    setError("")

    jsonRegisterTable_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } "
    if (number !== "") jsonRegisterTable_New += ", \"NUMBER\": "+ number
    jsonRegisterTable_New += ", \"SITUATION\": "+ situation
    jsonRegisterTable_New += ", \"STATUS\": "+ status
    jsonRegisterTable_New += ", \"IMPORTED\": "+ 2    
    if (code !== "") jsonRegisterTable_New += ", \"CODE\": "+ code
    if (codeLocal !== "") jsonRegisterTable_New += ", \"CODE_LOCAL\": "+ codeLocal
    jsonRegisterTable_New += " }"

    if (numberOrigin === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonRegisterTable_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "REGISTER_TABLE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a mesa. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("registerTableNumber") && document.getElementById("registerTableNumber").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonRegisterTable(returnJson.OBJECT)
        setNumber(returnJson.OBJECT.NUMBER)
        setNumberOrigin(returnJson.OBJECT.NUMBER)

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonRegisterTable))
      formData.append("JSON_NEW_1", jsonRegisterTable_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "REGISTER_TABLE/ModifySituation", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a mesa. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("registerTableSituation") && document.getElementById("registerTableSituation").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonRegisterTable(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonRegisterTable(event)
    setNumber(event.NUMBER)
    setNumberOrigin(event.NUMBER)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Mesa"
            titleSearch="Pesquisa das Mesas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchRegisterTable
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={showMenu ?  
                            (width < 1180) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                          :
                            (width < 910) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                            <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                              <Input
                                type="text"
                                name="registerTableNumber"
                                description="Número"
                                value={number}
                                maxLength={2}
                                disabled={ (numberOrigin === "") ? false : true }
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setNumber(event.target.value)} />
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :
                              (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 990) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                              <div style={showMenu ?
                                  (width < 390) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                :
                                  (width < 390) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                <Select
                                  name="registerTableSituation"
                                  description="Situação"
                                  value={situation || 0}
                                  disabled={false}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                                    ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setSituation} />
                              </div>

                              <div style={showMenu ?
                                  (width < 390) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                :
                                  (width < 390) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                <Select
                                  name="registerTableStatus"
                                  description="Status"
                                  value={status || 0}
                                  disabled={true}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Vazia\" }, {\"ID\": 2, \"VALUE\": \"Ocupada\" }"+
                                      ", {\"ID\": 3, \"VALUE\": \"Finalizada\" }, {\"ID\": 4, \"VALUE\": \"Reservada\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setStatus} />
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 990) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                              :
                                (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                              <div style={showMenu ?
                                  (width < 390) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                :
                                  (width < 390) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                <Input
                                  type="text"
                                  name="registerTableCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>

                              <div style={showMenu ?
                                  (width < 390) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 1180) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                :
                                  (width < 390) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 160}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                <Input
                                  type="text"
                                  name="registerTableCodeLocal"
                                  description="Código Local"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCodeLocal(event.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* reservation // reserva */}
                        { numberOrigin && (parseInt(situation) == 1) && (parseInt(status) == 1) && 
                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                            <Titulo description="Reserva" />

                            <SearchRegisterTableReservation
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonLogin={jsonLogin}
                              system={system} 
                              jsonRegisterTable={jsonRegisterTable}
                              showMenu={showMenu}
                              width={width} />
                          </div>
                        }

                        
                      </div>
                    </div>
                }
              </div>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default RegisterTable