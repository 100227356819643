import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import BillReceivePortions from "../BillReceivePortions"
import SearchAccount from "../Account/Search"
import SearchAccountPlan from "../AccountPlan/Search"
import SearchBillet from "../Billet/Search"
import SearchBillReceive from "./Search"
import SearchBillReceiveReceipt from "../BillReceiveReceipt/Search"
import SearchClient from "../Client/Search"
import SearchDocumentType from "../DocumentType/Search"
import SearchPaymentForm from "../PaymentForm/Search"
import image_cancel from "../../Images/Buttons/cancel.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_receive from "../../Images/Buttons/receive.png"
import image_reversal from "../../Images/Buttons/reversal.png";
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select_circle.png"

const BillReceive = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, selectNoteService, setReturn, height, width, 
  logoff}) => {

  const [code, setCode] = useState("")
  const [accountPlanCode, setAccountPlanCode] = useState("")
  const [accountPlanNumber, setAccountPlanNumber] = useState("")
  const [accountPlanDescription, setAccountPlanDescription] = useState("")
  const [documentTypeCode, setDocumentTypeCode] = useState("")
  const [documentTypeDescription, setDocumentTypeDescription] = useState("")
  const [paymentFormCode, setPaymentFormCode] = useState("")
  const [paymentFormDescription, setPaymentFormDescription] = useState("")
  const [paymentFormTerm , setPaymentFormTerm] = useState("")

  const [clientCode, setClientCode] = useState("")
  const [clientCpfCnpj, setClientCpfCnpj] = useState("")
  const [clientCorporateName, setClientCorporateName] = useState("")
  const [clientFantasyName, setClientFantasyName] = useState("")
  const [clientCep, setClientCep] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [clientNumber, setClientNumber] = useState("")
  const [clientComplement, setClientComplement] = useState("")
  const [clientNeighborhood, setClientNeighborhood] = useState("")
  const [clientCity, setClientCity] = useState("")
  const [clientCityIBGE, setClientCityIBGE] = useState("")
  const [clientUf, setClientUf] = useState("")
  const [clientResponsibleCpf, setClientResponsibleCpf] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientIm, setClientIm] = useState("")
  const [clientTypeCode, setClientTypeCode] = useState("")
  const [clientTypeDescription, setClientTypeDescription] = useState("")
  const [clientBankType, setClientBankType] = useState(0)

  const [nfs, setNfs] = useState("")
  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")
  const [number, setNumber] = useState("")
  const [receiptNumber, setReceiptNumber] = useState(0)
  const [portion, setPortion] = useState("")
  const [totalPortion, setTotalPortion] = useState("")
  const [emissionDate, setEmissionDate] = useState("")
  const [releaseDate, setReleaseDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [cancellationDate, setCancellationDate] = useState("")
  const [cancellationDatePrevious, setCancellationDatePrevious] = useState("")
  const [receivingDate, setReceivingDate] = useState("")
  const [receivingDatePrevious, setReceivingDatePrevious] = useState("")
  const [value, setValue] = useState("")

  const [valueRetained, setValueRetained] = useState("")
  const [valueReceived, setValueReceived] = useState("") // Valor Recebido
  const [valueReceivedPrevious, setValueReceivedPrevious] = useState("")
  const [valueReceive, setValueReceive] = useState("") // Valor à Receber
  const [situationPreviousDescription, setSituationPreviousDescription] = useState("")
  const [origin, setOrigin] = useState(0)
  const [observation, setObservation] = useState("")
  const [generateNfse, setGenerateNfse] = useState(0)    
  const [situation, setSituation] = useState(0)
  const [situationPrevious, setSituationPrevious] = useState(0)
  const [situationDescription, setSituationDescription] = useState("")
  const [sge, setSge] = useState("")

  ////
  // Service // Serviço
  ////
  const [showService, setShowService] = useState("2")
  const [highlightRetain, setHighlightRetain] = useState("1")
  const [serviceNature, setServiceNature] = useState("0")

  const [serviceCnaeCode, setServiceCnaeCode] = useState("")
  const [serviceCnaeDescription, setServiceCnaeDescription] = useState("")
  const [serviceCodeCnaeCode, setServiceCodeCnaeCode] = useState("")
  const [serviceCodeCnaeDescription, setServiceCodeCnaeDescription] = useState("")
  const [serviceItemCnaeCode, setServiceItemCnaeCode] = useState("")
  const [serviceItemCnaeDescription, setServiceItemCnaeDescription] = useState("")

  const [serviceAliquot, setServiceAliquot] = useState(0)
  const [percentageINSS, setPercentageINSS] = useState(0)
  const [percentageIR, setPercentageIR] = useState(0)
  const [percentageCSLL, setPercentageCSLL] = useState(0)
  const [jsonNoteService, setJsonNoteService] = useState({})

  const [jBillReceive, setJBillReceive] = useState({})
  const [jBillReceiveReversal, setJBillReceiveReversal] = useState("{}")

  const [jsonServiceCnae, setJsonServiceCnae] = useState([])
  const [jsonServiceCodeCnae, setJsonServiceCodeCnae] = useState([])
  const [jsonServiceItemCnae, setJsonServiceItemCnae] = useState([])

  const [showMenu, setShowMenu] = useState(true)
  const [showFunctions, setShowFunctions] = useState(false)
  const [firstAcess, setFirstAcess] = useState(true)

  const [showCancellation, setShowCancellation] = useState(false)
  const [showAccount, setShowAccount] = useState(false)
  const [showReversal, setShowReversal] = useState(false)
  const [showCancellationReversal, setShowCancellationReversal] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  const [showClient, setShowClient] = useState(false)
  const [showReceipt, setShowReceipt] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)

  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsServiceCnae, setOptionsServiceCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsServiceCodeCnae, setOptionsServiceCodeCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsServiceItemCnae, setOptionsServiceItemCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  useEffect(() => {
    if (firstAcess){
      let returnJson = []
      
      ///
      // Get information from the Service CNAE // Carrega informações do CNAE do serviço
      ///
      setLoading("Aguarde buscando as informações dos CNAEs dos serviços...")      
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonServiceCnae(returnJson.OBJECT)

          let serviceCnae = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              serviceCnae += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
            ))
          serviceCnae += "]}"

          setOptionsServiceCnae(serviceCnae)
        }
      })

      ////
      // Get information from the Code Service CNAE // Carrega informações do Código do Serviço CNAE
      ////
      setLoading("Aguarde buscando as informações dos Códigos dos Serviços CNAE...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CODE_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os códigos dos serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonServiceCodeCnae(returnJson.OBJECT)
        }
      })
      
      ////
      // Get information from the Item Service CNAE // Carrega informações do Item do Serviço CNAE
      ///
      setLoading("Aguarde buscando as informações dos Itens dos serviços CNAE...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os itens dos serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonServiceItemCnae(returnJson.OBJECT)
        }
      })

      setFirstAcess(false)
      setLoading("")
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, code])

  const add = (event) => {
    setShowSearch(false)
        
    setError("")
    setWarning("")
        
    setCode("")
    setAccountPlanCode("")
    setAccountPlanNumber("")
    setAccountPlanDescription("")
    setDocumentTypeCode("")
    setDocumentTypeDescription("")
    setPaymentFormCode("")
    setPaymentFormDescription("")
    setPaymentFormTerm("")
    setClientCode("")
    setClientCpfCnpj("")
    setClientCorporateName("")
    setClientFantasyName("")
    setClientCep("")
    setClientAddress("")
    setClientNumber("")
    setClientComplement("")
    setClientNeighborhood("")
    setClientCity("")
    setClientCityIBGE("")
    setClientUf("")
    setClientResponsibleCpf("")
    setClientPhone("")
    setClientEmail("")
    setClientIm("")
    setClientTypeCode("")
    setClientTypeDescription("")
    setClientBankType(0)

    ////
    // Service // Serviço
    ////
    setShowService("2")
    setServiceNature("0")
    setHighlightRetain("1")

    setServiceCnaeCode("")
    setServiceCnaeDescription("")
    setServiceCodeCnaeCode("")
    setServiceCodeCnaeDescription("")
    setServiceItemCnaeCode("")
    setServiceItemCnaeDescription("")
    setServiceAliquot(0)
    setPercentageCSLL(0)
    setPercentageINSS(0)
    setPercentageIR(0)

    setJsonNoteService({})
    setJBillReceive({})
    setJBillReceiveReversal("{}")

    setNfs("")

    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")
    setNumber("")
    setReceiptNumber(0)
    setPortion("")
    setTotalPortion("")
    setEmissionDate("")
    setReleaseDate("")
    setDueDate("")
    setCancellationDate("")
    setCancellationDatePrevious("")
    setReceivingDate("")
    setReceivingDatePrevious("")
    setValue("")
    setValueRetained("")
    setValueReceived("")
    setValueReceivedPrevious("")
    setValueReceive("")
    setSituationPreviousDescription("")

    setOrigin(0)
    setObservation("")
    setGenerateNfse(0)
    setSituation(1)
    setSituationDescription("Lançada")
    setSge("")
    
    setShowFunctions(true)
  }

  const callCancellation = () => {
    let date = new Date()
    date = date.toLocaleDateString()
    setCancellationDate(date)

    setShowCancellation(true)

    setSituationPrevious(situation)
    setSituation(3)
    setSituationPreviousDescription(situationDescription)
    setSituationDescription("Cancelado")
  }

  const callCancellationReversal = (item) => {
    setShowCancellationReversal(true)
    
    setCancellationDatePrevious(cancellationDate)
    setCancellationDate("")

    if (receiptNumber === 0)
    {
      setSituationPrevious(situation)
      setSituation(1)
      setSituationPreviousDescription(situationDescription)
      setSituationDescription("Pendente")
    }
    else
    {
      setSituationPrevious(situation)
      setSituation(4)
      setSituationPreviousDescription(situationDescription)
      setSituationDescription("Recebido parcialmente")
    }
  }

  const callReceipt = () => {
    setReceivingDatePrevious(receivingDate)
    setValueReceivedPrevious(valueReceived)

    setReceivingDate("")
    setValueReceived("")
    setShowReceipt(true)
  }

  const callReversal = (item) => {
    const jsonReversal = "{ \"ID\": "+ item.ID +", \"ACCOUNT\": { \"ID\": "+ item.ACCOUNT.ID +" }, \"BILL_RECEIVE\": { \"COMPANY\": { \"ID\": "+ 
      item.BILL_RECEIVE.COMPANY.ID +" } }, \"VALUE\": "+ item.VALUE +" }"

    setJBillReceiveReversal(jsonReversal)

    setValueReceivedPrevious(valueReceived)
    setValueReceived(valueReceived - item.VALUE)

    setShowReversal(true)

    setSituationPrevious(situation)
    setSituation(2)
    setSituationPreviousDescription(situationDescription)
    setSituationDescription("Recebido")
  }

  const cancel = () => {
    save()
  }

  const cleanAccount = () => {
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    setAccountPlanCode("")
    setAccountPlanNumber("")
    setAccountPlanDescription("")

    setShowAccountPlan(false)
  }

  const cleanClient = () => {
    setClientCode("")
    setClientCpfCnpj("")
    setClientCorporateName("")
    setClientFantasyName("")
    setClientCep("")
    setClientAddress("")
    setClientNumber("")
    setClientComplement("")
    setClientNeighborhood("")
    setClientCity("")
    setClientCityIBGE("")
    setClientUf("")
    setClientResponsibleCpf("")
    setClientPhone("")
    setClientEmail("")
    setClientIm("")
    setClientTypeCode("")
    setClientTypeDescription("")
    setClientBankType(0)

    setShowClient(false)
  }  

  const cleanDocumentType = () => {
    setDocumentTypeCode("")
    setDocumentTypeDescription("")

    setShowDocumentType(false)
  }

  const cleanPaymentForm = () => {
    setPaymentFormCode("")
    setPaymentFormDescription("")

    setShowPaymentForm(false)
  }

  const enableAccount = () => {
    setShowAccount(!showAccount)
  }

  const enableAccountPlan = () => {
    setShowAccountPlan(!showAccountPlan)
  }

  const enableClient = () => {
    setShowClient(!showClient)
  }

  const enableDocumentType = () => {
    setShowDocumentType(!showDocumentType)
  }

  const enablePaymentForm = () => {
    setShowPaymentForm(!showPaymentForm)
  }  

  const fieldSelected = () => {
  }

  const fillAccount = (account) => {
    setAccountCode(account.ID)
    setAccountNumber(account.NUMBER)
    setAccountOwner(account.OWNER)

    setShowAccount(false)
  }

  const fillAccountPlan = (accountPlan) => {
    setAccountPlanCode(accountPlan.ID)
    setAccountPlanNumber(accountPlan.NUMBER)
    setAccountPlanDescription(accountPlan.DESCRIPTION)

    setShowAccountPlan(false)
  }  

  const fillClient = (client) => {
    setClientCode(client.ID)
    setClientCpfCnpj(client.CPF_CNPJ)
    setClientCorporateName(client.CORPORATE_NAME)
    setClientFantasyName(client.FANTASY_NAME)
    setClientCep(client.CEP)
    setClientAddress(client.ADDRESS)
    setClientNumber(client.NUMBER)

    if (client.COMPLEMENT != null) setClientComplement(client.COMPLEMENT)

    setClientNeighborhood(client.NEIGHBORHOOD)
    setClientCity(client.CITY)
    setClientCityIBGE(client.CITY_IBGE)
    setClientUf(client.UF)

    if (client.RESPONSIBLE_CPF != null) setClientResponsibleCpf(client.RESPONSIBLE_CPF)
    if (client.STANDARD_PHONE != null) setClientPhone(client.STANDARD_PHONE)
    if (client.STANDARD_EMAIL != null) setClientEmail(client.STANDARD_EMAIL)
    if (client.IM != null) setClientIm(client.IM)

    setClientTypeCode(client.CLIENT_TYPE.ID)
    setClientTypeDescription(client.CLIENT_TYPE.DESCRIPTION)

    if (client.NATURE_SERVICE != null) setServiceNature(client.NATURE_SERVICE)
    if (client.HIGHLIGHT_RETAIN != null) setHighlightRetain(client.HIGHLIGHT_RETAIN)

    if (client.SERVICE_CODE_CNAE !== null){
      setOptionsServiceCodeCnae("{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"+
        ", {\"ID\": \""+ client.SERVICE_CODE_CNAE.CODE +"\", \"VALUE\": \""+ client.SERVICE_CODE_CNAE.DESCRIPTION +"\"}]}")

      if (client.SERVICE_CODE_CNAE.CODE !== null) setServiceCodeCnaeCode(client.SERVICE_CODE_CNAE.CODE)
      if (client.SERVICE_CODE_CNAE.DESCRIPTION !== null) setServiceCodeCnaeDescription(client.SERVICE_CODE_CNAE.DESCRIPTION)

      if (client.SERVICE_CODE_CNAE.SERVICE_CNAE !== null)
      {
        if (client.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE !== null) setServiceCnaeCode(client.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE)

        if (client.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION !== null) 
          setServiceCnaeDescription(client.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION)
      }
    }

    if (client.CLIENT_COMPANY != null){
      if (client.CLIENT_COMPANY.SERVICE_ITEM_CNAE != null){
        setOptionsServiceItemCnae("{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"+
          ", {\"ID\": \""+ client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE +
            "\", \"VALUE\": \""+ client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION +"\"}]}")

        if (client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE !== null) setServiceItemCnaeCode(client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE)

        if (client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION !== null)
          setServiceItemCnaeDescription(client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION)

        if (client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.ALIQUOT !== null) 
          setServiceAliquot(formatNumeric(client.CLIENT_COMPANY.SERVICE_ITEM_CNAE.ALIQUOT,2))
      }

      if ((client.CLIENT_COMPANY.BANK_TYPE !== null) && (client.CLIENT_COMPANY.BANK_TYPE !== 0)) 
        setClientBankType(client.CLIENT_COMPANY.BANK_TYPE)        
    }    

    setPercentageCSLL(client.PERCENTAGE_CSLL)
    setPercentageINSS(client.PERCENTAGE_INSS)
    setPercentageIR(client.PERCENTAGE_IR)

    setShowClient(false)
  }

  const fillDocumentType = (documentType) => {
    setDocumentTypeCode(documentType.ID)
    setDocumentTypeDescription(documentType.DESCRIPTION)

    setShowDocumentType(false)
  }  

  const fillPaymentForm = (paymentForm) => {
    setPaymentFormCode(paymentForm.ID)
    setPaymentFormDescription(paymentForm.DESCRIPTION)

    let term = "0"
    if (jsonCompany.TYPE === 7){
      if (paymentForm.TERM_PAY == null) term = "0"
      else term = paymentForm.TERM_PAY
    }
    else {
      if (paymentForm.TERM_RECEIVE == null) term = "0"
      else term = paymentForm.TERM_RECEIVE
    }

    setPaymentFormTerm(term)
    setShowPaymentForm(false)
  }

  const fillPortion = (number, portion, totalPortion, company, accountPlan, documentType, paymentForm, client, employee, noteService) => {
    let portionNew = ""

    if (portion.PORTION > 1) portionNew += ", {"
    else portionNew += "{"

    portionNew += company
    portionNew += accountPlan
    portionNew += documentType
    portionNew += paymentForm
    portionNew += client
    portionNew += employee

    if (showService === "1")
    {
      portionNew += noteService
      portionNew += ", \"GENERATE_NFSE\": 1"
    } else portionNew += ", \"GENERATE_NFSE\": 2"

    portionNew += ", \"NUMBER\": "+ number + ","
    portionNew += " \"PORTION\": "+ portion.PORTION + ","
    portionNew += " \"TOTAL_PORTION\": "+ totalPortion + ","

    let date = new Date()
    date = date.toLocaleDateString()

    portionNew += " \"EMISSION_DATE\": \""+ date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2) + "T00:00:00\","
    portionNew += " \"RELEASE_DATE\": \""+ date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2) + "T00:00:00\","
    portionNew += " \"DUE_DATE\": \""+ portion.DUE_DATE.substr(6, 4) + "-" + portion.DUE_DATE.substr(3, 2) + "-" + portion.DUE_DATE.substr(0, 2) + "T00:00:00\","
    portionNew += " \"VALUE\": "+ portion.VALUE + ","
    portionNew += " \"VALUE_RETAINED\": 0,"
    portionNew += " \"VALUE_RECEIVED\": 0,"
    portionNew += " \"SITUATION\": 1,"
    portionNew += " \"ORIGIN\": 1,"
    portionNew += " \"OBSERVATION\": \""+ portion.OBSERVATION +"\""
    portionNew += " }"

    return portionNew
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleObservation = (event) => {
    setObservation(event.target.value.replaceAll("\\",""))
  }

  const handlePercentageCSLL = (event) => {
    setPercentageCSLL(formatNumeric(event.target.value,2))
  }

  const handlePercentageINSS = (event) => {
    setPercentageINSS(formatNumeric(event.target.value,2))
  }

  const handlePercentageIR = (event) => {
    setPercentageIR(formatNumeric(event.target.value,2))
  }  
  
  const handleServiceAliquot = (event) => {
    setServiceAliquot(formatNumeric(event.target.value,2))
  }

  const handleValueReceived = (event) => {
    if (event.target.value !== "")
      formatNumeric(setValueReceived(event.target.value), 2)
  }

  const receive = () => { 
    if (!validateDate(receivingDate)){
      setError("Data do recebimento inválida!")
      return
    }

    if (accountCode === ""){
      setError("É necessário informar a conta!")
      return
    }

    if (valueReceived === ""){
      setError("É necessário informar o valor!")
      return
    }

    if (parseFloat(valueReceived) <= 0){
      setError("É necessário informar o valor!")
      return
    }

    save()
  }

  const returnAdd = () => {
    setWarning("")
    setError("")

    setJBillReceiveReversal("{}")

    setShowAccountPlan(false)
    setShowCancellation(false)
    setShowCancellationReversal(false)
    setShowClient(false)
    setShowDocumentType(false)
    setShowPaymentForm(false)
    setShowReceipt(false)
    setShowReversal(false)

    setShowSearch(true)
  }

  const returnCancellation = () => {
    setCancellationDate("")
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowCancellation(false)
  }

  const returnCancellationReversal = () => {
    setCancellationDate(cancellationDatePrevious)
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowCancellationReversal(false)
  }

  const returnReceipt = () => {
    setReceivingDate(receivingDatePrevious)
    setValueReceived(valueReceivedPrevious)

    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setShowReceipt(false)
  }
  
  const returnReversal = () => {
    setReceivingDate(receivingDatePrevious)
    setValueReceived(valueReceivedPrevious)
    setSituation(situationPrevious)
    setSituationDescription(situationPreviousDescription)

    setShowReversal(false)
  }

  const reversal = () => {
    save()
  }

  const reversalCancellation = () => {
    save()
  }

  const save = (portions) => {
    let returnJson = []
    let jsonBillReceive_New = {}

    setWarning("")
    setError("")

    if (number === ""){
      setError("Número não informado.")
      return
    }

    if (clientCode === ""){
      setError("Cliente não informado.")
      return
    }

    if (accountPlanCode === ""){
      setError("Plano de conta não informado.")
      return
    }

    if (documentTypeCode === ""){
      setError("Tipo de documento não informado.")
      return
    }

    if (code === ""){
      if (paymentFormCode === ""){
        setError("Forma de pagamento não informada.")
        return
      }
    }

    const jsonBillReceiveCompany_New = " \"COMPANY\": "+ JSON.stringify(jsonCompany)

    const jsonBillReceiveAccountPlan_New = ", \"ACCOUNT_PLAN\": { \"ID\": "+ accountPlanCode +", \"NUMBER\": \""+ accountPlanNumber +
      "\", \"DESCRIPTION\": \""+ accountPlanDescription +"\" }"

    const jsonBillReceiveDocumentType_New = ", \"DOCUMENT_TYPE\": { \"ID\": "+ documentTypeCode +", \"DESCRIPTION\": \""+ documentTypeDescription +"\" }"

    let jsonBillReceivePaymentForm_New = ""
    if (paymentFormCode !== "") 
      jsonBillReceivePaymentForm_New = ", \"PAYMENT_FORM\": { \"ID\": "+ paymentFormCode +", \"DESCRIPTION\": \""+ paymentFormDescription +"\" }"
    else jsonBillReceivePaymentForm_New = ", \"PAYMENT_FORM\": { \"ID\": 0, \"DESCRIPTION\": \"\" }"

    let clientIbge = 0
    if (clientCityIBGE !== "") clientIbge = clientCityIBGE

    let jsonBillReceiveClient_New = ", \"CLIENT\": { \"ID\": "+ clientCode +", \"CPF_CNPJ\": \""+ clientCpfCnpj +
      "\", \"CORPORATE_NAME\": \""+ clientCorporateName +"\", \"FANTASY_NAME\": \""+ clientFantasyName +"\", \"CEP\": \""+ clientCep +
      "\", \"ADDRESS\": \""+ clientAddress +"\", \"NUMBER\": \""+ clientNumber +"\", \"NEIGHBORHOOD\": \""+ clientNeighborhood +
      "\", \"CITY\": \""+ clientCity +"\", \"CITY_IBGE\": "+ clientIbge  +", \"UF\": \""+ clientUf +
      "\", \"CLIENT_TYPE\": { \"ID\": "+ clientTypeCode +", \"DESCRIPTION\": \""+ clientTypeDescription +"\" }"
    if (clientComplement !== "") jsonBillReceiveClient_New += ", \"COMPLEMENT\": \""+ clientComplement +"\""
    if (clientResponsibleCpf !== "") jsonBillReceiveClient_New += ", \"RESPONSIBLE_CPF\": \""+ clientResponsibleCpf +"\""
    if (clientPhone !== "") jsonBillReceiveClient_New += ", \"STANDARD_PHONE\": \""+ clientPhone +"\""
    if (clientEmail !== "") jsonBillReceiveClient_New += ", \"STANDARD_EMAIL\": \""+ clientEmail +"\""
    if (clientIm !== "") jsonBillReceiveClient_New += ", \"IM\": \""+ clientIm +"\""
    if (clientBankType !== "") jsonBillReceiveClient_New += ", \"CLIENT_COMPANY\": { \"BANK_TYPE\": "+ clientBankType +" }"
    jsonBillReceiveClient_New += " }"

    const jsonBillReceiveEmployee_New = ", \"EMPLOYEE\": { \"ID\": "+ jsonLogin.ID +", \"NAME\": \""+ jsonLogin.NAME +"\" }"
    
    let jsonBillReceiveNoteService_New = ", \"NOTE_SERVICE\": { }" 
    if (showService === "1"){
      jsonBillReceiveNoteService_New = ", \"NOTE_SERVICE\": { \"NATURE\":" + serviceNature + ", \"HIGHLIGHT_RETAIN\": "+ highlightRetain +
        ", \"PERCENTAGE_CSLL\": "+ percentageCSLL +", \"PERCENTAGE_INSS\": "+ percentageINSS +", \"PERCENTAGE_IR\": "+ percentageIR +
        ", \"SERVICE_ITEM_CNAE\": { \"CODE\": \""+ serviceItemCnaeCode  +"\", \"DESCRIPTION\": \""+ serviceItemCnaeDescription +
        "\", \"ALIQUOT\": "+ serviceAliquot +", \"SERVICE_CODE_CNAE\": { \"CODE\": \""+ serviceCodeCnaeCode +
        "\", \"DESCRIPTION\": \""+ serviceCodeCnaeDescription +"\", \"SERVICE_CNAE\": { \"CODE\": \""+ serviceCnaeCode  +
        "\", \"DESCRIPTION\": \""+ serviceCnaeDescription +"\" } } }, \"ENVIRONMENT\": \""+ jsonCompany.ENVIRONMENT +"\" }"
    }

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")

      jsonBillReceive_New = "[ "

      portions.map(item => (
        jsonBillReceive_New += fillPortion(number, item, portions.length, jsonBillReceiveCompany_New, jsonBillReceiveAccountPlan_New, 
          jsonBillReceiveDocumentType_New, jsonBillReceivePaymentForm_New, jsonBillReceiveClient_New, jsonBillReceiveEmployee_New, 
            jsonBillReceiveNoteService_New)))

      jsonBillReceive_New += " ]"
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      jsonBillReceive_New = "{ \"ID\": \""+ code +"\","
      jsonBillReceive_New += jsonBillReceiveCompany_New
      jsonBillReceive_New += jsonBillReceiveClient_New
      jsonBillReceive_New += jsonBillReceiveAccountPlan_New
      jsonBillReceive_New += jsonBillReceiveDocumentType_New
      jsonBillReceive_New += jsonBillReceivePaymentForm_New
      jsonBillReceive_New += jsonBillReceiveEmployee_New

      if (jsonNoteService.ID !== undefined) jsonBillReceive_New += ", \"NOTE_SERVICE\": "+ JSON.stringify(jsonNoteService)
      if ((nfs !== "") && (nfs !== 0)) jsonBillReceive_New += ", \"NFS\": "+ nfs

      if (accountCode !== "") jsonBillReceive_New += ", \"ACCOUNT\": { \"ID\": "+ accountCode +", \"NUMBER\": \""+ accountNumber +
        "\", \"OWNER\": \""+ accountOwner +"\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

      jsonBillReceive_New += ", \"NUMBER\": \""+ number +"\""
      jsonBillReceive_New += ", \"PORTION\": "+ portion
      jsonBillReceive_New += ", \"TOTAL_PORTION\": "+ totalPortion

      if (emissionDate !== "")
        if (String(emissionDate).replace("_","").length >= 10){
          const emission = String(emissionDate).substr(6,4) + "-" + String(emissionDate).substr(3,2) + "-" + String(emissionDate).substr(0,2) + "T00:00:00"
          jsonBillReceive_New += ", \"EMISSION_DATE\": \""+ emission + "\""
        }

      const release = String(releaseDate).substr(6,4) + "-" + String(releaseDate).substr(3,2) + "-" + String(releaseDate).substr(0,2) + "T00:00:00"
      jsonBillReceive_New += ", \"RELEASE_DATE\": \""+ release + "\""

      if (dueDate !== "")
        if (String(dueDate).replace("_","").length >= 10){
          const due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00"
          jsonBillReceive_New += ", \"DUE_DATE\": \""+ due + "\""
        }

      if (cancellationDate !== "")
        if (String(cancellationDate).replace("_","").length >= 10){
          const cancellation = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" +
            String(cancellationDate).substr(0,2) + "T00:00:00";
          jsonBillReceive_New += ", \"CANCELLATION_DATE\": \""+ cancellation + "\""
        }

      if (receivingDate !== "")
        if (String(receivingDate).replace("_","").length >= 10){
          const receiving = String(receivingDate).substr(6,4) + "-" + String(receivingDate).substr(3,2) + "-" + String(receivingDate).substr(0,2) + "T00:00:00"
          jsonBillReceive_New += ", \"RECEIVING_DATE\": \""+ receiving + "\""
        }

      if (value !== "") jsonBillReceive_New += ", \"VALUE\": "+ value
      if (valueReceived !== "") jsonBillReceive_New += ", \"VALUE_RECEIVED\": "+ valueReceived
      if (valueRetained !== "") jsonBillReceive_New += ", \"VALUE_RETAINED\": "+ valueRetained      
      if (origin !== 0) jsonBillReceive_New += ", \"ORIGIN\": "+ origin
      if (observation !== "") jsonBillReceive_New += ", \"OBSERVATION\": \""+ observation + "\""
      if (generateNfse !== 0) jsonBillReceive_New += ", \"GENERATE_NFSE\": "+ generateNfse
      if (situation !== 0) jsonBillReceive_New += ", \"SITUATION\": "+ situation
      if ((sge !== "") && (sge !== 0)) jsonBillReceive_New += ", \"SGE\": "+ sge
      jsonBillReceive_New += " }"
    }
    
    if (code === ""){
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonBillReceive_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "BILL_RECEIVE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Conta a Receber. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setError("")
        setLoading("")
        setWarning(returnJson.WARNING)

        alert(returnJson.OBJECT)
        returnAdd()
      })
    }
    else
    {
      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD",  JSON.stringify(jBillReceive))
      formData.append("JSON_NEW_1", jsonBillReceive_New)
      formData.append("JSON_NEW_2", jBillReceiveReversal)
      formData.append("JSON_LOGIN",  JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.put(BASE_URL + "BILL_RECEIVE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Conta a Receber. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }

        setError("")
        setLoading("")
        setWarning(returnJson.WARNING)
      
        setJBillReceiveReversal("{}")

        setShowAccountPlan(false)
        setShowCancellation(false)
        setShowCancellationReversal(false)
        setShowClient(false)
        setShowDocumentType(false)
        setShowPaymentForm(false)
        setShowReceipt(false)
        setShowReversal(false)

        setAccountCode("")
        setAccountNumber("")
        setAccountOwner("")        

        updateRecord()
      })
    }
  }

  const savePortions = (portions) => {
    save(portions)
  }

  const selected = (event) => {
    setShowSearch(false)

    setShowAccountPlan(false)
    setShowClient(false)
    setShowDocumentType(false)
    setShowPaymentForm(false)

    setCode(event.ID)
    setAccountPlanCode(event.ACCOUNT_PLAN.ID)
    setAccountPlanNumber(event.ACCOUNT_PLAN.NUMBER)
    setAccountPlanDescription(event.ACCOUNT_PLAN.DESCRIPTION)
    setDocumentTypeCode(event.DOCUMENT_TYPE.ID)
    setDocumentTypeDescription(event.DOCUMENT_TYPE.DESCRIPTION)

    if (event.PAYMENT_FORM != null){
      if (event.PAYMENT_FORM.ID != null) setPaymentFormCode(event.PAYMENT_FORM.ID); else setPaymentFormCode("")
      if (event.PAYMENT_FORM.DESCRIPTION != null) setPaymentFormDescription(event.PAYMENT_FORM.DESCRIPTION); else setPaymentFormDescription("")
    }

    setClientCode(event.CLIENT.ID)
    setClientCpfCnpj(event.CLIENT.CPF_CNPJ)
    setClientCorporateName(event.CLIENT.CORPORATE_NAME)
    setClientFantasyName(event.CLIENT.FANTASY_NAME)

    if (event.CLIENT.RESPONSIBLE_CPF !== null) setClientResponsibleCpf(event.CLIENT.RESPONSIBLE_CPF)

    setClientTypeCode(event.CLIENT.CLIENT_TYPE.ID)
    setClientTypeDescription(event.CLIENT.CLIENT_TYPE.DESCRIPTION)

    ////
    // Service // Serviço
    ////
    if (event.CLIENT.NATURE_SERVICE !== null) setServiceNature(event.CLIENT.NATURE_SERVICE)
    if (event.CLIENT.HIGHLIGHT_RETAIN !== null) setHighlightRetain(event.CLIENT.HIGHLIGHT_RETAIN)

    if (event.CLIENT.SERVICE_CODE_CNAE !== null)
    {
      if (event.CLIENT.SERVICE_CODE_CNAE.CODE !== null) setServiceCodeCnaeCode(event.CLIENT.SERVICE_CODE_CNAE.CODE)
      if (event.CLIENT.SERVICE_CODE_CNAE.DESCRIPTION !== null) setServiceCodeCnaeDescription(event.CLIENT.SERVICE_CODE_CNAE.DESCRIPTION)

      if (event.CLIENT.SERVICE_CODE_CNAE.SERVICE_CNAE !== null)
      {
        if (event.CLIENT.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE !== null) setServiceCnaeCode(event.CLIENT.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE)

        if (event.CLIENT.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION !== null) 
          setServiceCnaeDescription(event.CLIENT.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION)

        let optionsServiceCnae = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
        jsonServiceCnae &&
          jsonServiceCnae.map(item => (
            optionsServiceCnae += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
          ))
          optionsServiceCnae += "]}"
        setOptionsServiceCnae(optionsServiceCnae)

        let optionsServiceCodeCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
        jsonServiceCodeCnae &&
          jsonServiceCodeCnae.map(it => (
            optionsServiceCodeCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}"
          ))
        optionsServiceCodeCnae += "]}"
        setOptionsServiceCodeCnae(optionsServiceCodeCnae)
  
        let optionsServiceItemCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
        jsonServiceItemCnae &&
          jsonServiceItemCnae.map(it => (
            optionsServiceItemCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}"
          ))
        optionsServiceItemCnae += "]}"

        setOptionsServiceItemCnae(optionsServiceItemCnae)
      }
    }

    if (event.CLIENT.CLIENT_COMPANY !== null)
    {
      if (event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE !== null)
      {      
        if (event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE !== null) setServiceItemCnaeCode(event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE)

        if (event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION !== null) 
          setServiceItemCnaeDescription(event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION)

        setServiceAliquot(formatNumeric(event.CLIENT.CLIENT_COMPANY.SERVICE_ITEM_CNAE.ALIQUOT,2))
      }

      if ((event.CLIENT.CLIENT_COMPANY.BANK_TYPE !== null) && (event.CLIENT.CLIENT_COMPANY.BANK_TYPE !== 0))
        setClientBankType(event.CLIENT.CLIENT_COMPANY.BANK_TYPE)
    }

    if (((event.NFS !== undefined) && (event.NFS !== null)) && (event.NFS !== 0)) setNfs(event.NFS)

    if (event.ACCOUNT != null){
      if (event.ACCOUNT.ID != null) setAccountCode(event.ACCOUNT.ID); else setAccountCode("")
      if (event.ACCOUNT.NUMBER != null) setAccountNumber(event.ACCOUNT.NUMBER); else setAccountNumber("")
      if (event.ACCOUNT.OWNER != null) setAccountOwner(event.ACCOUNT.OWNER); else setAccountOwner("")
    }

    setNumber(event.NUMBER)
    setPortion(event.PORTION)
    setTotalPortion(event.TOTAL_PORTION)

    const sEmissionDate = event.EMISSION_DATE.substr(8, 2) + "-" + event.EMISSION_DATE.substr(5, 2) + "-" + event.EMISSION_DATE.substr(0, 4)
    setEmissionDate(sEmissionDate)

    const sReleaseDate = event.RELEASE_DATE.substr(8, 2) + "-" + event.RELEASE_DATE.substr(5, 2) + "-" + event.RELEASE_DATE.substr(0, 4)
    setReleaseDate(sReleaseDate)

    const sDueDate = event.DUE_DATE.substr(8, 2) + "-" + event.DUE_DATE.substr(5, 2) + "-" + event.DUE_DATE.substr(0, 4)
    setDueDate(sDueDate)

    if (event.CANCELLATION_DATE !== "0001-01-01T00:00:00"){
      const sCancellationDate = event.CANCELLATION_DATE.substr(8, 2) + "-" +  event.CANCELLATION_DATE.substr(5, 2) + "-" + 
        event.CANCELLATION_DATE.substr(0, 4)

      setCancellationDate(sCancellationDate);
    } else setCancellationDate("")

    setReceivingDate("")
    setReceivingDatePrevious("")
    setValue(formatNumeric(event.VALUE, 2))
    setValueRetained(formatNumeric(event.VALUE_RETAINED, 2))

    setValueReceived(formatNumeric(event.VALUE_RECEIVED, 2))
    setValueReceivedPrevious("");

    setValueReceive(formatNumeric((event.VALUE - event.VALUE_RECEIVED), 2))

    setOrigin(event.ORIGIN)

    if (event.OBSERVATION != null) setObservation(event.OBSERVATION); else setObservation("")
    
    setGenerateNfse(event.GENERATE_NFSE)

    setSituation(event.SITUATION)
    setSituationPrevious("")

    setSituationDescription(event.DESCRIPTION_SITUATION)
    setSituationPreviousDescription("")

    if (((event.SGE !== undefined) && (event.SGE !== null)) && (event.SGE !== 0)) setSge(event.SGE)

    setReceiptNumber(event.RECEIVE_NUMBER)

    if (event.NOTE_SERVICE !== null) setJsonNoteService(event.NOTE_SERVICE)

    if (event.BILLET_SITUATION !== null) {
      if (event.BILLET_SITUATION === "3") setShowFunctions(false)
      else setShowFunctions(true)
    } else setShowFunctions(true)

    setJBillReceive(event)
  }

  const selectServiceCnae = (item) => {
    if (item === "0")
    {
      const newItem = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}]}"
      setOptionsServiceCnae(newItem)
      setOptionsServiceCodeCnae(newItem)
      setOptionsServiceItemCnae(newItem)

      setServiceCnaeCode("")
      setServiceCnaeDescription("")
      setServiceCodeCnaeCode("")
      setServiceCodeCnaeDescription("")
      setServiceItemCnaeCode("")
      setServiceItemCnaeDescription("")
      setServiceAliquot(0)
    }
    else
    {
      setServiceCnaeCode(item)
        
      jsonServiceCnae &&
        jsonServiceCnae.map(it => (
          (it.CODE === item) ? setServiceCnaeDescription(it.DESCRIPTION) : ""
        ))
            
      let optServiceCodeCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
      jsonServiceCodeCnae &&
        jsonServiceCodeCnae.map(it => (
          (String(it.SERVICE_CNAE.CODE) === String(item)) ?
            optServiceCodeCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}" : optServiceCodeCnae += ""
        ))
        optServiceCodeCnae += "]}"

      setOptionsServiceCodeCnae(optServiceCodeCnae)
    }
  }

  const selectServiceCodeCnae = (item) =>
  {
    if (item === "0")
    {
      const newItem = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}]}"
      setOptionsServiceItemCnae(newItem)

      setServiceCodeCnaeCode("")
      setServiceCodeCnaeDescription("")
      setServiceItemCnaeCode("")
      setServiceItemCnaeDescription("")
      setServiceAliquot(0)
    }
    else
    {
      setServiceCodeCnaeCode(item)
      
      jsonServiceCodeCnae &&
        jsonServiceCodeCnae.map(it => (
          (String(it.CODE) === String(item)) ? setServiceCodeCnaeDescription(it.DESCRIPTION) : ""
        ))
        
      let optServiceItemCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
      jsonServiceItemCnae &&
        jsonServiceItemCnae.map(it => (
          (String(it.SERVICE_CODE_CNAE.CODE) === String(item)) ? 
            optServiceItemCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}" : optServiceItemCnae += ""
        ))

      optServiceItemCnae += "]}"
      setOptionsServiceItemCnae(optServiceItemCnae)      
    }
  }

  const selectServiceItemCnae = (item) => {
    if (item === "0")
    {
      setServiceItemCnaeCode("")
      setServiceItemCnaeDescription("")
      setServiceAliquot(0)
    }
    else
    {
      setServiceItemCnaeCode(item)
        
      jsonServiceItemCnae &&
        jsonServiceItemCnae.map(it => (
          (String(it.CODE) === String(item)) ? setServiceItemCnaeDescription(it.DESCRIPTION) : ""
        ))

      jsonServiceItemCnae &&
        jsonServiceItemCnae.map(it => (
          (String(it.CODE) === String(item)) ? setServiceAliquot(it.ALIQUOT) : ""
        ))
    }
  }  

  const updateRecord = () => {
    let returnJson = []
    const jBillReceive  = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID  +" } }"

    setLoading("")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillReceive)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILL_RECEIVE/SearchUpdate", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível atualizar a Conta a Receber. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }
      else
      {
        selected(returnJson.OBJECT)
        setLoading("")
      }
    })
  }

  const validateDate = (value) => {
    let error = false;
    const date = value;
    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Conta a Receber"
            titleSearch="Pesquisa das Contas a Receber"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchBillReceive
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={add}
                setSelected={selected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>                    
                      { !showAccount && !showAccountPlan && !showCancellation && !showCancellationReversal && !showClient && !showDocumentType &&
                        !showPaymentForm && !showReceipt && !showReversal && code &&

                        <> 
                          { situation === 1 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              { showFunctions &&
                                <>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={save}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callReceipt()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_receive} alt="Receber"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Receber</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callCancellation()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_cancel  } alt="Cancelar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                                    </div>
                                  </div>
                                </>
                              }

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,                                  
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 2 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 3 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              { showFunctions &&
                                <>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => callCancellationReversal()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_cancel} alt="Estornar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Estornar</label>
                                    </div>
                                  </div>
                                </>
                              }

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 4 &&                          
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => callReceipt()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_receive} alt="Receber"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Receber</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => callCancellation()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                  paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_cancel  } alt="Cancelar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }
                          
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={showMenu ?
                                (width < 1150) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                :
                                  (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :                                  
                                    (width < 660) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billReceiveCode"
                                    description="Código"
                                    value={code}
                                    maxLength={11}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :                                  
                                    (width < 660) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billReceiveNumber"
                                    description="Documento"
                                    value={number}
                                    maxLength={50}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1150) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                :
                                  (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1150) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                  <Input
                                    type="text"
                                    name="billReceivePortion"
                                    description="Parcela"
                                    value={portion}
                                    maxLength={6}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1150) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                  :
                                    (width < 380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                  <Input
                                    type="text"
                                    name="billReceiveTotalPortion"
                                    description="Tot. Parcela"
                                    value={totalPortion}
                                    maxLength={6}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :  
                                (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billReceiveValue"
                                    description="Valor"
                                    value={value}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billReceiveValueReceive"
                                    description="Valor à Receber"
                                    value={((value - valueReceived) < 0) ? 
                                              0 : (situationDescription === "Recebido") ? 0 : formatNumeric(value - valueReceived, 2) || ''}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="billReceiveValueReceived"
                                    description="Valor Recebido"
                                    value={valueReceived}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}
                                  :
                                    (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 240}}>
                                  <Input
                                    type="text"
                                    name="billReceiveSituationDescription"
                                    description="Situação"
                                    value={situationDescription} 
                                    maxLength={10}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 800) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                  <InputMasked
                                    type="text"
                                    name="billReceiveEmissionDate"
                                    description="Data Emissão"
                                    mask="99/99/9999"
                                    value={emissionDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billReceiveReleaseDate"
                                    description="Data Lançamento"
                                    mask="99/99/9999"
                                    value={releaseDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 800) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                  
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 800) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  { situation === 1 &&
                                    <InputMasked
                                      type="text"
                                      name="billReceiveDueDate"
                                      description="Data Vencimento"
                                      mask="99/99/9999"
                                      value={dueDate}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setDueDate(event.target.value)} />
                                  }

                                  { situation !== 1 &&
                                    <InputMasked
                                      type="text"
                                      name="billReceiveDueDate"
                                      description="Data Vencimento"
                                      mask="99/99/9999"
                                      value={dueDate}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  }

                                </div>
                                
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="billReceiveCancellationDate"
                                    description="Data Cancelamento"
                                    mask="99/99/9999"
                                    value={cancellationDate}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>

                                { situation === 1 &&
                                  <Input
                                    type="text"
                                    name="billReceiveObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={handleObservation} />
                                }

                                { situation !== 1 &&
                                  <Input
                                    type="text"
                                    name="billReceiveObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                }

                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                <Titulo description="Cliente" />
                                <div style={showMenu ?
                                    (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}
                                    : 
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}}>
                                    <Input
                                      type="text"
                                      name="billReceiveClientCpfCnpj"
                                      description="CPF/CNPJ"
                                      value={clientCpfCnpj}
                                      maxLength={14}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="billReceiveClientCorporateName"
                                      description="Nome"
                                      value={clientCorporateName}
                                      maxLength={60}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :  
                                  (width < 1570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Forma de Pagamento" />
                                <div style={showMenu ?
                                    (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}}>
                                    <Input
                                      type="text"
                                      name="billReceivePaymentFormCode"
                                      description="Código"
                                      value={paymentFormCode}
                                      maxLength={14}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                    :
                                      (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                    <Input
                                      type="text"
                                      name="billReceivePaymentFormDescription"
                                      description="Descrição"
                                      value={paymentFormDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Plano de Conta" />
                                
                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      {display: "flex", flexGrow: 1, minWidth: 180}
                                    : 
                                      {display: "flex", flexGrow: 1, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="billReceiveAccountPlanDescription"
                                      description="Descrição"
                                      value={accountPlanDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { (String(situation) === "1") && showFunctions &&
                                    <div style={(showMenu) ? 
                                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccountPlan}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccountPlan}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>
                              
                              <div style={showMenu ?
                                  (width < 1640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :  
                                  (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Tipo Documento" />

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      {display: "flex", flexGrow: 1, minWidth: 180}
                                    : 
                                      {display: "flex", flexGrow: 1, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="billReceiveDocumentTypeDescription"
                                      description="Descrição"
                                      value={documentTypeDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { (String(situation) === "1") && showFunctions &&
                                    <div style={(showMenu) ? 
                                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }
        
                                </div>
                              </div>
                            </div>

                            { ((situation === 2) || (situation === 4)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Recebimentos" />

                                <SearchBillReceiveReceipt
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonBillReceive={jBillReceive}
                                  jsonLogin={jsonLogin}
                                  system={system}
                                  callReversal={callReversal} 
                                  showFunctions={showFunctions} 
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                            { ((situation === 3) && (valueReceived > 0)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Recebimentos" />
                              
                                <SearchBillReceiveReceipt
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonBillReceive={jBillReceive}
                                  jsonLogin={jsonLogin}
                                  system={system}
                                  callReversal={callReversal}
                                  showFunctions={showFunctions}
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                            { (JSON.stringify(jsonNoteService) !== "{}") &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Nota Fiscal de Serviço" />

                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, 
                                      paddingRight: 5}
                                    : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, 
                                      paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, 
                                      paddingRight: 5}
                                    : (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, 
                                    paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    :
                                      (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="billReceiveNoteServiceNumber"
                                      description="Número"
                                      value={jsonNoteService.NUMBER}
                                      maxLength={15}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 225, maxWidth: 225}
                                    :
                                      (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 225, maxWidth: 225}}>
                                    <Input
                                      type="text"
                                      name="billReceiveNoteServiceSeries"
                                      description="Série"
                                      value={jsonNoteService.SERIES}
                                      maxLength={5}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, 
                                      paddingRight: 5}
                                    : 
                                    {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}                                
                                  :
                                    (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, 
                                      paddingRight: 5}
                                    : 
                                    {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="BillReceiveNoteServiceValue"
                                        description="Valor"
                                        value={formatNumeric(jsonNoteService.VALUE,2)}
                                        maxLength={15}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="BillReceiveNoteServiceSituation"
                                        description="Situação"
                                        value={jsonNoteService.DESCRIPTION_SITUATION}
                                        maxLength={15}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", marginTop: 5}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", justifyContent: "center", marginLeft: 5, width: 40}
                                    :
                                      (width < 510) ? {display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", marginTop: 5}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", justifyContent: "center", marginLeft: 5, width: 40}}>
                                    <img src={image_select} style={{ width: 42, height: 42 }} alt="Selecionar" 
                                      onClick={() => selectNoteService(jsonNoteService, jBillReceive)} />
                                  </div>
                                </div>
                              </div>
                            }

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                              <Titulo description="Boletos" />

                              <SearchBillet
                                databaseType={databaseType}
                                databasePath={databasePath}
                                jsonCompany={jsonCompany}
                                jsonLogin={jsonLogin}
                                jsonBillReceive={jBillReceive}                                
                                system={system}
                                updateRecord={updateRecord}
                                setShowFunctions={setShowFunctions}
                                showMenu={showMenu}
                                width={width} />

                            </div>
                          </div>
                        </>
                      }

                      { !showAccountPlan && !showClient && !showDocumentType && !showPaymentForm && !code &&
                        <>

                          { !paymentFormCode &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,                                  
                                    paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                              </div>
                            </>
                          }

                          <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={showMenu ?
                                (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="billReceiveNumber"
                                  description="Documento"
                                  value={number}
                                  maxLength={50}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={fieldSelected}
                                  onChange={(event) => setNumber(event.target.value)} />
                              </div>

                              <div style={showMenu ? 
                                  (width < 990) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 230, maxWidth: 230}
                                : 
                                  (width < 730) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 230, maxWidth: 230}}>

                                <Input
                                  type="text"
                                  name="billReceiveSituationDescription"
                                  description="Situação"
                                  value={situationDescription}
                                  maxLength={21}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2} 
                                  onSelect={fieldSelected} />
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Cliente" />
                                
                                <div style={showMenu ?
                                    (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?                                    
                                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="billReceiveClientCpfCnpj"
                                        description="CPF/CNPJ"
                                        value={clientCpfCnpj}
                                        maxLength={14}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : 
                                        (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="billReceiveClientCorporateName"
                                        description="Nome"
                                        value={clientCorporateName}
                                        maxLength={60}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={fieldSelected} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableClient}>
                                      <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanClient}>
                                      <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={showMenu ?
                                  (width < 1950) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :
                                  (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Plano de Conta" />
                                
                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="billReceiveAccountPlanDescription"
                                      description="Descrição"
                                      value={accountPlanDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccountPlan}>
                                      <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccountPlan}>
                                      <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 50) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                <Titulo description="Tipo Documento" />

                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="billReceiveDocumentTypeDescription"
                                      description="Descrição"
                                      value={documentTypeDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  { situation === 1 &&
                                    <div style={(showMenu) ? 
                                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanDocumentType}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :
                                  (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Forma de Pagamento" />

                                <div style={showMenu ?
                                    (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>                                    
                                    <Input
                                      type="text"
                                      name="billReceivePaymentFormDescription"
                                      description="Descrição"
                                      value={paymentFormDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected} />
                                  </div>

                                  { situation === 1 &&
                                    <div style={(showMenu) ? 
                                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enablePaymentForm}>
                                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanPaymentForm}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  }
 
                                </div>
                              </div>
                            </div>
                            
                            {/* Múltipla ou Geral */}
                            { ((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <Titulo description="Nota de Serviço" />

                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                  <div style={showMenu? 
                                      (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 1310) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : 
                                        (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                      <Select
                                        name="billReceiveShowService"
                                        description="Gerar Nfse:"
                                        value={showService}
                                        require={true}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": \"1\", \"VALUE\": \"Sim\" }, {\"ID\": \"2\", \"VALUE\": \"Não\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={fieldSelected}
                                        set={setShowService} />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                      { showService === "1" &&
                                        <Select
                                          name="billReceiveServiceNature"
                                          description="Natureza"
                                          value={serviceNature || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Tributação no município\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Tributação fora do município\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Isenção\" }, {\"ID\": 4, \"VALUE\": \"Imune\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Exigibilidade suspensa por decisão judicial\" }"+
                                            ", {\"ID\": 6, \"VALUE\": \"Exigibilidade suspensa por procedimento administrativo\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={fieldSelected}
                                          set={setServiceNature} />
                                      }
                                    </div>
                                  </div>

                                  { showService === "1" &&
                                    <>
                                      <div style={showMenu? 
                                          (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        :
                                          (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : 
                                            (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                          <Select
                                            name="billReceiveHighlightRetain"
                                            description="Destacar/Reter"
                                            value={highlightRetain || "0"}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": \"1\", \"VALUE\": \"Destacar\" }, {\"ID\": \"2\", \"VALUE\": \"Reter\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={fieldSelected}
                                            set={setHighlightRetain} />
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          :
                                            (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                          <Select
                                            name="billReceiveServiceCnaeCode"
                                            description="CNAE Serviço"
                                            value={serviceCnaeCode || 0}
                                            require={true}
                                            options={JSON.parse(optionsServiceCnae)}
                                            image={false}
                                            typeInput={2}
                                            onSelect={fieldSelected}
                                            set={selectServiceCnae} />
                                        </div>
                                      </div>

                                      <div style={showMenu? 
                                          (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        :
                                          (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : 
                                            (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                          <Select
                                            name="billReceiveServiceCodeCnaeCode"
                                            description="Serviço"
                                            value={serviceCodeCnaeCode || 0}
                                            require={true}
                                            options={JSON.parse(optionsServiceCodeCnae)}
                                            image={false}
                                            typeInput={2}
                                            onSelect={fieldSelected}
                                            set={selectServiceCodeCnae} />
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          :
                                            (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                          <Select
                                            name="billReceiveServiceItemCnaeCode"
                                            description="Item Serviço"
                                            value={serviceItemCnaeCode || 0}
                                            require={true}
                                            options={JSON.parse(optionsServiceItemCnae)}
                                            image={false}
                                            typeInput={2}
                                            onSelect={fieldSelected}
                                            set={selectServiceItemCnae} />
                                        </div>
                                      </div>

                                      <div style={showMenu? 
                                          (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                        :
                                          (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                        <div style={showMenu? 
                                            (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                          :
                                            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                          <div style={showMenu ?
                                              (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            :
                                              (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                            <InputNumeric
                                              type="text"
                                              name="billReceiveServiceAliquot"
                                              description="Alíquota do serviço"
                                              value={ formatNumeric(serviceAliquot, 2) || "0" }
                                              maxLength={13}
                                              disabled={true}
                                              require={false}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={fieldSelected}
                                              onChange={handleServiceAliquot} />
                                          </div>
                                          
                                          <div style={showMenu ?
                                              (width < 730) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                            :
                                              (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                            <InputNumeric
                                              type="text"
                                              name="billReceivePercentageCSLL"
                                              description="Porcentagem CSLL"
                                              value={ formatNumeric(percentageCSLL, 2) || "0" }
                                              maxLength={13}
                                              disabled={false}
                                              require={false}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={fieldSelected}
                                              onChange={handlePercentageCSLL} />
                                          </div>
                                        </div>

                                        <div style={showMenu?
                                            (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                            : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          :
                                            (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                            : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                          <div style={showMenu ?
                                              (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            :
                                              (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                            <InputNumeric
                                              type="text"
                                              name="billReceivePercentageINSS"
                                              description="Porcentagem INSS"
                                              value={ formatNumeric(percentageINSS, 2) || "0" }
                                              maxLength={13}
                                              disabled={false}
                                              require={false}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={fieldSelected}
                                              onChange={handlePercentageINSS} />
                                          </div>
                                          
                                          <div style={showMenu ?
                                              (width < 730) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                            :
                                              (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                            <InputNumeric
                                              type="text"
                                              name="billReceivePercentageIR"
                                              description="Porcentagem IR"
                                              value={ formatNumeric(percentageIR, 2) || "0" }
                                              maxLength={13}
                                              disabled={false}
                                              require={false}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={fieldSelected}
                                              onChange={handlePercentageIR} />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }

                                </div>
                              </div>
                            }
                            
                            { paymentFormCode && 
                              <div style={{marginTop: 10}}>
                                <Titulo description="Parcelas" />

                                <BillReceivePortions
                                  jsonCompany={jsonCompany}
                                  paymentFormTerm={paymentFormTerm}
                                  savePortions={savePortions}
                                  returnAdd={returnAdd}
                                  showMenu={showMenu}
                                  width={width} />
                              </div>
                            }

                          </div>
                        </>
                      }

                      { showCancellation &&
                        <div name="ContaReceber_Cancelamento" style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Cancelamento ( Documento: "+ number + " Parcela: "+ portion + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente cancelar?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={cancel}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_cancel} alt="Cancelar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnCancellation}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showAccount &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione a Conta:</label>
                          </div>

                          <SearchAccount
                            modoSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}                            
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccount} 
                            setReturn={() => setShowAccount(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showReversal &&
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Estorno ( Documento: "+ number + " Parcela: "+ portion + " Valor: "+ 
                            JSON.parse(jBillReceiveReversal).VALUE + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente estornar?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={reversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_reversal} alt="Estornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Estornar</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnReversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                          
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showCancellationReversal &&
                        <div name="ContaReceber_EstornoCancelamento" style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"( Documento: "+ number + " Parcela: "+ portion + " Valor: "+ value + " )"} />
                          <b style={{marginLeft: 10}}>Deseja realmente reabrir?</b>
                          <br/>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={reversalCancellation}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_reversal} alt="Reabrir"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Reabrir</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnCancellationReversal}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                          
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>
                        </div>
                      }

                      { showPaymentForm &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione a Forma de Pagamento:</label>
                          </div>

                          <SearchPaymentForm
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillPaymentForm} 
                            setReturn={() => setShowPaymentForm(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showClient &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Cliente:</label>
                          </div>

                          <SearchClient
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillClient} 
                            setReturn={() => setShowClient(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showReceipt && !showAccount &&
                    
                        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                          <Titulo description={"Recebimento ( Documento: "+ number + " Parcela: "+ portion + " )"} />

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={receive}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_receive} alt="Receber"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Receber</label>
                              </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                              marginLeft: 5, width: 147}} onClick={returnReceipt}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                              </div>
                            </div>                            
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                            paddingLeft: 10}}>
                            { warning && 
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                              </div> 
                            }

                            { error &&
                              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                              </div>
                            }
                          </div>

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            { showMenu && (width > 890) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="BillReceiveReceivingDate"
                                    description="Data do recebimento"
                                    value={receivingDate || setReceivingDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setReceivingDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceive"
                                    description="Valor em aberto"
                                    value={valueReceive || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceived"
                                    description="Valor a receber"
                                    value={valueReceived || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { showMenu && (width <= 890) && (width > 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputMasked
                                      type="text"
                                      name="BillReceiveReceivingDate"
                                      description="Data do recebimento"
                                      value={receivingDate || setReceivingDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                        String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setReceivingDate(event.target.value)} />
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="BillReceiveValueReceive"
                                      description="Valor em aberto"
                                      value={valueReceive || ""}
                                      maxLength={13}
                                      disabled={true}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceived"
                                    description="Valor a receber"
                                    value={valueReceived || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }       

                            { !showMenu && (width > 620) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="BillReceiveReceivingDate"
                                    description="Data do recebimento"
                                    value={receivingDate || setReceivingDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setReceivingDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceive"
                                    description="Valor em aberto"
                                    value={valueReceive || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceived"
                                    description="Valor a receber"
                                    value={valueReceived || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { !showMenu && (width <= 620) && (width > 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1}}>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputMasked
                                      type="text"
                                      name="BillReceiveReceivingDate"
                                      description="Data do recebimento"
                                      value={receivingDate || setReceivingDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                        String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={fieldSelected}
                                      onChange={(event) => setReceivingDate(event.target.value)} />
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="BillReceiveValueReceive"
                                      description="Valor em aberto"
                                      value={valueReceive || ""}
                                      maxLength={13}
                                      disabled={true}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceived"
                                    description="Valor a receber"
                                    value={valueReceived || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            { (width <= 450) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <InputMasked
                                    type="text"
                                    name="BillReceiveReceivingDate"
                                    description="Data do recebimento"
                                    value={receivingDate || setReceivingDate(String(new Date().getDate()).padStart(2, '0') + '/' + 
                                      String(new Date().getMonth() + 1).padStart(2, '0') + '/' + new Date().getFullYear()) }
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected}
                                    onChange={(event) => setReceivingDate(event.target.value)} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceive"
                                    description="Valor em aberto"
                                    value={valueReceive || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="BillReceiveValueReceived"
                                    description="Valor a receber"
                                    value={valueReceived || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected}
                                    onChange={handleValueReceived} />
                                </div>
                              </div>
                            }

                            <div style={{marginTop: 10}}>       
                              <Titulo description="Conta" />
                            </div>                            

                            <div style={showMenu ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                              :
                                (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                              <div style={showMenu ?                                  
                                  (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={showMenu ?
                                    (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                  :
                                    (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                  <Input
                                    type="text"
                                    name="BillReceiveAccountNumber"
                                    description="Número"
                                    value={accountNumber}
                                    maxLength={10}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                <div style={showMenu ?
                                    (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                                  :
                                    (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="BillReceiveAccountOwner"
                                    description="Titular"
                                    value={accountOwner}
                                    maxLength={50}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccount}>
                                  <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccount}>
                                  <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      { showAccountPlan &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Plano de Conta:</label>
                          </div>

                          <SearchAccountPlan
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccountPlan}
                            setReturn={() => setShowAccountPlan(false)}
                            type={98} 
                            periodic={1} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showDocumentType &&
                        <div style={{marginTop : 10}}>
                          <div style={{marginLeft: 10}}>
                            <label style={{fontWeight: 600}} >Selecione o Tipo do Documento:</label>
                          </div>
         
                          <SearchDocumentType
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}                            
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillDocumentType} 
                            setReturn={() => setShowDocumentType(false)}
                            width={width} />
                        </div>
                      }

                    </>
                }
              </>
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BillReceive