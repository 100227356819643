import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import SearchClientType from "./Search"
import image_loading from "../../Images/spinner.gif"

const ClientType = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [jsonClientType, setJsonClientType] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") setDescription("")
    else setDescription(jsonClientType.DESCRIPTION)

    document.getElementById("clientTypeDescription") && document.getElementById("clientTypeDescription").focus()
  }, [code, jsonClientType, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") setDescription("")
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }  

  const save = () => {
    let returnJson = []
    let jsonClientType_New = {}

    setWarning("")
    setError("")

    jsonClientType_New = "{ "
    if (code !== "") jsonClientType_New += "\"ID\": "+ code
    if (jsonClientType_New !== "{ ") jsonClientType_New += ", "
    if (description !== "") jsonClientType_New += "\"DESCRIPTION\": \""+ description +"\""
    jsonClientType_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonClientType_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "CLIENT_TYPE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o tipo do cliente. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("clientTypeDescription") && document.getElementById("clientTypeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJsonClientType(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonClientType))
      formData.append("JSON_NEW_1", jsonClientType_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "CLIENT_TYPE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o tipo do cliente. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("clientTypeDescription") && document.getElementById("clientTypeDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonClientType(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonClientType(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Tipo do Cliente"
            titleSearch="Pesquisa dos Tipos dos Clientes"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchClientType
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                            <div style={{display: "flex", flexGrow: 1}}>
                              <Input
                                type="text"
                                name="clientTypeDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description}
                                maxLength={50}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                              <Input
                                type="text"
                                name="clientTypeCode"
                                description="Código"
                                planceHolder=""
                                value={code}
                                maxLength={11}
                                disabled={true}
                                require={false}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setCode(event.target.value)} />
                            </div>
                            <div style={(width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="clientTypeDescription"
                                description="Descrição"
                                planceHolder=""
                                value={description}
                                maxLength={50}
                                disabled={false}
                                require={true}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ClientType