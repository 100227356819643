import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const DigitalCertificate = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [file, setFile] = useState("")
  const [certificateDate, setCertificateDate] = useState("")  

  const [firstAcess, setFirstAcess] = useState(true)
  const [showMenu, setShowMenu] = useState(true)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")  

  useEffect(() => {    
    if (firstAcess){
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_COMPANY", jsonCompany)
      formData.append("JSON_LOGIN", jsonLogin)
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "DIGITAL_CERTIFICATE/Check", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os certificados. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {   
          setCertificateDate(returnJson.OBJECT)
          setResult(returnJson)

          setLoading("")
          setFirstAcess(false)
        }
      })
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, certificateDate])

  const handleFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file)
  }  

  const send = async(e) => {
    let returnJson = []

    if (file === ""){
      alert("É necessário selecionar o arquivo.")
      return
    }

    setLoading("Aguarde efetuando o upload do certificado digital...")
    setWarning("")
    setError("")

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", jsonCompany)
    formData.append("JSON_LOGIN", jsonLogin)
    formData.append("SYSTEM", system)
    formData.append("FILE", file);
       
    axios.post(BASE_URL + "DIGITAL_CERTIFICATE/Upload", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível enviar o certificado digital. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setWarning(returnJson.WARNING)
        setError(returnJson.ERROR)
        setLoading("")
        setResult(null)
        return
      }

      setError("")
      setWarning("Upload do certificado efetuado com sucesso.")
      setCertificateDate(returnJson.OBJECT)
      setResult(returnJson)

      setLoading("")
    })
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Certificado Digital"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

            { loading ?
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              :
                <>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                      width: 147}} onClick={send}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_save} alt="Enviar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Enviar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                      width: 147}} onClick={setReturn}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 20, paddingRight: 20}}>
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                      paddingLeft: 10}}>
                      { warning && 
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                        </div> 
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                        </div>
                      }
                    </div>

                    { result ?
                        <div style={{paddingleft: 10, paddingright: 10, marginTop: 10}}>
                          <label>Certificado digital selecionado</label>
                          <br/>
                          <label>Data de validade do certificado: { certificateDate && certificateDate.substring(0,10) }</label>
                        </div>
                      :
                        <div style={{paddingleft: 10, paddingright: 10, marginTop: 10}}>
                          <label>
                            Certificado não foi selecionado
                          </label>
                        </div> 
                    }

                    <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                        <Input 
                          type="file"
                          name="digitalCertificateFile"
                          maxLength={255}
                          typeImage={2}
                          disabled={false}
                          placeholder=""
                          grow={1}
                          accept=".pfx,.p12"
                          onSelect={setFieldSelected}
                          onChange={handleFile} />
                      </div>
                    </div>
                  </div>
                </>
            }
        </div>
      </div>
      
      <Footer/>
    </div>
  )
}

export default DigitalCertificate