import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Select from "../../Components/Select"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import Titulo from "../../Components/Titulo"
import SearchAliquot from "../Aliquot/Search"
import SearchCfop from "../Cfop/Search"
import SearchCstCsosn from "../CstCsosn/Search"
import img_adicionar from "../../Images/add.svg"
import img_anterior from "../../Images/left.svg"
import img_buscar from "../../Images/search.svg"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"
import img_limpar from "../../Images/clean.svg"
import img_menu from "../../Images/Menu/menu.png"
import img_multiplaBranca from "../../Images/logo_multipla_branca.png"
import img_salvar from "../../Images/save.svg"

import SearchNcm from "../Ncm/Search"

const TaxDefault = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")

  const [ncmCode, setNcmCode] = useState("")
  const [ncmDescription, setNcmDescription] = useState("")

  const [cfopCode, setCfopCode] = useState("")
  const [cfopDescription, setCfopDescription] = useState("")

  const [cstCsosnConsumerCode, setCstCsosnConsumerCode] = useState("")
  const [cstCsosnConsumerDescription, setCstCsosnConsumerDescription] = useState("")
  const [aliquotIcmsConsumerCode, setAliquotIcmsConsumerCode] = useState("")
  const [aliquotIcmsConsumerAcronym, setAliquotIcmsConsumerAcronym] = useState("")
  const [reductionIcmsConsumer, setReductionIcmsConsumer] = useState(0)
  const [aliquotStConsumerCode, setAliquotStConsumerCode] = useState("")
  const [aliquotStConsumerAcronym, setAliquotStConsumerAcronym] = useState("")
  const [reductionStConsumer, setReductionStConsumer] = useState(0)
  const [mvaConsumer, setMvaConsumer] = useState(0)

  const [cstCsosnResaleCode, setCstCsosnResaleCode] = useState("")
  const [cstCsosnResaleDescription, setCstCsosnResaleDescription] = useState("")
  const [aliquotIcmsResaleCode, setAliquotIcmsResaleCode] = useState("")
  const [aliquotIcmsResaleAcronym, setAliquotIcmsResaleAcronym] = useState("")
  const [reductionIcmsResale, setReductionIcmsResale] = useState(0)
  const [aliquotStResaleCode, setAliquotStResaleCode] = useState("")
  const [aliquotStResaleAcronym, setAliquotStResaleAcronym] = useState("")
  const [reductionStResale, setReductionStResale] = useState(0)
  const [mvaResale, setMvaResale] = useState(0)

  const [jTaxDefault, setJTaxDefault] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showNcm, setShowNcm] = useState(false)
  const [showCfop, setShowCfop] = useState(false)
  const [showCstCsosnConsumer, setShowCstCsosnConsumer] = useState(false)
  const [showAliquotIcmsConsumer, setShowAliquotIcmsConsumer] = useState(false)
  const [showAliquotStConsumer, setShowAliquotStConsumer] = useState(false)
  const [showCstCsosnResale, setShowCstCsosnResale] = useState(false)
  const [showAliquotIcmsResale, setShowAliquotIcmsResale] = useState(false)
  const [showAliquotStResale, setShowAliquotStResale] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(true)

  useEffect(() => {
    let returnJson = []

    if (firstAccess) {      
      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações...")
  
      const jTaxDefault = "{ \"ID\": 1, \"COMPANY\": { \"ID\": "+ JSON.parse(jsonCompany).ID + " } }"
  
      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jTaxDefault)
      formData.append("JSON_LOGIN", jsonLogin)
      formData.append("SYSTEM", system)
                
      axios.post(BASE_URL + "TAX_DEFAULT/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null"+
        ", \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar o imposto padrão. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
  
        { returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (setJTaxDefault(item)))
          returnJson.OBJECT.map(item => (setCode(item.ID)))
        }
      })

      setFirstAccess(false)
      setLoading("")
    }

    if (code === "") {
      setNcmCode("")
      setNcmDescription("")

      setCfopCode("")
      setCfopDescription("")
      
      setCstCsosnConsumerCode("")
      setCstCsosnConsumerDescription("")
      setAliquotIcmsConsumerCode("")
      setAliquotIcmsConsumerAcronym("")
      setReductionIcmsConsumer("")
      setAliquotStConsumerCode("")
      setAliquotStConsumerAcronym("")
      setReductionStConsumer("")
      setMvaConsumer("")
      
      setCstCsosnResaleCode("")
      setCstCsosnResaleDescription("")
      setAliquotIcmsResaleCode("")
      setAliquotIcmsResaleAcronym("")
      setReductionIcmsResale("")
      setAliquotStResaleCode("")
      setAliquotStResaleAcronym("")
      setReductionStResale("")
      setMvaResale("")
    }
    else {
      if (jTaxDefault.NCM != null){
        setNcmCode(jTaxDefault.NCM.CODE)
        setNcmDescription(jTaxDefault.NCM.DESCRIPTION)
      }

      if (jTaxDefault.CFOP != null){
        setCfopCode(jTaxDefault.CFOP.ID)
        setCfopDescription(jTaxDefault.CFOP.DESCRIPTION)
      }

      if (jTaxDefault.CST_CSOSN_CONSUMER != null){
        setCstCsosnConsumerCode(jTaxDefault.CST_CSOSN_CONSUMER.CODE)
        setCstCsosnConsumerDescription(jTaxDefault.CST_CSOSN_CONSUMER.DESCRIPTION)
      }
      else
      {
        setCstCsosnConsumerCode("");
        setCstCsosnConsumerDescription("");
      }
  
      if (jTaxDefault.ALIQUOT_ICMS_CONSUMER != null){
        setAliquotIcmsConsumerCode(jTaxDefault.ALIQUOT_ICMS_CONSUMER.ID)
        setAliquotIcmsConsumerAcronym(jTaxDefault.ALIQUOT_ICMS_CONSUMER.ACRONYM)
      }
      else{
        setAliquotIcmsConsumerCode("")
        setAliquotIcmsConsumerAcronym("")
      }
          
      setReductionIcmsConsumer(formatNumeric(jTaxDefault.REDUCTION_ICMS_CONSUMER, 2))
  
      if (jTaxDefault.ALIQUOT_ST_CONSUMER != null){
        setAliquotStConsumerCode(jTaxDefault.ALIQUOT_ST_CONSUMER.ID)
        setAliquotStConsumerAcronym(jTaxDefault.ALIQUOT_ST_CONSUMER.ACRONYM)
      }
      else{
        setAliquotStConsumerCode("")
        setAliquotStConsumerAcronym("")
      }
  
      setReductionStConsumer(formatNumeric(jTaxDefault.REDUCTION_ST_CONSUMER, 2))
      setMvaConsumer(formatNumeric(jTaxDefault.MVA_CONSUMER, 2))
  
      if (jTaxDefault.CST_CSOSN_RESALE != null){
        setCstCsosnResaleCode(jTaxDefault.CST_CSOSN_RESALE.CODE)
        setCstCsosnResaleDescription(jTaxDefault.CST_CSOSN_RESALE.DESCRIPTION)
      }
      else
      {
        setCstCsosnResaleCode("")
        setCstCsosnResaleDescription("")
      }
  
      if (jTaxDefault.ALIQUOT_ICMS_RESALE != null){
        setAliquotIcmsResaleCode(jTaxDefault.ALIQUOT_ICMS_RESALE.ID)
        setAliquotIcmsResaleAcronym(jTaxDefault.ALIQUOT_ICMS_RESALE.ACRONYM)
      }
      else{
        setAliquotIcmsResaleCode("")
        setAliquotIcmsResaleAcronym("")
      }
  
      setReductionIcmsResale(formatNumeric(jTaxDefault.REDUCTION_ICMS_RESALE, 2))
  
      if (jTaxDefault.ALIQUOT_ST_RESALE != null){
        setAliquotStResaleCode(jTaxDefault.ALIQUOT_ST_RESALE.ID)
        setAliquotStResaleAcronym(jTaxDefault.ALIQUOT_ST_RESALE.ACRONYM)
      }
      else{
        setAliquotStResaleCode("")
        setAliquotStResaleAcronym("")
      }
  
      setReductionStResale(formatNumeric(jTaxDefault.REDUCTION_ST_RESALE, 2))
      setMvaResale(formatNumeric(jTaxDefault.MVA_RESALE, 2))
    }

    // document.getElementById("descricao") && document.getElementById("descricao").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAccess, code, jTaxDefault, updateFocu])  

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setNcmCode("")
      setNcmDescription("")

      setCfopCode("")
      setCfopDescription("")
      
      setCstCsosnConsumerCode("")
      setCstCsosnConsumerDescription("")
      setAliquotIcmsConsumerCode("")
      setAliquotIcmsConsumerAcronym("")
      setReductionIcmsConsumer("")
      setAliquotStConsumerCode("")
      setAliquotStConsumerAcronym("")
      setReductionStConsumer("")
      setMvaConsumer("")
      
      setCstCsosnResaleCode("")
      setCstCsosnResaleDescription("")
      setAliquotIcmsResaleCode("")
      setAliquotIcmsResaleAcronym("")
      setReductionIcmsResale("")
      setAliquotStResaleCode("")
      setAliquotStResaleAcronym("")
      setReductionStResale("")
      setMvaResale("")
    }
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const  regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }  

  const cleanAliquotIcmsConsumer = () => {
    setAliquotIcmsConsumerCode("")
    setAliquotIcmsConsumerAcronym("")
    setShowAliquotIcmsConsumer(false)
  }

  const cleanAliquotIcmsResale = () => {
    setAliquotIcmsResaleCode("")
    setAliquotIcmsResaleAcronym("")
    setShowAliquotIcmsResale(false)
  }

  const cleanAliquotStConsumer = () => {
    setAliquotStConsumerCode("")
    setAliquotStConsumerAcronym("")
    setShowAliquotStConsumer(false)
  }

  const cleanAliquotStResale = () => {
    setAliquotStResaleCode("")
    setAliquotStResaleAcronym("")
    setShowAliquotStResale(false)
  }

  const cleanCfop = () => {
    setCfopCode("")
    setCfopDescription("")
    setShowCfop(false)
  }

  const cleanCstCsosnConsumer = () => {
    setCstCsosnConsumerCode("")
    setCstCsosnConsumerDescription("")
    setShowCstCsosnConsumer(false)
  }

  const cleanCstCsosnResale = () => {
    setCstCsosnResaleCode("")
    setCstCsosnResaleDescription("")
    setShowCstCsosnResale(false)
  }

  const cleanNcm = () => {
    setNcmCode("")
    setNcmDescription("")
    setShowNcm(false)
  }

  const enableAliquotIcmsConsumer = () => {
    setShowAliquotIcmsConsumer(!showAliquotIcmsConsumer)
  }

  const enableAliquotIcmsResale = () => {
    setShowAliquotIcmsResale(!showAliquotIcmsResale)
  }    

  const enableAliquotStConsumer = () => {
    setShowAliquotStConsumer(!showAliquotStConsumer)
  }    

  const enableAliquotStResale = () => {
    setShowAliquotStResale(!showAliquotStResale)
  }        

  const enableCfop = () => {
    setShowCfop(!showCfop)
  }

  const enableCstCsosnConsumer = () => {
    setShowCstCsosnConsumer(!showCstCsosnConsumer)
  }

  const enableCstCsosnResale = () => {
    setShowCstCsosnResale(!showCstCsosnConsumer)
  }

  const enableNcm = () => {
    setShowNcm(!showNcm)
  }  

  const fillAliquotIcmsConsumer = (aliquotIcmsConsumer) => {
    setAliquotIcmsConsumerCode(aliquotIcmsConsumer.ID)
    setAliquotIcmsConsumerAcronym(aliquotIcmsConsumer.ACRONYM)
    setShowAliquotIcmsConsumer(false)
  }

  const fillAliquotIcmsResale = (aliquotIcmsResale) => {
    setAliquotIcmsResaleCode(aliquotIcmsResale.ID)
    setAliquotIcmsResaleAcronym(aliquotIcmsResale.ACRONYM)
    setShowAliquotIcmsResale(false)
  }

  const fillAliquotStConsumer = (aliquotStConsumer) => {
    setAliquotStConsumerCode(aliquotStConsumer.ID)
    setAliquotStConsumerAcronym(aliquotStConsumer.ACRONYM)
    setShowAliquotStConsumer(false)
  }

  const fillAliquotStResale = (aliquotStResale) => {
    setAliquotStResaleCode(aliquotStResale.ID)
    setAliquotStResaleAcronym(aliquotStResale.ACRONYM)
    setShowAliquotStResale(false)
  }

  const fillCfop = (cfop) => {
    setCfopCode(cfop.ID)
    setCfopDescription(cfop.DESCRIPTION)
    setShowCfop(false)
  }

  const fillCstCsosnConsumer = (cstCsosnConsumer) => {
    setCstCsosnConsumerCode(cstCsosnConsumer.CODE)
    setCstCsosnConsumerDescription(cstCsosnConsumer.DESCRIPTION)
    setShowCstCsosnConsumer(false)
  }

  const fillCstCsosnResale = (cstCsosnResale) => {
    setCstCsosnResaleCode(cstCsosnResale.CODE)
    setCstCsosnResaleDescription(cstCsosnResale.DESCRIPTION)
    setShowCstCsosnResale(false)
  }

  const fillNcm = (ncm) => {
    setNcmCode(ncm.CODE)
    setNcmDescription(ncm.DESCRIPTION)
    setShowNcm(false)
  }

  const handleMvaConsumer = (event) => {
    setMvaConsumer(formatNumeric(event.target.value, 2))
  }

  const handleMvaResale = (event) => {
    setMvaResale(formatNumeric(event.target.value, 2))
  }

  const handleReductionIcmsConsumer = (event) => {
    setReductionIcmsConsumer(formatNumeric(event.target.value, 2))
  }

  const handleReductionIcmsResale = (event) => {
    setReductionIcmsResale(formatNumeric(event.target.value, 2))
  }

  const handleReductionStConsumer = (event) => {
    setReductionStConsumer(formatNumeric(event.target.value, 2))
  }

  const handleReductionStResale = (event) => {
    setReductionStResale(formatNumeric(event.target.value, 2))
  }  

  const save = () => {
    let returnJson = []
    const jCom = JSON.parse(jsonCompany)    

    setWarning("")
    setError("")      

    let jsonTaxDefault_New = "{ \"COMPANY\": { \"ID\": "+ jCom.ID + ", \"INTEGRATE_SGE\": "+ jCom.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jCom.DATABASE_SGE_PATH +"\", \"REGIME\": "+ jCom.REGIME +" } }"

    if (code !== "") jsonTaxDefault_New += ", \"ID\": " + code
    if (ncmCode !== "") jsonTaxDefault_New += ", \"NCM\": { \"CODE\": \"" + ncmCode + "\", \"ACRONYM\": \""+ ncmDescription +"\" }"
    if (cfopCode !== "") jsonTaxDefault_New += ", \"CFOP\": { \"ID\": " + cfopCode + ", \"ACRONYM\": \""+ cfopDescription +"\" }"

    if (cstCsosnConsumerCode !== "")
      jsonTaxDefault_New += ", \"CST_CSOSN_CONSUMER\": { \"CODE\": \"" + cstCsosnConsumerCode + 
        "\", \"DESCRIPTION\": \""+ cstCsosnConsumerDescription +"\" }"

    if (aliquotIcmsConsumerCode !== "")
      jsonTaxDefault_New += ", \"ALIQUOT_ICMS_CONSUMER\": { \"ID\": " + aliquotIcmsConsumerCode + 
        ", \"ACRONYM\": \""+ aliquotIcmsConsumerAcronym +"\" }"

    jsonTaxDefault_New += ", \"REDUCTION_ICMS_CONSUMER\": " + reductionIcmsConsumer

    if (aliquotStConsumerCode !== "")
      jsonTaxDefault_New += ", \"ALIQUOT_ST_CONSUMER\": { \"ID\": " + aliquotStConsumerCode + 
        ", \"ACRONYM\": \""+ aliquotStConsumerAcronym +"\" }"

    jsonTaxDefault_New += ", \"REDUCTION_ST_CONSUMER\": " + reductionStConsumer
    jsonTaxDefault_New += ", \"MVA_CONSUMER\": " + mvaConsumer

    if (cstCsosnResaleCode !== "")
      jsonTaxDefault_New += ", \"CST_CSOSN_RESALE\": { \"CODE\": \"" + cstCsosnResaleCode + 
        "\", \"DESCRIPTION\": \""+ cstCsosnResaleDescription +"\" }"

    if (aliquotIcmsResaleCode !== "")
      jsonTaxDefault_New += ", \"ALIQUOT_ICMS_RESALE\": { \"ID\": " + aliquotIcmsResaleCode +
        ", \"ACRONYM\": \""+ aliquotIcmsResaleAcronym +"\" }"

    jsonTaxDefault_New += ", \"REDUCTION_ICMS_RESALE\": " + reductionIcmsResale

    if (aliquotStResaleCode !== "")
      jsonTaxDefault_New += ", \"ALIQUOT_ST_RESALE\": { \"ID\": " + aliquotStResaleCode + 
        ", \"ACRONYM\": \""+ aliquotStResaleAcronym +"\" }"

    jsonTaxDefault_New += ", \"REDUCTION_ST_RESALE\": " + reductionStResale
    jsonTaxDefault_New += ", \"MVA_RESALE\": " + mvaResale + " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonTaxDefault_New)
      formData.append("JSON_LOGIN", jsonLogin)
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "TAX_DEFAULT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null"+
        ", \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível incluir o imposto padrão. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          //document.getElementById("establishmentDescription") && document.getElementById("establishmentDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJTaxDefault(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD",  JSON.stringify(jTaxDefault))
      formData.append("JSON_NEW_1", jsonTaxDefault_New)
      formData.append("JSON_COMPANY", jsonCompany)
      formData.append("JSON_LOGIN", jsonLogin)
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "TAX_DEFAULT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null"+
        ", \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível alterar o imposto padrão. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          //document.getElementById("establishmentDescription") && document.getElementById("establishmentDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJTaxDefault(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setSelected = (event) => {
    setJTaxDefault(event)
    setCode(event.ID)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Imposto Padrão"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { loading ?
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
              </div>
            :
              <>
                { !showAliquotIcmsConsumer && !showAliquotIcmsResale && !showAliquotStConsumer && !showAliquotStResale &&
                    !showCfop && !showCstCsosnConsumer && !showCstCsosnResale && !showNcm &&
                
                  <>
                    <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => setReturn()} />

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                    </div>
                  
                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                      <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10,
                        paddingBottom: 0, paddingLeft: 10}}>
                        { warning && 
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                          </div> 
                        }

                        { error &&
                          <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                            marginBottom: 10}}>
                            <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                          </div>
                        }

                        <div style={showMenu ?
                            (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                            <b>NCM:</b>
                            <div style={showMenu ?
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultNcmCode"
                                    description="Código"
                                    value={ncmCode}
                                    maxLength={12}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(value) => setNcmCode(value.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultNcmDescription"
                                    description="Descrição"
                                    value={ncmDescription}
                                    maxLength={200}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(value) => setNcmDescription(value.target.value)} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                  : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                :
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                <img 
                                  style={{height: 42, width: 42}}
                                  name="taxDefaultSearchNcm"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={enableNcm} />

                                <img 
                                  style={{marginLeft: 10, height: 42, width: 42}}
                                  name="taxDefaultCleanNcm"
                                  src={image_clean} 
                                  alt="Limpar" 
                                  onClick={cleanNcm} />
                              </div>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                              : (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                            :
                              (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                              : (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                            <b>CFOP:</b>
                            <div style={showMenu ?
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCfopCode"
                                    description="Código"
                                    value={cfopCode}
                                    maxLength={4}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(value) => setCfopCode(value.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCfopDescription"
                                    description="Descrição"
                                    value={cfopDescription}
                                    maxLength={200}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(value) => setCfopDescription(value.target.value)} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                  : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                :
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                <img 
                                  style={{height: 42, width: 42}}
                                  name="taxDefaultSearchCfop"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={enableCfop} />

                                <img 
                                  style={{marginLeft: 10, height: 42, width: 42}}
                                  name="taxDefaultCleanCfop"
                                  src={image_clean} 
                                  alt="Limpar" 
                                  onClick={cleanCfop} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 10}}>
                          <Titulo description="Consumidor" />
                        </div>

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                            <b>CST/CSOSN:</b>
                            <div style={showMenu ?
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :                      
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :   
                                    (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCstCsosnConsumerCode"
                                    description="Código"
                                    value={cstCsosnConsumerCode}
                                    maxLength={3}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCstCsosnConsumerCode(event.target.value) } />
                                </div>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCstCsosnConsumerDescription"
                                    description="Descrição"
                                    value={cstCsosnConsumerDescription}
                                    maxLength={80}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCstCsosnConsumerDescription(event.target.value) } />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                  : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                :
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                <img 
                                  style={{height: 42, width: 42}}
                                  name="taxDefaultSearchCstCsosnConsumer"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={enableCstCsosnConsumer} />

                                <img 
                                  style={{marginLeft: 10, height: 42, width: 42}}
                                  name="taxDefaultCleanCstCsosnConsumer"
                                  src={image_clean} 
                                  alt="Limpar"
                                  onClick={cleanCstCsosnConsumer} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                          :
                            (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                          <div style={showMenu ?
                              (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                            :
                              (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                            <div>
                              <Titulo description="ICMS" />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                              :                 
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <b>Alíquota:</b>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="taxDefaultAliquotIcmsConsumerAcronym"
                                      description="Sigla"
                                      value={aliquotIcmsConsumerAcronym}
                                      maxLength={4}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setAliquotIcmsConsumerAcronym(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="taxDefaultSearchAliquotIcmsConsumer"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableAliquotIcmsConsumer} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="taxDefaultCleanAliquotIcmsConsumer"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanAliquotIcmsConsumer} />
                                  </div>
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                                <b>Redução:</b>
                                <div style={showMenu ?
                                    (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                  : 
                                    (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="taxDefaultReductionIcmsConsumer"
                                    description="Valor"
                                    value={reductionIcmsConsumer || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleReductionIcmsConsumer} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={showMenu ?
                                (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              : 
                                (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                            <div>
                              <Titulo description="ST" />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <b>Alíquota:</b>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="taxDefaultAliquotStConsumerAcronym"
                                      description="Sigla"
                                      value={aliquotStConsumerAcronym}
                                      maxLength={4}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setAliquotStConsumerAcronym(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="taxDefaultSearchAliquotStConsumer"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableAliquotStConsumer} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="taxDefaultCleanAliquotStConsumer"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanAliquotStConsumer} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                                <div style={showMenu ?
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0}
                                  :
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0}}>
                                  <b>Redução:</b>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    :
                                      (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="taxDefaultReductionStConsumer"
                                      description="Valor"
                                      value={reductionStConsumer || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={true}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleReductionStConsumer} />
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                                  <b>MVA:</b>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    :
                                      (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="taxDefaultMvaConsumer"
                                      description="Valor"
                                      value={mvaConsumer || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={true}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleMvaConsumer} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 10}}>
                          <Titulo description="Revendedor" />
                        </div>

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                            <b>CST/CSOSN:</b>
                            <div style={showMenu ?
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :                      
                                  (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :   
                                    (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCstCsosnResaleCode"
                                    description="Código"
                                    value={cstCsosnResaleCode}
                                    maxLength={3}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCstCsosnResaleCode(event.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="taxDefaultCstCsosnResaleDescription"
                                    description="Descrição"
                                    value={cstCsosnResaleDescription}
                                    maxLength={80}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2}   
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCstCsosnResaleDescription(event.target.value)} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                  : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                :
                                  (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                <img 
                                  style={{height: 42, width: 42}}
                                  name="taxDefaultSearchCstCsosnResale"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={enableCstCsosnResale} />

                                <img 
                                  style={{marginLeft: 10, height: 42, width: 42}}
                                  name="taxDefaultCleanCstCsosnResale"
                                  src={image_clean} 
                                  alt="Limpar"
                                  onClick={cleanCstCsosnResale} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                          :
                            (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                          <div style={showMenu ?
                              (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                            :
                              (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                            <div>
                              <Titulo description="ICMS" />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                              :                 
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <b>Alíquota:</b>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="taxDefaultAliquotIcmsResaleAcronym"
                                      description="Sigla"
                                      value={aliquotIcmsResaleAcronym}
                                      maxLength={4}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setAliquotIcmsResaleAcronym(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="taxDefaultSearchAliquotIcmsResale"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableAliquotIcmsResale} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="taxDefaultCleanAliquotIcmsResale"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanAliquotIcmsResale} />
                                  </div>
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                                <b>Redução:</b>
                                <div style={showMenu ?
                                    (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                  : 
                                    (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>                            
                                    <InputNumeric
                                      type="text"
                                      name="taxDefaultReductionIcmsResale"
                                      description="Valor"
                                      value={reductionIcmsResale || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={true}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleReductionIcmsResale} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={showMenu ?
                                (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              : 
                                (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                            <div>
                              <Titulo description="ST" />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <b>Alíquota:</b>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="taxDefaultAliquotStResaleAcronym"
                                      description="Sigla"
                                      value={aliquotStResaleAcronym}
                                      maxLength={4}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setAliquotStResaleAcronym(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="taxDefaultSearchAliquotStResale"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableAliquotStResale} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="taxDefaultCleanAliquotStResale"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanAliquotStResale} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                                <div style={showMenu ?
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0}
                                  :
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0}}>
                                  <b>Redução:</b>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    :
                                      (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="taxDefaultReductionStResale"
                                      description="Valor"
                                      value={reductionStResale || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={true}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleReductionStResale} />
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                                  <b>MVA:</b>
                                  <div style={showMenu ?
                                      (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                                    :
                                      (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="taxDefaultMvaResale"
                                      description="Valor"
                                      value={mvaResale || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={true}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleMvaResale} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }

                { showAliquotIcmsConsumer &&
                  <div>
                    <b>Selecione a alíquota do ICMS do consumidor:</b>
                    <SearchAliquot
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillAliquotIcmsConsumer} 
                      setReturn={() => setShowAliquotIcmsConsumer(false)}
                      width={width} />
                  </div>
                }

                { showAliquotIcmsResale &&
                  <div>
                    <b>Selecione a alíquota do ICMS do revendedor:</b>
                      <SearchAliquot
                        modeSearch={true}
                        databaseType={databaseType}
                        databasePath={databasePath}
                        jsonLogin={jsonLogin}
                        jsonCompany={jsonCompany}                    
                        system={system}
                        setAdd={() => alert("")}
                        setSelected={fillAliquotIcmsResale} 
                        setReturn={() => setShowAliquotIcmsResale(false)}
                        width={width} />
                  </div>
                }

                { showAliquotStConsumer &&
                  <div>
                    <b>Selecione a alíquota do ST do consumidor:</b>
                    <SearchAliquot
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillAliquotStConsumer} 
                      setReturn={() => setShowAliquotStConsumer(false)} 
                      width={width} />
                  </div>
                }

                { showAliquotStResale &&
                  <div>
                    <b>Selecione a alíquota do ST do revendedor:</b>
                    <SearchAliquot
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}                  
                      setSelected={fillAliquotStResale} 
                      setReturn={() => setShowAliquotStResale(false)} 
                      width={width} />
                  </div>
                }

                { showCfop &&
                  <div>
                    <b>Selecione o CFOP:</b>
                    <SearchCfop
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillCfop} 
                      setReturn={() => setShowCfop(false)} 
                      showMenu={showMenu}
                      width={width} />
                  </div>
                }

                { showCstCsosnConsumer &&
                  <div>
                    <b>Selecione o CST/CSOSN do consumidor:</b>
                    <SearchCstCsosn
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillCstCsosnConsumer} 
                      setReturn={() => setShowCstCsosnConsumer(false)} 
                      showMenu={showMenu}
                      width={width} />
                  </div>
                }

                { showCstCsosnResale &&
                  <div>
                    <b>Selecione o CST/CSOSN do revendedor:</b>
                    <SearchCstCsosn
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillCstCsosnResale} 
                      setReturn={() => setShowCstCsosnResale(false)} 
                      showMenu={showMenu}
                      width={width} />
                  </div>
                }

                { showNcm &&
                  <div>
                    <b>Selecione o NCM:</b>
                    <SearchNcm
                      modeSearch={true}
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonLogin={jsonLogin}
                      jsonCompany={jsonCompany}                  
                      system={system}
                      setAdd={() => alert("")}
                      setSelected={fillNcm} 
                      setReturn={() => setShowNcm(false)} 
                      showMenu={showMenu}
                      width={width} />
                  </div>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default TaxDefault