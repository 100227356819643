import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import DataPicker from "../../../Components/DataPicker"
import Input from "../../../Components/Input"
import InputMasked from "../../../Components/InputMasked"
import InputNumeric from "../../../Components/InputNumeric"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import Titulo from "../../../Components/Titulo"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_message from "../../../Images/Buttons/message.png" 
import image_previous from "../../../Images/Buttons/previous.png"
import image_refresh from "../../../Images/Buttons/refresh.png"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchBillReceive = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [number, setNumber] = useState("")
  const [value, setValue] = useState("")
  const [situation, setSituation] = useState("0")  

  const [documentTypeDescription, setDocumentTypeDescription] = useState("")
  const [documentTypeDescriptionSelected, setDocumentTypeDescriptionSelected] = useState("")
  const [resultDocumentType, setResultDocumentType] = useState({})
  const [resultDocumentTypeFilter, setResultDocumentTypeFilter] = useState({})

  const [accountPlanDescription, setAccountPlanDescription] = useState("")
  const [accountPlanDescriptionSelected, setAccountPlanDescriptionSelected] = useState("")
  const [resultAccountPlan, setResultAccountPlan] = useState({})
  const [resultAccountPlanFilter, setResultAccountPlanFilter] = useState({})

  const [clientCorporateName, setClientCorporateName] = useState("")
  const [clientCorporateNameSelected, setClientCorporateNameSelected] = useState("")
  const [resultClient, setResultClient] = useState({})
  const [resultClientFilter, setResultClientFilter] = useState({})

  const [accountOwner, setAccountOwner] = useState("")
  const [accountOwnerSelected, setAccountOwnerSelected] = useState("")
  const [resultAccount, setResultAccount] = useState({})
  const [resultAccountFilter, setResultAccountFilter] = useState({})

  const [emissionDateIni, setEmissionDateIni] = useState(null)
  const [emissionDateEnd, setEmissionDateEnd] = useState(null)
  const [releaseDateIni, setReleaseDateIni] = useState(null)
  const [releaseDateEnd, setReleaseDateEnd] = useState(null)
  const [dueDateIni, setDueDateIni] = useState(null)
  const [dueDateEnd, setDueDateEnd] = useState(null)
  const [receivingDateIni, setReceivingDateIni] = useState(null)
  const [receivingDateEnd, setReceivingDateEnd] = useState(null)

  const [valueTotal, setValueTotal] = useState(0)
  const [valueTotalReceived, setValueTotalReceived] = useState(0)

  const [showAccount, setShowAccount] = useState(false)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showClient, setShowClient] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [order, setOrder] = useState(1)

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(false)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [firstAcess, setFirstAcess] = useState(true)

  useEffect(() => {
    let returnJson = []

    if (firstAcess){
      ////
      // Get Document Type information // Carrega as informações dos Tipos de Documento
      ////
      setLoading("Aguarde buscando as informações dos Tipos de Documentos...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_RECEIVE/SearchDocumentType", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Tipos de Documentos. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          alert(returnJson.ERROR)
          setReturn()
          return
        }

        let arrDocumentType = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrDocumentType.push(item)
          ))
        setResultDocumentType(arrDocumentType)

        let arrDocumentTypeFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrDocumentTypeFilter.push(item)
          ))
        setResultDocumentTypeFilter(arrDocumentTypeFilter)

        ////
        // Get Account Plan information // Carrega as informações dos Planos de Conta
        ////
        setLoading("Aguarde buscando as informações dos Planos de Conta...")
        setWarning("")
        setError("")

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)

        axios.post(BASE_URL + "BILL_RECEIVE/SearchAccountPlan", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os Planos de Conta. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            alert(returnJson.ERROR)
            setReturn()
            return
          }

          let arrAccountPlan = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrAccountPlan.push(item)
            ))
          setResultAccountPlan(arrAccountPlan)

          let arrAccountPlanFilter = []
          returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              arrAccountPlanFilter.push(item)
            ))
          setResultAccountPlanFilter(arrAccountPlanFilter)

          ////
          // Get Client information // Carrega as informações dos Clientes
          ////
          setLoading("Aguarde buscando as informações dos Clientes...")
          setWarning("")
          setError("")

          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)

          axios.post(BASE_URL + "BILL_RECEIVE/SearchClient", formData) 
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os Clientes. ("+ response +")\" }") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              alert(returnJson.ERROR)
              setReturn()
              return
            }

            let arrClient = []
            returnJson.OBJECT &&
              returnJson.OBJECT.map(item => (
                arrClient.push(item)
              ))
            setResultClient(arrClient)

            let arrClientFilter = []
            returnJson.OBJECT &&
              returnJson.OBJECT.map(item => (
                arrClientFilter.push(item)
              ))
            setResultClientFilter(arrClientFilter)

            ////
            // Get Account information // Carrega as informações dos Contas
            ////
            setLoading("Aguarde buscando as informações das Contas...")
            setWarning("")
            setError("")

            formData = new FormData()
            formData.append("DATABASE_TYPE", databaseType)
            formData.append("DATABASE_PATH", databasePath)
            formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
            formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
            formData.append("SYSTEM", system)

            axios.post(BASE_URL + "BILL_RECEIVE/SearchAccount", formData) 
            .then((response) => { returnJson = JSON.parse(response.data) })
            .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
              ", \"ERROR\": \"Não foi possível pesquisar as Contas. ("+ response +")\" }") })
            .finally(() => {
              if (returnJson.SUCCESS === false){
                alert(returnJson.ERROR)
                setReturn()
                return
              }

              let arrAccount = []
              returnJson.OBJECT &&
                returnJson.OBJECT.map(item => (
                  arrAccount.push(item)
                ))
              setResultAccount(arrAccount)

              let arrAccountFilter = []
              returnJson.OBJECT &&
                returnJson.OBJECT.map(item => (
                  arrAccountFilter.push(item)
                ))
              setResultAccountFilter(arrAccountFilter)
            })

            setFirstAcess(false)
            setLoading("")            
          })
        })
      })
    }

    if (updatePage){
      search()
      setUpdatePage(false)
    }

    if (showDocumentType){
      let arrDocumentType = []

      if (documentTypeDescription === ""){
        resultDocumentType.map(item => (
          arrDocumentType.push(item)
        ))
      }
      else{
        resultDocumentType.map(item => (
          (item.DESCRIPTION.indexOf(documentTypeDescription.toUpperCase()) !== -1) &&
            arrDocumentType.push(item)
        ))
      }
      setResultDocumentTypeFilter(arrDocumentType)
    }

    if (showAccountPlan){
      let arrAccountPlan = []

      if (accountPlanDescription === ""){
        resultAccountPlan.map(item => (
          arrAccountPlan.push(item)
        ))
      }
      else{
        resultAccountPlan.map(item => (
          (item.DESCRIPTION.indexOf(accountPlanDescription.toUpperCase()) !== -1) &&
            arrAccountPlan.push(item)
        ))
      }
      setResultAccountPlanFilter(arrAccountPlan)
    }

    if (showClient){
      let arrClient = []
  
      if (clientCorporateName === ""){
        resultClient.map(item => (
          arrClient.push(item)
        ))
      }
      else{
        resultClient.map(item => (
          (item.CORPORATE_NAME.indexOf(clientCorporateName.toUpperCase()) !== -1) &&
            arrClient.push(item)
        ))
      }
      setResultClientFilter(arrClient)
    }

    if (showAccount){
      let arrAccount = []
  
      if (accountOwner === ""){
        resultAccount.map(item => (
          arrAccount.push(item)
        ))
      }
      else{
        resultAccount.map(item => (
        (item.OWNER.indexOf(accountOwner.toUpperCase()) !== -1) &&
          arrAccount.push(item)
        ))
      }
      setResultAccountFilter(arrAccount)
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, updatePage, documentTypeDescription, accountPlanDescription, clientCorporateName, 
    accountOwner])

  const addClient = () => {
    cleanClient()
    setShowClient(true)
  }

  const addAccount = () => {  
    cleanAccount()
    setShowAccount(true)
  }

  const addAccountPlan = () => {  
    cleanAccountPlan()
    setShowAccountPlan(true)
  }

  const addDocumentType = () => {
    cleanDocumentType()
    setShowDocumentType(true)
  }

  const checkDigitalTitles = () => {
    let returnJson = []

    setWarning("")
    setError("")

    setLoading("Verificando as Contas a Receber Digitais, Aguarde...")

    axios.get(BASE_URL + "ROUTINE/BilletSituation?sCnpj="+ jsonCompany.CPF_CNPJ)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível verificar as Conta a Receber Digitais. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Verificação das Contas Digitais efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Verificação das Contas Digitais efetuada com sucesso!")

      setError("")
      setLoading("")
      setWarning(returnJson.WARNING)
    })
  }

  const clean = () => {
    setCode("")
    setNumber("")
    setValue("")
    setSituation("0")

    setEmissionDateIni(null)
    setEmissionDateEnd(null)
    setReceivingDateIni(null)
    setReceivingDateEnd(null)
    setDueDateIni(null)
    setDueDateEnd(null)    
    setReleaseDateIni(null)
    setReleaseDateEnd(null)

    setAccountOwner("")
    setAccountOwnerSelected("")
    setAccountPlanDescription("")
    setAccountPlanDescriptionSelected("")
    setClientCorporateName("")
    setClientCorporateNameSelected("")
    setDocumentTypeDescription("")
    setDocumentTypeDescriptionSelected("")    

    setResultAccountFilter(resultAccount)
    setResultAccountPlanFilter(resultAccountPlan)
    setResultClientFilter(resultClient)
    setResultDocumentTypeFilter(resultDocumentType)

    setValueTotal(0)
    setValueTotalReceived(0)

    setOrder(1)
    setShowResult(false)
  }

  const cleanAccount = () => {
    resultAccount.map(item => (
      item.MARKET = false
    ))

    setResultAccountFilter(resultAccount)

    setAccountOwnerSelected("")
    setAccountOwner("")
    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    resultAccountPlan.map(item => (
      item.MARKET = false
    ))

    setResultAccountPlanFilter(resultAccountPlan)

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setShowAccountPlan(false)
  }

  const cleanClient = () => {
    resultClient.map(item => (
      item.MARKET = false
    ))

    setResultClientFilter(resultClient)

    setClientCorporateNameSelected("")
    setClientCorporateName("")
    setShowClient(false)
  }

  const cleanDocumentType = () => {
    resultDocumentType.map(item => (
      item.MARKET = false
    ))

    setResultDocumentTypeFilter(resultDocumentType)

    setDocumentTypeDescriptionSelected("")
    setDocumentTypeDescription("")
    setShowDocumentType(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleValue = (event) => {
    if (event.target.value !== "")
      setValue(formatNumeric(event.target.value, 2))
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const markAllAccount = () => {
    let selected = ""

    resultAccount.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.OWNER)
      : selected += ("," + item.ID + "-" + item.OWNER)
    ))
    
    setAccountOwnerSelected(selected)
    setShowAccount(false)
  }

  const markAllAccountPlan = () => {
    let selected = ""

    resultAccountPlan.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setAccountPlanDescriptionSelected(selected)
    setShowAccountPlan(false)
  }

  const markAllClient = () => {
    let selected = ""

    resultClient.map(item => (
      (selected === "") ? selected = (item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
      : selected += ("," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
    ))
    
    setClientCorporateNameSelected(selected)
    setShowClient(false)
  }

  const markAllDocumentType = () => {
    let selected = ""

    resultDocumentType.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDocumentTypeDescriptionSelected(selected)
    setShowDocumentType(false)
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const search = () => {
    let returnJson = []

    let search = false
    if (code !== "") search = true
    if (!search) if (number !== "") search = true
    if (!search) if (value !== "") search = true
    if (!search) if (situation !== "0") search = true
    if (!search) if (accountPlanDescriptionSelected !== "") search = true
    if (!search) if (accountOwnerSelected !== "") search = true
    if (!search) if (clientCorporateNameSelected !== "") search = true
    if (!search) if (documentTypeDescriptionSelected !== "") search = true
    if (!search) if ((validateDate(emissionDateIni)) && (validateDate(emissionDateEnd))) search = true
    if (!search) if ((validateDate(releaseDateIni)) && (validateDate(releaseDateEnd))) search = true
    if (!search) if ((validateDate(dueDateIni)) && (validateDate(dueDateEnd))) search = true
    if (!search) if ((validateDate(receivingDateIni)) && (validateDate(receivingDateEnd))) search = true

    if (!search) 
    {
      setLoading("")
      setWarning("")
      setError("")

      setShowDocumentType(false)
      setShowAccountPlan(false)
      setShowClient(false)
      setShowResult(false)
    }

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let jBillReceive = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
      if (code !== "") jBillReceive += ", \"ID\": "+ code.replaceAll("_","")
      if (number !== "") jBillReceive += ", \"NUMBER\": \""+ number + "\""
      if (value !== "") jBillReceive += ", \"VALUE\": "+ value
      if (situation !== "0") jBillReceive += ", \"SITUATION\": "+ situation
      if (accountPlanDescriptionSelected !== "") jBillReceive += ", \"ACCOUNTS_PLAN\": \""+ accountPlanDescriptionSelected +"\""      
      if (accountOwnerSelected !== "") jBillReceive += ", \"ACCOUNTS\": \""+ accountOwnerSelected +"\""      
      if (clientCorporateNameSelected !== "") jBillReceive += ", \"CLIENTS\": \""+ clientCorporateNameSelected +"\""      
      if (documentTypeDescriptionSelected !== "") jBillReceive += ", \"DOCUMENT_TYPES\": \""+ documentTypeDescriptionSelected +"\""

      if (validateDate(emissionDateIni) && validateDate(emissionDateEnd)){
        const emissionI = emissionDateIni.toLocaleString()
        const emissionE = emissionDateEnd.toLocaleString()

        const emissionIni = String(emissionI).substr(6,4) + "-" + String(emissionI).substr(3,2) + "-" + String(emissionI).substr(0,2) + "T00:00:00";
        const emissionEnd = String(emissionE).substr(6,4) + "-" + String(emissionE).substr(3,2) + "-" + String(emissionE).substr(0,2) + "T23:59:59";
        jBillReceive  += ", \"EMISSION_DATE_START\": \""+ emissionIni + "\""
        jBillReceive  += ", \"EMISSION_DATE_END\": \""+ emissionEnd + "\""
      }

      if (validateDate(releaseDateIni) && validateDate(releaseDateEnd)){
        const releaseI = releaseDateIni.toLocaleString()
        const releaseE = releaseDateEnd.toLocaleString()        

        const releaseIni = String(releaseI).substr(6,4) + "-" + String(releaseI).substr(3,2) + "-" + String(releaseI).substr(0,2) + "T00:00:00";
        const releaseEnd = String(releaseE).substr(6,4) + "-" + String(releaseE).substr(3,2) + "-" + String(releaseE).substr(0,2) + "T23:59:59";
        jBillReceive += ", \"RELEASE_DATE_START\": \""+ releaseIni + "\""
        jBillReceive += ", \"RELEASE_DATE_END\": \""+ releaseEnd + "\""
      }

      if (validateDate(dueDateIni) && validateDate(dueDateEnd)){
        const dueI = dueDateIni.toLocaleString()
        const dueE = dueDateEnd.toLocaleString()

        const dueIni = String(dueI).substr(6,4) + "-" + String(dueI).substr(3,2) + "-" + String(dueI).substr(0,2) + "T00:00:00";
        const dueEnd = String(dueE).substr(6,4) + "-" + String(dueE).substr(3,2) + "-" + String(dueE).substr(0,2) + "T23:59:59";
        jBillReceive += ", \"DUE_DATE_START\": \""+ dueIni + "\""
        jBillReceive += ", \"DUE_DATE_END\": \""+ dueEnd + "\""
      }
        
      if (validateDate(receivingDateIni) && validateDate(receivingDateEnd)){
        const receivingI = receivingDateIni.toLocaleString()
        const receivingE = receivingDateEnd.toLocaleString()

        const receivingIni = String(receivingI).substr(6,4) + "-" + String(receivingI).substr(3,2) + "-" + String(receivingI).substr(0,2) + "T00:00:00";
        const receivingEnd = String(receivingE).substr(6,4) + "-" + String(receivingE).substr(3,2) + "-" + String(receivingE).substr(0,2) + "T23:59:59";
        jBillReceive += ", \"RECEIVING_DATE_START\": \""+ receivingIni + "\""
        jBillReceive += ", \"RECEIVING_DATE_END\": \""+ receivingEnd + "\""
      }
        
      jBillReceive += " }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jBillReceive)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_RECEIVE/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de Contas a Receber. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setWarning("")
          setError(returnJson.ERROR)

          setShowAccount(false)
          setShowAccountPlan(false)
          setShowClient(false)
          setShowDocumentType(false)
          setShowResult(false)

          setValueTotal(0)
          setValueTotalReceived(0)
          
          setLoading("")
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setError("")

          setShowAccount(false)
          setShowAccountPlan(false)
          setShowClient(false)
          setShowDocumentType(false)
          setShowResult(false)

          setValueTotal(0)
          setValueTotalReceived(0)
          
          setLoading("")
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jBillReceive)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)
        formData.append("ORDER", order)

        axios.post(BASE_URL + "BILL_RECEIVE/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as Contas a Receber. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setWarning("")
            setError(returnJson.ERROR)

            setShowAccount(false)
            setShowAccountPlan(false)
            setShowClient(false)
            setShowDocumentType(false)
            setShowResult(false)
  
            setValueTotal(0)
            setValueTotalReceived(0)
            
            setLoading("") 
            return
          }
            
          let valueOpen = 0
          let valueReceived = 0

          returnJson.OBJECT.map(item => (valueOpen += item.VALUE));
          returnJson.OBJECT.map(item => (valueReceived += item.VALUE_RECEIVED));

          setValueTotal(valueOpen)
          setValueTotalReceived(valueReceived)

          setShowAccount(false)
          setShowAccountPlan(false)
          setShowClient(false)
          setShowDocumentType(false)

          setResult(returnJson)
          setShowResult(true)
          setLoading("")
        })
      })
    }
    else
    {
      setError("Nenhum filtro informado!")
    }
  }

  const selectAccount = () => {
    setShowAccount(false)
  }

  const selectAccountPlan = () => {
    setShowAccountPlan(false)
  }

  const selectClient = () => {
    setShowClient(false)
  }

  const selectDocumentType = () => {
    setShowDocumentType(false)
  }

  const sendMessages = () => {
    let returnJson = []

    setWarning("")
    setError("")

    setLoading("Enviando as mensagens das Contas a Receber pendentes, Aguarde...")

    axios.get(BASE_URL + "ROUTINE/Messaging?sCnpj="+ jsonCompany.CPF_CNPJ)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível enviar as mensagens. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Envio das mensagens efetuado com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Envio das mensagens efetuado com sucesso!")

      setError("")
      setLoading("")
      setWarning(returnJson.WARNING)
    })
  }

  const setFieldSelected = () => {
  }

  const updateShowAccount = (id) => {
    resultAccount.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccount.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.OWNER
                       : selected = selected + "," + item.ID + "-" + item.OWNER
      : ""
    ))
    setAccountOwnerSelected(selected)
  }

  const updateShowAccountPlan = (id) => {
    resultAccountPlan.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccountPlan.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setAccountPlanDescriptionSelected(selected)
  }

  const updateShowClient = (id) => {
    resultClient.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultClient.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.CPF_CNPJ + "-" + item.CORPORATE_NAME
                       : selected = selected + "," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME
      : ""
    ))
    setClientCorporateNameSelected(selected)
  }

  const updateShowDocumentType = (id) => {
    resultDocumentType.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultDocumentType.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setDocumentTypeDescriptionSelected(selected)    
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");        

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {/* Begin - Buttons // Botões */}
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={search}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                </div>
              </div>

              { !modeSearch &&
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={() => setAdd()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                  </div>
                </div>
              }

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={clean}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 267}} onClick={checkDigitalTitles}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_refresh} alt="Verificar Títulos Digital"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Verificar Títulos Digitais</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 217}} onClick={sendMessages}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_message} alt="Enviar Mensagens"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Enviar Mensagens</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={setReturn}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>
            {/* End - Buttons // Botões */}
            
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}> 
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                  marginBottom: 10, marginLeft: 0}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                </div>

                <div style={(showMenu) ? 
                    (width < 1420) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 1150) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={(showMenu) ?
                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    : 
                      (width < 700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={(showMenu) ? 
                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                      : 
                        (width < 700) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                      <InputMasked
                        type="text"
                        name="searchBillReceiveCode"
                        description="Código"
                        mask="99999999999"
                        value={code}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setCode(event.target.value)} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      : 
                        (width < 700) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Input
                        type="search"
                        name="searchBillReceiveNumber"
                        description="Número"
                        value={number}
                        maxLength={50}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setNumber(event.target.value)} />
                    </div>
                  </div>

                  <div style={(showMenu) ? 
                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1420) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                    : 
                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1150) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                    <div style={(showMenu) ? 
                        (width < 570) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : (width < 1420) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                      : 
                        (width < 570) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : (width < 1150) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="searchBillReceiveValue"
                        description="Valor"
                        value={value || ""}
                        maxLength={13}
                        disabled={false}
                        require={false}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleValue} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 570) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 1420) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 250, maxWidth: 250}
                      : 
                        (width < 570) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 1150) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 250, maxWidth: 250}}>
                      <Select
                        name="searchBillReceiveSituation"
                        description="Situação"
                        value={situation || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Pendente\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Recebido\" }, {\"ID\": 3, \"VALUE\": \"Cancelado\" }"+
                          ", {\"ID\": 4, \"VALUE\": \"Recebido parcialmente\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setSituation} />
                    </div>
                  </div>
                </div>

                <div style={(showMenu) ? 
                    (width < 1670) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 1400) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={(showMenu) ? 
                      (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                    : 
                      (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                    <div style={(showMenu) ? 
                        (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                      : 
                        (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                      <b>Data Emissão</b>
                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={(showMenu) ?
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveEmissionDateIni"
                            description="Inicial"
                            value={emissionDateIni}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setEmissionDateIni(event)} 
                            intervalo={true}
                            inicial={true}
                            endDate={emissionDateEnd} />
                        </div>

                        <div style={(showMenu) ? 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}
                          : 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}}>

                          <label style={{fontWeight: 600}}>à</label>
                        </div>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveEmissionDateEnd"
                            description="Final"
                            value={emissionDateEnd}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setEmissionDateEnd(event)} 
                            intervalo={true}
                            inicial={false}
                            startDate={emissionDateIni} />
                        </div>
                      </div>
                    </div>

                    <div style={(showMenu) ? 
                        (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                      : 
                        (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                      <b>Data Lançamento</b>
                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>
                          <DataPicker
                            name="searchBillReceiveReleaseDateIni"
                            description="Inicial"
                            value={releaseDateIni}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setReleaseDateIni(event)} 
                            intervalo={true}
                            inicial={true}
                            endDate={releaseDateEnd} />
                        </div>

                        <div style={(showMenu) ? 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}
                          : 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}}>

                          <label style={{fontWeight: 600}}>à</label>
                        </div>

                        <div style={(showMenu) ?
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveReleaseDateEnd"
                            description="Final"
                            value={releaseDateEnd}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setReleaseDateEnd(event)} 
                            intervalo={true}
                            inicial={false}
                            startDate={releaseDateIni} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={(showMenu) ? 
                      (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    : 
                      (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                    <div style={(showMenu) ? 
                        (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                      : 
                        (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                      <b>Data Vencimento</b>
                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>                      

                          <DataPicker
                            name="searchBillReceiveDueDateIni"
                            description="Inicial"
                            value={dueDateIni}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setDueDateIni(event)} 
                            intervalo={true}
                            inicial={true}
                            endDate={dueDateEnd} />
                        </div>

                        <div style={(showMenu) ? 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}
                          : 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}}>

                          <label style={{fontWeight: 600}}>à</label>
                        </div>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveDueDateEnd"
                            description="Final"
                            value={dueDateEnd}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setDueDateEnd(event)}
                            intervalo={true}
                            inicial={false}
                            startDate={dueDateIni} />
                        </div>
                      </div>
                    </div>

                    <div style={(showMenu) ? 
                        (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                      : 
                        (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                      <b>Data Recebimento</b>
                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveReceivingDateIni"
                            description="Inicial"
                            value={receivingDateIni}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setReceivingDateIni(event)}
                            intervalo={true}
                            inicial={true}
                            endDate={receivingDateEnd} />
                        </div>

                        <div style={(showMenu) ? 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}
                          : 
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}}>

                          <label style={{fontWeight: 600}}>à</label>
                        </div>

                        <div style={(showMenu) ? 
                            (width < 1670) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}
                          : 
                            (width < 1400) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 150}}>

                          <DataPicker
                            name="searchBillReceiveReceivingDateEnd"
                            description="Final"
                            value={receivingDateEnd}
                            require={false}
                            onSelect={setFieldSelected}
                            onChange={(event) => setReceivingDateEnd(event)}
                            intervalo={true}
                            inicial={false}
                            startDate={receivingDateIni} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={(showMenu) ? 
                    (width < 1170) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={(showMenu) ? 
                      (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 230, maxWidth: 230}
                    : 
                      (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 230, maxWidth: 230}}>

                    <Select
                      name="searchBillReceiveOrder"
                      description="Ordenar"
                      value={order}
                      require={false}
                      options={JSON.parse("{\"RESULT\": [{\"ID\": 1, \"VALUE\": \"Data emissão\"}, {\"ID\": 2, \"VALUE\": \"Data vencimento\"}"+
                        ", {\"ID\": 3, \"VALUE\": \"Data pagamento\"}, {\"ID\": 4, \"VALUE\": \"Cliente\"}, {\"ID\": 5, \"VALUE\": \"Valor\"}]}")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setOrder} />
                  </div>

                  <div style={(showMenu) ? 
                      (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    : 
                      (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                    <div style={(showMenu) ? 
                        (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      : 
                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Input
                        type="search"
                        name="searchBillReceiveClientCorporateNameSelected"
                        description="Clientes Selecionados"
                        value={clientCorporateNameSelected}
                        maxLength={6000}
                        disabled={true}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addClient() })}>
                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanClient() })}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </div>

                { showClient &&
                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 0}}>
                      <div style={{width: "100%"}}>
                        <Titulo description="Cliente" />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => selectClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => markAllClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => cleanClient()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                        <Input
                          type="search"
                          name="searchBillReceiveClientCorporateName"
                          description="Nome/Razão"
                          value={clientCorporateName}
                          maxLength={500}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setClientCorporateName(event.target.value)} />
                      </div>

                      { resultClientFilter && (resultClientFilter.length > 0) &&
                        <div style={(showMenu) ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Código</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label style={{fontWeight: 600}}>Nome/Razão Social</label>
                          </div>
                        </div>
                      }

                      { resultClientFilter && resultClientFilter.map(item => (
                        <div key={item.CPF_CNPJ + "_" + item.CORPORATE_NAME} style={showMenu ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowClient(item.ID) } />
                              </div>

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label>{item.ID}</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label>
                                { item.CPF_CNPJ.length === 11 &&
                                  item.CPF_CNPJ.substr(0,3) + "." + item.CPF_CNPJ.substr(4,3) + "." + item.CPF_CNPJ.substr(6,3) + "-" + 
                                    item.CPF_CNPJ.substr(9,2)
                                }

                                { item.CPF_CNPJ.length === 14 &&
                                  item.CPF_CNPJ.substr(0,2) + "." + item.CPF_CNPJ.substr(2,3) + "." + item.CPF_CNPJ.substr(5,3) + "/" + 
                                    item.CPF_CNPJ.substr(8,4) + "-" + item.CPF_CNPJ.substr(12,4)
                                }
                              </label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label>
                              {item.CORPORATE_NAME}
                            </label>
                          </div>
                        </div>
                        ))
                      }

                      { resultClientFilter && (resultClientFilter.length === 0) &&
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                        </div>
                      }

                    </div>
                  </div>
                }

                <div style={(showMenu) ?
                    (width < 1540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                      : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                    :
                      (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                      : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="search"
                        name="searchBillReceiveDocumentTypeDescriptionSelected"
                        description="Tipos de Documentos Selecionados"
                        value={documentTypeDescriptionSelected}
                        maxLength={6000}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addDocumentType() })}>
                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanDocumentType() })}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                  <div style={showMenu ?
                      (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                      : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                    :
                      (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                      : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="search"
                        name="searchBillReceiveAccountPlanDescriptionSelected"
                        description="Plano de Contas Selecionados"
                        value={accountPlanDescriptionSelected}
                        maxLength={6000}
                        disabled={true}
                        require={false}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addAccountPlan() })}>
                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanAccountPlan() })}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </div>

                { showDocumentType &&
                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 0}}>
                      <div style={{width: "100%"}}>
                        <Titulo description="Tipo do Documento" />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => selectDocumentType()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => markAllDocumentType()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => cleanDocumentType()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                        <Input
                          type="search"
                          name="searchBillReceiveDocumentTypeDescription"
                          description="Descrição"
                          value={documentTypeDescription}
                          maxLength={500}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setDocumentTypeDescription(event.target.value)} />
                      </div>

                      { resultDocumentTypeFilter && (resultDocumentTypeFilter.length > 0) &&
                        <div style={(showMenu) ?
                            (width < 1030) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                          :
                            (width < 760) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                              (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                            <div style={showMenu ?
                                (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                              :
                                (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                              <label style={{fontWeight: 600}}>Código</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label style={{fontWeight: 600}}>Descrição</label>
                          </div>
                        </div>
                      }

                      { resultDocumentTypeFilter && resultDocumentTypeFilter.map(item => (
                        <div key={item.ID + "_" + item.DESCRIPTION} style={showMenu ?
                            (width < 1030) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                          :
                            (width < 760) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                          <div style={(showMenu) ? 
                              (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                              <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowDocumentType(item.ID) } />
                            </div>

                            <div style={showMenu ?
                                (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                              :
                                (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                              <label>{item.ID}</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label>
                              {item.DESCRIPTION}
                            </label>
                          </div>
                        </div>
                        ))
                      }
                        
                      { resultDocumentTypeFilter && (resultDocumentTypeFilter.length === 0) &&
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                        </div>
                      }

                    </div>
                  </div>
                }

                { showAccountPlan &&
                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 0}}>
                      <div style={{width: "100%"}}>
                        <Titulo description="Plano de Conta" />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => selectAccountPlan()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => markAllAccountPlan()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => cleanAccountPlan()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                        <Input
                          type="search"
                          name="searchBillReceiveAccountPlanDescription"
                          description="Descrição"
                          value={accountPlanDescription}
                          maxLength={500}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setAccountPlanDescription(event.target.value)} />
                      </div>

                      { resultAccountPlanFilter && (resultAccountPlanFilter.length > 0) &&
                        <div style={(showMenu) ?
                          (width < 1170) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 5}
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Código</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label style={{fontWeight: 600}}>Número</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label style={{fontWeight: 600}}>Descrição</label>
                          </div>
                        </div>
                      }

                      { resultAccountPlanFilter && resultAccountPlanFilter.map(item => (
                        <div key={item.ID + "_" + item.NUMBER + "_" + item.DESCRIPTION} style={showMenu ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccountPlan(item.ID) } />
                              </div>

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label>{item.ID}</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label>{item.NUMBER}</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label>{item.DESCRIPTION}</label>
                          </div>
                        </div>
                        ))
                      }

                      { resultAccountPlanFilter && (resultAccountPlanFilter.length === 0) &&
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                        </div>
                      }

                    </div>
                  </div>
                }

                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={showMenu ?
                      (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                      : {display: "flex", flexGrow: 1, flexDirection: "row"}
                    :
                      (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="search"
                        name="descricaoContaSelecionado"
                        description="Contas Selecionadas"
                        value={accountOwnerSelected}
                        maxLength={6000}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected} />
                    </div>

                    <div style={(showMenu) ? 
                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addAccount() })}>
                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanAccount() })}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </div>

                { showAccount &&
                  <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 0}}>
                      <div style={{width: "100%"}}>
                        <Titulo description="Conta" />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => selectAccount()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => markAllAccount()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 210}} onClick={() => cleanAccount()}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}}>
                        <Input
                          type="search"
                          name="searchBillReceiveAccountOwner"
                          description="Descrição"
                          value={accountOwner}
                          maxLength={50}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setAccountOwner(event.target.value)} />
                      </div>

                      { resultAccountFilter && (resultAccountFilter.length > 0) &&
                        <div style={(showMenu) ?
                          (width < 1170) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 5}
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 5}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Código</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label style={{fontWeight: 600}}>Número</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label style={{fontWeight: 600}}>Descrição</label>
                          </div>
                        </div>
                      }

                      { resultAccountFilter && resultAccountFilter.map(item => (
                        <div key={item.ID + "_" + item.NUMBER + "_" + item.OWNER} style={showMenu ?
                            (width < 1170) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                          :
                            (width < 900) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                          <div style={(showMenu) ? 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={(showMenu) ? 
                                (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccount(item.ID) } />
                              </div>

                              <div style={showMenu ?
                                  (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label>{item.ID}</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                              :  
                                (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                              <label>{item.NUMBER}</label>
                            </div>
                          </div>
                          <div style={showMenu ?
                              (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}
                            :
                              (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, minWidth: 180}}>
                            <label>{item.OWNER}</label>
                          </div>
                        </div>
                        ))
                      }

                      { resultAccountFilter && (resultAccountFilter.length === 0) &&
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                        </div>
                      }

                    </div>
                  </div>
                }

                { showResult && 
                  <>

                    { result.OBJECT &&
                      <>
                        <div style={(width < 480) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1,
                              borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                              marginTop: 30, marginBottom: 4}}>

                          <div style={(showMenu) ? 
                                (width < 2440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 2160) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={showMenu ?
                                  (width < 1010) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2440) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                :
                                  (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2160) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                <div style={showMenu ?
                                    {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 110, maxWidth: 110}
                                  :
                                    {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 110, maxWidth: 110}}>
                                  <label style={{fontWeight: 600}}>Código</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 1010) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                    : (width < 2440) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}
                                  :
                                    (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                    : (width < 2160) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}}>
                                  <label style={{fontWeight: 600}}>Docto/Parc</label>
                                </div>
                              </div>

                              {/* Begin - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Lancto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Vencto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Recebto.</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Lancto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Vencto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Recebto.</label>
                                        </div>
                                      </div>
                                    : (width < 760) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Lancto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Vencto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Recebto.</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 760) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Lancto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Vencto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Recebto.</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Lancto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Vencto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Recebto.</label>
                                        </div>
                                      </div>
                                  }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Lancto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Vencto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Recebto.</label>
                                          </div>
                                        </div>
                                      </>
                                    
                                    : (width >= 380) && (width < 2160) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Lancto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Vencto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Recebto.</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Lancto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Vencto.</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>Recebto.</label>
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                              {/* End - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}

                              <div style={showMenu ?
                                  (width < 2440) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :                   
                                  (width < 2160) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                <label style={{fontWeight: 600}}>Cliente</label>
                              </div>

                              {/* Begin - SITUATION / VALUE / VALUE_RECEIVED */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>Situação</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Valor</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>Situação</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                        </div>
                                      </div>
                                    : (width < 890) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>Situação</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Valor</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 890) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>Situação</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          <label style={{fontWeight: 600}}>Situação</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                        </div>
                                      </div> 
                                    }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>Situação</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Valor</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2160) && (width >= 580) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>Situação</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          <label style={{fontWeight: 600}}>Situação</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>Vlr. Recto</label>
                                        </div>
                                      </div>                                
                                  }
                                </>
                              }
                              {/* End - SITUATION / VALUE / VALUE_RECEIVED */}

                            </div>

                            <div style={(width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, marginRight: 10, 
                              justifyContent: "center"}
                              : {display: "flex", flexGrow: 0, alignItems: "center", marginRight: 10, marginLeft: 10, minWidth: 50, maxWidth: 50}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />
                            </div>
                        </div>

                        { result.OBJECT.map(item => (
                          <div key={item.ID} style={(width < 480) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1,
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                              backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                              borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                              marginTop: 4, minWidth: 180}}>

                            <div style={(showMenu) ? 
                                (width < 2440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 2160) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={showMenu ?
                                  (width < 1010) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2440) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                :
                                  (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2160) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                <div style={showMenu ?
                                    {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                  :
                                    {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                  <label>{item.ID}</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 1010) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 2440) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}
                                  :
                                    (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 2160) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}}>
                                  <label>{item.NUMBER + "/" + item.PORTION}</label>
                                </div>
                              </div>

                              {/* Begin - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                              String(item.RELEASE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                              String(item.DUE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" ?
                                                String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                                  String(item.RECEIVING_DATE).substr(0,4) 
                                              :
                                                "-"
                                            }
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                            String(item.RELEASE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                            String(item.DUE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" &&
                                            String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                              String(item.RECEIVING_DATE).substr(0,4) 
                                          }
                                        </div>
                                      </div>
                                    : (width < 760) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                              String(item.RELEASE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                              String(item.DUE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" ?
                                                String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                                  String(item.RECEIVING_DATE).substr(0,4) 
                                              :
                                                "-"
                                            }
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 760) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                            String(item.RELEASE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                            String(item.DUE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" &&
                                            String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                              String(item.RECEIVING_DATE).substr(0,4) 
                                          }
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                            String(item.RELEASE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                            String(item.DUE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" &&
                                            String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                              String(item.RECEIVING_DATE).substr(0,4) 
                                          }
                                        </div>
                                      </div>
                                  }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                              String(item.RELEASE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                              String(item.DUE_DATE).substr(0,4) }
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" ?
                                                String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                                  String(item.RECEIVING_DATE).substr(0,4) 
                                              :
                                                "-"
                                            }
                                          </div>
                                        </div>
                                      </>
                                    
                                    : (width >= 380) && (width < 2160) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                            String(item.RELEASE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                            String(item.DUE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" &&
                                            String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                              String(item.RECEIVING_DATE).substr(0,4) 
                                          }
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" + 
                                            String(item.RELEASE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                            String(item.DUE_DATE).substr(0,4) }
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          { item.RECEIVING_DATE !== undefined && item.RECEIVING_DATE !== "0001-01-01T00:00:00" &&
                                            String(item.RECEIVING_DATE).substr(8,2) + "/" + String(item.RECEIVING_DATE).substr(5,2) + "/" +
                                              String(item.RECEIVING_DATE).substr(0,4) 
                                          }
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                              {/* End - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}

                              <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>{item.CLIENT.CORPORATE_NAME}</div>

                              {/* Begin - SITUATION / VALUE / VALUE_RECEIVED */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}}>
                                            { item.SITUATION === 1 && "Pendente" }
                                            { item.SITUATION === 2 && "Recebido" }
                                            { item.SITUATION === 3 && "Cancelado" }
                                            { item.SITUATION === 4 && "Recebido parcialmente" } 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { formatNumeric(item.VALUE, 2)} 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}}>
                                          { item.SITUATION === 1 && "Pendente" }
                                          { item.SITUATION === 2 && "Recebido" }
                                          { item.SITUATION === 3 && "Cancelado" }
                                          { item.SITUATION === 4 && "Recebido parcialmente" } 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { formatNumeric(item.VALUE, 2)} 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                        </div>
                                      </div>
                                    : (width < 890) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}}>
                                            { item.SITUATION === 1 && "Pendente" }
                                            { item.SITUATION === 2 && "Recebido" }
                                            { item.SITUATION === 3 && "Cancelado" }
                                            { item.SITUATION === 4 && "Recebido parcialmente" } 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { formatNumeric(item.VALUE, 2)} 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 890) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}}>
                                          { item.SITUATION === 1 && "Pendente" }
                                          { item.SITUATION === 2 && "Recebido" }
                                          { item.SITUATION === 3 && "Cancelado" }
                                          { item.SITUATION === 4 && "Recebido parcialmente" } 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { formatNumeric(item.VALUE, 2)} 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          { item.SITUATION === 1 && "Pendente" }
                                          { item.SITUATION === 2 && "Recebido" }
                                          { item.SITUATION === 3 && "Cancelado" }
                                          { item.SITUATION === 4 && "Recebido parcialmente" } 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          { formatNumeric(item.VALUE, 2)} 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                        </div>
                                      </div> 
                                    }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 200}}>
                                            { item.SITUATION === 1 && "Pendente" }
                                            { item.SITUATION === 2 && "Recebido" }
                                            { item.SITUATION === 3 && "Cancelado" }
                                            { item.SITUATION === 4 && "Recebido parcialmente" } 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { formatNumeric(item.VALUE, 2)} 
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 120}}>
                                            { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2160) && (width >= 580) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}}>
                                          { item.SITUATION === 1 && "Pendente" }
                                          { item.SITUATION === 2 && "Recebido" }
                                          { item.SITUATION === 3 && "Cancelado" }
                                          { item.SITUATION === 4 && "Recebido parcialmente" } 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { formatNumeric(item.VALUE, 2)} 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          { item.SITUATION === 1 && "Pendente" }
                                          { item.SITUATION === 2 && "Recebido" }
                                          { item.SITUATION === 3 && "Cancelado" }
                                          { item.SITUATION === 4 && "Recebido parcialmente" } 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          { formatNumeric(item.VALUE, 2)} 
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          { item.VALUE_RECEIVED ? formatNumeric(item.VALUE_RECEIVED, 2) : "0.00" }
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                              {/* End - SITUATION / VALUE / VALUE_RECEIVED */}

                            </div>

                            <div style={(width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, 
                                justifyContent: "center"}
                              : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 50, maxWidth: 50}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                <img
                                  style={{height: 32, width: 32}}
                                  src={image_select}
                                  name="searchBillReceiveSelected"
                                  alt="Selecionar"
                                  onClick={(() => setSelected(item))} />
                              </div>
                            </div>  
                          </div>
                          ))
                        }

                        <div style={(width < 480) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1,
                              borderTopColor: "black", borderTopWidth: 2, borderTopStyle: "solid", 
                              marginTop: 8, marginBottom: 8}
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1,
                            borderTopColor: "black", borderTopWidth: 2, borderTopStyle: "solid", 
                              marginTop: 8, marginBottom: 8}}>

                          <div style={(showMenu) ? 
                                (width < 2440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15}
                              : 
                                (width < 2160) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15}}>

                              <div style={showMenu ?
                                  (width < 1010) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2440) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}
                                :
                                  (width < 740) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                  : (width < 2160) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                                <div style={showMenu ?
                                    {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 110, maxWidth: 110}
                                  :
                                    {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 110, maxWidth: 110}}>
                                  <label style={{fontWeight: 600}}>Totais:</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 1010) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                    : (width < 2440) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}
                                  :
                                    (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                    : (width < 2160) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 470}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 470, maxWidth: 470}}>
                                  <label style={{fontWeight: 600}}>{" "}</label>
                                </div>
                              </div>

                              {/* Begin - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Lancto.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>
                                      </div>
                                    : (width < 760) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 760) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>
                                      </div>
                                  }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 380) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 100}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>
                                      </>
                                    
                                    : (width >= 380) && (width < 2160) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                              {/* End - RELEASE_DATE / DUE_DATE / RECEIVING_DATE */}

                              <div style={showMenu ?
                                  (width < 2440) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :
                                  (width < 2160) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                <label style={{fontWeight: 600}}>{" "}</label>
                              </div>

                              {/* Begin - SITUATION / VALUE / VALUE_RECEIVED */}
                              { showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 690) && (width >= 380) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                        </div>
                                      </div>
                                    : (width < 890) && (width >= 690) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                          </div>
                                        </div>
                                      </>
                                    : (width < 2440) && (width >= 890) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                        </div>
                                      </div> 
                                    }
                                </>
                              }

                              { !showMenu &&
                                <>
                                  {
                                    (width < 580) ?
                                      <>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 200}}>
                                            <label style={{fontWeight: 600}}>{" "}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 120}}>
                                            <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                          </div>
                                        </div>
                                      </>                                     
                                    : (width < 2160) && (width >= 580) ?
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 15, minWidth: 200}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                        </div>
                                      </div>
                                    :
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                          <label style={{fontWeight: 600}}>{" "}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ formatNumeric(valueTotal, 2)}</label>
                                        </div>

                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                          <label style={{fontWeight: 600}}>{ valueTotalReceived ? formatNumeric(valueTotalReceived, 2) : "0.00" }</label>
                                        </div>
                                      </div>
                                  }
                                </>
                              }
                              {/* End - SITUATION / VALUE / VALUE_RECEIVED */}

                            </div>

                            <div style={(width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, marginRight: 10, 
                              justifyContent: "center"}
                              : {display: "flex", flexGrow: 0, alignItems: "center", marginRight: 10, marginLeft: 10, minWidth: 50, maxWidth: 50}}>

                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />
                            </div>
                        </div>

                        <SearchPagination 
                          pageCurrent={pageCurrent}
                          totalPage={totalPage}
                          recordPage={recordPage}
                          optionsRegPage={JSON.parse(optionsRegPage)}
                          pagePrevious={pagePrevious}
                          pageNext={pageNext}
                          setSelectedField={setFieldSelected}
                          handleRecordPage={handleRecordPage}
                          width={width} />
                      </>
                    }

                  </>
                }

              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SearchBillReceive