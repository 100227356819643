import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchPrinterSection from "./Search"
import SearchPrinterSectionDepartment from "../PrinterSectionDepartment/Search"
import image_loading from "../../Images/spinner.gif";

const PrinterSection = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [path, setPath] = useState("")
  const [situation, setSituation] = useState(0)
  const [sge, setSge] = useState("")

  const [jPrinterSection, setJPrinterSection] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setDescription("")
      setPath("")
      setSituation(0)
    }
    else {
      setDescription(jPrinterSection.DESCRIPTION)
      setPath(jPrinterSection.PATH.replaceAll("+","\\"))
      setSituation(jPrinterSection.SITUATION)

      if ((jPrinterSection.SGE !== null) && (jPrinterSection.SGE !== undefined)) setSge(jPrinterSection.SGE)
    }

    document.getElementById("printerSectionDescription") && document.getElementById("printerSectionDescription").focus()

  }, [jsonCompany, code, jPrinterSection])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setDescription("")
      setPath("")
      setSituation(0)
      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonPrinterSection_New = {}

    jsonPrinterSection_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonPrinterSection_New += ", \"ID\": "+ code    
    if (((description !== undefined) && (description !== null)) && (description !== "")) jsonPrinterSection_New += ", \"DESCRIPTION\": \""+ description + "\""
    if (((path !== undefined) && (path !== null)) && (path !== "")) jsonPrinterSection_New += ", \"PATH\": \""+ path.replaceAll("\\","\\\\") + "\""

    jsonPrinterSection_New += ", \"SITUATION\": "+ situation

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonPrinterSection_New += ", \"SGE\": "+ sge

    jsonPrinterSection_New += " }"

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonPrinterSection_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "RTS_PRINTER/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a impressora. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("printSectionDescription") && document.getElementById("printSectionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")      

        setJPrinterSection(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jPrinterSection))
      formData.append("JSON_NEW_1", jsonPrinterSection_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "RTS_PRINTER/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a impressora. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("printSectionDescription") && document.getElementById("printSectionDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJPrinterSection(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  } 

  const setSelected = (event) => {
    setShowSearch(false)
    setJPrinterSection(event)
    setCode(event.ID)
  }   

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Impressora"
            titleSearch="Pesquisa das Impressoras"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchPrinterSection
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10} : {paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 220}
                                : (width < 730) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 220}
                              :                              
                                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 220}}>
                              <Input
                                type="text"
                                name="printerSectionDescription"
                                description="Descrição"
                                value={description}
                                maxLength={20}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                : (width < 730) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                              <Select
                                name="printerSectionSituation"
                                description="Situação"
                                value={situation || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setSituation} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={showMenu ?
                              (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :  
                              (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={showMenu ?
                                (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={showMenu ?
                                  (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                <Input
                                  type="text"
                                  name="printerSectionCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={showMenu ?
                                  (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 220}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 220}}>
                                <Input
                                  type="text"
                                  name="printerSectionDescription"
                                  description="Descrição"
                                  value={description}
                                  maxLength={20}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDescription(event.target.value)} />
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 860) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                              :
                                (width < 590) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                              <Select
                                name="printerSectionSituation"
                                description="Situação"
                                value={situation || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setSituation} />
                            </div>
                          </div>
                        }

                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                            <Input
                              type="text"
                              name="printerSectionPath"
                              description="Caminho"
                              value={path}
                              maxLength={40}
                              disabled={false}
                              require={true}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setPath(event.target.value)} />
                          </div>
                        </div>
              
                        { code &&
                          <div style={{marginTop: 10}}>
                            <Titulo description="Departamentos"/>

                            <SearchPrinterSectionDepartment
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonPrinterSection={jPrinterSection}
                              jsonCompany={jsonCompany}
                              jsonLogin={jsonLogin}
                              system={system}
                              showMenu={showMenu}
                              width={width} />
                          </div>
                        }

                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default PrinterSection