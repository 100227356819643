import "./style.css"
import React, { useEffect, useState } from "react"
import axios from "axios"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import image_loading from "../../Images/spinner.gif";

const api = axios.create({
  baseURL: "https://localhost:5001/api/",
  headers: {                
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=utf-8"
  }
})

const Authentication = ({typeDatabase, pathDatabase, jsonCompany, jsonLogin, restriction, cancellation, height, setReturn}) => {
  const [codigoEmployee, setCodigoEmployee] = useState("0")
  const [codigoEmployeeMultipla, setCodigoEmployeeMultipla] = useState("0")    
  const [firstAcess, setFirstAcess] = useState(true)
  const [password, setPassword] = useState("")
  const [update, setUpdate] = useState(0)

  const [jsonEmployee, setJsonEmployee] = useState("")
  const [jsonEmployeeMultipla, setJsonEmployeeMultipla] = useState("")  
  const [jsonEmployees, setJsonEmployees] = useState("")
  const [jsonEmployeesMultipla, setJsonEmployeesMultipla] = useState("")

  const [optionsEmployee, setOptionsEmployee] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")
  const [optionsEmployeeMultipla, setOptionsEmployeeMultipla] = useState("{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}]}")  

  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")

  useEffect(() => {    
    if (firstAcess){
      var jFil = JSON.parse(jsonCompany)

      ////
      // Find employeers at Múltipla Sistemas
      ////
      var retornoJson = []
      var sPathCentral = "User Id=postgres;Server=clmultipla-id43.cloudtsci.tec.br;Port=5432;Database=dbCentral;"
      var jsonLoginA = "{\"CPF\": \"79772468050\", \"SENHA\": \"M!LTPL\", \"FILIAL\": {\"ID\": 1 } }"
  
      setLoading("Aguarde buscando as informações dos coolaboradores...")
      setError("")
          
      api.get("FUNCIONARIO/Pesquisar?iTipoBanco=2&sCaminhoBanco="+ sPathCentral + "&jsonFuncionario={ \"FILIAL\": { \"ID\": 1 }, \"SITUACAO\": 1 }"+
        "&jsonLogin=" + jsonLoginA + "&bSistema=true&iQtdeInicial=0&iQtdeRegistros=99")
      .then((response) => { retornoJson = JSON.parse(response.data); })
      .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
      .finally(() => {
        if (retornoJson.CONCRETIZADO === false){
          setLoading("")
          setError(retornoJson.ERRO)
          return
        }
        else
        {
          retornoJson.RESULTADO &&
            retornoJson.RESULTADO && setJsonEmployeesMultipla(retornoJson.RESULTADO)
  
          var employees = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
          retornoJson &&
            retornoJson.RESULTADO &&
              retornoJson.RESULTADO.map(item => (
                employees += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.NOME +"\"}"
          ))
          employees += "]}"
                     
          setOptionsEmployeeMultipla(employees)

          ////
          // Find employeers at logged company 
          ////
          var jEmployee = "{ \"SITUACAO\": 1, \"FILIAL\": { \"ID\": "+ jFil.ID +" } }"

          api.get("FUNCIONARIO/Pesquisar?iTipoBanco="+ typeDatabase + "&sCaminhoBanco="+ pathDatabase + "&jsonFuncionario="+  jEmployee +
            "&jsonLogin=" + jsonLogin + "&bSistema=false&iQtdeInicial=0&iQtdeRegistros=999")
          .then((response) => { retornoJson = JSON.parse(response.data) })
          .catch((response) => { retornoJson = JSON.parse("{ \"CONCRETIZADO\": false, \"RESULTADO\": null, \"AVISO\": null, \"ERRO\": \"Não foi possível comunicar com a WebAPI ("+ response +")\"}") })
          .finally(() => {
            if (retornoJson.CONCRETIZADO === false){
              setLoading("")
              setError(retornoJson.ERRO)
              return
            }
            else
            {
              var employee = "{\"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\"}"
              retornoJson &&
                retornoJson.RESULTADO &&
                  retornoJson.RESULTADO.map(item => (
                    employee += ",{\"ID\": "+ item.ID +", \"VALOR\": \""+ item.NOME +"\"}"
                  ))
              employee += "]}"

              retornoJson &&
                retornoJson.RESULTADO && setJsonEmployees(retornoJson.RESULTADO)

              setOptionsEmployee(employee)
              setLoading("")
              setError("")
            }
          })
        }
      })

      setFirstAcess(false)
    }

    document.getElementById("codigoEmployee_authentication") &&
      document.getElementById("codigoEmployee_authentication").focus()

  }, [update, loading])  

  const confirm = () => {
    if (String(codigoEmployee) === "0"){
      setLoading("")
      setError("É necessário selecionar o coolaborador!!!")

      document.getElementById("codigoEmployee_authentication") &&
        document.getElementById("codigoEmployee_authentication").focus()

      return
    }
  
    if (String(codigoEmployee) === "1"){
      if (String(codigoEmployeeMultipla) === "0"){
        setLoading("")
        setError("É necessário selecionar o coolaborador da Múltipla!!!")

        document.getElementById("codigoEmployeeMultipla_authentication") &&
          document.getElementById("codigoEmployeeMultipla_authentication").focus()

        return
      }
    }
  
    if (password === ""){
      setLoading("")
      setError("É necessário preecher a senha!!!")

      document.getElementById("passwordEmployee_authentication") &&
        document.getElementById("passwordEmployee_authentication").focus()

      return
    }
  
    if ((parseInt(codigoEmployee) === 1) && (JSON.parse(jsonCompany).CPF_CNPJ !== "07824993000165"))
    {
      if (String(JSON.parse(jsonEmployeeMultipla).SENHA) !== password)
      {
        setLoading("")
        setError("Senha inválida.")

        document.getElementById("passwordEmployee_authentication") &&
          document.getElementById("passwordEmployee_authentication").focus()

        return
      }
    }
    else
    {
      if (JSON.parse(jsonEmployee).SENHA !== password)
      {
        setLoading("")
        setError("Senha inválida.")

        document.getElementById("passwordEmployee_authentication") &&
          document.getElementById("passwordEmployee_authentication").focus()

        return
      }
    }

    if (!restriction) setReturn(100)
    else
    {
        var bPermited = false;
        if ((cancellation) && (parseInt(JSON.parse(jsonEmployee).PERMISSAO) === 3)) bPermited = false;
        else
        {
            if (parseInt(JSON.parse(jsonEmployee).PERMISSAO) === 1) bPermited = false;
            else bPermited = true;
        }

        if (!bPermited){
            setReturn(999)
            return
        }

        setReturn(100)
    }
  }

  const formatEmployee = (item) => {
    var sEmployee = "{ "
    sEmployee += "\"ID\": "+ item.ID + ", "
    sEmployee += "\"FILIAL\": { \"ID\": "+ item.FILIAL.ID + " }, "

    if ((item.MATRICULA !== null) && (item.MATRICULA !== "")) sEmployee += "\"MATRICULA\": " + item.MATRICULA + ", "

    sEmployee += "\"CPF\": \"" + item.CPF + "\", "

    if ((item.RG !== null) && (item.RG !== "")) sEmployee += "\"RG\": \"" + item.RG + "\", "

    sEmployee += "\"NOME\": \"" + item.NOME + "\", "

    if ((item.DT_NASCIMENTO !== null) && (item.DT_NASCIMENTO !== "")) sEmployee += "\"DT_NASCIMENTO\": \"" + item.DT_NASCIMENTO + "\", "

    sEmployee += "\"SENHA\": \"" + item.SENHA + "\", "
    sEmployee += "\"BARRAS\": \"" + item.BARRAS + "\", "
    sEmployee += "\"PERMISSAO\": " + item.PERMISSAO + ", "

    if ((item.EMAIL !== null) && (item.EMAIL !== "")) sEmployee += "\"EMAIL\": \"" + item.EMAIL + "\", "
    if ((item.CTPS !== null) && (item.CTPS !== "")) sEmployee += "\"CTPS\": \"" + item.CTPS + "\", "
    if ((item.CTPS_SERIE !== null) && (item.CTPS_SERIE !== "")) sEmployee += "\"CTPS_SERIE\": \"" + item.CTPS_SERIE + "\", "                

    sEmployee += "\"SALARIO\": " + item.SALARIO + ", "
    sEmployee += "\"DIAS_TRABALHO\": \"" + item.DIAS_TRABALHO.replaceAll("#"," ") + "\", "
    sEmployee += "\"DT_ADMISSAO\": \"" + item.DT_ADMISSAO + "\", "

    if ((item.DT_DEMISSAO !== null) && (item.DT_DEMISSAO !== "0001-01-01T00:00:00")) sEmployee += "\"DT_DEMISSAO\": \"" + item.DT_DEMISSAO + "\", "
    if (item.RH_CARGO !== null) {
      sEmployee = sEmployee + "\"RH_CARGO\": { \"ID\": "+ item.RH_CARGO.ID

      if (item.RH_CARGO.RH_SETOR !== null)
        if (item.RH_CARGO.RH_SETOR.PERMISSAO !== null)
          sEmployee = sEmployee + ", \"RH_SETOR\": { \"PERMISSAO\": \""+ item.RH_CARGO.RH_SETOR.PERMISSAO.replaceAll("#"," ") + "\" }"

      sEmployee = sEmployee + " },"
    }

    if (item.RH_TURNO !== null) sEmployee += "\"RH_TURNO\": { \"ID\": "+ item.RH_TURNO.ID + " }, "

    sEmployee += "\"CEP\": \""+ item.CEP + "\", "
    sEmployee += "\"LOGRADOURO\": \""+ item.LOGRADOURO + "\", "
    sEmployee += "\"NUMERO\": \"" + item.NUMERO + "\", "
    if ((item.COMPLEMENTO !== null) && (item.COMPLEMENTO !== "")) sEmployee += "\"COMPLEMENTO\": \"" + item.COMPLEMENTO + "\", "
    sEmployee += "\"BAIRRO\": \"" + item.BAIRRO + "\", "
    sEmployee += "\"CIDADE\": \"" + item.CIDADE + "\", "
    sEmployee += "\"CIDADE_IBGE\": " + item.CIDADE_IBGE + ", "
    sEmployee += "\"UF\": \"" + item.UF + "\", "
    sEmployee += "\"UF_IBGE\": " + item.UF_IBGE + ", "
    sEmployee += "\"PAIS\": \"" + item.PAIS + "\", "
    sEmployee += "\"PAIS_IBGE\": " + item.PAIS_IBGE + ", "

    if ((item.FOTO !== null) && (item.FOTO !== "")) sEmployee += "\"FOTO\": \"" + item.FOTO + "\", "

    sEmployee += "\"DESCONTO_PEDIDO\": " + item.DESCONTO_PEDIDO + ", "
    sEmployee += "\"DESCONTO_PORCENTAGEM\": " + item.DESCONTO_PORCENTAGEM + ", "
    sEmployee += "\"EXECUTA_OS\": " + item.EXECUTA_OS + ", "
    sEmployee += "\"TRANSFERE_OS\": " + item.TRANSFERE_OS + ", "
    sEmployee += "\"FINALIZA_OS\": " + item.FINALIZA_OS + ", "

    if ((item.OBSERVACOES !== null) && (item.OBSERVACOES !== "")) sEmployee += "\"OBSERVACOES\": \"" + item.OBSERVACOES + "\", "
    if ((item.ACESSO !== null) && (item.ACESSO !== "")) sEmployee += "\"ACESSO\": \"" + item.ACESSO.replaceAll("#"," ") + "\", "

    sEmployee += "\"SITUACAO\": " + item.SITUACAO + " } "
    return sEmployee
  }

  const handleEmployee = (event) => {
    var codigo = event
    setCodigoEmployee(codigo)

    jsonEmployees.map(item => (
      ((item.ID) === parseInt(codigo)) && setJsonEmployee(formatEmployee(item))
    ))

    setUpdate(update + 1)
  }

  const handleEmployeeMultipla = (event) => {
    var codigo = event
    setCodigoEmployeeMultipla(codigo)

    jsonEmployeesMultipla.map(item => (
      ((item.ID) === parseInt(codigo)) && setJsonEmployeeMultipla(formatEmployee(item))
    ))
  }  

  const returns = () => {
    setReturn(998)
  }

  const selectedField = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: (height - 110), maxHeight: (height - 110), boxSizing: "border-box", width: "100%"}}>
      { loading ?
          <div id="loading_authentication">
            <img src={image_loading} alt="Carregando"/>
            <label>{loading}</label>
          </div>
        :
          <div style={{ maxWidth: 450 }}>
            <div>
              <Select
                name="codigoEmployee_authentication"
                description="Coolaborador:"
                value={codigoEmployee || 0}
                disabled={false}
                require={true}
                options={JSON.parse(optionsEmployee)}
                image={true}
                typeImage={1}
                typeInput={2}
                onSelect={selectedField}
                set={handleEmployee} />
            </div>

            { ((parseInt(codigoEmployee) === 1) && ((JSON.parse(jsonCompany).CPF_CNPJ) !== "07824993000165")) &&            
              <div className="marginTop10_authentication">
                <Select                      
                  name="codigoEmployeeMultipla_authentication"
                  description="Coolaborador Múltipla"
                  value={codigoEmployeeMultipla || 0}
                  disabled={false}
                  require={true}
                  options={JSON.parse(optionsEmployeeMultipla)}
                  image={true}
                  typeImage={1}
                  typeInput={2}
                  onSelect={selectedField}
                  set={handleEmployeeMultipla} />
              </div>
            }

            <div className="marginTop10_authentication">
              <Input                    
                type="password"
                name="passwordEmployee_authentication"
                description="Senha:"
                value={password}
                maxLength={14}
                disabled={false}
                require={true}
                image={true}
                typeImage={2}
                typeInput={2}
                onSelect={selectedField}
                onChange={(event) => setPassword(event.target.value)} />
            </div>

            { error &&
              <div className="message_authentication">
                <label className="messageError_authentication">{error}</label>
              </div> 
            }

            <div className="botoes_authentication" onClick={confirm}>
              <label className="fontWeight600_authentication colorWhite_authentication">LOGIN</label>
            </div>

            <div className="botoes_authentication" onClick={returns}>
              <label className="fontWeight600_Authentication colorWhite_authentication">RETORNAR</label>
            </div>
          </div>
      }
    </div>
  )
}

export default Authentication