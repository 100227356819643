import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchCest from "../Cest/Search"
import SearchCofins from "../CstCofins/Search"
import SearchIpi from "../CstIpi/Search"
import SearchNcm from "./Search"
import SearchPis from "../CstPis/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_search from "../../Images/Buttons/search.png"

const Ncm = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [codeOrigin, setCodeOrigin] = useState("")
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [situation, setSituation] = useState(0)

  const [cestCode, setCestCode] = useState("")
  const [cestDescription, setCestDescription] = useState("")

  const [pisECode, setPisECode] = useState("")
  const [pisEDescription, setPisEDescription] = useState("")

  const [cofinsECode, setCofinsECode] = useState("")
  const [cofinsEDescription, setCofinsEDescription] = useState("")

  const [ipiSCode, setIpiSCode] = useState("")
  const [ipiSDescription, setIpiSDescription] = useState("")
  const [ipiSAliquot, setIpiSAliquot] = useState(0)

  const [pisSCode, setPisSCode] = useState("")
  const [pisSDescription, setPisSDescription] = useState("")
  const [pisSAliquot, setPisSAliquot] = useState(0)
  const [pisSNature, setPisSNature] = useState("")

  const [cofinsSCode, setCofinsSCode] = useState("")
  const [cofinsSDescription, setCofinsSDescription] = useState("")
  const [cofinsSAliquot, setCofinsSAliquot] = useState(0)
  const [cofinsSNature, setCofinsSNature] = useState("")

  const [fcp, setFcp] = useState(0)
  const [fcpAliquot, setFcpAliquot] = useState(0)

  const [percentageIbptN, setPercentageIbptN] = useState(0)
  const [percentageIbptI, setPercentageIbptI] = useState(0)
  const [percentageIbptE, setPercentageIbptE] = useState(0)
  const [percentageIbptM, setPercentageIbptM] = useState(0)
    
  const [jNcm, setJNcm] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showCest, setShowCest] = useState(false)
  const [showIpiS, setShowIpiS] = useState(false)
  const [showPisE, setShowPisE] = useState(false)
  const [showPisS, setShowPisS] = useState(false)
  const [showCofinsE, setShowCofinsE] = useState(false)
  const [showCofinsS, setShowCofinsS] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (codeOrigin === "") {
      setDescription("")
      setSituation(0)

      setCestCode("")
      setCestDescription("")
    
      setPisECode("")
      setPisEDescription("")
    
      setCofinsECode("")
      setCofinsEDescription("")
    
      setIpiSCode("")
      setIpiSDescription("")
      setIpiSAliquot(0)
    
      setPisSCode("")
      setPisSDescription("")
      setPisSAliquot(0)
      setPisSNature("")
    
      setCofinsSCode("")
      setCofinsSDescription("")
      setCofinsSAliquot(0)
      setCofinsSNature("")
    
      setFcp(0)
      setFcpAliquot(0)
    
      setPercentageIbptN(0)
      setPercentageIbptI(0)
      setPercentageIbptE(0)
      setPercentageIbptM(0)
    }    
    else {
      setDescription(jNcm.DESCRIPTION)
      setSituation(jNcm.SITUATION)

      if (jNcm.CEST !== null){
        setCestCode(jNcm.CEST.CODE)
        setCestDescription(jNcm.CEST.DESCRIPTION)
      }
      else{
        setCestCode("")
        setCestDescription("")
      }

      if (jNcm.CST_PIS_E !== null){
        setPisECode(jNcm.CST_PIS_E.CODE)
        setPisEDescription(jNcm.CST_PIS_E.DESCRIPTION)
      }
      else{
        setPisECode("")
        setPisEDescription("")
      }

      if (jNcm.CST_COFINS_E !== null){
        setCofinsECode(jNcm.CST_COFINS_E.CODE)
        setCofinsEDescription(jNcm.CST_COFINS_E.DESCRIPTION)
      }
      else{
        setCofinsECode("")
        setCofinsEDescription("")
      }

      if (jNcm.CST_IPI_S !== null){
        setIpiSCode(jNcm.CST_IPI_S.CODE)
        setIpiSDescription(jNcm.CST_IPI_S.DESCRIPTION)
      }
      else{
        setIpiSCode("")
        setIpiSDescription("")
      }

      setIpiSAliquot(jNcm.IPI_ALIQUOT_S)
      setPisSCode(jNcm.CST_PIS_S.CODE)
      setPisSDescription(jNcm.CST_PIS_S.DESCRIPTION)
      setPisSAliquot(jNcm.PIS_ALIQUOT_S)
      setPisSNature(jNcm.PIS_NATURE_S)
      setCofinsSCode(jNcm.CST_COFINS_S.CODE)
      setCofinsSDescription(jNcm.CST_COFINS_S.DESCRIPTION)
      setCofinsSAliquot(jNcm.COF_ALIQUOT_S)
      setCofinsSNature(jNcm.COF_NATURE_S)
      setFcp(jNcm.FCP)
      setFcpAliquot(jNcm.ALIQUOT_FCP)
      setPercentageIbptN(jNcm.PERCENTAGE_IBPT_N)
      setPercentageIbptI(jNcm.PERCENTAGE_IBPT_I)
      setPercentageIbptE(jNcm.PERCENTAGE_IBPT_E)
      setPercentageIbptM(jNcm.PERCENTAGE_IBPT_M)
    }

    if (codeOrigin === "") document.getElementById("ncmCode") && document.getElementById("ncmCode").focus()
    else document.getElementById("ncmDescription") && document.getElementById("ncmDescription").focus()

  }, [codeOrigin, jNcm, updateFocu]) 

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      setDescription("")
      setSituation(0)

      setCestCode("")
      setCestDescription("")
    
      setPisECode("")
      setPisEDescription("")
    
      setCofinsECode("")
      setCofinsEDescription("")
    
      setIpiSCode("")
      setIpiSDescription("")
      setIpiSAliquot(0)
    
      setPisSCode("")
      setPisSDescription("")
      setPisSAliquot(0)
      setPisSNature("")
    
      setCofinsSCode("")
      setCofinsSDescription("")
      setCofinsSAliquot(0)
      setCofinsSNature("")
    
      setFcp(0)
      setFcpAliquot(0)
    
      setPercentageIbptN(0)
      setPercentageIbptI(0)
      setPercentageIbptE(0)
      setPercentageIbptM(0)
    } 
    else{
      setCodeOrigin("")
      setCode("")
    }

    setUpdateFocu(updateFocu + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const cleanCest = () => {
    setCestCode("")
    setCestDescription("")
    setShowCest(false)
  }

  const cleanCofinsE = () => {
    setCofinsECode("")
    setCofinsEDescription("")
    setShowCofinsE(false)
  }

  const cleanCofinsS = () => {
    setCofinsSCode("")
    setCofinsSDescription("")
    setCofinsSAliquot(0)
    setCofinsSNature("")
    setShowCofinsS(false)
  }

  const cleanIpiS = () => {
    setIpiSCode("")
    setIpiSDescription("")
    setIpiSAliquot(0)
    setShowIpiS(false)
  }

  const cleanPisE = () => {
    setPisECode("")
    setPisEDescription("")
    setShowPisE(false)
  }

  const cleanPisS = () => {
    setPisSCode("")
    setPisSDescription("")
    setPisSAliquot(0)
    setPisSNature("")
    setShowPisS(false)
  }

  const enableCest = () => {
    setShowCest(!showCest)
  }

  const enableCofinsE = () => {
    setShowCofinsE(!showCofinsE)
  }

  const enableCofinsS = () => {
    setShowCofinsS(!showCofinsS)
  }

  const enableIpiS = () => {
    setShowIpiS(!showIpiS)
  }

  const enablePisE = () => {
    setShowPisE(!showPisE)
  }

  const enablePisS = () => {
    setShowPisS(!showPisS)
  }

  const fillCest = (cest) => {
    setCestCode(cest.CODE)
    setCestDescription(cest.DESCRIPTION)
    setShowCest(false)
  }

  const fillCofinsE = (cofins) => {
    setCofinsECode(cofins.CODE)
    setCofinsEDescription(cofins.DESCRIPTION)
    setShowCofinsE(false)
  }

  const fillCofinsS = (cofins) => {
    setCofinsSCode(cofins.CODE)
    setCofinsSDescription(cofins.DESCRIPTION)
    setShowCofinsS(false)
  }

  const fillIpiS = (ipi) => {
    setIpiSCode(ipi.CODE)
    setIpiSDescription(ipi.DESCRIPTION)
    setShowIpiS(false)
  }

  const fillPisE = (pis) => {
    setPisECode(pis.CODE)
    setPisEDescription(pis.DESCRIPTION)
    setShowPisE(false)
  }

  const fillPisS = (pis) => {
    setPisSCode(pis.CODE)
    setPisSDescription(pis.DESCRIPTION)
    setShowPisS(false)
  }    
  
  const handleCofinsSAliquot = (event) => {
    setCofinsSAliquot(formatNumeric(event.target.value, 2))
  }

  const handleFcpAliquot = (event) => {
    setFcpAliquot(formatNumeric(event.target.value, 2))
  }    

  const handleIpiSAliquot = (event) => {
    setIpiSAliquot(formatNumeric(event.target.value, 2))
  }

  const handlePercentageIbptN = (event) => {
    setPercentageIbptN(formatNumeric(event.target.value, 0))
  }

  const handlePercentageIbptI = (event) => {
    setPercentageIbptI(formatNumeric(event.target.value, 0))
  }

  const handlePercentageIbptE = (event) => {
    setPercentageIbptE(formatNumeric(event.target.value, 0))
  }

  const handlePercentageIbptM = (event) => {
    setPercentageIbptM(formatNumeric(event.target.value, 0))
  }

  const handlePisSAliquot = (event) => {
    setPisSAliquot(formatNumeric(event.target.value, 2))
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCodeOrigin("")
    setCode("")

    setShowSearch(true)
  }  

  const save = () => {
    let returnJson = []

    setWarning("")
    setError("")      

    let jsonNcm_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\", \"REGIME\": "+ jsonCompany.REGIME +" }"

    if (code !== "") jsonNcm_New += ", \"CODE\": \"" + code + "\""
    if (description !== "") jsonNcm_New += ", \"DESCRIPTION\": \""+ description + "\""
    if (cestCode !== "") jsonNcm_New += ", \"CEST\": { \"CODE\": \""+ cestCode +"\", \"DESCRIPTION\": \""+ cestDescription +"\" }"
    if (pisECode !== "") jsonNcm_New += ", \"CST_PIS_E\": { \"CODE\": \""+ pisECode +"\", \"DESCRIPTION\": \""+ pisEDescription +"\" }"

    if (cofinsECode !== "") 
      jsonNcm_New += ", \"CST_COFINS_E\": { \"CODE\": \""+ cofinsECode +"\", \"DESCRIPTION\": \""+ cofinsEDescription +"\" }"

    if (ipiSCode !== "") jsonNcm_New += ", \"CST_IPI_S\": { \"CODE\": \""+ ipiSCode +"\", \"DESCRIPTION\": \""+ ipiSDescription +"\" }"
    jsonNcm_New += ", \"IPI_ALIQUOT_S\": "+ ipiSAliquot;

    if (pisSCode !== "") jsonNcm_New += ", \"CST_PIS_S\": { \"CODE\": \""+ pisSCode +"\", \"DESCRIPTION\": \""+ pisSDescription +"\" }"
    jsonNcm_New += ", \"PIS_ALIQUOT_S\": "+ pisSAliquot;
    if (pisSNature !== "") jsonNcm_New += ", \"PIS_NATURE_S\": \""+ pisSNature +"\" "

    if (cofinsSCode !== "") 
      jsonNcm_New += ", \"CST_COFINS_S\": { \"CODE\": \""+ cofinsSCode +"\", \"DESCRIPTION\": \""+ cofinsSDescription +"\" }"

    jsonNcm_New += ", \"COF_ALIQUOT_S\": "+ cofinsSAliquot;
    if (cofinsSNature !== "") jsonNcm_New += ", \"COF_NATURE_S\": \""+ cofinsSNature +"\" "

    jsonNcm_New += ", \"FCP\": "+ fcp;
    jsonNcm_New += ", \"ALIQUOT_FCP\": "+ fcpAliquot;
    jsonNcm_New += ", \"PERCENTAGE_IBPT_N\": "+ percentageIbptN;
    jsonNcm_New += ", \"PERCENTAGE_IBPT_I\": "+ percentageIbptI;
    jsonNcm_New += ", \"PERCENTAGE_IBPT_E\": "+ percentageIbptE;
    jsonNcm_New += ", \"PERCENTAGE_IBPT_M\": "+ percentageIbptM;
    jsonNcm_New += ", \"SITUATION\": "+ situation +" }";

    if (codeOrigin === ""){           
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonNcm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "NCM/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null"+
        ", \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível incluir o NCM. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("ncmDescription") && document.getElementById("ncmDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJNcm(returnJson.OBJECT)
        setCode(returnJson.OBJECT.CODE)
        setCodeOrigin(returnJson.OBJECT.CODE)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })     
    }
    else{
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jNcm))
      formData.append("JSON_NEW_1", jsonNcm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "NCM/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o NCM. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("ncmDescription") && document.getElementById("ncmDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJNcm(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }    

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setSelected = (event) => {
    setShowSearch(false)    
    setJNcm(event)
    setCode(event.CODE)
    setCodeOrigin(event.CODE)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="NCM"
            titleSearch="Pesquisa dos NCMs"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchNcm
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showCest && !showPisE && !showCofinsE && !showIpiS && !showPisS && !showCofinsS &&
                        <>
                          <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={showMenu ?
                                (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={showMenu ?
                                  (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 960) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}
                                  :
                                    (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}}>
                                  <Input
                                    type="text"
                                    name="ncmCode"
                                    description="Código"
                                    value={code}
                                    maxLength={12}
                                    disabled={ (codeOrigin === "") ? false : true }
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCode(event.target.value)} />
                                </div> 
                                <div style={showMenu ?
                                    (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="ncmDescription"
                                    description="Descrição"
                                    value={description}
                                    maxLength={255}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 1140) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                :
                                  (width < 840) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                <Select
                                  name="ncmSituation"
                                  description="Situação"
                                  value={situation || 0}
                                  require={false}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setSituation} />
                              </div>
                            </div> 

                            <div style={{marginTop: 10}}>
                              <Titulo description="CEST" />
                            </div>

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :                      
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    :   
                                      (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="ncmCestCode"
                                      description="Código"
                                      value={cestCode}
                                      maxLength={10}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCestCode(event.target.value) } />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="ncmCestDescription"
                                      description="Descrição"
                                      value={cestDescription}
                                      maxLength={255}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCestDescription(event.target.value) } />
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                  :
                                    (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                  <img 
                                    style={{height: 42, width: 42}}
                                    name="ncmSearchCest"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={enableCest} />

                                  <img 
                                    style={{marginLeft: 10, height: 42, width: 42}}
                                    name="ncmCleanCest"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanCest} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="Entrada" />
                            </div>

                            <div style={showMenu ?
                                (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :   
                                (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                <b>PIS:</b>
                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :                      
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :   
                                        (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="ncmPisECode"
                                        description="Código"
                                        value={pisECode}
                                        maxLength={2}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setPisECode(event.target.value) } />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="ncmPisEDescription"
                                        description="Descrição"
                                        value={pisEDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setPisEDescription(event.target.value) } />
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="ncmSearchPisE"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enablePisE} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="ncmCleanPisE"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanPisE} />
                                  </div>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                    paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                :   
                                  (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                    paddingRight: 10, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                <b>COFINS:</b>
                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :                      
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :   
                                        (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="ncmCofinsECode"
                                        description="Código"
                                        value={cofinsECode}
                                        maxLength={2}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCofinsECode(event.target.value) } />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="ncmCofinsEDescription"
                                        description="Descrição"
                                        value={cofinsEDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCofinsEDescription(event.target.value) } />
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="ncmSearchCofinsE"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableCofinsE} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="ncmCleanCofinsE"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanCofinsE} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="Saída" />
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                <b>IPI:</b>

                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={showMenu ?
                                      (width < 1300) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :   
                                      (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={showMenu ?
                                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={showMenu ?
                                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :   
                                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmIpiSCode"
                                          description="Código"
                                          value={ipiSCode}
                                          maxLength={2}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setIpiSCode(event.target.value) } />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ipiSDescription"
                                          description="Descrição"
                                          value={ipiSDescription}
                                          maxLength={255}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setIpiSDescription(event.target.value) } />
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 1300) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :   
                                        (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="ncmIpiSAliquot"
                                        description="Alíquota"
                                        value={ipiSAliquot}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleIpiSAliquot} />
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="ncmSearchIpiS"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableIpiS} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="ncmCleanIpiS"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanIpiS} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                <b>PIS:</b>

                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 1490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :   
                                      (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 1230) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={showMenu ?
                                          (width < 1230) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmPisSCode"
                                          description="Código"
                                          value={pisSCode}
                                          maxLength={2}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPisSCode(event.target.value)} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1230) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmPisSDescription"
                                          description="Descrição"
                                          value={pisSDescription}
                                          maxLength={255}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPisSDescription(event.target.value)} />
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 1490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 1290) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="ncmPisSAliquot"
                                          description="Alíquota"
                                          value={pisSAliquot}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handlePisSAliquot} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1490) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmPisSNature"
                                          description="Enquadramento"
                                          value={pisSNature}
                                          maxLength={3}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPisSNature(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="ncmSearchPisS"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enablePisS} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="ncmCleanPisS"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanPisS} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                <b>COFINS:</b>

                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 1490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :   
                                      (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 1230) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={showMenu ?
                                          (width < 1230) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmCofinsSCode"
                                          description="Código"
                                          value={cofinsSCode}
                                          maxLength={2}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCofinsSCode(event.target.value)} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 1230) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 1030) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmCofinsSDescription"
                                          description="Descrição"
                                          value={cofinsSDescription}
                                          maxLength={255}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCofinsSDescription(event.target.value)} />
                                      </div>                          
                                    </div>
                                    <div style={showMenu ?
                                        (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 1490) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 1490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 1290) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="ncmCofinsSAliquot"
                                          description="Alíquota"
                                          value={cofinsSAliquot}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleCofinsSAliquot} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1490) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1290) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="ncmCofinsSNature"
                                          description="Enquadramento"
                                          value={cofinsSNature}
                                          maxLength={3}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setCofinsSNature(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="ncmSearchCofinsS"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableCofinsS} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="ncmCleanCofinsS"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanCofinsS} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="FCP" />
                            </div>                            

                            <div style={showMenu ?
                                {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                              :
                                {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>

                              <div style={showMenu ?
                                  {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                :
                                  {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                <Select
                                  name="ncmFcp"
                                  description="Situação"
                                  value={fcp || 0}
                                  require={false}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  set={setFcp} />                                    
                              </div>

                              <div style={showMenu ?
                                  {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                :
                                  {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                <InputNumeric
                                  type="text"
                                  name="ncmFcpAliquot"
                                  description="Alíquota"
                                  value={fcpAliquot}
                                  maxLength={13}
                                  disabled={false}
                                  require={true}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handleFcpAliquot} />
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="IBPT" />
                            </div>

                            <div style={showMenu ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                  paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                              :
                                (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10,
                                  paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>
                              <div style={showMenu ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  :
                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="ncmPercentageIbptN"
                                    description="Nacional"
                                    value={percentageIbptN}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handlePercentageIbptN} />
                                </div>
                                <div style={showMenu ?
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="ncmPercentageIbptI"
                                    description="Interestadual"
                                    value={percentageIbptI}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handlePercentageIbptI} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={showMenu ?
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                    : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1090) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="ncmPercentageIbptE"
                                    description="Estadual"
                                    value={percentageIbptE}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handlePercentageIbptE} />
                                </div>
                                <div style={showMenu ?
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10,
                                      minWidth: 180}
                                    : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1090) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10,
                                      minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, marginLeft: 10,
                                      minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="ncmPercentageIbptM"
                                    description="Municipal"
                                    value={percentageIbptM}
                                    maxLength={13}
                                    disabled={false}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handlePercentageIbptM} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }

                      { showCest &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o CEST:</b>
                          </div>

                          <SearchCest
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillCest} 
                            setReturn={() => setShowCest(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showCofinsE &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o COFINS de entrada:</b>
                          </div>

                          <SearchCofins
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillCofinsE} 
                            setReturn={() => setShowCofinsE(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showCofinsS &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o COFINS de saída:</b>
                          </div>

                          <SearchCofins
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillCofinsS} 
                            setReturn={() => setShowCofinsS(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showIpiS &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o IPI de saída:</b>
                          </div>

                          <SearchIpi
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillIpiS} 
                            setReturn={() => setShowIpiS(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showPisE &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o PIS de entrada:</b>
                          </div>

                          <SearchPis
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillPisE} 
                            setReturn={() => setShowPisE(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showPisS &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o PIS de saída:</b>
                          </div>

                          <SearchPis
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillPisS} 
                            setReturn={() => setShowPisS(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Ncm