import React, { useState, useEffect } from "react"

const AccessControlTreasury = ({permissionAccount, permissionAccountGroup, permissionAccountPlan, permissionAccountTransfer, permissionAgency,
  permissionBank, permissionReportAccountBalance, permissionReportAccountExtract, setAccount, setAccountGroup, setAccountPlan,
  setAccountTransfer, setAgency, setBank, setReportAccountBalance, setReportAccountExtract, showMenu, width}) => {

  const [accountF, setAccountF] = useState(1)
  const [accountGroupF, setAccountGroupF] = useState(1)  
  const [accountPlanF, setAccountPlanF] = useState(1)
  const [accountTransferF, setAccountTransferF] = useState(1)
  const [agencyF, setAgencyF] = useState(1)
  const [bankF, setBankF] = useState(1)  
  const [reportAccountBalanceF, setReportAccountBalanceF] = useState(1)
  const [reportAccountExtractF, setReportAccountExtractF] = useState(1)

  const handleAccount = () => {
    setAccountF(2)
    setAccount("F")
  }

  const handleAccountC = () => {
    setAccountF(1)
    setAccount("T")
  }

  const handleAccountGroup = () => {
    setAccountGroupF(2)
    setAccountGroup("F")
  }

  const handleAccountGroupC = () => {
    setAccountGroupF(1)
    setAccountGroup("T")
  }

  const handleAccountPlan = () => {
    setAccountPlanF(2)
    setAccountPlan("F")
  }

  const handleAccountPlanC = () => {
    setAccountPlanF(1)
    setAccountPlan("T")
  }

  const handleAccountTransfer = () => {
    setAccountTransferF(2)
    setAccountTransfer("F")
  }

  const handleAccountTransferC = () => {
    setAccountTransferF(1)
    setAccountTransfer("T")
  }

  const handleAgency = () => {
    setAgencyF(2)
    setAgency("F")
  }

  const handleAgencyC = () => {
    setAgency(1)
    setAgency("T")
  }

  const handleBank = () => {
    setBankF(2)
    setBank("F")
  }

  const handleBankC = () => {
    setBankF(1)
    setBank("T")
  }

  const handleReportAccountBalance = () => {
    setReportAccountBalanceF(2)
    setReportAccountBalance("F")
  }

  const handleReportAccountBalanceC = () => {
    setReportAccountBalanceF(1)
    setReportAccountBalance("T")
  }

  const handleReportAccountExtract = () => {
    setReportAccountExtractF(2)
    setReportAccountExtract("F")
  }

  const handleReportAccountExtractC = () => {
    setReportAccountExtractF(1)
    setReportAccountExtract("T")
  }

  useEffect(() => {
    if (permissionBank === "T") setBankF(1)
    else setBankF(2)

    if (permissionAgency === "T") setAgencyF(1)
    else setAgencyF(2)

    if (permissionAccount === "T") setAccountF(1)
    else setAccountF(2)

    if (permissionAccountGroup === "T") setAccountGroupF(1)
    else setAccountGroupF(2)

    if (permissionAccountPlan === "T") setAccountPlanF(1)
    else setAccountPlanF(2)

    if (permissionAccountTransfer === "T") setAccountTransferF(1)
    else setAccountTransferF(2)

    if (permissionReportAccountBalance === "T") setReportAccountBalanceF(1)
    else setReportAccountBalanceF(2)

    if (permissionReportAccountExtract === "T") setReportAccountExtractF(1)
    else setReportAccountExtractF(2)

  }, [permissionAccount, permissionAccountGroup, permissionAccountPlan, permissionAccountTransfer, permissionAgency, permissionBank,
    permissionReportAccountBalance, permissionReportAccountExtract])

  return(
    <div style={{marginTop: 10}}>
      <h3>Tesouraria</h3>
      <hr/>

      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Bank */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Banco</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBank"
                checked={(bankF === 1) ? true : false}
                onChange={(bankF === 1) ? handleBank : handleBankC} />
            </div>
          </div>
        </div>
      </div>

      {/* Agency */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Agência</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAgency"
                checked={(agencyF === 1) ? true : false}
                onChange={(agencyF === 1) ? handleAgency : handleAgencyC} />
            </div>
          </div>
        </div>
      </div>

      {/* Account */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Conta</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAccount"
                checked={(accountF === 1) ? true : false}
                onChange={(accountF === 1) ? handleAccount : handleAccountC} />
            </div>
          </div>
        </div>
      </div>

      {/* Account Group */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Grupo Conta</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAccountGroup"
                checked={(accountGroupF === 1) ? true : false}
                onChange={(accountGroupF === 1) ? handleAccountGroup : handleAccountGroupC} />
            </div>
          </div>
        </div>
      </div>

      {/* Account Plan */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Plano Conta</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAccountPlan"
                checked={(accountPlanF === 1) ? true : false}
                onChange={(accountPlanF === 1) ? handleAccountPlan : handleAccountPlanC} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 2}}>
        <h4>Processo</h4>
      </div>

      {/* Account Transfer */}          
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Transferência entre Contas</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAccountTransfer"
                checked={(accountTransferF === 1) ? true : false}
                onChange={(accountTransferF === 1) ? handleAccountTransfer : handleAccountTransferC} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 2}}>
        <h4>Relatório</h4>
      </div>

      {/* Account Extract */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Extrato das Contas</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlReportAccountExtract"
                checked={(reportAccountExtractF === 1) ? true : false}
                onChange={(reportAccountExtractF === 1) ? handleReportAccountExtract : handleReportAccountExtractC} />
            </div>
          </div>
        </div>
      </div>

      {/* Account Balance */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Saldo das Contas</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlReportAccountBalance"
                checked={(reportAccountBalanceF === 1) ? true : false}
                onChange={(reportAccountBalanceF === 1) ? handleReportAccountBalance : handleReportAccountBalanceC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlTreasury