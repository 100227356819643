import React from "react";
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"

const Header = ({title, titleSearch, showSearch, showMenu, setShowMenu, width}) => {  
  return(
    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", paddingTop: 15, paddingBottom: 15}}>
      { showMenu ?
          <div style={(width < 520) ?
              {display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", paddingLeft: 10, paddingRight: 10}
            :             
              {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", paddingLeft: 10,
              paddingRight: 15}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5,
              paddingRight: 5, marginTop: 5}}>
                <label style={                  
                  (width < 520) ? {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"} 
                  : (width < 690) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                  : ( width < 760) ? {color: "white", fontSize: 10, fontWeight: 600, textAlign: "center"} 
                  : (width < 910) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                  : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}> {(showSearch) ? titleSearch : title}</label>
            </div>

            { (width < 520) ?
                <div style={{marginTop: 10}}>
                  <img src={image_multiplaWhite} 
                    style={{ height: "auto", width: 150 }}
                    alt="Logomarca" />
                </div>
              :
                <img src={image_multiplaWhite} 
                  style={{ height: "auto", width: 150 }}
                  alt="Logomarca" />
            }
          </div>
        :
          <div style={(width < 560) ?
              {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
            : 
              {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center",
              paddingLeft: 10, paddingRight: 15}}>

            <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "flex-start", alignItems: "center",
                paddingLeft: 10, paddingRight: 10}}>
                  <img src={image_menu}
                  style={(width < 360) ? { height: "auto", width: 30 } : { height: "auto", width: 35 }}
                  alt="Retrair"
                  onClick={(() => { setShowMenu(!showMenu) })} />
              </div>

              <div style={(width < 420) ? 
                  {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5, marginTop: 15}
                : 
                  {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5, marginTop: 5}}>
                <label style={
                  
                  (width < 420) ? {color: "white", fontSize: 16, fontWeight: 600, textAlign: "center"} 
                  : (width < 760) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                  : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}> {(showSearch) ? titleSearch : title}</label>
              </div>
            </div>

            { (width < 560) ?
                <div style={{display: "flex", flex: 1, marginTop: 10, justifyContent: "center"}}>
                  <img src={image_multiplaWhite} 
                    style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                    alt="Logomarca" />
                </div>
              :
                <img src={image_multiplaWhite} 
                  style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                  alt="Logomarca" />
            }
          </div>
      }
    </div>
  )
}

export default Header