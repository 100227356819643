import React from "react";
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_import from "../../Images/Buttons/import.png"
import image_previous from "../../Images/Buttons/previous.png"

const SearchButtons = ({modeSearch, jsonCompany, setAdd, clean, getSGE, setReturn}) => {  
  return(
    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
      { !modeSearch &&
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
          width: 147}} onClick={() => setAdd()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
          </div>
        </div>
      }

      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
        width: 147}} onClick={clean}>
        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
          paddingBottom: 5, paddingLeft: 10}}>
          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
        </div>
        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
        </div>
      </div>

      { !modeSearch && (jsonCompany.INTEGRATE_SGE === 1) &&
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
          width: 147}} onClick={() => getSGE()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_import} alt="Importar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Importar</label>
          </div>
        </div>
      }

      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
        width: 147}} onClick={setReturn}>
        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
          paddingBottom: 5, paddingLeft: 10}}>
          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
        </div>
        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
        </div>
      </div>
    </div>
  )
}

export default SearchButtons