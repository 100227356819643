import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

////
// process: 1 - Parent // Matriz 2 - Bill Pay // Conta Pagar
////
const SearchSupplier = ({modeSearch, process, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState("")
  const [cpfCnpj, setCpfCnpj] = useState("")
  const [corporateName, setCorporateName] = useState("")  

  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})
    
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  const [errorImport, setErrorImport] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    if (process === 1){
      let jSupplier = "{ "
      if (cpfCnpj !== "") {
        jSupplier += "\"CPF_CNPJ\": \""+ cpfCnpj + "\""
        if ((cpfCnpj.length === 11) || (cpfCnpj.length === 14)) search = true
      }      
      if (corporateName !== ""){
        if (jSupplier !== "{ ") jSupplier += ", "
        jSupplier += "\"CORPORATE_NAME\": \""+ corporateName + "\""
        if (corporateName.length > 2) search = true
      }
      jSupplier += " }"

      if (search) {
        setLoading("Aguarde buscando as informações...")
        setWarning("")
        setError("")
  
        let formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jSupplier)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
  
        axios.post(BASE_URL + "SUPPLIER/TotalRecords", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar o total de Fornecedores. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }
  
            return
          }
  
          const tRecord = returnJson.OBJECT
          if (tRecord === 0)
          {
            setWarning("Nenhum registro encontrado.")
            setShowResult(false)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }
  
            return
          }
  
          const operation = tRecord / recordPage
          const numberR = Math.floor(operation)
          const decimal = operation % 1
  
          let tPage = 0
          if (decimal > 0) tPage = numberR + 1
          else tPage = numberR
  
          if (tRecord > 600)
            setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
              ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")
  
          setTotalRecord(tRecord)
          setTotalPage(tPage)
          if (tPage < pageCurrent) setPageCurrent(1)
  
          returnJson.WARNING && setWarning(returnJson.WARNING)
        
          let qtdeInitial = 0
          if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)
  
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", jSupplier)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", qtdeInitial)
          formData.append("QUANTITY_RECORDS", recordPage)
  
          axios.post(BASE_URL + "SUPPLIER/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar os Fornecedores. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setError(returnJson.ERROR)
              setShowResult(false)
              setLoading("")
  
              if (fieldSelected === "searchSupplierCorporateName") {
                document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
              }
              else {
                document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
              }
  
              return
            }
              
            setResult(returnJson)
            setShowResult(true)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }  
          })
        })
      }
      else
      {
        setLoading("")
        setWarning("")
        setError("")
        setShowResult(false)
        
        if (fieldSelected === "searchSupplierCorporateName") {
          document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
        }
        else {
          document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
        }
      }
    } else if (process === 2){     
      let jSupplier = "{ "
      jSupplier += " \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"
      if ((cpfCnpj !== "") || (corporateName !== "")) jSupplier += " \"SUPPLIER\": {"
      if (cpfCnpj !== ""){
        jSupplier += " \"CPF_CNPJ\": \""+ cpfCnpj + "\""
        if ((cpfCnpj.length === 11) || (cpfCnpj.length === 14)) search = true
      }      
      if (corporateName !== ""){
        if (cpfCnpj !== "") jSupplier += ", \"CORPORATE_NAME\": \""+ corporateName + "\""
        else jSupplier += " \"CORPORATE_NAME\": \""+ corporateName + "\""
        if (corporateName.length > 2) search = true
      }
      if ((cpfCnpj !== "") || (corporateName !== "")) jSupplier += " }"
      jSupplier += " }"
    
      if (search) {
        setLoading("Aguarde buscando as informações...")
        setWarning("")
        setError("")
  
        let formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jSupplier)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
  
        axios.post(BASE_URL + "BILL_PAY/TotalRecords", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar o total das contas pagar. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }
  
            return
          }
  
          const tRecord = returnJson.OBJECT
          if (tRecord === 0)
          {
            setWarning("Nenhum registro encontrado.")
            setShowResult(false)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }
  
            return
          }
  
          const operation = tRecord / recordPage
          const numberR = Math.floor(operation)
          const decimal = operation % 1
  
          let tPage = 0
          if (decimal > 0) tPage = numberR + 1
          else tPage = numberR
  
          if (tRecord > 600)
            setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
              ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")
  
          setTotalRecord(tRecord)
          setTotalPage(tPage)
          if (tPage < pageCurrent) setPageCurrent(1)
  
          returnJson.WARNING && setWarning(returnJson.WARNING)
        
          let qtdeInitial = 0
          if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)
  
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", jSupplier)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", qtdeInitial)
          formData.append("QUANTITY_RECORDS", recordPage)

          axios.post(BASE_URL + "BILL_PAY/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar as contas a pagar. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setError(returnJson.ERROR)
              setShowResult(false)
              setLoading("")
  
              if (fieldSelected === "searchSupplierCorporateName") {
                document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
              }
              else {
                document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
              }
  
              return
            }

            setResult(returnJson)
            setShowResult(true)
            setLoading("")
  
            if (fieldSelected === "searchSupplierCorporateName") {
              document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
            }
            else {
              document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
            }  
          })
        })
      }
      else
      {
        setLoading("")
        setWarning("")
        setError("")
        setShowResult(false)
        
        if (fieldSelected === "searchSupplierCorporateName") {
          document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
        }
        else {
          document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
        }
      }
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, pageCurrent, recordPage, process, cpfCnpj, corporateName, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o fornecedor (" + item.CORPORATE_NAME + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setCode("")
    setCpfCnpj(0)
    setCorporateName("")

    setErrorImport("")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jSupplier = "{ \"ID\": "+ code + " }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jSupplier)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SUPPLIER/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o fornecedor. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchSupplierCorporateName") {
          document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
        }
        else {
          document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
        }

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SUPPLIER/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os fornecedores. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchSupplierCorporateName") {
          document.getElementById("searchSupplierCorporateName") && document.getElementById("searchSupplierCorporateName").focus()
        }
        else {
          document.getElementById("searchSupplierCpfCnpj") && document.getElementById("searchSupplierCpfCnpj").focus()
        }

        return
      }

      returnJson.WARNING && setErrorImport(returnJson.WARNING)        

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean}
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    { errorImport &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{errorImport}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={(showMenu) ? 
                          (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        : 
                          (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                        <div style={(showMenu) ? 
                            (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                            : (width < 960) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                          : 
                            (width < 680) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>

                          <Input
                            type="search"
                            name="searchSupplierCpfCnpj"
                            description="CPF/CNPJ"
                            planceHolder=""
                            value={cpfCnpj}
                            maxLength={14}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setCpfCnpj(event.target.value)} />
                        </div>
                        <div style={(showMenu) ? 
                            (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            : (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                          : 
                            (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                          <Input
                            type="search"
                            name="searchSupplierCorporateName"
                            description="Nome/Razão social"
                            planceHolder="Mínimo 3 caracteres"
                            value={corporateName}
                            maxLength={60}
                            disabled={false}
                            require={false}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setCorporateName(event.target.value)} />
                        </div>
                      </div>

                      { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 15}}>
                                <div style={showMenu ? 
                                    (width < 1400) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                  : 
                                    (width < 1200) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                    : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                  <div style={{display: "flex", flexGrow: 0, minWidth : 200, maxWidth: 200}}>
                                    <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                                  </div>
                                  <div style={(showMenu) ? 
                                      (width < 1200) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                    :
                                      (width < 1000) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                      : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                    <div style={(showMenu) ? 
                                        (width < 1400) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10}
                                      : 
                                        (width < 1200) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10}}>
                                        
                                      <label style={{fontWeight: 600}}>Razão Social</label>
                                    </div>

                                    <div style={(showMenu) ? 
                                        (width < 1200) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10} 
                                      : 
                                        (width < 1000) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                                      <label style={{fontWeight: 600}}>Nome Fantasia</label>
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ? 
                                    (width < 390) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                  : 
                                    (width < 500) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>

                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 5, height: 32, width: 32}}/>

                                  <div style={(modeSearch) ? {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5,
                                      paddingBottom: 5, paddingLeft: 0}
                                    : {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 0,
                                      marginTop: 2, height: 28, width: 2}} />
                                </div>
                              </div>
                              <hr/>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={(showMenu) ?
                                  (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                : 
                                  (width < 268) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                  : (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4}}>

                                  <div style={(showMenu) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                    <div style={ showMenu ? 
                                        (width < 1400) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                      : 
                                        (width < 1200) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                      <div style={{display: "flex", flexGrow: 0, minWidth : 200, maxWidth: 200}}>
                                        <label>{item.CPF_CNPJ}</label>
                                      </div>

                                      <div style={(showMenu) ? 
                                          (width < 1200) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        : 
                                          (width < 1000) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                          : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                                        <div style={(showMenu) ? 
                                            (width < 1400) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                            : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                                          : 
                                            (width < 1200) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                            : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>
                                              
                                          <label>{item.CORPORATE_NAME}</label>
                                        </div>
                                        
                                        <div style={(showMenu) ?
                                            (width < 1200) ? {display: "flex", flexGrow: 1, flexBasis: 1}
                                            : (width < 1400) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: -20}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: -10} 
                                          : 
                                            (width < 1000) ? {display: "flex", flexGrow: 1, flexBasis: 1}
                                            : (width < 1200) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: -20}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: -10}}>

                                          <label>{item.FANTASY_NAME}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>                                  

                                  <div style={showMenu ?
                                      (width < 390) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                    :
                                      (width < 500) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                        
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      <img
                                        style={{height: 32, width: 32}}
                                        name="searchSupplierSelected"
                                        src={image_select}
                                        alt="Selecionar"
                                        onClick={(() => setSelected(item))} />
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      { !modeSearch &&
                                        <img 
                                          style={{height: 28, width: 28, marginTop: 2}}
                                          name="searchSupplierDelete"
                                          src={image_delete}
                                          alt="Deletar"
                                          onClick={() => callDelete(item)} />
                                      }
                                    </div>
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination 
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchSupplier