import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchFinisher from "../Finisher/Search"
import SearchPaymentForm from "./Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const PaymentForm = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")

  const [finisherCode, setFinisherCode] = useState("")
  const [finisherDescription, setFinisherDescription] = useState("")

  const [termReceive, setTermReceive] = useState("")
  const [assessmentReceive, setAssessmentReceive] = useState("")
  const [taxReceive, setTaxReceive] = useState("")

  const [termPay, setTermPay] = useState("")
  const [assessmentPay, setAssessmentPay] = useState("")
  const [taxPay, setTaxPay] = useState("")

  const [listAccountPlanCredit, setListAccountPlanCredit] = useState([])
  const [accountPlanCreditCode, setAccountPlanCreditCode] = useState("")
  const [accountPlanCreditDescription, setAccountPlanCreditDescription] = useState("")

  const [listAccountPlanDebit, setListAccountPlanDebit] = useState([])
  const [accountPlanDebitCode, setAccountPlanDebitCode] = useState("")
  const [accountPlanDebitDescription, setAccountPlanDebitDescription] = useState("")

  const [appearOrder, setAppearOrder] = useState(0)
  const [defaultOrder, setDefaultOrder] = useState(0)
  const [sge, setSge] = useState("")

  const [jPaymentForm, setJPaymentForm] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showFinisher, setShowFinisher] = useState(false)

  // Update Reccords // Atualizar registros
  const [optionsAccountPlanCredit, setOptionsAccountPlanCredit] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsAccountPlanDebit, setOptionsAccountPlanDebit] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [firstAcess, setFirstAcess] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    let returnJson = []

    if (firstAcess){
      ////
      // Upload information of account plan (Credit) // Atualiza as informações do plano de conta (Crédito)
      ////
      setLoading("Aguarde buscando as informações dos planos de contas (Crédito)...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 1, \"PERIODIC\": 1 }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ACCOUNT_PLAN/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de plano de contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)

          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        let tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setLoading("")

          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 1, \"PERIODIC\": 1 }")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", tRecord)

        axios.post(BASE_URL + "ACCOUNT_PLAN/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os planos de contas. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setLoading("")
            setWarning("")
            setError(returnJson.ERROR)

            document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
            return
          }

          let accountPlanCredit = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson && returnJson.OBJECT.map(item => (
            accountPlanCredit += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
          ))
          accountPlanCredit += "]}"

          setListAccountPlanCredit(returnJson.OBJECT)
          setOptionsAccountPlanCredit(accountPlanCredit)
          setLoading("")
        })
      })

      ////
      // Upload information of account plan (Debit) // Atualiza as informações do plano de conta (Débito)
      ////
      setLoading("Aguarde buscando as informações dos planos de contas (Débito)...")
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 2, \"PERIODIC\": 1 }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ACCOUNT_PLAN/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de plano de contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)

          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        let tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setLoading("")

          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"TYPE\": 2, \"PERIODIC\": 1 }")
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", tRecord)

        axios.post(BASE_URL + "ACCOUNT_PLAN/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os planos de contas. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setLoading("")
            setWarning("")
            setError(returnJson.ERROR)

            document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
            return
          }          

          let accountPlanDebit = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson && returnJson.OBJECT.map(item => (
            accountPlanDebit += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
          ))
          accountPlanDebit += "]}"

          setListAccountPlanDebit(returnJson.OBJECT)
          setOptionsAccountPlanDebit(accountPlanDebit)
          setLoading("")
        })
      })

      setFirstAcess(false)
    }

    if (code === "") {
      setDescription("")
    
      setFinisherCode("")
      setFinisherDescription("")
    
      setTermReceive("")
      setAssessmentReceive("")
      setTaxReceive("")
    
      setTermPay("")
      setAssessmentPay("")
      setTaxPay("")
   
      setAccountPlanCreditCode("")
      setAccountPlanCreditDescription("")
    
      setAccountPlanDebitCode("")
      setAccountPlanDebitDescription("")
    
      setAppearOrder(0)
      setDefaultOrder(0)
      setSge("")
    }
    else {
      ////
      // Múltipla, Geral, Detetização, Licitação, Feira ou Lene
      ////
      if ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) || 
      (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)){

        setCode(jPaymentForm.ID)
        setDescription(jPaymentForm.DESCRIPTION)

        ////
        // Finisher // Finalizadora
        ////
        setFinisherCode(jPaymentForm.FINISHER.ID)
        setFinisherDescription(jPaymentForm.FINISHER.DESCRIPTION)
  
        setTermReceive(jPaymentForm.TERM_RECEIVE)
        setAssessmentReceive(jPaymentForm.APPORTIONMENT_RECEIVE)
        setTaxReceive(jPaymentForm.TAX_RECEIVE)

        setAccountPlanCreditCode(jPaymentForm.ACCOUNT_PLAN_RECEIVE.ID)
        setAccountPlanCreditDescription(jPaymentForm.ACCOUNT_PLAN_RECEIVE.DESCRIPTION)
  
        setAccountPlanDebitCode(jPaymentForm.ACCOUNT_PLAN_PAY.ID)
        setAccountPlanDebitDescription(jPaymentForm.ACCOUNT_PLAN_PAY.DESCRIPTION)
  
        setAppearOrder(jPaymentForm.APARECE_PEDIDO)
        setDefaultOrder(jPaymentForm.PADRAO_PEDIDO)

        if ((jPaymentForm.SGE !== null) && (jPaymentForm.SGE !== undefined))  setSge(jPaymentForm.SGE)
      }

      // Jm3d
      if (jsonCompany.TYPE === 7){
        setCode(jPaymentForm.ID)
        setDescription(jPaymentForm.DESCRIPTION)

        ////
        // Finisher // Finalizadora
        ////
        setFinisherCode(jPaymentForm.FINISHER.ID)
        setFinisherDescription(jPaymentForm.FINISHER.DESCRIPTION)
  
        setTermReceive(jPaymentForm.TERM_RECEIVE)
        setAssessmentReceive(jPaymentForm.APPORTIONMENT_RECEIVE)
        setTaxReceive(jPaymentForm.TAX_RECEIVE)

        if ((jPaymentForm.TERM_PAY !== null) && (jPaymentForm.TERM_PAY !== undefined))
          setTermPay(jPaymentForm.TERM_PAY); 
        else setTermPay("")

        if ((jPaymentForm.APPORTIONMENT_PAY !== null) && (jPaymentForm.APPORTIONMENT_PAY !== undefined))
          setAssessmentPay(jPaymentForm.APPORTIONMENT_PAY);
        else setAssessmentPay("")

        if ((jPaymentForm.TAX_PAY !== null) && (jPaymentForm.TAX_PAY !== undefined))
          setTaxPay(jPaymentForm.TAX_PAY); 
        else setTaxPay("")

        setAccountPlanCreditCode(jPaymentForm.ACCOUNT_PLAN_RECEIVE.ID)
        setAccountPlanCreditDescription(jPaymentForm.ACCOUNT_PLAN_RECEIVE.DESCRIPTION)

        setAccountPlanDebitCode(jPaymentForm.ACCOUNT_PLAN_PAY.ID)
        setAccountPlanDebitDescription(jPaymentForm.ACCOUNT_PLAN_PAY.DESCRIPTION)

        setAppearOrder(jPaymentForm.APARECE_PEDIDO)
        setDefaultOrder(jPaymentForm.PADRAO_PEDIDO)

        if ((jPaymentForm.SGE !== null) && (jPaymentForm.SGE !== undefined))  setSge(jPaymentForm.SGE)
      }
    }

    document.getElementById("description_paymentForm") && document.getElementById("description_paymentForm").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, firstAcess, system, code, jPaymentForm, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setDescription("")
    
      setFinisherCode("")
      setFinisherDescription("")
    
      setTermReceive("")
      setAssessmentReceive("")
      setTaxReceive("")
    
      setTermPay("")
      setAssessmentPay("")
      setTaxPay("")
   
      setAccountPlanCreditCode("")
      setAccountPlanCreditDescription("")      
    
      setAccountPlanDebitCode("")
      setAccountPlanDebitDescription("")
    
      setAppearOrder(0)
      setDefaultOrder(0)
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const cleanFinisher = () => {
    setFinisherCode("")
    setFinisherDescription("")
    setShowFinisher(false)
  }

  const enableFinisher = () => {
    setShowFinisher(!showFinisher)
  }

  const fillFinisher = (finisher) => {
    setFinisherCode(finisher.ID)
    setFinisherDescription(finisher.DESCRIPTION) 
    setShowFinisher(false)
  }

  const returnAddGeral = () => {
    setWarning("")
    setError("")
    setCode("")
    setShowSearch(true)
  }

  const returnAddJm3d = () => {
    setWarning("")
    setError("")
    setCode("")
    setShowSearch(true)
  }

  const saveGeral = () => {
    let returnJson = []
    let jsonPaymentForm_New = {}
   
    jsonPaymentForm_New = "{ \"FINISHER\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((finisherCode !== undefined) && (finisherCode !== null)) && (finisherCode !== "")){
      jsonPaymentForm_New += ", \"ID\": "+ finisherCode
      jsonPaymentForm_New += ", \"DESCRIPTION\": \""+ finisherDescription + "\""
    }
    jsonPaymentForm_New += " }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonPaymentForm_New += ", \"ID\": \""+ code + "\""
    jsonPaymentForm_New += ", \"DESCRIPTION\": \""+ description + "\""

    if (((termReceive !== undefined) && (termReceive !== null)) && (termReceive !== ""))
      jsonPaymentForm_New += ", \"TERM_RECEIVE\": \""+ termReceive + "\""

    if (((assessmentReceive !== undefined) && (assessmentReceive !== null)) && (assessmentReceive !== ""))
      jsonPaymentForm_New += ", \"APPORTIONMENT_RECEIVE\": \""+ assessmentReceive + "\""

    if (((taxReceive !== undefined) && (taxReceive !== null)) && (taxReceive !== ""))
      jsonPaymentForm_New += ", \"TAX_RECEIVE\": \""+ taxReceive + "\""

    if (((accountPlanCreditCode !== undefined) && (accountPlanCreditCode !== null)) && (accountPlanCreditCode !== "")){
      jsonPaymentForm_New += ", \"ACCOUNT_PLAN_RECEIVE\": { \"ID\": "+ accountPlanCreditCode +
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"+
      ", \"DESCRIPTION\": \""+ accountPlanCreditDescription +"\" }"
    }

    if (((accountPlanDebitCode !== undefined) && (accountPlanDebitCode !== null)) && (accountPlanDebitCode !== "")){
      jsonPaymentForm_New += ", \"ACCOUNT_PLAN_PAY\": { \"ID\": "+ accountPlanDebitCode +
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"+      
      ", \"DESCRIPTION\": \""+ accountPlanDebitDescription +"\" }"
    }      

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonPaymentForm_New += ", \"SGE\": "+ sge

    if (jsonCompany.TYPE === 1) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": 2";
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": 2 }";
    } else if (jsonCompany.TYPE === 2) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": "+ appearOrder;
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": "+ defaultOrder + " }";
    } else if (jsonCompany.TYPE === 3) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": 2";
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": 2 }";
    } else if (jsonCompany.TYPE === 4) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": "+ appearOrder;
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": "+ defaultOrder + " }";
    } else if (jsonCompany.TYPE === 5) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": 2";
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": 2 }";
    } else if (jsonCompany.TYPE === 6) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": "+ appearOrder;
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": "+ defaultOrder + " }";
    } else if (jsonCompany.TYPE === 7) {
      jsonPaymentForm_New += ", \"SHOW_ORDER\": 2";
      jsonPaymentForm_New += ", \"DEFAULT_ORDER\": 2 }";
    }    

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonPaymentForm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PAYMENT_FORM/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a forma de pagamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJPaymentForm(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jPaymentForm))
      formData.append("JSON_NEW_1", jsonPaymentForm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "PAYMENT_FORM/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a forma de pagamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJPaymentForm(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const saveJm3d = () => {
    let returnJson = []
    let jsonPaymentForm_New = {}

    jsonPaymentForm_New = "{ \"FINISHER\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((finisherCode !== undefined) && (finisherCode !== null)) && (finisherCode !== "")){
      jsonPaymentForm_New += ", \"ID\": "+ finisherCode
      jsonPaymentForm_New += ", \"DESCRIPTION\": \""+ finisherDescription + "\""
    }
    jsonPaymentForm_New += " }"

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonPaymentForm_New += ", \"ID\": \""+ code + "\""
    jsonPaymentForm_New += ", \"DESCRIPTION\": \""+ description + "\""

    if (((termReceive !== undefined) && (termReceive !== null)) && (termReceive !== ""))
      jsonPaymentForm_New += ", \"TERM_RECEIVE\": \""+ termReceive + "\""

    if (((assessmentReceive !== undefined) && (assessmentReceive !== null)) && (assessmentReceive !== ""))
      jsonPaymentForm_New += ", \"APPORTIONMENT_RECEIVE\": \""+ assessmentReceive + "\""

    if (((taxReceive !== undefined) && (taxReceive !== null)) && (taxReceive !== ""))
      jsonPaymentForm_New += ", \"TAX_RECEIVE\": \""+ taxReceive + "\""

    if (((termPay !== undefined) && (termPay !== null)) && (termPay !== "")) 
      jsonPaymentForm_New += ", \"TERM_PAY\": \""+ termPay + "\"";

    if (((assessmentPay !== undefined) && (assessmentPay !== null)) && (assessmentPay !== ""))
      jsonPaymentForm_New += ", \"APPORTIONMENT_PAY\": \""+ assessmentPay + "\"";

    if (((taxPay !== undefined) && (taxPay !== null)) && (taxPay !== ""))
      jsonPaymentForm_New += ", \"TAX_PAY\": \""+ taxPay + "\"";

    if (((accountPlanCreditCode !== undefined) && (accountPlanCreditCode !== null)) && (accountPlanCreditCode !== "")){
      jsonPaymentForm_New += ", \"ACCOUNT_PLAN_RECEIVE\": { \"ID\": "+ accountPlanCreditCode +
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"+
      ", \"DESCRIPTION\": \""+ accountPlanCreditDescription +"\" }"
    }

    if (((accountPlanDebitCode !== undefined) && (accountPlanDebitCode !== null)) && (accountPlanDebitCode !== "")){
      jsonPaymentForm_New += ", \"ACCOUNT_PLAN_PAY\": { \"ID\": "+ accountPlanDebitCode +
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"+
      ", \"DESCRIPTION\": \""+ accountPlanDebitDescription +"\" }"
    }

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonPaymentForm_New += ", \"SGE\": "+ sge

    jsonPaymentForm_New += ", \"SHOW_ORDER\": 2" +
    ", \"DEFAULT_ORDER\": 2 }";

    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonPaymentForm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PAYMENT_FORM/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a forma de pagamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJPaymentForm(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jPaymentForm))
      formData.append("JSON_NEW_1", jsonPaymentForm_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "PAYMENT_FORM/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a forma de pagamento. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("paymentFormDescription") && document.getElementById("paymentFormDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJPaymentForm(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const selectAccontPlanCredit = (accountPlan) => {
    for (let i = 0; i < listAccountPlanCredit.length; i++) {
      if (listAccountPlanCredit[i].ID === parseInt(accountPlan)){        
        setAccountPlanCreditCode(listAccountPlanCredit[i].ID)
        setAccountPlanCreditDescription(listAccountPlanCredit[i].DESCRIPTION)
        break;
      }
    }
  }

  const selectAccontPlanDebit = (accountPlan) => {
    for (let i = 0; i < listAccountPlanDebit.length; i++) {
      if (listAccountPlanDebit[i].ID === parseInt(accountPlan)){        
        setAccountPlanDebitCode(listAccountPlanDebit[i].ID)
        setAccountPlanDebitDescription(listAccountPlanDebit[i].DESCRIPTION)
        break;
      }
    }
  }

  const setAddGeral = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setAddJm3d = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJPaymentForm(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Forma de Pagamento"
            titleSearch="Pesquisa das Formas de Pagamento"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchPaymentForm
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}

                setAdd={                  
                  ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) || 
                    (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) ? setAddGeral : setAddJm3d}

                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showFinisher &&
                        <>
                          <ButtonsCadastre                             
                            save={() => ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || 
                              (jsonCompany.TYPE === 4)) || (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) ? saveGeral() :  saveJm3d()} 
                                  
                            add={() => add()} 
                            
                            returnAdd={() => ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || 
                              (jsonCompany.TYPE === 4)) || (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) ? returnAddGeral() : returnAddJm3d()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                        
                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            { (code === "") &&
                              <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="description_paymentForm"
                                    description="Descrição"
                                    planceHolder=""
                                    value={description}
                                    maxLength={50}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>
                              </div>
                            }

                            { (code !== "") &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                  <Input
                                    type="text"
                                    name="code_paymentForm"
                                    description="Código"
                                    planceHolder=""
                                    value={code}
                                    maxLength={11}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCode(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                  <Input
                                    type="text"
                                    name="description_paymentForm"
                                    description="Descrição"
                                    planceHolder=""
                                    value={description}
                                    maxLength={50}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>
                              </div>
                            }

                            {/* Múltipla, Geral, Detetização, Licitação, Feira ou Lene */}
                            { ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) ||
                                (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) &&

                              <>
                                <div style={(showMenu) ?
                                    (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="termReceive_paymentForm"
                                        description="Prazo"
                                        value={termReceive}
                                        maxLength={100}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setTermReceive(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="assessmentReceive_paymentForm"
                                        description="Rateio"
                                        value={assessmentReceive}
                                        maxLength={100}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setAssessmentReceive(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 910) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="taxReceive_paymentForm"
                                      description="Taxa"
                                      value={taxReceive}
                                      maxLength={100}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setTaxReceive(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Select
                                      name="accountPlanCreditCode_paymentForm"
                                      description="Plano de Conta (Crédito)"
                                      value={accountPlanCreditCode || 0}
                                      disabled={false}
                                      require={true}
                                      options={JSON.parse(optionsAccountPlanCredit)}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={selectAccontPlanCredit} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : 
                                      (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                    <Select
                                      name="accountPlanDebitCode_paymentForm"
                                      description="Plano de Conta (Débito)"
                                      value={accountPlanDebitCode || 0}
                                      disabled={false}
                                      require={true}
                                      options={JSON.parse(optionsAccountPlanDebit)}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={selectAccontPlanDebit} />
                                  </div>
                                </div>

                                {/* Geral, Licitação ou Lene */}
                                { (((jsonCompany.TYPE === 2) || (jsonCompany.TYPE === 4)) || (jsonCompany.TYPE === 6)) &&

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="appearOrder_paymentForm"
                                        description="Aparecer Pedido"
                                        value={appearOrder}
                                        disabled={false}
                                        require={true}
                                        options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setAppearOrder} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Select
                                        name="defaultOrder_paymentForm"
                                        description="Padrão Pedido"
                                        value={defaultOrder}
                                        disabled={false}
                                        require={true}
                                        options={JSON.parse("{ \"RESULTADO\": [{\"ID\": 0, \"VALOR\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALOR\": \"Sim\" }, {\"ID\": 2, \"VALOR\": \"Não\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setDefaultOrder} />
                                    </div>
                                  </div>
                                }

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, marginBottom: 10}}>
                                  <div style={{minWidth: 180, width: "100%"}}>
                                    <Titulo description="Finalizadora" />
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="finisherDescription_paymentForm"
                                        description="Descrição"
                                        planceHolder=""
                                        value={finisherDescription}
                                        maxLength={20}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setFinisherDescription(event.target.value)} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="searchFinisher_paymentForm"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableFinisher} />

                                      <img 
                                        style={{marginLeft: 10, height: 42, width: 42}}
                                        name="cleanFinisher_paymentForm"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={cleanFinisher} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                            {/* Jm3d */}
                            { (jsonCompany.TYPE === 7) &&
                              <>
                                <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                                  <Titulo description="Conta a receber" />
                                </div>

                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                  <div style={(showMenu) ?
                                      (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="termReceive_paymentForm"
                                          description="Prazo"
                                          value={termReceive}
                                          maxLength={100}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setTermReceive(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="assessmentReceive_paymentForm"
                                          description="Rateio"
                                          value={assessmentReceive}
                                          maxLength={100}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setAssessmentReceive(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 910) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="taxReceive_paymentForm"
                                        description="Taxa"
                                        value={taxReceive}
                                        maxLength={100}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setTaxReceive(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="accountPlanCreditCode_paymentForm"
                                        description="Plano de Conta (Crédito)"
                                        value={accountPlanCreditCode || 0}
                                        disabled={false}
                                        require={true}
                                        options={JSON.parse(optionsAccountPlanCredit)}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={selectAccontPlanCredit} />
                                    </div>
                                  </div>
                                </div>

                                <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                                  <Titulo description="Conta a pagar" />
                                </div>

                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                  <div style={(showMenu) ?
                                      (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="termPay_paymentForm"
                                          description="Prazo"
                                          value={termPay}
                                          maxLength={100}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setTermPay(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                        <Input
                                          type="text"
                                          name="assessmentPay_paymentForm"
                                          description="Rateio"
                                          value={assessmentPay}
                                          maxLength={100}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setAssessmentPay(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 910) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 630) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Input
                                        type="text"
                                        name="taxPay_paymentForm"
                                        description="Taxa"
                                        value={taxPay}
                                        maxLength={100}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setTaxPay(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="accountPlanDebitCode_paymentForm"
                                        description="Plano de Conta (Débito)"
                                        value={accountPlanDebitCode || 0}
                                        disabled={false}
                                        require={true}
                                        options={JSON.parse(optionsAccountPlanDebit)}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={selectAccontPlanDebit} />
                                    </div>
                                  </div>
                                </div>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, marginBottom: 10}}>
                                  <div style={{minWidth: 180, width: "100%"}}>
                                    <Titulo description="Finalizadora" />
                                  </div>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="finisherDescription_paymentForm"
                                        description="Descrição"
                                        planceHolder=""
                                        value={finisherDescription}
                                        maxLength={20}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setFinisherDescription(event.target.value)} />
                                    </div>
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="searchFinisher_paymentForm"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableFinisher} />

                                      <img 
                                        style={{marginLeft: 10, height: 42, width: 42}}
                                        name="cleanFinisher_paymentForm"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={cleanFinisher} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          </div>
                        </>
                      }

                      { showFinisher &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione a finalizadora:</b>
                          </div>

                          <SearchFinisher
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillFinisher} 
                            setReturn={() => setShowFinisher(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default PaymentForm