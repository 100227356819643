import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import SearchFisClass from "./Search"
import SearchFisClassMovement from "../FisClassMovement/Search"
import SearchCstCsosn from "../CstCsosn/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_search from "../../Images/Buttons/search.png"

const FisClass = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [cstCsosnCode, setCstCsosnCode] = useState("")
  const [cstCsosnDescription, setCstCsosnDescription] = useState("")
  const [sge, setSge] = useState("")
    
  const [jFisClass, setJFisClass] = useState({})

  const [showCstCsosn, setShowCstCsosn] = useState(false)
  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setDescription("")
      setCstCsosnCode("")
      setCstCsosnDescription("")
      setSge("")
    }
    else {
      setDescription(jFisClass.DESCRIPTION)

      if ((jFisClass.IMPORT !== null) && (jFisClass.IMPORT !== undefined)){
        setCstCsosnCode(jFisClass.IMPORT.CODE)
        setCstCsosnDescription(jFisClass.IMPORT.DESCRIPTION)
      }

      if ((jFisClass.SGE !== null) && (jFisClass.SGE !== undefined)) setSge(jFisClass.SGE)
    }

    document.getElementById("fisClassDescription") && document.getElementById("fisClassDescription").focus()

  }, [jsonCompany, code, jFisClass, updateFocu])  

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {       
      setDescription("")
      setCstCsosnCode("")
      setCstCsosnDescription("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const cleanCstCsosn = () => {
    setCstCsosnCode("")
    setCstCsosnDescription("")
  }

  const enableCstCsosn = () => {
    setShowCstCsosn(!showCstCsosn)
  }

  const fillCstCsosn = (cstCsosn) => {
    setCstCsosnCode(cstCsosn.CODE)
    setCstCsosnDescription(cstCsosn.DESCRIPTION)  
    setShowCstCsosn(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
        
    setWarning("")
    setError("")

    let jsonFisClass_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (code !== "") jsonFisClass_New += ", \"ID\": "+ code
    if (description !== "") jsonFisClass_New += ", \"DESCRIPTION\": \""+ description + "\""

    if (cstCsosnCode !== "")
      jsonFisClass_New += ", \"IMPORT\": { \"CODE\": \""+ cstCsosnCode +"\", \"DESCRIPTION\": \""+ cstCsosnDescription +"\" }"

    if (sge !== "") jsonFisClass_New += ", \"SGE\": "+ sge

    jsonFisClass_New += " }";

    if (code === ""){           
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonFisClass_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "FIS_CLASS/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a classe fiscal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")          
          document.getElementById("fisClassDescription") && document.getElementById("fisClassDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJFisClass(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)

        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jFisClass))
      formData.append("JSON_NEW_1", jsonFisClass_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "FIS_CLASS/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a classe fiscal. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("fisClassDescription") && document.getElementById("fisClassDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJFisClass(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }  

  const setSelected = (event) => {
    setShowSearch(false)
    setJFisClass(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Classe Fiscal"
            titleSearch="Pesquisa das Classes Fiscais"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchFisClass
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showCstCsosn ?
                          <>
                            <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                            </div>
                          
                            <div style={{paddingLeft: 20, paddingRight: 20}}>
                              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                paddingLeft: 10}}>
                                { warning && 
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                  </div> 
                                }

                                { error &&
                                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                    marginBottom: 10}}>
                                    <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                  </div>
                                }
                              </div>

                              { (code === "") &&
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="fisClassDescription"
                                    description="Descrição"
                                    value={description}
                                    maxLength={40}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDescription(event.target.value)} />
                                </div>
                              }

                              { (code !== "") &&
                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                    <Input
                                      type="text"
                                      name="fisClassCode"
                                      description="Código"
                                      value={code}
                                      maxLength={11}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="fisClassDescription"
                                      description="Descrição"
                                      value={description}
                                      maxLength={40}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                </div>
                              }

                              <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                                <Titulo description="CST/CSOSN de Importação" />
                              </div>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                  : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}                                  
                                  : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                                <div style={showMenu ?
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  :
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={showMenu ?
                                      (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                                    <Input
                                      type="text"
                                      name="fisClassCstCsosnCode"
                                      description="Código"
                                      value={cstCsosnCode}
                                      maxLength={3}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCstCsosnCode(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 980) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : 
                                      (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="fisClassCstCsosnDescription"
                                      description="Descrição"
                                      value={cstCsosnDescription}
                                      maxLength={80}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCstCsosnDescription(event.target.value)} />
                                  </div>
                                </div>
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    : (width < 780) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                  :
                                    (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                  <img 
                                    style={{height: 42, width: 42}}
                                    name="fisClassSearchCstCsosn"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={() => enableCstCsosn(true)} />

                                  <img 
                                    style={{marginLeft: 10, height: 42, width: 42}}
                                    name="fisClassCleanCstCsosn"
                                    src={image_clean} 
                                    alt="Limpar" 
                                    onClick={cleanCstCsosn} />
                                </div>
                              </div>

                              { code &&
                                <div className="paddingLeftRight10_classeFiscal">
                                  <br/>
                                  <Titulo description="Movimento"/>
                                  <SearchFisClassMovement
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jFisClass={jFisClass}
                                    jsonLogin={jsonLogin}
                                    jsonCompany={jsonCompany}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              }
                            </div>
                          </>
                        :
                          <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                            <b>Selecione a CST\CSOSN de importação:</b>
                            <SearchCstCsosn
                              modeSearch={true}
                              databaseType={databaseType}
                              databasePath={databasePath}
                              jsonLogin={jsonLogin}
                              jsonCompany={jsonCompany}
                              system={system}
                              setAdd={() => alert("")}
                              setSelected={fillCstCsosn} 
                              setReturn={() => enableCstCsosn(false)} 
                              showMenu={showMenu}
                              width={width} />
                          </div>
                      }
                    </>
                }
              </>              
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default FisClass