import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchPagination from "../../../Components/SearchPagination"
import Titulo from "../../../Components/Titulo"
import SearchState from "../../State/Search"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchCity = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [code, setCode] = useState(0)
  const [name, setName] = useState("")
  const [stateCode, setStateCode] = useState("")
  const [stateName, setStateName] = useState("")

  const [showResult, setShowResult] = useState(false)
  const [showState, setShowState] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  
  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    let jCity = "{ "
    if (name !== "")  {
      jCity += "\"NAME\": \""+ name + "\""
      if (name.length > 2) search = true
    }
    if (stateCode !== "")  {
      if (jCity !== "{ ") jCity += ", "
      jCity += "\"STATE\": { \"ID\": \""+ stateCode + "\" }"
      search = true
    }
    jCity += " }"

    if (search){
      setWarning("")
      setError("")      
      setLoading("Aguarde buscando as informações...")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jCity)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "CITY/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de cidades. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")
          document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")
          document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jCity)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "CITY/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as cidades. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")
            document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()
            return
          }

          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()
        })
      })
    }
    else {
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()      
    }
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, name, stateCode, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir a cidade (" + item.NAME + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setName("")
    setStateCode("")
    setStateName("")

    setUpdatePage(updatePage + 1)
  }

  const cleanState = () => {
    setShowState(false)

    setStateCode("")
    setStateName("")
  }

  const confirmDelete = () => {
    let returnJson = []
    const jCity = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jCity)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "CITY/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir a cidade. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchCityName") && document.getElementById("searchCityName").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const enableState = (situation) => {
    setShowState(situation)
  }

  const fillState = (state) => {
    setShowState(false)

    setStateCode(state.ID)
    setStateName(state.DESCRICAO)
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  { !showState ?
                      <>
                        {/* Begin - Buttons */}
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                          { !modeSearch &&
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                              marginTop: 10, marginLeft: 5, width: 147}} onClick={() => setAdd()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                              </div>
                            </div>
                          }

                          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                            marginTop: 10, marginLeft: 5, width: 147}} onClick={clean}>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                              paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                              <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                            </div>
                          </div>

                          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                            marginTop: 10, marginLeft: 5, width: 147}} onClick={setReturn}>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                              paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                              <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                            </div>
                          </div>
                        </div>
                        {/* End - Buttons */}

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                        </div>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                          { warning &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div> 
                          }

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                              marginBottom: 10, marginLeft: 0}}>
                              <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                            </div>

                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="search"
                                  name="searchCityName"
                                  description="Nome"
                                  planceHolder="Mínimo 3 caracteres"
                                  value={name}
                                  maxLength={60}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setName(event.target.value)} />
                              </div>
                            </div>

                            <div style={{marginTop: 10, minWidth: 180, width: "100%"}}>
                              <Titulo description="Estado" />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                              : {paddingLeft: 10, paddingRight: 10}}>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="searchCityStateName"
                                    description="Nome"
                                    planceHolder=""
                                    value={stateName}
                                    maxLength={60}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setStateName(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                  <img
                                    style={{height: 42, width: 42}}
                                    name="searchCitySearchState"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={(() => enableState(true))} />

                                  <img
                                    style={{height: 42, width: 42, marginLeft: 10}}
                                    name="searchCityCleanState"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanState} />
                                </div>
                              </div>
                            </div>
                        
                            { showResult && 
                              <>
                                { result.OBJECT &&
                                  <>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, marginLeft: 5,
                                      minWidth: 180}}>

                                      <div style={(showMenu) ?
                                          (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                        :
                                          (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                        <div style={(showMenu) ?
                                            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :
                                            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                            <label style={{fontWeight: 600}}>Nome</label>
                                          </div>
                                          <div style={(showMenu) ?
                                              (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                              : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                            :
                                              (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                            <label style={{fontWeight: 600}}>Estado</label>
                                          </div>
                                        </div>

                                        <div style={(showMenu) ?
                                            (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          :
                                            (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <label style={{fontWeight: 600}}>País</label>
                                        </div>
                                      </div>

                                      { (width >= 450) &&
                                        <div style={{display: "flex", flexGrow: 0, marginRight: 7}}>
                                          <div style={{height: 32, width: 32}} />
                                          { !modeSearch && <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} /> }
                                        </div>
                                      }
                                    </div>

                                    { result.OBJECT.map(item => (
                                      <div key={item.ID} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, paddingBottom: 8,
                                        marginTop: 4, minWidth: 180} 
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                        backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                        borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                        marginTop: 4, minWidth: 180}}>

                                        <div style={(showMenu) ?
                                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                          :
                                            (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                          <div style={(showMenu) ?
                                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            :
                                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                              <label>{item.NAME}</label>
                                            </div>
                                            <div style={(showMenu) ?
                                                (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10,
                                                  minWidth: 180}
                                                : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                              :
                                                (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10,
                                                  minWidth: 180}}>

                                              <label>{item.STATE.NAME}</label>
                                            </div>
                                          </div>

                                          <div style={(showMenu) ?
                                              (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                            :
                                              (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            <label>{item.STATE.COUNTRY.NAME}</label>
                                          </div>
                                        </div>
                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0}}>

                                          <img
                                            style={{height: 32, width: 32}}
                                            src={image_select}
                                            name="searchCitySelect"
                                            alt="Selecionar"
                                            onClick={() => setSelected(item)} />

                                          { !modeSearch && 
                                            <img
                                              style={{height: 28, width: 28, marginTop: 2, marginLeft: 10}}
                                              src={image_delete}
                                              name="searchCityDelete"
                                              alt="Deletar"
                                              onClick={() => callDelete(item)} />
                                          }
                                        </div>
                                      </div>
                                      ))
                                    }

                                    <SearchPagination
                                      pageCurrent={pageCurrent}
                                      totalPage={totalPage}
                                      recordPage={recordPage}
                                      optionsRegPage={JSON.parse(optionsRegPage)}
                                      pagePrevious={pagePrevious}
                                      pageNext={pageNext}
                                      setSelectedField={setFieldSelected}
                                      handleRecordPage={handleRecordPage}
                                      width={width} />
                                  </>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </>
                    :
                      <div style={{marginTop: 10}}>
                        <div style={{marginLeft: 10}}>
                          <b>Selecione o estado:</b>
                        </div>

                        <SearchState
                          modeSearch={true}
                          databaseType={databaseType}
                          databasePath={databasePath}
                          jsonCompany={jsonCompany}
                          jsonLogin={jsonLogin}
                          system={system}
                          setAdd={() => alert("")}
                          setSelected={fillState} 
                          setReturn={() => enableState(false)} 
                          showMenu={showMenu}
                          width={width} />
                      </div>
                  }
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchCity