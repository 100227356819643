import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Questionnaire from "../../../Components/Questionnaire"
import SupplierPhone from ".."
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchSupplierPhone = ({databaseType, databasePath, jsonSupplier, jsonCompany, jsonLogin, system, width}) => {
  const [code, setCode] = useState(0)
  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonSupplierPhone, setJsonSupplierPhone] = useState("")
    
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jSupplierPhone = "{ \"SUPPLIER\": { \"ID\": " + jsonSupplier.ID + " } }"        

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jSupplierPhone)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SUPPLIER_PHONE/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar os Telefones do Fornecedor. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.CONCRETIZADO === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonSupplier, jsonLogin, system, update])

  const add = () => {
    const jSupplierPhone = "{ \"SUPPLIER\": { \"ID\": " + jsonSupplier.ID + " } }"

    setJsonSupplierPhone(JSON.parse(jSupplierPhone))
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setShowChange(false)
  }

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o telefone (" + item.NUMBER + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jPhone = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o telefone...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jPhone)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "SUPPLIER_PHONE/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir o Telefone do Fornecedor. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      const count = update + 1
      setUpdate(count)
    })
  }

  const save = (phone) => {
    let returnJson = []

    setWarning("")
    setError("")

    if (JSON.parse(phone).ID === undefined)
    {
      setLoading("Aguarde incluindo o telefone...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", phone)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "SUPPLIER_PHONE/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Telefone do Fornecedor. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
    else
    {
      setLoading("Aguarde alterando o telefone...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonSupplierPhone))
      formData.append("JSON_NEW_1", phone)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "SUPPLIER_PHONE/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o Telefone do Fornecedor. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
  }

  const select = (phone) => {
    setJsonSupplierPhone(phone)
    setWarning("")
    setError("")
    setShowChange(true)
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <SupplierPhone
                databaseType={databaseType}
                databasePath={databasePath}
                jsonSupplierPhone={jsonSupplierPhone}
                jsonLogin={jsonLogin}
                system={system}
                save={save}
                back={back} 
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                          marginLeft: 5, width: 147}} onClick={() => add()}>                            
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}>

                        { result.OBJECT ?
                            <>
                              <div style={(width < 480) ? 
                                  {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                                  borderBottomStyle: "solid", marginTop: 7, marginBottom: 7}
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2,
                                    borderBottomStyle: "solid", marginTop: 7, marginBottom: 7}}>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={{display: "flex", flexGrow: 1, marginLeft: 14}}>
                                      <label style={{fontWeight: 600}}>Telefone</label>
                                    </div>
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center", marginLeft: 10,
                                      minWidth: 82, maxWidth: 82}} />
                                </div>
                              </div>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  backgroundColor: "rgb(241, 242, 242)", paddingTop: 8, paddingRight: 8, paddingBottom: 8, marginTop: 4,
                                  minWidth: 180}}>

                                  <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexGrow: 1, marginLeft: 10}}>
                                        <label>{item.NUMBER}</label>
                                      </div>
                                    </div>

                                    <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                        marginTop: 10} 
                                      : 
                                        {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "center", marginLeft: 10}}>

                                      <img
                                        style={{height: 32, width: 32}}
                                        name="searchSupplierPhoneSelect"
                                        src={image_select}
                                        alt="Selecionar"
                                        onClick={(() => select(item))} />

                                      <img
                                        style={{height: 28, width: 28, marginLeft: 10}}
                                        name="searchSupplierPhoneRemove"
                                        src={image_delete}
                                        alt="Excluir"
                                        onClick={() => callDelete(item)} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          :
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                        }
                      </div>
                    </>
                }
              </>
          }
        </>
      }
    </div>
  )
}

export default SearchSupplierPhone