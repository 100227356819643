import React, { useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import DataPicker from "../../Components/DataPicker"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Titulo from "../../Components/Titulo"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_search from "../../Images/Buttons/search.png"

const BancoBrasil = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {   
  const [dateSearch, setDateSearch] = useState(null)
  const [internalCode, setInternalCode] = useState("")

  const [showMenu, setShowMenu] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")  

  const [resultSearch, setResultSearch] = useState([])
  const [resultCharge, setResultCharge] = useState({})
 
  const cleanCharge = () => {
    setInternalCode("")
    setResultCharge({})
  }  

  const cleanCharges = () => {
    setDateSearch(null)
    setResultSearch([])
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0"
    return parsed
  }  

  const searchCharge = () => {
    let returnJson = []

    if (internalCode === "") {
      setError("Por favor informe o Código Interno!")
      document.getElementById("bancoBrasilInternal") && document.getElementById("bancoBrasilInternal").focus() 
      return
    }

    setLoading("Aguarde buscando a solicitação...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)

    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.PRODUCTION +"}"+
        ", \"TYPE\": 2 }")

    formData.append("JSON_NEW_2", "{ \"INTERNAL\": "+ internalCode +" }")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "COMPANY_BANK/SearchBillet", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar a cobrança. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }
      else
      { 
        setResultCharge(returnJson.OBJECT)
        setLoading("")
      }
    })  
  }

  const searchChargeList = () => {
    let returnJson = []

    if (dateSearch === "") {
      setError("Por favor informe a Data!")
      document.getElementById("bancoBrasilDateSearch") && document.getElementById("bancoBrasilDateSearch").focus() 
      return
    }

    if (!validateDate(dateSearch)) {
      setDateSearch("")
      setError("Data inválida!")      
      document.getElementById("bancoBrasilDateSearch") && document.getElementById("bancoBrasilDateSearch").focus() 
      return
    }
        
    let period = dateSearch.toLocaleString().substr(0,10)
    console.log(period);
    period =  period.substr(6,10) + "-" + period.substr(3,2) + "-" + period.substr(0,2)

    setLoading("Aguarde buscando as solicitações...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)

    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +", \"ENVIRONMENT\": "+ jsonCompany.PRODUCTION +" }"+
        ", \"TYPE\": 2 }")

    formData.append("JSON_NEW_2", "{ \"EMISSION_DATE\": \""+ period +"\" }")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "COMPANY_BANK/SearchBilletList", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar as solicitações. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }
      else
      { 
        if (returnJson.OBJECT !== null)
          setResultSearch(returnJson.OBJECT)

        setLoading("")
      }
    })
  }

  const setFieldSelected = () => {
  }  

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30)) error = true
    else if (ardt[1] === 2) {
      if ((ardt[0] > 28) && ((ardt[2]%4) !== 0)) error = true;
      if ((ardt[0] > 29) && ((ardt[2]%4) === 0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Banco do Brasil"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

            { loading ?
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              :
                <>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                      marginTop: 10, marginLeft: 5, width: 147}} onClick={setReturn}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                        paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 20, paddingRight: 20}}>
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                      paddingLeft: 10}}>
                      { warning && 
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                        </div> 
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                        </div>
                      }
                    </div>

                    <div style={(width < 450) ? {marginTop: 10, paddingLeft: 0, paddingRight: 0} 
                      : {marginTop: 10, paddingLeft: 10, paddingRight: 10}}>

                      <Titulo description="Consulta das Solicitações" />                

                      <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                        <div style={(width < 300) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                          <div style={(width < 300) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                            <DataPicker
                              name="bancoBrasilDateSearch"
                              description="Data Emissão"
                              value={dateSearch}
                              require={false}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDateSearch(event)} 
                              intervalo={false}
                              inicial={true} />
                          </div>
                          <div style={
                            (width < 300) ? 
                              {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", 
                              marginTop: 10, marginBottom: 10}
                            :
                              {display: "flex", flexGrow: 1, alignItems: "flex-end"}}>
                            <div style={{marginLeft: 10}}>
                              <img 
                                style={{height: 36, width: 36}}
                                name="bancoBrasilSearchList"
                                src={image_search} 
                                alt="Pesquisar"
                                onClick={searchChargeList} />
                            </div>
                            <div style={{marginLeft: 10}}>
                              <img
                                style={{height: 36, width: 36}}
                                name="bancoBrasilCleanList"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={cleanCharges} />
                            </div>
                          </div>
                        </div>
                        
                        { resultSearch && 
                          resultSearch.length !== 0 &&
                          <>
                            <div style={(showMenu) ?
                                  (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4}
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                              :
                                  (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4 }
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  :
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                  <label style={{fontWeight: 600}}>Interno</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}
                                  : 
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}}>
                                  <label style={{fontWeight: 600}}>Referência</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}
                                :
                                  (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}
                                    :
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}}>

                                    <label style={{fontWeight: 600}}>Tipo</label>
                                  </div>

                                  <div style={(width < 270) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 320) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600}}>Emissão</label>
                                    </div>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600}}>Vencto</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
                                    <label style={{fontWeight: 600}}>Valor</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1450) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                    :  
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                        
                                    <label style={{fontWeight: 600}}>Situação</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr/>

                            { resultSearch.map(item => (
                              <div key={item.ID} style={(showMenu) ?
                                    (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                  : 
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                :
                                    (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                  :
                                    {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                    borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                    paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  :
                                    (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>

                                  <div style={showMenu ?
                                      (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                    :
                                      (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                    {item.numeroSolicitacao}
                                  </div>

                                  <div style={showMenu ?
                                      (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 1, minWidth: 360}
                                    : 
                                      (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 1, minWidth: 360}}>
                                    {item.codigoConciliacaoSolicitacao}
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                    : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                    : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>

                                  <div style={showMenu ?
                                      (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                        : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}
                                      :
                                        (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                        : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}}>

                                      { item.codigoConciliacaoSolicitacao.length == 36 ?
                                          item.codigoConciliacaoSolicitacao.substr(14,1) == 1 ? "Boleto"
                                          : item.codigoConciliacaoSolicitacao.substr(14,1) == 2 ? "Pix"
                                          : item.codigoConciliacaoSolicitacao.substr(14,1) == 3 ? "Cartão"
                                          : "Sem"
                                        : "Sem" }
                                    </div>

                                    <div style={(width < 270) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 320) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                        : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                        { String(item.timestampCriacaoSolicitacao).substr(8,2) + "/" +
                                          String(item.timestampCriacaoSolicitacao).substr(5,2) + "/" +
                                            String(item.timestampCriacaoSolicitacao).substr(0,4) }
                                      </div>

                                      <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                        : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                        { item.vencimento &&
                                          item.vencimento.data &&
                                            String(item.vencimento.data).substr(8,2) + "/" + String(item.vencimento.data).substr(5,2) + "/" +
                                              String(item.vencimento.data).substr(0,4) }
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
                                      {formatNumeric(item.valorSolicitacao,2)}
                                    </div>

                                    <div style={showMenu ?
                                        (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 1450) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                      :  
                                        (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                        : (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                        : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                          
                                        {item.codigoEstadoSolicitacao == 0 ? "Aguardando Pagamento"
                                        : item.codigoEstadoSolicitacao == 1 ? "Paga"
                                        : item.codigoEstadoSolicitacao == 800 ? "Expirada"
                                        : item.codigoEstadoSolicitacao == 850 ? "Abandonada"
                                        : item.codigoEstadoSolicitacao == 900 && "Excluída"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        }

                        { resultSearch && 
                          resultSearch.length === 0 &&
                            <div style={{display: "flex", flexGrow: 1, marginTop: 10, marginBottom: 10, justifyContent: "center"}}>
                              <label style={{fontWeight: 600}}>Nenhum registro encontrado.</label>
                            </div>
                        }
                      </div>
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 5} : {paddingLeft: 10, paddingRight: 10, marginTop: 5}}>
                      <Titulo description="Consulta a Solicitação" />

                      <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                        <div style={
                            (width < 300) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                          <div style={
                            (width < 300) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                            <Input
                            type="text"
                            name="bancoBrasilInternal"
                            description="Interno"
                            value={internalCode}
                            maxLength={11}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setInternalCode(event.target.value)} />
                          </div>                          
                          <div style={
                              (width < 300) ? {display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}
                            : 
                              {display: "flex", flexGrow: 1, alignItems: "flex-end"}}>
                            <div style={{marginLeft: 10}}>
                              <img 
                                style={{height: 36, width: 36}}
                                name="mentesPensamSearch"
                                src={image_search} 
                                alt="Pesquisar"
                                onClick={searchCharge} />
                            </div>
                            <div style={{marginLeft: 10}}>
                              <img
                                style={{height: 36, width: 36}}
                                name="mentesPensamClean"
                                src={image_clean} 
                                alt="Limpar" 
                                onClick={cleanCharge} />
                            </div>
                          </div>
                        </div>

                        { (resultCharge.numeroSolicitacao !== undefined) &&
                          <>
                            <div style={(showMenu) ?
                                  (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4}
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                              :
                                  (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingTop: 4, paddingBottom: 4,
                                  marginTop: 4 }
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  :
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                  <label style={{fontWeight: 600}}>Interno</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}
                                  : 
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}}>
                                  <label style={{fontWeight: 600}}>Referência</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}
                                :
                                  (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}
                                    :
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}}>

                                    <label style={{fontWeight: 600}}>Tipo</label>
                                  </div>

                                  <div style={(width < 270) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 320) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600}}>Emissão</label>
                                    </div>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      <label style={{fontWeight: 600}}>Vencto</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
                                    <label style={{fontWeight: 600}}>Valor</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1450) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                    :  
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                        
                                    <label style={{fontWeight: 600}}>Situação</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr/>

                            <div style={(showMenu) ?
                                  (width < 1450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                : 
                                  {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4}
                              :
                                  (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                  borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                  paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                              <div style={showMenu ?
                                  (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                :
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                  :
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                  {resultCharge.numeroSolicitacao}
                                </div>

                                <div style={showMenu ?
                                    (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}
                                  : 
                                    (width < 530) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 1, minWidth: 360}}>
                                  {resultCharge.codigoConciliacaoSolicitacao}
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}
                                :
                                  (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                  : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>

                                <div style={showMenu ?
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 320) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 710) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}
                                    :
                                      (width < 320) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 60}
                                      : {display: "flex", flexGrow: 0, minWidth: 60, maxWidth: 60}}>

                                    { resultCharge.codigoConciliacaoSolicitacao.length == 36 ?
                                        resultCharge.codigoConciliacaoSolicitacao.substr(14,1) == 1 ? "Boleto"
                                        : resultCharge.codigoConciliacaoSolicitacao.substr(14,1) == 2 ? "Pix"
                                        : resultCharge.codigoConciliacaoSolicitacao.substr(14,1) == 3 ? "Cartão"
                                        : "Sem"
                                      : "Sem" }
                                  </div>

                                  <div style={(width < 270) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 320) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      { String(resultCharge.timestampCriacaoSolicitacao).substr(8,2) + "/" +
                                        String(resultCharge.timestampCriacaoSolicitacao).substr(5,2) + "/" +
                                          String(resultCharge.timestampCriacaoSolicitacao).substr(0,4) }
                                    </div>

                                    <div style={(width < 270) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 320) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 100}
                                      : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                      { resultCharge.vencimento &&
                                        resultCharge.vencimento.data &&
                                          String(resultCharge.vencimento.data).substr(8,2) + "/" + 
                                            String(resultCharge.vencimento.data).substr(5,2) + "/" + 
                                              String(resultCharge.vencimento.data).substr(0,4) }
                                    </div>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1450) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                    : {display: "flex", flexGrow: 0, minWidth: 130, maxWidth: 130}}>
                                    {formatNumeric(resultCharge.valorSolicitacao,2)}
                                  </div>

                                  <div style={showMenu ?
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1450) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                    :  
                                      (width < 710) ? {display: "flex", flexGrow: 1, minWidth: 180, wordBreak: "break-all"}
                                      : (width < 1180) ? {display: "flex", flexGrow: 1, minWidth: 210}
                                      : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                        
                                      {resultCharge.codigoEstadoSolicitacao == 0 ? "Aguardando Pagamento"
                                      : resultCharge.codigoEstadoSolicitacao == 1 ? "Paga"
                                      : resultCharge.codigoEstadoSolicitacao == 800 ? "Expirada"
                                      : resultCharge.codigoEstadoSolicitacao == 850 ? "Abandonada"
                                      : resultCharge.codigoEstadoSolicitacao == 900 && "Excluída"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
             
                        { (resultCharge.numeroSolicitacao === undefined) &&
                          <div style={{display: "flex", flexGrow: 1, marginTop: 10, marginBottom: 10, justifyContent: "center"}}>
                            <label style={{fontWeight: 600}}>Nenhum registro encontrado.</label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </>
          }

        </div>
      </div>
      
      <Footer/>
    </div>
  )
}

export default BancoBrasil