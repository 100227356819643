import React from "react";
import Select from "../Select"
import image_next from "../../Images/Buttons/next.png"
import image_previous from "../../Images/Buttons/previous.png"

const SearchPagination = ({pageCurrent, totalPage, recordPage, optionsRegPage, pagePrevious, pageNext, setSelectedField, handleRecordPage,
  width}) => {

  return(
    <div style={{marginTop: 20}}>
      <hr/>
      <div style={(width < 450) ? {display: "flex", flexDirection: "column", justifyContent: "flex-end", marginTop: 10, marginBottom: 10} 
        : {display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 10, marginBottom: 10}}>

        <div style={(width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center"}
        : {display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
          <div>
            <img
              style={{height: 32, width: 32}}
              name="searchPaginationPrevious"
              src={image_previous}
              alt="Anterior"
              onClick={pagePrevious} />
          </div> 
          <div style={{paddingRight: 10, paddingBottom: 10, paddingLeft: 10}}>
            {pageCurrent} / {totalPage}
          </div>
          <div style={{paddingRight: 10}}>
            <img 
              style={{height: 32, width: 32}}
              name="searchPaginationNext"
              src={image_next}
              alt="Proximo"
              onClick={pageNext} />
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <Select
            name="searchPaginationRecordPage"
            description="Registros"
            value={recordPage || 200}
            require={true}
            options={optionsRegPage}
            typeImage={0}
            typeInput={0}
            onSelect={setSelectedField}
            set={handleRecordPage} />
        </div>
      </div>
    </div>
  )
}

export default SearchPagination