import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import SearchProduct from "../Product/Search"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const ClientProduct = ({databaseType, databasePath, jsonClientProduct, jsonCompany, jsonLogin, system, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [clientCode, setClientCode] = useState("")
  const [productCode, setProductCode] = useState("")
  const [companyProductCode, setCompanyProductCode] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [price, setPrice] = useState("")
  const [sge, setSge] = useState("")

  const [showProduct, setShowProduct] = useState(false)

  useEffect(() => {
    if (jsonClientProduct.ID !== undefined) setCode(jsonClientProduct.ID)

    setClientCode(jsonClientProduct.CLIENT.ID)

    if (jsonClientProduct.PRODUCT_COMPANY !== undefined){ 
      if (jsonClientProduct.PRODUCT_COMPANY.ID !== undefined) setCompanyProductCode(jsonClientProduct.PRODUCT_COMPANY.ID)

      if (jsonClientProduct.PRODUCT_COMPANY.PRODUCT !== undefined){
        setProductCode(jsonClientProduct.PRODUCT_COMPANY.PRODUCT.ID)
        setProductDescription(jsonClientProduct.PRODUCT_COMPANY.PRODUCT.DESCRIPTION)
      }
    }
    else{
      setProductCode("")
      setCompanyProductCode("")
      setProductDescription("")
    }

    if (jsonClientProduct.SALE_PRICE !== undefined) setPrice(formatNumeric(jsonClientProduct.SALE_PRICE,2))
    if (jsonClientProduct.SGE !== undefined) setSge(jsonClientProduct.SGE)
  }, [jsonClientProduct])

  const callSave = () => {
    let json = "{"
    if (code !== "") json += " \"ID\": "+ code + ","
    if (clientCode !== "") json += " \"CLIENT\": { \"ID\": " + clientCode + " }"

    if (productCode !== "") json += ", \"PRODUCT_COMPANY\": { \"ID\": " + companyProductCode + 
      ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
      ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }, \"PRODUCT\": { \"ID\": "+ productCode +
      ", \"DESCRIPTION\": \""+ productDescription +"\" } }"

    if (price !== "") json += ", \"SALE_PRICE\": "+ price
    if (sge !== "") json += ", \"SGE\": "+ sge

    json += " }"
    save(json)
  }

  const cleanProduct = () => {
    setShowProduct(false)
    setProductCode("")
    setCompanyProductCode("")
    setProductDescription("")
  }

  const enableProduct = () => {
    setShowProduct(!showProduct)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handlePrice = (event) => {
    setPrice(formatNumeric(event.target.value, 2))
  }

  const fillProduct = (product) => {
    if (product.PRODUCT_COMPANY !== null){
      setCompanyProductCode(product.PRODUCT_COMPANY.ID)
      setProductCode(product.ID)
      setProductDescription(product.DESCRIPTION)
    }
    else{
      alert("Esse produto não pertence a filial selecionada.")

      setCompanyProductCode("")
      setProductCode("")
      setProductDescription("")
    }
  
    setShowProduct(false)
  } 

  function setFieldSelected(){
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showProduct &&
        <>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={callSave}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={() => back()}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
          </div>

          <div style={(width < 540) ? { marginTop: 10}
            : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
            <label style={{fontWeight: 600}}>Produto</label>
          </div>

          <div style={showMenu ?
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10, paddingLeft: 0, paddingRight: 0}
              : (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10, paddingLeft: 20, paddingRight: 20}
              : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 20, paddingRight: 20}
            :
              (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 20, paddingRight: 20}}>

            <div style={showMenu ?
                (width < 1340) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}
              :
                (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
              <div style={showMenu ?
                  (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                :
                  (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                <div style={showMenu ?
                    (width < 1160) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                  :
                    (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                  <Input
                    type="text"
                    name="clientProductProductCode"
                    description="Código"
                    planceHolder=""
                    value={productCode}
                    maxLength={11}
                    disabled={true}
                    require={true}
                    image={false}
                    typeImage={0}
                    typeInput={2} 
                    onSelect={setFieldSelected}
                    onChange={(event) => setProductCode(event.target.value)} />
                </div>
                <div style={showMenu ?
                    (width < 1160) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                  :
                    (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                  <Input
                    type="text"
                    name="clientProductProductDescription"
                    description="Descrição"
                    planceHolder=""
                    value={productDescription}
                    maxLength={120}
                    disabled={true}
                    require={true}
                    image={false}
                    typeImage={0}
                    typeInput={2} 
                    onSelect={setFieldSelected}
                    onChange={(event) => setProductDescription(event.target.value)} />
                </div>
              </div>
              <div style={showMenu ?
                  (width < 1340) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                :
                  (width < 1070) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                <InputNumeric
                  type="text"
                  name="clientProductPrice"
                  description="Preço"
                  value={price || ""}
                  maxLength={13}
                  disabled={false}
                  require={true}
                  negative={false} 
                  image={false}
                  typeImage={0}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  onChange={handlePrice} />
              </div>
            </div>
            <div style={showMenu ?
                (width < 970) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                  marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, width: 90}
              :              
                (width < 540) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                  marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, width: 90}}>

              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="clientProductSearchProduct"
                  src={image_search} 
                  alt="Pesquisar" 
                  onClick={(() => enableProduct(true))} />
              </div>
              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="clientProductCleanProduct"
                  src={image_clean} 
                  alt="Limpar"
                  onClick={cleanProduct} />
              </div>
            </div>
          </div>
        </>
      }

      { showProduct &&
        <div style={(width < 540) ? {paddingLeft: 0, paddingRight: 0}
          : {paddingLeft: 10, paddingRight: 10}}>
          <b>Selecione o Produto:</b>
          <SearchProduct
            modeSearch={true}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonCompany={jsonCompany}
            jsonLogin={jsonLogin}
            type=""
            system={system}
            setAdd={() => alert("")}
            setSelected={fillProduct} 
            setReturn={() => setShowProduct(false)}
            showMenu={showMenu}
            width={width} />
        </div>
      }
    </div>
  )
}

export default ClientProduct