import React, { useState, useEffect } from "react"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"

const ProductCompanyOffer = ({jsonProductCompanyOffer, jsonCompany, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [productCompanyCode, setProductCompanyCode] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [expirationDate, setExpirationDate] = useState("")
  const [situation, setSituation] = useState("")

  useEffect(() => {
    setCode(jsonProductCompanyOffer.ID)
    setProductCompanyCode(jsonProductCompanyOffer.PRODUCT_COMPANY.ID)
    setQuantity(formatNumeric(jsonProductCompanyOffer.QUANTITY, 3))
    setPrice(formatNumeric(jsonProductCompanyOffer.PRICE, 2))

    if (((jsonProductCompanyOffer.EXPIRATION_DATE !== undefined) && (jsonProductCompanyOffer.EXPIRATION_DATE !== null)) && 
      (jsonProductCompanyOffer.EXPIRATION_DATE !== "")){

      const sExpiration = jsonProductCompanyOffer.EXPIRATION_DATE.substr(8, 2) + "/" + jsonProductCompanyOffer.EXPIRATION_DATE.substr(5, 2) + "/" +
      jsonProductCompanyOffer.EXPIRATION_DATE.substr(0, 4)
      setExpirationDate(sExpiration)
    }

    setSituation(jsonProductCompanyOffer.SITUATION)
  }, [jsonProductCompanyOffer])
 
  const formatNumeric = (value, decimals) => {
    if (!value) return "0.000"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.000"
    return parsed
  }

  const handlePrice = (event) => {
    setPrice(formatNumeric(event.target.value, 2))
  }
  
  const handleQuantity = (event) => {
    setQuantity(formatNumeric(event.target.value, 3))
  }

  const callSave = () => {
    if (!validateDate(expirationDate)){
      alert("Data da validade inválida!")
      return
    }

    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","

    json += " \"PRODUCT_COMPANY\": { \"ID\": " + productCompanyCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
      ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } }"
   
    if (((quantity !== undefined) && (quantity !== null)) && (quantity !== "")) json += ", \"QUANTITY\": "+ quantity
    if (((price !== undefined) && (price !== null)) && (price !== "")) json += ", \"PRICE\": "+ price

    if (((expirationDate !== undefined) && (expirationDate !== null)) && (expirationDate !== ""))
    {
      if (String(expirationDate).replaceAll("_","").length >= 10){
        const expiration = String(expirationDate).substr(6,4) + "-" + String(expirationDate).substr(3,2) + "-" + String(expirationDate).substr(0,2) +
          "T00:00:00";

        json += ", \"EXPIRATION_DATE\": \""+ expiration + "\""
      }
    }

    if (((situation !== undefined) && (situation !== null)) && (situation !== "")) json += ", \"SITUATION\": "+ situation
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  const validateDate = (value) => {
    var error = false;
    var date = value;
    var ardt = new Array([]);
    var ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={callSave}>

          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
          width: 147}} onClick={() => back()}>
          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
            paddingBottom: 5, paddingLeft: 10}}>
            <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
          </div>
          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
      </div>

      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
        { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

        <div style={showMenu ?
            (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
          :  
            (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}}>

          <div style={showMenu ?
              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}
            :
              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

            <div style={showMenu ?
                (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
              :
                (width < 870) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

              <InputNumeric
                type="text"
                name="productCompanyOfferQuantity"
                description="Quantidade"
                value={quantity || ""}
                maxLength={13}
                disabled={false}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handleQuantity} />
            </div>

            <div style={showMenu ?
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
              :
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 870) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

              <InputNumeric
                type="text"
                name="productCompanyOfferPrice"
                description="Preço"
                value={price || ""}
                maxLength={13}
                disabled={false}
                require={true}
                negative={false} 
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={handlePrice} />
            </div>
          </div>

          <div style={showMenu ?
              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
              : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}
            :
              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

            <div style={showMenu ?
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
              :
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 870) ? {display: "flex", flexGrow: 0, marginTop: 10, minWidth: 180, maxWidth: 180}
                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

              <InputMasked
                type="text"
                name="productCompanyOfferExpirationDate"
                description="Data Validade"
                mask="99/99/9999"
                value={expirationDate}
                disabled={false}
                require={true}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setExpirationDate(event.target.value)} />
            </div>

            <div style={showMenu ?
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 690) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 1140) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
              :
                (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                : (width < 870) ? {display: "flex", flexGrow: 1, marginTop: 10, marginLeft: 10, minWidth: 180}
                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

              <Select
                name="productCompanyOfferSituation"
                description="Situação:"
                value={situation || 1}
                require={true}
                options={JSON.parse("{ \"RESULT\": [{\"ID\": \"1\", \"VALUE\": \"Ativo\" }, {\"ID\": \"2\", \"VALUE\": \"Inativo\" }] }")}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                set={setSituation} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCompanyOffer