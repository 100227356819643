import "./style.css"
import React, { useEffect, useState } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import pt from "date-fns/locale/pt"

const DataPicker = ({name, description, planceHolder, value, require, typeInput, onSelect, onChange, intervalo, inicial, startDate, endDate}) => {
  registerLocale("pt", pt)

  const [obrigatorio, setObrigatorio] = useState("")
  const [colorFocu, setColorFocu] = useState( (typeInput === 1) ? "corpoUnFocuLogin_datePicker" : "corpoUnFocuTela_datePicker" )
  const descricaoInput = (typeInput === 1) ? "descricaoLogin_datePicker" : "descricaoTela_datePicker"  

  useEffect(() => {
    if (String(value) !== "") setObrigatorio("naoObrigatorio_datePicker")
    else
    {        
      if (require){
        if (String(value) !== "") setObrigatorio("naoObrigatorio_datePicker")
        else setObrigatorio("obrigatorio_datePicker")
      } else setObrigatorio("naoObrigatorio_datePicker")
    }
  }, [require, value])

  function handleBlur(event) {
    if (event.target.value === "") {
      if (require) setObrigatorio("obrigatorio_datePicker"); else setObrigatorio("naoObrigatorio_datePicker")
    } else setObrigatorio("naoObrigatorio_datePicker")

    if (typeInput === 1) setColorFocu("corpoUnFocuLogin_datePicker")
    else if (typeInput === 2) setColorFocu("corpoUnFocuTela_datePicker")    
  }

  function handleFocu(){
    if (typeInput === 1) setColorFocu("corpoFocuLogin_datePicker")
    else if (typeInput === 2) setColorFocu("corpoFocuTela_datePicker")

    onSelect(name)
  }

  return(
    <div style={{ width: "100%" }}>      
      <div id="datePicker">
                
        { description &&
          <div id={descricaoInput}>
            <label>{description}</label>
          </div>
        }

        <div id={colorFocu}>
          <>
            <div id="corpoCampoSemImagem_datePicker">

              { !intervalo &&
                <DatePicker
                  id={name}
                  name={name}
                  className={obrigatorio}
                  planceHolder={planceHolder}
                  selected={value}
                  onChange={(date) => onChange(date)} 
                  onBlur={handleBlur}
                  onFocus={handleFocu}
                  locale="pt" 
                  dateFormat="dd/MM/yyyy"
                />
              }

              { intervalo &&
                <>

                  { inicial &&
                    <>
                      <DatePicker
                        id={name}
                        name={name}
                        className={obrigatorio}
                        planceHolder={planceHolder}
                        selected={value}
                        onChange={(date) => onChange(date)} 
                        onBlur={handleBlur}
                        onFocus={handleFocu}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={value}
                        endDate={endDate}
                      />
                    </>
                  }

                  { !inicial &&
                    <>
                      <DatePicker
                        id={name}
                        name={name}
                        className={obrigatorio}
                        planceHolder={planceHolder}
                        selected={value}
                        onChange={(date) => onChange(date)} 
                        onBlur={handleBlur}
                        onFocus={handleFocu}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        startDate={startDate}
                        endDate={value}
                        minDate={startDate}              
                      />
                    </>
                  }                  
                
                </>              
              }

            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default DataPicker