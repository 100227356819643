import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Questionnaire from "../../../Components/Questionnaire"
import TypeTaxOperationCfop from "../"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchTypeTaxOperationCfop = ({databaseType, databasePath, jsonTypeTaxOperation, jsonLogin, jsonCompany, system, showMenu,
  width}) => {

  const [code, setCode] = useState(0)
  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)

  const [jsonTypeTaxOperationCfop, setJsonTypeTaxOperationCfop] = useState("")

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jTypeTaxOperationCfop = "{ \"TYPE_TAX_OPERATION\": { \"ID\": \""+ jsonTypeTaxOperation.ID +
      "\", \"DESCRIPTION\": \""+ jsonTypeTaxOperation.DESCRIPTION + "\", \"COMPANY\": { \"ID\": "+ 
        jsonTypeTaxOperation.COMPANY.ID + " } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jTypeTaxOperationCfop)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "TYPE_TAX_OPERATION_CFOP/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar os tipos de operações fiscais dos CFOPs. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonTypeTaxOperation, jsonLogin, system, update])

  const add = () => {
    setJsonTypeTaxOperationCfop(JSON.parse("{ \"TYPE_TAX_OPERATION\": { \"ID\": \""+ jsonTypeTaxOperation.ID +
      "\", \"DESCRIPTION\": \""+ jsonTypeTaxOperation.DESCRIPTION + 
        "\", \"COMPANY\": { \"ID\": "+ jsonTypeTaxOperation.COMPANY.ID + " } }, \"TYPE\": 0 }"))

    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setWarning("")
    setError("")
    setShowChange(false)
  }  

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o CFOP do tipo de operação fiscal ("+ item.CFOP.ID + "," + item.CST_CSOSN.CODE + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jTypeTaxOperationCfop = "{ \"ID\": "+ code +", \"TYPE_TAX_OPERATION\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } } }"

    setLoading("Aguarde excluindo o Tipo de Operação Fiscal CFOP...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jTypeTaxOperationCfop)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "TYPE_TAX_OPERATION_CFOP/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir o tipo de operação fiscal do CFOP. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      const count = update + 1
      setUpdate(count)
    })
  }

  const save = (typeTaxOperationCfop) => {
    let returnJson = []

    if (JSON.parse(typeTaxOperationCfop).ID === undefined)
    {
      setLoading("Aguarde incluindo o Tipo de Operação Fiscal CFOP...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", typeTaxOperationCfop)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "TYPE_TAX_OPERATION_CFOP/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o tipo de operação fiscal CFOP. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
    else
    {
      setLoading("Aguarde alterando o Tipo de Operação Fiscal CFOP...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonTypeTaxOperationCfop))
      formData.append("JSON_NEW_1", typeTaxOperationCfop)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "TYPE_TAX_OPERATION_CFOP/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o tipo de operação fiscal CFOP. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setLoading("")
        setError("")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
  }

  const setSelected = (typeTaxOperationCfop) => {
    setJsonTypeTaxOperationCfop(typeTaxOperationCfop)
    setWarning("")
    setError("")
    setShowChange(true)
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <TypeTaxOperationCfop
                databaseType={databaseType}
                databasePath={databasePath}
                jsonTypeTaxOperationCfop={jsonTypeTaxOperationCfop}
                setJsonTypeTaxOperationCfop={setJsonTypeTaxOperationCfop}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                save={save}
                back={back} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                          marginLeft: 5, width: 147}} onClick={() => add()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                          </div>
                        </div>
                      </div>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}></div>

                        { result.OBJECT ?
                            <>
                              <div style={(width < 480) ?
                                  {display: "flex", flexDirection: "column", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 1,
                                    borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                    marginTop: 30, marginBottom: 4}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <label style={{fontWeight: 600}}>CFOP</label>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}> 
                                    <label style={{fontWeight: 600}}>CST/CSOSN</label>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                    <div style={showMenu ?
                                        (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 860) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Tipo</label>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 860) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Padrão</label>
                                    </div>
                                  </div>
                                </div>

                                { (width >= 480) &&
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                      maxWidth: 32}} />

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minHeight: 32, minWidth: 32,
                                      maxWidth: 32}}/>
                                  </div>
                                }
                              </div>

                              <div style={showMenu ? 
                                  {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32,
                                    maxWidth: 32}
                                  : (width < 330) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, minWidth: 32, maxWidth: 32}
                                : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}} />                              

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={showMenu ?
                                    (width < 480) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : (width < 690) ?
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : (width < 820) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                  :
                                    (width < 480) ?
                                      {display: "flex", flexDirection: "column", flexGrow: 1,
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>{item.CFOP.ID + ' - ' + item.CFOP.DESCRIPTION}</div>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>{item.CST_CSOSN.CODE + ' - ' + item.CST_CSOSN.DESCRIPTION}</div>
                                    <div style={showMenu ?
                                        (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 860) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        :
                                          (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        { item.TYPE === 1 && "Entrada Dentro da UF" }
                                        { item.TYPE === 2 && "Entrada Fora da UF" }
                                        { item.TYPE === 3 && "Importação" }
                                        { item.TYPE === 4 && "Saída Dentro da UF" }
                                        { item.TYPE === 5 && "Saída Fora da UF" }
                                        { item.TYPE === 6 && "Exportação" }  
                                      </div>
                                      <div style={showMenu ?
                                          (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 860) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        :
                                          (width < 590) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        { item.DEFAULT === 1 && "Sim" }
                                        { item.DEFAULT === 2 && "Não" }
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div style={showMenu ?
                                      (width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10,
                                        justifyContent: "center"}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10,
                                        minWidth: 90, maxWidth: 90}
                                      : (width < 820) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10,
                                        justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 480) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10,
                                        justifyContent: "center"}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 90, maxWidth: 90}}>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                      <img
                                        style={{height: 32, width: 32}}
                                        src={image_select}
                                        name="typeTaxOperationSelected"
                                        alt="Selecionar"
                                        onClick={(() => setSelected(item))} />
                                    </div>

                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", marginLeft: 10, minWidth: 32, maxWidth: 32}}>
                                      <img
                                        style={{height: 28, width: 28}}
                                        name="typeTaxOperationDeleted"
                                        src={image_delete}
                                        alt="Excluir"
                                        onClick={() => callDelete(item)} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          :
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                        }
                      </>
                  }
                </>
            }
          </>
        }
      </div>
    )
  }

export default SearchTypeTaxOperationCfop