import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Titulo from "../../../Components/Titulo"
import SearchAgency from "../../Agency/Search"
import image_clean from "../../../Images/Buttons/clean.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchAccount = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [number, setNumber] = useState("")
  const [owner, setOwner] = useState("")
    
  const [agencyCode, setAgencyCode] = useState("")
  const [agencyDescription, setAgencyDescription] = useState("")

  const [showAgency, setShowAgency] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})  
  
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)    
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0) 

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    setCompanyCode(jsonCompany.ID)

    let jAccount = "{ "
    jAccount += "\"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
    if (number !== "") {
      jAccount += ", \"NUMBER\": \""+ number + "\""
      search = true
    }    
    if (owner !== "") {
      jAccount += ", \"OWNER\": \""+ owner + "\""
      if (owner.length > 2) search = true
    }
    if (agencyCode !== "")  {
      jAccount += ", \"AGENCY\": { \"ID\": "+ agencyCode + " }"
      search = true
    }
    jAccount += " }"

    if (jAccount === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }") search = true

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jAccount)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ACCOUNT/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
            document.getElementById("searchAccountNumber").focus()
          else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()
          
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
            document.getElementById("searchAccountNumber").focus()
          else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600) setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
          ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jAccount)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "ACCOUNT/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as contas. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
              document.getElementById("searchAccountNumber").focus()
            else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()

            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
            document.getElementById("searchAccountNumber").focus()
          else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
        document.getElementById("searchAccountNumber").focus()
      else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, pageCurrent, recordPage, number, owner, agencyCode, updatePage],)

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir a conta (" + item.OWNER + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setNumber("")
    setAgencyCode("")
    setAgencyDescription("")
    setUpdatePage(updatePage + 1)
  }

  const cleanAgency = () => {
    setShowAgency(false)

    setAgencyCode("")
    setAgencyDescription("")
  }  

  const confirmDelete = () => {
    let returnJson = []
    const jAccount = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ companyCode +" } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jAccount)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir a conta. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
          document.getElementById("searchAccountNumber").focus()
        else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const enableAgency = (situation) => {
    setShowAgency(situation)
  }

  const fillAgency = (event) => {
    setShowAgency(false)
    setAgencyCode(event.ID)
    setAgencyDescription(event.DESCRIPTION)
  }  

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter as contas no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchAccountNumber") document.getElementById("searchAccountNumber") && 
          document.getElementById("searchAccountNumber").focus()
        else document.getElementById("searchAccountOwner") && document.getElementById("searchAccountOwner").focus()

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  { !showAgency ?
                      <>
                        <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean} 
                          getSGE={() => getSGE()} setReturn={setReturn} />

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                        </div>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                          { warning &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                            </div> 
                          }

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                              marginBottom: 10, marginLeft: 0}}>
                              <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                            </div>

                            <div style={(showMenu) ? (width < 1300) ? {display: "flex", flexDirection: "column", flexGrow: 1} :
                                  {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1} :
                                  {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", minWidth: 190}}>
                                <Input
                                  type="search"
                                  name="searchAccountOwner"
                                  description="Titular:"
                                  planceHolder="Mínimo 3 letras"
                                  value={owner}
                                  maxLength={50}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setOwner(event.target.value)} />
                              </div>

                              <div style={(showMenu) ? (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={(showMenu) ? (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10,
                                    minWidth: 190}
                                  : (width < 1300) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, 
                                    minWidth: 190, maxWidth: 190} 
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 10, minWidth: 190,
                                    maxWidth: 190}
                                :
                                  (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 190}
                                  : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", marginTop: 10, 
                                    minWidth: 190, maxWidth: 190} 
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "flex-end", paddingLeft: 10, minWidth: 190,
                                    maxWidth: 190}}>

                                  <Input
                                    type="search"
                                    name="searchAccountNumber"
                                    description="Número:"
                                    value={number}
                                    maxLength={10}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setNumber(event.target.value)} />
                                </div>

                                <div style={(showMenu) ? (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>

                                  <div style={{marginTop: 10, marginBottom: 10, minWidth: 190}}>
                                    <Titulo description="Agência" />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 190}}>
                                      <Input
                                        type="search"
                                        name="searchAccountDescriptionAgency"
                                        description="Descrição:"
                                        value={agencyDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setAgencyDescription(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 780) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                          marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                                      :
                                        (width < 490) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                          marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                                      <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                                        <img
                                          style={{height: 42, width: 42}}
                                          name="searchAccountSearch"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={() => enableAgency(true)} />
                                      </div>
                                      <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                                        <img
                                          style={{height: 42, width: 42}}
                                          name="searchAccountCleanAgency"
                                          src={image_clean} 
                                          alt="Limpar"
                                          onClick={cleanAgency} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            { showResult && 
                              <>
                                { result.OBJECT &&
                                  <>
                                    <div style={showMenu ?
                                        (width < 390) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 1,
                                            borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                            marginTop: 30, marginBottom: 8}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 1,
                                            borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                            marginTop: 30, marginBottom: 8}
                                      :                                      
                                        (width < 500) ?
                                          {display: "flex", flexDirection: "column", flexGrow: 1,
                                            borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                            marginTop: 30, marginBottom: 8}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 1,
                                            borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                            marginTop: 30, marginBottom: 8}}>

                                      <div style={showMenu ?
                                          (width < 1880) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                            paddingRight: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                                        :
                                          (width < 1610) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                            paddingRight: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}}>
                                        <div style={
                                          showMenu ?
                                            (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : 
                                            (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={
                                            showMenu ?
                                              (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                            : 
                                              (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                            <label style={{fontWeight: 600}}>Número</label>
                                          </div>

                                          <div style={
                                            showMenu ?
                                              (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, minWidth: 440}
                                            : 
                                              (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, minWidth: 440}}>
                                            <label style={{fontWeight: 600}}>Titular</label>
                                          </div>
                                        </div>

                                        <div style={
                                          showMenu ?
                                            (width < 1330) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                                            : (width < 1880) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          : 
                                            (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                                            : (width < 1610) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={
                                            showMenu ?
                                              (width < 1330) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : (width < 1880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                              : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}
                                            : 
                                              (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : (width < 1610) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                              : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}}>

                                            <label style={{fontWeight: 600}}>Agência</label>
                                          </div>

                                          <div style={
                                            showMenu ?
                                              (width < 1330) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : (width < 1880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                              : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}
                                            : 
                                              (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : (width < 1610) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                              : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}}>

                                            <label style={{fontWeight: 600}}>Banco</label>
                                          </div>
                                        </div>
                                      </div>

                                      { showMenu && (width >= 780) &&
                                        <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                          <div style={{display: "flex", alignItems: "center"}}>
                                            <div style={{height: 32, width: 32}} />

                                            <div style={{display: "flex", alignItems: "center"}}>
                                              { !modeSearch &&
                                                <div style={{height: 28, width: 28, marginTop: 2}} />
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      }

                                      { !showMenu && (width >= 520) &&
                                        <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                          <div style={{display: "flex", alignItems: "center"}}>
                                            <div style={{height: 32, width: 32}} />

                                            <div style={{display: "flex", alignItems: "center"}}>
                                              { !modeSearch &&
                                                <div style={{height: 28, width: 28, marginTop: 2}} />
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      }

                                    </div>

                                    { result.OBJECT.map(item => (
                                      <div key={item.ID} style={(showMenu) ?
                                          (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                            backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                            borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                            minWidth: 190}
                                          :
                                          {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                            borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                            paddingTop: 4, paddingBottom: 4, marginTop: 4 }
                                        :
                                          (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                            backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                            borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                            minWidth: 190}
                                          :
                                          {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                            borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                            paddingTop: 4, paddingBottom: 4, marginTop: 4 }}>

                                        <div style={showMenu ?
                                            (width < 1880) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                              paddingRight: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}
                                          :
                                            (width < 1610) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, 
                                              paddingRight: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 1}}>
                                          <div style={
                                            showMenu ?
                                              (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : 
                                              (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={
                                              showMenu ?
                                                (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                              : 
                                                (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                              <label>{item.NUMBER}</label>
                                            </div>

                                            <div style={
                                              showMenu ?
                                                (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 440}
                                              : 
                                                (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 440}}>
                                              <label>{item.OWNER}</label>
                                            </div>
                                          </div>

                                          <div style={
                                            showMenu ?
                                              (width < 1330) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                                              : (width < 1880) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            : 
                                              (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                                              : (width < 1610) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={
                                              showMenu ?
                                                (width < 1330) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                                : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}
                                              : 
                                                (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1610) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                                : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}}>

                                              <label>{item.AGENCY.DESCRIPTION}</label>
                                            </div>

                                            <div style={
                                              showMenu ?
                                                (width < 1330) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1880) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                                : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}
                                              : 
                                                (width < 1050) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1610) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 440}
                                                : {display: "flex", flexGrow: 0, minWidth: 440, maxWidth: 440}}>

                                              <label>{item.AGENCY.BANK.DESCRIPTION}</label>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={showMenu ?
                                            (width < 780) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                            : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                          : (width < 520) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                            : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>

                                          <div style={{display: "flex", alignItems: "center", padding: 5}}>
                                            <img 
                                              style={{height: 32, width: 32}}
                                              name="searchAccountSelected"
                                              src={image_select}
                                              alt="Selecionar"
                                              onClick={(() => setSelected(item))} />
                                          </div>
                                          <div style={{display: "flex", alignItems: "center", padding: 5}}>
                                            { !modeSearch &&
                                              <img 
                                                style={{height: 28, width: 28, marginTop: 2}}
                                                name="searchAccountDelete"
                                                src={image_delete}
                                                alt="Deletar"
                                                onClick={() => callDelete(item)} />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      ))
                                    }

                                    <SearchPagination
                                      pageCurrent={pageCurrent}
                                      totalPage={totalPage}
                                      recordPage={recordPage}
                                      optionsRegPage={JSON.parse(optionsRegPage)}
                                      pagePrevious={pagePrevious}
                                      pageNext={pageNext}
                                      setSelectedField={setFieldSelected}
                                      handleRecordPage={handleRecordPage}
                                      width={width} />
                                  </>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </>
                    :
                      <div style={{marginTop: 10}}>
                        <div style={{marginLeft: 10}}>
                          <b>Selecione a agência:</b>
                        </div>

                        <SearchAgency
                          modeSearch={true}
                          databaseType={databaseType}
                          databasePath={databasePath}
                          jsonCompany={jsonCompany}
                          jsonLogin={jsonLogin}
                          system={system}
                          setAdd={() => alert("")}
                          setSelected={fillAgency} 
                          setReturn={() => enableAgency(false)}
                          showMenu={showMenu}
                          width={width} />
                      </div>
                  }
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchAccount