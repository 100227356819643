import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Titulo from "../../Components/Titulo"
import SearchNoteService from "./Search"
import image_arrow_down from "../../Images/Buttons/arrow_down.png";
import image_cancel from "../../Images/Buttons/cancel.png";
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_receive from "../../Images/Buttons/receive.png"
import image_search from "../../Images/Buttons/search.png"
import image_send_email from "../../Images/Buttons/send_email.png"

const NoteService = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, noteService, setNoteService, height, width, 
  logoff}) => {

  const [code, setCode] = useState("")
  const [billReceiveCode, setBillReceiveCode] = useState("")
  const [billReceiveNumber, setBillReceiveNumber] = useState("")
  const [billReceivePortion, setBillReceivePortion] = useState("")  
  const [billReceiveDueDate, setBillReceiveDueDate] = useState("")

  const [companyCpfCnpj, setCompanyCpfCnpj] = useState("")
  const [companyIm, setCompanyIm] = useState("")
  const [companySimples, setCompanySimples] = useState(0)
  const [companyRegime, setCompanyRegime] = useState(0)
  const [companyCulture, setCompanyCulture] = useState(0)

  const [clientCode, setClientCode] = useState("")
  const [clientCpfCnpj, setClientCpfCnpj] = useState("")
  const [clientCorporateName, setClientCorporateName] = useState("")
  const [clientIm, setClientIm] = useState("")
  const [clientCep, setClientCep] = useState("")
  const [clientComplement, setClientComplement] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [clientNumber, setClientNumber] = useState("")
  const [clientNeighborhood, setClientNeighborhood] = useState("")
  const [clientCity, setClientCity] = useState("")
  const [clientIbgeCity, setClientIbgeCity] = useState("")
  const [clientUf, setClientUf] = useState("")
  const [clientPhone, setClientPhone] = useState("")  
  const [clientEmail, setClientEmail] = useState("")

  const [serviceCnaeCode, setServiceCnaeCode] = useState("")
  const [serviceCnaeDescription, setServiceCnaeDescription] = useState("")
  const [jsonServiceCnae, setJsonServiceCnae] = useState([])
  const [optionsServiceCnae, setOptionsServiceCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [serviceCodeCnaeCode, setServiceCodeCnaeCode] = useState("")
  const [serviceCodeCnaeDescription, setServiceCodeCnaeDescription] = useState("")
  const [jsonServiceCodeCnae, setJsonServiceCodeCnae] = useState([])
  const [optionsServiceCodeCnae, setOptionsServiceCodeCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [serviceItemCnaeCode, setServiceItemCnaeCode] = useState("")
  const [serviceItemCnaeDescription, setServiceItemCnaeDescription] = useState("")
  const [jsonServiceItemCnae, setJsonServiceItemCnae] = useState([])
  const [optionsServiceItemCnae, setOptionsServiceItemCnae] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [date, setDate] = useState("")
  const [competencyDate, setCompetencyDate] = useState("")
  const [transmissionDate, setTransmissionDate] = useState("")
  const [cancellationDate, setCancellationDate] = useState("")

  const [lot, setLot] = useState("")
  const [number, setNumber] = useState("")
  const [series, setSeries] = useState("")
  const [type, setType] = useState(0)
  const [highlightRetaion, setHighlightRetaion] = useState(0)
  const [nature, setNature] = useState(0)
  const [value, setValue] = useState(0)
  const [valueDeduction, setValueDeduction] = useState(0)
  const [valuePis, setValuePis] = useState(0)
  const [valueCofins, setValueCofins] = useState(0)
  const [valueInss, setValueInss] = useState(0)
  const [valueIr, setValueIr] = useState(0)
  const [valueCsll, setValueCsll] = useState(0)
  const [valueIssRetain, setValueIssRetain] = useState(0)
  const [valueIss, setValueIss] = useState(0)
  const [valueOther, setValueOther] = useState(0)
  const [base, setBase] = useState(0)
  const [aliquot, setAliquot] = useState(0)
  const [valueLiquid, setValueLiquid] = useState(0)
  const [valueUnconditioned, setValueUnconditioned] = useState(0)
  const [valueConditioning, setValueConditioning] = useState(0)
  const [returnError, setReturnError] = useState("")
  const [protocol, setProtocol] = useState("")
  const [xml, setXml] = useState("")
  const [xmlReturn, setXmlReturn] = useState("")
  const [xmlCancellation, setXmlCancellation] = useState("")
  const [verificationCode, setVerificationCode] = useState("")
  const [automatic, setAutomatic] = useState(0)
  const [environment, setEnvironment] = useState("")
  const [situation, setSituation] = useState(0)

  const [jsonNoteService, setJsonNoteService] = useState("")

  const [showMenu, setShowMenu] = useState(true)  
  const [firstAcess, setFirstAcess] = useState(true)
  const [showSearch, setShowSearch] = useState((!noteService) ? true : false)
  const [showChange, setShowChange] = useState((!noteService) ? false : true)

  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
    
  useEffect(() => {
    if (firstAcess){
      let returnJson = []
      
      ///
      // Get information from the Service CNAE // Carrega informações do CNAE do serviço
      ///
      setLoading("Aguarde buscando as informações dos CNAEs dos serviços...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonServiceCnae(returnJson.OBJECT)

          let serviceCnae = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              serviceCnae += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
            ))
          serviceCnae += "]}"

          setOptionsServiceCnae(serviceCnae)

          ////
          // Get information from the Code Service CNAE // Carrega informações do Código do Serviço CNAE
          ////
          setLoading("Aguarde buscando as informações dos Códigos dos Serviços CNAE...")      
          setWarning("")
          setError("")

          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", "{}")
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)

          axios.post(BASE_URL + "SERVICE_CODE_CNAE/Search", formData) 
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os Códigos dos Serviços CNAE. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setError(returnJson.ERROR)
              setLoading("")
              return
            }
            else
            {
              setJsonServiceCodeCnae(returnJson.OBJECT)

              ////
              // Get information from the Item Service CNAE // Carrega informações do Item do Serviço CNAE
              ///
              setLoading("Aguarde buscando as informações dos Itens dos Serviços CNAE...")      
              setWarning("")
              setError("")

              formData = new FormData()
              formData.append("DATABASE_TYPE", databaseType)
              formData.append("DATABASE_PATH", databasePath)
              formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }")
              formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
              formData.append("SYSTEM", system)

              axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Search", formData) 
              .then((response) => { returnJson = JSON.parse(response.data) })
              .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
              ", \"ERROR\": \"Não foi possível pesquisar os Itens dos Serviços CNAE. ("+ response +")\"}") })
              .finally(() => {
                if (returnJson.SUCCESS === false){
                  setError(returnJson.ERROR)
                  setLoading("")
                  return
                }
                else
                {
                  setJsonServiceItemCnae(returnJson.OBJECT)
                  
                  if (noteService) {             
                    selected(noteService)
                    setNoteService(null)
                  }

                  setLoading("")
                  setFirstAcess(false)
                }
              })
            }
          })
        }
      })
    }
    else
    {
      if (((jsonNoteService !== undefined) && (jsonNoteService !== null)) && (jsonNoteService !== "")){
        setCode(jsonNoteService.ID)
        setBillReceiveCode(jsonNoteService.BILL_RECEIVE.ID)

        const dueDate = jsonNoteService.BILL_RECEIVE.DUE_DATE.substr(8,2) + "/" + 
          jsonNoteService.BILL_RECEIVE.DUE_DATE.substr(5,2) + "/" +
            jsonNoteService.BILL_RECEIVE.DUE_DATE.substr(0,4);
        setBillReceiveDueDate(dueDate)

        setBillReceiveNumber(jsonNoteService.BILL_RECEIVE.NUMBER)
        setBillReceivePortion(jsonNoteService.BILL_RECEIVE.PORTION)

        const date = jsonNoteService.DATE.substr(8,2) + "/" + jsonNoteService.DATE.substr(5,2) + "/" +
          jsonNoteService.DATE.substr(0,4);

        setDate(date)

        setLot(jsonNoteService.LOT)
        setNumber(jsonNoteService.NUMBER)
        setSeries(jsonNoteService.SERIES)
        setType(jsonNoteService.TYPE)
        setNature(jsonNoteService.NATURE)
        setCompanyRegime(jsonNoteService.REGIME)
        setCompanySimples(jsonNoteService.SIMPLES)
        setCompanyCulture(jsonNoteService.CULTURE)
        setHighlightRetaion(jsonNoteService.HIGHLIGHT_RETAIN)
        setValue(formatNumeric(jsonNoteService.VALUE, 2))

        if (jsonNoteService.VALUE_DEDUCTION === 0) setValueDeduction("0.00")
        else setValueDeduction(formatNumeric(jsonNoteService.VALUE_DEDUCTION, 2))

        if (jsonNoteService.VALUE_PIS === 0) setValuePis("0.00")
        else setValuePis(formatNumeric(jsonNoteService.VALUE_PIS, 2))

        if (jsonNoteService.VALUE_COFINS === 0) setValueCofins("0.00")
        else setValueCofins(formatNumeric(jsonNoteService.VALUE_COFINS, 2))

        if (jsonNoteService.VALUE_INSS === 0) setValueInss("0.00")
        else setValueInss(formatNumeric(jsonNoteService.VALUE_INSS, 2))

        if (jsonNoteService.VALUE_IR === 0) setValueIr("0.00")
        else setValueIr(formatNumeric(jsonNoteService.VALUE_IR, 2))

        if (jsonNoteService.VALUE_CSLL === 0) setValueCsll("0.00")
        else setValueCsll(formatNumeric(jsonNoteService.VALUE_CSLL, 2))

        if (jsonNoteService.VALUE_ISS_RETAINED === 0) setValueIssRetain("0.00")
        else setValueIssRetain(formatNumeric(jsonNoteService.VALUE_ISS_RETAINED, 2))

        if (jsonNoteService.VALUE_ISS === 0) setValue("0.00")
        else setValueIss(formatNumeric(jsonNoteService.VALUE_ISS, 2))

        if (jsonNoteService.VALUE_OTHER_RETENTIONS === 0) setValueOther("0.00")
        else setValueOther(formatNumeric(jsonNoteService.VALUE_OTHER_RETENTIONS, 2))

        if (jsonNoteService.BASE_CALCULATION === 0) setBase("0.00")
        else setBase(formatNumeric(jsonNoteService.BASE_CALCULATION, 2))

        if (jsonNoteService.SERVICE_ITEM_CNAE.ALIQUOT === 0) setAliquot("0.00")
        else setAliquot(formatNumeric(jsonNoteService.SERVICE_ITEM_CNAE.ALIQUOT, 2))

        if (jsonNoteService.VALUE_LIQUID === 0) setValueLiquid("0.00")
        else setValueLiquid(formatNumeric(jsonNoteService.VALUE_LIQUID, 2))

        if (jsonNoteService.VALUE_UNCONDITIONED === 0) setValueUnconditioned("0.00")
        else setValueUnconditioned(formatNumeric(jsonNoteService.VALUE_UNCONDITIONED, 2))

        if (jsonNoteService.VALUE_CONDITIONED === 0) setValueConditioning("0.00")
        else setValueConditioning(formatNumeric(jsonNoteService.VALUE_CONDITIONED, 2))

        setServiceItemCnaeCode(jsonNoteService.SERVICE_ITEM_CNAE.CODE)
        setServiceItemCnaeDescription(jsonNoteService.SERVICE_ITEM_CNAE.DESCRIPTION)

        setServiceCodeCnaeCode(jsonNoteService.SERVICE_ITEM_CNAE.SERVICE_CODE_CNAE.CODE)
        setServiceCodeCnaeDescription(jsonNoteService.SERVICE_ITEM_CNAE.SERVICE_CODE_CNAE.DESCRIPTION)

        setServiceCnaeCode(jsonNoteService.SERVICE_ITEM_CNAE.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE)
        setServiceCnaeDescription(jsonNoteService.SERVICE_ITEM_CNAE.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION)

        setCompanyCpfCnpj(jsonNoteService.BILL_RECEIVE.COMPANY.CPF_CNPJ)
        setCompanyIm(jsonNoteService.BILL_RECEIVE.COMPANY.IM)
          
        ////
        /// Client // Cliente
        ////
        setClientCode(jsonNoteService.BILL_RECEIVE.CLIENT.ID)
        setClientCpfCnpj(jsonNoteService.BILL_RECEIVE.CLIENT.CPF_CNPJ)
        setClientCorporateName(jsonNoteService.BILL_RECEIVE.CLIENT.CORPORATE_NAME)
        setClientCep(jsonNoteService.BILL_RECEIVE.CLIENT.CEP)
        setClientAddress(jsonNoteService.BILL_RECEIVE.CLIENT.ADDRESS)
        setClientNumber(jsonNoteService.BILL_RECEIVE.CLIENT.NUMBER)

        if ((jsonNoteService.BILL_RECEIVE.CLIENT.COMPLEMENT !== undefined) && (jsonNoteService.BILL_RECEIVE.CLIENT.COMPLEMENT !== null))
          setClientComplement(jsonNoteService.BILL_RECEIVE.CLIENT.COMPLEMENT)
        else setClientComplement("")

        setClientNeighborhood(jsonNoteService.BILL_RECEIVE.CLIENT.NEIGHBORHOOD)
        setClientCity(jsonNoteService.BILL_RECEIVE.CLIENT.CITY)
        setClientIbgeCity(jsonNoteService.BILL_RECEIVE.CLIENT.CITY_IBGE)
        setClientUf(jsonNoteService.BILL_RECEIVE.CLIENT.UF)

        if ((jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== undefined) && (jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== null))
          setClientPhone(jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_PHONE)
        else setClientPhone("")

        if ((jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== undefined) && (jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== null))
          setClientEmail(jsonNoteService.BILL_RECEIVE.CLIENT.STANDARD_EMAIL)
        else setClientEmail("")

        if ((jsonNoteService.BILL_RECEIVE.CLIENT.IM !== undefined) && (jsonNoteService.BILL_RECEIVE.CLIENT.IM !== null))
          setClientIm(jsonNoteService.BILL_RECEIVE.CLIENT.IM)
        else setClientIm("")      
        ///

        if ((jsonNoteService.PROTOCOL !== undefined) && (jsonNoteService.PROTOCOL !== null)) setProtocol(jsonNoteService.PROTOCOL)
        else setProtocol("")

        if ((jsonNoteService.TRANSMISSION_DATE !== undefined) && (jsonNoteService.TRANSMISSION_DATE !== null))
        {
          if (jsonNoteService.TRANSMISSION_DATE !== "0001-01-01T00:00:00") {
            const transmissionD = jsonNoteService.TRANSMISSION_DATE.substr(8,2) + "/" +
            jsonNoteService.TRANSMISSION_DATE.substr(5,2) + "/" +
              jsonNoteService.TRANSMISSION_DATE.substr(0,4) + " " +
                jsonNoteService.TRANSMISSION_DATE.substr(11,8);
            setTransmissionDate(transmissionD)
          }
        } else setTransmissionDate("")

        if ((jsonNoteService.COMPETENCE_DATE !== undefined) && (jsonNoteService.COMPETENCE_DATE !== null))
        {
          if (jsonNoteService.COMPETENCE_DATE !== "0001-01-01T00:00:00") {
            const dataComp = jsonNoteService.COMPETENCE_DATE.substr(8,2) + "/" + jsonNoteService.COMPETENCE_DATE.substr(5,2) + "/" +
              jsonNoteService.COMPETENCE_DATE.substr(0,4) + " " + jsonNoteService.COMPETENCE_DATE.substr(11,8);
            setCompetencyDate(dataComp)
          }
        } else setCompetencyDate("")

        if ((jsonNoteService.CANCELED_DATE !== undefined) && (jsonNoteService.CANCELED_DATE !== null))
        {
          if (jsonNoteService.CANCELED_DATE !== "0001-01-01T00:00:00") {
            const dataCanc = jsonNoteService.CANCELED_DATE.substr(8,2) + "/" + jsonNoteService.CANCELED_DATE.substr(5,2) + "/" +
              jsonNoteService.CANCELED_DATE.substr(0,4) + " " + jsonNoteService.CANCELED_DATE.substr(11,8);
            setCancellationDate(dataCanc)
          }
        } else setCancellationDate("")
          
        if ((jsonNoteService.VERIFICATION_CODE !== undefined) && (jsonNoteService.VERIFICATION_CODE !== null)) 
          setVerificationCode(jsonNoteService.VERIFICATION_CODE)
        else setVerificationCode("")

        if ((jsonNoteService.XML !== undefined) && (jsonNoteService.XML !== null)) setXml(jsonNoteService.XML); else setXml("")

        if ((jsonNoteService.XML_RETURN !== undefined) && (jsonNoteService.XML_RETURN !== null)) 
          setXmlReturn(jsonNoteService.XML_RETURN)
        else setXmlReturn("")

        if ((jsonNoteService.XML_CANCELLATION !== undefined) && (jsonNoteService.XML_CANCELLATION !== null)) 
          setXmlCancellation(jsonNoteService.XML_CANCELLATION)
        else setXmlCancellation("")

        if ((jsonNoteService.ERROR !== undefined) && (jsonNoteService.ERROR !== null)) setReturnError(jsonNoteService.ERROR); else setReturnError("")

        setAutomatic(jsonNoteService.AUTOMATIC)
        setEnvironment(jsonNoteService.ENVIRONMENT)
        setSituation(jsonNoteService.SITUATION)
      }
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, jsonNoteService, firstAcess])

  const downloadTxtFile = (type) => {
    const element = document.createElement("a");

    let file;

    if (type === 1)
    {
      file = new Blob([xml], {
        type: "text/plain"
      });
    }
    else if (type === 2)
    {
      file = new Blob([xmlCancellation], {
        type: "text/plain"
      });
    }

    element.href = URL.createObjectURL(file);
    element.download =  companyCpfCnpj + "_" + number + "_" + series + ".xml";
    document.body.appendChild(element);
    element.click();
  }

  const fieldSelected = () => {
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const process = (type) => {
    ////
    // Type: 1 - Send, 2 - Search, 3 - Cancel
    // Tipo: 1 - Enviar, 2 - Consultar, 3 - Cancelar
    ////
    let returnJson = []
    let jsonNoteService_New = {}

    setWarning("")
    setError("")

    if (type === 1) setLoading("Aguarde emitindo a nota...")
    else if (type === 2) setLoading("Aguarde consultando o status da nota...")
    else if (type === 3) setLoading("Aguarde cancelando a nota...")
     
    ////
    // New // Novo
    ////
    jsonNoteService_New = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode

    if (validateDate(billReceiveDueDate)) {
      const dueDate = String(billReceiveDueDate).substr(6,4) + "-" + String(billReceiveDueDate).substr(3,2) + "-" + String(billReceiveDueDate).substr(0,2) + "T00:00:00";
      jsonNoteService_New += ", \"DUE_DATE\": \""+ dueDate + "\""
    }

    jsonNoteService_New += ", \"NUMBER\": \""+ billReceiveNumber +"\"" +
    ", \"PORTION\": \""+ billReceivePortion +"\""

    jsonNoteService_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
    ", \"CPF_CNPJ\": \""+ jsonCompany.CPF_CNPJ + "\"" +
    ", \"CORPORATE_NAME\": \""+ jsonCompany.CORPORATE_NAME + "\"" +
    ", \"FANTASY_NAME\": \""+ jsonCompany.FANTASY_NAME +"\"" +
    ", \"CEP\": \""+ jsonCompany.CEP +"\"" +
    ", \"ADDRESS\": \""+ jsonCompany.ADDRESS +"\"" +
    ", \"NUMBER\": \""+ jsonCompany.NUMBER +"\""

    if ((jsonCompany.COMPLEMENT !== undefined) && (jsonCompany.COMPLEMENT !== "")) 
      jsonNoteService_New += ", \"COMPLEMENT\": \"" + jsonCompany.COMPLEMENT + "\""

    jsonNoteService_New += ", \"NEIGHBORHOOD\": \"" + jsonCompany.NEIGHBORHOOD + "\"" +
    ", \"CITY\": \"" + jsonCompany.CITY + "\"" +
    ", \"CITY_IBGE\": \"" + jsonCompany.CITY_IBGE + "\"" +
    ", \"UF\": \"" + jsonCompany.UF + "\""

    if ((jsonCompany.STANDARD_PHONE !== undefined) && (jsonCompany.STANDARD_PHONE !== "")) 
      jsonNoteService_New += ", \"STANDARD_PHONE\": \"" + jsonCompany.STANDARD_PHONE + "\""
        
    jsonNoteService_New += ", \"IM\": \""+ companyIm + "\" }" +

    ", \"CLIENT\": { \"ID\": "+ clientCode +
    ", \"CPF_CNPJ\": \""+ clientCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \""+ clientCorporateName + "\"" +
    ", \"ADDRESS\": \""+ clientAddress + "\"" +
    ", \"NUMBER\": \""+ clientNumber + "\""

    if ((clientComplement !== undefined) && (clientComplement !== "")) jsonNoteService_New += ", \"COMPLEMENT\": \""+ clientComplement +"\""

    jsonNoteService_New += ", \"NEIGHBORHOOD\": \""+ clientNeighborhood +"\"" +
    ", \"CITY\": \""+ clientCity +"\"" +
    ", \"CITY_IBGE\": \""+ clientIbgeCity +"\"" +
    ", \"UF\": \""+ clientUf +"\"" +
    ", \"CEP\": \""+ clientCep +"\""

    if ((clientPhone !== undefined) && (clientPhone !== "")) jsonNoteService_New += ", \"TELEFONE_PADRAO\": \""+ clientPhone +"\""
    if ((clientEmail !== undefined) && (clientEmail !== "")) jsonNoteService_New += ", \"EMAIL_PADRAO\": \""+ clientEmail +"\""
    if ((clientIm !== undefined) && (clientIm !== "")) jsonNoteService_New += ", \"IM\": \""+ clientIm +"\""

    jsonNoteService_New += ", \"SERVICE_ITEM_CNAE\": {"+
    " \"CODE\": \""+ serviceItemCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceItemCnaeDescription + "\"" +
    ", \"ALIQUOT\": \""+ aliquot + "\"" +
    ", \"SERVICE_CODE_CNAE\": {"+
    " \"CODE\": \""+ serviceCodeCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCodeCnaeDescription + "\"" +
    ", \"SERVICE_CNAE\": {"+
    " \"CODE\": \""+ serviceCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCnaeDescription + "\" } } } } }"

    if (validateDate(date)) {
      const emissionDate = String(date).substr(6,4) + "-" + String(date).substr(3,2) + "-" + String(date).substr(0,2) + "T00:00:00";
      jsonNoteService_New += ", \"DATE\": \""+ emissionDate + "\""
    }

    jsonNoteService_New += ", \"LOT\": \""+ lot +"\"" +
    ", \"NUMBER\": \""+ number +"\"" +
    ", \"SERIES\": \""+ series +"\"" +
    ", \"TYPE\": "+ type +
    ", \"NATURE\": "+ nature +
    ", \"REGIME\": "+ companyRegime +
    ", \"SIMPLES\": "+ companySimples +
    ", \"CULTURE\": "+ companyCulture +
    ", \"VALUE\": "+ value +
    ", \"VALUE_DEDUCTION\": "+ valueDeduction +
    ", \"VALUE_PIS\": "+ valuePis +
    ", \"VALUE_COFINS\": "+ valueCofins +
    ", \"VALUE_INSS\": "+ valueInss +
    ", \"VALUE_IR\": "+ valueIr +
    ", \"VALUE_CSLL\": "+ valueCsll +
    ", \"VALUE_ISS_RETAINED\": "+ valueIssRetain +
    ", \"VALUE_ISS\": "+ valueIss +
    ", \"VALUE_OTHER_RETENTIONS\": "+ valueOther +
    ", \"BASE_CALCULATION\": "+ base +
    ", \"VALUE_LIQUID\": "+ valueLiquid +
    ", \"VALUE_UNCONDITIONED\": "+ valueUnconditioned +
    ", \"VALUE_CONDITIONED\": "+ valueConditioning +
    ", \"NATURE_SERVICE\": \""+ nature + "\"" +
    ", \"HIGHLIGHT_RETAIN\": "+ highlightRetaion +
    ", \"SERVICE_ITEM_CNAE\": {"+
    " \"CODE\": \""+ serviceItemCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceItemCnaeDescription + "\"" +
    ", \"ALIQUOT\": \""+ aliquot + "\"" +
    ", \"SERVICE_CODE_CNAE\": {"+
    " \"CODE\": \""+ serviceCodeCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCodeCnaeDescription + "\"" +
    ", \"SERVICE_CNAE\": {"+
    " \"CODE\": \""+ serviceCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCnaeDescription + "\" } } }"

    if ((protocol !== undefined) && (protocol !== "")) jsonNoteService_New += ", \"PROTOCOL\": \""+ protocol +"\""

    if (validateDate(transmissionDate)) {
      const transmissionD = String(transmissionDate).substr(6,4) + "-" + String(transmissionDate).substr(3,2) + "-" + String(transmissionDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"TRANSMISSION_DATE\": \""+ transmissionD + "\""
    }

    if (validateDate(competencyDate)) {
      const competencyD = String(competencyDate).substr(6,4) + "-" + String(competencyDate).substr(3,2) + "-" + String(competencyDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"COMPETENCE_DATE\": \""+ competencyD + "\""
    }

    if (validateDate(cancellationDate)) {
      const cancellationD = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" + String(cancellationDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"CANCELED_DATE\": \""+ cancellationD + "\""
    }

    if ((verificationCode !== undefined) && (verificationCode !== "")) jsonNoteService_New += ", \"VERIFICATION_CODE\": \""+ verificationCode +"\""
    if ((xml !== undefined) && (xml !== "")) jsonNoteService_New += ", \"XML\": \""+ xml.replaceAll("\"","'")  +"\""
    if ((xmlReturn !== undefined) && (xmlReturn !== "")) jsonNoteService_New += ", \"XML_RETURN\": \""+ xmlReturn.replaceAll("\"","'") +"\""

    if ((xmlCancellation !== undefined) && (xmlCancellation !== ""))
       jsonNoteService_New += ", \"XML_CANCELLATION\": \""+ xmlCancellation.replaceAll("\"","'") +"\""

    if ((returnError !== undefined) && (returnError !== "")) jsonNoteService_New += ", \"ERROR\": \""+ returnError.replaceAll("\"","'") +"\""

    jsonNoteService_New += ", \"AUTOMATIC\": "+ automatic
    jsonNoteService_New += ", \"ENVIRONMENT\": "+ environment
    jsonNoteService_New += ", \"SITUATION\": "+ situation
    jsonNoteService_New += " }"
    //

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonNoteService_New)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    if (type === 1){
      axios.put(BASE_URL + "NOTE_SERVICE/Send", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível emitir a Nota Fiscal de Serviço. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }
  
        setError("")
        setLoading("")
  
        if (((returnJson.OBJECT.ERROR !== "undefined") && (returnJson.OBJECT.ERROR !== null)) && (returnJson.OBJECT.ERROR !== ""))
          setError("Não foi possível emitir (Número:" + returnJson.OBJECT.NUMBER + " / Série:" + returnJson.OBJECT.SERIES +")!")
        else setWarning("Emissão efetuada com sucesso (Número:" + returnJson.OBJECT.NUMBER + " / Série:" + returnJson.OBJECT.SERIES +")!")
  
        setJsonNoteService(returnJson.OBJECT)
      })
    }
    else if (type === 2){
      axios.post(BASE_URL + "NOTE_SERVICE/SearchStatus", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível consultar a Nota Fiscal de Serviço. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }
  
        setError("")
        setLoading("")
        setWarning("Consulta do status efetuada com sucesso (Número:" + returnJson.OBJECT.NUMBER + " / Série:" + returnJson.OBJECT.SERIES +")!")
  
        setJsonNoteService(returnJson.OBJECT)
      })
    }
    else if (type === 3){
      axios.put(BASE_URL + "NOTE_SERVICE/Cancel" , formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível cancelar a Nota Fiscal de Serviço. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }
  
        setError("")
        setLoading("")
        setWarning("Envio do cancelamento efetuada com sucesso (Número:" + returnJson.OBJECT.NUMBER + " / Série:" + returnJson.OBJECT.SERIES +")!")
  
        setJsonNoteService(returnJson.OBJECT)
      })
    }    
  }

  const reSendEmail = () => {
    let returnJson = []
    let jsonNoteService_New = {}
    
    setWarning("")
    setError("")
    setLoading("Aguarde re-enviando a NFSe...")
    
    //
    jsonNoteService_New = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode

    if (validateDate(billReceiveDueDate)) {
      const billReceiveDueD = String(billReceiveDueDate).substr(6,4) + "-" + String(billReceiveDueDate).substr(3,2) + "-" + 
        String(billReceiveDueDate).substr(0,2) + "T00:00:00";
      jsonNoteService_New += ", \"DUE_DATE\": \""+ billReceiveDueD + "\""
    }

    jsonNoteService_New += ", \"NUMBER\": \""+ billReceiveNumber +"\"" +
    ", \"PORTION\": \""+ billReceivePortion +"\""

    jsonNoteService_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
    ", \"CPF_CNPJ\": \""+ jsonCompany.CPF_CNPJ + "\"" +
    ", \"CORPORATE_NAME\": \""+ jsonCompany.CORPORATE_NAME + "\"" +
    ", \"FANTASY_NAME\": \""+ jsonCompany.FANTASY_NAME +"\"" +
    ", \"CEP\": \""+ jsonCompany.CEP +"\"" +
    ", \"ADDRESS\": \""+ jsonCompany.ADDRESS +"\"" +
    ", \"NUMBER\": \""+ jsonCompany.NUMBER +"\""

    if ((jsonCompany.COMPLEMENT !== null) && (jsonCompany.COMPLEMENT !== undefined))
      jsonNoteService_New += ", \"COMPLEMENT\": \"" + jsonCompany.COMPLEMENT + "\""

    jsonNoteService_New += ", \"NEIGHBORHOOD\": \"" + jsonCompany.NEIGHBORHOOD + "\"" +
    ", \"CITY\": \"" + jsonCompany.CITY + "\"" +
    ", \"CITY_IBGE\": \"" + jsonCompany.CITY_IBGE + "\"" +
    ", \"UF\": \"" + jsonCompany.UF + "\""

    if ((jsonCompany.STANDARD_PHONE !== null) && (jsonCompany.STANDARD_PHONE !== undefined))
      jsonNoteService_New += ", \"STANDARD_PHONE\": \"" + jsonCompany.STANDARD_PHONE + "\""
        
    jsonNoteService_New += ", \"IM\": \""+ companyIm + "\" }" +

    ", \"CLIENT\": { \"ID\": "+ clientCode +
    ", \"CPF_CNPJ\": \""+ clientCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \""+ clientCorporateName + "\"" +
    ", \"ADDRESS\": \""+ clientAddress + "\"" +
    ", \"NUMBER\": \""+ clientNumber + "\""

    if (((clientComplement !== undefined) && (clientComplement !== null)) && (clientComplement !== ""))
      jsonNoteService_New += ", \"COMPLEMENT\": \""+ clientComplement +"\""

    jsonNoteService_New += ", \"NEIGHBORHOOD\": \""+ clientNeighborhood +"\"" +
    ", \"CITY\": \""+ clientCity +"\"" +
    ", \"CITY_IBGE\": \""+ clientIbgeCity +"\"" +
    ", \"UF\": \""+ clientUf +"\"" +
    ", \"CEP\": \""+ clientCep +"\""

    if (((clientPhone !== undefined) && (clientPhone !== null)) && (clientPhone !== "")) 
      jsonNoteService_New += ", \"STANDARD_PHONE\": \""+ clientPhone +"\""

    if (((clientEmail !== undefined) && (clientEmail !== null)) && (clientEmail !== "")) 
      jsonNoteService_New += ", \"STANDARD_EMAIL\": \""+ clientEmail +"\""

    if (((clientIm !== undefined) && (clientIm !== null)) && (clientIm !== "")) jsonNoteService_New += ", \"IM\": \""+ clientIm +"\""

    jsonNoteService_New += ", \"SERVICE_ITEM_CNAE\": {"+
    " \"CODE\": \""+ serviceItemCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceItemCnaeDescription + "\"" +
    ", \"ALIQUOT\": \""+ aliquot + "\"" +
    ", \"SERVICE_CODE_CNAE\": {"+
    " \"CODE\": \""+ serviceCodeCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCodeCnaeDescription + "\"" +
    ", \"SERVICE_CNAE\": {"+
    " \"CODE\": \""+ serviceCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCnaeDescription + "\" } } } } }"
                             
    if (validateDate(date)) {
      const emissionDate = String(date).substr(6,4) + "-" + String(date).substr(3,2) + "-" + String(date).substr(0,2) + "T00:00:00";
      jsonNoteService_New += ", \"DATE\": \""+ emissionDate + "\""
    }

    jsonNoteService_New += ", \"LOT\": \""+ lot +"\"" +
    ", \"NUMBER\": \""+ number +"\"" +
    ", \"SERIES\": \""+ series +"\"" +
    ", \"TYPE\": "+ type +
    ", \"NATURE\": "+ nature +
    ", \"REGIME\": "+ companyRegime +
    ", \"SIMPLES\": "+ companySimples +
    ", \"CULTURE\": "+ companyCulture +
    ", \"VALUE\": "+ value +
    ", \"VALUE_DEDUCTION\": "+ valueDeduction +
    ", \"VALUE_PIS\": "+ valuePis +
    ", \"VALUE_COFINS\": "+ valueCofins +
    ", \"VALUE_INSS\": "+ valueInss +
    ", \"VALUE_IR\": "+ valueIr +
    ", \"VALUE_CSLL\": "+ valueCsll +
    ", \"VALUE_ISS_RETAINED\": "+ valueIssRetain +
    ", \"VALUE_ISS\": "+ valueIss +
    ", \"VALUE_OTHER_RETENTIONS\": "+ valueOther +
    ", \"BASE_CALCULATION\": "+ base +
    ", \"VALUE_LIQUID\": "+ valueLiquid +
    ", \"VALUE_UNCONDITIONED\": "+ valueUnconditioned +
    ", \"VALUE_CONDITIONED\": "+ valueConditioning +
    ", \"NATURE_SERVICE\": \""+ nature + "\"" +
    ", \"HIGHLIGHT_RETAIN\": "+ highlightRetaion +

    ", \"SERVICE_ITEM_CNAE\": {"+
    " \"CODE\": \""+ serviceItemCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceItemCnaeDescription + "\"" +
    ", \"ALIQUOT\": \""+ aliquot + "\"" +
    ", \"SERVICE_CODE_CNAE\": {"+
    " \"CODE\": \""+ serviceCodeCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCodeCnaeDescription + "\"" +
    ", \"SERVICE_CNAE\": {"+
    " \"CODE\": \""+ serviceCnaeCode + "\"" +
    ", \"DESCRIPTION\": \""+ serviceCnaeDescription + "\" } } }"

    if (((protocol !== undefined) && (protocol !== null)) && (protocol!== "")) jsonNoteService_New += ", \"PROTOCOL\": \""+ protocol +"\""

    if (validateDate(transmissionDate)) {
      const transmissionD = String(transmissionDate).substr(6,4) + "-" + String(transmissionDate).substr(3,2) + "-" + String(transmissionDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"TRANSMISSION_DATE\": \""+ transmissionD + "\""
    }

    if (validateDate(competencyDate)) {
      const competencyD = String(competencyDate).substr(6,4) + "-" + String(competencyDate).substr(3,2) + "-" + String(competencyDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"COMPETENCE_DATE\": \""+ competencyD + "\""
    }

    if (validateDate(cancellationDate)) {
      const cancellationD = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" + String(cancellationDate).substr(0,2) +
        "T00:00:00";
      jsonNoteService_New += ", \"CANCELED_DATE\": \""+ cancellationD + "\""
    }

    if (((verificationCode !== undefined) && (verificationCode !== null)) && (verificationCode !== ""))
      jsonNoteService_New += ", \"VERIFICATION_CODE\": \""+ verificationCode +"\""

    if (((xml !== undefined) && (xml !== null)) && (xml !== ""))
      jsonNoteService_New += ", \"XML\": \""+ xml.replaceAll("\"","'")  +"\""

    if (((xmlReturn !== undefined) && (xmlReturn !== null)) && (xmlReturn !== ""))
      jsonNoteService_New += ", \"XML_RETURN\": \""+ xmlReturn.replaceAll("\"","'") +"\""

    if (((xmlCancellation !== undefined) && (xmlCancellation !== null)) && (xmlCancellation !== ""))
      jsonNoteService_New += ", \"XML_CANCELLATION\": \""+ xmlCancellation.replaceAll("\"","'") +"\""

    if (((returnError !== undefined) && (returnError !== null)) && (returnError !== ""))
      jsonNoteService_New += ", \"ERROR\": \""+ returnError.replaceAll("\"","'") +"\""

    jsonNoteService_New += ", \"AUTOMATIC\": "+ automatic
    jsonNoteService_New += ", \"ENVIRONMENT\": "+ environment
    jsonNoteService_New += ", \"SITUATION\": "+ situation
    jsonNoteService_New += " }"
    //

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonNoteService_New)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "NOTE_SERVICE/SendEmail", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível re-enviar a Nota Fiscal de Serviço. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }
      
      setError("")
      setLoading("")

      setWarning("Re-envio efetuado com sucesso!")      
    })
  }

  const selected = (event) => {
    setShowSearch(false)
    setShowChange(true)

    setJsonNoteService(event)
    
    let optServiceCnae = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
    jsonServiceCnae &&
      jsonServiceCnae.map(item => (
        optServiceCnae += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
      ))
      optServiceCnae += "]}"

    setOptionsServiceCnae(optServiceCnae)

    let optServiceCodeCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
    jsonServiceCodeCnae &&
      jsonServiceCodeCnae.map(it => (
        optServiceCodeCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}"
      ))
      optServiceCodeCnae += "]}"

    setOptionsServiceCodeCnae(optServiceCodeCnae)

    let optServiceItemCnae = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
    jsonServiceItemCnae &&
      jsonServiceItemCnae.map(it => (
        optServiceItemCnae += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}"
      ))
      optServiceItemCnae += "]}"

    setOptionsServiceItemCnae(optServiceItemCnae)

    setCode(event.ID)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")

    setShowSearch(true)
    setShowChange(false)

    setCode("")
  }

  const validateDate = (value) => {
    let error = false;
    const date = value;
    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Nota Fiscal de Serviço"
            titleSearch="Pesquisa das Notas Fiscais de Serviço"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchNoteService
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setSelected={selected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>

                { showChange &&
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

                    { loading ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                          <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                        </div>
                      :
                        <>
                          {/* 
                          ////
                          // Buttons // Botões
                          //// 
                          */}

                          { situation === 1 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 147}} onClick={() => process(1)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_receive} alt="Emitir"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Emitir</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>                  
                          } 

                          { situation === 2 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => process(2)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_search} alt="Consultar Status"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Consultar Status</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 210}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          { situation === 3 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => downloadTxtFile(1)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_arrow_down} alt="Download XML"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Download XML</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => reSendEmail()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_send_email} alt="Re-Enviar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Re-Enviar</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => process(3)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_cancel} alt="Cancelar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>                              
                            </div>
                          }

                          { situation === 4 &&
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => downloadTxtFile(1)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_arrow_down} alt="Download XML"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Download XML</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => downloadTxtFile(2)}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_arrow_down} alt="Download XML Cancelamento"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Download XML Cancelamento</label>
                                </div>
                              </div>
                              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                marginLeft: 5, width: 197}} onClick={() => returnAdd()}>
                                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                                  paddingBottom: 5, paddingLeft: 10}}>
                                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                </div>
                              </div>
                            </div>
                          }

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1340) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ? 
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1340) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1070) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(showMenu) ? 
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1340) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  : 
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1070) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceCode"
                                    description="Código"
                                    value={code}
                                    maxLength={11}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={(showMenu) ? 
                                    (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1340) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                  : 
                                    (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1070) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceDate"
                                    description="Data"
                                    value={date}
                                    maxLength={10}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1340) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                : 
                                  (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1070) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                <div style={(showMenu) ? 
                                    (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1340) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}
                                  : 
                                    (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1070) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceLot"
                                    description="Lote"
                                    // value={lot}
                                    value={123456789112345}
                                    maxLength={15}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={(showMenu) ?
                                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : 
                                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(showMenu) ? 
                                      (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 970) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190, marginLeft: 10}
                                    : 
                                      (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 190, maxWidth: 190, marginLeft: 10}}>
                                    <Select
                                      name="noteServiceEnvironment"
                                      description="Ambiente"
                                      value={environment || 0}
                                      require={true}
                                      options={JSON.parse("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}, {\"ID\": 1, \"VALUE\": \"Produção\"}"+
                                        ", {\"ID\": 2, \"VALUE\": \"Homologação\"}]}")}
                                      image={false}
                                      typeInput={2}
                                      disabled={true}
                                      set={setEnvironment}
                                      mostrarTitulo={true} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={(showMenu) ? 
                                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 970) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 250, maxWidth: 250, marginLeft: 10}
                                    : 
                                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 250, maxWidth: 250, marginLeft: 10}}>
                                    <Select
                                      name="noteServiceSituation"
                                      description="Situação"
                                      value={situation || 0}
                                      require={true}
                                      options={JSON.parse("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}, {\"ID\": 1, \"VALUE\": \"Lançada\"}"+
                                        ", {\"ID\": 2, \"VALUE\": \"Aguardando Protocolo\"}, {\"ID\": 3, \"VALUE\": \"Finalizada\"}"+
                                        ", {\"ID\": 4, \"VALUE\": \"Cancelada\"}]}")}
                                      image={false}
                                      typeInput={2}
                                      disabled={true}
                                      set={setSituation}
                                      mostrarTitulo={true} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceNumber"
                                    description="Número"
                                    value={number}
                                    maxLength={15}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              
                                <div style={(width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120, marginLeft: 10}}>
                                  <Input
                                    type="text"
                                    name="noteServiceSeries"
                                    description="Série"
                                    value={series}
                                    maxLength={5}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                              
                              <div style={(showMenu) ? 
                                  (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1380) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                : 
                                  (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1110) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                <div style={(showMenu) ? 
                                    (width < 1070) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1380) ? {display: "flex", flexGrow: 1, minWidth: 250}
                                    : {display: "flex", flexGrow: 0, minWidth: 250, maxWidth: 250}
                                  : 
                                    (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1110) ? {display: "flex", flexGrow: 1, minWidth: 250}
                                    : {display: "flex", flexGrow: 0, minWidth: 250, maxWidth: 250}}>
                                  <Select
                                    name="noteServiceType"
                                    description="Tipo"
                                    value={type || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"RPS\" }, {\"ID\": 2, \"VALUE\": \"Nota Fiscal Conjugada\" }, {\"ID\": 3, \"VALUE\": \"Cupom\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    disabled={true}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={(showMenu) ? 
                                    (width < 1070) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1380) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 480}
                                    : {display: "flex", flexGrow: 0, minWidth: 480, maxWidth: 480, marginLeft: 10}
                                  : 
                                    (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1110) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 480}
                                    : {display: "flex", flexGrow: 0, minWidth: 480, maxWidth: 480, marginLeft: 10}}>

                                  <Select
                                    name="noteServiceNature"
                                    description="Natureza"
                                    value={nature || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                      ", {\"ID\": 1, \"VALUE\": \"Tributação no município\" }"+ 
                                      ", {\"ID\": 2, \"VALUE\": \"Tributação fora do município\" }, {\"ID\": 3, \"VALUE\": \"Isenção\" }"+
                                      ", {\"ID\": 4, \"VALUE\": \"Imune\" }, {\"ID\": 5, \"VALUE\": \"Exigibilidade suspensa por decisão judicial\" }"+
                                      ", {\"ID\": 6, \"VALUE\": \"Exigibilidade suspensa por procedimento administrativo\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    disabled={true}
                                    mostrarTitulo={true}
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Select
                                  name="noteServiceCompanyRegime"
                                  description="Regime"
                                  value={companyRegime || 0}
                                  require={true}
                                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                    ", {\"ID\": 1, \"VALUE\": \"Microempresa municipal\" }, {\"ID\": 2, \"VALUE\": \"Estimativa\" }"+
                                    ", {\"ID\": 3, \"VALUE\": \"Sociedade de profissionais\" }, {\"ID\": 4, \"VALUE\": \"Cooperativa\" }"+
                                    ", {\"ID\": 5, \"VALUE\": \"MEI Simples Nacional\" }, {\"ID\": 6, \"VALUE\": \"ME EPP Simples Nacional\" }] }")}
                                  image={false}
                                  typeInput={2}
                                  disabled={true}
                                  mostrarTitulo={true} 
                                  onSelect={fieldSelected} />
                              </div>

                              <div style={(showMenu) ? 
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                : 
                                  (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 590) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                <div style={(showMenu) ? 
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 120}
                                    : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                  : 
                                    (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 590) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 120}
                                    : {display: "flex", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                  <Select
                                    name="noteServiceCompanySimples"
                                    description="Simples"
                                    value={companySimples || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                      ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    disabled={true}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={(showMenu) ? 
                                    (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                  : 
                                    (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 590) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 120}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>

                                  <Select
                                    name="noteServiceCompanyCulture"
                                    description="Cultural"
                                    value={companyCulture || 0}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                      ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                    image={false}
                                    typeInput={2}
                                    disabled={true}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValue"
                                    description="Valor"
                                    value={value || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueDeduction"
                                    description="Valor Dedução"
                                    value={valueDeduction || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValuePis"
                                    description="Valor PIS"
                                    value={valuePis || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueCofins"
                                    description="Valor COFINS"
                                    value={valueCofins || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueInss"
                                    description="Valor INSS"
                                    value={valueInss || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueIr"
                                    description="Valor IR"
                                    value={valueIr || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueCsll"
                                    description="Valor CSLL"
                                    value={valueCsll || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueIssRetain"
                                    description="Valor ISS Retido"
                                    value={valueIssRetain || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueIss"
                                    description="Valor ISS"
                                    value={valueIss || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                        
                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueOther"
                                    description="Valor Outras"
                                    value={valueOther || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                : 
                                  (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueUnconditioned"
                                    description="Valor Incondicionado"
                                    value={valueUnconditioned || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={(width < 480) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceValueConditioning"
                                    description="Valor Condicionado"
                                    value={valueConditioning || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="Serviço" />
                            </div>

                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Select
                                    name="noteServiceServiceCnaeCode"
                                    description="CNAE Serviço"
                                    value={serviceCnaeCode || 0}
                                    require={true}
                                    disabled={true}
                                    options={JSON.parse(optionsServiceCnae)}
                                    image={false}
                                    typeInput={2}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Select
                                    name="noteServiceServiceCodeCnaeCode"
                                    description="Código Serviço"
                                    value={serviceCodeCnaeCode || 0}
                                    require={true}
                                    disabled={true}
                                    options={JSON.parse(optionsServiceCodeCnae)}
                                    image={false}
                                    typeInput={2}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Select
                                    name="noteServiceServiceItemCnaeCode"
                                    description="Item Serviço"
                                    value={serviceItemCnaeCode || 0}
                                    require={true}
                                    disabled={true}
                                    options={JSON.parse(optionsServiceItemCnae)}
                                    image={false}
                                    typeInput={2}
                                    mostrarTitulo={true} 
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="noteServiceAliquot"
                                    description="Alíquota ISS"
                                    value={ formatNumeric(aliquot, 2) || "0" }
                                    maxLength={5}
                                    disabled={true}
                                    require={true}
                                    negative={false} 
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="Cliente" />
                            </div>

                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <div style={showMenu ?
                                  (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceClientCpfCnpj"
                                    description="CPF/CNPJ"
                                    value={clientCpfCnpj}
                                    maxLength={14}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2}
                                    onSelect={fieldSelected} />
                                </div>
                                
                                <div style={showMenu ?
                                    (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceClientCorporateName"
                                    description="Nome/Razão"
                                    value={clientCorporateName}
                                    maxLength={80}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>
                              </div>
                            </div>

                            <div style={{marginTop: 10}}>
                              <Titulo description="Conta Receber" />
                            </div>

                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <div style={showMenu ?
                                  (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="noteServiceBillReceiveNumber"
                                    description="Número"
                                    value={billReceiveNumber}
                                    maxLength={50}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={fieldSelected} />
                                </div>

                                <div style={showMenu ?
                                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={showMenu ?
                                      (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}
                                    :
                                      (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 630) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>
                                    <Input
                                      type="text"
                                      name="noteServiceBillReceivePortion"
                                      description="Parcela"
                                      value={billReceivePortion}
                                      maxLength={7}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={showMenu ?
                                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                    :
                                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 630) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                    <Input
                                      type="text"
                                      name="noteServiceBillReceiveDueDate"
                                      description="Data Vencimento"
                                      value={billReceiveDueDate}
                                      maxLength={10}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            { ((situation === 2 || situation === 3) || situation === 4) &&
                              <div style={{marginTop: 10}}>
                                <Titulo description="Transmissão" />
                              </div>
                            }

                            { ((situation === 2 || situation === 3) || situation === 4) &&
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={showMenu ?
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      
                                  <div style={showMenu ?
                                      (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                                    :
                                      (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                                    <Input
                                      type="text"
                                      name="noteServiceTransmissionDate"
                                      description="Data Transmissão"
                                      value={transmissionDate}
                                      maxLength={10}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={showMenu ?
                                      (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 550) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="noteServiceProtocol"
                                      description="Protocolo"
                                      value={protocol}
                                      maxLength={24}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            }

                            { (situation === 3 || situation === 4) &&
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={showMenu ?
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={showMenu ?
                                      (width < 820) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                                    :
                                      (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                                    <Input
                                      type="text"
                                      name="noteServiceCompetencyDate"
                                      description="Data Competência"
                                      value={competencyDate}
                                      maxLength={19}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>

                                  <div style={showMenu ?
                                      (width < 820) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 550) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="noteServiceVerificationCode"
                                      description="Código Verificação"
                                      value={verificationCode}
                                      maxLength={10}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            }

                            { (situation === 4) &&
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="noteServiceCancellationDate"
                                      description="Data Cancelamento"
                                      value={cancellationDate}
                                      maxLength={19}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            }

                            { returnError &&
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="retornoErro"
                                      description="Erro"
                                      value={returnError}
                                      maxLength={10000}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onChange={(event) => setReturnError(event.target.value)} 
                                      onSelect={fieldSelected} />
                                  </div>
                                </div>
                              </div>
                            } 

                          </div>
                        </>
                    }
                  </div>
                }

              </>
          }

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default NoteService