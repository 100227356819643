import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import image_cancel from "../../Images/Buttons/cancel.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_select from "../../Images/Buttons/select_circle.png"

const BillPayBordereau = ({databaseType, databasePath, jsonCompany, jsonLogin, system, jBillPay, saveBillPay,  reverseBillPay,  returnBordereau,
  newBordereau, width, showMenu}) => {

  const [jBills, setJBills] = useState([])

  const [valueTotal, setValueTotal] = useState(0)
  const [valueTotalSelected, setValueTotalSelected] = useState(0)  
    
  const [loadRecords, setLoadRecords] = useState(false)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    let returnJson = []

    if (newBordereau){
      if (jBillPay.CODE !== 0){
        setWarning("")
        setError("")
        setLoading("Aguarde buscando as informações...")
    
        let formData = new FormData();
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jBillPay)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)

        axios.post(BASE_URL + "BILL_PAY/TotalRecords", formData) 
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar o total de Contas a Pagar. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setWarning("")
            setError(returnJson.ERROR)
            setLoading("")
            return
          }
  
          const tRecord = returnJson.OBJECT
          if (tRecord === 0)
          {
            setWarning("Nenhum registro encontrado.")
            setError("")
            setLoading("")
            return
          }
   
          returnJson.WARNING ? setWarning(returnJson.WARNING) : setWarning("")
  
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", jBillPay)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("QUANTITY_INITIAL", 0)
          formData.append("QUANTITY_RECORDS", tRecord)
          formData.append("ORDER", 2)
  
          axios.post(BASE_URL + "BILL_PAY/Search", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível pesquisar as Contas a Pagar. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setWarning("")
              setError(returnJson.ERROR)
              setLoading("") 
              return
            }

            let aBills = []
            returnJson.OBJECT.map(item =>
              aBills.push(preparePortion(item))
            )

            let totalValue = 0
            aBills.map(item =>
              totalValue += (item.VALUE - item.VALUE_PAID)
            )
            setValueTotal(formatNumeric(totalValue, 2))

            setJBills(aBills)
            setLoading("")
            setLoadRecords(true)
          })
        })
      }
      else
      {
        setLoadRecords(false)

        setLoading(null)
        setWarning("")
        setError("")
      }
    }
    else
    {
      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações...")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jBillPay)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_PAY/SearchBordereau", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Boderos das Contas a Pagar. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setWarning("")
          setError(returnJson.ERROR)
          setLoading("") 
          return
        }
          
        let aBills = []
        returnJson.OBJECT &&
        returnJson.OBJECT.map(item =>
          aBills.push(preparePortion(item))
        )

        let totalValue = 0
        aBills.map(item =>
          totalValue += (item.VALUE_PAID)
        )
        setValueTotal(formatNumeric(totalValue, 2))

        setJBills(aBills)
        setLoading("")
        setLoadRecords(true)
      })
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, newBordereau, jBillPay])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const preparePortion = (item) => {
    let sBillPay = "{ "
    sBillPay += "\"MARKET\": false, "
    sBillPay += "\"ID\": "+ item.ID + ", "

    sBillPay += "\"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
      ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }, "

    sBillPay += "\"ACCOUNT_PLAN\": { \"ID\": " + item.ACCOUNT_PLAN.ID + ", \"DESCRIPTION\": \""+ item.ACCOUNT_PLAN.DESCRIPTION +"\" }, "
    sBillPay += "\"DOCUMENT_TYPE\": { \"ID\": " + item.DOCUMENT_TYPE.ID + " }, "

    if (item.PAYMENT_FORM !== undefined){
      if ((item.NFS != null) && (item.NFS !== 0))
        sBillPay += "\"PAYMENT_FORM\": { \"ID\": " + item.PAYMENT_FORM.ID + " }, "
    }
        
    sBillPay += "\"SUPPLIER\": { \"ID\": " + item.SUPPLIER.ID + ", \"CPF_CNPJ\": \""+ item.SUPPLIER.CPF_CNPJ +
      "\", \"CORPORATE_NAME\": \""+ item.SUPPLIER.CORPORATE_NAME +"\", \"FANTASY_NAME\": \""+ item.SUPPLIER.FANTASY_NAME +"\" }, "

    sBillPay += "\"EMPLOYEE\": { \"ID\": " + item.EMPLOYEE.ID + ", \"NAME\": \""+ item.EMPLOYEE.NAME +"\" }, "

    if ((item.NFE != null) && (item.NFE !== 0)) sBillPay += "\"NFE\": " + item.NFE + ", "
    if ((item.OS != null) && (item.OS !== 0)) sBillPay += "\"OS\": " + item.OS + ", "

    if (item.ACCOUNT != null){
      if (item.ACCOUNT.ID != null) sBillPay += "\"ACCOUNT\": { \"ID\": " + item.ACCOUNT.ID + ", "
      if (item.ACCOUNT.NUMBER != null) sBillPay += "\"NUMBER\": \"" + item.ACCOUNT.NUMBER + "\", "
      if (item.ACCOUNT.OWNER != null) sBillPay += "\"OWNER\": \"" + item.ACCOUNT.OWNER + "\" }, "
    }

    sBillPay += "\"NUMBER\": \"" + item.NUMBER + "\", "
    sBillPay += "\"PORTION\": \"" + item.PORTION + "\", "
    sBillPay += "\"TOTAL_PORTION\": \"" + item.TOTAL_PORTION + "\", "
    sBillPay += "\"EMISSION_DATE\": \"" + item.EMISSION_DATE + "\", "
    sBillPay += "\"RELEASE_DATE\": \"" + item.RELEASE_DATE + "\", "
    sBillPay += "\"DUE_DATE\": \"" + item.DUE_DATE + "\", "

    if ((item.CANCELLATION_DATE !== "0001-01-01T00:00:00") && (item.CANCELLATION_DATE !== undefined))
      sBillPay += "\"CANCELLATION_DATE\": \"" + item.DT_CANCELAMENTO + "\", "

    if ((item.PAYMENT_DATE !== "0001-01-01T00:00:00") && (item.PAYMENT_DATE !== undefined))
      sBillPay += "\"PAYMENT_DATE\": \"" + item.PAYMENT_DATE + "\", "

    sBillPay += "\"VALUE\": " + item.VALUE + ", "

    if (item.VALUE_PAID === undefined) sBillPay += "\"VALUE_PAID\": 0, "
    else sBillPay += "\"VALUE_PAID\": " + item.VALUE_PAID + ", "

    sBillPay += "\"ORIGIN\": " + item.ORIGIN + ", "

    if ((item.SCHEDULING_DATE !== "0001-01-01T00:00:00") && (item.SCHEDULING_DATE !== undefined))
      sBillPay += "\"SCHEDULING_DATE\": \"" + item.SCHEDULING_DATE + "\", "

    if (item.BARCODE_BILLET != null) sBillPay += "\"BARCODE_BILLET\": \"" + item.BARCODE_BILLET + "\", "
    if (item.OBSERVATION != null) sBillPay += "\"OBSERVATION\": \"" + item.OBSERVATION + "\", "

    sBillPay += "\"SITUATION\": " + item.SITUATION + " }"
    return JSON.parse(sBillPay)
  }

  const updateShow = (id) => {
    jBills.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    if (newBordereau)
    {
      let selectedValue = 0
      jBills.map(item =>
        (item.MARKET) ? selectedValue += (item.VALUE - item.VALUE_PAID) : selectedValue += 0
      )
      setValueTotalSelected(formatNumeric(selectedValue, 2))
    }

    if (!newBordereau)
    {
      let selectedValue = 0
      jBills.map(item =>
        (item.MARKET) ? selectedValue += item.VALUE_PAID : selectedValue += 0
      )
      setValueTotalSelected(formatNumeric(selectedValue, 2))
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { loadRecords && newBordereau &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 167}} onClick={() => saveBillPay(jBills)}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                  </div>
                </div>
              </div>
            }

            { !newBordereau &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 167}} onClick={() => reverseBillPay(jBills)}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_cancel} alt="Cancelar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 167}} onClick={() => returnBordereau()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            }

            { loadRecords &&
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>
            }

            <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
              <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                paddingLeft: 10}}>
                { warning &&
                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                    marginBottom: 10}}>
                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                  </div> 
                }

                { error &&
                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                    marginBottom: 10}}>
                    <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                  </div>
                }
              </div>

              { loadRecords &&
                <>
                  { jBills && (jBills.length > 0) &&
                    <>
                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2, 
                        borderBottomStyle: "solid", marginTop: 30, marginBottom: 4}}>

                        <div style={(showMenu) ? 
                            (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                          : 
                            (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={showMenu ?
                              (width < 1310) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 1030) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={showMenu ?
                                (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={showMenu ?
                                  (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :
                                  (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={{display: "flex", flexGrow: 0, minWidth: 20, maxWidth: 20, justifyContent: "center", alignItems: "center", 
                                  marginLeft: 10}} />

                                <div style={showMenu ?
                                    (width < 1050) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                    : (width < 2170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                  :
                                    (width < 780) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                    : (width < 1910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                  <label style={{fontWeight: 600}}>Número/Parcela</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1050) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                :
                                  (width < 780) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                <label style={{fontWeight: 600}}>Vencto.</label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Valor</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                  : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                  : {display: "flex", flexGrow: 0, marginLeft: 15, marginRight: 5, minWidth: 110, maxWidth: 110}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                  : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                  : {display: "flex", flexGrow: 0, marginLeft: 15, marginRight: 5, minWidth: 110, maxWidth: 110}}>
                                <label style={{fontWeight: 600}}>Pago</label>
                              </div>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                              : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                            :
                              (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                              : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 1310) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: -5, minWidth: 430}
                              :
                                (width < 1030) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: -5, minWidth: 430}}>
                              <label style={{fontWeight: 600}}>Fornecedor</label>
                            </div>
                            <div style={showMenu ?
                                (width < 1310) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 5, minWidth: 430}
                              :
                                (width < 1030) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 5, minWidth: 430}}>
                              <label style={{fontWeight: 600}}>Plano Conta</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  { jBills && jBills.map(item => (
                    <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                      marginTop: 4, minWidth: 180}}>

                      <div style={(showMenu) ? 
                          (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        : 
                          (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                        <div style={showMenu ?
                            (width < 1310) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                          :
                            (width < 1030) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                          <div style={showMenu ?
                              (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={showMenu ?
                                (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={{display: "flex", flexGrow: 0, minWidth: 20, maxWidth: 20, justifyContent: "center", alignItems: "center", 
                                marginLeft: 10}}>
                                <input type="checkbox" onChange={ (e) => updateShow(item.ID) } /> 
                              </div>

                              <div style={showMenu ?
                                  (width < 1050) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                  : (width < 2170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                :
                                  (width < 780) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                  : (width < 1910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                <label>{item.NUMBER + "/" + item.PORTION}</label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1050) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                              :
                                (width < 780) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                              <label>{String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                String(item.DUE_DATE).substr(0,4)}</label>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                              : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                              : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                              <label>{formatNumeric(item.VALUE,2)}</label>
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                              <label>{item.VALUE_PAID !== 0 ? formatNumeric(item.VALUE_PAID,2) : "0.00"}</label>
                            </div>
                          </div>
                        </div>

                        <div style={showMenu ?
                            (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                          :
                            (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                          <div style={showMenu ?
                              (width < 1310) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}
                            :
                              (width < 1030) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}}>
                            <label>{item.SUPPLIER && item.SUPPLIER.CORPORATE_NAME}</label>
                          </div>
                          <div style={showMenu ?
                              (width < 1310) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}
                            :
                              (width < 1030) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}}>
                            <label>{item.ACCOUNT_PLAN && item.ACCOUNT_PLAN.DESCRIPTION}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  { jBills && (jBills.length > 0) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10, paddingLeft: 10, paddingRight: 10, 
                      backgroundColor: "rgb(0, 177, 171)"}}>

                      <div style={{marginTop: 5, marginRight: 5, marginBottom: 5, marginLeft: 5, width: "50%"}}>
                        <label style={{fontWeight: 600, color: "white"}}>Total dos Títulos : {valueTotal}</label>
                      </div>
                      <div style={{marginTop: 5, marginRight: 5, marginBottom: 5, marginLeft: 5, width: "50%"}}>
                        <label style={{fontWeight: 600, color: "white"}}>Total dos Títulos Selecionados : {valueTotalSelected}</label>
                      </div>
                    </div>
                  }

                  { jBills && (jBills.length === 0) &&
                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                    </div>
                  }

                </>
              }

          </div>
        </div>
      }

    </div>
  )
}

export default BillPayBordereau