import React from "react"
import { QrReader } from 'react-qr-reader';

const ScanQrCode = ({setValue, setError, height, width}) => {     
  return(
    <div style={{width: ((width * 38) / 100)}}>
      <QrReader
        scanDelay={100}
        onResult={(result, error) => {
          if (!!result) setValue(result?.text);
          if ((!!error) && (error != "e")) setError(error)
        }}
      />
    </div>    
  )
}

export default ScanQrCode