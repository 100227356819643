import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Titulo from "../../../Components/Titulo"
import SearchRHSection from "../../RhSection/Search"
import image_clean from "../../../Images/Buttons/clean.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchRhPosition = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setSelected, setReturn, showMenu,
  width}) => {

  const [code, setCode] = useState(0)    
  const [companyCode, setCompanyCode] = useState("")
  const [description, setDescription] = useState("")
  const [rhSectionCode, setRhSectionCode] = useState("")
  const [rhSectionDescription, setRhSectionDescription] = useState("")

  const [showResult, setShowResult] = useState(false)
  const [showRHSection, setShowRHSection] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  
  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false    

    setCompanyCode(jsonCompany.ID)

    let jRHPosition = "{ "

    if (description !== ""){    
      jRHPosition += "\"DESCRIPTION\": \""+ description + "\""
      if (description.length > 2) search = true
    }

    if (jRHPosition !== "{ ") jRHPosition += ", "
    jRHPosition += "\"RH_SECTION\": {"

    if (rhSectionCode !== ""){
      jRHPosition += " \"ID\": \""+ rhSectionCode + "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"
      search = true
    }
    else jRHPosition += " \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

    jRHPosition += " }"

    if (jRHPosition === "{ \"RH_SECTION\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } } }") search = true

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jRHPosition)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "RH_POSITION/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de cargos do RH. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jRHPosition)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "RH_POSITION/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os cargos do RH. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
             return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)
  
      document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, pageCurrent, description, rhSectionCode, recordPage, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o cargo RH (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setDescription("")
    setRhSectionCode("")
    setRhSectionDescription("")

    setUpdatePage(updatePage + 1)
  }

  const cleanRHSection = () => {
    setShowRHSection(false)

    setRhSectionCode("")
    setRhSectionDescription("")
  }

  const confirmDelete = () => {
    let returnJson = []
    const jRHPosition = "{ \"ID\": "+ code +", \"RH_SECTION\": { \"COMPANY\": { \"ID\": "+ companyCode  +" } } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jRHPosition)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "RH_POSITION/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o cargo do RH. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const enableRHSection = (situation) => {
    setShowRHSection(situation)
  }

  const fillRHSection = (section) => {
    setShowRHSection(false)

    setRhSectionCode(section.ID)
    setRhSectionDescription(section.DESCRIPTION)
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "RH_POSITION/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os cargos do RH no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchRHPositionDescription") && document.getElementById("searchRHPositionDescription").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  { !showRHSection ?
                      <>
                        <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean}
                          getSGE={() => getSGE()} setReturn={setReturn} />

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                        </div>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                          { warning &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div> 
                          }

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                              marginBottom: 10, marginLeft: 0}}>
                              <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                            </div>
                      
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="search"
                                  name="searchRHPositionDescription"
                                  description="Descrição"
                                  planceHolder="Mínimo 3 caracteres"
                                  value={description}
                                  maxLength={100}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDescription(event.target.value)} />
                              </div>
                            </div>

                            <div style={{marginTop: 10, width: "100%"}}>
                              <Titulo description="Setor RH" />
                            </div>

                            <div style={(width < 450) ? {} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1} }>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="searchRHPositionRhSectionDescription"
                                    description="Descrição"
                                    planceHolder=""
                                    value={rhSectionDescription}
                                    maxLength={100}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setRhSectionDescription(event.target.value)} />
                                </div>
                                
                                <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center",
                                    marginTop: 10} 
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                  <img
                                    style={{height: 42, width: 42}}
                                    name="searchAgencySearchRHSection"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={(() => enableRHSection(true))} />

                                  <img
                                    style={{height: 42, width: 42, marginLeft: 10}}
                                    name="searchAgencyCleanRHSection"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanRHSection} />
                                </div>
                              </div>
                            </div>
                            
                            { showResult && 
                              <>
                                { result.OBJECT &&
                                  <>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}}>

                                      <div style={(showMenu) ? 
                                          (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                        : 
                                          (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                        <div style={(showMenu) ? 
                                            (width < 1100) ? {display: "flex", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : 
                                            (width < 780) ? {display: "flex", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                          <label style={{fontWeight: 600}}>Descrição</label>
                                        </div>
                                        <div style={(showMenu) ? 
                                            (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : 
                                            (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                          <label style={{fontWeight: 600}}>Setor</label>
                                        </div>
                                      </div>

                                      <div style={(width < 450) ? {display: "none"}  
                                        : {display: "flex", flexGrow: 0, marginLeft: 0}}>
                                        <div style={{height: 32, width: 32}} />
                                        { !modeSearch && <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} /> }
                                      </div>
                                    </div>

                                    { result.OBJECT.map(item => (
                                      <div key={item.ID} style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                          backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                          borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                          marginTop: 4, minWidth: 180} 
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                          backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                          borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                                          marginTop: 4, minWidth: 180}}>

                                        <div style={(showMenu) ? 
                                            (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                          : 
                                            (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                          <div style={(showMenu) ? 
                                              (width < 1100) ? {display: "flex", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                            : 
                                              (width < 780) ? {display: "flex", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>

                                            <label>{item.DESCRIPTION}</label>
                                          </div>
                                          <div style={(showMenu) ? 
                                              (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                            : 
                                              (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 0, minWidth: 180}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                            <label>{item.RH_SECTION.DESCRIPTION}</label>
                                          </div>
                                        </div>

                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10} 
                                          : {display: "flex", flexGrow: 0}}>
                                          <img
                                            style={{height: 32, width: 32}}
                                            src={image_select}
                                            name="searchRHPositionSelect"
                                            alt="Selecionar"
                                            onClick={() => setSelected(item)} />

                                          { !modeSearch && 
                                            <img
                                              style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}}
                                              src={image_delete}
                                              name="searchRHPositionDelete"
                                              alt="Deletar"
                                              onClick={() => callDelete(item)} />
                                          }
                                        </div>
                                      </div>
                                      ))
                                    }

                                    <SearchPagination 
                                      pageCurrent={pageCurrent}
                                      totalPage={totalPage}
                                      recordPage={recordPage}
                                      optionsRegPage={JSON.parse(optionsRegPage)}
                                      pagePrevious={pagePrevious}
                                      pageNext={pageNext}
                                      setSelectedField={setFieldSelected}
                                      handleRecordPage={handleRecordPage}
                                      width={width} />
                                  </>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </>
                    :
                      <div style={{marginTop: 10}}>
                        <div style={{marginLeft: 10}}>
                          <b>Selecione o setor RH:</b>
                        </div>

                        <SearchRHSection
                          modeSearch={true}
                          databaseType={databaseType}
                          databasePath={databasePath}
                          jsonCompany={jsonCompany}
                          jsonLogin={jsonLogin}
                          system={system}
                          setAdd={() => alert("")}
                          setSelected={fillRHSection} 
                          setReturn={() => enableRHSection(false)} 
                          showMenu={showMenu}
                          width={width} />
                      </div>
                  }
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchRhPosition