import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import SearchSupplier from "../Supplier/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const ProductSupplier = ({databaseType, databasePath, jsonProductSupplier, jsonCompany, jsonLogin, system, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [productCode, setProductCode] = useState("")
  const [supplierCode, setSupplierCode] = useState("")
  const [supplierCpfCnpj, setSupplierCpfCnpj] = useState("")
  const [supplierCorporateName, setSupplierCorporateName] = useState("")
  const [reference, setReference] = useState("")
  const [sge, setSge] = useState("")

  const [showSupplier, setShowSupplier] = useState(false)

  useEffect(() => {
    setCode(jsonProductSupplier.ID)
    setProductCode(jsonProductSupplier.PRODUCT.ID)

    if (jsonProductSupplier.SUPPLIER !== undefined){
      setSupplierCode(jsonProductSupplier.SUPPLIER.ID)
      setSupplierCpfCnpj(jsonProductSupplier.SUPPLIER.CPF_CNPJ)
      setSupplierCorporateName(jsonProductSupplier.SUPPLIER.CORPORATE_NAME)
    }
    else{
      setSupplierCode("")
      setSupplierCpfCnpj("")
      setSupplierCorporateName("")
    }

    if (jsonProductSupplier.REFERENCE !== undefined) setReference(jsonProductSupplier.REFERENCE)
    if (((jsonProductSupplier.SGE !== undefined) && (jsonProductSupplier.SGE !== null)) && (jsonProductSupplier.SGE !== 0)) setSge(jsonProductSupplier.SGE)

  }, [jsonProductSupplier])

  const cleanSupplier = () => {
    setSupplierCode("")
    setSupplierCpfCnpj("")
    setSupplierCorporateName("")
    setShowSupplier(false);
  }

  const enableSupplier = () => {
    setShowSupplier(!showSupplier)
  }

  const fillSupplier = (supplier) => {
    setSupplierCode(supplier.ID)
    setSupplierCpfCnpj(supplier.CPF_CNPJ)
    setSupplierCorporateName(supplier.CORPORATE_NAME)
    setShowSupplier(false);
  }

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"PRODUCT\": { \"ID\": " + productCode + " }"

    if (((supplierCode !== undefined) && (supplierCode !== null)) && (supplierCode !== ""))
      json += ", \"SUPPLIER\": { \"ID\": " + supplierCode + ", \"CPF_CNPJ\": \""+ supplierCpfCnpj +
        "\", \"CORPORATE_NAME\": \""+ supplierCorporateName +"\" }"

    if (((reference !== undefined) && (reference !== null)) && (reference !== "")) json += ", \"REFERENCE\": \""+ reference +"\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showSupplier ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <b>Fornecedor:</b>
              </div>

              <div style={showMenu ?
                  (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                :  
                  (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}}>
                <div style={showMenu ?
                    (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                  : 
                    (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                  <div style={showMenu ?
                      (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    :  
                      (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={showMenu ?
                        (width < 1060) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}
                      :  
                        (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190}}>
                      <Input
                        type="text"
                        name="productSupplierSupplierCode"
                        description="CPF/CNPJ"
                        value={supplierCpfCnpj}
                        maxLength={14}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setSupplierCpfCnpj(event.target.value)} />
                    </div>
                    <div style={showMenu ?
                        (width < 1060) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Input
                        type="text"
                        name="productSupplierSupplierCorporateName"
                        description="Nome"
                        value={supplierCorporateName}
                        maxLength={120}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setSupplierCorporateName(event.target.value)} />
                    </div>
                  </div>
                  <div style={showMenu ?
                      (width < 1310) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}
                    :
                      (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}}>
                    <Input
                      type="text"
                      name="productSupplierReference"
                      description="Referência"
                      value={reference}
                      maxLength={20}
                      disabled={false}
                      require={true}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setReference(event.target.value)} />
                  </div>
                </div>
                <div style={showMenu ?
                    (width < 590) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                    : (width < 860) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                  :
                    (width < 590) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 10}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productSupplierSearchSupplier"
                      src={image_search} 
                      alt="Pesquisar" 
                      onClick={(() => enableSupplier(true))} />
                  </div>
                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productSupplierCleanSupplier"
                      src={image_clean} 
                      alt="Limpar"
                      onClick={cleanSupplier} />
                  </div>
                </div>
              </div>
            </div>
          </>
        :
          <div>
            <div name="resultadoProdutoFornecedor_PesquisaFornecedor" 
              className="marginTop10_produtoFornecedor">

              <b>Selecione o fornecedor:</b>
              <SearchSupplier
                modeSearch={true}
                process={1}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                setAdd={() => alert("")}
                setSelected={fillSupplier} 
                setReturn={() => setShowSupplier(false)} 
                showMenu={showMenu}
                width={width} />
            </div>
          </div>
      }

    </div>
  )
}

export default ProductSupplier