import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchAliquot from "../Aliquot/Search"
import SearchCstCsosn from "../CstCsosn/Search"
import SearchState from "../State/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const FisClassMovement = ({databaseType, databasePath, jsonFisClassMovement, setJsonFisClassMovement, jsonCompany, jsonLogin, system, save, back,
  showMenu, width}) => {

  const [code, setCode] = useState("")
  const [fisClassCode, setFisClassCode] = useState("")
  const [fisClassDescription, setFisClassDescription] = useState("")

  const [stateCode, setStateCode] = useState("")
  const [stateAcronym, setStateAcronym] = useState("")

  const [cstCsosnConsumerCode, setCstCsosnConsumerCode] = useState("")
  const [cstCsosnConsumerDescription, setCstCsosnConsumerDescription] = useState("")
  const [aliquotIcmsConsumerCode, setAliquotIcmsConsumerCode] = useState("")
  const [aliquotIcmsConsumerAcronym, setAliquotIcmsConsumerAcronym] = useState("")
  const [reductionIcmsConsumer, setReductionIcmsConsumer] = useState(0)
  const [aliquotStConsumerCode, setAliquotStConsumerCode] = useState("")
  const [aliquotStConsumerAcronym, setAliquotStConsumerAcronym] = useState("")
  const [reductionStConsumer, setReductionStConsumer] = useState(0)
  const [mvaConsumer, setMvaConsumer] = useState(0)

  const [cstCsosnResaleCode, setCstCsosnResaleCode] = useState("")
  const [cstCsosnResaleDescription, setCstCsosnResaleDescription] = useState("")
  const [aliquotIcmsResaleCode, setAliquotIcmsResaleCode] = useState("")
  const [aliquotIcmsResaleAcronym, setAliquotIcmsResaleAcronym] = useState("")
  const [reductionIcmsResale, setReductionIcmsResale] = useState(0)
  const [aliquotStResaleCode, setAliquotStResaleCode] = useState("")
  const [aliquotStResaleAcronym, setAliquotStResaleAcronym] = useState("")
  const [reductionStResale, setReductionStResale] = useState(0)
  const [mvaResale, setMvaResale] = useState(0)

  const [noteType, setNoteType] = useState("0")   
  const [sge, setSge] = useState("")

  const [showState, setShowState] = useState(false)
  const [showCstCsosnConsumer, setShowCstCsosnConsumer] = useState(false)
  const [showAliquotIcmsConsumer, setShowAliquotIcmsConsumer] = useState(false)
  const [showAliquotStConsumer, setShowAliquotStConsumer] = useState(false)
  const [showCstCsosnResale, setShowCstCsosnResale] = useState(false)
  const [showAliquotIcmsResale, setShowAliquotIcmsResale] = useState(false)
  const [showAliquotStResale, setShowAliquotStResale] = useState(false)

  useEffect(() => {   
    if (jsonFisClassMovement.ID !== undefined) setCode(jsonFisClassMovement.ID)

    setFisClassCode(jsonFisClassMovement.FIS_CLASS.ID)
    setFisClassDescription(jsonFisClassMovement.FIS_CLASS.DESCRIPTION)

    if (jsonFisClassMovement.STATE != null){
      setStateCode(jsonFisClassMovement.STATE.ID)
      setStateAcronym(jsonFisClassMovement.STATE.ACRONYM)
    }
    else
    {
      setStateCode("")
      setStateAcronym("")
    }

    if (jsonFisClassMovement.CST_CSOSN_CONSUMER != null){
      setCstCsosnConsumerCode(jsonFisClassMovement.CST_CSOSN_CONSUMER.CODE)
      setCstCsosnConsumerDescription(jsonFisClassMovement.CST_CSOSN_CONSUMER.DESCRIPTION)
    }
    else
    {
      setCstCsosnConsumerCode("");
      setCstCsosnConsumerDescription("");
    }

    if (jsonFisClassMovement.ALIQUOT_ICMS_CONSUMER != null){
      setAliquotIcmsConsumerCode(jsonFisClassMovement.ALIQUOT_ICMS_CONSUMER.ID)
      setAliquotIcmsConsumerAcronym(jsonFisClassMovement.ALIQUOT_ICMS_CONSUMER.ACRONYM)
    }
    else{
      setAliquotIcmsConsumerCode("")
      setAliquotIcmsConsumerAcronym("")
    }
        
    setReductionIcmsConsumer(formatNumeric(jsonFisClassMovement.REDUCTION_ICMS_CONSUMER, 2))

    if (jsonFisClassMovement.ALIQUOT_ST_CONSUMER != null){
      setAliquotStConsumerCode(jsonFisClassMovement.ALIQUOT_ST_CONSUMER.ID)
      setAliquotStConsumerAcronym(jsonFisClassMovement.ALIQUOT_ST_CONSUMER.ACRONYM)
    }
    else{
      setAliquotStConsumerCode("")
      setAliquotStConsumerAcronym("")
    }

    setReductionStConsumer(formatNumeric(jsonFisClassMovement.REDUCTION_ST_CONSUMER, 2))
    setMvaConsumer(formatNumeric(jsonFisClassMovement.MVA_CONSUMER, 2))

    if (jsonFisClassMovement.CST_CSOSN_RESALE != null){
      setCstCsosnResaleCode(jsonFisClassMovement.CST_CSOSN_RESALE.CODE)
      setCstCsosnResaleDescription(jsonFisClassMovement.CST_CSOSN_RESALE.DESCRIPTION)
    }
    else
    {
      setCstCsosnResaleCode("")
      setCstCsosnResaleDescription("")
    }

    if (jsonFisClassMovement.ALIQUOT_ICMS_RESALE != null){
      setAliquotIcmsResaleCode(jsonFisClassMovement.ALIQUOT_ICMS_RESALE.ID)
      setAliquotIcmsResaleAcronym(jsonFisClassMovement.ALIQUOT_ICMS_RESALE.ACRONYM)
    }
    else{
      setAliquotIcmsResaleCode("")
      setAliquotIcmsResaleAcronym("")
    }

    setReductionIcmsResale(formatNumeric(jsonFisClassMovement.REDUCTION_ICMS_RESALE, 2))

    if (jsonFisClassMovement.ALIQUOT_ST_RESALE != null){
      setAliquotStResaleCode(jsonFisClassMovement.ALIQUOT_ST_RESALE.ID)
      setAliquotStResaleAcronym(jsonFisClassMovement.ALIQUOT_ST_RESALE.ACRONYM)
    }
    else{
      setAliquotStResaleCode("")
      setAliquotStResaleAcronym("")
    }

    setReductionStResale(formatNumeric(jsonFisClassMovement.REDUCTION_ST_RESALE, 2))
    setMvaResale(formatNumeric(jsonFisClassMovement.MVA_RESALE, 2))
    setNoteType(jsonFisClassMovement.NOTE_TYPE)

    if (jsonFisClassMovement.SGE != null) setSge(jsonFisClassMovement.SGE)

    setJsonFisClassMovement(jsonFisClassMovement)

  }, [jsonFisClassMovement])

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const callSave = () => {
    let json = "{ \"FIS_CLASS\": { \"ID\": \"" + fisClassCode + "\", \"DESCRIPTION\": \""+ fisClassDescription +
      "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
        ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\", \"REGIME\": "+ jsonCompany.REGIME +" } }"

    if (code !== "") json += ", \"ID\": " + code    
    if (stateCode !== "") json += ", \"STATE\": { \"ID\": " + stateCode + ", \"ACRONYM\": \""+ stateAcronym +"\" }"

    if (cstCsosnConsumerCode !== "")
      json += ", \"CST_CSOSN_CONSUMER\": { \"CODE\": \"" + cstCsosnConsumerCode + "\", \"DESCRIPTION\": \""+ cstCsosnConsumerDescription +"\" }"

    if (aliquotIcmsConsumerCode !== "")
      json += ", \"ALIQUOT_ICMS_CONSUMER\": { \"ID\": " + aliquotIcmsConsumerCode + ", \"ACRONYM\": \""+ aliquotIcmsConsumerAcronym +"\" }"

    json += ", \"REDUCTION_ICMS_CONSUMER\": " + reductionIcmsConsumer

    if (aliquotStConsumerCode !== "")
      json += ", \"ALIQUOT_ST_CONSUMER\": { \"ID\": " + aliquotStConsumerCode + ", \"ACRONYM\": \""+ aliquotStConsumerAcronym +"\" }"

    json += ", \"REDUCTION_ST_CONSUMER\": " + reductionStConsumer
    json += ", \"MVA_CONSUMER\": " + mvaConsumer

    if (cstCsosnResaleCode !== "")
      json += ", \"CST_CSOSN_RESALE\": { \"CODE\": \"" + cstCsosnResaleCode + "\", \"DESCRIPTION\": \""+ cstCsosnResaleDescription +"\" }"

    if (aliquotIcmsResaleCode !== "")
      json += ", \"ALIQUOT_ICMS_RESALE\": { \"ID\": " + aliquotIcmsResaleCode + ", \"ACRONYM\": \""+ aliquotIcmsResaleAcronym +"\" }"

    json += ", \"REDUCTION_ICMS_RESALE\": " + reductionIcmsResale

    if (aliquotStResaleCode !== "")
      json += ", \"ALIQUOT_ST_RESALE\": { \"ID\": " + aliquotStResaleCode + ", \"ACRONYM\": \""+ aliquotStResaleAcronym +"\" }"

    json += ", \"REDUCTION_ST_RESALE\": " + reductionStResale
    json += ", \"MVA_RESALE\": " + mvaResale
    json += ", \"NOTE_TYPE\": "+ noteType
   
    if (sge !== "") json += ", \"SGE\": "+ sge    
    json += " }"

    save(json)
  }  

  const cleanAliquotIcmsConsumer = () => {
    setAliquotIcmsConsumerCode("")
    setAliquotIcmsConsumerAcronym("")
    setShowAliquotIcmsConsumer(false)
  }

  const cleanAliquotIcmsResale = () => {
    setAliquotIcmsResaleCode("")
    setAliquotIcmsResaleAcronym("")
    setShowAliquotIcmsResale(false)
  }

  const cleanAliquotStConsumer = () => {
    setAliquotStConsumerCode("")
    setAliquotStConsumerAcronym("")
    setShowAliquotStConsumer(false)
  }

  const cleanAliquotStResale = () => {
    setAliquotStResaleCode("")
    setAliquotStResaleAcronym("")
    setShowAliquotStResale(false)
  }

  const cleanCstCsosnConsumer = () => {
    setCstCsosnConsumerCode("")
    setCstCsosnConsumerDescription("")
    setShowCstCsosnConsumer(false)
  }

  const cleanCstCsosnResale = () => {
    setCstCsosnResaleCode("")
    setCstCsosnResaleDescription("")
    setShowCstCsosnResale(false)
  }

  const cleanState = () => {
    setStateCode("")
    setStateAcronym("")
    setShowState(false)
  }

  const enableAliquotIcmsConsumer = () => {
    setShowAliquotIcmsConsumer(!showAliquotIcmsConsumer)
  }

  const enableAliquotIcmsResale = () => {
    setShowAliquotIcmsResale(!showAliquotIcmsResale)
  }    

  const enableAliquotStConsumer = () => {
    setShowAliquotStConsumer(!showAliquotStConsumer)
  }    

  const enableAliquotStResale = () => {
    setShowAliquotStResale(!showAliquotStResale)
  }        

  const enableCstCsosnConsumer = () => {
    setShowCstCsosnConsumer(!showCstCsosnConsumer)
  }

  const enableCstCsosnResale = () => {
    setShowCstCsosnResale(!showCstCsosnConsumer)
  }

  const enableState = () => {
    setShowState(!showState)
  }

  const fillAliquotIcmsConsumer = (aliquotIcmsConsumer) => {
    setAliquotIcmsConsumerCode(aliquotIcmsConsumer.ID)
    setAliquotIcmsConsumerAcronym(aliquotIcmsConsumer.ACRONYM)
    setShowAliquotIcmsConsumer(false)
  }

  const fillAliquotIcmsResale = (aliquotIcmsResale) => {
    setAliquotIcmsResaleCode(aliquotIcmsResale.ID)
    setAliquotIcmsResaleAcronym(aliquotIcmsResale.ACRONYM)
    setShowAliquotIcmsResale(false)
  }

  const fillAliquotStConsumer = (aliquotStConsumer) => {
    setAliquotStConsumerCode(aliquotStConsumer.ID)
    setAliquotStConsumerAcronym(aliquotStConsumer.ACRONYM)
    setShowAliquotStConsumer(false)
  }

  const fillAliquotStResale = (aliquotStResale) => {
    setAliquotStResaleCode(aliquotStResale.ID)
    setAliquotStResaleAcronym(aliquotStResale.ACRONYM)
    setShowAliquotStResale(false)
  }

  const fillCstCsosnConsumer = (cstCsosnConsumer) => {
    setCstCsosnConsumerCode(cstCsosnConsumer.CODE)
    setCstCsosnConsumerDescription(cstCsosnConsumer.DESCRIPTION)
    setShowCstCsosnConsumer(false)
  }

  const fillCstCsosnResale = (cstCsosnResale) => {
    setCstCsosnResaleCode(cstCsosnResale.CODE)
    setCstCsosnResaleDescription(cstCsosnResale.DESCRIPTION)
    setShowCstCsosnResale(false)
  }

  const fillState = (state) => {
    setStateCode(state.ID)
    setStateAcronym(state.ACRONYM)
    setShowState(false)
  }

  const handleMvaConsumer = (event) => {
    setMvaConsumer(formatNumeric(event.target.value, 2))
  }

  const handleMvaResale = (event) => {
    setMvaResale(formatNumeric(event.target.value, 2))
  }

  const handleReductionIcmsConsumer = (event) => {
    setReductionIcmsConsumer(formatNumeric(event.target.value, 2))
  }

  const handleReductionIcmsResale = (event) => {
    setReductionIcmsResale(formatNumeric(event.target.value, 2))
  }

  const handleReductionStConsumer = (event) => {
    setReductionStConsumer(formatNumeric(event.target.value, 2))
  }

  const handleReductionStResale = (event) => {
    setReductionStResale(formatNumeric(event.target.value, 2))
  }  

  const setFieldSelected = () => {
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      { !showAliquotIcmsConsumer && !showAliquotIcmsResale && !showAliquotStConsumer && !showAliquotStResale && !showCstCsosnConsumer &&
        !showCstCsosnResale && !showState ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                marginLeft: 5, width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              <div>
                <label style={{fontWeight: 600}}>{ (code === "") ? "Inclusão:" : "Alteração:" }</label>
              </div>

              <div style={showMenu ?
                  (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                :
                  (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <div style={showMenu ?
                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    :(width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                    : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                  :
                    (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                  <b>Estado:</b>
                  <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="text"
                        name="fisClassMovementStateAcronym"
                        description="Sigla"
                        value={stateAcronym}
                        maxLength={2}
                        disabled={true}
                        require={true}
                        image={false}
                        typeInput={2} 
                        onSelect={setFieldSelected}
                        onChange={(event) => setStateAcronym(event.target.value)} />
                    </div>

                    { (code === "") &&
                      <div style={(width < 360) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                        <img 
                          style={{height: 42, width: 42}}
                          name="fillClassMovementSearchState"
                          src={image_search} 
                          alt="Pesquisar" 
                          onClick={enableState} />

                        <img 
                          style={{marginLeft: 10, height: 42, width: 42}}
                          name="fillClassMovementCleanState"
                          src={image_clean} 
                          alt="Limpar" 
                          onClick={cleanState} />
                      </div>
                    }
                  </div>
                </div>
                <div style={showMenu ?
                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, justifyContent: "end", marginLeft: 10}
                    : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, justifyContent: "end", marginLeft: 10}
                  :                  
                    (width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, justifyContent: "end", marginLeft: 10}}>
                  <b>Tipo:</b>
                  <div style={{display: "flex", flexGrow: 0, minWidth: 180}}>
                    <Select
                      name="fillClassMovementNoteType"
                      description=""
                      value={noteType || 0}
                      disabled={(code === "") ? false : true}
                      require={true}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"NFe\" }"+
                        ", {\"ID\": 2, \"VALUE\": \"NFCe\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setNoteType} />
                  </div>
                </div>
              </div>

              <div style={{marginTop: 10}}>
                <Titulo description="Consumidor" />
              </div>

              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                  <b>CST/CSOSN:</b>
                  <div style={showMenu ?
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    :
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={showMenu ?
                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :                      
                        (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={showMenu ?
                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :   
                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <Input
                          type="text"
                          name="fillClassMovementCstCsosnConsumerCode"
                          description="Código"
                          value={cstCsosnConsumerCode}
                          maxLength={3}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnConsumerCode(event.target.value) } />
                      </div>
                      <div style={showMenu ?
                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :
                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="text"
                          name="fillClassMovementCstCsosnConsumerDescription"
                          description="Descrição"
                          value={cstCsosnConsumerDescription}
                          maxLength={80}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnConsumerDescription(event.target.value) } />
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                        : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                      :
                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                      <img 
                        style={{height: 42, width: 42}}
                        name="fillClassMovementSearchCstCsosnConsumer"
                        src={image_search} 
                        alt="Pesquisar" 
                        onClick={enableCstCsosnConsumer} />

                      <img 
                        style={{marginLeft: 10, height: 42, width: 42}}
                        name="fillClassMovementCleanCstCsosnConsumer"
                        src={image_clean} 
                        alt="Limpar"
                        onClick={cleanCstCsosnConsumer} />
                    </div>
                  </div>
                </div>
              </div>

              <div style={showMenu ?
                  (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                :
                  (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                <div style={showMenu ?
                    (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                  :
                    (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                  <div>
                    <Titulo description="ICMS" />
                  </div>
                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                    :                 
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                      <b>Alíquota:</b>
                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="fillClassMovementAliquotIcmsConsumerAcronym"
                            description="Sigla"
                            value={aliquotIcmsConsumerAcronym}
                            maxLength={4}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected}
                            onChange={(event) => setAliquotIcmsConsumerAcronym(event.target.value)} />
                        </div>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                            : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                          :
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                          <img 
                            style={{height: 42, width: 42}}
                            name="fillClassMovementSearchAliquotIcmsConsumer"
                            src={image_search} 
                            alt="Pesquisar" 
                            onClick={enableAliquotIcmsConsumer} />

                          <img 
                            style={{marginLeft: 10, height: 42, width: 42}}
                            name="fillClassMovementCleanAliquotIcmsConsumer"
                            src={image_clean} 
                            alt="Limpar" 
                            onClick={cleanAliquotIcmsConsumer} />
                        </div>
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                      <b>Redução:</b>
                      <div style={showMenu ?
                          (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                        : 
                          (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="fillClassMovementReductionIcmsConsumer"
                          description="Valor"
                          value={reductionIcmsConsumer || ""}
                          maxLength={13}
                          disabled={false}
                          require={true}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleReductionIcmsConsumer} />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={showMenu ?
                      (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    : 
                      (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                  <div>
                    <Titulo description="ST" />
                  </div>
                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                    :
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                      <b>Alíquota:</b>
                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="fillClassMovementAliquotStConsumerAcronym"
                            description="Sigla"
                            value={aliquotStConsumerAcronym}
                            maxLength={4}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected}
                            onChange={(event) => setAliquotStConsumerAcronym(event.target.value)} />
                        </div>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                            : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                          :
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                          <img 
                            style={{height: 42, width: 42}}
                            name="fillClassMovementSearchAliquotStConsumer"
                            src={image_search} 
                            alt="Pesquisar" 
                            onClick={enableAliquotStConsumer} />

                          <img 
                            style={{marginLeft: 10, height: 42, width: 42}}
                            name="fillClassMovementCleanAliquotStConsumer"
                            src={image_clean} 
                            alt="Limpar" 
                            onClick={cleanAliquotStConsumer} />
                        </div>
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                      <div style={showMenu ?
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : {display: "flex", flexDirection: "column", flexGrow: 0}
                        :
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : {display: "flex", flexDirection: "column", flexGrow: 0}}>
                        <b>Redução:</b>
                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          :
                            (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="fillClassMovementReductionStConsumer"
                            description="Valor"
                            value={reductionStConsumer || ""}
                            maxLength={13}
                            disabled={false}
                            require={true}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleReductionStConsumer} />
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                        :
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                        <b>MVA:</b>
                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          :
                            (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="fillClassMovementMvaConsumer"
                            description="Valor"
                            value={mvaConsumer || ""}
                            maxLength={13}
                            disabled={false}
                            require={true}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMvaConsumer} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{marginTop: 10}}>
                <Titulo description="Revendedor" />
              </div>

              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                  <b>CST/CSOSN:</b>
                  <div style={showMenu ?
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                    :
                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={showMenu ?
                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :                      
                        (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                      <div style={showMenu ?
                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :   
                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <Input
                          type="text"
                          name="fillClassMovementCstCsosnResaleCode"
                          description="Código"
                          value={cstCsosnResaleCode}
                          maxLength={3}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnResaleCode(event.target.value)} />
                      </div>
                      <div style={showMenu ?
                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :
                          (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="text"
                          name="fillClassMovementCstCsosnResaleDescription"
                          description="Descrição"
                          value={cstCsosnResaleDescription}
                          maxLength={80}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2}   
                          onSelect={setFieldSelected}
                          onChange={(event) => setCstCsosnResaleDescription(event.target.value)} />
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                        : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}
                      :
                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, justifyContent: "center"}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, alignItems: "end"}}>
                      <img 
                        style={{height: 42, width: 42}}
                        name="fillClassMovementSearchCstCsosnResale"
                        src={image_search} 
                        alt="Pesquisar" 
                        onClick={enableCstCsosnResale} />

                      <img 
                        style={{marginLeft: 10, height: 42, width: 42}}
                        name="fillClassMovementCleanCstCsosnResale"
                        src={image_clean} 
                        alt="Limpar"
                        onClick={cleanCstCsosnResale} />
                    </div>
                  </div>
                </div>
              </div>

              <div style={showMenu ?
                  (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                :
                  (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                <div style={showMenu ?
                    (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                  :
                    (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                  <div>
                    <Titulo description="ICMS" />
                  </div>
                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                    :                 
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                      <b>Alíquota:</b>
                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="fillClassMovementAliquotIcmsResaleAcronym"
                            description="Sigla"
                            value={aliquotIcmsResaleAcronym}
                            maxLength={4}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected}
                            onChange={(event) => setAliquotIcmsResaleAcronym(event.target.value)} />
                        </div>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                            : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                          :
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                          <img 
                            style={{height: 42, width: 42}}
                            name="fillClassMovementSearchAliquotIcmsResale"
                            src={image_search} 
                            alt="Pesquisar" 
                            onClick={enableAliquotIcmsResale} />

                          <img 
                            style={{marginLeft: 10, height: 42, width: 42}}
                            name="fillClassMovementCleanAliquotIcmsResale"
                            src={image_clean} 
                            alt="Limpar" 
                            onClick={cleanAliquotIcmsResale} />
                        </div>
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                        : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                      <b>Redução:</b>
                      <div style={showMenu ?
                          (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                        : 
                          (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>                            
                          <InputNumeric
                            type="text"
                            name="fillClassMovementReductionIcmsResale"
                            description="Valor"
                            value={reductionIcmsResale || ""}
                            maxLength={13}
                            disabled={false}
                            require={true}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleReductionIcmsResale} />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={showMenu ?
                      (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    : 
                      (width < 1510) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                  <div>
                    <Titulo description="ST" />
                  </div>
                  <div style={showMenu ?
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                    :
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                      <b>Alíquota:</b>
                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                        :
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="fillClassMovementAliquotStResaleAcronym"
                            description="Sigla"
                            value={aliquotStResaleAcronym}
                            maxLength={4}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={setFieldSelected}
                            onChange={(event) => setAliquotStResaleAcronym(event.target.value)} />
                        </div>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                            : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}
                          :
                            (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                          <img 
                            style={{height: 42, width: 42}}
                            name="fillClassMovementSearchAliquotStResale"
                            src={image_search} 
                            alt="Pesquisar" 
                            onClick={enableAliquotStResale} />

                          <img 
                            style={{marginLeft: 10, height: 42, width: 42}}
                            name="fillClassMovementCleanAliquotStResale"
                            src={image_clean} 
                            alt="Limpar" 
                            onClick={cleanAliquotStResale} />
                        </div>
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                      <div style={showMenu ?
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : {display: "flex", flexDirection: "column", flexGrow: 0}
                        :
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                          : {display: "flex", flexDirection: "column", flexGrow: 0}}>
                        <b>Redução:</b>
                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          :
                            (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="fillClassMovementReductionStResale"
                            description="Valor"
                            value={reductionStResale || ""}
                            maxLength={13}
                            disabled={false}
                            require={true}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleReductionStResale} />
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}
                        :
                          (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                          : (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                          : {display: "flex", flexDirection: "column", flexGrow: 0, marginLeft: 10}}>
                        <b>MVA:</b>
                        <div style={showMenu ?
                            (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}
                          :
                            (width < 840) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, maxWidth: 180, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="fillClassMovementMvaResale"
                            description="Valor"
                            value={mvaResale || ""}
                            maxLength={13}
                            disabled={false}
                            require={true}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleMvaResale} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        :        
          <>
            { showAliquotIcmsConsumer &&
              <div>
                <b>Selecione a alíquota do ICMS do consumidor:</b>
                <SearchAliquot
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}                  
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillAliquotIcmsConsumer} 
                  setReturn={() => setShowAliquotIcmsConsumer(false)}
                  width={width} />
              </div>
            }

            { showAliquotIcmsResale &&
              <div>
                <b>Selecione a alíquota do ICMS do revendedor:</b>
                  <SearchAliquot
                    modeSearch={true}
                    databaseType={databaseType}
                    databasePath={databasePath}
                    jsonLogin={jsonLogin}
                    jsonCompany={jsonCompany}                    
                    system={system}
                    setAdd={() => alert("")}
                    setSelected={fillAliquotIcmsResale} 
                    setReturn={() => setShowAliquotIcmsResale(false)}
                    width={width} />
              </div>
            }

            { showAliquotStConsumer &&
              <div>
                <b>Selecione a alíquota do ST do consumidor:</b>
                <SearchAliquot
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}                  
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillAliquotStConsumer} 
                  setReturn={() => setShowAliquotStConsumer(false)} 
                  width={width} />
              </div>
            }

            { showAliquotStResale &&
              <div>
                <b>Selecione a alíquota do ST do revendedor:</b>
                <SearchAliquot
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}                  
                  system={system}
                  setAdd={() => alert("")}                  
                  setSelected={fillAliquotStResale} 
                  setReturn={() => setShowAliquotStResale(false)} 
                  width={width} />
              </div>
            }

            { showCstCsosnConsumer &&
              <div>
                <b>Selecione o CST/CSOSN do consumidor:</b>
                <SearchCstCsosn
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}                  
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillCstCsosnConsumer} 
                  setReturn={() => setShowCstCsosnConsumer(false)} 
                  showMenu={showMenu}
                  width={width} />
              </div>
            }

            { showCstCsosnResale &&
              <div>
                <b>Selecione o CST/CSOSN do revendedor:</b>
                <SearchCstCsosn
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillCstCsosnResale} 
                  setReturn={() => setShowCstCsosnResale(false)} 
                  showMenu={showMenu}
                  width={width} />
              </div>
            }

            { showState &&
              <div>
                <b>Selecione o estado:</b>
                <SearchState
                  modeSearch={true}
                  databaseType={databaseType}
                  databasePath={databasePath}
                  jsonLogin={jsonLogin}
                  jsonCompany={jsonCompany}                  
                  system={system}
                  setAdd={() => alert("")}
                  setSelected={fillState} 
                  setReturn={() => setShowState(false)} 
                  showMenu={showMenu}
                  width={width} />
              </div>
            }
          </>
      }      
    </div>
  )
}

export default FisClassMovement