import React, { useState, useEffect } from "react"
import AccessControlBilling from "./Billing"
import AccessControlCashierControl from "./CashierControl"
import AccessControlFinance from "./Finance"
import AccessControlHumanResources from "./HumanResources"
import AccessControlRequisition from "./Requisition"
import AccessControlStock from "./Stock"
import AccessControlTax from "./Tax"
import AccessControlTelemarketing from "./Telemarketing"
import AccessControlTreasury from "./Treasury"
import AccessControlManagement from "./Management"
import image_save from "../../Images/Buttons/save.png"

const AccessControl = ({jsonCompany, access, setSelected, width, showMenu}) => {
  // Cashier Control
  const [finisher, setFinisher] = useState("F")
  const [terminal, setTerminal] = useState("F")

  // Billing
  const [serviceInvoice, setServiceInvoice] = useState("F")
  const [centralTables, setCentralTables] = useState("F")

  // Finance
  const [billetBatch, setBilletBatch] = useState("F")
  const [billPay, setBillPay] = useState("F")
  const [billReceive, setBillReceive] = useState("F")
  const [bordereauPay, setBordereauPay] = useState("F")
  const [bordereauReceive, setBordereauReceive] = useState("F")
  const [dapPay, setDapPay] = useState("F")
  const [dapReceive, setDapReceive] = useState("F")
  const [documentType, setDocumentType] = useState("F")
  const [paymentMethod, setPaymentMethod] = useState("F")
  const [reportBillPay, setReportBillPay] = useState("F")
  const [reportBillReceive, setReportBillReceive] = useState("F")

  // Human Resources
  const [employee, setEmployee] = useState("F")
  const [rhPosition, setRhPosition] = useState("F")
  const [rhSection, setRhSection] = useState("F")
  const [rhShift, setRhShift] = useState("F")

  // Management
  const [accounting, setAccounting] = useState("F")
  const [company, setCompany] = useState("F")
  const [establishment, setEstablishment] = useState("F")
  const [digitalCertificate, setDigitalCertificate] = useState("F")
  const [mentesPensam, setMentesPensam] = useState("F")

  // Requisition
  const [shiftPrinter, setShiftPrinter] = useState("F")

  // Stock
  const [box, setBox] = useState("F")
  const [department, setDepartment] = useState("F")
  const [manufacturer, setManufacturer] = useState("F")
  const [priceTable, setPriceTable] = useState("F")
  const [product, setProduct] = useState("F")
  const [stock, setStock] = useState("F")
  const [supplier, setSupplier] = useState("F")
  const [unit, setUnit] = useState("F")

  // Tax
  const [aliquot, setAliquot] = useState("F")
  const [anp, setAnp] = useState("F")
  const [cest, setCest] = useState("F")
  const [cfop, setCfop] = useState("F")
  const [cnaeService, setCnaeService] = useState("F")
  const [cstCofins, setCstCofins] = useState("F")
  const [cstCsosn, setCstCsosn] = useState("F")
  const [cstIpi, setCstIpi] = useState("F")
  const [cstPis, setCstPis] = useState("F")
  const [fiscalClass, setFiscalClass] = useState("F")
  const [ncm, setNcm] = useState("F")
  const [nfModel, setNfModel] = useState("F")
  const [standardTax, setStandardTax] = useState("F")
  const [typeTaxOperation, setTypeTaxOperation] = useState("F")

  // Telemarketing
  const [address, setAddress] = useState("F")
  const [branch, setBranch] = useState("F")
  const [carrier, setCarrier] = useState("F")
  const [city, setCity] = useState("F")
  const [client, setClient] = useState("F")
  const [clientClass, setClientClass] = useState("F")
  const [clientRoute, setClientRoute] = useState("F")
  const [clientType, setClientType] = useState("F")
  const [country, setCountry] = useState("F")
  const [neighborhood, setNeighborhood] = useState("F")
  const [reportExpirationClientBillet, setReportExpirationClientBillet] = useState("F")
  const [state, setState] = useState("F")

  // Treasury
  const [account, setAccount] = useState("F")
  const [accountGroup, setAccountGroup] = useState("F")
  const [accountPlan, setAccountPlan] = useState("F")
  const [accountTransfer, setAccountTransfer] = useState("F")
  const [agency, setAgency] = useState("F")
  const [bank, setBank] = useState("F")
  const [reportAccountBalance, setReportAccountBalance] = useState("F")
  const [reportAccountExtract, setReportAccountExtract] = useState("F")

  const [show, setShow] = useState(false)

  useEffect(() => {
    let counter = 1
    const arrayProcesses = access.split("/")
    arrayProcesses.forEach(processes => {
      if (counter === 1) setCountry(processes)      
      else if (counter === 2) setState(processes)    
      else if (counter === 3) setCity(processes)
      else if (counter === 4) setNeighborhood(processes)
      else if (counter === 5) setAddress(processes)
      else if (counter === 6) setClientClass(processes)
      else if (counter === 7) setClientType(processes)    
      else if (counter === 8) setBranch(processes)
      else if (counter === 9) setClientRoute(processes)
      else if (counter === 10) setClient(processes)
      else if (counter === 11) setCarrier(processes)
      else if (counter === 12) setReportExpirationClientBillet(processes)    
      else if (counter === 13) setRhSection(processes)
      else if (counter === 14) setRhPosition(processes)
      else if (counter === 15) setRhShift(processes)
      else if (counter === 16) setEmployee(processes)
      else if (counter === 17) setSupplier(processes)
      else if (counter === 18) setManufacturer(processes)
      else if (counter === 19) setUnit(processes)
      else if (counter === 20) setDepartment(processes)
      else if (counter === 21) setBox(processes)
      else if (counter === 22) setStock(processes)
      else if (counter === 23) setProduct(processes)
      else if (counter === 24) setDocumentType(processes)
      else if (counter === 25) setPaymentMethod(processes)
      else if (counter === 26) setBillPay(processes)
      else if (counter === 27) setBordereauPay(processes)
      else if (counter === 28) setDapPay(processes)
      else if (counter === 29) setBillReceive(processes)
      else if (counter === 30) setBordereauReceive(processes)
      else if (counter === 31) setDapReceive(processes)
      else if (counter === 32) setBilletBatch(processes)
      else if (counter === 33) setReportBillPay(processes)
      else if (counter === 34) setReportBillReceive(processes)
      else if (counter === 35) setBank(processes)
      else if (counter === 36) setAgency(processes)
      else if (counter === 37) setAccount(processes)
      else if (counter === 38) setAccountGroup(processes)
      else if (counter === 39) setAccountPlan(processes)
      else if (counter === 40) setAccountTransfer(processes)
      else if (counter === 41) setReportAccountExtract(processes)
      else if (counter === 42) setReportAccountBalance(processes)
      else if (counter === 43) setEstablishment(processes)
      else if (counter === 44) setCompany(processes)
      else if (counter === 45) setAccounting(processes)
      else if (counter === 46) setDigitalCertificate(processes)
      else if (counter === 47) setMentesPensam(processes)
      else if (counter === 48) setServiceInvoice(processes)
      else if (counter === 49) setCentralTables(processes)
      else if (counter === 50) setFinisher(processes)
      else if (counter === 51) setTerminal(processes)
      else if (counter === 52) setAliquot(processes)
      else if (counter === 53) setAnp(processes)
      else if (counter === 54) setCest(processes)
      else if (counter === 55) setNcm(processes)
      else if (counter === 56) setCstIpi(processes)
      else if (counter === 57) setCstPis(processes)
      else if (counter === 58) setCstCofins(processes)
      else if (counter === 59) setCfop(processes)
      else if (counter === 60) setCstCsosn(processes)
      else if (counter === 61) setFiscalClass(processes)
      else if (counter === 62) setTypeTaxOperation(processes)
      else if (counter === 63) setNfModel(processes)
      else if (counter === 64) setCnaeService(processes)
      else if (counter === 65) setStandardTax(processes)
      else if (counter === 66) setShiftPrinter(processes)
      else if (counter === 67) setPriceTable(processes)
      counter++
    })

    setShow(true)
  }, [access])

  const setAccess = () => {
    const sAccess = country + "/" + state + "/" + city + "/" + neighborhood + "/" + address + "/" + clientClass + "/" + clientType + "/" +
      branch + "/" + clientRoute + "/" + client + "/" + carrier + "/" + reportExpirationClientBillet + "/" + rhSection + "/" + rhPosition + "/" +
        rhShift + "/" + employee + "/" + supplier + "/" + manufacturer + "/" + unit + "/" + department + "/" + box + "/" + stock + "/" +
          product + "/" + documentType + "/" + paymentMethod + "/" + billPay + "/" + bordereauPay + "/" + dapPay + "/" + billReceive + "/" +
            bordereauReceive + "/" + dapReceive + "/" + billetBatch + "/" + reportBillPay + "/" + reportBillReceive + "/" + bank + "/" +
              agency + "/" + account + "/" + accountGroup + "/" + accountPlan + "/" + accountTransfer + "/" + reportAccountExtract + "/" +
                reportAccountBalance + "/" + establishment + "/" + company + "/" + accounting + "/" + digitalCertificate + "/" +
                  mentesPensam + "/" + serviceInvoice + "/" + centralTables + "/" + finisher + "/" + terminal + "/" + aliquot + "/" + anp + "/" +
                    cest + "/" + ncm + "/" + cstIpi + "/" + cstPis + "/" + cstCofins + "/" + cfop + "/" + cstCsosn + "/" + fiscalClass + "/" +
                      typeTaxOperation + "/" + nfModel + "/" + cnaeService + "/" + standardTax + "/" + shiftPrinter + "/" + priceTable;

    setSelected(sAccess)
  }

  return(
    <div>
      { show &&
        <div>
          <AccessControlTelemarketing
            jsonCompany={jsonCompany}
            permissionAddress={address}
            permissionBranch={branch}
            permissionCarrier={carrier}
            permissionCity={city}
            permissionClient={client}
            permissionClientClass={clientClass}
            permissionClientRoute={clientRoute}
            permissionClientType={clientType}
            permissionCountry={country}
            permissionNeighborhood={neighborhood}
            permissionReportExpirationClientBillet={reportExpirationClientBillet}
            permissionState={state}
            setAddress={setAddress}
            setBranch={setBranch}
            setCarrier={setCarrier}
            setCity={setCity}
            setClient={setClient}
            setClientClass={setClientClass}
            setClientRoute={setClientRoute}
            setClientType={setClientType}
            setCountry={setCountry}
            setNeighborhood={setNeighborhood}
            setReportExpirationClientBillet={setReportExpirationClientBillet}
            setState={setState} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlHumanResources
            permissionEmployee={employee}
            permissionRhPosition={rhPosition}
            permissionRhSection={rhSection}
            permissionRhShift={rhShift}
            setEmployee={setEmployee}
            setRhPosition={setRhPosition}
            setRhSection={setRhSection}
            setRhShift={setRhShift} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlStock
            permissionBox={box}
            permissionDepartment={department}
            permissionManufacturer={manufacturer}
            permissionPriceTable={priceTable}
            permissionProduct={product}
            permissionStock={stock}
            permissionSupplier={supplier}
            permissionUnit={unit}
            setBox={setBox}
            setDepartment={setDepartment}
            setManufacturer={setManufacturer}
            setPriceTable={setPriceTable}
            setProduct={setProduct}
            setStock={setStock}
            setSupplier={setSupplier}
            setUnit={setUnit} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlFinance
            permissionBilletBatch={billetBatch}
            permissionBillPay={billPay}
            permissionBillReceive={billReceive}
            permissionBordereauPay={bordereauPay}
            permissionBordereauReceive={bordereauReceive}
            permissionDapPay={dapPay}
            permissionDapReceive={dapReceive}
            permissionDocumentType={documentType}
            permissionPaymentMethod={paymentMethod}
            permissionReportBillPay={reportBillPay}
            permissionReportBillReceive={reportBillReceive}
            setBilletBatch={setBilletBatch}
            setBillPay={setBillPay}
            setBillReceive={setBillReceive}
            setBordereauPay={setBordereauPay}
            setBordereauReceive={setBordereauReceive}
            setDapPay={setDapPay}
            setDapReceive={setDapReceive}
            setDocumentType={setDocumentType}
            setPaymentMethod={setPaymentMethod}
            setReportBillPay={setReportBillPay} 
            setReportBillReceive={setReportBillReceive} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlTreasury
            permissionAccount={account}
            permissionAccountGroup={accountGroup}
            permissionAccountPlan={accountPlan}
            permissionAccountTransfer={accountTransfer}
            permissionAgency={agency}
            permissionBank={bank}
            permissionReportAccountBalance={reportAccountBalance}
            permissionReportAccountExtract={reportAccountExtract}
            setAccount={setAccount}
            setAccountGroup={setAccountGroup}
            setAccountPlan={setAccountPlan}
            setAccountTransfer={setAccountTransfer}
            setAgency={setAgency}
            setBank={setBank}
            setReportAccountBalance={setReportAccountBalance}
            setReportAccountExtract={setReportAccountExtract} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlManagement
            permissionAccounting={accounting}
            permissionCompany={company}
            permissionEstablishment={establishment}
            permissionDigitalCertificate={digitalCertificate}
            permissionMentesPensam={mentesPensam}
            setAccounting={setAccounting}
            setCompany={setCompany}
            setEstablishment={setEstablishment}
            setDigitalCertificate={setDigitalCertificate}
            setMentesPensam={setMentesPensam} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlBilling
            permissionCentralTables={centralTables}
            permissionServiceInvoice={serviceInvoice}
            setCentralTables={setCentralTables}
            setServiceInvoice={setServiceInvoice}
            showMenu={showMenu}
            width={width} />

          <AccessControlCashierControl
            permissionFinisher={finisher}
            permissionTerminal={terminal}
            setFinisher={setFinisher}
            setTerminal={setTerminal} 
            showMenu={showMenu} 
            width={width} />

          <AccessControlTax
            permissionAliquot={aliquot}
            permissionAnp={anp}
            permissionCest={cest}
            permissionCfop={cfop}
            permissionCnaeService={cnaeService}
            permissionCstCofins={cstCofins}
            permissionCstCsosn={cstCsosn}
            permissionCstIpi={cstIpi}
            permissionCstPis={cstPis}
            permissionFiscalClass={fiscalClass}
            permissionNcm={ncm}
            permissionNfModel={nfModel}
            permissionStandardTax={standardTax}
            permissionTypeTaxOperation={typeTaxOperation}
            setAliquot={setAliquot}
            setAnp={setAnp}
            setCest={setCest}
            setCfop={setCfop}
            setCnaeService={setCnaeService}
            setCstCofins={setCstCofins}
            setCstCsosn={setCstCsosn}
            setCstIpi={setCstIpi}
            setCstPis={setCstPis}
            setFiscalClass={setFiscalClass}
            setNcm={setNcm}
            setNfModel={setNfModel}
            setStandardTax={setStandardTax}
            setTypeTaxOperation={setTypeTaxOperation} 
            showMenu={showMenu} 
            width={width} />
        
          <AccessControlRequisition
            permissionShiftPrinter={shiftPrinter}
            setShiftPrinter={setShiftPrinter} 
            showMenu={showMenu}
            width={width} />

          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginTop: 10,
            marginRight: 0, marginBottom: 10, marginLeft: 0}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
              marginLeft: 5, width: 147}} onClick={setAccess}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default AccessControl