import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import Menu from "../Menu"
import Select from "../../Components/Select"
import SearchUnit from "./Search"
import image_loading from "../../Images/spinner.gif"

const Unit = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [acronym, setAcronym] = useState("")
  const [description, setDescription] = useState("")
  const [fractioned, setFractioned] = useState(2)
  const [weighable, setWeighable] = useState(2)
  const [meter, setMeter] = useState(2)
  const [sge, setSge] = useState("")

  const [jsonUnit, setJsonUnit] = useState({})

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      setAcronym("")
      setDescription("")
      setFractioned(2)
      setWeighable(2)
      setMeter(2)
      setSge("")
    }
    else {
      setAcronym(jsonUnit.ACRONYM)
      setDescription(jsonUnit.DESCRIPTION)
      setFractioned(jsonUnit.FRACTIONED)
      setWeighable(jsonUnit.WEIGHABLE)
      setMeter(jsonUnit.METER)

      if ((jsonUnit.SGE !== null) && (jsonUnit.SGE !== undefined)) setSge(jsonUnit.SGE)
    }

    document.getElementById("unitAcronym") && document.getElementById("unitAcronym").focus()

  }, [code, jsonUnit, updateFocu])  

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setAcronym("")
      setDescription("")
      setFractioned(2)
      setWeighable(2)
      setMeter(2)
      setSge("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonUnit_New = {}

    setWarning("")
    setError("")
        
    jsonUnit_New = "{ "
    if (code !== "") jsonUnit_New += "\"ID\": "+ code
    if (jsonUnit_New !== "{ ") jsonUnit_New += ", "
    if (acronym !== "") jsonUnit_New += "\"ACRONYM\": \""+ acronym +"\""
    if (jsonUnit_New !== "{ ") jsonUnit_New += ", "
    if (description !== "") jsonUnit_New += "\"DESCRIPTION\": \""+ description +"\""
    if (jsonUnit_New !== "{ ") jsonUnit_New += ", "
    jsonUnit_New += "\"FRACTIONED\": \""+ fractioned +"\""
    if (jsonUnit_New !== "{ ") jsonUnit_New += ", "
    jsonUnit_New += "\"WEIGHABLE\": \""+ weighable +"\""
    if (jsonUnit_New !== "{ ") jsonUnit_New += ", "
    jsonUnit_New += "\"METER\": \""+ meter +"\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonUnit_New += ", \"SGE\": "+ sge
    jsonUnit_New += " }"
    
    if (code === "")
    {
      setLoading("Aguarde incluindo o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonUnit_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "UNIT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir a unidade. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("unitDescription") && document.getElementById("unitDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")
                
        setJsonUnit(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
    else 
    {
      setLoading("Aguarde alterando o registro...")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonUnit))
      formData.append("JSON_NEW_1", jsonUnit_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "UNIT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar a unidade. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("unitDescription") && document.getElementById("unitDescription").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJsonUnit(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)
    setJsonUnit(event)
    setCode(event.ID)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Unidade"
            titleSearch="Pesquisa das Unidades"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchUnit
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => returnAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        { (code === "") &&
                          <div style={(width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 460) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                              <Input
                                type="text"
                                name="unitAcronym"
                                description="Sigla"
                                value={acronym}
                                maxLength={2}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setAcronym(event.target.value)} />
                            </div>
                            <div style={(width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="unitDescription"
                                description="Descrição"
                                value={description}
                                maxLength={50}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        { (code !== "") &&
                          <div style={(showMenu) ?
                              (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                            :
                              (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                            <div style={(width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={(showMenu) ?
                                  (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                : 
                                  (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                <Input
                                  type="text"
                                  name="unitCode"
                                  description="Código"
                                  value={code}
                                  maxLength={11}
                                  disabled={true}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setCode(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                  (width < 460) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                  : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                : 
                                  (width < 460) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                  : (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>

                                <Input
                                  type="text"
                                  name="unitAcronym"
                                  description="Sigla"
                                  value={acronym}
                                  maxLength={2}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setAcronym(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              : 
                                (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                              <Input
                                type="text"
                                name="unitDescription"
                                description="Descrição"
                                value={description}
                                maxLength={50}
                                disabled={false}
                                require={true}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                onChange={(event) => setDescription(event.target.value)} />
                            </div>
                          </div>
                        }

                        <div style={(showMenu) ?
                            (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 610) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(showMenu) ?
                              (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                              : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            : 
                              (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                            <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                              <Select
                                name="unitFractioned"
                                description="Fracionado"
                                value={fractioned || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setFractioned} />
                            </div>
                            <div style={(showMenu) ?
                                (width < 460) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                : (width < 710) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                              : 
                                (width < 460) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                              <Select
                                name="unitWeighable"
                                description="Pesável"
                                value={weighable || 0}
                                require={true}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={setWeighable} />
                            </div>
                          </div>
                          <div style={(showMenu) ?
                              (width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            :
                              (width < 610) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                            <Select
                              name="unitMeter"
                              description="Metro"
                              value={meter || 0}
                              require={true}
                              options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setMeter} />
                          </div>
                        </div>
                      </div> 
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Unit