import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import Select from "../../Components/Select"
import SearchRhSection from "../RhSection/Search"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const CompanyEmail = ({databaseType, databasePath, jsonCompanyEmail, jsonCompany, jsonLogin, system, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [rhSectionCode, setRhSectionCode] = useState("")
  const [rhSectionDescription, setRhSectionDescription] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [smtpServer, setSmtpServer] = useState("")
  const [smtpPort, setSmtpPort] = useState("")
  const [ssl, setSsl] = useState("")
  const [nfeStandard, setNfeStandard] = useState("")
  const [nfceStandard, setNfceStandard] = useState("")
  const [nfseStandard, setNfseStandard] = useState("")
    
  const [showRhSection, setShowRhSection] = useState(false) 

  useEffect(() => {      
    setCode(jsonCompanyEmail.ID)
    setRhSectionCode(jsonCompanyEmail.RH_SECTION.ID)
    setRhSectionDescription(jsonCompanyEmail.RH_SECTION.DESCRIPTION)
    setCompanyCode(jsonCompanyEmail.RH_SECTION.COMPANY.ID)
    setEmail(jsonCompanyEmail.EMAIL)

    if (jsonCompanyEmail.USER_NAME !== "null") setUserName(jsonCompanyEmail.USER_NAME)
    else setUserName("")

    if (jsonCompanyEmail.PASSWORD !== "null") setPassword(jsonCompanyEmail.PASSWORD)
    else setPassword("")

    if (jsonCompanyEmail.SMTP_SERVER !== "null") setSmtpServer(jsonCompanyEmail.SMTP_SERVER)
    else setSmtpServer("")
    
    if (jsonCompanyEmail.SMTP_PORT !== "0") setSmtpPort(jsonCompanyEmail.SMTP_PORT)
    else setSmtpPort("")

    setSsl(jsonCompanyEmail.SSL)
    setNfeStandard(jsonCompanyEmail.NFE_STANDARD)
    setNfceStandard(jsonCompanyEmail.NFCE_STANDARD)
    setNfseStandard(jsonCompanyEmail.NFSE_STANDARD)

  }, [jsonCompanyEmail])
   
  const cleanRhSection = () => {
    setRhSectionCode("")
    setRhSectionDescription("")

    setShowRhSection(false)
  }

  const enableRhSection = () => {
    setShowRhSection(!showRhSection)
  }  

  const fillRhSection = (section) => {
    setRhSectionCode(section.ID)
    setRhSectionDescription(section.DESCRIPTION)
  
    setShowRhSection(false)
  }  

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"RH_SECTION\": {"        
    if ((rhSectionCode !== undefined) && (rhSectionCode !== 0)){
      json += " \"ID\": "+ rhSectionCode + ", \"DESCRIPTION\": \""+ rhSectionDescription + "\","
    }        
    json += " \"COMPANY\": { \"ID\": "+ companyCode + " } }"
    if (email !== undefined) json += ", \"EMAIL\": \""+ email +"\""
    else json += ", \"EMAIL\": \"\""        
    if (userName !== undefined) json += ", \"USER_NAME\": \""+ userName +"\""
    else json += ", \"USER_NAME\": \"\""
    if (password !== undefined) json += ", \"PASSWORD\": \""+ password +"\""
    else json += ", \"PASSWORD\": \"\""
    if (smtpServer !== undefined) json += ", \"SMTP_SERVER\": \""+ smtpServer +"\""
    else json += ", \"SMTP_SERVER\": \"\""
    if ((smtpPort !== undefined) && (smtpPort !== "")) json += ", \"SMTP_PORT\": "+ parseInt(String(smtpPort).replaceAll("_",""))
    else json += ", \"SMTP_PORT\": "+ 0
    if ((ssl !== undefined) && (ssl !== 0)) json += ", \"SSL\": \""+ ssl + "\""
    if ((nfeStandard !== undefined) && (nfeStandard !== 0)) json += ", \"NFE_STANDARD\": \""+ nfeStandard + "\""
    if ((nfceStandard !== undefined) && (nfceStandard !== 0)) json += ", \"NFCE_STANDARD\": \""+ nfceStandard + "\""
    if ((nfseStandard !== undefined) && (nfseStandard !== 0)) json += ", \"NFSE_STANDARD\": \""+ nfseStandard + "\""
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showRhSection &&
        <>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={callSave}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={() => back()}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
          </div>

          <div style={(width < 450) ? { paddingLeft: 5, marginTop: 10 }
            : (width < 540) ? { marginTop: 10 }
            : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
            <label style={{fontWeight: 600}}>Setor RH</label>
          </div>

          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 5, marginBottom: 10}
            : (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginBottom: 10, paddingLeft: 20, paddingRight: 20}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
              <Input
                type="text"
                name="companyEmailRhSectionDescription"
                description="Descrição"
                value={rhSectionDescription}
                maxLength={60}
                disabled={true}
                require={true}
                image={false}
                typeInput={2} 
                onSelect={setFieldSelected}
                onChange={(event) => setRhSectionDescription(event.target.value)} />
            </div>
            <div style={(width < 540) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
              marginTop: 10}
              : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="companyEmailRhSectionSearch"
                  src={image_search} 
                  alt="Pesquisar" 
                  onClick={(() => enableRhSection(true))} />
              </div>
              <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                <img
                  style={{height: 42, width: 42}}
                  name="companyEmailRhSectionClean"
                  src={image_clean} 
                  alt="Limpar"
                  onClick={cleanRhSection} />
              </div>
            </div>
          </div>

           <div style={
            (width < 450) ? {paddingLeft: 5}
            : (width < 540) ? {display: "flex", flexGrow: 1}
            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
            <Input
              type="text"
              name="companyEmailEmail"
              description="Email"
              value={email}
              maxLength={70}
              disabled={false}
              require={true}
              image={false}
              typeInput={2}
              onSelect={setFieldSelected}
              onChange={(event) => setEmail(event.target.value)} />
          </div>

          <div style={showMenu ?
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : (width < 840) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingRight: 20, paddingLeft: 20, marginTop: 10}
              : {display: "flex", flexGrow: 1, flexDirection: "row", paddingRight: 20, paddingLeft: 20, marginTop: 10}
            :
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : {display: "flex", flexGrow: 1, flexDirection: "row", paddingRight: 20, paddingLeft: 20, marginTop: 10}}>
            <div style={(width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
              <Input
                type="text"
                name="companyEmailUserName"
                description="UserName"
                value={userName}
                maxLength={255}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setUserName(event.target.value)} />
            </div>
            <div style={showMenu ?
                (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              :
                (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
              <Input
                type="text"
                name="companyEmailPassword"
                description="Password"
                value={password}
                maxLength={255}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setPassword(event.target.value)} />
            </div>
          </div>

          <div style={showMenu ?
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : (width < 840) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingRight: 20, paddingLeft: 20, marginTop: 10}
              : {display: "flex", flexGrow: 1, flexDirection: "row", paddingRight: 20, paddingLeft: 20, marginTop: 10}
            :
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : {display: "flex", flexGrow: 1, flexDirection: "row", paddingRight: 20, paddingLeft: 20, marginTop: 10}}>
            <div style={(width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>                
              <Input
                type="text"
                name="companyEmailSmtpServer"
                description="SMTP server"
                value={smtpServer}
                maxLength={255}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setSmtpServer(event.target.value)} />
            </div>
            <div style={showMenu ?
                (width < 840) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
              :              
                (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
              <InputMasked
                type="text"
                name="companyEmailSmtpPort"
                description="SMTP port"
                mask="999999"
                value={smtpPort}
                disabled={false}
                require={false}
                image={false}
                typeInput={2}
                onSelect={setFieldSelected}
                onChange={(event) => setSmtpPort(event.target.value)} />
            </div>
          </div>

          <div style={showMenu ?
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : (width < 1160) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 20, paddingRight: 20, marginTop: 10}
              : {display: "flex", flexGrow: 1, flexDirection: "row", paddingLeft: 20, paddingRight: 20, marginTop: 10}
            :            
              (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 5, marginTop: 10}
              : (width < 540) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
              : (width < 860) ? {display: "flex", flexGrow: 1, flexDirection: "column", paddingLeft: 20, paddingRight: 20, marginTop: 10}
              : {display: "flex", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>
            <div style={showMenu ?
                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}
              : 
                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                  : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                :
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                <Select
                  name="ssl"
                  description="SSL"
                  value={ssl || 0}
                  require={true}
                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                    ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  set={setSsl} />
              </div>
              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                  : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                :
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                <Select
                  name="companyEmailNfeStandard"
                  description="Padrão NFe"
                  value={nfeStandard || 0}
                  require={true}
                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                    ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  set={setNfeStandard} />
              </div>
            </div>
            <div style={showMenu ?
                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                : (width < 1160) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
              :              
                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                  : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                :
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                <Select
                  name="companyEmailNfceStandard"
                  description="Padrão NFCe"
                  value={nfceStandard || 0}
                  require={true}
                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                    ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  set={setNfceStandard} />
              </div>
              <div style={showMenu ?
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                  : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}
                :
                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180, marginLeft: 10}}>
                <Select
                  name="companyEmailNfseStandard"
                  description="Padrão NFSe"
                  value={nfseStandard || 0}
                  require={true}
                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                    ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  set={setNfseStandard} />
              </div> 
            </div>
          </div>
        </>
      }

      { showRhSection &&
        <div>
          <b>Selecione o Setor RH:</b>
          <SearchRhSection
            modeSearch={true}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonLogin={jsonLogin}
            jsonCompany={jsonCompany}
            system={system}
            setAdd={() => alert("")}
            setSelected={fillRhSection} 
            setReturn={() => setShowRhSection(false)} 
            width={width} />
        </div>
      }

    </div>
  )
}

export default CompanyEmail