import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DigitalKeyboard from "../../Components/DigitalKeyboard"
import DigitalKeyboardNumeric from "../../Components/DigitalKeyboardNumeric"
import Input from "../../Components/Input"
import SearchPagination from "../../Components/SearchPagination"
import Select from "../../Components/Select"
import image_asterisk from "../../Images/Buttons/asterisk.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select_circle.png"

const SaleProductSearch = ({databaseType, databasePath, jsonCompany, jsonLogin, system, keyboard, setShowFunctions, setSelected, width}) => {
  const [field, setField] = useState("")

  const [typeSearch, setTypeSearch] = useState(1)
  const [inputSearch, setInputSearch] = useState("")  
  const [quantity, setQuantity] = useState(1)  

  const [result, setResult] = useState("")
  const [showResult, setShowResult] = useState(false)

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")

  useEffect(() => {
    document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
  }, [keyboard, updatePage])

  const cleanSearch = () => {
    setInputSearch("")
    document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
    setResult([])
    setShowResult(false)
  }

  const changeQuantity = () => {
    let quantit = 0

    try 
    {
      quantit = parseFloat(inputSearch) 

      if ((quantit == 0) || (quantity > 9999.99)) setQuantity("1.000") 
      else setQuantity(formatNumeric(quantit, 3))

      setInputSearch("")
    } 
    catch { setQuantity("1.000") }

    setUpdatePage(updatePage + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pressEnter = (field) => {
    search()
  }

  const pressButton = (field, value) => {
    setInputSearch(inputSearch + value)
    setUpdatePage(updatePage +1)
  }

  const pressBackspace = (field) => {
    if (inputSearch != ""){
      const str = String(inputSearch).substring(0, inputSearch.length - 1);
      setInputSearch(str)
      setUpdatePage(updatePage +1)
    } else document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
  }

  const returnSearch = () => {
    setResult([])
    setShowResult(false)

    setShowFunctions(true)
    setUpdatePage(updatePage + 1)
  }

  const search = () => {
    setError("")

    // Code or BarCode
    if (parseInt(typeSearch) == 1){
      if (inputSearch == ""){
        setError("É necessário informar o Código ou Código de Barras!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }

      if (inputSearch.length > 14){
        setError("Código de Barras inválido!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }
    }

    // Description
    if (parseInt(typeSearch) == 2){
      if (inputSearch == ""){
        setError("É necessário informar a Descrição!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }

      if (inputSearch.length <= 2){
        setError("É necessário informar ao menos 3 letras na Descrição!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }

      if (inputSearch.length > 120){
        setError("Descrição inválida!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }
    }

    // Reference
    if (parseInt(typeSearch) == 3){
      if (inputSearch == ""){
        setError("É necessário informar a Referência!!!")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }      
    }

    let returnJson = []
    let jProduct = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }, \"TYPES\": \"'01','02','04','05','10','11'\""

    if (parseInt(typeSearch) == 1){
      if (inputSearch.length < 7) jProduct += ", \"PRODUCT\": { \"ID\": "+ inputSearch + " }"
      else jProduct += ", \"PRODUCT\": { \"TAXABLE_BARCODE\": \""+ inputSearch + "\" }"
    }
    else if (parseInt(typeSearch) == 2) jProduct += ", \"PRODUCT\": { \"DESCRIPTION\": \""+ inputSearch + "\" }"
    else if (parseInt(typeSearch) == 3) jProduct += ", \"PRODUCT\": { \"REFERENCE\": \""+ inputSearch + "\" }"

    jProduct += " }"

    setLoading("Aguarde buscando os produtos...")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jProduct)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "PRODUCT_COMPANY/TotalRecordsSale", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar o total de produtos. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }

      const tRecord = returnJson.OBJECT
      if (tRecord === 0)
      {
        setError("Nenhum registro encontrado.")        
        setShowResult(false)
        setLoading("")
        document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
        return
      }

      const operation = tRecord / recordPage
      const numberR = Math.floor(operation)
      const decimal = operation % 1

      let tPage = 0
      if (decimal > 0) tPage = numberR + 1
      else tPage = numberR

      if (tRecord > 600)
        setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
          ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

      setTotalRecord(tRecord)
      setTotalPage(tPage)
      if (tPage < pageCurrent) setPageCurrent(1)
   
      let qtdeInitial = 0
      if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jProduct)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", qtdeInitial)
      formData.append("QUANTITY_RECORDS", recordPage)

      axios.post(BASE_URL + "PRODUCT_COMPANY/SearchSale", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as produtos. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")
          document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
          return
        }

        if (tRecord == 1){
          setResult(returnJson.OBJECT[0])
          setSelected()
        }
        else {
          setResult(returnJson)
          setShowResult(true)
          setLoading("")
        }

        setShowFunctions(false)
      })
    })
  }

  const selectTypeSearch = (item) => {
    setTypeSearch(item)

    setResult([])
    setShowResult(false)
    document.getElementById("saleProductSearchInputSearch") && document.getElementById("saleProductSearchInputSearch").focus()
  }

  const setFieldSelected = (field) => {
    setField(field)
  }  

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 10, paddingRight: 10, paddingBottom: 0,
              paddingLeft: 10}}>
              { error &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                  marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                </div>
              }
            </div>
            
            { showResult &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center", marginBottom: 10}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginLeft: 5, 
                  width: 147}} onClick={() => returnSearch()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            }

            { !showResult &&
              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>

                <div style={(width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                  <div style={(width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>

                    <div style={(width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}}>
                      <div style={
                        (width < 360) ? {display: "flex", flexGrow: 1, marginTop: 10, marginRight: 10, minWidth: 180}
                        : (width < 550) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <Select
                          name="saleMainTypeSearch"
                          description="Tipo da Pesquisa:"
                          value={typeSearch || 0}
                          require={false}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Código/EAN\" }, {\"ID\": 2, \"VALUE\": \"Descrição\" }"+
                            ", {\"ID\": 3, \"VALUE\": \"Referência\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={selectTypeSearch} />
                      </div>
                      <div style={
                        (width < 360) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, marginRight: 10, minWidth: 180}
                        : (width < 550) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="text"
                          name="saleProductSearchInputSearch"
                          description="Filtro:"
                          value={inputSearch}
                          maxLength={(parseInt(typeSearch) == 1) ? 14 : (parseInt(typeSearch) == 2) ? 120 : 15}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setInputSearch(event.target.value)} />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>                    
                      <div style={
                          (width < 360) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, marginLeft: 5, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, marginLeft: 5, maxWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 150, maxWidth: 150}}>
                        <Input
                          type="text"
                          name="saleProductSearchQuantity"
                          description="Quantidade:"
                          value={formatNumeric(quantity, 3)}
                          maxLength={13}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setQuantity(event.target.value)} />
                      </div>

                      <div style={(width < 760) ? {display: "flex", flexGrow: 0, marginLeft: 5, marginRight: 10, marginTop: 40}
                        : {display: "flex", flexGrow: 0, marginLeft: 5, marginTop: 30}}>
                        <img style={{width: 44, height: 44}} src={image_asterisk} alt="Alterar Quantidade" onClick={() => changeQuantity()}/>
                      </div>
                    </div>
                  </div>
                  <div style={(width < 360) ? {display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10}
                    : {display: "flex", flexGrow: 0, marginLeft: 5, marginRight: 10, marginTop: 30}}>
                    <img style={{width: 44, height: 44}} src={image_search} alt="Pesquisar" onClick={() => search()}/>
                    <img style={{width: 44, height: 44, marginLeft: 5}} src={image_clean} alt="Limpar" onClick={cleanSearch}/>
                  </div>
                </div>

                { keyboard &&
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, 
                    marginBottom: 10}}>
                    <DigitalKeyboard
                      field={field}
                      pressButton={pressButton}
                      pressBackSpace={pressBackspace}
                      pressEnter={pressEnter}
                      width={width} />
                  </div>
                }

              </div>
            }

            { showResult &&
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginBottom: 10}}>
                  <label style={{color: "rgb(0, 177, 171)", fontWeight: 600, textAlign: "center"}}>Pesquisa dos Produtos</label>
                </div>

                { result.OBJECT &&
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", borderBottomStyle: "solid", 
                      borderBottomWidth: 5, borderBottomColor: "rgb(0, 177, 171)", paddingLeft: 5}}>

                      <div style={(width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={(width < 460) ?
                            {display: "flex", flexDirection: "column", flexGrow: 1}
                          :
                            {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={
                            (width < 460) ?
                              {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                            :
                              {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140, marginLeft: 10}}>
                            <label style={{fontWeight: 600}}>Cód. Barras</label>
                          </div>

                          <div style={
                            (width < 460) ?
                              {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                            :  
                              {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10}}>
                            <label style={{fontWeight: 600}}>Descrição</label>
                          </div>
                        </div>

                        <div style={
                            (width < 620) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                          : 
                            {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100, marginLeft: 10}}>
                          <label style={{fontWeight: 600}}>Preço</label>
                        </div>
                      </div>

                      { (width >= 500) &&
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginRight: 10}}>
                          <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 5,
                            width: 40}} />
                        </div>                        
                      }

                    </div>

                    { result.OBJECT.map(item => (
                      <div key={item.ID} style={
                          (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center",
                          backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, 
                          borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4}
                        :
                          {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", backgroundColor: "rgb(241, 242, 242)",
                          borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, 
                          paddingBottom: 4, marginTop: 4}}>

                        <div style={(width < 620) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                          <div style={(width < 460) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={
                              (width < 460) ?
                                {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                              :
                                {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140, marginLeft: 10}}>
                              <label>{item.PRODUCT.COMMERCIAL_BARCODE}</label>
                            </div>

                            <div style={
                              (width < 460) ?
                                {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                              :  
                                {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10}}>
                              <label>{item.PRODUCT.DESCRIPTION}</label>
                            </div>
                          </div>

                          <div style={
                              (width < 620) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginLeft: 10, marginRight: 10}
                            : 
                              {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100, marginLeft: 10}}>
                            <label>{formatNumeric(item.SALE_PRICE, 2)}</label>
                          </div>
                        </div>

                        <div style={(width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center"}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginRight: 10}}>
                          <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 5, 
                            width: 40}}>
                            <img
                              style={{height: 32, width: 32}}
                              name="saleProductSearchSelected"
                              src={image_select}
                              alt="Selecionar"
                              onClick={(() => setSelected(item))} />
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                }

                <SearchPagination 
                  pageCurrent={pageCurrent}
                  totalPage={totalPage}
                  recordPage={recordPage}
                  optionsRegPage={JSON.parse(optionsRegPage)}
                  pagePrevious={pagePrevious}
                  pageNext={pageNext}
                  setSelectedField={setFieldSelected}
                  handleRecordPage={handleRecordPage}
                  width={width} />
              </div>
            }
          </div>
      }
    </div>
  )
}

export default SaleProductSearch