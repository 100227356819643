import "./style.css"
import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask";
import img_boneco from "../../Images/usuario.png"
import img_cadeado from "../../Images/cadeado.png"

const InputMaskedLogin = ({name, description, planceHolder, mask, value, disabled, require, image, typeImage, typeInput, onSelect, onChange}) => {
  const [obrigatorio, setObrigatorio] = useState("")
  const [colorFocu, setColorFocu] = useState( (typeInput === 1) ? "corpoUnFocuLogin_inputMasked" : "corpoUnFocuTela_inputMasked" )
  const descricaoInputMasked = (typeInput === 1) ? "descricaoLogin_inputMasked" : "descricaoTela_inputMasked"

  useEffect(() => {
    if (String(value) !== "") setObrigatorio("naoObrigatorio_inputMasked")
    else
    {
      if (require){
        if (String(value) !== "") setObrigatorio("naoObrigatorio_inputMasked")
        else setObrigatorio("obrigatorio_inputMasked")
      } else setObrigatorio("naoObrigatorio_inputMasked")
    }
  }, [require, value])

  function handleBlur(event) {
    if (event.target.value === "") {
      if (require) setObrigatorio("obrigatorio_inputMasked"); else setObrigatorio("naoObrigatorio_inputMasked")        
    } else setObrigatorio("naoObrigatorio_inputMasked")

    if (typeInput === 1) setColorFocu("corpoUnFocuLogin_inputMasked")
    else if (typeInput === 2) setColorFocu("corpoUnFocuTela_inputMasked")        
  }

  function handleFocu(){
    if (typeInput === 1) setColorFocu("corpoFocuLogin_inputMasked")
    else if (typeInput === 2) setColorFocu("corpoFocuTela_inputMasked")

    onSelect(name)
  }

  return(
    <div style={{ width: "100%" }}>
      <div id="inputMasked">

        { description &&
          <div id={descricaoInputMasked}>
            <label>{description}</label>
          </div>
        }

        <div id={colorFocu}>

          { image &&
            <>

              { (typeImage === 1) && 
                <div className="corpoImagem_inputMasked">
                  <img src={img_boneco} alt="boneco" style={{ width: 23, height: 30 }} /> 
                </div>
              }

              { (typeImage === 2) && 
                <div className="corpoImagem_inputMasked">
                  <img src={img_cadeado} alt="cadeado" /> 
                </div>
              }

              <div id="corpoCampoImagem_inputMasked">
                <InputMask
                  InputMask
                  id={name}
                  name={name}
                  className={obrigatorio}
                  placeholder={planceHolder}
                  mask={mask}
                  value={value}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onFocus={handleFocu}
                  onChange={onChange} />
              </div>

            </>
          }

          { !image &&
            <>
              <div id="corpoCampoSemImagem_inputMasked">
                <InputMask
                  id={name}
                  name={name}
                  className={obrigatorio}
                  placeholder={planceHolder}
                  mask={mask}
                  value={value}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onFocus={handleFocu}                  
                  onChange={onChange} />                    
              </div>
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default InputMaskedLogin
