import React, { useState, useEffect } from "react"

const AccessControlTax = ({permissionAliquot, permissionAnp, permissionCest, permissionCfop, permissionCnaeService, permissionCstCofins,
  permissionCstCsosn, permissionCstIpi, permissionCstPis, permissionFiscalClass, permissionNcm, permissionNfModel, permissionStandardTax,
  permissionTypeTaxOperation, setAliquot, setAnp, setCest, setCfop, setCnaeService, setCstCofins, setCstCsosn, setCstIpi, setCstPis,
  setFiscalClass, setNcm, setNfModel, setStandardTax, setTypeTaxOperation, showMenu, width}) => {

  const [aliquotF, setAliquotF] = useState(1)
  const [anpF, setAnpF] = useState(1)
  const [cestF, setCestF] = useState(1)
  const [cfopF, setCfopF] = useState(1)
  const [cnaeServiceF, setCnaeServiceF] = useState(1)
  const [cstCofinsF, setCstCofinsF] = useState(1)
  const [cstCsosnF, setCstCsosnF] = useState(1)  
  const [cstIpiF, setCstIpiF] = useState(1)
  const [cstPisF, setCstPisF] = useState(1)
  const [fiscalClassF, setFiscalClassF] = useState(1)
  const [ncmF, setNcmF] = useState(1)
  const [nfModelF, setNfModelF] = useState(1)
  const [standardTaxF, setStandardTaxF] = useState(1)
  const [typeTaxOperationF, setTypeTaxOperationF] = useState(1)

  const handleAliquot = () => {
    setAliquotF(2)
    setAliquot("F")
  }

  const handleAliquotC = () => {
    setAliquotF(1)
    setAliquot("T")
  }

  const handleAnp = () => {
    setAnpF(2)
    setAnp("F")
  }

  const handleAnpC = () => {
    setAnpF(1)
    setAnp("T")
  }

  const handleCest = () => {
    setCestF(2)
    setCest("F")
  }

  const handleCestC = () => {
    setCestF(1)
    setCest("T")
  }

  const handleCfop = () => {
    setCfopF(2)
    setCfop("F")
  }

  const handleCfopC = () => {
    setCfopF(1)
    setCfop("T")
  }

  const handleCnaeService = () => {
    setCnaeServiceF(2)
    setCnaeService("F")
  }

  const handleCnaeServiceC = () => {
    setCnaeServiceF(1)
    setCnaeService("T")
  }

  const handleCstCofins = () => {
    setCstCofinsF(2)
    setCstCofins("F")
  }

  const handleCstCofinsC = () => {
    setCstCofinsF(1)
    setCstCofins("T")
  }

  const handleCstCsosn = () => {
    setCstCsosnF(2)
    setCstCsosn("F")
  }

  const handleCstCsosnC = () => {
    setCstCsosnF(1)
    setCstCsosn("T")
  }

  const handleCstIpi = () => {
    setCstIpiF(2)
    setCstIpi("F")
  }

  const handleCstIpiC = () => {
    setCstIpiF(1)
    setCstIpi("T")
  }

  const handleCstPis = () => {
    setCstPisF(2)
    setCstPis("F")
  }

  const handleCstPisC = () => {
    setCstPisF(1)
    setCstPis("T")
  }

  const handleFiscalClass = () => {
    setFiscalClassF(2)
    setFiscalClass("F")
  }

  const handleFiscalClassC = () => {
    setFiscalClassF(1)
    setFiscalClass("T")
  }

  const handleNcm = () => {
    setNcmF(2)
    setNcm("F")
  }

  const handleNcmC = () => {
    setNcmF(1)
    setNcm("T")
  }

  const handleNfModel = () => {
    setNfModelF(2)
    setNfModel("F")
  }

  const handleNfModelC = () => {
    setNfModelF(1)
    setNfModel("T")
  }

  const handleStandardTax = () => {
    setStandardTaxF(2)
    setStandardTax("F")
  }

  const handleStandardTaxC = () => {
    setStandardTaxF(1)
    setStandardTax("T")
  }

  const handleTypeTaxOperation = () => {
    setTypeTaxOperationF(2)
    setTypeTaxOperation("F")
  }

  const handleTypeTaxOperationC = () => {
    setTypeTaxOperationF(1)
    setTypeTaxOperation("T")
  }

  useEffect(() => {
    if (permissionAliquot === "T") setAliquotF(1)
    else setAliquotF(2)

    if (permissionAnp === "T") setAnpF(1)
    else setAnpF(2)

    if (permissionCest === "T") setCestF(1)
    else setCestF(2)

    if (permissionCfop === "T") setCfopF(1)
    else setCfopF(2)

    if (permissionCnaeService === "T") setCnaeServiceF(1)
    else setCnaeServiceF(2)

    if (permissionCstCofins === "T") setCstCofinsF(1)
    else setCstCofinsF(2)

    if (permissionCstCsosn === "T") setCstCsosnF(1)
    else setCstCsosnF(2)

    if (permissionCstIpi === "T") setCstIpiF(1)
    else setCstIpiF(2)

    if (permissionCstPis === "T") setCstPisF(1)
    else setCstPisF(2)

    if (permissionFiscalClass === "T") setFiscalClassF(1)
    else setFiscalClassF(2)

    if (permissionNcm === "T") setNcmF(1)
    else setNcmF(2)

    if (permissionNfModel === "T") setNfModelF(1)
    else setNfModelF(2)

    if (permissionStandardTax === "T") setStandardTaxF(1)
    else setStandardTaxF(2)

    if (permissionTypeTaxOperation === "T") setTypeTaxOperationF(1)
    else setTypeTaxOperationF(2)

  }, [permissionAliquot, permissionAnp, permissionCest, permissionCfop, permissionCnaeService, permissionCstCofins, permissionCstCsosn,
    permissionCstIpi, permissionCstPis, permissionFiscalClass, permissionNcm, permissionNfModel, permissionStandardTax, permissionTypeTaxOperation])

  return(
    <div style={{marginTop: 10}}>
      <h3>Fiscal</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Aliquot */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Alíquota</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAliquot"
                checked={(aliquotF === 1) ? true : false}
                onChange={(aliquotF === 1) ? handleAliquot : handleAliquotC} />
            </div>
          </div>
        </div>
      </div>

      {/* ANP */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>ANP</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlAnp"
                checked={(anpF === 1) ? true : false}
                onChange={(anpF === 1) ? handleAnp : handleAnpC} />
            </div>
          </div>
        </div>
      </div>

      {/* CEST */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CEST</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCest"
                checked={(cestF === 1) ? true : false}
                onChange={(cestF === 1) ? handleCest : handleCestC} />
            </div>
          </div>
        </div>
      </div>

      {/* NCM */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>NCM</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlNcm"
                checked={(ncmF === 1) ? true : false}
                onChange={(ncmF === 1) ? handleNcm : handleNcmC} />
            </div>
          </div>
        </div>
      </div>

      {/* CST IPI */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CST IPI</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCstIpi"
                checked={(cstIpiF === 1) ? true : false}
                onChange={(cstIpiF === 1) ? handleCstIpi : handleCstIpiC} />
            </div>
          </div>
        </div>
      </div>

      {/* CST PIS */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CST PIS</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCstPis"
                checked={(cstPisF === 1) ? true : false}
                onChange={(cstPisF === 1) ? handleCstPis : handleCstPisC} />
            </div>
          </div>
        </div>
      </div>

      {/* CST COFINS */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CST COFINS</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCstCofins"
                checked={(cstCofinsF === 1) ? true : false}
                onChange={(cstCofinsF === 1) ? handleCstCofins : handleCstCofinsC} />
            </div>
          </div>
        </div>
      </div>

      {/* CFOP */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CFOP</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCfop"
                checked={(cfopF === 1) ? true : false}
                onChange={(cfopF === 1) ? handleCfop : handleCfopC} />
            </div>
          </div>
        </div>
      </div>

      {/* CST CSOSN */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CST/CSOSN</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCstCsosn"
                checked={(cstCsosnF === 1) ? true : false}
                onChange={(cstCsosnF === 1) ? handleCstCsosn : handleCstCsosnC} />
            </div>
          </div>
        </div>
      </div>

      {/* Fiscal Class */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Classe Fiscal</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlFiscalClass"
                checked={(fiscalClassF === 1) ? true : false}
                onChange={(fiscalClassF === 1) ? handleFiscalClass : handleFiscalClassC} />
            </div>
          </div>
        </div>
      </div>

      {/* Type Tax Operation */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Tipo Operação</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlTypeTaxOperation"
                checked={(typeTaxOperationF === 1) ? true : false}
                onChange={(typeTaxOperationF === 1) ? handleTypeTaxOperation : handleTypeTaxOperationC} />
            </div>
          </div>
        </div>
      </div>

      {/* NF Model */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Modelo NF</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlNfModel"
                checked={(nfModelF === 1) ? true : false}
                onChange={(nfModelF === 1) ? handleNfModel : handleNfModelC} />
            </div>
          </div>
        </div>
      </div>

      {/* CNAE Service */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>CNAE Serviço</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlCnaeService"
                checked={(cnaeServiceF === 1) ? true : false}
                onChange={(cnaeServiceF === 1) ? handleCnaeService : handleCnaeServiceC} />
            </div>
          </div>
        </div>
      </div>

      {/* Standard Tax */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Imposto Padrão</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlStandardTax"
                checked={(standardTaxF === 1) ? true : false}
                onChange={(standardTaxF === 1) ? handleStandardTax : handleStandardTaxC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlTax