import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Titulo from "../../Components/Titulo"
import SearchAnp from "../Anp/Search"
import SearchBox from "../Box/Search"
import SearchDepartment from "../Department/Search"
import SearchFisClass from "../FisClass/Search"
import SearchManufacturer from "../Manufacturer/Search"
import SearchNcm from "../Ncm/Search"
import SearchProduct from "./Search"
import SearchProductAlterationClass from "../ProductAlterationClass/Search"
import SearchProductAlterationNcm from "../ProductAlterationNcm/Search"
import SearchProductAlterationPrice from "../ProductAlterationPrice/Search"
import SearchProductAlterationSituation from "../ProductAlterationSituation/Search"
import SearchProductCompanyAddition from "../ProductCompanyAddition/Search"
import SearchProductCompanyComposition from "../ProductCompanyComposition/Search"
import SearchProductCompanyOffer from "../ProductCompanyOffer/Search"
import SearchProductCompanyNve from "../ProductCompanyNve/Search"
import SearchProductPack from "../ProductPack/Search"
import SearchProductSupplier from "../ProductSupplier/Search"
import SearchServiceItemCnae from "../ServiceItemCnae/Search"
import SearchUnit from "../Unit/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Product = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  ////
  // Product // Product
  ////
  const [code, setCode] = useState("")
  const [description, setDescription] = useState("")
  const [shortDescription, setShortDescription] = useState("")

  const [commercialBarCode, setCommercialBarCode] = useState("")
  const [taxableBarCode, setTaxableBarCode] = useState("")

  const [commercialUnitCode, setCommercialUnitCode] = useState("")
  const [commercialUnitAcronym, setCommercialUnitAcronym] = useState("")
  const [commercialUnitDescription, setCommercialUnitDescription] = useState("")
  const [commercialUnitWeighable, setCommercialUnitWeighable] = useState(0)

  const [taxableUnitCode, setTaxableUnitCode] = useState("")
  const [taxableUnitAcronym, setTaxableUnitAcronym] = useState("")
  const [taxableUnitDescription, setTaxableUnitDescription] = useState("")
  const [taxableUnitWeighable, setTaxableUnitWeighable] = useState(0)

  const [departmentCode, setDepartmentCode] = useState("")
  const [departmentDescription, setDepartmentDescription] = useState("")

  const [manufacturerCode, setManufacturerCode] = useState("")
  const [manufacturerName, setManufacturerName] = useState("")

  const [unitWeight, setUnitWeight] = useState(0)
  const [reference, setReference] = useState("")
  const [validity, setValidity] = useState(0)
  const [emitGondola, setEmitGondola] = useState(0)
  const [preparationTime, setPreparationTime] = useState("00:00")
  const [totalFraction, setTotalFraction] = useState(0)
  const [allowsObservation, setAllowsObservation] = useState(0)
  const [brand, setBrand] = useState("")
  const [type, setType] = useState(0)
  const [photo, setPhoto] = useState("")
  const [observation, setObservation] = useState("")
  const [relevantScale, setRelevantScale] = useState(0)
  const [benefitCode, setBenefitCode] = useState("")
  const [anvisaCode, setAnvisaCode] = useState("")
  const [anvisaExemptionReason, setAnvisaExemptionReason] = useState("")
  ////

  ////
  // Product Company // Produto Filial
  ////
  const [codeFil, setCodeFil] = useState("")
  const [costPrice, setCostPrice] = useState(0)
  const [biddingCostPrice, setBiddingCostPrice] = useState(0)
  const [margin, setMargin] = useState(0)
  const [salePrice, setSalePrice] = useState(0)
  const [maximunSalePrice, setMaximunSalePrice] = useState(0)
  const [maximumIncreasePercentage, setMaximumIncreasePercentage] = useState(0)
  const [maximumDiscountPercentage, setMaximumDiscountPercentage] = useState(0)
  const [comissionPercentage, setComissionPercentage] = useState(0)
  const [minimumStock, setMinimumStock] = useState(0)
  const [purchaseQuantity, setPurchaseQuantity] = useState(0)

  const [ncmCode, setNcmCode] = useState("")
  const [ncmDescription, setNcmDescription] = useState("")

  const [classCode, setClassCode] = useState("")
  const [classDescription, setClassDescription] = useState("")

  const [boxCode, setBoxCode] = useState("")
  const [boxDescription, setBoxDescription] = useState("")

  const [anpCode, setAnpCode] = useState("")
  const [anpDescription, setAnpDescription] = useState("")
  const [glpPercentage, setGlpPercentage] = useState(0)
  const [gnvPercentage, setGnvPercentage] = useState(0)
  const [partPercentage, setPartPercentage] = useState(0)

  const [baseRetainedSt, setBaseRetainedSt] = useState(0)
  const [rateRetainedSt, setRateRetainedSt] = useState(0)
  const [rateFcpRetainedSt, setRateFcpRetainedSt] = useState(0)
  const [original, setOriginal] = useState(0)
  const [mandatoryLot, setMandatoryLot] = useState(0)
  const [lotSeries, setLotSeries] = useState(0)

  const [serviceItemCnaeCode, setServiceItemCnaeCode] = useState("")
  const [serviceItemCnaeDescription, setServiceItemCnaeDescription] = useState("")

  const [situation, setSituation] = useState(0)
  const [sge, setSge] = useState("")

  ////

  const [jProduct, setJProduct] = useState({})
  const [jProductCompany, setJProductCompany] = useState({})

  const [showMenu, setShowMenu] = useState(true)  
  const [showSearch, setShowSearch] = useState(true)
  const [showCommercialUnit, setShowCommercialUnit] = useState(false)
  const [showTaxableUnit, setShowTaxableUnit] = useState(false)
  const [showDepartment, setShowDepartment] = useState(false)
  const [showManufacturer, setShowManufacturer] = useState(false)
  const [showNcm, setShowNcm] = useState(false)
  const [showClass, setShowClass] = useState(false)
  const [showBox, setShowBox] = useState(false)
  const [showAnp, setShowAnp] = useState(false)
  const [showServiceItemCnae, setShowServiceItemCnae] = useState(false) 

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocu, setUpdateFocu] = useState(0)

  useEffect(() => {
    if (code === "") {
      ////
      // Product // Produto
      ////
      setDescription("")
      setShortDescription("")
      setCommercialBarCode("")
      setTaxableBarCode("")

      setCommercialUnitCode("")
      setCommercialUnitAcronym("")
      setCommercialUnitDescription("")
      setCommercialUnitWeighable(0)

      setTaxableUnitCode("")
      setTaxableUnitAcronym("")
      setTaxableUnitDescription("")
      setTaxableUnitWeighable(0)

      setDepartmentCode("")
      setDepartmentDescription("")

      setManufacturerCode("")
      setManufacturerName("")

      setUnitWeight(0)
      setReference("")
      setValidity(0)
      setEmitGondola(0)
      setPreparationTime("00:00")
      setTotalFraction(0)
      setAllowsObservation(0)
      setBrand("")
      setType(0)
      setPhoto("")
      setObservation("")
      setRelevantScale(0)
      setBenefitCode("")
      setAnvisaCode("")
      setAnvisaExemptionReason("")
      ////

      ////
      // Product Company // Produto Filial
      ////
      setCodeFil("")
      setCostPrice(0)
      setBiddingCostPrice(0)
      setMargin(0)
      setSalePrice(0)
      setMaximunSalePrice(0)
      setMaximumIncreasePercentage(0)
      setMaximumDiscountPercentage(0)
      setComissionPercentage(0)
      setMinimumStock(0)
      setPurchaseQuantity(0)

      setNcmCode("")
      setNcmDescription("")

      setClassCode("")
      setClassDescription("")

      setBoxCode("")
      setBoxDescription("")

      setAnpCode("")
      setAnpDescription("")
      setGlpPercentage(0)
      setGnvPercentage(0)
      setPartPercentage(0)

      setBaseRetainedSt(0)
      setRateRetainedSt(0)
      setRateFcpRetainedSt(0)
      setOriginal(0)
      setMandatoryLot(0)
      setLotSeries(0)

      setServiceItemCnaeCode("")
      setServiceItemCnaeDescription("")

      setSituation(0)
      setSge("")
      ////
    }
    else {
      ////
      // Product // Produto
      ////
      setDescription(jProduct.DESCRIPTION)
      setShortDescription(jProduct.SHORT_DESCRIPTION)
      setCommercialBarCode(jProduct.COMMERCIAL_BARCODE)
      setTaxableBarCode(jProduct.TAXABLE_BARCODE)

      setCommercialUnitCode(jProduct.COMMERCIAL_UNIT.ID)
      setCommercialUnitAcronym(jProduct.COMMERCIAL_UNIT.ACRONYM)
      setCommercialUnitDescription(jProduct.COMMERCIAL_UNIT.DESCRIPTION)
      setCommercialUnitWeighable(jProduct.COMMERCIAL_UNIT.WEIGHABLE)

      setTaxableUnitCode(jProduct.TAXABLE_UNIT.ID)
      setTaxableUnitAcronym(jProduct.TAXABLE_UNIT.ACRONYM)
      setTaxableUnitDescription(jProduct.TAXABLE_UNIT.DESCRIPTION)
      setTaxableUnitWeighable(jProduct.TAXABLE_UNIT.WEIGHABLE)

      setDepartmentCode(jProduct.DEPARTMENT.ID)
      setDepartmentDescription(jProduct.DEPARTMENT.DESCRIPTION)

      if ((jProduct.MANUFACTURER !== null) && (jProduct.MANUFACTURER !== undefined)) {
        if (jProduct.MANUFACTURER.DESCRICAO !== "null") {
          setManufacturerCode(jProduct.MANUFACTURER.ID)
          setManufacturerName(jProduct.MANUFACTURER.NAME)
        }
        else{
          setManufacturerCode("")
          setManufacturerName("")
        }
      }
      else{
        setManufacturerCode("")
        setManufacturerName("")
      }

      setUnitWeight(formatNumeric(jProduct.UNIT_WEIGHT, 3))

      if ((jProduct.REFERENCE !== null) && (jProduct.REFERENCE !== undefined)) setReference(jProduct.REFERENCE)
      else setReference("")

      setValidity(jProduct.VALIDITY)
      setEmitGondola(jProduct.EMIT_GONDOLA)
      setPreparationTime(jProduct.PREPARATION_TIME)
      setTotalFraction(jProduct.TOTAL_FRACTION)
      setAllowsObservation(jProduct.ALLOWS_OBSERVATION)

      if ((jProduct.BRAND !== null) && (jProduct.BRAND !== undefined)) setBrand(jProduct.BRAND)
      else setBrand("")

      setType(jProduct.TYPE)

      if ((jProduct.PHOTO !== null) && (jProduct.PHOTO !== undefined)) setPhoto(jProduct.PHOTO); else setPhoto("")

      if ((jProduct.OBSERVATION !== null) && (jProduct.OBSERVATION !== undefined)) 
        setObservation(jProduct.OBSERVATION); else setObservation("")

      setRelevantScale(jProduct.RELEVANT_SCALE)

      if ((jProduct.BENEFIT_CODE !== null) && (jProduct.BENEFIT_CODE !== undefined))
        setBenefitCode(jProduct.BENEFIT_CODE); else setBenefitCode("")
      if ((jProduct.ANVISA_CODE !== null) && (jProduct.ANVISA_CODE !== undefined))
        setAnvisaCode(jProduct.ANVISA_CODE); else setAnvisaCode("")      
      if ((jProduct.ANVISA_EXEMPTION_REASON !== null) && (jProduct.ANVISA_EXEMPTION_REASON !== undefined))
        setAnvisaExemptionReason(jProduct.ANVISA_EXEMPTION_REASON); else setAnvisaExemptionReason("")

      ////

      ////
      // Product Company // Produto Filial
      ////
      if ((jProductCompany !== null) && (jProductCompany !== undefined))
      {
        if ((jProductCompany.ID !== null) && (jProductCompany.ID !== undefined)){
          if (jProductCompany.ID === 0) setCodeFil("")
          else setCodeFil(jProductCompany.ID)
        }

        if ((jProductCompany.COST_PRICE !== null) && (jProductCompany.COST_PRICE !== undefined)) setCostPrice(formatNumeric(jProductCompany.COST_PRICE,2))

        if ((jProductCompany.BIDDING_COST_PRICE !== null) && (jProductCompany.BIDDING_COST_PRICE !== undefined))
          setBiddingCostPrice(formatNumeric(jProductCompany.BIDDING_COST_PRICE,2))

        if ((jProductCompany.MARGIN !== null) && (jProductCompany.MARGIN !== undefined)) setMargin(formatNumeric(jProductCompany.MARGIN,2))
        if ((jProductCompany.SALE_PRICE !== null) && (jProductCompany.SALE_PRICE !== undefined)) setSalePrice(formatNumeric(jProductCompany.SALE_PRICE,2))

        if ((jProductCompany.MAXIMUN_SALE_PRICE !== null) && (jProductCompany.MAXIMUN_SALE_PRICE !== undefined)) 
          setMaximunSalePrice(formatNumeric(jProductCompany.MAXIMUN_SALE_PRICE,2))

        if ((jProductCompany.MAXIMUM_INCREASE_PERCENTAGE !== null) && (jProductCompany.MAXIMUM_INCREASE_PERCENTAGE !== undefined))
          setMaximumIncreasePercentage(formatNumeric(jProductCompany.MAXIMUM_INCREASE_PERCENTAGE,2))

        if ((jProductCompany.MAXIMUM_DISCOUNT_PERCENTAGE !== null) && (jProductCompany.MAXIMUM_DISCOUNT_PERCENTAGE !== undefined))
            setMaximumDiscountPercentage(formatNumeric(jProductCompany.MAXIMUM_DISCOUNT_PERCENTAGE,2))

        if ((jProductCompany.COMISSION_PERCENTAGE !== null) && (jProductCompany.COMISSION_PERCENTAGE !== undefined))
            setComissionPercentage(formatNumeric(jProductCompany.COMISSION_PERCENTAGE,2))

        if ((jProductCompany.MINIMUM_STOCK !== null) && (jProductCompany.MINIMUM_STOCK !== undefined)) 
          setMinimumStock(formatNumeric(jProductCompany.MINIMUM_STOCK,2))

        if ((jProductCompany.PURCHASE_QUANTITY !== null) && (jProductCompany.PURCHASE_QUANTITY !== undefined)) 
          setPurchaseQuantity(formatNumeric(jProductCompany.PURCHASE_QUANTITY,3))

        if ((jProductCompany.NCM !== null) && (jProductCompany.NCM !== undefined)){
          setNcmCode(jProductCompany.NCM.CODE)
          setNcmDescription(jProductCompany.NCM.DESCRIPTION)
        }

        if ((jProductCompany.FIS_CLASS !== null) && (jProductCompany.FIS_CLASS !== undefined)){
          setClassCode(jProductCompany.FIS_CLASS.ID)
          setClassDescription(jProductCompany.FIS_CLASS.DESCRIPTION)
        }

        if ((jProductCompany.BOX !== null) && (jProductCompany.BOX !== undefined)){
          setBoxCode(jProductCompany.BOX.ID)
          setBoxDescription(jProductCompany.BOX.DESCRIPTION)
        }
        else{
          setBoxCode("")
          setBoxDescription("")
        }

        if ((jProductCompany.ANP !== null) && (jProductCompany.ANP !== undefined)){
          setAnpCode(jProductCompany.ANP.CODE)
          setAnpDescription(jProductCompany.ANP.DESCRIPTION)
        }
        else{
          setAnpCode("")
          setAnpDescription("")
        }

        if ((jProductCompany.GLP_PERCENTAGE !== null) && (jProductCompany.GLP_PERCENTAGE !== undefined))
          setGlpPercentage(formatNumeric(jProductCompany.GLP_PERCENTAGE,2))

        if ((jProductCompany.GNV_PERCENTAGE !== null) && (jProductCompany.GNV_PERCENTAGE !== undefined))
          setGnvPercentage(formatNumeric(jProduct.PRODUCT_COMPANY.GNV_PERCENTAGE,2))

        if ((jProductCompany.PART_PERCENTAGE !== null) && (jProductCompany.PART_PERCENTAGE !== undefined))
          setPartPercentage(formatNumeric(jProduct.PRODUCT_COMPANY.PART_PERCENTAGE,2))

        if ((jProductCompany.BASE_RETAINED_ST !== null) && (jProductCompany.BASE_RETAINED_ST !== undefined))
          setBaseRetainedSt(formatNumeric(jProductCompany.BASE_RETAINED_ST, 2))

        if ((jProductCompany.RATE_RETAINED_ST !== null) && (jProductCompany.RATE_RETAINED_ST !== undefined))
          setRateRetainedSt(formatNumeric(jProduct.PRODUCT_COMPANY.RATE_RETAINED_ST, 2))

        if ((jProductCompany.RATE_FCP_RETAINED_ST !== null) && (jProductCompany.RATE_FCP_RETAINED_ST !== undefined))
          setRateFcpRetainedSt(formatNumeric(jProductCompany.RATE_FCP_RETAINED_ST, 2))

        if ((jProductCompany.ORIGINAL !== null) && (jProductCompany.ORIGINAL !== undefined)) setOriginal(jProductCompany.ORIGINAL)
        if ((jProductCompany.MANDATORY_LOT !== null) && (jProductCompany.MANDATORY_LOT !== undefined)) setMandatoryLot(jProductCompany.MANDATORY_LOT)
        if ((jProductCompany.LOT_SERIES !== null) && (jProductCompany.LOT_SERIES !== undefined)) setLotSeries(jProductCompany.LOT_SERIES)

        if ((jProductCompany.SERVICE_ITEM_CNAE !== null) && (jProductCompany.SERVICE_ITEM_CNAE !== undefined)){
          setServiceItemCnaeCode(jProductCompany.SERVICE_ITEM_CNAE.CODE)
          setServiceItemCnaeDescription(jProductCompany.SERVICE_ITEM_CNAE.DESCRIPTION)
        }
        else{
          setServiceItemCnaeCode("")
          setServiceItemCnaeDescription("")
        }
        
        if ((jProductCompany.SITUATION !== null) && (jProductCompany.SITUATION !== undefined)) setSituation(jProductCompany.SITUATION)
        if ((jProductCompany.SGE !== null) && (jProductCompany.SGE !== undefined)) setSge(jProductCompany.SGE)
        ////
      }
      else{
        setCodeFil("")
        setCostPrice(0)
        setBiddingCostPrice(0)
        setMargin(0)
        setSalePrice(0)
        setMaximunSalePrice(0)
        setMaximumIncreasePercentage(0)
        setMaximumDiscountPercentage(0)
        setComissionPercentage(0)
        setMinimumStock(0)
        setPurchaseQuantity(0)

        setNcmCode("")
        setNcmDescription("")

        setClassCode("")
        setClassDescription("")

        setBoxCode("")
        setBoxDescription("")

        setAnpCode("")
        setAnpDescription("")
        setGlpPercentage(0)
        setGnvPercentage(0)
        setPartPercentage(0)

        setBaseRetainedSt(0)
        setRateRetainedSt(0)
        setRateFcpRetainedSt(0)
        setOriginal(0)
        setMandatoryLot(0)
        setLotSeries(0)

        setServiceItemCnaeCode("")
        setServiceItemCnaeDescription("")

        setSituation(0)
        setSge("")
      }
    }

    document.getElementById("productDescription") && document.getElementById("productDescription").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, code, jProduct, updateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === ""){
      ////
      // Product // Produto
      ////
      setDescription("")
      setShortDescription("")
      setCommercialBarCode("")
      setTaxableBarCode("")

      setCommercialUnitCode("")
      setCommercialUnitAcronym("")
      setCommercialUnitDescription("")
      setCommercialUnitWeighable("")

      setTaxableUnitCode("")
      setTaxableUnitAcronym("")
      setTaxableUnitDescription("")
      setTaxableUnitWeighable("")

      setDepartmentCode("")
      setDepartmentDescription("")

      setManufacturerCode("")
      setManufacturerName("")

      setUnitWeight(0)
      setReference("")
      setValidity(0)
      setEmitGondola(0)
      setPreparationTime("00:00")
      setTotalFraction(0)
      setAllowsObservation(0)
      setBrand("")
      setType(0)
      setPhoto("")
      setObservation("")
      setRelevantScale(0)
      setBenefitCode("")
      setAnvisaCode("")
      setAnvisaExemptionReason("")
      ////

      ////
      // Product Company // Produto Filial
      ////
      setCodeFil("")
      setCostPrice(0)
      setBiddingCostPrice(0)
      setMargin(0)
      setSalePrice(0)
      setMaximunSalePrice(0)
      setMaximumIncreasePercentage(0)
      setMaximumDiscountPercentage(0)
      setComissionPercentage(0)
      setMinimumStock(0)
      setPurchaseQuantity(0)

      setNcmCode("")
      setNcmDescription("")

      setClassCode("")
      setClassDescription("")

      setBoxCode("")
      setBoxDescription("")

      setAnpCode("")
      setAnpDescription("")
      setGlpPercentage(0)
      setGnvPercentage(0)
      setPartPercentage(0)

      setBaseRetainedSt(0)
      setRateRetainedSt(0)
      setRateFcpRetainedSt(0)
      setOriginal(0)
      setMandatoryLot(0)
      setLotSeries(0)

      setServiceItemCnaeCode("")
      setServiceItemCnaeDescription("")

      setSituation(0)
      setSge("")
      ////
    } 
    else {
      setCodeFil("")
      setCode("")
    }    

    setJProduct({})
    setJProductCompany({})

    setUpdateFocu(updateFocu + 1)
  }

  const cleanAnp = () => {
    setAnpCode("")
    setAnpDescription("")
    setShowAnp(false);
  }

  const cleanBox = () => {
    setBoxCode("")
    setBoxDescription("")
    setShowBox(false);
  }

  const cleanClass = () => {
    setClassCode("")
    setClassDescription("")
    setShowClass(false);
  }

  const cleanCommercialUnit = () => {
    setCommercialUnitCode("")
    setCommercialUnitAcronym("")
    setCommercialUnitDescription("")
    setCommercialUnitWeighable(0)
    setShowCommercialUnit(false)
  }

  const cleanDepartment = () => {
    setDepartmentCode("")
    setDepartmentDescription("")
    setShowDepartment(false);
  }

  const cleanManufacturer = () => {
    setManufacturerCode("")
    setManufacturerName("")
    setShowManufacturer(false)
  }

  const cleanNcm = () => {
    setNcmCode("")
    setNcmDescription("")
    setShowNcm(false);
  }

  const cleanServiceItemCnae = () => {
    setServiceItemCnaeCode("")
    setServiceItemCnaeDescription("")
    setShowServiceItemCnae(false)
  }

  const cleanTaxableUnit = () => {
    setTaxableUnitCode("")
    setTaxableUnitAcronym("")
    setTaxableUnitDescription("")
    setTaxableUnitWeighable(0)
    setShowTaxableUnit(false)
  }

  const enableAnp = () => {
    setShowAnp(!showAnp)
  }

  const enableBox = () => {
    setShowBox(!showBox)
  }

  const enableClass = () => {
    setShowClass(!showClass)
  }

  const enableCommercialUnit = () => {
    setShowCommercialUnit(!showCommercialUnit)
  }  

  const enableDepartment = () => {
    setShowDepartment(!showDepartment)
  }

  const enableManufacturer = () => {
    setShowManufacturer(!showManufacturer)
  }

  const enableNcm = () => {
    setShowNcm(!showNcm)
  }

  const enableServiceItemCnae = () => {
    setShowServiceItemCnae(!showServiceItemCnae)
  }

  const enableTaxableUnit = () => {
    setShowTaxableUnit(!showTaxableUnit)
  }

  const fillAnp = (anp) => {
    setAnpCode(anp.CODE)
    setAnpDescription(anp.DESCRIPTION)
    setShowAnp(false);
  }

  const fillBox = (box) => {
    setBoxCode(box.ID)
    setBoxDescription(box.DESCRIPTION)
    setShowBox(false);
  }

  const fillClass = (clas) => {
    setClassCode(clas.ID)
    setClassDescription(clas.DESCRIPTION)
    setShowClass(false);
  }

  const fillCommercialUnit = (unit) => {
    setCommercialUnitCode(unit.ID)
    setCommercialUnitAcronym(unit.ACRONYM)
    setCommercialUnitDescription(unit.DESCRIPTION)
    setCommercialUnitWeighable(unit.WEIGHABLE)
    setShowCommercialUnit(false);
  }  

  const fillDepartment = (department) => {
    setDepartmentCode(department.ID)
    setDepartmentDescription(department.DESCRIPTION) 
    setShowDepartment(false);
  }

  const fillManufacturer = (manufacturer) => {
    setManufacturerCode(manufacturer.ID)
    setManufacturerName(manufacturer.NAME) 
    setShowManufacturer(false);
  }

  const fillNcm = (ncm) => {
    setNcmCode(ncm.CODE)
    setNcmDescription(ncm.DESCRIPTION) 
    setShowNcm(false);
  }    

  const fillServiceItemCnae = (ncm) => {
    setServiceItemCnaeCode(ncm.CODE)
    setServiceItemCnaeDescription(ncm.DESCRIPTION) 
    setShowServiceItemCnae(false);
  }

  const fillTaxableUnit = (unit) => {
    setTaxableUnitCode(unit.ID)
    setTaxableUnitAcronym(unit.ACRONYM)
    setTaxableUnitDescription(unit.DESCRIPTION)
    setTaxableUnitWeighable(unit.WEIGHABLE)
    setShowTaxableUnit(false);
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleBaseRetainedSt = (event) => {
    setBaseRetainedSt(formatNumeric(event.target.value, 2))
  }

  const handleCode = (event) => {
    let codeN = String(event.target.value).replaceAll("_","")
    if (codeN !== "") setCode(event.target.value)
    else setCode("")
  }

  const handleCommercialBarCode = (event) => {
    let commercialBarCodeN = String(event.target.value).replaceAll("_","")
    if (commercialBarCodeN !== "") setCommercialBarCode(event.target.value)
    else setCommercialBarCode("")
  }

  const handleComissionPercentage = (event) => {
    setComissionPercentage(formatNumeric(event.target.value, 2))
  }

  const handleCostPrice = (event) => {
    let pc = formatNumeric(event.target.value, 2)

    setCostPrice(pc)

    try { setMargin(formatNumeric((((salePrice  / costPrice) * 100) - 100), 2)) }
    catch { setMargin(formatNumeric(0,2)) }
  }

  const handleFile = (e) => {
    const _file = e.target.files[0];
    uploadPhoto(_file)
  }

  const handleGlpPercentage = (event) => {
    setGlpPercentage(formatNumeric(event.target.value, 2))
  }

  const handleGnvPercentage = (event) => {
    setGnvPercentage(formatNumeric(event.target.value, 2))
  }

  const handleMargin = (event) => {
    setMargin(formatNumeric(event.target.value, 2))
  }

  const handleMaximumDiscountPercentage = (event) => {
    setMaximumDiscountPercentage(formatNumeric(event.target.value, 2))
  }

  const handleMaximumIncreasePercentage = (event) => {
    setMaximumIncreasePercentage(formatNumeric(event.target.value, 2))
  }  

  const handleMaximunSalePrice = (event) => {
    setMaximunSalePrice(formatNumeric(event.target.value, 2))
  }  

  const handleMinimumStock = (event) => {
    setMinimumStock(formatNumeric(event.target.value, 3))
  }  

  const handlePartPercentage = (event) => {
    setPartPercentage(formatNumeric(event.target.value, 2))
  }

  const handlePurchaseQuantity = (event) => {
    setPurchaseQuantity(formatNumeric(event.target.value,2))
  }

  const handleRateRetainedSt = (event) => {
    setRateRetainedSt(formatNumeric(event.target.value, 2))
  }

  const handleRateFcpRetainedSt = (event) => {
    setRateFcpRetainedSt(formatNumeric(event.target.value, 2))
  }

  const handleSalePrice = (event) => {
    let pc = formatNumeric(event.target.value, 2)
    
    setSalePrice(pc)

    try { setMargin(formatNumeric((((salePrice  / costPrice) * 100) - 100), 2)) }
    catch { setMargin(formatNumeric(0,2)) }
  }

  const handleTaxableBarCode = (event) => {
    let taxableBarCodeN = String(event.target.value).replaceAll("_","")
    if (taxableBarCodeN !== "") setTaxableBarCode(event.target.value)
    else setTaxableBarCode("")
  }

  const handleTotalFraction = (event) => {
    let totalFractionN = String(event.target.value).replaceAll("_","")
    if (totalFractionN !== "") setTotalFraction(event.target.value)
    else setTotalFraction("")
  }

  const handleUnitWeight = (event) => {
    setUnitWeight(formatNumeric(event.target.value, 3))
  }

  const handleValidity = (event) => {
    let validityN = String(event.target.value).replaceAll("_","")
    if (validityN !== "") setValidity(event.target.value)
    else setValidity("")
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []
    let jsonProduct_New = "{ "
    let jsonProductCompany_New = "{ "

    ////
    // Product // Produto
    ////
    let codeN = ""

    if (((code !== undefined) && (code !== null)) && (code !== "")){
      codeN = String(code).replaceAll("_","")
      if (codeN !== "") jsonProduct_New += " \"ID\": "+ codeN
    }
    
    if (((description !== undefined) && (description !== null)) && (description !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"DESCRIPTION\": \""+ description + "\""
    }

    if (((shortDescription !== undefined) && (shortDescription !== null)) && (shortDescription !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"SHORT_DESCRIPTION\": \""+ shortDescription + "\""
    }
    
    if (((commercialBarCode !== undefined) && (commercialBarCode !== null)) && (commercialBarCode !== "")){
      let commercialBarCodeN = String(commercialBarCode).replaceAll("_","")
      if (commercialBarCodeN !== ""){
        if (jsonProduct_New !== "{ ") jsonProduct_New += ","
        jsonProduct_New += " \"COMMERCIAL_BARCODE\": \""+ commercialBarCodeN + "\""
      }
    }

    if (((taxableBarCode !== undefined) && (taxableBarCode !== null)) && (taxableBarCode !== "")){
      let taxableBarCodeN = String(taxableBarCode).replaceAll("_","")
      if (taxableBarCodeN !== ""){
        if (jsonProduct_New !== "{ ") jsonProduct_New += ","
        jsonProduct_New += " \"TAXABLE_BARCODE\": \""+ taxableBarCodeN + "\""
      }
    }

    if (((commercialUnitCode !== undefined) && (commercialUnitCode !== null)) && (commercialUnitCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"COMMERCIAL_UNIT\": { \"ID\": "+ commercialUnitCode +
      ", \"ACRONYM\": \""+ commercialUnitAcronym +"\"" +
      ", \"DESCRIPTION\": \""+ commercialUnitDescription +"\"" +
      ", \"WEIGHABLE\": "+ commercialUnitWeighable + "}"
    }

    if (((taxableUnitCode !== undefined) && (taxableUnitCode !== null)) && (taxableUnitCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"TAXABLE_UNIT\": { \"ID\": "+ taxableUnitCode +
      ", \"ACRONYM\": \""+ taxableUnitAcronym +"\"" +
      ", \"DESCRIPTION\": \""+ taxableUnitDescription +"\"" +
      ", \"WEIGHABLE\": "+ taxableUnitWeighable + "}"
    }

    if (((departmentCode !== undefined) && (departmentCode !== null)) && (departmentCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"DEPARTMENT\": { \"ID\": "+ departmentCode + ", \"DESCRIPTION\": \""+ departmentDescription +"\" }"
    }

    if (((manufacturerCode !== undefined) && (manufacturerCode !== null)) && (manufacturerCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"MANUFACTURER\": { \"ID\": "+ manufacturerCode + ", \"NAME\": \""+ manufacturerName +"\" }"
    }

    if (jsonProduct_New !== "{ ") jsonProduct_New += ","
    jsonProduct_New += " \"UNIT_WEIGHT\": "+ unitWeight

    if (((reference !== undefined) && (reference !== null)) && (reference !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"REFERENCE\": \""+ reference +"\""
    }

    if (((validity !== undefined) && (validity !== null)) && (validity !== "")){
      let validityN = String(validity).replaceAll("_","")
      if (validityN !== ""){
        if (jsonProduct_New !== "{ ") jsonProduct_New += ","
        jsonProduct_New += " \"VALIDITY\": "+ validityN
      }
    }
 
    if (jsonCompany.TYPE !== 1){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"EMIT_GONDOLA\": "+ emitGondola
    }
    else{
      setEmitGondola(2)
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"EMIT_GONDOLA\": 2"
    }

    if (((preparationTime !== undefined) && (preparationTime !== null)) && (preparationTime !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"PREPARATION_TIME\": \""+ preparationTime +"\""
    } 
    else{
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"PREPARATION_TIME\": \"00:00\""
    }

    if (((totalFraction !== undefined) && (totalFraction !== null)) && (totalFraction !== "")){
      let totalFractionN = String(totalFraction).replaceAll("_","")
      if (totalFractionN !== ""){      
        if (jsonProduct_New !== "{ ") jsonProduct_New += ","
        jsonProduct_New += " \"TOTAL_FRACTION\": "+ totalFractionN
      }
    }

    if (jsonCompany.TYPE === 2){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"ALLOWS_OBSERVATION\": "+ allowsObservation
    }
    else {
      setAllowsObservation(2)
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"ALLOWS_OBSERVATION\": 2"
    }

    if (jsonCompany.TYPE === 4)
      if (((brand !== undefined) && (brand !== null)) && (brand !== "")){
        if (jsonProduct_New !== "{ ") jsonProduct_New += ","
        jsonProduct_New += " \"BRAND\": \""+ brand +"\""
      }
    
    if (jsonProduct_New !== "{ ") jsonProduct_New += ","
    jsonProduct_New += " \"TYPE\": "+ type

    if (((photo !== undefined) && (photo !== null)) && (photo !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"PHOTO\": \""+ photo + "\""
    }

    if (((observation !== undefined) && (observation !== null)) && (observation !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"OBSERVATION\": \""+ observation + "\""
    }
    
    if (jsonProduct_New !== "{ ") jsonProduct_New += ","
    jsonProduct_New += " \"RELEVANT_SCALE\": "+ relevantScale

    if (((benefitCode !== undefined) && (benefitCode !== null)) && (benefitCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"BENEFIT_CODE\": \""+ benefitCode + "\""
    }

    if (((anvisaCode !== undefined) && (anvisaCode !== null)) && (anvisaCode !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"ANVISA_CODE\": \""+ anvisaCode + "\""
    }
    
    if (((anvisaExemptionReason !== undefined) && (anvisaExemptionReason !== null)) && (anvisaExemptionReason !== "")){
      if (jsonProduct_New !== "{ ") jsonProduct_New += ","
      jsonProduct_New += " \"ANVISA_EXEMPTION_REASON\": \""+ anvisaExemptionReason + "\""
    }

    ////

    ////
    // Product Company // Produto Filial
    ////
    jsonProductCompany_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"

    if (((codeFil !== undefined) && (codeFil !== null)) && (codeFil !== "")) jsonProductCompany_New += ", \"ID\": "+ codeFil   
      
    jsonProductCompany_New += ", \"COST_PRICE\": "+ costPrice
    jsonProductCompany_New += ", \"BIDDING_COST_PRICE\": "+ biddingCostPrice
    jsonProductCompany_New += ", \"MARGIN\": "+ margin
    jsonProductCompany_New += ", \"SALE_PRICE\": "+ salePrice
    jsonProductCompany_New += ", \"MAXIMUN_SALE_PRICE\": "+ maximunSalePrice
    jsonProductCompany_New += ", \"MAXIMUM_INCREASE_PERCENTAGE\": "+ maximumIncreasePercentage
    jsonProductCompany_New += ", \"MAXIMUM_DISCOUNT_PERCENTAGE\": "+ maximumDiscountPercentage
    jsonProductCompany_New += ", \"COMISSION_PERCENTAGE\": "+ comissionPercentage
    jsonProductCompany_New += ", \"MINIMUM_STOCK\": "+ minimumStock
    jsonProductCompany_New += ", \"PURCHASE_QUANTITY\": "+ purchaseQuantity

    if (((ncmCode !== undefined) && (ncmCode !== null)) && (ncmCode !== ""))
      jsonProductCompany_New += ", \"NCM\": { \"CODE\": \""+ ncmCode + "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ ncmDescription +"\" }"

    if (((classCode !== undefined) && (classCode !== null)) && (classCode !== ""))
      jsonProductCompany_New += ", \"FIS_CLASS\": { \"ID\": "+ classCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ classDescription +"\" }"

    if (((boxCode !== undefined) && (boxCode !== null)) && (boxCode !== ""))
      jsonProductCompany_New += ", \"BOX\": { \"ID\": "+ boxCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ boxDescription +"\" }"

    if (((anpCode !== undefined) && (anpCode !== null)) && (anpCode !== ""))
      jsonProductCompany_New += ", \"ANP\": { \"CODE\": "+ anpCode + ", \"DESCRIPTION\": \""+ anpDescription +"\" }"

    jsonProductCompany_New += ", \"GLP_PERCENTAGE\": "+ glpPercentage
    jsonProductCompany_New += ", \"GNV_PERCENTAGE\": "+ gnvPercentage
    jsonProductCompany_New += ", \"PART_PERCENTAGE\": "+ partPercentage
    jsonProductCompany_New += ", \"BASE_RETAINED_ST\": "+ baseRetainedSt
    jsonProductCompany_New += ", \"RATE_RETAINED_ST\": "+ rateRetainedSt
    jsonProductCompany_New += ", \"RATE_FCP_RETAINED_ST\": "+ rateFcpRetainedSt

    if (jsonCompany.TYPE === 4)
    {
      jsonProductCompany_New += ", \"ORIGINAL\": "+ original
      jsonProductCompany_New += ", \"MANDATORY_LOT\": "+ mandatoryLot
      jsonProductCompany_New += ", \"LOT_SERIES\": "+ lotSeries
    }
    else
    {
      jsonProductCompany_New += ", \"ORIGINAL\": 2"
      jsonProductCompany_New += ", \"MANDATORY_LOT\": 2"
      jsonProductCompany_New += ", \"LOT_SERIES\": 1"
    }

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonProductCompany_New += ", \"SGE\": "+ sge

    if (((serviceItemCnaeCode !== undefined) && (serviceItemCnaeCode !== null)) && (serviceItemCnaeCode !== ""))
      jsonProductCompany_New += ", \"SERVICE_ITEM_CNAE\": { \"CODE\": \""+ serviceItemCnaeCode + "\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ serviceItemCnaeDescription +"\" }"

    jsonProductCompany_New += ", \"SITUATION\": "+ situation
    jsonProduct_New += ", \"PRODUCT_COMPANY\": " + jsonProductCompany_New + " } }"

    if (codeN === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonProduct_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "PRODUCT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o produto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("productDescription") && document.getElementById("productDescription").focus()
          return
        }

        const codePro = returnJson.OBJECT.ID
        const jsonProduct = returnJson.OBJECT
        jsonProductCompany_New += ", \"PRODUCT\": "+ JSON.stringify(jsonProduct) + " }"

        let bPending = true;

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jsonProductCompany_New)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("GET_ID", true)

        axios.post(BASE_URL + "PRODUCT_COMPANY/Insert", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível incluir o produto filial. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setLoading("")

            setJProduct(jsonProduct)
            setCode(codePro)

            document.getElementById("productDescription") && document.getElementById("productDescription").focus()
            return
          }

          setCodeFil(returnJson.OBJECT.ID)
          setJProductCompany(returnJson.OBJECT)
          setJProduct(jsonProduct)
          setCode(codePro)

          bPending = false

          setLoading("")
          setUpdateFocu(updateFocu + 1)
        })

        if (!bPending) setWarning("Inclusão efetuada com sucesso (" + codePro +")!")
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jProduct))
      formData.append("JSON_NEW_1", jsonProduct_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "PRODUCT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o produto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("productDescription") && document.getElementById("productDescription").focus()
          return
        }

        const codePro = returnJson.OBJECT.ID
        const jsonProduct = returnJson.OBJECT
        jsonProductCompany_New += ", \"PRODUCT\": "+ JSON.stringify(jsonProduct) + " }"

        let bPending = true;

        if (((codeFil === undefined) || (codeFil === null)) || (codeFil === "")){
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_NEW_1", jsonProductCompany_New)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)
          formData.append("GET_ID", true)

          axios.post(BASE_URL + "PRODUCT_COMPANY/Insert", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível incluir o produto filial. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setError(returnJson.ERROR)
              setLoading("")

              setJProduct(jsonProduct)
              setCode(codePro)

              document.getElementById("productDescription") && document.getElementById("productDescription").focus()
              return
            }

            setCodeFil(returnJson.OBJECT.ID)
            setJProductCompany(returnJson.OBJECT)
            setJProduct(jsonProduct)
            setCode(codePro)

            bPending = false

            setLoading("")
            setUpdateFocu(updateFocu + 1)
          })

          if (!bPending) setWarning("Alteração efetuada com sucesso (" + codePro +")!")
        }
        else{
          formData = new FormData()
          formData.append("DATABASE_TYPE", databaseType)
          formData.append("DATABASE_PATH", databasePath)
          formData.append("JSON_OLD", JSON.stringify(jProductCompany))
          formData.append("JSON_NEW_1", jsonProductCompany_New)
          formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
          formData.append("SYSTEM", system)

          axios.put(BASE_URL + "PRODUCT_COMPANY/Update", formData)
          .then((response) => { returnJson = JSON.parse(response.data) })
          .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
            ", \"ERROR\": \"Não foi possível alterar o produto filial. ("+ response +")\"}") })
          .finally(() => {
            if (returnJson.SUCCESS === false){
              setError(returnJson.ERROR)
              setLoading("")

              setJProduct(jsonProduct)
              setCode(codePro)

              document.getElementById("productDescription") && document.getElementById("productDescription").focus()
              return
            }

            setCodeFil(returnJson.OBJECT.ID)
            setJProductCompany(returnJson.OBJECT)
            setJProduct(jsonProduct)
            setCode(codePro)

            bPending = false

            setLoading("")
            setUpdateFocu(updateFocu + 1)
          })

          if (!bPending) setWarning("Alteração efetuada com sucesso (" + codePro +")!")
        }
      })
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()
    
    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)   
    setJProductCompany(event.PRODUCT_COMPANY)
    setJProduct(event)
    setCode(event.ID)
  }
  
  const uploadPhoto = async(e) => {
    let returnJson = []

    setLoading("Aguarde efetuando o upload da foto do produto...")
    setWarning("")
    setError("")

    let jsonProduct = "{ \"ID\": "+ code +", \"PRODUCT_COMPANY\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ 
      jsonCompany.INTEGRATE_SGE + ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" } } }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", "{}")
    formData.append("JSON_NEW_1", jsonProduct)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("FILE", e);
       
    axios.post(BASE_URL + "PRODUCT/UploadPhoto", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível alterar o produto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        document.getElementById("productDescription") && document.getElementById("productDescription").focus()
        return
      }

      returnJson.WARNING ? setWarning("Foto inserida com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Foto inserida com sucesso!")

      jProduct.PHOTO = String(returnJson.OBJECT)

      setLoading("")
      setUpdateFocu(updateFocu + 1)
    })
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Produto"
            titleSearch="Pesquisa dos Produtos"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchProduct
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                type={""}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showCommercialUnit && !showTaxableUnit && !showDepartment && !showManufacturer && !showNcm && !showClass && !showBox && !showAnp &&
                        <>
                          <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            { (code !== "") &&
                              <div style={{display: "flex", justifyContent: "center"}}>
                                <Input 
                                  type="file"
                                  name="productPhoto"
                                  title=""
                                  maxLength={255}
                                  typeImage={1}
                                  disabled={false}
                                  placeholder=""
                                  grow={1}
                                  accept=".jpg,.jpeg,.png,.bmp"
                                  pathPhoto={photo}
                                  onChange={handleFile} />
                              </div>
                            }

                            { (code === "") ?
                                <div style={showMenu ?
                                    (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="productDescription"
                                      description="Descrição"
                                      value={description}
                                      maxLength={120}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDescription(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width< 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 670) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                    <Select
                                      name="productSituation"
                                      description="Situação"
                                      value={situation || 0}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                                        ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setSituation} />
                                  </div>
                                </div>
                              :
                                <div style={showMenu ?
                                    (width < 1130) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={showMenu ?
                                      (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 670) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : (width < 940) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 670) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <InputMasked
                                        name="productCode"
                                        description="Código"
                                        planceHolder=""
                                        mask="99999999999"
                                        value={code}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleCode} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 670) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180} 
                                        : (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180} 
                                      :
                                        (width < 670) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="productDescription"
                                        description="Descrição"
                                        value={description}
                                        maxLength={120}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDescription(event.target.value)} />
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1130) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 870) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                    <Select
                                      name="productSituation"
                                      description="Situação"
                                      value={situation || 0}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                                        ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setSituation} />
                                  </div>
                                </div>
                            }

                            <div style={showMenu ?
                                (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={showMenu ?
                                  (width < 1070) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                :
                                  (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="productShortDescription"
                                  description="Descrição Resumida"
                                  value={shortDescription}
                                  maxLength={20}
                                  disabled={false}
                                  require={true}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setShortDescription(event.target.value)} />
                              </div>
                              <div style={showMenu ?
                                  (width < 1070) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                :                
                                  (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="productReference"
                                  description="Referência"
                                  value={reference}
                                  maxLength={15}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setReference(event.target.value)} />
                              </div>
                            </div>

                            {/* Sem ser Licitação */}
                            { jsonCompany.TYPE !== 4 ?
                                <div style={showMenu ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}
                                      : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 200}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}}>
                                    <InputMasked
                                      name="productCommercialBarCode"
                                      description="Barras Comercial"
                                      planceHolder=""
                                      mask="99999999999999"
                                      value={commercialBarCode}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleCommercialBarCode} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}
                                      : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 200}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}}>
                                    <InputMasked
                                      name="productTaxableBarCode"
                                      description="Barras Tributável"
                                      planceHolder=""
                                      mask="99999999999999"
                                      value={taxableBarCode}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleTaxableBarCode} />
                                  </div>
                                </div>
                              :
                                <div style={showMenu ?
                                    (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1060) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}
                                        : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 200}
                                        : (width < 1060) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}
                                        : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 870) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 200}
                                        : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                                      <InputMasked
                                        name="productCommercialBarCode"
                                        description="Barras Comercial"
                                        planceHolder=""
                                        mask="99999999999999"
                                        value={commercialBarCode}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleCommercialBarCode} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180 }
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}
                                        : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 200}
                                        : (width < 1060) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180 }
                                        : (width < 870) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 200}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 200, maxWidth: 200}}>
                                      <InputMasked
                                        name="productTaxableBarCode"
                                        description="Barras Tributável"
                                        planceHolder=""
                                        mask="99999999999999"
                                        value={taxableBarCode}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleTaxableBarCode} />
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1060) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 870) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="productBrand"
                                      description="Marca"
                                      value={brand}
                                      maxLength={100}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setBrand(event.target.value)} />
                                  </div>
                                </div>
                            }

                            <div style={showMenu ?
                                (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productCostPrice"
                                    description="Preço custo"
                                    value={costPrice || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleCostPrice} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productMargin"
                                    description="Markup(%)"
                                    value={margin || ""}
                                    maxLength={13}
                                    disabled={true}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleMargin} />
                                </div>
                              </div>
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :  
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productSalePrice"
                                    description="Preço venda"
                                    value={salePrice || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleSalePrice} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productMaximunSalePrice"
                                    description="Preço venda máximo"
                                    value={maximunSalePrice || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleMaximunSalePrice} />
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 670) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={showMenu ?
                                  (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                : 
                                  (width < 670) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                <InputNumeric
                                  type="text"
                                  name="productUnitWeight"
                                  description="Peso unitário"
                                  value={unitWeight || ""}
                                  maxLength={14}
                                  disabled={false}
                                  require={false}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handleUnitWeight} />
                              </div>
                              <div style={showMenu ?
                                  (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                :
                                  (width < 670) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                <Input
                                  type="text"
                                  name="productObservation"
                                  description="Observação"
                                  value={observation}
                                  maxLength={65535}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setObservation(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <Titulo description="Unidade Comercial" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productCommercialUnitDescription"
                                      description="Descrição"
                                      value={commercialUnitDescription}
                                      maxLength={20}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCommercialUnitDescription(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                      marginTop: 10}
                                    : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchCommercialUnit"
                                      src={image_search}
                                      alt="Pesquisar"
                                      onClick={enableCommercialUnit} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanCommercialUnit"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanCommercialUnit} />
                                  </div>
                                </div>
                              </div>
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                                <Titulo description="Unidade Tributável" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productTaxableUnitDescription"
                                      description="Descrição"
                                      value={taxableUnitDescription}
                                      maxLength={20}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setTaxableUnitDescription(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchTaxableUnit"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableTaxableUnit} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanTaxableUnit"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanTaxableUnit} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <Titulo description="Departamento" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productDepartmentDescription"
                                      description="Descrição"
                                      value={departmentDescription}
                                      maxLength={150}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setDepartmentDescription(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                      marginTop: 10}
                                    : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchDepartment"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableDepartment} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanDepartment"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanDepartment} />
                                  </div>
                                </div>
                              </div>
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                                <Titulo description="Fabricante" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productManufacturerName"
                                      description="Descrição"
                                      value={manufacturerName}
                                      maxLength={20}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setManufacturerName(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchManufacturer"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableManufacturer} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanManufacturer"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanManufacturer} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                <Titulo description="Box" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productBoxDescription"
                                      description="Descrição"
                                      value={boxDescription}
                                      maxLength={150}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setBoxDescription(event.target.value)} />
                                  </div>
                                  <div style={(width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                      marginTop: 10}
                                    : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchBox"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableBox} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanBox"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={cleanBox} />
                                  </div>
                                </div>
                              </div>
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                :
                                  (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}}>
                                <Titulo description="Classe" />
                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <Input
                                      type="text"
                                      name="productClassDescription"
                                      description="Descrição"
                                      value={classDescription}
                                      maxLength={40}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setClassDescription(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchClass"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableClass} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanClass"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanClass} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                              <Titulo description="NCM" />
                            </div>

                            <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>
                              <div style={(showMenu) ?
                                  (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                :
                                  (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={showMenu ?
                                    (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="productNcmCode"
                                    description="Código"
                                    value={ncmCode}
                                    maxLength={12}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setNcmCode(event.target.value)} /> 
                                </div>
                                <div style={showMenu ?
                                    (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="productNcmDescription"
                                    description="Descrição"
                                    value={ncmDescription}
                                    maxLength={255}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeInput={2} 
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setNcmDescription(event.target.value)} />
                                </div>
                              </div>
                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                    marginTop: 10}
                                  : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                :
                                  (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                    marginTop: 10}
                                  : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                <img 
                                  style={{height: 42, width: 42}}
                                  name="productSearchNcm"
                                  src={image_search} 
                                  alt="Pesquisar" 
                                  onClick={enableNcm} />
                                <img 
                                  style={{marginLeft: 10, height: 42, width: 42}}
                                  name="productCleanNcm"
                                  src={image_clean} 
                                  alt="Limpar"
                                  onClick={cleanNcm} />
                              </div>
                            </div>

                            { parseInt(type) === 5 &&
                              <>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="ANP" />
                                </div>
                                <div style={showMenu ?
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0}
                                    : (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 0, paddingRight: 0}
                                    : (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 1480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 1210) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="productAnpDescription"
                                        description="Descrição"
                                        value={anpDescription}
                                        maxLength={80}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setAnpDescription(event.target.value)} />
                                    </div>

                                    { (width < 560) &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { showMenu && (width >= 560) && (width < 690) &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGlpPercentage"
                                              description="GLP(%)"
                                              value={glpPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGlpPercentage} />
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGnvPercentage"
                                              description="GNV(%)"
                                              value={gnvPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGnvPercentage} />
                                          </div>
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { showMenu && (width >= 690) && (width < 820) &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { showMenu && (width >= 820) && (width < 1010) &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGlpPercentage"
                                              description="GLP(%)"
                                              value={glpPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGlpPercentage} />
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGnvPercentage"
                                              description="GNV(%)"
                                              value={gnvPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGnvPercentage} />
                                          </div>
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { showMenu && (width >= 1010) && (width < 1480) &&
                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { showMenu && (width >= 1480) &&
                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                                        <div style={{display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { !showMenu && (width >= 560) && (width < 740) &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGlpPercentage"
                                              description="GLP(%)"
                                              value={glpPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false}
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGlpPercentage} />
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                            <InputNumeric
                                              type="text"
                                              name="productGnvPercentage"
                                              description="GNV(%)"
                                              value={gnvPercentage || ""}
                                              maxLength={13}
                                              disabled={false}
                                              require={true}
                                              negative={false} 
                                              image={false}
                                              typeInput={2}
                                              onSelect={setFieldSelected}
                                              onChange={handleGnvPercentage} />
                                          </div>
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { !showMenu && (width >= 740) && (width < 1210) &&
                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                    { !showMenu && (width >= 1210) &&
                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                                        <div style={{display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGlpPercentage"
                                            description="GLP(%)"
                                            value={glpPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGlpPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productGnvPercentage"
                                            description="GNV(%)"
                                            value={gnvPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleGnvPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productPartPercentage"
                                            description="Part(%)"
                                            value={partPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handlePartPercentage} />
                                        </div>
                                      </div>
                                    }

                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 930) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 660) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>

                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchAnp"
                                      src={image_search} 
                                      alt="Pesquisar"
                                      onClick={enableAnp} />

                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanAnp"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanAnp} />
                                  </div>
                                </div>
                              </>
                            }

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                              <Titulo description="Entrada" />
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>
                              
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                  : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                :  
                                  (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                <InputNumeric
                                  type="text"
                                  name="productMinimumStock"
                                  description="Estoque mínimo"
                                  value={minimumStock || ""}
                                  maxLength={13}
                                  disabled={false}
                                  require={false}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handleMinimumStock} />
                              </div>
                              <div style={showMenu ?
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                  : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                :  
                                  (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                <InputNumeric
                                  type="text"
                                  name="productPurchaseQuantity"
                                  description={ (jsonCompany.TYPE !== 4) ? "Qtde. compra" : "Emb. Ped. Compra" }
                                  value={purchaseQuantity || ""}
                                  maxLength={13}
                                  disabled={false}
                                  require={false}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handlePurchaseQuantity} />
                              </div>
                            </div>

                            { showMenu && (width < 980) &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                                <div style={
                                  (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                  <div style={
                                    (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="productBaseRetainedSt"
                                      description="Base ST retido"
                                      value={baseRetainedSt || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleBaseRetainedSt} />
                                  </div>
                                  <div style={
                                    (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="productRateRetainedSt"
                                      description="Aliq. ST retido"
                                      value={rateRetainedSt || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleRateRetainedSt} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateFcpRetainedSt"
                                    description="Aliq. FCP ST retido"
                                    value={rateFcpRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateFcpRetainedSt} />
                                </div>
                              </div>
                            }

                            { showMenu && (width >= 980) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productBaseRetainedSt"
                                    description="Base ST retido"
                                    value={baseRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleBaseRetainedSt} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateRetainedSt"
                                    description="Aliq. ST retido"
                                    value={rateRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateRetainedSt} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateFcpRetainedSt"
                                    description="Aliq. FCP ST retido"
                                    value={rateFcpRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateFcpRetainedSt} />
                                </div>
                              </div>
                            }

                            { !showMenu && (width < 700) &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                                <div style={
                                  (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                  <div style={
                                    (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="productBaseRetainedSt"
                                      description="Base ST retido"
                                      value={baseRetainedSt || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleBaseRetainedSt} />
                                  </div>
                                  <div style={
                                    (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <InputNumeric
                                      type="text"
                                      name="productRateRetainedSt"
                                      description="Aliq. ST retido"
                                      value={rateRetainedSt || ""}
                                      maxLength={13}
                                      disabled={false}
                                      require={false}
                                      negative={false} 
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={handleRateRetainedSt} />
                                  </div>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateFcpRetainedSt"
                                    description="Aliq. FCP ST retido"
                                    value={rateFcpRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateFcpRetainedSt} />
                                </div>
                              </div>
                            }

                            { !showMenu && (width >= 700) &&
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productBaseRetainedSt"
                                    description="Base ST retido"
                                    value={baseRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleBaseRetainedSt} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateRetainedSt"
                                    description="Aliq. ST retido"
                                    value={rateRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateRetainedSt} />
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                  <InputNumeric
                                    type="text"
                                    name="productRateFcpRetainedSt"
                                    description="Aliq. FCP ST retido"
                                    value={rateFcpRetainedSt || ""}
                                    maxLength={13}
                                    disabled={false}
                                    require={false}
                                    negative={false} 
                                    image={false}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={handleRateFcpRetainedSt} />
                                </div>
                              </div>
                            }

                            {/* Múltipla, Dedetização */}
                            { ((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 3)) &&
                              <div style={{marginBottom: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Saída" />
                                </div>

                                <div style={showMenu ?
                                    (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                    :
                                      (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1040) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="productMaximumDiscountPercentage"
                                        description="Desconto Max.(%)"
                                        value={maximumDiscountPercentage || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleMaximumDiscountPercentage} />
                                    </div>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="productMaximumIncreasePercentage"
                                        description="Acréscimo Max.(%)"
                                        value={maximumIncreasePercentage || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleMaximumIncreasePercentage} />
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    :  
                                      (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1040) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="productComissionPercentage"
                                        description="Comissão (%)"
                                        value={comissionPercentage || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={handleComissionPercentage} />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                      <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Geral */}
                            { jsonCompany.TYPE === 2 &&
                              <div style={{marginBottom: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Saída" />
                                </div>

                                <div style={(width < 560) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  { (width < 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 690) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 740) && (width >= 690) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 960) && (width >= 740) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 960) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 680) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 680) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={false}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { (width < 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Select
                                          name="productAllowsObservation"
                                          description="Permite OBS"
                                          value={allowsObservation || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setAllowsObservation} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productPreparationTime"
                                          mask="99:99"
                                          description="Tempo preparo"
                                          value={preparationTime}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPreparationTime(event.target.value)} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 690) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="productAllowsObservation"
                                            description="Permite OBS"
                                            value={allowsObservation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setAllowsObservation} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productPreparationTime"
                                            mask="99:99"
                                            description="Tempo preparo"
                                            value={preparationTime}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setPreparationTime(event.target.value)} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 740) && (width >= 690) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Select
                                          name="productAllowsObservation"
                                          description="Permite OBS"
                                          value={allowsObservation || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setAllowsObservation} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productPreparationTime"
                                          mask="99:99"
                                          description="Tempo preparo"
                                          value={preparationTime}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPreparationTime(event.target.value)} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 960) && (width >= 740) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="productAllowsObservation"
                                            description="Permite OBS"
                                            value={allowsObservation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setAllowsObservation} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productPreparationTime"
                                            mask="99:99"
                                            description="Tempo preparo"
                                            value={preparationTime}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setPreparationTime(event.target.value)} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 960) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Select
                                          name="productAllowsObservation"
                                          description="Permite OBS"
                                          value={allowsObservation || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setAllowsObservation} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productPreparationTime"
                                          mask="99:99"
                                          description="Tempo preparo"
                                          value={preparationTime}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPreparationTime(event.target.value)} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 680) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Select
                                          name="productAllowsObservation"
                                          description="Permite OBS"
                                          value={allowsObservation || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setAllowsObservation} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productPreparationTime"
                                          mask="99:99"
                                          description="Tempo preparo"
                                          value={preparationTime}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPreparationTime(event.target.value)} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 680) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="productAllowsObservation"
                                            description="Permite OBS"
                                            value={allowsObservation || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setAllowsObservation} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productPreparationTime"
                                            mask="99:99"
                                            description="Tempo preparo"
                                            value={preparationTime}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setPreparationTime(event.target.value)} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productTotalFraction"
                                          mask="9"
                                          description="Total Fração"
                                          value={totalFraction}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleTotalFraction} />
                                      </div>
                                    </div>
                                  }

                                  { (width < 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 690) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 740) && (width >= 690) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 1060) && (width >= 740) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1060) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 790) && (width >= 560) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={false}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 790) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }, {\"ID\": 2, \"VALUE\": \"Revenda\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Consumo\" }, {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }, {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }"+
                                            ", {\"ID\": 9, \"VALUE\": \"Outros insumos\" }, {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }                                  
                                </div>
                              </div>
                            }

                            {/* Licitação */}
                            { jsonCompany.TYPE === 4 &&
                              <div style={{marginBottom: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Saída" />
                                </div>
                                <div style={(width < 560) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  { showMenu && (width < 1120) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1120) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 850) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 850) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 1120) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1120) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 850) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 850) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 1120) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="productOriginal"
                                            description="Original"
                                            value={original || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setOriginal} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productMandatoryLot"
                                            description="Lote Obrigatório"
                                            value={mandatoryLot || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setMandatoryLot} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productLotSeries"
                                          description="Série/Lote"
                                          value={lotSeries || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Série\" }, {\"ID\": 2, \"VALUE\": \"Lote\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setLotSeries} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1120) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Select
                                          name="productOriginal"
                                          description="Original"
                                          value={original || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setOriginal} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productMandatoryLot"
                                          description="Lote Obrigatório"
                                          value={mandatoryLot || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setMandatoryLot} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productLotSeries"
                                          description="Série/Lote"
                                          value={lotSeries || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Série\" }, {\"ID\": 2, \"VALUE\": \"Lote\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setLotSeries} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 850) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <Select
                                            name="productOriginal"
                                            description="Original"
                                            value={original || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setOriginal} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productMandatoryLot"
                                            description="Lote Obrigatório"
                                            value={mandatoryLot || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setMandatoryLot} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productLotSeries"
                                          description="Série/Lote"
                                          value={lotSeries || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Série\" }, {\"ID\": 2, \"VALUE\": \"Lote\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setLotSeries} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 850) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <Select
                                          name="productOriginal"
                                          description="Original"
                                          value={original || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setOriginal} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productMandatoryLot"
                                          description="Lote Obrigatório"
                                          value={mandatoryLot || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setMandatoryLot} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productLotSeries"
                                          description="Série/Lote"
                                          value={lotSeries || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Série\" }, {\"ID\": 2, \"VALUE\": \"Lote\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setLotSeries} />
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>
                            }

                            {/* Feira ou Lene */}
                            { ((jsonCompany.TYPE === 5) || (jsonCompany.TYPE === 6)) &&
                              <div style={{marginBottom: 10}}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Saída" />
                                </div>
                                <div style={(width < 560) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  { showMenu && (width < 1120) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1120) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 850) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumDiscountPercentage"
                                            description="Desconto Max.(%)"
                                            value={maximumDiscountPercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumDiscountPercentage} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <InputNumeric
                                            type="text"
                                            name="productMaximumIncreasePercentage"
                                            description="Acréscimo Max.(%)"
                                            value={maximumIncreasePercentage || ""}
                                            maxLength={13}
                                            disabled={false}
                                            require={true}
                                            negative={false} 
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleMaximumIncreasePercentage} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 850) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumDiscountPercentage"
                                          description="Desconto Max.(%)"
                                          value={maximumDiscountPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumDiscountPercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productMaximumIncreasePercentage"
                                          description="Acréscimo Max.(%)"
                                          value={maximumIncreasePercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleMaximumIncreasePercentage} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="productComissionPercentage"
                                          description="Comissão (%)"
                                          value={comissionPercentage || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={true}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleComissionPercentage} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width < 1120) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width >= 1120) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width < 850) &&
                                    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 470) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                          <InputMasked
                                            type="text"
                                            name="productValidity"
                                            mask="999999"
                                            description="Validade"
                                            value={validity || "0"}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={handleValidity} />
                                        </div>
                                        <div style={
                                          (width < 470) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                          <Select
                                            name="productEmitGondola"
                                            description="Emite Gondola"
                                            value={emitGondola || 0}
                                            require={true}
                                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                              ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            set={setEmitGondola} />
                                        </div>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width >= 850) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="productValidity"
                                          mask="999999"
                                          description="Validade"
                                          value={validity || "0"}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValidity} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productEmitGondola"
                                          description="Emite Gondola"
                                          value={emitGondola || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setEmitGondola} />
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                        <Select
                                          name="productType"
                                          description="Tipo da movimentação"
                                          value={type || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Industrialização\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Revenda\" }, {\"ID\": 3, \"VALUE\": \"Consumo\" }"+
                                            ", {\"ID\": 4, \"VALUE\": \"Serviço\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Combustível\" }, {\"ID\": 6, \"VALUE\": \"Embalagem\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Materia Prima\" }"+
                                            ", {\"ID\": 8, \"VALUE\": \"Ativo Imobilizado\" }, {\"ID\": 9, \"VALUE\": \"Outros insumos\" }"+
                                            ", {\"ID\": 10, \"VALUE\": \"Outras\" }"+
                                            ", {\"ID\": 11, \"VALUE\": \"Revenda (Substituto)\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setType} />
                                      </div>
                                    </div>
                                  }

                                </div>
                              </div>
                            }

                            {/* Geral */}
                            { jsonCompany.TYPE === 2 &&
                              <>
                                <div style={showMenu ?
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 0,
                                      paddingRight: 0}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                                    : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10, 
                                      paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                                  :
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 0,
                                      paddingRight: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                      : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    :  
                                      (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                    <Select
                                      name="productRelevantScale"
                                      description="Escala"
                                      value={relevantScale || 0}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                      image={false}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setRelevantScale} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="productBenefitCode"
                                      description="Código Benefício"
                                      value={benefitCode}
                                      maxLength={10}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setBenefitCode(event.target.value)} />
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 0,
                                      paddingRight: 0}
                                    : (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10,
                                      paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}
                                  : 
                                    (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 0,
                                      paddingRight: 0}
                                    : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 10,
                                      paddingRight: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    :  
                                      (width < 750) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="productAnvisaCode"
                                      description="Código ANVISA"
                                      value={anvisaCode}
                                      maxLength={13}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setAnvisaCode(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 750) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="productAnvisaExemptionReason"
                                      description="Motivo Isenção ANVISA"
                                      value={anvisaExemptionReason}
                                      maxLength={255}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} 
                                      onChange={(event) => setAnvisaExemptionReason(event.target.value)} />
                                  </div>
                                </div>
                              </>
                            }

                            { parseInt(type) === 4 &&
                              <>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Item do Serviço CNAE" />
                                </div>

                                <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={(showMenu) ?
                                      (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 1040) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="productServiceItemCnaeCode"
                                        description="Código"
                                        value={serviceItemCnaeCode}
                                        maxLength={5}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} 
                                        onChange={(event) => setServiceItemCnaeCode(event.target.value)} /> 
                                    </div>
                                    <div style={showMenu ?
                                        (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="productServiceItemCnaeDescription"
                                        description="Descrição"
                                        value={serviceItemCnaeDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setServiceItemCnaeDescription(event.target.value)} />
                                    </div>
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1,
                                        marginTop: 10}
                                      : {display: "flex", flexDirection: "row", alignItems: "end", flexGrow: 0, marginLeft: 10}}>
                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="productSearchServiceItemCnae"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableServiceItemCnae} />
                                    <img 
                                      style={{marginLeft: 10, height: 42, width: 42}}
                                      name="productCleanServiceItemCnae"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={cleanServiceItemCnae} />
                                  </div>
                                </div>
                              </>
                            }

                            {/* Fornecedores */}
                            { code && parseInt(type) !== 1 && parseInt(type) !== 4  &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Fornecedores" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}>
                                  <SearchProductSupplier
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProduct={jProduct}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Embalagens */}
                            { code &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Embalagens" />
                                </div>
                            
                                <div>
                                  <SearchProductPack
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProduct={jProduct}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Composições */}
                            { codeFil && (parseInt(type) === 1) &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Composições" />
                                </div>
                            
                                <div>
                                  <SearchProductCompanyComposition
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany} 
                                    jsonLogin={jsonLogin}
                                    system={system} 
                                    showMenu={showMenu} 
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Acréscimos */}
                            { codeFil && (parseInt(type) === 1) &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Acréscimos" />
                                </div>
                            
                                <div>
                                  <SearchProductCompanyAddition
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany} 
                                    jsonLogin={jsonLogin}
                                    system={system} 
                                    showMenu={showMenu} 
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Offer */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Ofertas" />
                                </div>
                            
                                <div>
                                  <SearchProductCompanyOffer
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* NVE */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="NVEs" />
                                </div>
                            
                                <div>
                                  <SearchProductCompanyNve
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Alteração Situação */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Alterações da Situação" />
                                </div>
                            
                                <div>
                                  <SearchProductAlterationSituation
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Alteração Preço */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Alterações do Preço" />
                                </div>
                            
                                <div>
                                  <SearchProductAlterationPrice
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    showMenu={showMenu}
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Alteração Classe */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Alterações da Classe" />
                                </div>
                            
                                <div>
                                  <SearchProductAlterationClass
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany} 
                                    jsonLogin={jsonLogin}
                                    system={system} 
                                    showMenu={showMenu} 
                                    width={width} />
                                </div>
                              </div>
                            }

                            {/* Alteração NCM */}
                            { codeFil &&
                              <div>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Alterações do NCM" />
                                </div>
                            
                                <div>
                                  <SearchProductAlterationNcm
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonProductCompany ={jProductCompany}
                                    jsonCompany={jsonCompany} 
                                    jsonLogin={jsonLogin}
                                    system={system} 
                                    showMenu={showMenu} 
                                    width={width} />
                                </div>
                              </div>
                            }
                            
                          </div>
                        </>
                      }

                      { showCommercialUnit &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione a unidade comercial:</b>
                          </div>

                          <SearchUnit
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillCommercialUnit} 
                            setReturn={() => setShowCommercialUnit(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showTaxableUnit &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione a unidade tributável:</b>
                          </div>

                          <SearchUnit
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillTaxableUnit} 
                            setReturn={() => setShowTaxableUnit(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showDepartment &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o departamento:</b>
                          </div>

                          <SearchDepartment
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillDepartment} 
                            setReturn={() => setShowDepartment(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showManufacturer &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o fabricante:</b>
                          </div>

                          <SearchManufacturer
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillManufacturer} 
                            setReturn={() => setShowManufacturer(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }            

                      { showNcm &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o NCM:</b>
                          </div>

                          <SearchNcm
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillNcm} 
                            setReturn={() => setShowNcm(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showClass &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione a classe:</b>
                          </div>

                          <SearchFisClass
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillClass}
                            setReturn={() => setShowClass(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showBox &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o box:</b>
                          </div>

                          <SearchBox
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillBox} 
                            setReturn={() => setShowBox(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showAnp &&
                        <div style={{marginTop: 10}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o ANP:</b>
                          </div>

                          <SearchAnp
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAnp} 
                            setReturn={() => setShowAnp(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showServiceItemCnae &&
                        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 15}}>
                          <div style={{marginLeft: 10}}>
                            <b>Selecione o CNAE do Item do Serviço:</b>
                          </div>

                          <SearchServiceItemCnae
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}
                            system={system}
                            setSelectedSearch={fillServiceItemCnae} 
                            setReturn={() => setShowServiceItemCnae(false)}
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Product