import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import SearchAccountPlan from "../AccountPlan/Search"
import SearchClientBranch from "../ClientBranch/Search"
import SearchClient from "./Search"
import SearchClientClass from "../ClientClass/Search"
import SearchClientContact from "../ClientContact/Search"
import SearchClientEmail from "../ClientEmail/Search"
import SearchClientPhone from "../ClientPhone/Search"
import SearchClientProduct from "../ClientProduct/Search"
import SearchClientType from "../ClientType/Search"
import SearchDocumentType from "../DocumentType/Search"
import SearchEstablishment from "../Establishment/Search"
import SearchFather from "./Search"
import SearchPaymentForm from "../PaymentForm/Search"
import SearchClientRoute from "../ClientRoute/Search"
import SearchSeller from "../Employee/Search"
import SearchStock from "../Stock/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_search from "../../Images/Buttons/search.png"

const Client = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, optionsClientClass, optionsClientType, setReturn,
  height, width, logoff}) => {

  ////
  // Client // Cliente
  ////
    const [code, setCode] = useState("")
    const [cpfCnpj, setCpfCnpj] = useState("")
    const [corporateName, setCorporateName] = useState("")
    const [fantasyName, setFantasyName] = useState("")
    const [dateBirth, setDateBirth] = useState("")
    const [indicatorIe, setIndicatorIe] = useState(3)
    const [im, setIm] = useState("")
    const [ie, setIe] = useState("")
    const [rg, setRg] = useState("")
    const [cnae, setCnae] = useState("")
    const [passaport, setPassaport] = useState("")
    const [mother, setMother] = useState("")
    const [responsibleCpf, setResponsibleCpf] = useState("")
    const [responsibleName, setResponsibleName] = useState("")
    const [suframa, setSuframa] = useState("")

    const [addressRelease, setAddressRelease] = useState(false)

    const [cep, setCep] = useState("")
    const [address, setAddress] = useState("")
    const [number, setNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [city, setCity] = useState("")
    const [cityIbge, setCityIbge] = useState("")
    const [uf, setUf] = useState("")
    const [ufIbge, setUfIbge] = useState("")
    const [countryCode, setCountryCode] = useState(0)
    const [countryDescription, setCountryDescription] = useState("")
    const [countryIbge, setCountryIbge] = useState("")

    ////
    // Establishment // Estabelecimento
    ////
      const [establishmentCode, setEstablishmentCode] = useState("")
      const [establishmentDescription, setEstablishmentDescription] = useState("")

    ////
    // Client Type // Tipo do Cliente
    ////
      const [clientTypeCode, setClientTypeCode] = useState("")
      const [clientTypeDescription, setClientTypeDescription] = useState("")

    ////
    // Client Class // Classe do Cliente
    ////  
      const [clientClassCode, setClientClassCode] = useState("")
      const [clientClassDescription, setClientClassDescription] = useState("")

    ////
    // Father // Pai
    ////
      const [fatherCode, setFatherCode] = useState("")
      const [fatherCorporateName, setFatherCorporateName] = useState("")
  
    const [barCode, setBarCode] = useState("")
    const [password, setPassword] = useState("")
    const [dueDay, setDueDay] = useState("")
    const [deliveryDay, setDeliveryDay] = useState("")
    const [limitTotalValue, setLimitTotalValue] = useState(0)
    const [limitPartialValue, setLimitPartialValue] = useState(0)
    const [shippingType, setShippingType] = useState(2)
    const [domain, setDomain] = useState("")
    const [databasePathF, setDatabasePathF] = useState("")
    const [databaseTypeF, setDatabaseTypeF] = useState(1)
    const [systemType, setSystemType] = useState(2)
    const [saleType, setSaleType] = useState(1)
    const [domainHomologation, setDomainHomologation] = useState("")
    const [databasePathHomologation, setDatabasePathHomologation] = useState("")
    const [databaseTypeHomologation, setDatabaseTypeHomologation] = useState(1)
    const [systemTypeHomologation, setSystemTypeHomologation] = useState(2)
    const [saleTypeHomologation, setSaleTypeHomologation] = useState(1)
    const [contractValue, setContractValue] = useState(0)
    const [automaticNfse, setAutomaticNfse] = useState(0)
    const [highlightRetain, setHighlightRetain] = useState(0)

    ///// 
    // Service CNAE // Serviço CNAE
    ////
      const [serviceCnaeCode, setServiceCnaeCode] = useState("")
      const [serviceCnaeDescription, setServiceCnaeDescription] = useState("")

    ///// 
    // Service CNAE Code // Código do Serviço CNAE
    ////
      const [serviceCode, setServiceCode] = useState("")
      const [serviceDescription, setServiceDescription] = useState("")

    ///// 
    // Service CNAE Item // Item do Serviço CNAE
    ////
      const [serviceItemCode, setServiceItemCode] = useState("")
      const [serviceItemDescription, setServiceItemDescription] = useState("")
      const [serviceItemAliquot, setServiceItemAliquot] = useState(0)

    const [natureService, setNatureService] = useState(0)
    const [percentageInss, setPercentageInss] = useState(0)  
    const [percentageIr, setPercentageIr] = useState(0)
    const [percentageCsll, setPercentageCsll] = useState(0)
    const [registrationDate, setRegistrationDate] = useState("")
    const [contractStartDate, setContractStartDate] = useState("")  
    const [reasonAcquisition, setReasonAcquisition] = useState("")
    const [contractExpirationDate, setContractExpirationDate] = useState("")
    const [cancellationDate, setCancellationDate] = useState("")
    const [reasonCancellation, setReasonCancellation] = useState("")
    const [type, setType] = useState(1)

    const [maritalStatus, setMaritalStatus] = useState(0)
    const [gender, setGender] = useState(0)
    const [kindPerson, setKindPerson] = useState(0)
    const [operator, setOperator] = useState(0)
    const [form, setForm] = useState(0)

    ///// 
    // Route Client // Rota do Cliente
    ////
      const [routeCode, setRouteCode] = useState("")
      const [routeDescription, setRouteDescription] = useState("")  

    const [integrateSge, setIntegrateSge] = useState(2)
    const [domainSge, setDomainSge] = useState("")
    const [databaseSge, setDatabaseSge] = useState("")
    const [bankType, setBankType] = useState(0)
    const [typeTransfer, setTypeTransfer] = useState(1)
    const [valueTransfer, setValueTransfer] = useState("")
    const [observation, setObservation] = useState("")
    const [situation, setSituation] = useState(1)
    const [sge, setSge] = useState("")

    ////
    // Client Company // Cliente Filial
    ////
      const [companyClientCode, setCompanyClientCode] = useState("")
      const [companyClientSge, setCompanyClientSge] = useState("")

      ////
      // Seller // Vendedor
      ////
        const [sellerCode, setSellerCode] = useState("")
        const [sellerName, setSellerName] = useState("")

      ////
      // Stock // Estoque
      ////
        const [stockCode, setStockCode] = useState("")
        const [stockDescription, setStockDescription] = useState("")

      ////
      // Payment Form // Forma de pagamento
      ////
        const [paymentFormCode, setPaymentFormCode] = useState("")
        const [paymentFormDescription, setPaymentFormDescription] = useState("")

      ////
      // Documento Type // Tipo do Documento
      ////
        const [documentTypeCode, setDocumentTypeCode] = useState("")
        const [documentTypeDescription, setDocumentTypeDescription] = useState("") 
        const [documentTypeProcess, setDocumentTypeProcess] = useState("") 

      ////
      // Account Plan // Plano de Conta
      ////
        const [accountPlanCode, setAccountPlanCode] = useState("")
        const [accountPlanDescription, setAccountPlanDescription] = useState("")
    ////
  ////

  const [jClient, setJClient] = useState({})

  const [jsonCnaeService, setJsonCnaeService] = useState([])
  const [jsonCodeService, setJsonCodeService] = useState([])
  const [jsonItemService, setJsonItemService] = useState([])

  const [showMenu, setShowMenu] = useState(true)
  const [firstAcess, setFirstAcess] = useState(true)
  const [updateFocu, setUpdateFocu] = useState(0)

  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showClientClass, setShowClientClass] = useState(false)
  const [showClientType, setShowClientType] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)
  const [showEstablishment, setShowEstablishment] = useState(false)
  const [showFather, setShowFather] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  const [showSearch, setShowSearch] = useState(true)
  const [showSeller, setShowSeller] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const [showRoute, setShowRoute] = useState(false)

  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
    
  const [national, setNational] = useState(true)
  const [listCountry, setListCountry] = useState([])
  const [optionsCountries, setOptionsCountries] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [optionsCnaeService, setOptionsCnaeService] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsService, setOptionsService] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  const [optionsItemService, setOptionsItemService] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")  

  useEffect(() => {
    if (firstAcess) {
      let returnJson = []

      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 252)

      axios.post(BASE_URL + "COUNTRY/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os países. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          let country = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
              country += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.NAME +"\"}"
            ))

          country += "]}"

          setListCountry(returnJson)
          setOptionsCountries(country)
          setCountryCode(34)
        }
      })

      ///
      // Upload information of the CNAE service // Atualiza informações do Serviço CNAE
      ///
      setLoading("Aguarde buscando as informações dos CNAEs dos serviços...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonCnaeService(returnJson.OBJECT)

          let cnaeService = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson && returnJson.OBJECT &&
            returnJson.OBJECT.map(item => (
                cnaeService += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
            ))
          cnaeService += "]}"

          setOptionsCnaeService(cnaeService)
        }
      })

      ////
      // Upload information of the code CNAE service // Atualiza as informações do Código do Serviço CNAE
      ////
      setLoading("Aguarde buscando as informações dos Códigos dos Serviços CNAE...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_CODE_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os códigos dos serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonCodeService(returnJson.OBJECT)
        }
      })
      
      ////
      // Upload information of the item service CNAE // Atualiza as informações dos itens dos serviços CNAE
      ///
      setLoading("Aguarde buscando as informações dos Itens dos serviços CNAE...")      
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "SERVICE_ITEM_CNAE/Search", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os itens dos serviços CNAE. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
        else
        {
          setJsonItemService(returnJson.OBJECT)
        }
      })

      setFirstAcess(false)
      setLoading("")
    }
    else{
      (countryDescription === "BRASIL") ? setNational(true) : setNational(false)

      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIbge("")
      setUf("")
      setUfIbge("")
    }

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")
      setDateBirth("")
      setIndicatorIe(3)
      setIm("")
      setIe("ISENTO")
      setRg("")
      setCnae("")
      setPassaport("")
      setMother("")
      setResponsibleCpf("")
      setResponsibleName("")
      setSuframa("")
      
      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIbge("")
      setUf("")
      setUfIbge("")
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIbge(1058)
      
      setEstablishmentCode("")
      setEstablishmentDescription("")
      
      setClientTypeCode("")
      setClientTypeDescription("")
      
      setClientClassCode("")
      setClientClassDescription("")
      
      setFatherCode("")
      setFatherCorporateName("")
        
      setBarCode("")
      setPassword("")
      setDueDay("")
      setDeliveryDay("")
      setLimitTotalValue(0)
      setLimitPartialValue(0)
      setShippingType(2)
      setDomain("")
      setDatabasePathF("")
      setDatabaseTypeF(1)
      setSystemType(2)
      setSaleType(1)
      setDomainHomologation("")
      setDatabasePathHomologation("")
      setDatabaseTypeHomologation(1)
      setSystemTypeHomologation(2)
      setSaleTypeHomologation(1)
      setContractValue(0)
      setAutomaticNfse(0)
      setHighlightRetain(0)
      
      setServiceCnaeCode("")
      setServiceCnaeDescription("")
      
      setServiceCode("")
      setServiceDescription("")
      
      setServiceItemCode("")
      setServiceItemDescription("")
      setServiceItemAliquot(0)
      
      setNatureService(0)
      setPercentageInss(0)  
      setPercentageIr(0)
      setPercentageCsll(0)
      setRegistrationDate("")
      setContractStartDate("")  
      setReasonAcquisition("")
      setContractExpirationDate("")
      setCancellationDate("")
      setReasonCancellation("")
      setType(1)
      setMaritalStatus(0)  
      setGender(0)
      setKindPerson(0)
      setOperator(0)
      setForm(0)
      
      setRouteCode("")
      setRouteDescription("")  
      
      setIntegrateSge(2)
      setDomainSge("")
      setDatabaseSge("")
      setBankType(0)
      setTypeTransfer(1)
      setValueTransfer("")
      setObservation("")
      setSituation(1)
      setSge("")
      
      setCompanyClientCode("")
      setCompanyClientSge("")

      setSellerCode("")
      setSellerName("")
      
      setStockCode("")
      setStockDescription("")
      
      setPaymentFormCode("")
      setPaymentFormDescription("")
      
      setDocumentTypeCode("")
      setDocumentTypeDescription("") 
      
      setAccountPlanCode("")
      setAccountPlanDescription("")
    }
    else {
      setCpfCnpj(jClient.CPF_CNPJ)
      setCorporateName(jClient.CORPORATE_NAME)
      setFantasyName(jClient.FANTASY_NAME)

      if (jClient.DATE_BIRTH !== "0001-01-01T00:00:00"){
        const sDateBirthM = jClient.DATE_BIRTH.substr(8, 2) + "-" + jClient.DATE_BIRTH.substr(5, 2) + "-" + jClient.DATE_BIRTH.substr(0, 4) + "T00:00:00"
        setDateBirth(sDateBirthM);
      } else setDateBirth("")

      setIndicatorIe(jClient.INDICATOR_IE)

      if (jClient.IM !== null) setIm(jClient.IM); else setIm("")

      setIe(jClient.IE)

      if (jClient.RG !== null) setRg(jClient.RG); else setRg("")
      if ((jClient.CNAE !== null) && (String(jClient.CNAE) !== "0")) setCnae(jClient.CNAE); else setCnae("")
      if (jClient.PASSPORT !== null) setPassaport(jClient.PASSPORT); else setPassaport("")
      if (jClient.MOTHER !== null) setMother(jClient.MOTHER); else setMother("")
      if (jClient.RESPONSIBLE_CPF !== null) setResponsibleCpf(jClient.RESPONSIBLE_CPF); else setResponsibleCpf("")
      if (jClient.RESPONSIBLE_NAME !== null) setResponsibleName(jClient.RESPONSIBLE_NAME); else setResponsibleName("")
      if (jClient.SUFRAMA !== null) setSuframa(jClient.SUFRAMA); else setSuframa("")

      setCep(jClient.CEP)
      setAddress(jClient.ADDRESS)
      setNumber(jClient.NUMBER)
      setComplement(jClient.COMPLEMENT)
      setNeighborhood(jClient.NEIGHBORHOOD)
      setCity(jClient.CITY)
      setCityIbge(jClient.CITY_IBGE)
      setUf(jClient.UF)
      setUfIbge(jClient.UF_IBGE)

      if (jClient.COUNTRY_DESCRIPTION === "BRASIL") setNational(true)
      else setNational(false)

      let codeP = 0;
      listCountry.OBJECT.map(item => (
        (item.NAME === jClient.COUNTRY_DESCRIPTION) ? codeP = item.ID : ""
      ))

      setCountryCode(codeP)
      setCountryDescription(jClient.COUNTRY_DESCRIPTION)
      setCountryIbge(jClient.COUNTRY_IBGE)

      if (jClient.ESTABLISHMENT !== null){
        setEstablishmentCode(jClient.ESTABLISHMENT.ID)
        setEstablishmentDescription(jClient.ESTABLISHMENT.DESCRIPTION)
      }
      else{
        setEstablishmentCode("")
        setEstablishmentDescription("")
      }

      setClientTypeCode(jClient.CLIENT_TYPE.ID)
      setClientTypeDescription(jClient.CLIENT_TYPE.DESCRIPTION)

      setClientClassCode(jClient.CLIENT_CLASS.ID)
      setClientClassDescription(jClient.CLIENT_CLASS.DESCRIPTION)

      if (jClient.CLIENT_FATHER !== null){
        setFatherCode(jClient.CLIENT_FATHER.ID)
        setFatherCorporateName(jClient.CLIENT_FATHER.CORPORATE_NAME)
      }
      else{
        setFatherCode("")
        setFatherCorporateName("")
      }
      
      if (jClient.BAR_CODE !== null) setBarCode(jClient.BAR_CODE); else setBarCode(jClient.CPF_CNPJ)
      if (jClient.PASSWORD !== null) setPassword(jClient.PASSWORD); else setPassaport("")
      if (jClient.DUE_DAY !== 0) setDueDay(jClient.DUE_DAY); else setDueDay("")
      if (jClient.DELIVERY_DAY !== 0) setDeliveryDay(jClient.DELIVERY_DAY); else setDeliveryDay("")

      setLimitTotalValue(formatNumeric(jClient.TOTAL_LIMIT_VALUE,2))
      setLimitPartialValue(formatNumeric(jClient.TOTAL_LIMIT_PARTIAL,2))
      setShippingType(jClient.SHIPPING_TYPE)

      if (jClient.DOMAIN !== null) setDomain(jClient.DOMAIN); else setDomain("")
      if (jClient.DATABASE_PATH !== null) setDatabasePathF(jClient.DATABASE_PATH); else setDatabasePathF("")
      if (jClient.DATABASE_TYPE !== 0) setDatabaseTypeF(jClient.DATABASE_TYPE); setDatabaseTypeF("")
      if (jClient.SYSTEM_TYPE !== 0) setSystemType(jClient.SYSTEM_TYPE); else setSystemType(2)
      if (jClient.SALE_TYPE !== 0) setSaleType(jClient.SALE_TYPE); else setSaleType(1)
      if (jClient.DOMAIN_HOMOLOGATION !== null) setDomainHomologation(jClient.DOMAIN_HOMOLOGATION); else setDomainHomologation("")
      if (jClient.DATABASE_PATH_HOMOLOGATION !== null) setDatabasePathHomologation(jClient.DATABASE_PATH_HOMOLOGATION); else setDatabasePathHomologation("")
      if (jClient.DATABASE_TYPE_HOMOLOGATION !== 0) setDatabaseTypeHomologation(jClient.DATABASE_TYPE_HOMOLOGATION); else setDatabaseTypeHomologation("")
      if (jClient.SYSTEM_TYPE_HOMOLOGATION !== 0) setSystemTypeHomologation(jClient.SYSTEM_TYPE_HOMOLOGATION); else setSystemTypeHomologation(2)
      if (jClient.SALE_TYPE_HOMOLOGATION !== 0) setSaleTypeHomologation(jClient.SALE_TYPE_HOMOLOGATION); else setSaleTypeHomologation(1)

      setContractValue(formatNumeric(jClient.CONTRACT_VALUE, 2))      
      
      if (jClient.AUTOMATIC_NFSE !== 0) setAutomaticNfse(jClient.AUTOMATIC_NFSE); else setAutomaticNfse(0)
      if (jClient.HIGHLIGHT_RETAIN !== 0) setHighlightRetain(jClient.HIGHLIGHT_RETAIN); else setHighlightRetain(0)

      if ((jClient.SERVICE_CODE_CNAE !== null) && (jClient.SERVICE_CODE_CNAE !== null)){
        if (jClient.SERVICE_CODE_CNAE.CODE !== null) setServiceCode(jClient.SERVICE_CODE_CNAE.CODE)
        if (jClient.SERVICE_CODE_CNAE.DESCRIPTION !== null) setServiceDescription(jClient.SERVICE_CODE_CNAE.DESCRIPTION)

        if (jClient.SERVICE_CODE_CNAE.SERVICE_CNAE !== null){
          if (jClient.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE !== null) setServiceCnaeCode(jClient.SERVICE_CODE_CNAE.SERVICE_CNAE.CODE)
          if (jClient.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION !== null) setServiceCnaeDescription(jClient.SERVICE_CODE_CNAE.SERVICE_CNAE.DESCRIPTION)

          let cnaeService = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          jsonCnaeService &&
            jsonCnaeService.map(item => (
              cnaeService += ",{\"ID\": "+ item.CODE +", \"VALUE\": \""+ item.CODE + " - " + item.DESCRIPTION +"\"}"
            ))
            cnaeService += "]}"

          setOptionsCnaeService(cnaeService)

          let optionsService = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
          jsonCodeService && 
            jsonCodeService.map(it => (
              optionsService += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}"
            ))
          optionsService += "]}"

          setOptionsService(optionsService)
        }
        else{
          setServiceCnaeCode("")
          setServiceCnaeDescription("")          
        }
      }
      else{
        setServiceCode("")
        setServiceDescription("")
        setServiceCnaeCode("")
        setServiceCnaeDescription("")
      }

      if (jClient.NATURE_SERVICE !== 0) setNatureService(jClient.NATURE_SERVICE); else setNatureService(0);

      setPercentageInss(jClient.PERCENTAGE_INSS)
      setPercentageIr(jClient.PERCENTAGE_IR)
      setPercentageCsll(jClient.PERCENTAGE_CSLL)

      if (jClient.REGISTRATION_DATE !== "0001-01-01T00:00:00"){
        const sDateCadastre = jClient.REGISTRATION_DATE.substr(8, 2) + "-" + jClient.REGISTRATION_DATE.substr(5, 2) + "-" + 
          jClient.REGISTRATION_DATE.substr(0, 4) + "T00:00:00"

        setRegistrationDate(sDateCadastre)
      } else setRegistrationDate("")

      if (jClient.CONTRACT_START_DATE !== "0001-01-01T00:00:00"){
        const sDateStart = jClient.CONTRACT_START_DATE.substr(8, 2) + "-" + jClient.CONTRACT_START_DATE.substr(5, 2) + "-" + 
          jClient.CONTRACT_START_DATE.substr(0, 4) + "T00:00:00"

        setContractStartDate(sDateStart)
      } else setContractStartDate("")

      if (jClient.REASON_ACQUISITION !== null) setReasonAcquisition(jClient.REASON_ACQUISITION); else setReasonAcquisition("")

      if (jClient.CONTRACT_EXPIRATION_DATE !== "0001-01-01T00:00:00"){
        const sDateValidity = jClient.CONTRACT_EXPIRATION_DATE.substr(8, 2) + "-" + jClient.CONTRACT_EXPIRATION_DATE.substr(5, 2) + "-" + 
          jClient.CONTRACT_EXPIRATION_DATE.substr(0, 4) + "T00:00:00"

        setContractExpirationDate(sDateValidity)
      } else setContractExpirationDate("")

      if (jClient.CANCELLATION_DATE !== "0001-01-01T00:00:00"){
        const sDateCancellation = jClient.CANCELLATION_DATE.substr(8, 2) + "-" + jClient.CANCELLATION_DATE.substr(5, 2) + "-" + 
          jClient.CANCELLATION_DATE.substr(0, 4) + "T00:00:00"

        setCancellationDate(sDateCancellation)
      } else setCancellationDate("")

      if (jClient.REASON_CANCELLATION !== null) setReasonCancellation(jClient.REASON_CANCELLATION); else setReasonCancellation("")

      setType(jClient.TYPE)

      if (jClient.MARITAL_STATUS !== 0) setMaritalStatus(jClient.MARITAL_STATUS); else setMaritalStatus(0)
      if (jClient.GENDER !== 0) setGender(jClient.GENDER); else setGender(0)
      if (jClient.KIND_PERSON !== 0) setKindPerson(jClient.KIND_PERSON); else setKindPerson(0)
      if (jClient.OPERATOR !== 0) setOperator(jClient.OPERATOR); else setOperator(0)
      if (jClient.FORM !== 0) setForm(jClient.FORM); else setForm(0)

      if ((jClient.CLIENT_ROUTE !== undefined) && (jClient.CLIENT_ROUTE !== null)){
        setRouteCode(jClient.CLIENT_ROUTE.ID)
        setRouteDescription(jClient.CLIENT_ROUTE.DESCRIPTION)
      }
      else{
        setRouteCode("")
        setRouteDescription("")
      }

      setIntegrateSge(jClient.INTEGRATE_SGE)

      if (jClient.DOMAIN_SGE !== undefined) setDomainSge(jClient.DOMAIN_SGE); else setDomainSge("")
      if (jClient.DATABASE_SGE !== undefined) setDatabaseSge(jClient.DATABASE_SGE); else setDatabaseSge("")

      setTypeTransfer(jClient.TYPE_TRANSFER)
      setValueTransfer(formatNumeric(jClient.VALUE_TRANSFER, 2))

      if (jClient.OBSERVATION !== undefined) setObservation(jClient.OBSERVATION); else setObservation("")

      setSituation(jClient.SITUATION)

      if (((jClient.SGE !== undefined) && (jClient.SGE !== null)) && (jClient.SGE !== 0)) setSge(jClient.SGE); else setSge("")

      if ((jClient.CLIENT_COMPANY !== undefined) && (jClient.CLIENT_COMPANY !== null)){
        setCompanyClientCode(jClient.CLIENT_COMPANY.ID)
      
        if ((jClient.CLIENT_COMPANY.SELLER !== undefined) && (jClient.CLIENT_COMPANY.SELLER !== null)){
          setSellerCode(jClient.CLIENT_COMPANY.SELLER.ID)
          setSellerName(jClient.CLIENT_COMPANY.SELLER.NAME)
        }
        else{
          setSellerCode("")
          setSellerName("")
        }

        if ((jClient.CLIENT_COMPANY.STOCK !== undefined) && (jClient.CLIENT_COMPANY.STOCK !== null)){
          setStockCode(jClient.CLIENT_COMPANY.STOCK.ID)
          setStockDescription(jClient.CLIENT_COMPANY.STOCK.DESCRIPTION)
        }
        else{
          setStockCode("")
          setStockDescription("")
        }

        if ((jClient.CLIENT_COMPANY.PAYMENT_FORM !== undefined) && (jClient.CLIENT_COMPANY.PAYMENT_FORM !== null)){
          setPaymentFormCode(jClient.CLIENT_COMPANY.PAYMENT_FORM.ID)
          setPaymentFormDescription(jClient.CLIENT_COMPANY.PAYMENT_FORM.DESCRIPTION)
        }
        else{
          setPaymentFormCode("")
          setPaymentFormDescription("")
        }

        if ((jClient.CLIENT_COMPANY.DOCUMENT_TYPE !== undefined) && (jClient.CLIENT_COMPANY.DOCUMENT_TYPE !== null)){
          setDocumentTypeCode(jClient.CLIENT_COMPANY.DOCUMENT_TYPE.ID)
          setDocumentTypeDescription(jClient.CLIENT_COMPANY.DOCUMENT_TYPE.DESCRIPTION)
        }
        else{
          setDocumentTypeCode("")
          setDocumentTypeDescription("")
        }

        if ((jClient.CLIENT_COMPANY.ACCOUNT_PLAN !== undefined) && (jClient.CLIENT_COMPANY.ACCOUNT_PLAN !== null)){
          setAccountPlanCode(jClient.CLIENT_COMPANY.ACCOUNT_PLAN.ID)
          setAccountPlanDescription(jClient.CLIENT_COMPANY.ACCOUNT_PLAN.DESCRIPTION)
        }
        else
        {
          setAccountPlanCode("")
          setAccountPlanDescription("")
        }

        if ((jClient.CLIENT_COMPANY.SERVICE_ITEM_CNAE !== undefined) && (jClient.CLIENT_COMPANY.SERVICE_ITEM_CNAE !== null)){
          setServiceItemCode(jClient.CLIENT_COMPANY.SERVICE_ITEM_CNAE.CODE)
          setServiceItemDescription(jClient.CLIENT_COMPANY.SERVICE_ITEM_CNAE.DESCRIPTION)
          setServiceItemAliquot(jClient.CLIENT_COMPANY.SERVICE_ITEM_CNAE.ALIQUOT)

          if ((jClient.SERVICE_CODE_CNAE !== null) && (jClient.SERVICE_CODE_CNAE !== null)){
            let optionsItemService = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
            jsonItemService &&
              jsonItemService.map(it => (
                (it.SERVICE_CODE_CNAE.CODE === jClient.SERVICE_CODE_CNAE.CODE) ? optionsItemService += ", {\"ID\": \""+ it.CODE +
                  "\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}" : optionsItemService += ""
              ))
            optionsItemService += "]}"
      
            setOptionsItemService(optionsItemService)            
          }
        }
        else{
          setServiceItemCode("")
          setServiceItemDescription("")
          setServiceItemAliquot(0)
        }

        if (((jClient.CLIENT_COMPANY.BANK_TYPE !== undefined) && (jClient.CLIENT_COMPANY.BANK_TYPE !== null)) && 
          (jClient.CLIENT_COMPANY.BANK_TYPE !== 0)) setBankType(jClient.CLIENT_COMPANY.BANK_TYPE)

        if (((jClient.CLIENT_COMPANY.SGE !== undefined) && (jClient.CLIENT_COMPANY.SGE !== null)) && (jClient.CLIENT_COMPANY.SGE !== 0))
          setCompanyClientSge(jClient.CLIENT_COMPANY.SGE)
        else setCompanyClientSge("")
      }
    }

    document.getElementById("clientCpfCnpj") && document.getElementById("clientCpfCnpj").focus()

  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAcess, code, jClient, countryDescription, listCountry.OBJECT, 
    updateFocu])
 
  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    if (code === "") {
      setCpfCnpj("")
      setCorporateName("")
      setFantasyName("")
      setDateBirth("")
      setIndicatorIe(3)
      setIm("")
      setIe("ISENTO")
      setRg("")
      setCnae("")
      setPassaport("")
      setMother("")
      setResponsibleCpf("")
      setResponsibleName("")      
      setSuframa("")
      
      setCep("")
      setAddress("")
      setNumber("")
      setComplement("")
      setNeighborhood("")
      setCity("")
      setCityIbge("")
      setUf("")
      setUfIbge("")
      setCountryCode(34)
      setCountryDescription("BRASIL")
      setCountryIbge(1058)
      
      setEstablishmentCode("")
      setEstablishmentDescription("")
      
      setClientTypeCode("")
      setClientTypeDescription("")
      
      setClientClassCode("")
      setClientClassDescription("")
      
      setFatherCode("")
      setFatherCorporateName("")
        
      setBarCode("")
      setPassword("")
      setDueDay("")
      setDeliveryDay("")
      setLimitTotalValue(0)
      setLimitPartialValue(0)
      setShippingType(2)
      setDomain("")
      setDatabasePathF("")
      setDatabaseTypeF(1)
      setSystemType(2)
      setSaleType(1)
      setDomainHomologation("")
      setDatabasePathHomologation("")
      setDatabaseTypeHomologation(1)
      setSystemTypeHomologation(2)
      setSaleTypeHomologation(1)
      setContractValue(0)
      setAutomaticNfse(0)
      setHighlightRetain(0)
      
      setServiceCnaeCode("")
      setServiceCnaeDescription("")
      
      setServiceCode("")
      setServiceDescription("")
      
      setServiceItemCode("")
      setServiceItemDescription("")
      setServiceItemAliquot(0)
      
      setNatureService(0)
      setPercentageInss(0)  
      setPercentageIr(0)
      setPercentageCsll(0)
      setRegistrationDate("")
      setContractStartDate("")  
      setReasonAcquisition("")
      setContractExpirationDate("")
      setCancellationDate("")
      setReasonCancellation("")
      setType(1)

      setMaritalStatus(0)  
      setGender(0)
      setKindPerson(0)
      setOperator(0)
      setForm(0)
      
      setRouteCode("")
      setRouteDescription("")  
      
      setIntegrateSge(2)
      setDomainSge("")
      setDatabaseSge("")
      setBankType(1)
      setTypeTransfer(1)
      setValueTransfer("")
      setObservation("")
      setSituation(1)
      setSge("")
      
      setCompanyClientCode("")
      setCompanyClientSge("")

      setSellerCode("")
      setSellerName("")
      
      setStockCode("")
      setStockDescription("")
      
      setPaymentFormCode("")
      setPaymentFormDescription("")
      
      setDocumentTypeCode("")
      setDocumentTypeDescription("") 
      setDocumentTypeProcess("") 
      
      setAccountPlanCode("")
      setAccountPlanDescription("")
    } 
    else setCode("")

    setUpdateFocu(updateFocu + 1)
  }

  const clearAccountPlan = () => {
    setAccountPlanCode("")
    setAccountPlanDescription("")

    setShowAccountPlan(false)
  }

  const clearAddress = () => {
    setCep("")
    setAddress("")
    setNumber("")
    setComplement("")
    setNeighborhood("")
    setCity("")
    setCityIbge("")
    setUf("")
    setUfIbge("")

    setAddressRelease(false)
  }

  const clearClientClass = () => {
    setClientClassCode("")
    setClientClassDescription("")

    setShowClientClass(false)
  }

  const clearClientType = () => {
    setClientTypeCode("")
    setClientTypeDescription("")

    setShowClientType(false)
  }

  const clearDocumentType = () => {
    setDocumentTypeCode("")
    setDocumentTypeDescription("")

    setShowDocumentType(false)
  }

  const clearEstablishment = () => {
    setEstablishmentCode("")
    setEstablishmentDescription("")

    setShowEstablishment(false)
  }

  const clearFather = () => {
    setFatherCode("")
    setFatherCorporateName("")

    setShowFather(false)
  }

  const clearPaymentForm = () => {
    setPaymentFormCode("")
    setPaymentFormDescription("")

    setShowPaymentForm(false)
  }

  const clearRoute = () => {
    setRouteCode("")
    setRouteDescription("")

    setShowRoute(false)
  }

  const clearSeller = () => {
    setSellerCode("")
    setSellerName("")

    setShowSeller(false)
  }

  const clearStock = () => {
    setStockCode("")
    setStockDescription("")

    setShowStock(false)
  }

  const enableAccountPlan = () => {
    setShowAccountPlan(!showAccountPlan)
  }

  const enableClientClass = () => {
    setShowClientClass(!showClientClass)
  }

  const enableClientType = () => {
    setShowClientType(!showClientType)
  }

  const enableDocumentType = () => {
    setShowDocumentType(!showDocumentType)
  }  

  const enableEstablishment = () => {
    setShowEstablishment(!showEstablishment)
  }

  const enableFather = () => {
    setShowFather(!showFather)
  }

  const enablePaymentForm = () => {
    setShowPaymentForm(!showPaymentForm)
  }

  const enableRoute = () => {
    setShowRoute(!showRoute)
  }    

  const enableSeller = () => {
    setShowSeller(!showSeller)
  }

  const enableStock = () => {
    setShowStock(!showStock)
  }

  const fillAccountPlan = (accountPlan) => {
    setAccountPlanCode(accountPlan.ID)
    setAccountPlanDescription(accountPlan.DESCRIPTION)
  
    setShowAccountPlan(false)
  }

  const fillClientClass = (clas) => {
    setClientClassCode(clas.ID)
    setClientClassDescription(clas.DESCRIPTION)
  
    setShowClientClass(false)
  }

  const fillClientType = (type) => {
    setClientTypeCode(type.ID)
    setClientTypeDescription(type.DESCRIPTION)
  
    setShowClientType(false)
  }

  const fillDocumentType = (type) => {
    setDocumentTypeCode(type.ID)
    setDocumentTypeDescription(type.DESCRIPTION)
  
    setShowDocumentType(false)
  }

  const fillEstablishment = (establishment) => {
    setEstablishmentCode(establishment.ID)
    setEstablishmentDescription(establishment.DESCRIPTION)
  
    setShowEstablishment(false)
  }  

  const fillFather = (father) => {
    setFatherCode(father.ID)
    setFatherCorporateName(father.CORPORATE_NAME)
  
    setShowFather(false)
  }

  const fillPaymentForm = (form) => {
    setPaymentFormCode(form.ID)
    setPaymentFormDescription(form.DESCRIPTION)
  
    setShowPaymentForm(false)
  }  

  const fillRoute = (route) => {
    setRouteCode(route.ID)
    setRouteDescription(route.DESCRIPTION)
  
    setShowRoute(false)
  }  

  const fillSeller = (seller) => {
    setSellerCode(seller.ID)
    setSellerName(seller.NAME)
  
    setShowSeller(false)
  }

  const fillStock = (stock) => {
    setStockCode(stock.ID)
    setStockDescription(stock.DESCRIPTION)
  
    setShowStock(false)
  }    

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleContractValue = (event) => {
    setContractValue(formatNumeric(event.target.value, 2))
  }  

  const handleLimitPartialValue = (event) => {
    setLimitPartialValue(formatNumeric(event.target.value,2))
  }

  const handleLimitTotalValue = (event) => {
    setLimitTotalValue(formatNumeric(event.target.value,2))
  }

  const handleValueTransfer = (event) => {
    setValueTransfer(formatNumeric(event.target.value,2))
  }

  const save = () => {
    let returnJson = []
        
    setWarning("")
    setError("")

    let jsonClient_New = "{ \"CPF_CNPJ\": \""+ cpfCnpj +"\""

    if (((code !== undefined) && (code !== null)) && (code !== "")) jsonClient_New += ", \"ID\": \""+ code +"\""

    jsonClient_New += ", \"CORPORATE_NAME\": \""+ corporateName +"\""

    if (((fantasyName !== undefined) && (fantasyName !== null)) && (fantasyName !== "")) 
      jsonClient_New += ", \"FANTASY_NAME\": \""+ fantasyName +"\""
    else jsonClient_New += ", \"FANTASY_NAME\": \""+ corporateName +"\""

    if (((dateBirth !== undefined) && (dateBirth !== null)) && (dateBirth !== "")){
      const sDateBirth = dateBirth.substr(6, 4) + "-" + dateBirth.substr(3, 2) + "-" + dateBirth.substr(0, 2) + "T00:00:00"
      jsonClient_New += ", \"DATE_BIRTH\": \""+ sDateBirth +"\""
    }
  
    jsonClient_New += ", \"INDICATOR_IE\": "+ indicatorIe

    if (((ie !== undefined) && (ie !== null)) && (ie !== ""))
      jsonClient_New += ", \"IE\": \""+ ie +"\""; else jsonClient_New += ", \"IE\": \"ISENTO\""

    if (((im !== undefined) && (im !== null)) && (im !== "")) jsonClient_New += ", \"IM\": \""+ im +"\""
    if (((rg !== undefined) && (rg !== null)) && (rg !== "")) jsonClient_New += ", \"RG\": \""+ rg +"\""
    if (((cnae !== undefined) && (cnae !== null)) && (cnae !== "")) jsonClient_New += ", \"CNAE\": \""+ cnae +"\""
    if (((passaport !== undefined) && (passaport !== null)) && (passaport !== "")) jsonClient_New += ", \"PASSPORT\": \""+ passaport +"\""
    if (((mother !== undefined) && (mother !== null)) && (mother !== "")) jsonClient_New += ", \"MOTHER\": \""+ mother +"\""

    if (((responsibleCpf !== undefined) && (responsibleCpf !== null)) && (responsibleCpf !== "")) 
      jsonClient_New += ", \"RESPONSIBLE_CPF\": \""+ responsibleCpf +"\""

    if (((responsibleName !== undefined) && (responsibleName !== null)) && (responsibleName !== "")) 
      jsonClient_New += ", \"RESPONSIBLE_NAME\": \""+ responsibleName +"\""

    if (((suframa !== undefined) && (suframa !== null)) && (suframa !== "")) jsonClient_New += ", \"SUFRAMA\": \""+ suframa +"\""
    if (((cep !== undefined) && (cep !== null)) && (cep !== "")) jsonClient_New += ", \"CEP\": "+ cep

    jsonClient_New += ", \"ADDRESS\": \""+ address +"\""
    jsonClient_New += ", \"NUMBER\": \""+ number +"\""

    if (((complement !== undefined) && (complement !== null)) && (complement !== "")) 
      jsonClient_New += ", \"COMPLEMENT\": \""+ complement +"\""

    jsonClient_New += ", \"NEIGHBORHOOD\": \""+ neighborhood +"\""
    jsonClient_New += ", \"CITY\": \""+ city +"\""

    if (national) {
      if (((cityIbge !== undefined) && (cityIbge !== null)) && (cityIbge !== "")) jsonClient_New += ", \"CITY_IBGE\": "+ cityIbge
    } else jsonClient_New += ", \"CITY_IBGE\": "+ 9999999
        
    jsonClient_New += ", \"UF\": \""+ uf +"\""

    if (national) {
      if (((ufIbge !== undefined) && (ufIbge !== null)) && (ufIbge !== "")) jsonClient_New += ", \"UF_IBGE\": "+ ufIbge
    } else jsonClient_New += ", \"UF_IBGE\": "+ 99

    jsonClient_New += ", \"COUNTRY_DESCRIPTION\": \""+ countryDescription +"\""

    if (national) jsonClient_New += ", \"COUNTRY_IBGE\": "+ countryIbge
    else jsonClient_New += ", \"COUNTRY_IBGE\": "+ 9999

    if (((establishmentCode !== undefined) && (establishmentCode !== null)) && (establishmentCode !== ""))
      jsonClient_New += ", \"ESTABLISHMENT\": { \"ID\": "+ establishmentCode +", \"DESCRIPTION\": \""+ establishmentDescription +"\" }"

    if (((clientClassCode !== undefined) && (clientClassCode !== null)) && (clientClassCode !== ""))
        jsonClient_New += ", \"CLIENT_CLASS\": { \"ID\": "+ clientClassCode +", \"DESCRIPTION\": \""+ clientClassDescription +"\" }"

    if (((clientTypeCode !== undefined) && (clientTypeCode !== null)) && (clientTypeCode !== ""))
      jsonClient_New += ", \"CLIENT_TYPE\": { \"ID\": "+ clientTypeCode +", \"DESCRIPTION\": \""+ clientTypeDescription +"\" }"

    if (((fatherCode !== undefined) && (fatherCode !== null)) && (fatherCode !== ""))
      jsonClient_New += ", \"CLIENT_FATHER\": { \"ID\": "+ fatherCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"CORPORATE_NAME\": \""+ 
        fatherCorporateName +"\" }"

    if (((barCode !== undefined) && (barCode !== null)) && (barCode !== "")){
      const cBarCode = String(barCode).replaceAll("_","")

      if (((cBarCode !== undefined) && (cBarCode !== null)) && (cBarCode !== "")) jsonClient_New += ", \"BAR_CODE\": \""+ cBarCode +"\""
      else jsonClient_New += ", \"BAR_CODE\": \""+ cpfCnpj +"\""

    } else jsonClient_New += ", \"BAR_CODE\": \""+ cpfCnpj +"\""

    if (((passaport !== undefined) && (passaport !== null)) && (passaport !== "")) jsonClient_New += ", \"PASSWORD\": \""+ passaport +"\""
    else jsonClient_New += ", \"PASSWORD\": \"123\""
   
    if (((dueDay !== undefined) && (dueDay !== null)) && (dueDay !== "")){
      const cDueDay = String(dueDay).replaceAll("_","")
      if (((cDueDay !== undefined) && (cDueDay !== null)) && (cDueDay !== "")) jsonClient_New += ", \"DUE_DAY\": "+ cDueDay
    }

    if (((deliveryDay !== undefined) && (deliveryDay !== null)) && (deliveryDay !== "")){
      const cDeliveryDay = String(deliveryDay).replaceAll("_","")
      if (((cDeliveryDay !== undefined) && (cDeliveryDay !== null)) && (cDeliveryDay !== "")) jsonClient_New += ", \"DELIVERY_DAY\": "+ cDeliveryDay
    } 

    jsonClient_New += ", \"TOTAL_LIMIT_VALUE\": "+ limitTotalValue
    jsonClient_New += ", \"TOTAL_LIMIT_PARTIAL\": "+ limitPartialValue
    jsonClient_New += ", \"SHIPPING_TYPE\": "+ shippingType
    
    if (((domain !== undefined) && (domain !== null)) && (domain !== "")) jsonClient_New += ", \"DOMAIN\": \""+ domain + "\""

    if (((databasePathF !== undefined) && (databasePathF !== null)) && (databasePathF !== "")) 
      jsonClient_New += ", \"DATABASE_PATH\": \""+ databasePathF + "\""

    if (((databaseTypeF !== undefined) && (databaseTypeF !== null)) && (databaseTypeF !== "")) jsonClient_New += ", \"DATABASE_TYPE\": "+ databaseTypeF
    if (((systemType !== undefined) && (systemType !== null)) && (systemType !== "")) jsonClient_New += ", \"SYSTEM_TYPE\": "+ systemType
    if (((systemType !== undefined) && (systemType !== null)) && (systemType !== "")) jsonClient_New += ", \"SALE_TYPE\": "+ saleType

    if (((domainHomologation !== undefined) && (domainHomologation !== null)) && (domainHomologation !== ""))
      jsonClient_New += ", \"DOMAIN_HOMOLOGATION\": \""+ domainHomologation + "\""

    if (((databasePathHomologation !== undefined) && (databasePathHomologation !== null)) && (databasePathHomologation !== "")) 
      jsonClient_New += ", \"DATABASE_PATH_HOMOLOGATION\": \""+ databasePathHomologation + "\""

    if (((databaseTypeHomologation !== undefined) && (databaseTypeHomologation !== null)) && (databaseTypeHomologation !== "")) 
      jsonClient_New += ", \"DATABASE_TYPE_HOMOLOGATION\": "+ databaseTypeHomologation

    if (((systemTypeHomologation !== undefined) && (systemTypeHomologation !== null)) && (systemTypeHomologation !== ""))
      jsonClient_New += ", \"SYSTEM_TYPE_HOMOLOGATION\": "+ systemTypeHomologation

    if (((saleTypeHomologation !== undefined) && (saleTypeHomologation !== null)) && (saleTypeHomologation !== ""))
      jsonClient_New += ", \"SALE_TYPE_HOMOLOGATION\": "+ saleTypeHomologation

    jsonClient_New += ", \"CONTRACT_VALUE\": "+ contractValue
    jsonClient_New += ", \"AUTOMATIC_NFSE\": "+ automaticNfse
    jsonClient_New += ", \"HIGHLIGHT_RETAIN\": "+ highlightRetain

    if (((serviceCnaeCode !== undefined) && (serviceCnaeCode !== null)) && (serviceCnaeCode !== "")){
      jsonClient_New += ", \"SERVICE_CODE_CNAE\": {" 
      jsonClient_New += " \"CODE\": \""+ serviceCode +"\", \"DESCRIPTION\": \""+ serviceDescription +"\""

      if (((serviceCode !== undefined) && (serviceCode !== null)) && (serviceCode !== ""))
        jsonClient_New += ", \"SERVICE_CNAE\": { \"CODE\": \""+ serviceCnaeCode +"\", \"DESCRIPTION\": \""+ serviceCnaeDescription +"\" } "

      jsonClient_New += " }"
    }      

    jsonClient_New += ", \"NATURE_SERVICE\": "+ natureService
    jsonClient_New += ", \"PERCENTAGE_INSS\": "+ percentageInss
    jsonClient_New += ", \"PERCENTAGE_IR\": "+ percentageIr
    jsonClient_New += ", \"PERCENTAGE_CSLL\": "+ percentageCsll

    if (((registrationDate !== undefined) && (registrationDate !== null)) && (registrationDate !== "")){
      const sRegistrationDate = registrationDate.substr(6, 4) + "-" + registrationDate.substr(3, 2) + "-" + registrationDate.substr(0, 2) + "T00:00:00"
      jsonClient_New += ", \"REGISTRATION_DATE\": \""+ sRegistrationDate +"\""
    }

    if (((contractStartDate !== undefined) && (contractStartDate !== null)) && (contractStartDate !== "")){
      const sContractStartDate = contractStartDate.substr(6, 4) + "-" + contractStartDate.substr(3, 2) + "-" + 
        contractStartDate.substr(0, 2) + "T00:00:00"

      jsonClient_New += ", \"CONTRACT_START_DATE\": \""+ sContractStartDate +"\""
    }

    if (((reasonAcquisition !== undefined) && (reasonAcquisition !== null)) && (reasonAcquisition !== ""))
      jsonClient_New += ", \"REASON_ACQUISITION\": \""+ reasonAcquisition + "\""

    if (((contractExpirationDate !== undefined) && (contractExpirationDate !== null)) && (contractExpirationDate !== "")){
      const sContractExpirationDate = contractExpirationDate.substr(6, 4) + "-" + contractExpirationDate.substr(3, 2) + "-" + 
        contractExpirationDate.substr(0, 2) + "T00:00:00"

      jsonClient_New += ", \"CONTRACT_EXPIRATION_DATE\": \""+ sContractExpirationDate +"\""
    }

    if (((cancellationDate !== undefined) && (cancellationDate !== null)) && (cancellationDate !== "")){
      const sCancellationDate = cancellationDate.substr(6, 4) + "-" + cancellationDate.substr(3, 2) + "-" + cancellationDate.substr(0, 2) + "T00:00:00"

      jsonClient_New += ", \"CANCELLATION_DATE\": \""+ sCancellationDate +"\""
    }
  
    if (((reasonCancellation !== undefined) && (reasonCancellation !== null)) && (reasonCancellation !== ""))
      jsonClient_New += ", \"REASON_CANCELLATION\": \""+ reasonCancellation + "\""

    jsonClient_New += ", \"TYPE\": "+ type
    jsonClient_New += ", \"MARITAL_STATUS\": "+ maritalStatus
    jsonClient_New += ", \"GENDER\": "+ gender
    jsonClient_New += ", \"KIND_PERSON\": "+ kindPerson
    jsonClient_New += ", \"OPERATOR\": "+ operator
    jsonClient_New += ", \"FORM\": "+ form

    if (((routeCode !== undefined) && (routeCode !== null)) && (routeCode !== ""))
      jsonClient_New += ", \"CLIENT_ROUTE\": { \"ID\": "+ routeCode +", \"DESCRIPTION\": \""+ routeDescription +"\" }"

    jsonClient_New += ", \"INTEGRATE_SGE\": "+ integrateSge

    if (((domainSge !== undefined) && (domainSge !== null)) && (domainSge !== "")) jsonClient_New += ", \"DOMAIN_SGE\": \""+ domainSge + "\""
    if (((databaseSge !== undefined) && (databaseSge !== null)) && (databaseSge !== "")) jsonClient_New += ", \"DATABASE_SGE\": \""+ databaseSge + "\""

    jsonClient_New += ", \"TYPE_TRANSFER\": "+ typeTransfer

    if (((valueTransfer !== undefined) && (valueTransfer !== null)) && (valueTransfer !== "")) jsonClient_New += ", \"VALUE_TRANSFER\": "+ valueTransfer
    if (((observation !== undefined) && (observation !== null)) && (observation !== "")) jsonClient_New += ", \"OBSERVATION\": \""+ observation + "\""
    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) jsonClient_New += ", \"SGE\": "+ sge
     
    ////
    // Company Client
    ////
    let iCode = 0
    if (code !== "") iCode = parseInt(code)
    jsonClient_New += ", \"CLIENT_COMPANY\": { \"CLIENT\": { \"ID\": "+ iCode + " }"
      if (companyClientCode !== "") jsonClient_New += ", \"ID\": "+ companyClientCode

    jsonClient_New += ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }"
    
    if (((sellerCode !== undefined) && (sellerCode !== null)) && (sellerCode !== ""))
      jsonClient_New += ", \"SELLER\": { \"ID\": "+ sellerCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"NAME\": \""+ sellerName +"\" }"

    if (((stockCode !== undefined) && (stockCode !== null)) && (stockCode !== ""))
      jsonClient_New += ", \"STOCK\": { \"ID\": "+ stockCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"DESCRIPTION\": \""+ 
        stockDescription +"\" }"

    if (((paymentFormCode !== undefined) && (paymentFormCode !== null)) && (paymentFormCode !== ""))
      jsonClient_New += ", \"PAYMENT_FORM\": { \"ID\": "+ paymentFormCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ paymentFormDescription +"\" }"

    if (((documentTypeCode !== undefined) && (documentTypeCode !== null)) && (documentTypeCode !== ""))
      jsonClient_New += ", \"DOCUMENT_TYPE\": { \"ID\": "+ documentTypeCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +
        " }, \"DESCRIPTION\": \""+ documentTypeDescription +"\" }"

    if (((accountPlanCode !== undefined) && (accountPlanCode !== null)) && (accountPlanCode !== ""))
      jsonClient_New += ", \"ACCOUNT_PLAN\": { \"ID\": "+ accountPlanCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"DESCRIPTION\": \""+ 
        accountPlanDescription +"\" }"

    if (((((serviceCnaeCode !== undefined) && (serviceCnaeCode !== null)) && (serviceCnaeCode !== "")) ||
      (((serviceCode !== undefined) && (serviceCode !== null)) && (serviceCode !== ""))) ||
        (((serviceItemCode !== undefined) && (serviceItemCode !== null)) && (serviceItemCode !== ""))){

      jsonClient_New += ", \"SERVICE_ITEM_CNAE\": {"

      if (((serviceItemCode !== undefined) && (serviceItemCode !== null)) && (serviceItemCode !== ""))
        jsonClient_New += " \"CODE\": \""+ serviceItemCode +"\", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"DESCRIPTION\": \""+ 
          serviceItemDescription +"\", \"ALIQUOT\": "+ serviceItemAliquot

      if (((serviceCode !== undefined) && (serviceCode !== null)) && (serviceCode !== "")){
        jsonClient_New += ", \"SERVICE_CODE_CNAE\": {" 
        jsonClient_New += " \"CODE\": \""+ serviceCode +"\", \"DESCRIPTION\": \""+ serviceDescription +"\""

        if (((serviceCnaeCode !== undefined) && (serviceCnaeCode !== null)) && (serviceCnaeCode !== "")){
          jsonClient_New += ", \"SERVICE_CODE_CNAE\": {" 
          jsonClient_New += " \"SERVICE_CNAE\": { \"CODE\": \""+ serviceCnaeCode +"\", \"DESCRIPTION\": \""+ serviceCnaeDescription +"\" } "
          jsonClient_New += " }"          
        }

        jsonClient_New += " }"
      }
      else{
        if (((serviceCnaeCode !== undefined) && (serviceCnaeCode !== null)) && (serviceCnaeCode !== "")){
          jsonClient_New += ", \"SERVICE_CODE_CNAE\": {" 
          jsonClient_New += " \"SERVICE_CNAE\": { \"CODE\": \""+ serviceCnaeCode +"\", \"DESCRIPTION\": \""+ serviceCnaeDescription +"\" } "
          jsonClient_New += " }"
        }
      }
      
      jsonClient_New += " }"
    }

    if (((bankType !== undefined) && (bankType !== null)) && (bankType !== "")) jsonClient_New += ", \"BANK_TYPE\": "+ bankType
    if (((companyClientSge !== undefined) && (companyClientSge !== null)) && (companyClientSge !== "")) jsonClient_New += ", \"SGE\": "+ companyClientSge

    jsonClient_New += "}"
    ////

    jsonClient_New += ", \"SITUATION\": "+ situation + " }"

    if (code === ""){
      setLoading("Aguarde incluindo o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jsonClient_New)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "CLIENT/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o cliente. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("clientCpfCnpj") && document.getElementById("clientCpfCnpj").focus()
          return
        }

        returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Inclusão efetuada com sucesso!")

        setJClient(returnJson.OBJECT)
        setCode(returnJson.OBJECT.ID)
        setLoading("")
        setUpdateFocu(updateFocu + 1)        
      })
    }
    else{
      setLoading("Aguarde alterando o registro...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jClient))
      formData.append("JSON_NEW_1", jsonClient_New)
      formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "CLIENT/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o cliente. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          document.getElementById("clientCpfCnpj") && document.getElementById("clientCpfCnpj").focus()
          return
        }

        returnJson.WARNING ? setWarning("Alteração efetuada com sucesso! ("+ returnJson.WARNING +")")
        : setWarning("Alteração efetuada com sucesso!")

        setJClient(returnJson.OBJECT)
        setLoading("")
        setUpdateFocu(updateFocu + 1)
      })
    }
  }

  const searchCEP = () => {
    if (cep === "")
    {
      setLoading("")
      setWarning("")
      setError("É necessário informar o CEP.")

      return
    }

    setLoading("Aguarde buscando o CEP...")

    const apiCEP = axios.create({
      baseURL: "https://viacep.com.br/ws/"
    })

    apiCEP.get(cep + "/json/")
    .then((response) => {
      if (response.data.erro !== "true") {
        setWarning("")
        setError("")

        if ((response.data.logradouro === '') && (response.data.bairro === '')) setAddressRelease(true)
        else setAddressRelease(false)

        setAddress(response.data.logradouro)
        setNeighborhood(response.data.bairro)
        setCity(response.data.localidade)
        setCityIbge(response.data.ibge)
        setUf(response.data.uf)

        if (response.data.uf === "RO") setUfIbge(11)
        if (response.data.uf === "AC") setUfIbge(12)
        if (response.data.uf === "AM") setUfIbge(13)
        if (response.data.uf === "RR") setUfIbge(14)
        if (response.data.uf === "PA") setUfIbge(15)
        if (response.data.uf === "AP") setUfIbge(16)
        if (response.data.uf === "TO") setUfIbge(17)
        if (response.data.uf === "MA") setUfIbge(21)
        if (response.data.uf === "PI") setUfIbge(22)
        if (response.data.uf === "CE") setUfIbge(23)
        if (response.data.uf === "RN") setUfIbge(24)
        if (response.data.uf === "PB") setUfIbge(25)
        if (response.data.uf === "PE") setUfIbge(26)
        if (response.data.uf === "AL") setUfIbge(27)
        if (response.data.uf === "SE") setUfIbge(28)
        if (response.data.uf === "BA") setUfIbge(29)
        if (response.data.uf === "MG") setUfIbge(31)
        if (response.data.uf === "ES") setUfIbge(32)
        if (response.data.uf === "RJ") setUfIbge(33)
        if (response.data.uf === "SP") setUfIbge(35)
        if (response.data.uf === "PR") setUfIbge(41)
        if (response.data.uf === "SC") setUfIbge(42)
        if (response.data.uf === "RS") setUfIbge(43)
        if (response.data.uf === "MS") setUfIbge(50)
        if (response.data.uf === "MT") setUfIbge(51)
        if (response.data.uf === "GO") setUfIbge(52)
        if (response.data.uf === "DF") setUfIbge(53)
      } 
      else
      {
        setWarning("")
        setError("CEP não encontrado.")
        alert("CEP não encontrado.")

        setAddress("")
        setNeighborhood("")
        setCity("")
        setCityIbge("")
        setUf("")
        setUfIbge("")
      }
    })
    .catch(() => {
      setWarning("")
      setError("Não foi possível obter CEP.")
      alert("Não foi possível obter CEP.")
    }) 
    .finally(() => { setLoading("") })
  }

  const selectCnaeService = (item) => {
    if (item === "0")
    {
      let newItem = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}]}"
      setOptionsService(newItem)
      setOptionsItemService(newItem)

      setServiceCnaeCode("")
      setServiceCnaeDescription("")
      setServiceCode("")
      setServiceDescription("")
      setServiceItemCode("")
      setServiceItemDescription("")
      setServiceItemAliquot(0)
    }
    else
    {
      setServiceCnaeCode(item)
        
      jsonCnaeService &&
        jsonCnaeService.map(it => (
          (it.CODE === item) ? setServiceCnaeDescription(it.DESCRIPTION) : ""
        ))
            
      let optionsService = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
      jsonCodeService &&
        jsonCodeService.map(it => (
          (it.SERVICE_CNAE.CODE === item) ? 
            optionsService += ", {\"ID\": \""+ it.CODE +"\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}" : optionsService += ""
        ))
      optionsService += "]}"

      setOptionsService(optionsService)
    }
  }

  const selectCountry = (event) => {
    let itemP = {}
    listCountry.OBJECT.map(item => (
      (item.ID ===  parseInt(event)) ? itemP = item : ""
    ))

    setCountryCode(itemP.ID)
    setCountryIbge(itemP.IBGE)
    setCountryDescription(itemP.NAME)
  }

  const selectItemService = (item) => {        
    if (item === "0")
    {
      setServiceItemCode("")
      setServiceItemDescription("")
      setServiceItemAliquot(0)
    }
    else
    {
      setServiceItemCode(item)
        
      jsonItemService &&
        jsonItemService.map(it => (
          (it.CODE === item) && setServiceItemDescription(it.DESCRIPTION)
        ))

      jsonItemService &&
        jsonItemService.map(it => (
          (it.CODE === item) && setServiceItemAliquot(it.ALIQUOT)
        ))                
    }
  }    

  const selectService = (item) => {
    if (item === "0")
    {
      let newItem = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}]}"
      setOptionsItemService(newItem)

      setServiceCode("")
      setServiceDescription("")
      setServiceItemCode("")
      setServiceItemDescription("")
      setServiceItemAliquot(0)
    }
    else
    {
      setServiceCode(item)
        
      jsonCodeService &&
        jsonCodeService.map(it => (
          (it.CODE === String(item)) ? setServiceDescription(it.DESCRIPTION) : ""
        ))
            
      let optionsItemService = "{\"RESULT\": [{\"ID\": \"0\", \"VALUE\": \"Selecione...\"}"
      jsonItemService &&
        jsonItemService.map(it => (
          (it.SERVICE_CODE_CNAE.CODE === item) ? optionsItemService += ", {\"ID\": \""+ it.CODE +
            "\", \"VALUE\": \""+ it.CODE + " - " + it.DESCRIPTION +"\"}" : optionsItemService += ""
        ))
      optionsItemService += "]}"

      setOptionsItemService(optionsItemService)
    }
  }

  const setAdd = () => {
    setShowSearch(false)
    add()

    setUpdateFocu(updateFocu + 1)
  }

  const setFieldSelected = () => {
  }

  const setSelected = (event) => {
    setShowSearch(false)

    setShowAccountPlan(false)
    setShowClientClass(false)
    setShowClientType(false)
    setShowDocumentType(false)
    setShowEstablishment(false)
    setShowFather(false)
    setShowPaymentForm(false)
    setShowSeller(false)
    setShowStock(false)
    setShowRoute(false)

    setJClient(event)
    setCode(event.ID)
  }

  const previousAdd = () => {
    setWarning("")
    setError("")
    setCode("")

    setShowAccountPlan(false)
    setShowClientClass(false)
    setShowClientType(false)
    setShowDocumentType(false)
    setShowEstablishment(false)
    setShowFather(false)
    setShowPaymentForm(false)
    setShowSearch(true)
    setShowSeller(false)
    setShowStock(false)
    setShowRoute(false)

    setShowSearch(true)
  }    

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Cliente"
            titleSearch="Pesquisa dos Clientes"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchClient
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={setAdd}
                setSelected={setSelected}
                setReturn={setReturn} 
                showMenu={showMenu}
                width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      { !showAccountPlan && !showClientClass && !showClientType && !showDocumentType && !showEstablishment && !showFather &&
                        !showPaymentForm && !showSeller && !showStock && !showRoute &&

                        <>
                          <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>

                          <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                              paddingLeft: 10}}>
                              { warning && 
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                </div> 
                              }

                              { error &&
                                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                  marginBottom: 10}}>
                                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                </div>
                              }
                            </div>

                            { (code === "") &&
                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                  <Input
                                    type="text"
                                    name="clientCpfCnpj"
                                    description="CPF/CNPJ"
                                    planceHolder=""
                                    value={cpfCnpj}
                                    maxLength={14}
                                    disabled={false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCpfCnpj(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                                  <Select
                                    name="clientSituation"
                                    description="Situação"
                                    value={situation || 1}
                                    disabled={false}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            { (code !== "") &&
                              <div style={(showMenu) ?                                                                
                                  (width < 590) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={(showMenu) ? 
                                      (width < 390) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : (width < 590) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0,  minWidth: 150, maxWidth: 150}
                                      : (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0,  minWidth: 150, maxWidth: 150}
                                    :   
                                      (width < 390) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 1}                                  
                                      : {display: "flex", flexDirection: "row", flexGrow: 0,  minWidth: 150, maxWidth: 150}}>

                                    <Input
                                      type="text"
                                      name="clientCode"
                                      description="Código"
                                      planceHolder=""
                                      value={code}
                                      maxLength={11}
                                      disabled={true}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCode(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 390) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : (width < 590) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180,
                                        marginLeft: 10}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
                                      : (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180,
                                        marginLeft: 10}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
                                    :
                                      (width < 390) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180, marginTop: 10}
                                      : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180,
                                        marginLeft: 10}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

                                    <Input
                                      type="text"
                                      name="clientCpfCnpj"
                                      description="CPF/CNPJ"
                                      planceHolder=""
                                      value={cpfCnpj}
                                      maxLength={14}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCpfCnpj(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 390) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 590) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10}
                                    : (width < 850) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}
                                  : 
                                    (width < 390) ?  {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                                  <Select
                                    name="clientSituation"
                                    description="Situação"
                                    value={situation || 1}
                                    disabled={false}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Ativo\" }, {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setSituation} />
                                </div>
                              </div>
                            }

                            <div style={(showMenu) ?
                                (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :
                                (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                 <Input
                                   type="text"
                                   name="clientCorporateName"
                                   description="Nome/Razão social"
                                   planceHolder=""
                                   value={corporateName}
                                   maxLength={60}
                                   disabled={false}
                                   require={true}
                                   image={false}
                                   typeImage={0}
                                   typeInput={2}
                                   onSelect={setFieldSelected}
                                   onChange={(event) => setCorporateName(event.target.value)} />
                              </div>
                              <div style={(showMenu) ?
                                   (width < 1000) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                   : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                 :
                                   (width < 600) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginTop: 10, minWidth: 180}
                                   : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                 <Input
                                   type="text"
                                   name="clientFantasyName"
                                   description="Nome fantasia"
                                   value={fantasyName}
                                   maxLength={60}
                                   disabled={false}
                                   require={false}
                                   image={false}
                                   typeImage={0}
                                   typeInput={2}
                                   onSelect={setFieldSelected}
                                   onChange={(event) => setFantasyName(event.target.value)} />
                              </div>
                            </div>

                            <div style={(showMenu) ? 
                                (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              : 
                                (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 1}                              
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}
                                : 
                                  (width < 510) ? {display: "flex", flexDirection: "column", flexGrow: 1}                              
                                  : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={(showMenu) ?
                                    (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    : (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 510) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientIe"
                                    description="IE"
                                    value={ie}
                                    maxLength={14}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setIe(event.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180} 
                                    : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180} 
                                  :
                                    (width < 510) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Select
                                    name="clientIndicatorIe"
                                    description="Indicador IE"
                                    value={indicatorIe || 3}
                                    disabled={false}
                                    require={false}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Contribuinte do ICMS\" }"+
                                      ", {\"ID\": 2, \"VALUE\": \"Contribuinte Isento de ICMS\" }"+
                                        ", {\"ID\": 3, \"VALUE\": \"Não Contribuinte do ICMS\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setIndicatorIe} />
                                </div>                                
                              </div>

                              <div style={(showMenu) ?
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 1160) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                : 
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                  : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                <div style={showMenu ?
                                    (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 1160) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :  
                                    (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientIm"
                                    description="IM"
                                    value={im}
                                    maxLength={15}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setIm(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 1160) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 890) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                  <InputMasked
                                    name="clientDateBirth"
                                    description="Data do nascimento"
                                    planceHolder=""
                                    mask="99/99/9999"
                                    value={dateBirth}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setDateBirth(event.target.value)} />
                                </div>
                              </div>
                            </div>

                            {/* Múltipla */} {/* Geral ou Detetização */} {/* Licitação */} {/* Feira */} {/* Lene */}
                            { ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 4)) ||
                              (jsonCompany.TYPE === 5)) || (jsonCompany.TYPE === 6)) &&

                              <div style={showMenu ?
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    : (width < 850) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                  :
                                    (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientResponsibleCpf"
                                    description="CPF Responsável"
                                    value={responsibleCpf}
                                    maxLength={11}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setResponsibleCpf(event.target.value)} />
                                </div>
                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 850) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  :
                                    (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientResponsibleName"
                                    description="Nome Responsável"
                                    value={responsibleName}
                                    maxLength={60}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setResponsibleName(event.target.value)} />
                                </div>
                              </div>
                            }

                            {/* Jm3d */}
                            { jsonCompany.TYPE === 7 &&
                              <>
                                <div style={(showMenu) ? 
                                    (width < 1040) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 770) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : (width < 850) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientRg"
                                        description="RG"
                                        value={rg}
                                        maxLength={19}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setRg(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 850) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : 
                                        (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientMother"
                                        description="Mãe"
                                        value={mother}
                                        maxLength={60}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setMother(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1040) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 770) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                    <InputMasked
                                      name="clientRegistrationDate"
                                      description="Data do cadastro"
                                      mask="99/99/9999"
                                      value={registrationDate}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setRegistrationDate(event.target.value)} />
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    : (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : (width < 850) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="clientResponsibleCpf"
                                      description="CPF Responsável"
                                      value={responsibleCpf}
                                      maxLength={1}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setResponsibleCpf(event.target.value)} />
                                  </div>
                                  <div style={showMenu ?
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 850) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    :
                                      (width < 580) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="clientResponsibleName"
                                      description="Nome Responsável"
                                      value={responsibleName}
                                      maxLength={60}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setResponsibleName(event.target.value)} />
                                  </div>
                                </div>
                              </>
                            }

                            <div style={(showMenu) ?
                                (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :                 
                                (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={(showMenu) ?
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientPassaport"
                                    description="Passaporte"
                                    value={passaport}
                                    maxLength={20}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setPassaport(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Input
                                    type="text"
                                    name="clientCnae"
                                    description="CNAE"
                                    value={cnae}
                                    maxLength={7}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setCnae(event.target.value)} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                  : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                :
                                  (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                <div style={(showMenu) ?
                                    (width < 1200) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                      minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :                                   
                                    (width < 940) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                      minWidth: 180} 
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Input
                                    type="text"
                                    name="clientSuframa"
                                    description="SUFRAMA"
                                    value={suframa}
                                    maxLength={9}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setSuframa(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                        marginLeft: 10}
                                    : (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : (width < 1200) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                        marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 500) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 940) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                      marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Select
                                    name="clientType"
                                    description="Tipo"
                                    value={type || 1}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Consumidor final\" }"+
                                      ", {\"ID\": 2, \"VALUE\": \"Revenda\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setType} />
                                </div>
                              </div>
                            </div>

                            {/* Jm3d */}
                            { (jsonCompany.TYPE === 7) &&
                              <>
                                <div style={(showMenu) ?
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :                                     
                                      (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ?
                                        (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                      <InputMasked
                                        name="clientDueDay"
                                        description="Data do vencimento"
                                        mask={99}
                                        value={dueDay}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDueDay(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 630) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                      <Select
                                        name="clientMaritalStatus"
                                        description="Estado civil"
                                        value={maritalStatus || 0}
                                        disabled={false}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Solteiro\" }, {\"ID\": 2, \"VALUE\": \"Casado\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setMaritalStatus} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    :
                                      (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                    <Select
                                      name="clientGender"
                                      description="Sexo"
                                      value={gender || 0}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Masculino\" }, {\"ID\": 2, \"VALUE\": \"Feminino\" }] }")}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={setGender} />                           
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : 
                                    (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :                                     
                                      (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ?
                                        (width < 890) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      : 
                                        (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                      <Select
                                        name="clientKindPerson"
                                        description="Tipo pessoa"
                                        value={kindPerson || 0}
                                        disabled={false}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Titular\" }, {\"ID\": 2, \"VALUE\": \"Dependente\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setKindPerson} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 440) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 630) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                      <Select
                                        name="clientOperator"
                                        description="Operador"
                                        value={operator || 0}
                                        disabled={false}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setOperator} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    :
                                      (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                    <Select
                                      name="clientForm"
                                      description="Formulário"
                                      value={form || 0}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                        ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                      image={false}
                                      typeInput={2}
                                      typeImage={0}
                                      onSelect={setFieldSelected}
                                      set={setForm} />
                                  </div>
                                </div>
                              </>
                            }

                            {/* Múltipla */} {/* Geral ou Detetização */} {/* Feira */} {/* Lene */} {/* Jm3d */}
                            { ((((((jsonCompany.TYPE === 1) || (jsonCompany.TYPE === 2)) || (jsonCompany.TYPE === 3)) || (jsonCompany.TYPE === 5)) || 
                              (jsonCompany.TYPE === 6)) || (jsonCompany.TYPE === 7)) &&
                            
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setObservation(event.target.value)} />
                                </div>
                              </div>
                            }

                            {/* Licitação */}
                            { (jsonCompany.TYPE === 4) &&
                              <div style={(width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={(width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                  <Select
                                    name="clientShippingType"
                                    description="Tipo envio"
                                    value={shippingType || 2}
                                    disabled={false}
                                    require={true}
                                    options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Boleto\" }"+
                                      ", {\"ID\": 2, \"VALUE\": \"Pessoalmente\" }] }")}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    set={setShippingType} />
                                </div>
                                <div style={(width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                  <Input
                                    type="text"
                                    name="clientObservation"
                                    description="Observações"
                                    value={observation}
                                    maxLength={65535}
                                    disabled={false}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setObservation(event.target.value)} />
                                </div>
                              </div>
                            }

                            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                              <Titulo description="Endereço" />
                            </div>

                            <div style={(showMenu) ?
                                (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                : {paddingLeft: 10, paddingRight: 10}
                              : 
                                (width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                : {paddingLeft: 10, paddingRight: 10}}>

                              <div style={(showMenu) ?
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                :                                
                                  (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(showMenu) ?
                                    (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : 
                                    (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                  <div style={{display: "flex", flexGrow: 1}}>
                                    <Select
                                      name="clientCountryCode"
                                      description="País"
                                      value={countryCode || 0}
                                      disabled={false}
                                      require={false}
                                      options={JSON.parse(optionsCountries)}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      set={selectCountry} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 600) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                      : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}
                                    :   
                                      (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}                                 
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 140, maxWidth: 140}}>

                                    <Input
                                      type="text"
                                      name="clientCep"
                                      description="CEP"
                                      value={cep}
                                      maxLength={8}
                                      disabled={false}
                                      require={national ? true : false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setCep(event.target.value)} />
                                  </div>
                                </div>

                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : (width < 600) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginTop: 10}
                                    : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                    : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end"}}>

                                  { (national && (!addressRelease)) &&
                                    <img
                                      style={{height: 42, width: 42, marginLeft: 10}}
                                      name="clientSearchAddress"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={() => searchCEP()} />
                                  }

                                  <img
                                    style={{height: 42, width: 42, marginLeft: 10}}
                                    name="clientClearAddress"
                                    src={image_clean} 
                                    alt="Limpar" 
                                    onClick={clearAddress} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                : 
                                  (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientAddress"
                                    description="Logradouro"
                                    value={address}
                                    maxLength={60}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setAddress(event.target.value)} />
                                </div>

                                <div style={(showMenu) ?
                                    (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                  :
                                    (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                    : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                    :
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>

                                    <Input
                                      type="text"
                                      name="clientNumber"
                                      description="Número"
                                      value={number}
                                      maxLength={60}
                                      disabled={false}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setNumber(event.target.value)} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 980) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                    :
                                      (width < 400) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                    <Input
                                      type="text"
                                      name="clientComplement"
                                      description="Complemento"
                                      value={complement}
                                      maxLength={60}
                                      disabled={false}
                                      require={false}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2}
                                      onSelect={setFieldSelected}
                                      onChange={(event) => setComplement(event.target.value)} />
                                  </div>
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientNeighborhood"
                                    description="Bairro"
                                    value={neighborhood}
                                    maxLength={60}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setNeighborhood(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                                  <Input
                                    type="text"
                                    name="clientCity"
                                    description="Cidade"
                                    value={city}
                                    maxLength={60}
                                    disabled={national ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setCity(event.target.value)} />
                                </div>
                              </div>

                              <div style={(showMenu) ?
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  : (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :
                                  (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                  <Input
                                    type="text"
                                    name="clientUf"
                                    description="Estado"
                                    value={uf}
                                    maxLength={2}
                                    disabled={(national && (!addressRelease)) ? true : false}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setUf(event.target.value)} />
                                </div>
                                <div style={(showMenu) ?
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    : (width < 900) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :                                  
                                    (width < 650) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                                      
                                  <Input
                                    type="text"
                                    name="clientCountryDescription"
                                    description="País"
                                    value={countryDescription}
                                    maxLength={60}
                                    disabled={true}
                                    require={true}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2} 
                                    onSelect={setFieldSelected} 
                                    onChange={(event) => setCountryDescription(event.target.value)} />
                                </div>
                              </div>
                            </div>

                            <div style={(showMenu) ?
                                (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                              :   
                                (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                <Titulo description="Estabelecimento" />
                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="clientEstablishmentDescription"
                                      description="Descrição"
                                      value={establishmentDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="clientSearchEstablishment"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableEstablishment} />

                                    <img 
                                      style={{height: 42, width: 42, marginLeft: 10}}
                                      name="clientClearEstablishment"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={clearEstablishment} />
                                  </div>
                                </div>
                              </div>
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                :
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                <Titulo description="Tipo" />
                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="clientClientTypeDescription"
                                      description="Descrição"
                                      value={clientTypeDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                    <img 
                                      style={{height: 42, width: 42}}
                                      name="clientSearchClientType"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableClientType} />

                                    <img 
                                      style={{height: 42, width: 42, marginLeft: 10}}
                                      name="clientClearClientType"
                                      src={image_clean} 
                                      alt="Limpar" 
                                      onClick={clearClientType} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Múltipla */}
                            { jsonCompany.TYPE === 1 &&
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :   
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <Titulo description="Classe" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientClientClassDescription"
                                        description="Descrição"
                                        value={clientClassDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                        
                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchClientClass"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableClientClass} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearClientClass"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearClientClass} />
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Titulo description="Cobrança" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>

                                      <InputMasked
                                        type="text"
                                        name="clientDueDay"
                                        description="Dia Pagto"
                                        mask="99"
                                        value={dueDay}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDueDay(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10}}>

                                      <Select
                                        name="clientShippingType"
                                        description="Tipo Envio"
                                        value={shippingType || 2}
                                        require={true}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Boleto\" }"+
                                          ", {\"ID\": 2, \"VALUE\": \"Pessoalmente\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setShippingType} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Geral ou Detetização */}
                            { ((jsonCompany.TYPE === 2) || (jsonCompany.TYPE === 3)) &&
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :   
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <Titulo description="Classe" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientClientClassDescription"
                                        description="Descrição"
                                        value={clientClassDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchClientClass"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableClientClass} />

                                      <img
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearClientClass"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearClientClass} />
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Titulo description="Vendedor" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientSellerName"
                                        description="Nome"
                                        value={sellerName}
                                        maxLength={50}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchSeller"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableSeller} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearSeller"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={clearSeller} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Licitação */}
                            { jsonCompany.TYPE === 4 &&
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :   
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <Titulo description="Classe" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>                                  
                                      <Input
                                        type="text"
                                        name="clientClientClassDescription"
                                        description="Descrição"
                                        value={clientClassDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                        
                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchClientClass"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableClientClass} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearClientClass"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearClientClass} />
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Titulo description="Pai" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientFatherName"
                                        description="Nome"
                                        value={fatherCorporateName}
                                        maxLength={60}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img
                                        style={{height: 42, width: 42}}
                                        name="clientSearchFather"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableFather} />

                                      <img
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearFather"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearFather} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Feira ou Jm3d */}
                            { ((jsonCompany.TYPE === 5) || (jsonCompany.TYPE === 7)) &&
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                <Titulo description="Classe" />
                                <div style={(showMenu) ?
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                  : 
                                    (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                    <Input
                                      type="text"
                                      name="clientClientClassDescription"
                                      description="Descrição"
                                      value={clientClassDescription}
                                      maxLength={50}
                                      disabled={true}
                                      require={true}
                                      image={false}
                                      typeImage={0}
                                      typeInput={2} 
                                      onSelect={setFieldSelected} />
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                    :
                                      (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                  
                                    <img
                                      style={{height: 42, width: 42}}
                                      name="clientSearchClientClass"
                                      src={image_search} 
                                      alt="Pesquisar" 
                                      onClick={enableClientClass} />

                                    <img 
                                      style={{height: 42, width: 42, marginLeft: 10}}
                                      name="clientClearClientClass"
                                      src={image_clean} 
                                      alt="Limpar"
                                      onClick={clearClientClass} />
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Lene */}
                            { jsonCompany.TYPE === 6 &&
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :   
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <Titulo description="Classe" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>     
                                      <Input
                                        type="text"
                                        name="clientClientClassDescription"
                                        description="Descrição"
                                        value={clientClassDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={true}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                        
                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientEnableClientClass"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableClientClass} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearClientClass"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={clearClientClass} />
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Titulo description="Forma de Pagamento" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientPaymentFormDescription"
                                        description="Descrição"
                                        value={paymentFormDescription}
                                        maxLength={50}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>                                        

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchPaymentForm"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enablePaymentForm} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearPaymentForm"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={clearPaymentForm} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Múltipla */}
                            { jsonCompany.TYPE === 1 &&
                              <>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Dados contratuais" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : 
                                      (width < 650) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 1}                                      
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        : 
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                          : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}                                        
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <InputMasked
                                          type="text"
                                          name="clientContractStartDate"
                                          description="Dt. inicio contrato"
                                          mask="99/99/9999"
                                          value={contractStartDate}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setContractStartDate(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :                                        
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}                                        
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                                        <InputMasked
                                          type="text"
                                          name="clientContractExpirationDate"
                                          description="Dt. validade contrato"
                                          mask="99/99/9999"
                                          value={contractExpirationDate}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setContractExpirationDate(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :                                      
                                        (width < 650) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <InputNumeric
                                        type="text"
                                        name="clientContractValue"
                                        description="Valor do contrato"
                                        value={contractValue || ""}
                                        maxLength={13}
                                        disabled={false}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}                                        
                                        onSelect={setFieldSelected}
                                        onChange={handleContractValue} />
                                    </div>
                                  </div>
                                  
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientReasonAcquisition"
                                        description="Motivo da arquisição"
                                        value={reasonAcquisition}
                                        maxLength={255}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setReasonAcquisition(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 580) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                      <InputMasked
                                        type="text"
                                        name="clientCancellationDate"
                                        description="Data cancelamento"
                                        mask="99/99/9999"
                                        value={cancellationDate}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setCancellationDate(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 580) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Input
                                        type="text"
                                        name="clientReasonCancellation"
                                        description="Motivo cancelamento"
                                        value={reasonCancellation}
                                        maxLength={255}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setReasonCancellation(event.target.value)} />
                                    </div>
                                  </div>
                                </div>                              

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Cobrança Automática" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>
                                    
                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 850) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 750) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <Select
                                          name="clientAutomaticNfse"
                                          description="NFSe automática"
                                          value={automaticNfse || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setAutomaticNfse} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : (width < 750) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                          : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180,
                                            maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 180,
                                            maxWidth: 180}}>

                                        <Select
                                          name="clientHighlightRetain"
                                          description="Destacar/Reter"
                                          value={highlightRetain || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Destacar\" }, {\"ID\": 2, \"VALUE\": \"Reter\" }] }")}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setHighlightRetain} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 850) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                                      <Select
                                        name="clientNatureService"
                                        description="Natureza"
                                        value={natureService || 0}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Tributação no município\" }"+
                                          ", {\"ID\": 2, \"VALUE\": \"Tributação fora do município\" }"+
                                          ", {\"ID\": 3, \"VALUE\": \"Isenção\" }"+
                                          ", {\"ID\": 4, \"VALUE\": \"Imune\" }"+
                                          ", {\"ID\": 5, \"VALUE\": \"Exigibilidade suspensa por decisão judicial\" }"+
                                          ", {\"ID\": 6, \"VALUE\": \"Exigibilidade suspensa por procedimento administrativo\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setNatureService} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}> 
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="clientServiceCnaeCode"
                                        description="CNAE Serviço"
                                        value={serviceCnaeCode || 0}
                                        require={false}
                                        options={JSON.parse(optionsCnaeService)}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={selectCnaeService} />
                                    </div>
                                  </div>
                                  
                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="clientServiceCode"
                                        description="Código Serviço"
                                        value={serviceCode || 0}
                                        require={false}
                                        options={JSON.parse(optionsService)}
                                        image={false}
                                        typeInput={2}
                                        typeImage={0}
                                        onSelect={setFieldSelected}
                                        set={selectService} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="clientServiceItemCode"
                                        description="Item Serviço"
                                        value={serviceItemCode || 0}
                                        require={false}
                                        options={JSON.parse(optionsItemService)}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={selectItemService} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="clientServiceAliquot"
                                          description="Alíquota do serviço"
                                          value={ formatNumeric(serviceItemAliquot, 2) || "0" }
                                          maxLength={5}
                                          disabled={true}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setServiceItemAliquot(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                        <InputNumeric
                                          type="text"
                                          name="clientPercentageCsll"
                                          description="Porcentagem CSLL"
                                          value={ formatNumeric(percentageCsll, 2) || "0" }
                                          maxLength={5}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPercentageCsll(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}                                      
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="clientPercentageInss"
                                          description="Porcentagem INSS"
                                          value={ formatNumeric(percentageInss, 2) || "0" }
                                          maxLength={5}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPercentageInss(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                        <InputNumeric
                                          type="text"
                                          name="clientPercentageIr"
                                          description="Porcentagem IR"
                                          value={ formatNumeric(percentageIr, 2) || "0" }
                                          maxLength={5}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPercentageIr(event.target.value)} />
                                      </div>
                                    </div>
                                  </div>
                                </div>                                

                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :   
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                    <Titulo description="Tipo do Documento" />
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                      : 
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="clientDocumentTypeDescription"
                                          description="Descrição"
                                          value={documentTypeDescription}
                                          maxLength={50}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                        <img
                                          style={{height: 42, width: 42}}
                                          name="clientSearchDocumentType"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={enableDocumentType} />

                                        <img 
                                          style={{height: 42, width: 42, marginLeft: 10}}
                                          name="clientClearDocumentType"
                                          src={image_clean} 
                                          alt="Limpar"
                                          onClick={clearDocumentType} />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                    :
                                      (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                    <Titulo description="Plano de Conta" />
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                      : 
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="text"
                                          name="clientAccountPlanDescription"
                                          description="Descrição"
                                          value={accountPlanDescription}
                                          maxLength={255}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}                                          
                                          onSelect={setFieldSelected} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>                                          

                                        <img 
                                          style={{height: 42, width: 42}}
                                          name="clientSearchAccountPlan"
                                          src={image_search} 
                                          alt="Pesquisar" 
                                          onClick={enableAccountPlan} />

                                        <img 
                                          style={{height: 42, width: 42, marginLeft: 10}}
                                          name="clientClearAccountPlan"
                                          src={image_clean}
                                          alt="Limpar"
                                          onClick={clearAccountPlan} />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Sistema" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientDomain"
                                        description="Domínio"
                                        value={domain}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDomain(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Input
                                        type="text"
                                        name="clientDatabasePathF"
                                        description="BD"
                                        value={databasePathF}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDatabasePathF(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :                                    
                                      (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1}                                      
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <Select
                                          name="clientDatabaseTypeF"
                                          description="Tipo banco"
                                          value={databaseTypeF || 0}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"MySQL\" }, {\"ID\": 2, \"VALUE\": \"PostgreSQL\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setDatabaseTypeF} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
                                        :                                         
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}                                        
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

                                        <Select
                                          name="clientSystemType"
                                          description="Tipo sistema"
                                          value={systemType || 0}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Múltipla\" }, {\"ID\": 2, \"VALUE\": \"Geral\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Detetização\" }, {\"ID\": 4, \"VALUE\": \"Licitação\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Feira\" }, {\"ID\": 6, \"VALUE\": \"Lene\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Jm3d\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setSystemType} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Select
                                        name="clientSaleType"
                                        description="Tipo venda"
                                        value={saleType || 1}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Simples\" }, {\"ID\": 2, \"VALUE\": \"Pedido\" }"+
                                          ", {\"ID\": 3, \"VALUE\": \"Restaurante\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setSaleType} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientDomainHomologation"
                                        description="Domínio Hom."
                                        value={domainHomologation}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDomainHomologation(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Input
                                        type="text"
                                        name="clientDatabasePathHomologation"
                                        description="BD Hom."
                                        value={databasePathHomologation}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDatabasePathHomologation(event.target.value)} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :                                    
                                      (width < 680) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1}                                      
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(showMenu) ?
                                          (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                        <Select
                                          name="clientDatabaseTypeHomologation"
                                          description="Tipo banco Hom."
                                          value={databaseTypeHomologation || 1}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"MySQL\" }, {\"ID\": 2, \"VALUE\": \"PostgreSQL\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setDatabaseTypeHomologation} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}
                                        :                                         
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}                                        
                                          : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 180, maxWidth: 180, marginLeft: 10}}>

                                        <Select
                                          name="clientSystemTypeHomologation"
                                          description="Tipo sistema Hom."
                                          value={systemTypeHomologation || 2}
                                          require={false}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                            ", {\"ID\": 1, \"VALUE\": \"Múltipla\" }, {\"ID\": 2, \"VALUE\": \"Geral\" }"+
                                            ", {\"ID\": 3, \"VALUE\": \"Detetização\" }, {\"ID\": 4, \"VALUE\": \"Licitação\" }"+
                                            ", {\"ID\": 5, \"VALUE\": \"Feira\" }, {\"ID\": 6, \"VALUE\": \"Lene\" }"+
                                            ", {\"ID\": 7, \"VALUE\": \"Jm3d\" }] }")}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setSystemTypeHomologation} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 680) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Select
                                        name="clientSaleTypeHomologation"
                                        description="Tipo venda Hom."
                                        value={saleTypeHomologation || 1}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Simples\" }, {\"ID\": 2, \"VALUE\": \"Pedido\" }"+
                                          ", {\"ID\": 3, \"VALUE\": \"Restaurante\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setSaleTypeHomologation} />
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <Select
                                        name="clientIntegrateSge"
                                        description="Integra SGE"
                                        value={integrateSge || 2}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setIntegrateSge} />
                                    </div>
                                  </div>

                                  <div style={(showMenu) ?
                                      (width < 900) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientDomainSge"
                                        description="Domínio SGE"
                                        value={domainSge}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDomainSge(event.target.value)} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <Input
                                        type="text"
                                        name="clientDatabaseSge"
                                        description="BD SGE"
                                        value={databaseSge}
                                        maxLength={50}
                                        disabled={false}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setDatabaseSge(event.target.value)} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                            {/* Geral ou Detetização */}
                            { ((jsonCompany.TYPE === 2) || (jsonCompany.TYPE === 3)) &&
                              <> 
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Rota" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientRouteDescription"
                                        description="Descrição"
                                        value={routeDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>
                                        
                                      <img
                                        style={{height: 42, width: 42}}
                                        name="clientSearchRoute"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableRoute} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearRoute"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearRoute} />
                                    </div>
                                  </div>
                                </div>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Cobrança" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :                                     
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                                        <InputMasked
                                          type="text"
                                          name="clientBarCode"
                                          description="Códido de barras"
                                          mask="99999999999999"
                                          value={barCode}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setBarCode(event.target.value)} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :                                         
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                        <Input
                                          type="text"
                                          name="clientPassword"
                                          description="Senha"
                                          value={password}
                                          maxLength={6}
                                          disabled={false}
                                          require={false}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={(event) => setPassword(event.target.value)} />
                                      </div>
                                    </div>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                      : 
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :                                         
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10}                                        
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                            
                                        <InputNumeric
                                          type="text"
                                          name="clientLimitTotalValue"
                                          description="Valor Limite Total"
                                          value={limitTotalValue || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleLimitTotalValue} />
                                      </div>
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                            marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                        :                                        
                                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 780) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginTop: 10,
                                            marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                        <InputNumeric
                                          type="text"
                                          name="clientLimitPartialValue"
                                          description="Valor Limite Parcial"
                                          value={limitPartialValue || ""}
                                          maxLength={13}
                                          disabled={true}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleLimitPartialValue} />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="clientShippingType"
                                        description="Tipo envio"
                                        value={shippingType || 2}
                                        require={true}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Boleto\" }"+
                                          ", {\"ID\": 2, \"VALUE\": \"Pessoalmente\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setShippingType} />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                            {/* Lene */}
                            { jsonCompany.TYPE === 6 &&
                              <div style={(showMenu) ?
                                  (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                :   
                                  (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}                            
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                  <Titulo description="Rota" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientRouteDescription"
                                        description="Descrição"
                                        value={routeDescription}
                                        maxLength={255}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchRoute"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableRoute} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearRoute"
                                        src={image_clean} 
                                        alt="Limpar" 
                                        onClick={clearRoute} />
                                    </div>
                                  </div>
                                </div>
                                <div style={(showMenu) ?
                                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                                  :
                                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10, minWidth: 180}
                                    : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                  <Titulo description="Estoque" />
                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                                    : 
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>

                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="clientStockDescription"
                                        description="Descrição"
                                        value={stockDescription}
                                        maxLength={100}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />

                                    </div>
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                      <img 
                                        style={{height: 42, width: 42}}
                                        name="clientSearchStock"
                                        src={image_search} 
                                        alt="Pesquisar" 
                                        onClick={enableStock} />

                                      <img 
                                        style={{height: 42, width: 42, marginLeft: 10}}
                                        name="clientClearStock"
                                        src={image_clean} 
                                        alt="Limpar"
                                        onClick={clearStock} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Múltipla */}
                            { jsonCompany.TYPE === 1 &&
                              <>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, minWidth: 180}}>
                                  <Titulo description="Dados Bancários" />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                                  : {paddingLeft: 10, paddingRight: 10}}>

                                  <div style={showMenu ? 
                                      (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 950) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, minWidth: 180}
                                      :
                                        (width < 630) ? {display: "flex", flexGrow: 1}
                                        : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Select
                                        name="clientBankType"
                                        description="Banco Digital"
                                        value={bankType}
                                        require={false}
                                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                                          ", {\"ID\": 1, \"VALUE\": \"Mentes que Pensam\" }, {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
                                        image={false}
                                        typeImage={0}
                                        typeInput={2}
                                        onSelect={setFieldSelected}
                                        set={setBankType} />
                                    </div>

                                    <div style={showMenu ? 
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 950) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                        : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1}
                                          : (width < 950) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1}
                                          : (width < 630) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                                        <Select
                                          name="clientTypeTransfer"
                                          description="Tipo do Repasse"
                                          value={typeTransfer || 1}
                                          require={true}
                                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sem\" }"+
                                            ", {\"ID\": 2, \"VALUE\": \"Percentual\" }, {\"ID\": 3, \"VALUE\": \"Valor\" }] }")}
                                          image={false}
                                          typeImage={0}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          set={setTypeTransfer} />
                                      </div>
                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                          : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                        <InputNumeric
                                          type="text"
                                          name="clientValueTransfer"
                                          description="Valor do Repasse"
                                          value={valueTransfer || ""}
                                          maxLength={13}
                                          disabled={false}
                                          require={false}
                                          negative={false} 
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected}
                                          onChange={handleValueTransfer} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                            {/* phones // telefones */}
                            { code &&
                              <div style={{marginTop: 10, minWidth: 180}}>
                                <Titulo description="Telefones" />

                                <SearchClientPhone
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jClient={jClient}
                                  jsonCompany={jsonCompany} 
                                  jsonLogin={jsonLogin}
                                  system={system} 
                                  showMenu={showMenu} 
                                  width={width} />
                              </div>
                            }

                            {/* emails */}
                            { code &&
                              <div style={{marginTop: 10, minWidth: 180}}>
                                <Titulo description="Emails" />

                                <SearchClientEmail
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jClient={jClient}
                                  jsonCompany={jsonCompany} 
                                  jsonLogin={jsonLogin}
                                  system={system} 
                                  showMenu={showMenu} 
                                  width={width} />
                              </div>
                            }

                            {/* Geral ou Detetização */}
                            { ((jsonCompany.TYPE === 2) || (jsonCompany.TYPE === 3)) &&
                              <> 
                                { code &&
                                  <div style={{marginTop: 10, minWidth: 180}}>
                                    <Titulo description="Preços Diferenciados" />

                                    <SearchClientProduct
                                      databaseType={databaseType}
                                      databasePath={databasePath}
                                      jClient={jClient}
                                      jsonCompany={jsonCompany} 
                                      jsonLogin={jsonLogin}
                                      system={system}
                                      showMenu={showMenu}
                                      width={width} />
                                  </div>
                                }
                              </>
                            }

                            {/* Jm3d */}
                            { jsonCompany.TYPE === 7 &&
                              <>
                                {/* branch // ramos */}
                                { code &&
                                  <div style={{marginTop: 10, minWidth: 180}}>
                                    <Titulo description="Ramos" />

                                    <SearchClientBranch
                                      databaseType={databaseType}
                                      databasePath={databasePath}
                                      jsonClient={jClient}
                                      jsonCompany={jsonCompany} 
                                      jsonLogin={jsonLogin}
                                      system={system} 
                                      showMenu={showMenu}
                                      width={width} />
                                  </div>
                                }

                                {/* contact // contato */}
                                { code &&
                                  <div style={{marginTop: 20, minWidth: 180}}>
                                    <Titulo description="Contatos" />
                                    <SearchClientContact
                                      databaseType={databaseType}
                                      databasePath={databasePath}
                                      jClient={jClient}
                                      jsonCompany={jsonCompany} 
                                      jsonLogin={jsonLogin}
                                      system={system} 
                                      showMenu={showMenu}
                                      width={width} />
                                  </div>
                                }
                              </>
                            }

                          </div>                        
                        </>
                      }

                      { showClientClass &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <label style={{fontWeight: 600}}>Selecione a classe:</label>
                          </div>

                          <SearchClientClass
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}                            
                            jsonCompany={jsonCompany}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillClientClass}
                            setReturn={() => setShowClientClass(false)}
                            width={width} />
                        </div>
                      }

                      { showEstablishment &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o estabelecimento:</b>
                          </div>

                          <SearchEstablishment
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillEstablishment} 
                            setReturn={() => setShowEstablishment(false)}
                            width={width} />
                        </div>
                      }

                      { showStock && 
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o estoque:</b>
                          </div>

                          <SearchStock
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillStock} 
                            setReturn={() => setShowStock(false)} 
                            width={width} />
                        </div>
                      }

                      { showPaymentForm && 
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione a forma de pagamento:</b>
                          </div>

                          <SearchPaymentForm
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillPaymentForm}
                            setReturn={() => setShowPaymentForm(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showFather &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o pai:</b>
                          </div>

                          <SearchFather
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillFather} 
                            setReturn={() => setShowFather(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showAccountPlan &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o plano de conta:</b>
                          </div>

                          <SearchAccountPlan
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillAccountPlan}
                            setReturn={() => setShowAccountPlan(false)}
                            type={98}
                            periodic={1} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }

                      { showRoute &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione a rota:</b>
                          </div>

                          <SearchClientRoute
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillRoute} 
                            setReturn={() => setShowRoute(false)} 
                            width={width} />
                        </div>
                      }

                      { showClientType &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o tipo:</b>
                          </div>

                          <SearchClientType
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillClientType}
                            setReturn={() => setShowClientType(false)} 
                            width={width} />
                        </div>
                      }

                      { showDocumentType &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o tipo de documento:</b>
                          </div>

                          <SearchDocumentType
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonLogin={jsonLogin}
                            jsonCompany={jsonCompany}                            
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillDocumentType}
                            setReturn={() => setShowDocumentType(false)} 
                            width={width} />
                        </div>
                      }

                      { showSeller &&
                        <div style={{marginTop: 10, minWidth: 180}}>
                          <div style={{marginLeft: 20}}>
                            <b>Selecione o vendedor:</b>
                          </div>

                          <SearchSeller
                            modeSearch={true}
                            databaseType={databaseType}
                            databasePath={databasePath}
                            jsonCompany={jsonCompany}
                            jsonLogin={jsonLogin}
                            system={system}
                            setAdd={() => alert("")}
                            setSelected={fillSeller} 
                            setReturn={() => setShowSeller(false)} 
                            showMenu={showMenu}
                            width={width} />
                        </div>
                      }
                    </>
                }
              </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Client