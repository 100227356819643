import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Barcode from 'react-barcode';
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import QRcode from 'qrcode.react'
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import image_cancel from "../../Images/Buttons/cancel.png"
import image_extend from "../../Images/Buttons/extend.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_send_email from "../../Images/Buttons/send_email.png"
import image_select from "../../Images/Buttons/select.png"
import image_printer from "../../Images/Buttons/printer.png"
// import image_protest from "../../Images/Buttons/protest.png"

const Billet = ({databaseType, databasePath, jsonCompany, jsonLogin, system, jsonBillet, save, issue, lower, cancel, resendEmail, extend, 
  protest, previous, showMenu, width}) => {

  const [code, setCode] = useState(undefined)

  const [companyCode, setCompanyCode] = useState("")
  const [companyRegistration, setCompanyRegistration] = useState("")  
  const [companyCpfCnpj, setCompanyCpfCnpj] = useState("")
  const [companyCorporateName, setCompanyCorporateName] = useState("")
  const [companyReponsibleCpf, setCompanyReponsibleCpf] = useState("")  

  const [billetConfigurationCode, setBilletConfigurationCode] = useState(0)
  const [billetConfigurationBankType, setBilletConfigurationBankType] = useState(0)
  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")
  const [accountParticipantCode, setAccountParticipantCode] = useState("")

  const [billReceiveCode, setBillReceiveCode] = useState("")

  const [clientRegistration, setClientRegistration] = useState("")
  const [clientCpfCnpj, setClientCpfCnpj] = useState("")
  const [clientCorporateName, setClientCorporateName] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [clientNumber, setClientNumber] = useState("")
  const [clientCep, setClientCep] = useState("")
  const [clientCity, setClientCity] = useState("")
  const [clientNeighborhood, setClientNeighborhood] = useState("")
  const [clientUf, setClientUf] = useState("")
  const [clientReponsibleCpf, setClientReponsibleCpf] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientEmail, setClientEmail] = useState("")

  const [emissionDate, setEmissionDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [originalValue, setOriginalValue] = useState(0)
  const [rebateValue, setRebateValue] = useState(0)
  const [quantityDayProtest, setQuantityDayProtest] = useState(0)
  const [quantityDayNegative, setQuantityDayNegative] = useState(0)
  const [negativeOrgan, setNegativeOrgan] = useState(0)
  const [acceptsExpired, setAcceptsExpired] = useState(2)
  const [daysLimitReceiving, setDaysLimitReceiving] = useState(0)
  const [codeAcceptance, setCodeAcceptance] = useState(0)
  const [titleType, setTitleType] = useState(0)
  const [description, setDescription] = useState("")
  const [partialReceipt, setPartialReceipt] = useState(0)
  const [number, setNumber] = useState("")
  const [informationAdditional, setInformationAdditional] = useState("")
  const [message, setMessage] = useState("")
  const [discountType, setDiscountType] = useState(0)
  const [expirationDateDiscount, setExpirationDateDiscount] = useState("")
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [jurosType, setJurosType] = useState(0)
  const [jurosPercentage, setJurosPercentage] = useState(0)
  const [jurosValue, setJurosValue] = useState(0)
  const [penaltyType, setPenaltyType] = useState(0)
  const [penaltyDate, setPenaltyDate] = useState("")
  const [penaltyPercentage, setPenaltyPercentage] = useState("")
  const [penaltyValue, setPenaltyValue] = useState(0)
  const [softwareCnpj, setSoftwareCnpj] = useState("")
  const [generateEmail, setGenerateEmail] = useState(0)
  const [generateSms, setGenerateSms] = useState(0)
  const [generatePix, setGeneratePix] = useState(0)
  const [tariffBillet, setTariffBillet] = useState(0)
  const [tariffPix, setTariffPix] = useState(0)
  const [ruler, setRuler] = useState("")
  const [valueTransfer, setValueTransfer] = useState("")
  const [internal, setInternal] = useState("")
  const [internalNumber, setInternalNumber] = useState("")
  const [typeableLine, setTypeableLine] = useState("")
  const [barCode, setBarCode] = useState("")
  const [urlPdf, setUrlPdf] = useState("")
  const [hashPix, setHashPix] = useState("")
  const [qrcodePix, setQrcodePix] = useState("")
  const [url, setUrl] = useState("")
  const [paymentDate, setPaymentDate] = useState("")
  const [cancellationDate, setCancellationDate] = useState("")
  const [environment, setEnvironment] = useState(0)
  const [situation, setSituation] = useState(0)

  const [resultBilletConfiguration, setResultBilletConfiguration] = useState({})
  const [optionsBilletConfiguration, setOptionsBilletConfiguration] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [firstAcess, setFirstAcess] = useState(true)

  const [extension, setExtension] = useState(false)
  const [extensionDate, setExtensionDate] = useState("")
  const [protes, setProtes] = useState(false)
  const [newProtest, setNewProtest] = useState("")

  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if (jsonBillet.ID !== undefined){
      setCode(jsonBillet.ID)
      setBillReceiveCode(jsonBillet.BILL_RECEIVE.ID)
      setCompanyCode(jsonBillet.BILL_RECEIVE.COMPANY.ID)
      setCompanyCpfCnpj(jsonBillet.BILL_RECEIVE.COMPANY.CPF_CNPJ)
      setCompanyCorporateName(jsonBillet.BILL_RECEIVE.COMPANY.CORPORATE_NAME)
      setCompanyReponsibleCpf(jsonBillet.BILL_RECEIVE.COMPANY.RESPONSIBLE_CPF)

      setClientCpfCnpj(jsonBillet.BILL_RECEIVE.CLIENT.CPF_CNPJ)
      setClientCorporateName(jsonBillet.BILL_RECEIVE.CLIENT.CORPORATE_NAME)
      setClientAddress(jsonBillet.BILL_RECEIVE.CLIENT.ADDRESS)
      setClientCep(jsonBillet.BILL_RECEIVE.CLIENT.CEP)
      setClientCity(jsonBillet.BILL_RECEIVE.CLIENT.CITY)
      setClientNeighborhood(jsonBillet.BILL_RECEIVE.CLIENT.NEIGHBORHOOD)
      setClientUf(jsonBillet.BILL_RECEIVE.CLIENT.UF)
      
      if ((jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF !== null)) 
        setClientReponsibleCpf(jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF)

      if ((jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== null)) 
        setClientPhone(jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE)

      if ((jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== null)) 
        setClientEmail(jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL)

      setBilletConfigurationCode(jsonBillet.BILLET_CONFIGURATION.ID)
      setBilletConfigurationBankType(jsonBillet.BILLET_CONFIGURATION.BANK_TYPE)
      setAccountCode(jsonBillet.BILLET_CONFIGURATION.ACCOUNT.ID)
      setAccountNumber(jsonBillet.BILLET_CONFIGURATION.ACCOUNT.NUMBER)
      setAccountOwner(jsonBillet.BILLET_CONFIGURATION.ACCOUNT.OWNER)

      if (((jsonBillet.BILLET_CONFIGURATION.ACCOUNT.PARTICIPANT_CODE !== undefined) && 
        (jsonBillet.BILLET_CONFIGURATION.ACCOUNT.PARTICIPANT_CODE !== null)) && 
          (jsonBillet.BILLET_CONFIGURATION.ACCOUNT.PARTICIPANT_CODE !== 0)){
            setAccountParticipantCode(jsonBillet.BILLET_CONFIGURATION.ACCOUNT.PARTICIPANT_CODE)
      }

      let sEmissionDate = jsonBillet.EMISSION_DATE.substr(8, 2) + "/" + jsonBillet.EMISSION_DATE.substr(5, 2) + "/" + 
        jsonBillet.EMISSION_DATE.substr(0, 4) + "T" + jsonBillet.EMISSION_DATE.substr(11, 8)
      setEmissionDate(sEmissionDate)

      let sDueDate = jsonBillet.DUE_DATE.substr(8, 2) + "/" + jsonBillet.DUE_DATE.substr(5, 2) + "/" + jsonBillet.DUE_DATE.substr(0, 4) + " " + 
        jsonBillet.DUE_DATE.substr(11, 9)
      setDueDate(sDueDate)

      setOriginalValue(formatNumeric(jsonBillet.ORIGINAL_VALUE, 2))
      setRebateValue(formatNumeric(jsonBillet.REBATE_VALUE, 2))
      setQuantityDayProtest(jsonBillet.QUANTITY_DAY_PROTEST)
      setQuantityDayNegative(jsonBillet.QUANTITY_DAY_NEGATIVE)
      setNegativeOrgan(jsonBillet.NEGATIVE_ORGAN)
      setAcceptsExpired(jsonBillet.ACCEPTS_EXPIRED)
      setDaysLimitReceiving(jsonBillet.DAYS_LIMIT_RECEIVING)
      setCodeAcceptance(jsonBillet.CODE_ACCEPTANCE)
      setTitleType(jsonBillet.TITLE_TYPE)
      setDescription(jsonBillet.DESCRIPTION)
      setPartialReceipt(jsonBillet.PARTIAL_RECEIPT)
      setNumber(jsonBillet.NUMBER)

      if ((jsonBillet.INFORMATION_ADDITIONAL !== undefined) && (jsonBillet.INFORMATION_ADDITIONAL !== null)) 
        setInformationAdditional(jsonBillet.INFORMATION_ADDITIONAL);

      if ((jsonBillet.MESSAGE !== undefined) && (jsonBillet.MESSAGE !== null)) setMessage(jsonBillet.MESSAGE);

      setDiscountType(jsonBillet.DISCOUNT_TYPE)

      if ((jsonBillet.EXPIRATION_DATE_DISCOUNT !== undefined) && (jsonBillet.EXPIRATION_DATE_DISCOUNT !== null)){
        if (jsonBillet.EXPIRATION_DATE_DISCOUNT !== "0001-01-01T00:00:00"){
          let sExpirationDateDiscount = jsonBillet.EXPIRATION_DATE_DISCOUNT.substr(8, 2) + "/" + jsonBillet.EXPIRATION_DATE_DISCOUNT.substr(5, 2) + "/" +
            jsonBillet.EXPIRATION_DATE_DISCOUNT.substr(0, 4) + " " + jsonBillet.EXPIRATION_DATE_DISCOUNT.substr(11, 8)
          setExpirationDateDiscount(sExpirationDateDiscount)
        }
      }

      setDiscountPercentage(formatNumeric(jsonBillet.DISCOUNT_PERCENTAGE, 2))
      setDiscountValue(formatNumeric(jsonBillet.DISCOUNT_VALUE, 2))
      setJurosType(jsonBillet.JUROS_TYPE)
      setJurosPercentage(formatNumeric(jsonBillet.JUROS_PERCENTAGE, 2))
      setJurosValue(formatNumeric(jsonBillet.JUROS_VALUE, 2))
      setPenaltyType(jsonBillet.PENALTY_TYPE)

      if ((jsonBillet.PENALTY_DATE !== undefined) && (jsonBillet.PENALTY_DATE !== null)){
        if (jsonBillet.PENALTY_DATE !== "0001-01-01T00:00:00"){
          let sPenaltyDate = jsonBillet.PENALTY_DATE.substr(8, 2) + "/" + jsonBillet.PENALTY_DATE.substr(5, 2) + "/" + 
            jsonBillet.PENALTY_DATE.substr(0, 4) + " " + jsonBillet.PENALTY_DATE.substr(11, 8)
          setPenaltyDate(sPenaltyDate)
        }
      }
 
      setPenaltyPercentage(formatNumeric(jsonBillet.PENALTY_PERCENTAGE, 2))
      setPenaltyValue(formatNumeric(jsonBillet.PENALTY_VALUE, 2))
      setClientRegistration(jsonBillet.REGISTRATION_CLIENT)
      setCompanyRegistration(jsonBillet.REGISTRATION_COMPANY)      
      setSoftwareCnpj(jsonBillet.CNPJ_SOFTWARE)
      setGenerateEmail(jsonBillet.GENERATE_EMAIL)
      setGenerateSms(jsonBillet.GENERATE_SMS)
      setGeneratePix(jsonBillet.GENERATE_PIX)
      setTariffBillet(formatNumeric(jsonBillet.TARIFF_BILLET, 2))
      setTariffPix(formatNumeric(jsonBillet.TARIFF_PIX, 2))
      
      if ((jsonBillet.VALUE_TRANSFER !== undefined) && (jsonBillet.VALUE_TRANSFER !== null)) setValueTransfer(jsonBillet.VALUE_TRANSFER)
      if ((jsonBillet.RULER !== undefined) && (jsonBillet.RULER !== null)) setRuler(jsonBillet.RULER)
      if ((jsonBillet.INTERNAL !== undefined) && (jsonBillet.INTERNAL !== null)) setInternal(jsonBillet.INTERNAL);
      if ((jsonBillet.INTERNAL_NUMBER !== undefined) && (jsonBillet.INTERNAL_NUMBER !== null)) setInternalNumber(jsonBillet.INTERNAL_NUMBER);
      if ((jsonBillet.TYPEABLE_LINE !== undefined) && (jsonBillet.TYPEABLE_LINE !== null)) setTypeableLine(jsonBillet.TYPEABLE_LINE);
      if ((jsonBillet.BAR_CODE !== undefined) && (jsonBillet.BAR_CODE !== null)) setBarCode(jsonBillet.BAR_CODE);
      if ((jsonBillet.URL_PDF !== undefined) && (jsonBillet.URL_PDF !== null)) setUrlPdf(jsonBillet.URL_PDF);
      if ((jsonBillet.HASH_PIX !== undefined) && (jsonBillet.HASH_PIX !== null)) setHashPix(jsonBillet.HASH_PIX);
      if ((jsonBillet.QRCODE_PIX !== undefined) && (jsonBillet.QRCODE_PIX !== null)) setQrcodePix(jsonBillet.QRCODE_PIX);
      if ((jsonBillet.URL !== undefined) && (jsonBillet.URL !== null)) setUrl(jsonBillet.URL);

      if ((jsonBillet.PAYMENT_DATE !== undefined) && (jsonBillet.PAYMENT_DATE !== null)){
        if (jsonBillet.PAYMENT_DATE !== "0001-01-01T00:00:00"){
          let sPaymentDate = jsonBillet.PAYMENT_DATE.substr(8, 2) + "/" + jsonBillet.PAYMENT_DATE.substr(5, 2) + "/" + 
            jsonBillet.PAYMENT_DATE.substr(0, 4) + " " + jsonBillet.PAYMENT_DATE.substr(11, 8)
          setPaymentDate(sPaymentDate)
        }
      } 
            
      if ((jsonBillet.CANCELLATION_DATE !== undefined) && (jsonBillet.CANCELLATION_DATE !== null)){
        if (jsonBillet.CANCELLATION_DATE !== "0001-01-01T00:00:00"){
          let sCancellationDate = jsonBillet.CANCELLATION_DATE.substr(8, 2) + "/" + jsonBillet.CANCELLATION_DATE.substr(5, 2) + "/" + 
            jsonBillet.CANCELLATION_DATE.substr(0, 4) + " " + jsonBillet.CANCELLATION_DATE.substr(11, 8)
          setCancellationDate(sCancellationDate)
        }
      }

      setEnvironment(jsonBillet.ENVIRONMENT)
      setSituation(jsonBillet.SITUATION)
    }
    else {
      if (firstAcess){
        ////
        // Carrega as configurações dos boletos
        ////
        let returnJson = []
                
        setLoading("Aguarde buscando as configurações dos boletos...")
        setWarning("")
        setError("")

        let jBilletConfiguration = "{ \"COMPANY\": { \"ID\": " + jsonBillet.BILL_RECEIVE.COMPANY.ID + " }"

        if (jsonBillet.BILL_RECEIVE.CLIENT.CLIENT_COMPANY !== null){
          if ((jsonBillet.BILL_RECEIVE.CLIENT.CLIENT_COMPANY.BANK_TYPE !== undefined) && 
            (jsonBillet.BILL_RECEIVE.CLIENT.CLIENT_COMPANY.BANK_TYPE !== 0)){
            jBilletConfiguration +=  ", \"BANK_TYPE\": "+ jsonBillet.BILL_RECEIVE.CLIENT.CLIENT_COMPANY.BANK_TYPE
          }
        }

        jBilletConfiguration +=  " }"

        const formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jBilletConfiguration)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
    
        axios.post(BASE_URL + "BILLET_CONFIGURATION/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as Configurações dos Boletos. ("+ response +")\" }") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            alert(returnJson.ERROR)
            previous()
            return
          }
    
          ////
          // Check for registration // Verifica se há registro
          ////
          if (Array(returnJson.OBJECT).length === 0)
          {
            alert("Nenhuma configuração de boleto encontrada!")
            previous()
            return
          }                    
          setResultBilletConfiguration(returnJson.OBJECT)
                      
          ///
          // Fill listbox // Preenhe listbox
          ///
          let billetConfiguration = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
          returnJson.OBJECT.map(item => (
            billetConfiguration += ",{\"ID\": "+ item.ID +", \"VALUE\": \""+ item.DESCRIPTION +"\"}"
          ))
          billetConfiguration += "]}"
          setOptionsBilletConfiguration(billetConfiguration)

          ////
          // Capture the pattern // Captura o padrão
          ////
          let defaultSetting = {}
          returnJson.OBJECT.map(item => (
            (String(item.DEFAULT) === "1") ? defaultSetting = item : ""
          ))

          if (Object.keys(defaultSetting).length === 0){
            if (returnJson.OBJECT){
              if (Array(returnJson.OBJECT).length > 0) defaultSetting = (returnJson.OBJECT[0])
            }
          }

          if (Object.keys(defaultSetting).length !== 0) 
          {
            setBilletConfigurationCode(defaultSetting.ID)
            setBilletConfigurationBankType(defaultSetting.BANK_TYPE)
            setQuantityDayProtest(defaultSetting.QUANTITY_DAY_PROTEST)
            setQuantityDayNegative(defaultSetting.QUANTITY_DAY_NEGATIVE)

            if (defaultSetting.NEGATIVE_ORGAN != null) setNegativeOrgan(defaultSetting.NEGATIVE_ORGAN)

            setAcceptsExpired(defaultSetting.ACCEPTS_EXPIRED)
            setDaysLimitReceiving(defaultSetting.DAYS_LIMIT_RECEIVING)
            setCodeAcceptance(defaultSetting.CODE_ACCEPTANCE)
            setTitleType(defaultSetting.TITLE_TYPE)
            setDescription(defaultSetting.DESCRIPTION)
            setPartialReceipt(defaultSetting.PARTIAL_RECEIPT)

            if (defaultSetting.INFORMATION_ADDITIONAL != null) setInformationAdditional(defaultSetting.INFORMATION_ADDITIONAL)
            if (defaultSetting.MESSAGE != null) setMessage(defaultSetting.MESSAGE)

            setDiscountType(defaultSetting.DISCOUNT_TYPE)
            setDiscountPercentage(formatNumeric(defaultSetting.DISCOUNT_PERCENTAGE, 2))
            setDiscountValue(formatNumeric(defaultSetting.DISCOUNT_VALUE, 2))
            setJurosType(defaultSetting.JUROS_TYPE)
            setJurosPercentage(formatNumeric(defaultSetting.JUROS_PERCENTAGE, 2))
            setJurosValue(formatNumeric(defaultSetting.JUROS_VALUE, 2))
            setPenaltyType(defaultSetting.PENALTY_TYPE)
            setPenaltyPercentage(formatNumeric(defaultSetting.PENALTY_PERCENTAGE, 2))
            setPenaltyValue(formatNumeric(defaultSetting.PENALTY_VALUE, 2))
            setTariffBillet(formatNumeric(defaultSetting.TARIFF_BILLET, 2))
            setTariffPix(formatNumeric(defaultSetting.TARIFF_PIX, 2))

            if (defaultSetting.RULER != null) setRuler(defaultSetting.RULER)

            setAccountCode(defaultSetting.ACCOUNT.ID)
            setAccountNumber(defaultSetting.ACCOUNT.NUMBER)
            setAccountOwner(defaultSetting.ACCOUNT.OWNER)

            if ((defaultSetting.ACCOUNT.PARTICIPANT_CODE != null) && (defaultSetting.ACCOUNT.PARTICIPANT_CODE != 0)) 
              setAccountParticipantCode(defaultSetting.ACCOUNT.PARTICIPANT_CODE)

            setGeneratePix(defaultSetting.HYBRID)
          }

          setLoading("")
        })

        setFirstAcess(false)
      }

      setBillReceiveCode(jsonBillet.BILL_RECEIVE.ID)
      setCompanyCode(jsonBillet.BILL_RECEIVE.COMPANY.ID)

      ////
      // Company // Filial
      ////
      if (jsonCompany.CPF_CNPJ.length === 11) setCompanyRegistration(1); else setCompanyRegistration(2)
      setCompanyCpfCnpj(jsonCompany.CPF_CNPJ)
      setCompanyCorporateName(jsonCompany.CORPORATE_NAME)
      setCompanyReponsibleCpf(jsonCompany.RESPONSIBLE_CPF)

      ////
      // Client // Cliente
      ////
      if (jsonBillet.BILL_RECEIVE.CLIENT.CPF_CNPJ.length === 11) setClientRegistration(1); else setClientRegistration(2)
      setClientCpfCnpj(jsonBillet.BILL_RECEIVE.CLIENT.CPF_CNPJ)
      setClientCorporateName(jsonBillet.BILL_RECEIVE.CLIENT.CORPORATE_NAME)
      setClientAddress(jsonBillet.BILL_RECEIVE.CLIENT.ADDRESS)
      setClientNumber(jsonBillet.BILL_RECEIVE.CLIENT.NUMBER)
      setClientCep(jsonBillet.BILL_RECEIVE.CLIENT.CEP)
      setClientCity(jsonBillet.BILL_RECEIVE.CLIENT.CITY)
      setClientNeighborhood(jsonBillet.BILL_RECEIVE.CLIENT.NEIGHBORHOOD)
      setClientUf(jsonBillet.BILL_RECEIVE.CLIENT.UF)

      if ((jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF !== null)) 
        setClientReponsibleCpf(jsonBillet.BILL_RECEIVE.CLIENT.RESPONSIBLE_CPF)

      if ((jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE !== null)) 
        setClientPhone(jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_PHONE)

      if ((jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== undefined) && (jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL !== null)) 
        setClientEmail(jsonBillet.BILL_RECEIVE.CLIENT.STANDARD_EMAIL)

      const currentDate = getDate()
      const sEmissionDate = currentDate.substr(8, 2) + "/" + currentDate.substr(5, 2) + "/" + currentDate.substr(0, 4) + " " + currentDate.substr(11, 8)
      setEmissionDate(sEmissionDate)

      const sDueDate = jsonBillet.BILL_RECEIVE.DUE_DATE.substr(8, 2) + "/" + jsonBillet.BILL_RECEIVE.DUE_DATE.substr(5, 2) + "/" + 
        jsonBillet.BILL_RECEIVE.DUE_DATE.substr(0, 4) + " " + jsonBillet.BILL_RECEIVE.DUE_DATE.substr(11, 8)
      setDueDate(sDueDate)

      setOriginalValue(formatNumeric(jsonBillet.BILL_RECEIVE.VALUE - jsonBillet.BILL_RECEIVE.VALUE_RECEIVED, 2))
      setRebateValue(0)

      setNumber(currentDate.substring(0,4) + String(jsonBillet.BILL_RECEIVE.COMPANY.ID).padStart(2,'0') + 
        String(jsonBillet.BILL_RECEIVE.ID).padStart(8, '0'))

      setSoftwareCnpj("07824993000165")

      setGenerateSms(2)
      setGenerateEmail(2)
      setEnvironment(jsonCompany.ENVIRONMENT)
      setSituation(1)
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, jsonBillet, previous, firstAcess])

  const enableExtension = () => {
    setExtensionDate("")
    setExtension(true)
  }

  const enableProtest = () => {
    setNewProtest("")
    setProtes(true)
  }

  const fieldSelected = () => {
  }  

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const generatePDF = () => {
    let returnJson = []
            
    setLoading("Aguarde gerando o PDF do boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", JSON.stringify(jsonBillet))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "BILLET/GeneratePdf", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível gerar o PDF do Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        alert(returnJson.ERROR)
        previous()
        return
      }    

      setUrlPdf(returnJson.OBJECT.URL_PDF)
      setLoading("")
    })   
  }

  const getDate = () => {
    ////
    // Get the current date/time // Obtem a data/hora atual
    ///
    const date = new Date();

    const day =  date.getDate(); // 1-31
    const month = date.getMonth(); // 0-11 (zero=january) / (zero=janeiro)
    const year4 = date.getFullYear(); // 4 digits / dígitos
    const hour = date.getHours(); // 0-23
    const min = date.getMinutes(); // 0-59
    const sec = date.getSeconds(); // 0-59

    ////
    // Format the date and time (note the month + 1) // Formata a data e a hora (note o mês + 1)
    ////
    const str_date = year4 + "-" + String(month + 1).padStart(2,'0') + "-" + String(day).padStart(2,'0')
    const str_hour = String(hour).padStart(2,'0') + ':' + String(min).padStart(2,'0') + ':' + String(sec).padStart(2,'0');

    return str_date + "T" + str_hour
  }

  const handleDaysLimitReceiving = (event) => {
    if (event.target.value !== "") setDaysLimitReceiving(formatNumeric(event.target.value, 0))
  }

  const handleDiscountPercentage = (event) => {
    if (event.target.value !== "") setDiscountPercentage(formatNumeric(event.target.value, 2))
  }

  const handleDiscountValue = (event) => {
    if (event.target.value !== "") setDiscountValue(formatNumeric(event.target.value, 2))
  }  

  const handleJurosPercentage = (event) => {
    if (event.target.value !== "") setJurosPercentage(formatNumeric(event.target.value, 2))
  }

  const handleJurosValue = (event) => {
    if (event.target.value !== "") setJurosValue(formatNumeric(event.target.value, 2))
  }  

  const handleNewProtest = (event) => {
    if (event.target.value !== "") setNewProtest(formatNumeric(event.target.value, 0))
  }

  const handleOriginalValue = (event) => {
    if (event.target.value !== "") setOriginalValue(formatNumeric(event.target.value, 2))        
  }  

  const handlePenaltyPercentage = (event) => {
    if (event.target.value !== "") setPenaltyPercentage(formatNumeric(event.target.value, 2))
  }

  const handlePenaltyValue = (event) => {
    if (event.target.value !== "") setPenaltyValue(formatNumeric(event.target.value, 2))
  }

  const handleQuantityDayNegative = (event) => {
    if (event.target.value !== "") setQuantityDayNegative(formatNumeric(event.target.value, 0))
  }

  const handleQuantityDayProtest = (event) => {
    if (event.target.value !== "") setQuantityDayProtest(formatNumeric(event.target.value, 0))
  }

  const handleRebateValue = (event) => {
    if (event.target.value !== "") setRebateValue(formatNumeric(event.target.value, 2))        
  }  

  const handleTariffBillet = (event) => {
    if (event.target.value !== "") setTariffBillet(formatNumeric(event.target.value, 2))
  }

  const handleTariffPix = (event) => {
    if (event.target.value !== "") setTariffPix(formatNumeric(event.target.value, 2))
  }

  const onCancel = () => {
    var json = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode +", \"COMPANY\": { \"ID\": " + companyCode + " } }" +
    ", \"BILLET_CONFIGURATION\": { \"BANK_TYPE\": " + billetConfigurationBankType + " }" +
    ", \"NUMBER\": \"" + number + "\"" +
    ", \"INTERNAL\": \"" + internal + "\"" +
    ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\"" +
    ", \"ENVIRONMENT\": " + environment + " }"

    cancel(json)
  }

  const onCheckLower = () => {
    var json = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode +", \"COMPANY\": { \"ID\": " + companyCode + 
    ", \"CPF_CNPJ\": \""+ companyCpfCnpj +"\" } }" +

    ", \"BILLET_CONFIGURATION\": { \"BANK_TYPE\": " + billetConfigurationBankType + ", \"ACCOUNT\": { \"ID\": "+ accountCode +
    ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" } }" +

    ", \"NUMBER\": \"" + number + "\"" +
    ", \"TARIFF_BILLET\": " + tariffBillet +
    ", \"TARIFF_PIX\": " + tariffPix +
    ", \"VALUE_TRANSFER\": " + valueTransfer +
    ", \"INTERNAL\": \"" + internal + "\"" +
    ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\" "+
    ", \"ORIGINAL_VALUE\": " + originalValue +     
    ", \"ENVIRONMENT\": " + environment + " }"
        
    lower(json)
  }

  const onExtension = () => {
    var json = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode +", \"COMPANY\": { \"ID\": " + companyCode + " } }" +

    ", \"BILLET_CONFIGURATION\": { \"BANK_TYPE\": " + billetConfigurationBankType + ", \"ACCOUNT\": { \"ID\": "+ accountCode +
    ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" } }" +

    ", \"DESCRIPTION\": \""+ description +"\"" +
    ", \"ORIGINAL_VALUE\": "+ originalValue +
    ", \"DAYS_LIMIT_RECEIVING\": \""+ daysLimitReceiving +"\"" +
    ", \"INTERNAL\": " + internal +
    ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\""

    if (validateDate(dueDate)) {
      const due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00"
      json += ", \"DUE_DATE\": \""+ due + "\""      
    }

    if (validateDate(extensionDate)) {
      const extension = String(extensionDate).substr(6,4) + "-" + String(extensionDate).substr(3,2) + "-" + String(extensionDate).substr(0,2) + "T00:00:00"
      json += ", \"EXTENSION_DATE\": \""+ extension + "\""
    }
    
    json += ", \"ENVIRONMENT\": " + environment + " }"
    extend(json)
  }  

  const onIssue = () => {
    var json = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": " + billReceiveCode +
    ", \"COMPANY\": { \"ID\": " + companyCode +
    ", \"CPF_CNPJ\": \"" + companyCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \"" + companyCorporateName + "\"" +
    ", \"RESPONSIBLE_CPF\": \"" + companyReponsibleCpf + "\" }" +
    ", \"CLIENT\": { \"CPF_CNPJ\": \"" + clientCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \"" + clientCorporateName + "\"" +
    ", \"ADDRESS\": \"" + clientAddress + "\"" +
    ", \"CEP\": \"" + clientCep + "\"" +
    ", \"CITY\": \"" + clientCity + "\"" +
    ", \"NEIGHBORHOOD\": \"" + clientNeighborhood + "\"" +
    ", \"UF\": \"" + clientUf + "\""

    if ((clientReponsibleCpf !== undefined) && (clientReponsibleCpf !== "")) json += ", \"RESPONSIBLE_CPF\": \""+ clientReponsibleCpf +"\""
    if ((clientPhone !== undefined) && (clientPhone !== "")) json += ", \"STANDARD_PHONE\": \""+ clientPhone +"\""
    if ((clientEmail !== undefined) && (clientEmail !== "")) json += ", \"STANDARD_EMAIL\": \""+ clientEmail +"\""

    json += " } }"

    json += ", \"BILLET_CONFIGURATION\": { \"ID\": "+ billetConfigurationCode +", \"BANK_TYPE\": " + billetConfigurationBankType + 
    ", \"ACCOUNT\": { \"ID\": "+ accountCode + ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\""
    if (accountParticipantCode !== "") json += ", \"PARTICIPANT_CODE\": "+ accountParticipantCode
    json += " } }"

    const emission = String(emissionDate).substr(6,4) + "-" + String(emissionDate).substr(3,2) + "-" + String(emissionDate).substr(0,2) + "T" + 
      String(emissionDate).substr(11,8);
    json += ", \"EMISSION_DATE\": \""+ emission + "\""

    if (validateDate(dueDate)) {
      const due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00";
      json += ", \"DUE_DATE\": \""+ due + "\""
    }

    json += ", \"ORIGINAL_VALUE\": " + originalValue

    if ((rebateValue !== undefined) && (rebateValue !== "")) json += ", \"REBATE_VALUE\": " + rebateValue
    if ((quantityDayProtest !== undefined) && (quantityDayProtest !== "")) json += ", \"QUANTITY_DAY_PROTEST\": " + quantityDayProtest
    if ((quantityDayNegative !== undefined) && (quantityDayNegative !== "")) json += ", \"QUANTITY_DAY_NEGATIVE\": " + quantityDayNegative

    json += ", \"NEGATIVE_ORGAN\": \"" + negativeOrgan + "\"" +
    ", \"ACCEPTS_EXPIRED\": \"" + acceptsExpired + "\""

    if ((daysLimitReceiving !== undefined) && (daysLimitReceiving !== "")) json += ", \"DAYS_LIMIT_RECEIVING\": " + daysLimitReceiving

    json += ", \"CODE_ACCEPTANCE\": " + codeAcceptance +
    ", \"TITLE_TYPE\": \"" + titleType + "\"" +
    ", \"DESCRIPTION\": \"" + description + "\"" +
    ", \"PARTIAL_RECEIPT\": \"" + partialReceipt + "\"" +
    ", \"NUMBER\": \"" + number + "\""
        
    if ((informationAdditional !== undefined) && (informationAdditional !== "")) json += ", \"INFORMATION_ADDITIONAL\": \""+ informationAdditional +"\""
    if ((message !== undefined) && (message !== "")) json += ", \"MESSAGE\": \""+ message +"\""

    json += ", \"DISCOUNT_TYPE\": \"" + discountType + "\""

    if (validateDate(expirationDateDiscount)) {
      const expirationDiscount = String(expirationDateDiscount).substr(6,4) + "-" + String(expirationDateDiscount).substr(3,2) + "-" +
        String(expirationDateDiscount).substr(0,2) + "T00:00:00";
      json += ", \"EXPIRATION_DATE_DISCOUNT\": \""+ expirationDiscount + "\""
    }

    if ((discountPercentage !== undefined) && (discountPercentage !== "")) json += ", \"DISCOUNT_PERCENTAGE\": " + discountPercentage
    if ((discountValue !== undefined) && (discountValue !== "")) json += ", \"DISCOUNT_VALUE\": " + discountValue            
    if ((jurosType !== undefined) && (jurosType !== "")) json += ", \"JUROS_TYPE\": " + jurosType
    if ((jurosPercentage !== undefined) && (jurosPercentage !== "")) json += ", \"JUROS_PERCENTAGE\": " + jurosPercentage
    if ((jurosValue !== undefined) && (jurosValue !== "")) json += ", \"JUROS_VALUE\": " + jurosValue            

    json += ", \"PENALTY_TYPE\": \"" + penaltyType + "\""

    if (validateDate(penaltyDate)) {
      const penalty = String(penaltyDate).substr(6,4) + "-" + String(penaltyDate).substr(3,2) + "-" + String(penaltyDate).substr(0,2) + "T00:00:00";
      json += ", \"PENALTY_DATE\": \""+ penalty + "\""
    }

    if ((penaltyPercentage !== undefined) && (penaltyPercentage !== "")) json += ", \"PENALTY_PERCENTAGE\": " + penaltyPercentage
    if ((penaltyValue !== undefined) && (penaltyValue !== ""))  json += ", \"PENALTY_VALUE\": " + penaltyValue            

    json += ", \"REGISTRATION_COMPANY\": " + companyRegistration +
    ", \"REGISTRATION_CLIENT\": " + clientRegistration +
    ", \"CNPJ_SOFTWARE\": \"" + softwareCnpj + "\"" +
    ", \"GENERATE_EMAIL\": \"" + generateEmail + "\"" +
    ", \"GENERATE_SMS\": \"" + generateSms + "\"" +
    ", \"GENERATE_PIX\": \"" + generatePix + "\"" +
    ", \"TARIFF_BILLET\": " + tariffBillet +
    ", \"TARIFF_PIX\": " + tariffPix

    json += ", \"VALUE_TRANSFER\": " + valueTransfer

    if ((ruler !== undefined) && (ruler !== "")) json += ", \"RULER\": \"" + ruler + "\""
    if ((internal !== undefined) && (internal !== "")) json += ", \"INTERNAL\": " + internal
    if ((internalNumber !== undefined) && (internalNumber !== "")) json += ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\""
    if ((typeableLine !== undefined) && (typeableLine !== "")) json += ", \"TYPEABLE_LINE\": \"" + typeableLine + "\""
    if ((barCode !== undefined) && (barCode !== "")) json += ", \"BAR_CODE\": \"" + barCode + "\""
    if ((urlPdf !== undefined) && (urlPdf !== "")) json += ", \"URL_PDF\": \"" + urlPdf + "\""
    if ((hashPix !== undefined) && (hashPix !== "")) json += ", \"HASH_PIX\": \"" + hashPix + "\""
    if ((qrcodePix !== undefined) && (qrcodePix !== "")) json += ", \"QRCODE_PIX\": \"" + qrcodePix + "\""
    if ((url !== undefined) && (url !== "")) json += ", \"URL\": \"" + url + "\""

    if ((paymentDate !== undefined) && (paymentDate !== "")) {
      const payment = String(paymentDate).substr(6,4) + "-" + String(paymentDate).substr(3,2) + "-" + String(paymentDate).substr(0,2) + "T" +
        String(paymentDate).substr(11,8)
      json += ", \"PAYMENT_DATE\": \""+ payment + "\""
    }
        
    if ((cancellationDate !== undefined) && (cancellationDate !== "")) {
      const cancellation = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" + 
        String(cancellationDate).substr(0,2) + "T" + String(cancellationDate).substr(11,8)
      json += ", \"CANCELLATION_DATE\": \""+ cancellation + "\""
    }
    
    json += ", \"ENVIRONMENT\": " + environment +
    ", \"SITUATION\": " + situation + " }"

    issue(json)
  }

  const onProtest = () => {
    var json = "{ \"ID\": "+ code +
    ", \"BILL_RECEIVE\": { \"ID\": "+ billReceiveCode +", \"COMPANY\": { \"ID\": " + companyCode + " } }" +

    ", \"BILLET_CONFIGURATION\": { \"BANK_TYPE\": " + billetConfigurationBankType + ", \"ACCOUNT\": { \"ID\": "+ accountCode +
    ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" } }" +

    ", \"INTERNAL\": " + internal +
    ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\"" +
    ", \"QUANTITY_DAY_PROTEST\": \"" + quantityDayProtest + "\""

    if ((negativeOrgan !== null) && (negativeOrgan !== ""))
      json += ", \"NEGATIVE_ORGAN\": " + negativeOrgan +
      ", \"QUANTITY_DAY_NEGATIVE\": \"" + quantityDayNegative + "\""
        
    if (newProtest !== "") json += ", \"PROTEST_DAY\": "+ newProtest 
    
    json += ", \"ENVIRONMENT\": " + environment + " }"
    protest(json)
  }

  const onResendEmail = () => {
    if ((clientEmail === undefined) || (clientEmail === ""))
    {
      alert("Email do cliente não configurado!")
      return
    }

    let json = "{ \"BILL_RECEIVE\": { \"COMPANY\": { \"CPF_CNPJ\": \""+ companyCpfCnpj +"\""+
      ", \"CORPORATE_NAME\": \""+ companyCorporateName +"\" }, \"CLIENT\": { \"CORPORATE_NAME\": \"" + clientCorporateName + "\""+
      ", \"STANDARD_EMAIL\": \""+ clientEmail +"\""

    if ((clientPhone !== undefined) && (clientPhone !== "")) json += ", \"STANDARD_PHONE\": \""+ clientPhone +"\""
    else json += ", \"STANDARD_PHONE\": \"\""

    json += " } }"

    json += ", \"BILLET_CONFIGURATION\": { \"BANK_TYPE\": " + billetConfigurationBankType + " }"

    if (validateDate(dueDate)) {
      let due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00";
      json += ", \"DUE_DATE\": \""+ due + "\""
    }    

    if ((url !== undefined) && (url !== "")) json += ", \"URL\": \"" + url + "\""
    if ((urlPdf !== undefined) && (urlPdf !== "")) json += ", \"URL_PDF\": \"" + urlPdf + "\""

    json += ", \"ENVIRONMENT\": " + environment + " }"
    resendEmail(json)
  }

  const onSave = () => {
    let json = "{ "
    if (code !== undefined)
      if (code !== "") json += "\"ID\": "+ code + ", "

    json += "\"BILL_RECEIVE\": { \"ID\": " + billReceiveCode +
    ", \"COMPANY\": { \"ID\": " + companyCode +
    ", \"CPF_CNPJ\": \"" + companyCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \"" + companyCorporateName + "\"" +
    ", \"RESPONSIBLE_CPF\": \"" + companyReponsibleCpf + "\" }" +
    ", \"CLIENT\": { \"CPF_CNPJ\": \"" + clientCpfCnpj + "\"" +
    ", \"CORPORATE_NAME\": \"" + clientCorporateName + "\"" +
    ", \"ADDRESS\": \"" + clientAddress + "," + clientNumber + "\"" +
    ", \"CEP\": \"" + clientCep + "\"" +
    ", \"CITY\": \"" + clientCity + "\"" +
    ", \"NEIGHBORHOOD\": \"" + clientNeighborhood + "\"" +
    ", \"UF\": \"" + clientUf + "\""

    if ((clientReponsibleCpf !== undefined) && (clientReponsibleCpf !== "")) json += ", \"RESPONSIBLE_CPF\": \""+ clientReponsibleCpf +"\""
    if ((clientPhone !== undefined) && (clientPhone !== "")) json += ", \"STANDARD_PHONE\": \""+ clientPhone +"\""
    if ((clientEmail !== undefined) && (clientEmail !== "")) json += ", \"STANDARD_EMAIL\": \""+ clientEmail +"\""

    json += " } }"

    json += ", \"BILLET_CONFIGURATION\": { \"ID\": "+ billetConfigurationCode +", \"BANK_TYPE\": " + billetConfigurationBankType + 
    ", \"ACCOUNT\": { \"ID\": "+ accountCode + ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\""
    if (accountParticipantCode !== "") json += ", \"PARTICIPANT_CODE\": "+ accountParticipantCode
    json += " } }"

    const emission = String(emissionDate).substr(6,4) + "-" + String(emissionDate).substr(3,2) + "-" + String(emissionDate).substr(0,2) + "T" + 
      String(emissionDate).substr(11,8);
    json += ", \"EMISSION_DATE\": \""+ emission + "\""

    if (validateDate(dueDate)) {
      const due = String(dueDate).substr(6,4) + "-" + String(dueDate).substr(3,2) + "-" + String(dueDate).substr(0,2) + "T00:00:00";
      json += ", \"DUE_DATE\": \""+ due + "\""
    }

    json += ", \"ORIGINAL_VALUE\": " + originalValue

    if ((rebateValue !== undefined) && (rebateValue !== "")) json += ", \"REBATE_VALUE\": " + rebateValue
    if ((quantityDayProtest !== undefined) && (quantityDayProtest !== "")) json += ", \"QUANTITY_DAY_PROTEST\": " + quantityDayProtest
    if ((quantityDayNegative !== undefined) && (quantityDayNegative !== "")) json += ", \"QUANTITY_DAY_NEGATIVE\": " + quantityDayNegative

    json += ", \"NEGATIVE_ORGAN\": \"" + negativeOrgan + "\"" +
    ", \"ACCEPTS_EXPIRED\": \"" + acceptsExpired + "\""

    if ((daysLimitReceiving !== undefined) && (daysLimitReceiving !== "")) json += ", \"DAYS_LIMIT_RECEIVING\": " + daysLimitReceiving

    json += ", \"CODE_ACCEPTANCE\": " + codeAcceptance +
    ", \"TITLE_TYPE\": \"" + titleType + "\"" +
    ", \"DESCRIPTION\": \"" + description + "\"" +
    ", \"PARTIAL_RECEIPT\": \"" + partialReceipt + "\"" +
    ", \"NUMBER\": \"" + number + "\""
        
    if ((informationAdditional !== undefined) && (informationAdditional !== "")) json += ", \"INFORMATION_ADDITIONAL\": \""+ informationAdditional +"\""
    if ((message !== undefined) && (message !== "")) json += ", \"MESSAGE\": \""+ message +"\""

    json += ", \"DISCOUNT_TYPE\": \"" + discountType + "\""

    if (validateDate(expirationDateDiscount)) {
      const expirationDiscount = String(expirationDateDiscount).substr(6,4) + "-" + String(expirationDateDiscount).substr(3,2) + "-" +
        String(expirationDateDiscount).substr(0,2) + "T00:00:00";
      json += ", \"EXPIRATION_DATE_DISCOUNT\": \""+ expirationDiscount + "\""
    }

    if ((discountPercentage !== undefined) && (discountPercentage !== "")) json += ", \"PC_DESCONTO\": " + discountPercentage
    if ((discountValue !== undefined) && (discountValue !== "")) json += ", \"DISCOUNT_PERCENTAGE\": " + discountValue
    if ((jurosType !== undefined) && (jurosType !== "")) json += ", \"JUROS_TYPE\": " + jurosType
    if ((jurosPercentage !== undefined) && (jurosPercentage !== "")) json += ", \"JUROS_PERCENTAGE\": " + jurosPercentage
    if ((jurosValue !== undefined) && (jurosValue !== "")) json += ", \"JUROS_VALUE\": " + jurosValue

    json += ", \"PENALTY_TYPE\": \"" + penaltyType + "\""

    if (validateDate(penaltyDate)) {
      const penalty = String(penaltyDate).substr(6,4) + "-" + String(penaltyDate).substr(3,2) + "-" + String(penaltyDate).substr(0,2) + "T00:00:00";
      json += ", \"PENALTY_DATE\": \""+ penalty + "\""
    }

    if ((penaltyPercentage !== undefined) && (penaltyPercentage !== "")) json += ", \"PENALTY_PERCENTAGE\": " + penaltyPercentage
    if ((penaltyValue !== undefined) && (penaltyValue !== ""))  json += ", \"PENALTY_VALUE\": " + penaltyValue

    json += ", \"REGISTRATION_COMPANY\": " + companyRegistration +
    ", \"REGISTRATION_CLIENT\": " + clientRegistration +
    ", \"CNPJ_SOFTWARE\": \"" + softwareCnpj + "\"" +
    ", \"GENERATE_EMAIL\": \"" +  generateEmail + "\"" +
    ", \"GENERATE_SMS\": \"" + generateSms + "\"" +
    ", \"GENERATE_PIX\": \"" + generatePix + "\"" +
    ", \"TARIFF_BILLET\": " + tariffBillet +
    ", \"TARIFF_PIX\": " + tariffPix

    if (code !== undefined)
      if (code !== "")
        json += ", \"VALUE_TRANSFER\": " + valueTransfer

    if ((ruler !== undefined) && (ruler !== "")) json += ", \"RULER\": \"" + ruler + "\""
    if ((internal !== undefined) && (internal !== "")) json += ", \"INTERNAL\": " + internal
    if ((internalNumber !== undefined) && (internalNumber !== "")) json += ", \"INTERNAL_NUMBER\": \"" + internalNumber + "\""
    if ((typeableLine !== undefined) && (typeableLine !== "")) json += ", \"TYPEABLE_LINE\": \"" + typeableLine + "\""
    if ((barCode !== undefined) && (barCode !== "")) json += ", \"BAR_CODE\": \"" + barCode + "\""
    if ((urlPdf !== undefined) && (urlPdf !== "")) json += ", \"URL_PDF\": \"" + urlPdf + "\""
    if ((hashPix !== undefined) && (hashPix !== "")) json += ", \"HASH_PIX\": \"" + hashPix + "\""
    if ((qrcodePix !== undefined) && (qrcodePix !== "")) json += ", \"QRCODE_PIX\": \"" + qrcodePix + "\""
    if ((url !== undefined) && (url !== "")) json += ", \"URL\": \"" + url + "\""

    if ((paymentDate !== undefined) && (paymentDate !== "")) {
      const payment = String(paymentDate).substr(6,4) + "-" + String(paymentDate).substr(3,2) + "-" + String(paymentDate).substr(0,2) + "T" +
        String(paymentDate).substr(11,8);
      json += ", \"PAYMENT_DATE\": \""+ payment + "\""
    }
        
    if ((cancellationDate !== undefined) && (cancellationDate !== "")) {
      const cancellation = String(cancellationDate).substr(6,4) + "-" + String(cancellationDate).substr(3,2) + "-" + String(cancellationDate).substr(0,2) + "T" +
        String(cancellationDate).substr(11,8);
      json += ", \"CANCELLATION_DATE\": \""+ cancellation + "\""
    }
    
    json += ", \"ENVIRONMENT\": " + environment +
    ", \"SITUATION\": " + situation + " }"

    save(json)
  }

  const onSelectConfiguration = (code) => {
    if (code !== 0) {
      let configurationDefault = {}
      resultBilletConfiguration.map(item => (
        (item.ID === Number(code)) ? configurationDefault = item : ""
      ))
            
      setBilletConfigurationCode(code)
      setBilletConfigurationBankType(configurationDefault.BANK_TYPE)
      setQuantityDayProtest(configurationDefault.QUANTITY_DAY_PROTEST)
      setQuantityDayNegative(configurationDefault.QUANTITY_DAY_NEGATIVE)

      if ((configurationDefault.NEGATIVE_ORGAN != null)  && (configurationDefault.NEGATIVE_ORGAN != 0)) 
        setNegativeOrgan(configurationDefault.NEGATIVE_ORGAN)

      setAcceptsExpired(configurationDefault.ACCEPTS_EXPIRED)
      setDaysLimitReceiving(configurationDefault.DAYS_LIMIT_RECEIVING)
      setCodeAcceptance(configurationDefault.CODE_ACCEPTANCE)
      setTitleType(configurationDefault.TITLE_TYPE)
      setDescription(configurationDefault.DESCRIPTION)
      setPartialReceipt(configurationDefault.PARTIAL_RECEIPT)

      if (configurationDefault.INFORMATION_ADDITIONAL != null) setInformationAdditional(configurationDefault.INFORMATION_ADDITIONAL)
      if (configurationDefault.MESSAGE != null) setMessage(configurationDefault.MESSAGE)

      setDiscountType(configurationDefault.DISCOUNT_TYPE)
      setDiscountPercentage(configurationDefault.DISCOUNT_PERCENTAGE)
      setDiscountValue(configurationDefault.DISCOUNT_VALUE)
      setJurosType(configurationDefault.JUROS_TYPE)
      setJurosPercentage(configurationDefault.JUROS_PERCENTAGE)
      setJurosValue(configurationDefault.JUROS_VALUE)
      setPenaltyType(configurationDefault.PENALTY_TYPE)
      setPenaltyPercentage(configurationDefault.PENALTY_PERCENTAGE)
      setPenaltyValue(configurationDefault.PENALTY_VALUE)
      setTariffBillet(configurationDefault.TARIFF_BILLET)
      setTariffPix(configurationDefault.TARIFF_PIX)
      setGeneratePix(configurationDefault.HYBRID)

      setAccountCode(configurationDefault.ACCOUNT.ID)
      setAccountNumber(configurationDefault.ACCOUNT.NUMBER)
      setAccountOwner(configurationDefault.ACCOUNT.OWNER)
      if (configurationDefault.ACCOUNT.PARTICIPANT_CODE != null) setAccountParticipantCode(configurationDefault.ACCOUNT.PARTICIPANT_CODE)
    }
  }

  const previousExtension = () => {
    setExtensionDate("")
    setExtension(false)
  }   

  const previousProtest = () => {
    setNewProtest("")
    setProtes(false)
  }  

  const validateDate = (value) => {
    let error =false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg=new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt=date.split("/");        

    if ( date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) error = true
    else if ( ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0))
        error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0))
        error = true;
    }

    if (error) return false;
    return true;
  }
      
  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
          <>
            {/* Begin - Buttons */}
            { !extension && !protes &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>

                { (String(situation) === "1") &&
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                    marginLeft: 5, width: 147}} onClick={() => onSave()}>
                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                      paddingBottom: 5, paddingLeft: 10}}>
                      <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                    </div>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                    </div>
                  </div>
                }

                { (String(situation) === "1") && (code !== undefined) &&
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                    marginLeft: 5, width: 147}} onClick={() => onIssue()}>
                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                      paddingBottom: 5, paddingLeft: 10}}>
                      <img style={{height: 32, width: 32}} src={image_save} alt="Emitir"/>
                    </div>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Emitir</label>
                    </div>
                  </div>
                }

                { (((String(situation) === "1") || (String(situation) === "3")) || (String(situation) === "4")) &&
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                    marginLeft: 5, width: 147}} onClick={previous}>
                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                      paddingBottom: 5, paddingLeft: 10}}>
                      <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                    </div>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                    </div>
                  </div>
                }

                { (String(situation) === "2") &&
                  <>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={() => onCheckLower()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_select} alt="Verificar Baixa"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Verificar Baixa</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={() => onCancel()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_cancel} alt="Cancelar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Cancelar</label>
                      </div>
                    </div>

                    { (urlPdf == "") && (parseInt(billetConfigurationBankType) == 2) &&
                      <>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                          marginTop: 10, marginLeft: 5, width: 187}} onClick={() => generatePDF()}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <img style={{height: 32, width: 32}} src={image_printer} alt="Gerar PDF"/>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Gerar PDF</label>
                          </div>
                        </div>
                      </>
                    }

                    { (urlPdf !== "") &&
                      <>
                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                          marginTop: 10, marginLeft: 5, width: 187}}>
                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                            <a href={urlPdf} target="_blank" rel="noopener noreferrer" >
                              <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                            </a>
                          </div>
                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                            <a href={urlPdf} target="_blank" rel="noopener noreferrer" >
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                            </a>
                          </div>
                        </div>                      
                      </>
                    }

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={()=> onResendEmail()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_send_email} alt="Re-enviar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Re-enviar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={enableExtension}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_extend} alt="Prorrogar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Prorrogar</label>
                      </div>
                    </div>

                    {/* <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={enableProtest}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_protest} alt="Protestar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Protestar</label>
                      </div>
                    </div> */}

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                      marginLeft: 5, width: 187}} onClick={previous}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </>
                }

              </div>
            }

            { extension &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={onExtension}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_extend} alt="Prorrogar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Prorrogar</label>
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={previousExtension}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            }

            { protes &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={onProtest}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_save} alt="Protestar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Protestar</label>
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={previousProtest}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            }
            {/* End - Buttons */}

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            { warning && 
              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
              </div> 
            }

            { error &&
              <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                <label style={{fontWeight: 600, color: "red"}}>{error}</label>
              </div>
            }

            { (code === undefined) &&
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10}
                : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                <div style={{marginBottom: 10}}>
                  <b>Inclusão:</b>
                </div>

                <div style={showMenu ?
                    (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <Select
                      name="billetBilletConfigurationCode"
                      description="Configuração do Boleto"
                      value={billetConfigurationCode}
                      require={false}
                      options={JSON.parse(optionsBilletConfiguration)}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      set={onSelectConfiguration} />
                  </div>

                  <div style={showMenu ?
                      (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1260) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                    :  
                      (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 990) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                    <div style={showMenu ?
                        (width < 460) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1260) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                      :  
                        (width < 460) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                      <Select
                        name="billetSituation"
                        description="Situação"
                        value={situation}
                        disabled={true}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Lançado\" }, {\"ID\": 2, \"VALUE\": \"Emitido\" }"+
                          ", {\"ID\": 3, \"VALUE\": \"Finalizado\" }, {\"ID\": 4, \"VALUE\": \"Cancelado\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setSituation} />
                    </div>

                    <div style={showMenu ?
                        (width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 1260) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 190, maxWidth: 190}
                      :  
                        (width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 990) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 190, maxWidth: 190}}>
                      <Select
                        name="billetEnvironment"
                        description="Tipo Ambiente"
                        value={environment}
                        disabled={true}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Produção\" }, {\"ID\": 2, \"VALUE\": \"Homologação\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setEnvironment} />
                    </div>
                  </div>
                </div>
              </div>
            }

            { (code !== undefined) && 
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10}
                : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                { (String(situation) !== "3") && (String(situation) !== "4") &&
                  <div style={{marginBottom: 10}}>
                    <b>Alteração:</b>
                  </div>
                }

                <div style={showMenu ?
                    (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={showMenu ?
                      (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                    :  
                      (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                    <Input
                      type="text"
                      name="billetCode"
                      description="Código"
                      value={code}
                      maxLength={11}
                      disabled={true}
                      require={true}
                      image={false}
                      typeInput={2} 
                      onSelect={fieldSelected} />
                  </div>

                  <div style={showMenu ?
                      (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                    :
                      (width < 460) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                    <div style={showMenu ?
                        (width < 460) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 460) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <Select
                        name="situation"
                        description="Situação"
                        value={situation}
                        disabled={true}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Lançado\" }, {\"ID\": 2, \"VALUE\": \"Emitido\" }"+
                          ", {\"ID\": 3, \"VALUE\": \"Finalizado\" }, {\"ID\": 4, \"VALUE\": \"Cancelado\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setSituation} />
                    </div>

                    <div style={showMenu ?
                        (width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :  
                        (width < 460) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                      <Select
                        name="billetEnvironment"
                        description="Tipo Ambiente"
                        value={environment}
                        disabled={true}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Produção\" }, {\"ID\": 2, \"VALUE\": \"Homologação\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setEnvironment} />
                    </div>
                  </div>
                </div>
              </div>
            }

            <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
              : {paddingLeft: 20, paddingRight: 20}}>

              <div style={showMenu ?
                  (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                :
                  (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                <div style={showMenu ?
                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                    : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                  :
                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                  <div style={(width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <InputMasked
                      type="text"
                      name="billetEmissionDate"
                      description="Data Emissão"
                      mask="99/99/9999"
                      value={emissionDate}
                      disabled={true}
                      require={true}
                      image={false}
                      typeInput={2} 
                      onSelect={fieldSelected} />
                  </div>

                  <div style={showMenu ?
                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <InputMasked
                      type="text"
                      name="billetDueDate"
                      description="Vencimento"
                      mask="99/99/9999"
                      value={dueDate}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={true}
                      image={false}
                      typeInput={2} 
                      onSelect={fieldSelected}
                      onChange={(event) => setDueDate(event.target.value)} />
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                  :
                    (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                    : (width < 870) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                  <div style={(width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                    : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <InputNumeric
                      type="text"
                      name="billetOriginalValue"
                      description="Valor"
                      value={originalValue || ""}
                      maxLength={13}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={handleOriginalValue} />
                  </div>

                  <div style={showMenu ?
                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                    <InputNumeric
                      type="text"
                      name="billetRebateValue"
                      description="Valor Abatimento"
                      value={rebateValue || ""}
                      maxLength={13}
                      disabled={(parseInt(billetConfigurationBankType) == 1) ? ((String(situation) === "1") ? false : true) : true}
                      require={false}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={handleRebateValue} />
                  </div>
                </div>
              </div>
            </div>

            { !extension && (parseInt(billetConfigurationBankType) == 1) &&
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                : {paddingLeft: 20, paddingRight: 20}}>

                <div style={showMenu ?
                    (width < 1210) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                      : (width < 1210) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                    :
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1210) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                      <InputNumeric
                        type="text"
                        name="billetQuantityDayProtest"
                        description="Dias Protesto"
                        value={quantityDayProtest || ""}
                        maxLength={2}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={false}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleQuantityDayProtest} />
                    </div>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 1210) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160, marginLeft: 10}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160, marginLeft: 10}}>
                      <InputNumeric
                        type="text"
                        name="billetQuantityDayNegative"
                        description="Dias Negativação"
                        value={quantityDayNegative || ""}
                        maxLength={2}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={false}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleQuantityDayNegative} />
                    </div>
                  </div>
                  <div style={showMenu ?
                      (width < 1210) ? {display: "flex", flexGrow: "1", minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: "1", minWidth: 180, marginLeft: 10}
                    :  
                      (width < 940) ? {display: "flex", flexGrow: "1", minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: "1", minWidth: 180, marginLeft: 10}}>
                    <Select
                      name="billetNegativeOrgan"
                      description="Orgão negativador"
                      value={negativeOrgan || 0}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={false}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 10, \"VALUE\": \"SERASA\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      set={setNegativeOrgan} />
                  </div>
                </div>
              </div>
            }

            { !extension && !protes &&
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                : {paddingLeft: 20, paddingRight: 20}}>

                { (parseInt(billetConfigurationBankType) == 1) &&
                  <div style={showMenu ?
                      (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                    :
                      (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetAcceptsExpired"
                        description="Aceita vencido"
                        value={acceptsExpired}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setAcceptsExpired} />
                    </div>

                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                      <div style={showMenu ?
                          (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180} 
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180} 
                          : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                        : 
                          (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180} 
                          : (width < 660) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                        <InputNumeric
                          type="text"
                          name="billetDaysLimitReceiving"
                          description="Dias limite receb."
                          value={daysLimitReceiving}
                          maxLength={3}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={true}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          onChange={handleDaysLimitReceiving} />
                      </div>

                      <div style={showMenu ?
                          (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                          : (width < 920) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                        :
                          (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180} 
                          : (width < 660) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                        <Select
                          name="billetCodeAcceptance"
                          description="Reconhece dívida"
                          value={codeAcceptance}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          set={(event) => setCodeAcceptance(event.target.value)} />
                      </div>
                    </div>
                  </div>
                }

                { (parseInt(billetConfigurationBankType) == 1) &&
                  <div style={showMenu ?
                      (width < 840) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                    :
                      (width < 570) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetTitleType"
                        description="Tipo"
                        value={titleType}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Cheque\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Dup. Mercantil\" }, {\"ID\": 3, \"VALUE\": \"Dup. Mtil\" }"+
                          ", {\"ID\": 4, \"VALUE\": \"Dup. Serviço\" }, {\"ID\": 5, \"VALUE\": \"Dup. Serviço Indicação\" }"+
                          ", {\"ID\": 6, \"VALUE\": \"Dup. Rural\" }, {\"ID\": 7, \"VALUE\": \"Câmbio\" }"+
                          ", {\"ID\": 8, \"VALUE\": \"Nt. Créd. Comercial\" }, {\"ID\": 9, \"VALUE\": \"Nt. Créd. Exportação\" }"+
                          ", {\"ID\": 10, \"VALUE\": \"Nt. Créd. Industrial\" }, {\"ID\": 11, \"VALUE\": \"Nt. Créd. Rural\" }"+
                          ", {\"ID\": 12, \"VALUE\": \"Nt. Promis.\" }, {\"ID\": 13, \"VALUE\": \"Nt. Promis. Rural\" }"+
                          ", {\"ID\": 14, \"VALUE\": \"Triplicata Mercantil\" }, {\"ID\": 15, \"VALUE\": \"Triplicata Serviço\" }"+
                          ", {\"ID\": 16, \"VALUE\": \"Nota Seguro\" }, {\"ID\": 17, \"VALUE\": \"Recibo\" }, {\"ID\": 18, \"VALUE\": \"Fatura\" }"+
                          ", {\"ID\": 19, \"VALUE\": \"Nota Débito\" }, {\"ID\": 20, \"VALUE\": \"Apólice Seguro\" }"+
                          ", {\"ID\": 21, \"VALUE\": \"Mensalidade Escolar\" }, {\"ID\": 22, \"VALUE\": \"Parcela Consórcio\" }"+
                          ", {\"ID\": 23, \"VALUE\": \"Dívida Ativa União\" }, {\"ID\": 24, \"VALUE\": \"Dívida Ativa Estado\" }"+
                          ", {\"ID\": 25, \"VALUE\": \"Dívida Ativa Município\" }, {\"ID\": 31, \"VALUE\": \"Cartão Crédito\" }"+
                          ", {\"ID\": 32, \"VALUE\": \"Boleto Proposta\" }, {\"ID\": 33, \"VALUE\": \"Boleto Aporte\" }"+
                          ", {\"ID\": 99, \"VALUE\": \"Outros\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setTitleType} />
                    </div>

                    <div style={showMenu ?
                        (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                      :
                        (width < 570) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                      <Select
                        name="billetPartialReceipt"
                        description="Recebimento parcial"
                        value={partialReceipt}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setPartialReceipt} />
                    </div>
                  </div>
                }

                <div style={showMenu ?
                    (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <Input
                      type="text"
                      name="descricao"
                      description="Descrição"
                      value={description}
                      maxLength={120}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={true}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={(event) => setDescription(event.target.value)} />
                  </div>
                  
                  <div style={showMenu ?
                      (width < 1060) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                    :
                      (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                    <InputNumeric
                      type="text"
                      name="numeroTitulo"
                      description="Número"
                      value={number}
                      maxLength={15}
                      disabled={true}
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2} 
                      onSelect={fieldSelected} />
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 1380) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 1110) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={showMenu ?
                      (width < 1380) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                    :
                      {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <Input
                      type="text"
                      name="billetInformationAdditional"
                      description="Informações Ad."
                      value={informationAdditional}
                      maxLength={15}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={false}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={(event) => setInformationAdditional(event.target.value)} />
                  </div>

                  <div style={showMenu ?
                      (width < 1380) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 1110) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <Input
                      type="text"
                      name="billetMessage"
                      description="Mensagem"
                      value={message}
                      maxLength={30}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={false}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={(event) => setMessage(event.target.value)} />
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?
                      (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1} 
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                    :
                      (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                        <Select
                          name="billetDiscountType"
                          description="Tipo desconto"
                          value={discountType}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={false}
                          options={parseInt(billetConfigurationBankType == 1) ?
                              JSON.parse(
                              "{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Sem\" }, {\"ID\": 1, \"VALUE\": \"Valor\" }"+
                              ", {\"ID\": 2, \"VALUE\": \"Percentual\" }, {\"ID\": 3, \"VALUE\": \"Antecipação\" }] }")
                            :
                              JSON.parse(
                              "{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Sem\" }, {\"ID\": 3, \"VALUE\": \"Antecipação\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          set={setDiscountType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <InputMasked
                          type="text"
                          name="billetExpirationDateDiscount"
                          description="Dt. expiração"
                          mask="99/99/9999"
                          value={expirationDateDiscount}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          onChange={(event) => setExpirationDateDiscount(event.target.value)} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    :
                      (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetDiscountPercentage"
                        description="Porcentagem desconto"
                        value={discountPercentage}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleDiscountPercentage} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetDiscountValue"
                        description="Valor desconto"
                        value={discountValue}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleDiscountValue} />
                    </div>
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 1100) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <Select
                      name="billetJurosType"
                      description="Tipo juros"
                      value={jurosType}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={false}
                      options={parseInt(billetConfigurationBankType == 1) ?
                          JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 1, \"VALUE\": \"Dia\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Mensal\" }, {\"ID\": 3, \"VALUE\": \"Isento\" }] }")
                        :
                          JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 2, \"VALUE\": \"Mensal\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      set={setJurosType} />
                  </div>

                  <div style={showMenu ?
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1100) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                    :
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetJurosPercentage"
                        description="Porcentagem juros"
                        value={jurosPercentage}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleJurosPercentage} />
                    
                    </div>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetJurosPercentage"
                        description="Valor juros"
                        value={jurosPercentage}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handleJurosValue} />
                    </div>
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 1140) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  :
                    (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={showMenu ?   
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                    : 
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, minWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="penaltyType"
                        description="Tipo multa"
                        value={penaltyType}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={false}
                        options={parseInt(billetConfigurationBankType == 1) ?                          
                            JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 1, \"VALUE\": \"Dia\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Mensal\" }, {\"ID\": 3, \"VALUE\": \"Isento\" }] }")
                          :
                            JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 2, \"VALUE\": \"Mensal\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setPenaltyType} />
                    </div>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 1140) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 860) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                      <InputMasked
                        type="text"
                        name="billetPenaltyDate"
                        description="Dt. multa"
                        mask="99/99/9999"
                        value={penaltyDate}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={(event) => setPenaltyDate(event.target.value)} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 1140) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                    :
                      (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : (width < 860) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetPenaltyPercentage"
                        description="Porcentagem multa"
                        value={penaltyPercentage}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handlePenaltyPercentage} />
                    </div>
                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetPenaltyValue"
                        description="Valor multa"
                        value={penaltyValue}
                        maxLength={13}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={handlePenaltyValue} />
                    </div>
                  </div>
                </div>

                <div style={showMenu ?
                    (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                  :
                    (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                  <div style={showMenu ?
                      {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                    :
                      {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                    <InputNumeric
                      type="text"
                      name="billetTariffBillet"
                      description="Tarifa Boleto"
                      value={tariffBillet || ""}
                      maxLength={13}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={handleTariffBillet} />
                  </div>
                  <div style={showMenu ?
                      (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                    <InputNumeric
                      type="text"
                      name="billetTariffPix"
                      description={(parseInt(billetConfigurationBankType) == 2) ? "Tarifa PIX (%)" : "Tarifa PIX" }
                      value={tariffPix || ""}
                      maxLength={13}
                      disabled={ (String(situation) === "1") ? false : true }
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={handleTariffPix} />
                  </div>
                </div>

                { (parseInt(billetConfigurationBankType) == 1) &&
                  <div style={showMenu ?
                      (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                    :
                      (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                    <div style={showMenu ?
                        {display: "flex", flexGrow: 1, minWidth: 180}
                      :
                        {display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Input
                        type="text"
                        name="billetRuler"
                        description="Régua"
                        value={ruler}
                        maxLength={120}
                        disabled={ (String(situation) === "1") ? false : true }
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        onChange={(event) => setRuler(event.target.value)} /> 
                    </div>

                    <div style={showMenu ?
                        (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                      :
                        (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                        : (width < 730) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>
                      <div style={showMenu ?
                          (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :
                          (width < 540) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <Select
                          name="billetGeneratePix"
                          description="Híbrido"
                          value={generatePix || 1}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          set={setGeneratePix} />
                      </div>

                      <div style={showMenu ?
                          (width < 810) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 1000) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 240}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}
                        :
                          (width < 540) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 730) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 240}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 240, maxWidth: 240}}>
                        <Select
                          name="billetBankType"
                          description="Banco Digital"
                          value={billetConfigurationBankType || 0}
                          disabled={true}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                            ", {\"ID\": 1, \"VALUE\": \"Mentes que Pensam\" }, {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          set={setBilletConfigurationBankType} />
                      </div>
                    </div>
                  </div>
                }

                { (parseInt(billetConfigurationBankType) == 2) &&
                  <div style={showMenu ?
                      (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                    :
                      (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                    <div style={showMenu ?
                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      :
                        (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : (width < 730) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={showMenu ?
                          (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 1000) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :
                          (width < 420) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetDaysLimitReceiving"
                          description="Dias limite receb."
                          value={daysLimitReceiving || ""}
                          maxLength={3}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          onChange={handleDaysLimitReceiving} />
                      </div>
                      <div style={showMenu ?
                          (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 1000) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 420) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 730) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                        <Select
                          name="billetGeneratePix"
                          description="Híbrido"
                          value={generatePix || 1}
                          disabled={ (String(situation) === "1") ? false : true }
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected}
                          set={setGeneratePix} />
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 1000) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 240}
                      :
                        (width < 730) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10, minWidth: 240}}>
                      <Select
                        name="billetBankType"
                        description="Banco Digital"
                        value={billetConfigurationBankType || 0}
                        disabled={true}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }"+
                          ", {\"ID\": 1, \"VALUE\": \"Mentes que Pensam\" }, {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={fieldSelected}
                        set={setBilletConfigurationBankType} />
                    </div>
                  </div>
                }

                {/* Emitido */}
                { (((String(situation) === "2") || (String(situation) === "3")) || (String(situation) === "4")) &&
                  <div>
                    <div style={{marginTop: 10}}>
                      <Titulo description="Dados da Emissão" />
                    </div>

                    <div style={showMenu ?
                        (width < 1230) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                      <div style={showMenu ?
                          (width < 830) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 1230) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                        :
                          (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 960) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                        <div style={showMenu ?
                            (width < 830) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 1230) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                          :
                            (width < 560) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                          <Input
                            type="text"
                            name="billetInternal"
                            description="Interno"
                            value={internal || ""}
                            maxLength={11}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={fieldSelected} />
                        </div>
                        <div style={showMenu ?
                            (width < 830) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 1230) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 220, maxWidth: 220}
                          : 
                            (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 220, maxWidth: 220}}>
                          <Input
                            type="text"
                            name="billetInternalNumber"
                            description="Número interno"
                            value={internalNumber || ""}
                            maxLength={20}
                            disabled={true}
                            require={false}
                            image={false}
                            typeInput={2} 
                            onSelect={fieldSelected} /> 
                        </div>
                      </div>

                      <div style={showMenu ?
                          (width < 1230) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :   
                          (width < 960) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                          <Input
                              type="text"
                              name="billetTypeableLine"
                              description="Linha digitável"
                              value={typeableLine || ""}
                              maxLength={48}
                              disabled={true}
                              require={false}
                              image={false}
                              typeInput={2} 
                              onSelect={fieldSelected} />
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          
                      <div style={showMenu ?
                          (width < 1290) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 410}
                        :  
                          (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 410}}>
                        <Input
                          type="text"
                          name="billetBarCode"
                          description="Barras"
                          value={barCode || ""}
                          maxLength={45}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected} />
                      </div>
                      <div style={showMenu ?
                          (width < 1290) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :  
                          (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="text"
                          name="billetUrlPdf"
                          description="URL PDF"
                          value={urlPdf || ""}
                          maxLength={2000}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={fieldSelected} /> 
                      </div>
                    </div>

                    <div style={showMenu ?
                        (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                          
                      <div style={showMenu ?
                          (width < 1290) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 410}
                        :  
                          (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 410}}>
                        <Input
                          type="text"
                          name="billetHashPix"
                          description="Hash PIX"
                          value={hashPix || ""}
                          maxLength={43}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={fieldSelected} />
                      </div>
                      <div style={showMenu ?
                          (width < 1290) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                        :  
                          (width < 1020) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                        <Input
                          type="text"
                          name="billetQrcodePix"
                          description="QrCode PIX"
                          value={qrcodePix || ""}
                          maxLength={2000}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={fieldSelected} />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                        <Input
                          type="text"
                          name="billetUrl"
                          description="URL Boleto"
                          value={url || ""}
                          maxLength={2000}
                          disabled={true}
                          require={false}
                          image={false}
                          typeInput={2} 
                          onSelect={fieldSelected} />
                      </div>
                    </div>
                  </div>
                }

                {/* Finalizado e Cancelado */}
                { ((String(situation) === "3") || (String(situation) === "4")) &&

                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                      :
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputMasked
                        type="text"
                        name="billetPaymentDate"
                        description="Dt. Pagto"
                        mask="99/99/9999"
                        value={paymentDate}
                        disabled={true}
                        require={false}
                        image={false}
                        typeInput={2} 
                        onSelect={fieldSelected} />
                    </div>

                    <div style={showMenu ?
                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                      <InputMasked
                        type="text"
                        name="billetCancellationDate"
                        description="Dt. Cancelamento"
                        mask="99/99/9999 99:99:99"
                        value={cancellationDate || ""}
                        disabled={true}
                        require={false}
                        image={false}
                        typeInput={2} 
                        onSelect={fieldSelected} />
                    </div>
                  </div>
                }

              </div>
            }

            { extension &&
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                : {paddingLeft: 20, paddingRight: 20}}>

                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <InputMasked
                      type="text"
                      name="billetExtensionDate"
                      description="Dt. Prorrogação"
                      mask="99/99/9999"
                      value={extensionDate}
                      disabled={false}
                      require={true}
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={(event) => setExtensionDate(event.target.value)} />
                  </div>
                </div>
              </div>
            }

            { protes &&
              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                : {paddingLeft: 20, paddingRight: 20}}>

                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <InputNumeric
                      type="text"
                      name="billetNewProtest"
                      description="Dias Protesto"
                      value={newProtest}
                      maxLength={2}
                      disabled={false}
                      require={true}
                      negative={false} 
                      image={false}
                      typeInput={2}
                      onSelect={fieldSelected}
                      onChange={handleNewProtest} />
                  </div>
                </div>
              </div>
            }

          </>
      }
    </div>
  )
}

export default Billet