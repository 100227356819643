import React from "react"

const Footer = () => {
  return(
    <div style={{width: "100%"}}>
      <div style={{height: 6, backgroundColor: "rgb(0, 177, 171)"}} />
      <div style={{height: 3, backgroundColor: "white"}} />

      <div style={{backgroundColor: "rgb(128, 130, 133)", paddingTop: 5, paddingBottom: 5}}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <label style={{color: "white", textAlign: "center", marginTop: 5}}>(031)2511-0909 - www.multiplasistemas.com.br</label>
        </div>
        <div style={{display: "flex", flexDirection: "row", marginTop: 5, paddingLeft: "3%", paddingRight: "3%"}}>
          <div style={{flexGrow: 0, width: 10, height: 10, backgroundColor: "rgb(0, 177, 171)", borderRadius: "100%"}} />
          <div style={{marginLeft: 10, flexGrow: 1, backgroundColor: "rgb(238, 238, 239)"}} />
        </div>
      </div>
    </div>
  )
}

export default Footer