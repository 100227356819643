import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import SearchProduct from "../Product/Search"
import image_clean from "../../Images/Buttons/clean.png"
import image_back from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const ProductPack = ({databaseType, databasePath, jsonProductPack, jsonCompany, jsonLogin, system, save, back, showMenu, width}) => {
  const [code, setCode] = useState("")
  const [productCodeO, setProductCodeO] = useState("")
  const [productDescriptionO, setProductDescriptionO] = useState("")
  const [productCodeD, setProductCodeD] = useState("")
  const [productDescriptionD, setProductDescriptionD] = useState("")
  const [sge, setSge] = useState("")

  const [showProduct, setShowProduct] = useState(false)

  useEffect(() => {
    setCode(jsonProductPack.ID)
    setProductCodeO(jsonProductPack.PRODUCT_O.ID)
    setProductDescriptionO(jsonProductPack.PRODUCT_O.DESCRIPTION)

    if (jsonProductPack.PRODUCT_D !== undefined){
      setProductCodeD(jsonProductPack.PRODUCT_D.ID)
      setProductDescriptionD(jsonProductPack.PRODUCT_D.DESCRIPTION)
    }
    else{
      setProductCodeD("")
      setProductDescriptionD("")
    }

    if (((jsonProductPack.SGE !== undefined) && (jsonProductPack.SGE !== null)) && (jsonProductPack.SGE !== 0)) setSge(jsonProductPack.SGE)

  }, [jsonProductPack])

  const cleanProduct = () => {
    setProductCodeD("")
    setProductDescriptionD("")
    setShowProduct(false);
  }

  const enableProduct = () => {
    setShowProduct(!showProduct)
  }

  const fillProduct = (product) => {
    setProductCodeD(product.ID)
    setProductDescriptionD(product.DESCRIPTION)
    setShowProduct(false);
  }

  const callSave = () => {
    let json = "{"
    if ((code !== undefined) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"PRODUCT_O\": { \"ID\": " + productCodeO + ", \"DESCRIPTION\": \""+ productDescriptionO +"\" }"

    if (((productCodeD !== undefined) && (productCodeD !== null)) && (productCodeD !== ""))
      json += ", \"PRODUCT_D\": { \"ID\": " + productCodeD + ", \"DESCRIPTION\": \""+ productDescriptionD +"\" }"

    if (((sge !== undefined) && (sge !== null)) && (sge !== "")) json += ", \"SGE\": "+ sge
    json += " }"

    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showProduct ?
          <>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={callSave}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5, marginLeft: 5,
                width: 147}} onClick={() => back()}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
            </div>

            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5, marginTop: 5} : {paddingLeft: 20, paddingRight: 20, marginTop: 5}}>
              { code === undefined ? <b>Inclusão:</b> : <b>Alteração:</b>  }

              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                <b>Produto Embalagem:</b>
              </div>

              <div style={showMenu ?
                  (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : (width < 870) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                :  
                  (width < 600) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10, paddingLeft: 5, paddingRight: 5}}>

                <div style={showMenu ?
                    (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}
                  :  
                    (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                    : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                  <div style={showMenu ?
                      (width < 1070) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                    :  
                      (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                      : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                    <Input
                      type="text"
                      name="productPackProductCodeD"
                      description="Código"
                      value={productCodeD}
                      maxLength={11}
                      disabled={true}
                      require={true}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setProductCodeD(event.target.value)} />
                  </div>
                  <div style={showMenu ?
                      (width < 1070) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                    :
                      (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                    <Input
                      type="text"
                      name="productPackProductDescriptionD"
                      description="Nome"
                      value={productDescriptionD}
                      maxLength={120}
                      disabled={true}
                      require={true}
                      image={false}
                      typeInput={2} 
                      onSelect={setFieldSelected}
                      onChange={(event) => setProductDescriptionD(event.target.value)} />
                  </div>
                </div>
                <div style={showMenu ?
                    (width < 600) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                    : (width < 870) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}
                  :
                    (width < 600) ? {display: "flex", flexDirection: "row", justifyContent: "center", flexGrow: 1, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, width: 90}}>

                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 10}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productPackSearchProductD"
                      src={image_search} 
                      alt="Pesquisar" 
                      onClick={(() => enableProduct(true))} />
                  </div>
                  <div style={{display: "flex", alignItems: "flex-end", marginLeft: 5}}>
                    <img 
                      style={{height: 42, width: 42}}
                      name="productPackCleanProductD"
                      src={image_clean} 
                      alt="Limpar"
                      onClick={cleanProduct} />
                  </div>
                </div>
              </div>
            </div>
          </>
        :
          <div>
            <div>
              <b>Selecione a embalagem:</b>
              <SearchProduct
                modeSearch={true}
                process={1}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                type={"'06'"}
                system={system}
                setAdd={() => alert("")}
                setSelected={fillProduct} 
                setReturn={() => setShowProduct(false)} 
                showMenu={showMenu}
                width={width} />
            </div>
          </div>
      }

    </div>
  )
}

export default ProductPack