import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Questionnaire from "../../../Components/Questionnaire"
import RegisterTableReservation from "../"
import image_add from "../../../Images/Buttons/add.png"
import image_arrow_down_circle from "../../../Images/Buttons/arrow_down_circle.png";
import image_arrow_up_circle from "../../../Images/Buttons/arrow_up_circle.png";
import image_cancel from "../../../Images/Buttons/cancel.png"
import image_check_circle from "../../../Images/Buttons/check_circle.png";
import image_loading from "../../../Images/spinner.gif";

const SearchRegisterTableReservation = ({databaseType, databasePath, jsonLogin, system, jsonRegisterTable, showMenu, width}) => {
  const [showChange, setShowChange] = useState(false)  

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)
  const [updatePage, setUpdatePage] = useState(0)

  const [item, setItem] = useState({})
  const [typeQuestionnaire, setTypeQuestionnaire] = useState(0)
  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    if (firstAccess){
      let returnJson = []

      let json = "{ \"REGISTER_TABLE\": { \"NUMBER\": "+ jsonRegisterTable.NUMBER +
        ", \"COMPANY\": { \"ID\": "+ jsonRegisterTable.COMPANY.ID +" } } "
      json += ", \"DATE_INI\": \""+ new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + "-" + 
      String(new Date().getDate()).padStart(2, '0') + "T00:00:00" + "\""
      json += ", \"DATE_END\": \""+ new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + "-" + 
      String(new Date().getDate()).padStart(2, '0') + "T23:59:59" +"\" }"

      setLoading("Aguarde buscando as informações...")
  
      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", json)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
  
      axios.post(BASE_URL + "TABLE_RESERVATION/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar o total de reservas da mesa. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }
  
        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setLoading("")
          return
        }
  
        returnJson.WARNING && setWarning(returnJson.WARNING)
          
        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", json)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", tRecord)
  
        axios.post(BASE_URL + "TABLE_RESERVATION/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as reservas da mesa. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setLoading("")
            return
          }
          
          setResult(returnJson)
          setLoading("")
        })
      })

      setFirstAccess(false)
    }
  }, [updatePage])

  const add = () => { 
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const back = () => {
    setError("")
    setWarning("")

    setShowChange(false)
  }

  const cancel = () => {
    let returnJson = []    

    let json = "{ \"ID\": "+ item.ID + ", \"REGISTER_TABLE\": { \"COMPANY\": { \"ID\": "+ item.REGISTER_TABLE.COMPANY.ID +" } }"+
      ", \"SITUATION\": "+ 3 + ", \"DATE_CANCELLATION\": \""+ new Date().getFullYear() + '-' + 
        String(new Date().getMonth() + 1).padStart(2, '0') + "-" + String(new Date().getDate()).padStart(2, '0') + "T" +
          String(new Date().getHours()).padStart(2, '0') + ":" + String(new Date().getMinutes()).padStart(2, '0') + ":" +
            String(new Date().getSeconds()).padStart(2, '0') +"\", \"EMPLOYEE_CANCELLATION\": { \"NAME\": \""+ jsonLogin.NAME +"\" }"+
        ", \"IMPORTED\": "+ 2 + ", \"SITUATION\": "+ 3 +" }"

    setWarning("")
    setError("")
    setLoading("Aguarde cancelando a reserva...")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", json)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "TABLE_RESERVATION/Cancel", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível cancelar a reserva da mesa. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Cancelamento efetuado com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Cancelamento efetuado com sucesso!")

      setLoading("")
      setFirstAccess(true)
      setUpdatePage(updatePage + 1)

      setTypeQuestionnaire(0)
      setShowQuestionnaire(false)
    })
  }

  const cancelQuestionnaire = (ite) => {
    setWarning("")
    setError("")

    setItem(ite)
    setAskQuestionnaire("Deseja realmente cancelar a reserva (" + String(ite.DATE).substr(8,2) + "/" + String(ite.DATE).substr(5,2) + "/" + 
      String(ite.DATE).substr(0,4) + " " + String(ite.DATE).substr(11,10) + " " + ite.CLIENT + ") ?")

    setTypeQuestionnaire(2)
    setShowQuestionnaire(true)
  }

  const detail = (item, value) => {
    item.DETAIL = !value
    setUpdatePage(updatePage + 1)
  }

  const effective = () => {
    let returnJson = []
    let json = "{ \"ID\": "+ item.ID + ", \"REGISTER_TABLE\": { \"COMPANY\": { \"ID\": "+ item.REGISTER_TABLE.COMPANY.ID +" } }"+
        ", \"SITUATION\": "+ 2 +", \"EMPLOYEE_IMPLEMENTATION\": { \"NAME\": \""+ jsonLogin.NAME +"\" }, \"IMPORTED\": "+ 2 + " }"
   
    setWarning("")
    setError("")
    setLoading("Aguarde efetuando a reserva...")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", json)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "TABLE_RESERVATION/Effective", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível efetivar a reserva da mesa. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Reserva efetivada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Reserva efetivada com sucesso!")

      setLoading("")
      setFirstAccess(true)
      setUpdatePage(updatePage + 1)

      setTypeQuestionnaire(0)
      setShowQuestionnaire(false)      
    })
  }

  const effectiveQuestionnaire = (ite) => {    
    setWarning("")
    setError("")

    setItem(ite)
    setAskQuestionnaire("Deseja realmente efetivar a reserva (" + String(ite.DATE).substr(8,2) + "/" + String(ite.DATE).substr(5,2) + "/" + 
      String(ite.DATE).substr(0,4) + " " + String(ite.DATE).substr(11,10) + " " + ite.CLIENT + ") ?")

    setTypeQuestionnaire(1)
    setShowQuestionnaire(true)
  }

  const returnQuestionnaire = () => {
    setShowQuestionnaire(false)
    setTypeQuestionnaire(0)
    setItem({})
  }

  const save = (item) => {
    let returnJson = []
   
    setWarning("")
    setError("")
    setLoading("Aguarde inserindo a reserva...")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", item)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "TABLE_RESERVATION/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível inserir a reserva da mesa. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      returnJson.WARNING ? setWarning("Reserva efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Reserva efetuada com sucesso!")

      setLoading("")
      setShowChange(false)
      setFirstAccess(true)
      setUpdatePage(updatePage + 1)
    })
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}> 
            { warning && 
                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                </div> 
            }

            { error &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                </div>
            }

            { showChange ?
                <RegisterTableReservation
                  jsonLogin={jsonLogin}
                  jsonRegisterTable={jsonRegisterTable}
                  save={save}
                  back={back} 
                  showMenu={showMenu}
                  width={width} />
              :
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>            
                  { showQuestionnaire ?
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={ typeQuestionnaire === 1 ? () => effective() : () => cancel() }
                        setCancel={() => returnQuestionnaire()} />
                    :
                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                            marginTop: 5, marginLeft: 5, width: 147}} onClick={() => add()}>
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                              paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                              <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                            </div>
                            <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                              <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                            </div>
                          </div>
                        </div>

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                        </div>

                        <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                          : {paddingLeft: 10, paddingRight: 10}}>

                          { result.OBJECT ?
                              <>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5, marginTop: 10}}>
                                  <div style={showMenu ?
                                      (width < 1170) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    :
                                      (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>
                                      
                                    <div style={showMenu ?
                                        (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :
                                        (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={showMenu ?
                                          (width < 1070) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                        :
                                          (width < 790) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                          : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                        <label style={{fontWeight: 600}}>Data</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 1070) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}
                                        :
                                          (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}}>
                                        <label style={{fontWeight: 600}}>Cliente</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1170) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginRight: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                      :
                                        (width < 910) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginRight: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}}>
                                      <label style={{fontWeight: 600}}>Situação</label>
                                    </div>
                                  </div>
                                </div>
                                <hr/>

                                { result.OBJECT.map(item => (
                                  <div key={item.ID} style={{display: "flex", flexDirection: "column", flexGrow: 1,
                                    backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                    borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                    minWidth: 180}}>

                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}}>

                                      <div style={showMenu ?
                                          (width < 1170) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                        :
                                          (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>
                                          
                                        <div style={showMenu ?
                                            (width < 1070) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :
                                            (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                          <div style={showMenu ?
                                              (width < 1070) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                            :
                                              (width < 790) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                            <label>
                                              { String(item.DATE).substr(8,2) + "/" + String(item.DATE).substr(5,2) + "/" + 
                                                String(item.DATE).substr(0,4) + " " + String(item.DATE).substr(11,10) }
                                            </label>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 1070) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}
                                            :
                                              (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 520}}>
                                            <label>{item.CLIENT}</label>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1170) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginRight: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}
                                          :
                                            (width < 910) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", marginRight: 10, minWidth: 180}
                                            : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 10, minWidth: 90, maxWidth: 90}}>
                                          <label style={{fontWeight: 600}}>
                                            { item.SITUATION === 1 && "Reservado" }
                                            { item.SITUATION === 2 && "Efetivado" }
                                            { item.SITUATION === 3 && "Cancelado" }
                                          </label>
                                        </div>
                                      </div>

                                      { !item.DETAIL &&
                                        <div style={{display: "flex", flexGrow: 1, flexWrap: "wrap", justifyContent: "center", marginBottom: 5, 
                                          marginTop: 5}}>
                                      
                                          { item.SITUATION == 1 &&
                                            <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                              paddingLeft: 5}} onClick={(() => effectiveQuestionnaire(item))}>
                                              <img
                                                style={{height: 24, width: 24}}
                                                src={image_check_circle}
                                                name="searchRegisterTableReservationEffective"
                                                alt="Efetivar"  />
                                              <label style={{marginLeft: 5}}>Efetivar</label>
                                            </div>
                                          }

                                          { item.SITUATION == 1 &&
                                            <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                              paddingLeft: 5}} onClick={() => cancelQuestionnaire(item)}>
                                              <img
                                                style={{height: 24, width: 24, marginTop: 2}}
                                                name="searchCompanyEmailCancel"
                                                src={image_cancel}
                                                alt="Cancelar" />
                                              <label style={{marginLeft: 5}}>Cancelar</label>
                                            </div>
                                          }

                                          <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                            paddingLeft: 5}} onClick={() => detail(item, item.DETAIL)}>
                                            <img
                                              style={{height: 28, width: 28, marginTop: 2}}
                                              name="searchCompanyEmailDatail"
                                              src={image_arrow_down_circle}
                                              alt="Detalhe" />
                                            <label style={{marginLeft: 5}}>Detalhe</label>
                                          </div>
                                        </div>
                                      }
                                    </div>

                                    { item.DETAIL &&
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10, marginRight: 10}}>
                                        <div style={showMenu ?
                                            (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :
                                            (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={(width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={(width < 290) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 80, maxWidth: 80}}>
                                              <label style={{fontWeight: 600}}>Telefone:</label>
                                            </div>
                                            <div style={(width < 290) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, minWidth: 120}}>
                                              <label>
                                                {String(item.CLIENT_PHONE).length == 11 &&
                                                    "(" + String(item.CLIENT_PHONE).substring(0,3) + ")" + 
                                                      String(item.CLIENT_PHONE).substring(3,7) + ":" + String(item.CLIENT_PHONE).substring(7,11) 
                                                }

                                                {String(item.CLIENT_PHONE).length == 12 &&
                                                    "(" + String(item.CLIENT_PHONE).substring(0,3) + ")" + 
                                                      String(item.CLIENT_PHONE).substring(3,8) + ":" + String(item.CLIENT_PHONE).substring(8,12)
                                                }
                                              </label>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 400) ? {display: "flex", flexGrow: 1}
                                              : (width < 690) ? {display: "flex", flexGrow: 0}
                                              : (width < 710) ? {display: "flex", flexGrow: 1}
                                              : {display: "flex", flexGrow: 0}
                                            :
                                              (width < 400) ? {display: "flex", flexGrow: 1}
                                              : {display: "flex", flexGrow: 0}}>
                                            <div style={{display: "flex", flexGrow: 0, minWidth: 85, maxWidth: 85}}>
                                              <label style={{fontWeight: 600}}>Pessoas:</label>
                                            </div>
                                            <div style={{display: "flex", flexGrow: 1, minWidth: 30, maxWidth: 30}}>
                                              <label>{item.AMOUNT_PEOPLE}</label>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :
                                            (width < 710) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                          <div style={showMenu ?
                                              (width < 970) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 220, maxWidth: 220}
                                            :
                                              (width < 710) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 220, maxWidth: 220}}>
                                            <label style={{fontWeight: 600}}>Responsável Lançamento:</label>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                                            <label>{item.EMPLOYEE_RELEASE.NAME}</label>
                                          </div>
                                        </div>

                                        { item.DATE_CANCELLATION !== undefined && item.DATE_CANCELLATION !== "0001-01-01T00:00:00" &&
                                          <div style={showMenu ?
                                              (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            :
                                              (width < 1060) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={showMenu ?
                                                (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1290) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              :
                                                (width < 380) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1060) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={(width < 380) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 200, maxWidth: 200}}>
                                                <label style={{fontWeight: 600}}>Data do Cancelamento:</label>
                                              </div>
                                              <div style={showMenu ?
                                                  (width < 380) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1290) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                                  : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}
                                                :
                                                  (width < 380) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                  : (width < 1060) ? {display: "flex", flexGrow: 1, minWidth: 100}
                                                  : {display: "flex", flexGrow: 0, minWidth: 100, maxWidth: 100}}>
                                                <label>
                                                  { String(item.DATE_CANCELLATION).substr(8,2) + "/" + String(item.DATE_CANCELLATION).substr(5,2) + "/" + 
                                                    String(item.DATE_CANCELLATION).substr(0,4) }
                                                </label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 1000) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                                              :
                                                (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                              <div style={showMenu ?
                                                  (width < 1000) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}
                                                :
                                                  (width < 740) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}}>
                                                <label style={{fontWeight: 600}}>Responsável Cancelamento:</label>
                                              </div>
                                              <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                                                <label>{item.EMPLOYEE_CANCELLATION.NAME}</label>                                          
                                              </div>
                                            </div>
                                          </div>
                                        }

                                        { item.EMPLOYEE_IMPLEMENTATION !== null &&
                                          <div style={showMenu ?
                                              (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            :
                                              (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                            <div style={showMenu ?
                                                (width < 970) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}
                                              :
                                                (width < 690) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 210, maxWidth: 210}}>
                                              <label style={{fontWeight: 600}}>Responsável Efetivação:</label>
                                            </div>
                                            <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                                              <label>{item.EMPLOYEE_IMPLEMENTATION.NAME}</label>
                                            </div>
                                          </div>
                                        }

                                      </div>
                                    }

                                    { item.DETAIL &&
                                      <div style={{display: "flex", flexGrow: 1, flexWrap: "wrap", justifyContent: "center", marginBottom: 5, 
                                        marginTop: 5}}>

                                        { item.SITUATION == 1 &&
                                          <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                            paddingLeft: 5}} onClick={(() => effectiveQuestionnaire(item))}>
                                            <img
                                              style={{height: 24, width: 24}}
                                              src={image_check_circle}
                                              name="searchRegisterTableReservationEffective"
                                              alt="Efetivar"
                                              />
                                            <label style={{marginLeft: 5}}>Efetivar</label>
                                          </div>
                                        }

                                        { item.SITUATION == 1 &&
                                          <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                            paddingLeft: 5}} onClick={() => cancelQuestionnaire(item)}>
                                            <img
                                              style={{height: 24, width: 24, marginTop: 2}}
                                              name="searchCompanyEmailCancel"
                                              src={image_cancel}
                                              alt="Cancelar" />
                                            <label style={{marginLeft: 5}}>Cancelar</label>
                                          </div>
                                        }

                                        <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                          paddingLeft: 5}} onClick={() => detail(item, item.DETAIL)}>
                                          <img
                                            style={{height: 28, width: 28, marginTop: 2}}
                                            name="searchCompanyEmailDatail"
                                            src={image_arrow_up_circle}
                                            alt="Detalhe" />
                                          <label style={{marginLeft: 5}}>Detalhe</label>
                                        </div>
                                      </div>
                                    }

                                  </div>
                                ))}
                              </>
                            :
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                                marginBottom: 10}}>
                                <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                              </div>
                          }

                        </div>
                      </div>
                  }
                </div>
            }
          </div>
      }

    </div>
  )
}

export default SearchRegisterTableReservation