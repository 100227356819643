import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Footer from "../../Components/Footer"
import Menu from "../Menu"
import img_menu from "../../Images/Menu/menu.png"
import img_multiplaBranca from "../../Images/logo_multipla_branca.png"
import image_terminal from "../../Images/Buttons/terminal.png"
import image_user from "../../Images/Buttons/user.png"

const Main = ({databaseType, databasePath, jsonCompany, jsonLogin, system, height, width, terminalSelect, logoff}) => {  
  const [firstAccess, setFirstAccess] = useState(true)

  const [showMenu, setShowMenu] = useState(true)

  const [terminal, setTerminal] = useState("")
  const [currentDate, setCurrentDate] = useState(null)
  const [certificateDate, setCertificateDate] = useState(null)
  const [dateDifference , setDateDifference] = useState(0)

  useEffect(() => {
    let resultJson = []

    if (firstAccess){
      let formData = new FormData()

      const term = localStorage.getItem("terminal")

      if (term == null) terminalSelect()
      else 
      {
        setTerminal(JSON.parse(term))

        const jTerminal = "{ \"ID\": "+ JSON.parse(term).ID +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }, \"SITUATION\": 1 }"
   
        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jTerminal)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", 0)
        formData.append("QUANTITY_RECORDS", 1)
  
        axios.post(BASE_URL + "TERMINAL/Search", formData)
        .then((response) => { resultJson = JSON.parse(response.data) })
        .catch((response) => { resultJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar o terminal. ("+ response +")\"}") })
        .finally(() => {
          if (resultJson.SUCCESS === false){
            alert(resultJson.ERROR)
            logoff()
            return
          }

          if (!resultJson.OBJECT){
            setTerminal({})
            localStorage.removeItem("terminal")
            terminalSelect()
          }
          else{
            const termin = "{ \"ID\": "+ resultJson.OBJECT[0].ID +", \"DESCRIPTION\": \""+ resultJson.OBJECT[0].DESCRIPTION +"\" }"
            setTerminal(JSON.parse(termin))
            localStorage.setItem("terminal", termin)
          }
        })
      }

      const saleTouch = localStorage.getItem("sale_touch")
      if (saleTouch == null) localStorage.setItem("sale_touch", "true")

      ///
      // Get the current date and time
      ///
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth()
      const year4 = date.getFullYear()

      ///
      // Format the current date and time (month + 1)
      ///
      const str_date = day + "/" + String(month + 1).padStart(2,"0") + "/" + year4
      setCurrentDate(str_date)

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_COMPANY", jsonCompany)
      formData.append("JSON_LOGIN", jsonLogin)
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "DIGITAL_CERTIFICATE/Check", formData)      
      .then((response) => { resultJson = JSON.parse(response.data) })
      .catch((response) => { resultJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null,"+
        "\"ERROR\": \"Não foi possível verificar o certificado digital. ("+ response +")\"}") })
      .finally(() => {
        if (resultJson.SUCCESS === false) setCertificateDate(null)
        else {
          setCertificateDate(String(resultJson.OBJECT).substring(0,10))
          setDateDifference(difference(new Date(), Date.parse(resultJson.OBJECT)))
        }
      })

      setFirstAccess(false)
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, firstAccess])

  const difference = (date1, date2) => {  
    const difference = Math.abs(date1 - date2)
    const days = difference/(1000 * 3600 * 24)
    const day = String(days).split(".")
        
    return day[0]
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <div style={showMenu ?
              (width < 690) ? {display: "flex", flexDirection: "column", backgroundColor: "rgb(0, 177, 171)", height: 94}
              : {display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", height: 74}
            :
              (width < 690) ? {display: "flex", flexDirection: "column", backgroundColor: "rgb(0, 177, 171)", height: 135}
              : {display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", height: 74}}>

            { !showMenu &&
              <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "rgb(0, 177, 171)",
                paddingLeft: 10, height: 74}}>
                <img style={{height: 27, width: 32}} src={img_menu} alt="Menu" onClick={() => setShowMenu(true)} />
              </div>
            }

            <div style={(width < 690) ? {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", marginTop: 5}
              : {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
              <label style={{color: "white", fontWeight: 600}}>{jsonLogin.NAME}, seja bem vindo!!! </label>
            </div>

            <div style={(width < 690) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10, marginBottom: 10}
              : {display: "flex", flexGrow: 0, justifyContent: "flex-start", alignItems: "center", paddingRight: 20}}>
              <img src={img_multiplaBranca} alt="Logomarca" />
            </div>
          </div>

          <div style={showMenu ?
              (width < 1045) ?
                {display: "flex", flexDirection: "column", flexGrow: 0, alignItems: "end", 
                backgroundColor: "rgb(241, 242, 242)", paddingRight: 10}
              :
                {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "end", alignItems: "center", 
                backgroundColor: "rgb(241, 242, 242)", paddingRight: 10}
            :  
              (width < 785) ?
                {display: "flex", flexDirection: "column", flexGrow: 0, alignItems: "end", 
                backgroundColor: "rgb(241, 242, 242)", paddingRight: 10}
              :                        
                {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "end", alignItems: "center",  
                backgroundColor: "rgb(241, 242, 242)", paddingRight: 10}}>

            <div style={showMenu ?
                (width < 1045) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}
              :
                (width < 785) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
              <div style={showMenu ?
                  (width < 1045) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 470, maxWidth: 470}
                :
                  {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 470, maxWidth: 470}}>
                <div style={showMenu ?
                      (width < 1045) ? {display: "flex", flexGrow: 1, justifyContent: "end", alignItems: "center", wordBreak: "break-all",
                      paddingRight: 10, minWidth: 180}
                    : 
                      {display: "flex", flexGrow: 0, justifyContent: "end", alignItems: "center", paddingRight: 10, minWidth: 440}
                  :
                      (width < 785) ? {display: "flex", flexGrow: 1, justifyContent: "end", alignItems: "center", wordBreak: "break-all",
                      paddingRight: 10, minWidth: 180}
                    : 
                      {display: "flex", flexGrow: 0, justifyContent: "end", alignItems: "center", paddingRight: 10, minWidth: 440}}>
                  <label style={{fontWeight: 600}}>{jsonLogin.NAME}</label>
                </div>
                <div style={showMenu ?
                    (width < 1045) ? {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, minWidth: 30, maxWidth: 30}
                    : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 30, maxWidth: 30}
                  :
                    (width < 785) ? {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, minWidth: 30, maxWidth: 30}
                    : {display: "flex", flexGrow: 0, alignItems: "center", minWidth: 30, maxWidth: 30}}>
                  <img style={{height: 18, width: 18}} src={image_user} alt="Usuário" />
                </div>
              </div>
            </div>

            <div style={showMenu ?
                (width < 1045) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}
              :
                (width < 785) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

              <div style={showMenu ?
                  (width < 1045) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 290, maxWidth: 290}
                :
                  (width < 785) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                  : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 290, maxWidth: 290}}>
                <div style={showMenu ?
                    (width < 1045) ?  
                      {display: "flex", flexGrow: 1, justifyContent: "end", alignItems: "center", wordBreak: "break-all", paddingRight: 10, 
                      minWidth: 180}
                    :
                      {display: "flex", flexGrow: 0, justifyContent: "end", alignItems: "center", paddingRight: 10, minWidth: 260}
                  :
                    (width < 785) ?  
                      {display: "flex", flexGrow: 1, justifyContent: "end", alignItems: "center", wordBreak: "break-all", paddingRight: 10, 
                      minWidth: 180}
                    :                  
                      {display: "flex", flexGrow: 0, justifyContent: "end", alignItems: "center", paddingRight: 10, minWidth: 260}}>
                  <label style={{fontWeight: 600}}>{terminal.DESCRIPTION}</label>
                </div>
                <div style={{display: "flex", flexGrow: 0, minWidth: 30, maxWidth: 30}}>
                  <img style={{height: 28, width: 28}} src={image_terminal} alt="Terminal" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <br/>
            <div style={{marginLeft: 20}}><label style={{fontWeight: 600}}>Empresa: {jsonCompany.CORPORATE_NAME}</label></div>
            
            { jsonCompany.PRODUCTION === 2 ? 
              <div style={{marginLeft: 20}}><label style={{fontSize: "800", fontWeight: 600, color: "red"}}>HOMOLOGAÇÃO</label></div>
              : <div style={{marginLeft: 20}}><label style={{fontSize: "800", fontWeight: 600}}>PRODUÇÃO</label></div>
            }
            
            <div style={{marginLeft: 20}}><label style={{fontWeight: 600}}>Hoje é {currentDate}</label></div>

            { certificateDate &&                
              <div style={{marginLeft: 20}}>
                <br/>
                <label style={{fontWeight: 600}}>Validade do Certificado: {certificateDate}</label>
                <br/>
                                
                { dateDifference > 0 &&
                  dateDifference <= 30 &&
                    <label style={{color: "red"}}>O certificado está prestes a vencer faltam {dateDifference} dias.</label>
                }

                { dateDifference <= 0 &&
                  <label style={{color: "red"}}>{"O certificado está vencimento. Favor atualizar Gerencial > Certificado Digital."}</label>
                }                    
              </div>
            }
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default Main