import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_clean from "../../../Images/Buttons/clean.png"
import image_import from "../../../Images/Buttons/import.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchTerminal = ({databaseType, databasePath, jsonLogin, jsonCompany, system, setSelected, setReturn, showMenu, height, width}) => {
  const [fieldSelected, setFieldSelected] = useState("")

  const [description, setDescription] = useState("")
  const [situation, setSituation] = useState(0)  
    
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  useEffect(() => {
    let returnJson = []
    let search = false

    let jTerminal = "{ "
    jTerminal += "\"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
    if (description !== "") {
      jTerminal += ", \"DESCRIPTION\": \""+ description + "\""
      if (description.length > 2) search = true
    }
    if (situation !== 0) {
      jTerminal += ", \"SITUATION\": \""+ situation + "\""
      search = true
    }    
    jTerminal += " }"

    if (jTerminal === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }") search = true

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jTerminal)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "TERMINAL/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de terminais. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchTerminalSituation") { 
            document.getElementById("searchTerminalSituation") &&
              document.getElementById("searchTerminalSituation").focus() 
          }
          else {
            document.getElementById("searchTerminalDescription") &&
              document.getElementById("searchTerminalDescription").focus() 
          }
          
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchTerminalSituation") {
            document.getElementById("searchTerminalSituation") &&
              document.getElementById("searchTerminalSituation").focus()
          }
          else {
            document.getElementById("searchTerminalDescription") &&
              document.getElementById("searchTerminalDescription").focus()
          }

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jTerminal)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "TERMINAL/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as terminais. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchTerminalSituation") { 
              document.getElementById("searchTerminalSituation") &&
                document.getElementById("searchTerminalSituation").focus() 
            }
            else {
              document.getElementById("searchTerminalDescription") &&
                document.getElementById("searchTerminalDescription").focus() 
            }

            return
          }

          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchTerminalSituation") { 
            document.getElementById("searchTerminalSituation") &&
              document.getElementById("searchTerminalSituation").focus() 
          }
          else {
            document.getElementById("searchTerminalDescription") &&
              document.getElementById("searchTerminalDescription").focus() 
          }
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      if (fieldSelected === "searchTerminalSituation") { 
        document.getElementById("searchTerminalSituation") &&
          document.getElementById("searchTerminalSituation").focus() 
      }
      else {
        document.getElementById("searchTerminalDescription") &&
          document.getElementById("searchTerminalDescription").focus() 
      }
    }
  }, [databaseType, databasePath, jsonCompany, jsonLogin, system, pageCurrent, recordPage, description, situation, updatePage])

  const clean = () => {
    setDescription("")
    setSituation(0)
    setUpdatePage(updatePage + 1)
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "TERMINAL/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os terminais no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")

        if (fieldSelected === "searchTerminalSituation") { 
          document.getElementById("searchTerminalSituation") &&
            document.getElementById("searchTerminalSituation").focus() 
        }
        else {
          document.getElementById("searchTerminalDescription") &&
            document.getElementById("searchTerminalDescription").focus() 
        }

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }
    
  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15, textAlign: "center"}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                marginLeft: 5, width: 147}} onClick={clean}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>

              { (jsonCompany.INTEGRATE_SGE === 1) &&
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => getSGE()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_import} alt="Importar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Importar</label>
                  </div>
                </div>
              }

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                marginLeft: 5, width: 147}} onClick={setReturn}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                </div>
              }

              <div style={{paddingLeft: 10, paddingRight: 10}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                  marginBottom: 10, marginLeft: 0}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                </div>

                <div style={showMenu ?
                    (width < 500) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    : (width < 770) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                  :
                    (width < 500) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                    : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                  <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                    <Input
                      type="search"
                      name="searchTerminalDescription"
                      description="Descrição"
                      planceHolder="Mínimo 3 caracteres"
                      value={description}
                      maxLength={20}
                      disabled={false}
                      require={false}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      onChange={(event) => setDescription(event.target.value)} />
                  </div>

                  <div style={showMenu ?
                      (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                      : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                      : (width < 770) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}
                    :
                      (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                      : {display: "flex", flexGrow: 0, minWidth: 180, marginLeft: 10}}>
                    <Select
                      name="searchTerminalSituacao"
                      description="Situação"
                      value={situation || 0}
                      require={false}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                        ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setSituation} />
                  </div>
                </div>

                { showResult && 
                  <>
                    { result.OBJECT &&
                      <>
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 15}}>
                          <div style={(showMenu) ? 
                              (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                              : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                            : 
                              (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={showMenu ?
                                  (width < 520) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}
                                  : (width < 790) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}
                                :  
                                  (width < 520) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                  : {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}}>
                                <label style={{fontWeight: 600}}>Descrição</label>
                              </div>
                            </div>  

                            <div style={showMenu ?
                                (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                                : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                              :
                                (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                              <label style={{fontWeight: 600}}>Situação</label>
                            </div>
                          </div>
                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                            : {display: "flex", flexGrow: 0}}>
                            <div style={{height: 32, width: 42}} />
                          </div>
                        </div>
                        <hr/>
                      </>
                    }

                    { result.OBJECT.map(item => (
                      <div key={item.ID} style={
                          (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                          borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, 
                          paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                        : 
                          {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center", backgroundColor: "rgb(241, 242, 242)", 
                          borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, 
                          paddingBottom: 8, paddingRight: 8, marginTop: 4, minWidth: 180}}>

                        <div style={(showMenu) ? 
                            (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                          : 
                            (width < 520) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                            <div style={showMenu ?
                                (width < 520) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}
                                : (width < 790) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}
                              :  
                                (width < 520) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 1, minWidth: 260, maxWidth: 260}}>
                              <label>{item.DESCRIPTION}</label>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                              : (width < 790) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}
                            :
                              (width < 520) ? {display: "flex", flexDirection: "row", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 120, maxWidth: 120}}>
                            <label>
                              {item.SITUATION === 1 && "Ativo" }
                              {item.SITUATION === 2 && "Inativo" }
                            </label>
                          </div>
                        </div>
                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                          : {display: "flex", flexGrow: 0}}>
                          <img
                            style={{height: 32, width: 32}}
                            src={image_select}
                            name="searchTerminalSelect"
                            alt="Selecionar"
                            onClick={(() => setSelected(item))} />
                        </div>
                      </div>
                      ))
                    }

                    <SearchPagination 
                      pageCurrent={pageCurrent}
                      totalPage={totalPage}
                      recordPage={recordPage}
                      optionsRegPage={JSON.parse(optionsRegPage)}
                      pagePrevious={pagePrevious}
                      pageNext={pageNext}
                      setSelectedField={setFieldSelected}
                      handleRecordPage={handleRecordPage}
                      width={width} />
                  </>
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SearchTerminal