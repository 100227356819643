import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Titulo from "../../../Components/Titulo"
import SearchBank from "../../Bank/Search"
import image_clean from "../../../Images/Buttons/clean.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchAgency = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu, 
  width}) => {

  const [code, setCode] = useState(0)
  const [description, setDescription] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [bankDescription, setBankDescription] = useState("")

  const [showResult, setShowResult] = useState(false)
  const [showBank, setShowBank] = useState(false)
    
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    let jAgency = "{ "
    if (description !== "")  {
      jAgency += "\"DESCRIPTION\": \""+ description + "\""
      if (description.length > 2) search = true
    }
    if (bankCode !== "")  {
      if (jAgency !== "{ ") jAgency += ", "
      jAgency += "\"BANK\": { \"ID\": "+ bankCode + " }"
      search = true
    }
    jAgency += " }"

    if (jAgency === "{  }") search = true

    if (search){
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jAgency)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "AGENCY/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de agências. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jAgency)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "AGENCY/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as agências. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
        })
      })
    }
    else{
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)

      document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
    }
  }, [databaseType, databasePath, jsonLogin, system, pageCurrent, recordPage, description, bankCode, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir a agência (" + item.DESCRIPTION + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setDescription("")
    setBankCode("")
    setBankDescription("")

    setUpdatePage(updatePage + 1)
  }

  const cleanBank = () => {
    setShowBank(false)

    setBankCode("")
    setBankDescription("")
  }

  const confirmDelete = () => {
    let returnJson = []
    const jAgency = "{ \"ID\": "+ code +" }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jAgency)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "AGENCY/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir a agência. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const enableBank = (situation) => {
    setShowBank(situation)
  }

  const fillBank = (bank) => {
    setShowBank(false)

    setBankCode(bank.ID)
    setBankDescription(bank.DESCRIPTION)
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "AGENCY/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter as agências no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        document.getElementById("searchAgencyDescription") && document.getElementById("searchAgencyDescription").focus()
        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>

                  { !showBank ?
                      <>
                        <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean} 
                          getSGE={() => getSGE()} setReturn={setReturn} />

                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                        </div>

                        <div style={{paddingLeft: 10, paddingRight: 10}}>
                          { warning &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div> 
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div> 
                          }

                          <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                              marginBottom: 10, marginLeft: 0}}>
                              <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                            </div>
                            
                            <div style={{display: "flex", flexGrow: 0}}>
                              <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                <Input
                                  type="search"
                                  name="searchAgencyDescription"
                                  description="Descrição"
                                  planceHolder="Mínimo 3 caracteres"
                                  value={description}
                                  maxLength={50}
                                  disabled={false}
                                  require={false}
                                  image={false}
                                  typeImage={0}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={(event) => setDescription(event.target.value)} />
                              </div>
                            </div>

                            <div style={{marginTop: 10, width: "100%"}}>
                              <Titulo description="Banco" />
                            </div>

                            <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0}
                              : {paddingLeft: 10, paddingRight: 10}}>

                              <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}}>
                                  <Input
                                    type="search"
                                    name="searchAgencyBankDescription"
                                    description="Descrição"
                                    planceHolder=""
                                    value={bankDescription}
                                    maxLength={255}
                                    disabled={true}
                                    require={false}
                                    image={false}
                                    typeImage={0}
                                    typeInput={2}
                                    onSelect={setFieldSelected}
                                    onChange={(event) => setBankDescription(event.target.value)} />
                                </div>
                                <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0, alignItems: "end", marginLeft: 10}}>

                                  <img
                                    style={{height: 42, width: 42}}
                                    name="searchAgencySearchBank"
                                    src={image_search} 
                                    alt="Pesquisar" 
                                    onClick={(() => enableBank(true))} />

                                  <img
                                    style={{height: 42, width: 42, marginLeft: 10}}
                                    name="searchAgencyCleanBank"
                                    src={image_clean} 
                                    alt="Limpar"
                                    onClick={cleanBank} />
                                </div>
                              </div>
                            </div>

                            { showResult && 
                              <>
                                { result.OBJECT &&
                                  <>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, minWidth: 180}}>

                                      <div style={(showMenu) ?
                                          (width < 1340) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                        :
                                          (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                        <div style={(showMenu) ?
                                            (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1340) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexBasis: "row", flexGrow: 0}
                                          :
                                            (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexBasis: "row", flexGrow: 0}}>

                                          <div style={(showMenu) ?
                                              (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                            :
                                              (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                            <label style={{fontWeight: 600}}>Número</label>
                                          </div>

                                          <div style={(showMenu) ?
                                                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1340) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350, maxWidth: 350}
                                              :
                                                (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350, maxWidth: 350}}>

                                            <label style={{fontWeight: 600}}>Agência</label>
                                          </div>
                                        </div>

                                        <div style={(showMenu) ?
                                            (width < 1340) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                          :
                                            (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}}>

                                          <label style={{fontWeight: 600}}>Banco</label>
                                        </div>
                                      </div>
                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10, marginRight: 8} 
                                        : {display: "flex", flexGrow: 0, marginRight: 8}}>

                                        <div style={{height: 32, width: 32}} />
                                          { !modeSearch && 
                                            <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} /> 
                                          }
                                      </div>
                                    </div>

                                    { result.OBJECT.map(item => (
                                      <div key={item.ID} style={(width < 450) ? 
                                          {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                            borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,                                    
                                            paddingTop: 8, paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                                        : 
                                          {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                            backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                            borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8, paddingRight: 8,                                      
                                            marginTop: 4, minWidth: 180}}>

                                        <div style={(showMenu) ?
                                            (width < 1340) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                          :
                                            (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                          <div style={(showMenu) ?
                                              (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1340) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexBasis: "row", flexGrow: 0}
                                            :
                                              (width < 730) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexBasis: "row", flexGrow: 0}}>

                                            <div style={(showMenu) ?
                                                (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                                              :
                                                (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>

                                              <label>{item.NUMBER}</label>
                                            </div>

                                            <div style={(showMenu) ?
                                                  (width < 980) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 1340) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350, maxWidth: 350}
                                                :
                                                  (width < 730) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 1080) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350, maxWidth: 350}}>

                                              <label>{item.DESCRIPTION}</label>
                                            </div>
                                          </div>

                                          <div style={(showMenu) ?
                                              (width < 1340) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}
                                            :
                                              (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 350}}>

                                            <label>{item.BANK.DESCRIPTION}</label>
                                          </div>
                                        </div>
                                        <div style={(width < 450) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexGrow: 0}}>

                                          <img
                                            style={{height: 32, width: 32}}
                                            src={image_select}
                                            name="searchAgencySelect"
                                            alt="Selecionar"
                                            onClick={() => setSelected(item)} />

                                          { !modeSearch && 
                                            <img
                                              style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}}
                                              src={image_delete}
                                              name="searchAgencyDelete"
                                              alt="Deletar"
                                              onClick={() => callDelete(item)} />
                                          }
                                        </div>
                                      </div>
                                      ))
                                    }

                                    <SearchPagination
                                      pageCurrent={pageCurrent}
                                      totalPage={totalPage}
                                      recordPage={recordPage}
                                      optionsRegPage={JSON.parse(optionsRegPage)}
                                      pagePrevious={pagePrevious}
                                      pageNext={pageNext}
                                      setSelectedField={setFieldSelected}
                                      handleRecordPage={handleRecordPage}
                                      width={width} />
                                  </>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </>
                    :
                      <div style={{marginTop: 10}}>
                        <div style={{marginLeft: 10}}>
                          <b>Selecione o banco:</b>
                        </div>

                        <SearchBank
                          modeSearch={true}
                          databaseType={databaseType}
                          databasePath={databasePath}
                          jsonCompany={jsonCompany}
                          jsonLogin={jsonLogin}
                          system={system}
                          setAdd={() => alert("")}
                          setSelected={fillBank} 
                          setReturn={() => enableBank(false)} 
                          showMenu={showMenu}
                          width={width} />
                      </div>
                  }
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchAgency