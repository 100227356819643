import React, { useEffect, useState } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DigitalKeyboard from "../../Components/DigitalKeyboard"
import DigitalKeyboardNumeric from "../../Components/DigitalKeyboardNumeric"
import Footer from "../../Components/Footer"
import Input from "../../Components/Input"
import Select from "../../Components/Select"
import image_clean from "../../Images/Buttons/clean.png"
import image_keyboard from "../../Images/Buttons/keyboard.png"
import image_logo_multipla_branca from '../../Images/logo_multipla_branca.png';
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_search from "../../Images/Buttons/search.png"
import image_touch_screen from "../../Images/Buttons/touch_screen.png"
import image_magnifying_lens from "../../Images/Buttons/magnifying_lens.png"
import SaleProductSearch from "../SaleProductSearch"

const SaleMain = ({databaseType, databasePath, jsonCompany, jsonLogin, system, typeSale, number, setReturn, height, width}) => {  
  const [clientCorporateName, setClientCorporateName] = useState("")

  const [field, setField] = useState("")

  const [touchScreen, setTouchScreen] = useState(true)
  const [keyboard, setKeyboard] = useState(false)

  const [loading, setLoading] = useState(null)
  const [error, setError] = useState("")

  const [firstAccess, setFirstAccess] = useState(true)
  const [updatePage, setUpdatePage] = useState(0)

  const [showFunctions, setShowFunctions] = useState(true)
  
  useEffect(() => {
    if (firstAccess){
      const saleTouch = localStorage.getItem("sale_touch")      
      setTouchScreen(Boolean(saleTouch))

      setFirstAccess(false)
    }

    if (!touchScreen) document.getElementById("saleMainInputSearch") && document.getElementById("saleMainInputSearch").focus()
  } , [firstAccess, updatePage])

  const selectTouch = (value) => {
    if (value){
      setError("")
      setTouchScreen(value)
      localStorage.setItem("sale_touch", "true")
    }
    else{
      setError("")
      setTouchScreen(value)
      localStorage.setItem("sale_touch", "false")
    }

    setUpdatePage(updatePage + 1)
  }

  const selectProduct = (item) => {

  }

  const setFieldSelected = (field) => {
    setField(field)
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",
              backgroundColor: "rgb(0, 177, 171)", height: 50}} />

            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
              <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
            </div>

            <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
              <Footer />
            </div>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, backgroundColor: "rgb(0, 177, 171)", height: 50}}>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                <label style={{fontSize: 18, textAlign: "center", fontWeight: 600, color: "white", paddingLeft: 10, paddingRight: 10}}>
                  { (typeSale == 1) ? "Mesa: "+ number 
                    : (typeSale == 2) ? "Comanda: "+ number
                    : ""
                  }
                </label>
              </div>

              <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 10}}>
                <img src={image_logo_multipla_branca} style={{height: 40}} alt="AVATAR" />
              </div>
            </div>

            { clientCorporateName &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, backgroundColor: "rgb(0, 177, 171)", height: 50}}>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center"}}>
                  <label style={{fontSize: 18, textAlign: "center", fontWeight: 600, color: "white", paddingLeft: 10, paddingRight: 10}}>
                    Cliente:
                  </label>  
                  <label style={{fontSize: 18, textAlign: "center", fontWeight: 600, color: "white", paddingRight: 10}}>
                     {clientCorporateName}
                  </label>
                </div>
              </div>
            }

            { showFunctions &&
              <div style={(width < 380) ? {display: "flex", flexDirection: "column", alignItems: "center", flexGrow: 1, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "end", flexGrow: 0, height: 40}}>

                { !touchScreen &&
                  <div style={(width < 380) ? {display: "flex", flexGrow: 0, alignItems: "center", width: 165}
                    : {display: "flex", flexGrow: 0, alignItems: "center", marginRight: 10, width: 165}}
                    onClick={() => setKeyboard(!keyboard)}>
                    <img style={{height: 32, width: 32}} src={image_keyboard} alt="Teclado"/>
                    <label style={{paddingLeft: 10, paddingTop: 5}}>Teclado</label>
                  </div>
                }

                { touchScreen &&
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", width: 135}} 
                    onClick={() => selectTouch(false)}>
                    <img style={{height: 30, width: 30}} src={image_magnifying_lens} alt="Códigos"/>
                    <label style={{paddingLeft: 10}}>Códigos</label>
                  </div>
                }

                { !touchScreen &&
                  <div style={(width < 380) ? {display: "flex", flexGrow: 0, alignItems: "center", marginTop: 10, width: 165}
                    : {display: "flex", flexGrow: 0, alignItems: "center", marginRight: 15, width: 165}} 
                    onClick={() => selectTouch(true)}>
                    <img style={{height: 28, width: 28}} src={image_touch_screen} alt="Departamentos"/>
                    <label style={(width < 380) ? {paddingLeft: 15}
                      : {paddingLeft: 10}}>Departamentos</label>
                  </div>
                }
              </div>
            }

            <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 10, paddingRight: 10, paddingBottom: 0,
              paddingLeft: 10}}>
              { error &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                  marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                </div>
              }
            </div>

            { touchScreen ?
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                  <label>Touch</label>
                </div>            
              :
                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>                
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
                    <SaleProductSearch
                      databaseType={databaseType}
                      databasePath={databasePath}
                      jsonCompany={jsonCompany}
                      jsonLogin={jsonLogin}
                      system={system}
                      keyboard={keyboard}
                      setShowFunctions={setShowFunctions}
                      setSelected={selectProduct}
                      width={width}
                    />
                  </div>

                </div>
            }

            { showFunctions &&
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, backgroundColor: "rgb(114,112,111)", marginBottom: 3}}>           

                <div style={{display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", marginLeft: 10}}>           
                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                    marginLeft: 5, width: 147}} onClick={() => setReturn()}>
                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                      paddingBottom: 5, paddingLeft: 10}}>
                      <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                    </div>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                    </div>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginBottom: 3, height: 70 }}>
                  <div style={{display: "flex", flexGrow: 1, flexDirection: "row" }}>
                    <div style={{display: "flex", flexGrow: 0, paddingLeft: 10, marginTop: 10, minWidth: 120, maxWidth: 120 }}>
                      <label style={{fontSize: 22, fontWeight: "600", color: "white" }}>Total:</label>
                    </div>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "flex-end", justifyContent: "flex-end", 
                      paddingRight: 20, minWidth: 160, maxWidth: 160 }}>
                      <label style={{fontSize: 26, fontWeight: "600", color: "white" }}>{"1000000.00"}</label>
                    </div>
                  </div>
                </div>
              </div>
            }            

            <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
              <Footer />
            </div>
          </div>
      }
    </div>
  )
}

export default SaleMain