import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import Header from "../../Components/Header"
import InputMasked from "../../Components/InputMasked"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Select from "../../Components/Select"
import image_07824993000165 from '../../Images/logos/07824993000165.png';
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif"
import image_previous from "../../Images/Buttons/previous.png"
import image_printer from "../../Images/Buttons/printer.png"
import image_search from "../../Images/Buttons/search.png"

const ReportExpirationClientBillet = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [periodIni, setPeriodIni] = useState("")
  const [periodEnd, setPeriodEnd] = useState("")
  const [nfse, setNfse] = useState("0")
  const [send, setSend] = useState("0")

  const [showMenu, setShowMenu] = useState(true)
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [updateFocus, setUpdateFocus] = useState(0)

  useEffect(() => {
    if (!showResult) {    
      document.getElementById("reportExpirationClientBilletPeriodIni") &&
      document.getElementById("reportExpirationClientBilletPeriodIni").focus()
    }
  }, [updateFocus])

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setPeriodIni("")
    setPeriodEnd("")
    setNfse("0")
    setSend("0")

    setUpdateFocus(updateFocus + 1)
  }  

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const getDate = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    return today.toLocaleString();
  }

  const print = () => {
    window.print()
  }

  const returnSearch = () => {
    setPeriodIni("")
    setPeriodEnd("")
    setNfse("0")
    setSend("0")
    
    setShowResult(false)    
    
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")

    setUpdateFocus(updateFocus + 1)
  }

  const search = () => {
    let returnJson = []

    if (periodIni === "") {
      setError("É necessário informar o período inicial.")
      return
    }

    if (periodEnd === "") {
      setError("É necessário informar o período final.")
      return
    }

    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações...")

    let jClient = "{ \"DAY_INITIAL\": "+ parseInt(String(periodIni).replaceAll("_","")) +
    ", \"DAY_FINAL\": "+ parseInt(String(periodEnd).replaceAll("_",""))

    if (nfse !== "0") jClient += ", \"AUTOMATIC_NFSE\": \""+ nfse +"\""
    if (send !== "0") jClient += ", \"SHIPPING_TYPE\": \""+ send +"\""
    jClient += " }"

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jClient)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "CLIENT/ReportBilletExpiration", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar o relatório dos Boletos dos Clientes. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        setShowResult(false)
        return
      }

      setLoading("")
      setError("")

      if (returnJson.WARNING === "Nenhum registro encontrado.") {
        setWarning("Nenhum registro encontrado com o filtro informado.")
        return
      }

      setResult(returnJson)
      setLoading("")
      setShowResult(true)
    }) 
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Relatório de Vencimento Boleto X Cliente"
            titleSearch=""
            showSearch={false}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { !showResult &&
            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

              { loading ?
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                    <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                    <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                  </div>
                :
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={search}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={clean}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                        </div>
                      </div>

                      <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                        marginLeft: 5, width: 147}} onClick={setReturn}>
                        <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                          paddingBottom: 5, paddingLeft: 10}}>
                          <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                        </div>
                        <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                          <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                        </div>
                      </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                    </div>

                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                      paddingLeft: 10}}>
                      { warning && 
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", textAlign: "center"}}>{warning}</label>
                        </div> 
                      }

                      { error &&
                        <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                          marginBottom: 10}}>
                          <label style={{fontWeight: 600, color: "red", textAlign: "center"}}>{error}</label>
                        </div>
                      }
                    </div>

                    <div style={(width < 450) ? {paddingLeft: 10, paddingRight: 10, marginTop: 10, marginBottom: 10}
                      : {paddingLeft: 20, paddingRight: 20, marginTop: 10, marginBottom: 10}}>

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={(width < 450) ? {paddingRight: 0, paddingLeft: 0}
                        : {paddingRight: 10, paddingLeft: 10}}>

                        <div style={showMenu ?
                            (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                          :
                            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={showMenu ?
                                (width < 450) ?{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}
                              :
                                {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90}}>
                              <InputMasked
                                type="text"
                                name="reportExpirationClientBilletPeriodIni"
                                description="Inicial"
                                mask="99"
                                value={periodIni}
                                disabled={false}
                                require={true} 
                                onSelect={setFieldSelected}
                                onChange={(event) => setPeriodIni(event.target.value)} />
                            </div>
                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180, marginTop: 10}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 90, marginLeft: 10}}>
                              <InputMasked
                                type="text"
                                name="reportExpirationClientBilletPeriodEnd"
                                description="Final"
                                mask="99"
                                value={periodEnd}
                                disabled={false}
                                require={true} 
                                onSelect={setFieldSelected}
                                onChange={(event) => setPeriodEnd(event.target.value)} />
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 920) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                            :
                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                              : (width < 640) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexGrow: 0, marginLeft: 10}}>

                            <div style={showMenu ?                            
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}
                                : (width < 720) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}
                                : {display: "flex", flexGrow: 0, minWidth: 170, maxWidth: 170}}>
                              <Select
                                name="reportExpirationClientBilletNfse"
                                description="NFSe"
                                value={nfse || 0}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={0}
                                onSelect={setFieldSelected}
                                set={setNfse} />
                            </div>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180, marginTop: 10}
                                : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 190, marginLeft: 10}
                                : (width < 720) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180, marginTop: 10}
                                : (width < 920) ? {display: "flex", flexGrow: 1, minWidth: 190, marginLeft: 10}
                                : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190, marginLeft: 10}
                              :
                                (width < 450) ? {display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180, marginTop: 10}
                                : (width < 920) ? {display: "flex", flexGrow: 1, minWidth: 190, marginLeft: 10}
                                : {display: "flex", flexGrow: 0, minWidth: 190, maxWidth: 190, marginLeft: 10}}>
                              <Select
                                name="reportExpirationClientBilletSend"
                                description="Envio"
                                value={send || 0}
                                require={false}
                                options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Boleto\" }"+
                                  ", {\"ID\": 2, \"VALUE\": \"Pessoalmente\" }] }")}
                                image={false}
                                typeImage={0}
                                typeInput={0}                          
                                onSelect={setFieldSelected}
                                set={setSend} />    
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
              }
            </div>
          }

          { showResult &&
            <>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={print}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                  marginLeft: 5, width: 147}} onClick={returnSearch}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>
            
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>
               
              <div id="print">
                <div style={showMenu ?
                    (width < 980) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                  :
                    (width < 720) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}}>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}
                    :
                      (width < 720) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}}>

                      { jsonCompany.CPF_CNPJ === "07824993000165" && 
                      <img src={image_07824993000165} alt="AVATAR" /> }
                  </div>

                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                    : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>VENCIMENTO BOLETOS X CLIENTES</label>
                    </div>

                    <div style={showMenu ?
                        (width < 980) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}
                      :
                        (width < 720) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>{getDate()}</label>
                    </div>
                  </div>
                </div>

                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginLeft: 20, marginRight: 20}}>

                  { result.OBJECT.map(capa => (
                    capa.lstITEM.map((item, index) => (                      
                      item.TYPE === 1 ?
                        <div style={showMenu ?
                            (width < 1390) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                          :
                            (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                          <div style={showMenu ?
                              (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                            <div style={showMenu ?
                                (width < 960) ?
                                  {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                  borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                  color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                  backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 100, 
                                  maxWidth: 100}
                              :
                                (width < 690) ?
                                  {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                  borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                  color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                :
                                  {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                  borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                  backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5,  minWidth: 100, 
                                  maxWidth: 100}}>
                              <label>Cod.</label>
                            </div>

                            <div style={showMenu ?
                                (width < 960) ?
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                : (width < 1390) ?
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 540}
                                : 
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                  color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 540}
                              :
                                (width < 690) ?
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                : (width < 1120) ?
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 540}
                                :
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                  color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 540}}>
                              <label>Cliente</label>
                            </div>
                          </div>
                          
                          <div style={showMenu ?
                              (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                            :
                              (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                    webkitPrintColorAdjust: "exact", wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                  : (width < 760) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact",
                                    minWidth: 40}
                                  : 
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 40, maxWidth: 40}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                  : (width < 480) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                    borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", minWidth: 40}
                                  :
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 40, maxWidth: 40}}>
                                <label>Dia</label>
                              </div>

                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                  : (width < 760) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                    backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5,  minWidth: 40}
                                  :
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 40, maxWidth: 40}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, backgroundColor: "rgb(0, 178, 177)", color: "white",
                                    webkitPrintColorAdjust: "exact", wordBreak: "break-all", minWidth: 180}
                                  : (width < 480) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                    borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                    borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1,                                     
                                    backgroundColor: "rgb(0, 178, 177)", color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 40}
                                  :
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 40, maxWidth: 40}}>
                                <label>NSE</label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              :
                                (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                    webkitPrintColorAdjust: "exact", wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                  : (width < 1390) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 200}
                                  :
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 200,  maxWidth: 200}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                    borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 180}
                                  : (width < 1120) ?
                                    {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 200}
                                  :
                                    {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                    borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", 
                                    color: "white", webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 200, maxWidth: 200}}>
                                <label>Valor</label>
                              </div>

                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                :
                                  {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                  borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                  borderBottomColor: "black", borderBottomWidth: 1, backgroundColor: "rgb(0, 178, 177)", color: "white", 
                                  webkitPrintColorAdjust: "exact", paddingLeft: 5, minWidth: 140, maxWidth: 140}}>
                                <label>Envio</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      : item.TYPE === 2 ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

                          { (index % 2 === 0) ?
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                backgroundColor: "#c9c9c9"}}>

                                <div style={showMenu ?
                                    (width < 1390) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 960) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          minWidth: 100, maxWidth: 100}
                                      :
                                        (width < 690) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          minWidth: 100, maxWidth: 100}}>
                                      <label>{item.CODE}</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 960) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1390) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                        : 
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                      :
                                        (width < 690) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1120) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                        :
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}}>
                                      <label>{item.NAME}</label>
                                    </div>
                                  </div>
                                  
                                  <div style={showMenu ?
                                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                            borderBottomWidth: 1, minWidth: 40}
                                          : 
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 480) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                            borderBottomWidth: 1, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}}>
                                        <label>{String(capa.DAY).padStart(2, '0') }</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 480) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}}>
                                        <label>{String(item.NFSE) === "1" ? "Sim": "Não"}</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 1390) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                            maxWidth: 200}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 1120) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                            maxWidth: 200}}>
                                        <label>{formatNumeric(item.VALUE, 2)}</label>
                                      </div>

                                      <div style={(width < 450) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 140, maxWidth: 140}}>
                                        <label>{String(item.SEND) === "1" ? "Boleto" : "Pessoalmente"}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            :
                              <div style={{display: "flex", flexDirection: "column", flexGrow: 0, webkitPrintColorAdjust: "exact",
                                backgroundColor: "#dcdcdc"}}>

                                <div style={showMenu ?
                                    (width < 1390) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  :
                                    (width < 1120) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 960) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :
                                      (width < 690) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={showMenu ?
                                        (width < 960) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          minWidth: 100, maxWidth: 100}
                                      :
                                        (width < 690) ?
                                          {display: "flex", flexDirection: "row", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexDirection: "row", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", 
                                          borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, 
                                          minWidth: 100, maxWidth: 100}}>
                                      <label>{item.CODE}</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 960) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1390) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                        : 
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                      :
                                        (width < 690) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        : (width < 1120) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}
                                        :
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 540}}>
                                      <label>{item.NAME}</label>
                                    </div>
                                  </div>
                                  
                                  <div style={showMenu ?
                                      (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                            borderBottomWidth: 1, minWidth: 40}
                                          : 
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 480) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                                            borderBottomWidth: 1, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}}>
                                        <label>{String(capa.DAY).padStart(2, '0') }</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 760) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                          : (width < 480) ?
                                            {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center", borderLeftStyle: "solid", 
                                            borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", borderRightColor: "black", 
                                            borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, 
                                            paddingLeft: 5, minWidth: 40}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 40, 
                                            maxWidth: 40}}>
                                        <label>{String(item.NFSE) === "1" ? "Sim": "Não"}</label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : (width < 1390) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                        : (width < 1120) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>
                                      <div style={showMenu ?
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 1390) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                            maxWidth: 200}
                                        :
                                          (width < 450) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                            borderBottomColor: "black", borderBottomWidth: 1, wordBreak: "break-all", paddingLeft: 5, minWidth: 180}
                                          : (width < 1120) ?
                                            {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200}
                                          :
                                            {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                            borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 200, 
                                            maxWidth: 200}}>
                                        <label>{formatNumeric(item.VALUE, 2)}</label>
                                      </div>

                                      <div style={(width < 450) ?
                                          {display: "flex", flexGrow: 1, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, wordBreak: "break-all", minWidth: 180}
                                        :
                                          {display: "flex", flexGrow: 0, borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                                          borderBottomColor: "black", borderBottomWidth: 1, paddingLeft: 5, minWidth: 140, maxWidth: 140}}>
                                        <label>{String(item.SEND) === "1" ? "Boleto" : "Pessoalmente"}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          }

                        </div>
                      : item.TYPE === 3 ?
                        <div style={showMenu ?
                            (width < 1580) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", 
                              color: "white", webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                              borderBottomColor: "black", borderBottomWidth: 1, paddingTop: 10, paddingBottom: 10}
                            : 
                              {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", 
                              color: "white", webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                              borderBottomColor: "black", borderBottomWidth: 1, paddingTop: 10, paddingBottom: 10}
                          :
                            (width < 1250) ?
                              {display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", 
                              color: "white", webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                              borderBottomColor: "black", borderBottomWidth: 1, paddingTop: 10, paddingBottom: 10}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", 
                              color: "white", webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                              borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", 
                              borderBottomColor: "black", borderBottomWidth: 1, paddingTop: 10, paddingBottom: 10}}>

                          <div style={showMenu ?
                              (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                            :
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>
                            <div style={showMenu ?
                                (width < 940) ? {display: "flex", flexGrow: 1}
                                : {display: "flex", flexGrow: 1, flexBasis: 1}
                              :  
                                (width < 640) ? {display: "flex", flexGrow: 1}
                                : {display: "flex", flexGrow: 1, flexBasis: 1}}>
                              <label style={{paddingLeft: 5}}>Total NF-e: {item.TOTAL_NFE}</label>
                            </div>
                            <div style={showMenu ?
                                (width < 940) ? {display: "flex", flexGrow: 1}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :
                                (width < 640) ? {display: "flex", flexGrow: 1}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                              <label style={{paddingLeft: 5}}>Total Boleto: {item.TOTAL_BILLET}</label>
                            </div>
                          </div>

                          <div style={showMenu ?
                              (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1580) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                            :
                              (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : (width < 1250) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                              : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                            <div style={{display: "flex", flexGrow: 1, flexBasis: 1}}>
                              <label style={{paddingLeft: 5}}>Total Pessoalmente: {item.TOTAL_PERSONALLY}</label>
                            </div>
                            <div style={showMenu ?
                                (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center"}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                              :  
                                (width < 640) ? {display: "flex", flexGrow: 1, flexBasis: 1, justifyContent: "center"}
                                : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                              <label style={{paddingLeft: 5}}>R$: { formatNumeric(item.TOTAL_VALUE, 2)} para {item.TOTAL_CLIENT} cliente(s)</label>
                            </div>
                          </div>
                        </div>
                      :
                        <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", 
                          color: "white", webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, 
                          borderRightStyle: "solid", borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", 
                          borderBottomWidth: 1}}>
                          <label>Valor Total: R$ {formatNumeric(item.TOTAL_GENERAL, 2)} para {item.TOTAL_CLIENT_GENERAL} clientes</label>
                        </div>
                    ))
                  ))}

                  <div style={{display: "flex", flexGrow: 0, justifyContent: "center", backgroundColor: "rgb(0, 178, 177)", color: "white",
                    webkitPrintColorAdjust: "exact", borderLeftStyle: "solid", borderLeftColor: "black", borderLeftWidth: 1, borderRightStyle: "solid", 
                    borderRightColor: "black", borderRightWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderBottomWidth: 1}}>
                    <label>DESENVOLVEDORA DE SISTEMAS - SGE - MULTIPLA SISTEMAS - (031) 2511-0909 - www.multiplasistemas.com.br</label>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ReportExpirationClientBillet