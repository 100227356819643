import React, { useState, useEffect } from "react"

const AccessControlFinance = ({permissionBilletBatch, permissionBillPay, permissionBillReceive, permissionBordereauPay,
  permissionBordereauReceive, permissionDapPay, permissionDapReceive, permissionDocumentType, permissionPaymentMethod, permissionReportBillPay,
  permissionReportBillReceive, setBilletBatch, setBillPay, setBillReceive, setBordereauPay, setBordereauReceive, setDapPay,
  setDapReceive, setDocumentType, setPaymentMethod, setReportBillPay, setReportBillReceive, showMenu, width}) => {

  const [billetBatchF, setBilletBatchF] = useState(1)
  const [billPayF, setBillPayF] = useState(1)
  const [billReceiveF, setBillReceiveF] = useState(1)
  const [bordereauPayF, setBordereauPayF] = useState(1)
  const [bordereauReceiveF, setBordereauReceiveF] = useState(1)
  const [dapPayF, setDapPayF] = useState(1)
  const [dapReceiveF, setDapReceiveF] = useState(1)
  const [documentTypeF, setDocumentTypeF] = useState(1)
  const [paymentMethodF, setPaymentMethodF] = useState(1)
  const [reportBillPayF, setReportBillPayF] = useState(1)
  const [reportBillReceiveF, setReportBillReceiveF] = useState(1)

  const handleBilletBatch = () => {
    setBilletBatchF(2)
    setBilletBatch("F")
  }

  const handleBilletBatchC = () => {
    setBilletBatchF(1)
    setBilletBatch("T")
  }

  const handleBillPay = () => {
    setBillPayF(2)
    setBillPay("F")
  }

  const handleBillPayC = () => {
    setBillPay(1)
    setBillPay("T")
  }

  const handleBillReceive = () => {
    setBillReceiveF(2)
    setBillReceive("F")
  }

  const handleBillReceiveC = () => {
    setBillReceiveF(1)
    setBillReceive("T")
  }

  const handleBordereauPay = () => {
    setBordereauPay(2)
    setBordereauPay("F")
  }

  const handleBordereauPayC = () => {
    setBordereauPay(1)
    setBordereauPay("T")
  }

  const handleBordereauReceive = () => {
    setBordereauReceive(2)
    setBordereauReceive("F")
  }

  const handleBordereauReceiveC = () => {
    setBordereauReceive(1)
    setBordereauReceive("T")
  }

  const handleDapPay = () => {
    setDapPay(2)
    setDapPay("F")
  }

  const handleDapPayC = () => {
    setDapPay(1)
    setDapPay("T")
  }

  const handleDapReceive = () => {
    setDapReceiveF(2)
    setDapReceive("F")
  }

  const handleDapReceiveC = () => {
    setDapReceive(1)
    setDapReceive("T")
  }

  const handleDocumentType = () => {
    setDocumentType(2)
    setDocumentType("F")
  }

  const handleDocumentTypeC = () => {
    setDocumentType(1)
    setDocumentType("T")
  }

  const handlePaymentMethod = () => {
    setPaymentMethod(2)
    setPaymentMethod("F")
  }

  const handlePaymentMethodC = () => {
    setPaymentMethod(1)
    setPaymentMethod("T")
  }

  const handleReportBillPay = () => {
    setReportBillPay(2)
    setReportBillPay("F")
  }

  const handleReportBillPayC = () => {
    setReportBillPay(1)
    setReportBillPay("T")
  }

  const handleReportBillReceive = () => {
    setReportBillReceive(2)
    setReportBillReceive("F")
  }

  const handleReportBillReceiveC = () => {
    setReportBillReceive(1)
    setReportBillReceive("T")
  }

  useEffect(() => {
    if (permissionBilletBatch === "T") setBilletBatchF(1)
    else setBilletBatchF(2)

    if (permissionBillPay === "T") setBillPayF(1)
    else setBillPayF(2)

    if (permissionBillReceive === "T") setBillReceiveF(1)
    else setBillReceiveF(2)

    if (permissionBordereauPay === "T") setBordereauPayF(1)
    else setBordereauPayF(2)

    if (permissionBordereauReceive === "T") setBordereauReceiveF(1)
    else setBordereauReceiveF(2)

    if (permissionDapPay === "T") setDapPayF(1)
    else setDapPayF(2)

    if (permissionDapReceive === "T") setDapReceiveF(1)
    else setDapReceiveF(2)

    if (permissionDocumentType === "T") setDocumentTypeF(1)
    else setDocumentTypeF(2)

    if (permissionPaymentMethod === "T") setPaymentMethodF(1)
    else setPaymentMethodF(2)

    if (permissionReportBillPay === "T") setReportBillPayF(1)
    else setReportBillPayF(2)

    if (permissionReportBillReceive === "T") setReportBillReceiveF(1)
    else setReportBillReceiveF(2)

  }, [permissionBilletBatch, permissionBillPay, permissionBillReceive, permissionBordereauPay, permissionBordereauReceive, permissionDapPay,
    permissionDapReceive, permissionDocumentType, permissionPaymentMethod, permissionReportBillPay, permissionReportBillReceive])

  return(
    <div style={{marginTop: 10}}>
      <h3>Financeiro</h3>
      <hr/>
      
      <div style={{marginTop: 2}}>
        <h4>Cadastro</h4>
      </div>

      {/* Document Type */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Tipo Documento</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlDocumentType"
                checked={(documentTypeF === 1) ? true : false}
                onChange={(documentTypeF === 1) ? handleDocumentType : handleDocumentTypeC} />
            </div>
          </div>
        </div>
      </div>

      {/* Payment Method */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Forma Pagamento</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlPaymentMethod"
                checked={(paymentMethodF === 1) ? true : false}
                onChange={(paymentMethodF === 1) ? handlePaymentMethod : handlePaymentMethodC} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 2}}>
        <h4>Processo</h4>
      </div>

      {/* Bill Pay */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Conta a Pagar</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBillPay"
                checked={(billPayF === 1) ? true : false}
                onChange={(billPayF === 1) ? handleBillPay : handleBillPayC} />
            </div>
          </div>
        </div>
      </div>

      {/* Bordereau Pay */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Borderô a Pagar</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBordereauPay"
                checked={(bordereauPayF === 1) ? true : false}
                onChange={(bordereauPayF === 1) ? handleBordereauPay : handleBordereauPayC} />
            </div>
          </div>
        </div>
      </div>

      {/* DAP Pay */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>DAP a Pagar</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlDapPay"
                checked={(dapPayF === 1) ? true : false}
                onChange={(dapPayF === 1) ? handleDapPay : handleDapPayC} />
            </div>
          </div>
        </div>
      </div>

      {/* Bill Receive */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Conta a Receber</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBillReceive"
                checked={(billReceiveF === 1) ? true : false}
                onChange={(billReceiveF === 1) ? handleBillReceive : handleBillReceiveC} />
            </div>
          </div>
        </div>
      </div>

      {/* Bordereau Receive */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Borderô a Receber</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBordereauReceive"
                checked={(bordereauReceiveF === 1) ? true : false}
                onChange={(bordereauReceiveF === 1) ? handleBordereauReceive : handleBordereauReceiveC} />
            </div>
          </div>
        </div>
      </div>

      {/* DAP Receive */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>DAP a Receber</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlDapReceive"
                checked={(dapReceiveF === 1) ? true : false}
                onChange={(dapReceiveF === 1) ? handleDapReceive : handleDapReceiveC} />
            </div>
          </div>
        </div>
      </div>

      {/* Billet Batch */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Lote do Borderô</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlBilletBatch"
                checked={(billetBatchF === 1) ? true : false}
                onChange={(billetBatchF === 1) ? handleBilletBatch : handleBilletBatchC} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 2}}>
        <h4>Relatório</h4>
      </div>

      {/* Report Bill Pay */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Conta a Pagar</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlReportBillPay"
                checked={(reportBillPayF === 1) ? true : false}
                onChange={(reportBillPayF === 1) ? handleReportBillPay : handleReportBillPayC} />
            </div>
          </div>
        </div>
      </div>

      {/* Report Bill Receive */}
      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 5}}>
        <div style={(showMenu)  ?
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : (width < 790) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2, maxWidth: 600}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}
          :
            (width < 580) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 2}
            : {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 2, maxWidth: 500}}>

          <div style={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-start", justifyContent: "end"}}>
            <div style={{display: "flex", flexGrow: 1}}>
              <label style={{textAlign: "center"}}>Conta a Receber</label>
            </div>
          </div>

          <div style={(showMenu) ?
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
              : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}
            :
              (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
              : {display: "flex", flexGrow: 0, flexDirection: "column"}}>

            <div style={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
              <label style={{fontWeight: 600}}>Permitido</label>
            </div>
            <div style={{display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 5}}>
              <input
                type="checkbox"
                name="cashierControlReportBillReceive"
                checked={(reportBillReceiveF === 1) ? true : false}
                onChange={(reportBillReceiveF === 1) ? handleReportBillReceive : handleReportBillReceiveC} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessControlFinance