import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Input from "../../../Components/Input"
import Questionnaire from "../../../Components/Questionnaire"
import SearchButtons from "../../../Components/SearchButtons"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchEmployee = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu, width}) => {
  const [fieldSelected, setFieldSelected] = useState("")

  const [code, setCode] = useState("")
  const [registration, setRegistration] = useState("")
  const [name, setName] = useState("")
  const [situation, setSituation] = useState(0)

  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")
  const [errorImport, setErrorImport] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []
    let search = false

    let jEmployee = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
    if (registration !== ""){
      jEmployee += ", \"REGISTRATION\": "+ registration
      search = true
    }
    if (name !== ""){
      jEmployee += ", \"NAME\": \""+ name + "\""
      if (name.length > 2) search = true
    }
    if (situation !== 0){
      jEmployee += ", \"SITUATION\": \""+ situation + "\""
      search = true
    }
    jEmployee += " }"

    if (jEmployee === "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }") search = true

    if (search) {
      setLoading("Aguarde buscando as informações...")
      setWarning("")
      setError("")

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jEmployee)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "EMPLOYEE/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de funcionários do RH. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchEmployeeName") {
            document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
          }
          else if (fieldSelected === "searchEmployeeSituation") {
            document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
          }
          else {
            document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
          }

          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchEmployeeName") {
            document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
          }
          else if (fieldSelected === "searchEmployeeSituation") {
            document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
          }
          else {
            document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
          }

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jEmployee)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "EMPLOYEE/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os funcionários do RH. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchEmployeeName") {
              document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
            }
            else if (fieldSelected === "searchEmployeeSituation") {
              document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
            }
            else {
              document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
            }

            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchEmployeeName") {
            document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
          }
          else if (fieldSelected === "searchEmployeeSituation") {
            document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
          }
          else {
            document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
          }

        })
      })
    }
    else
    {
      setLoading("")
      setWarning("")
      setError("")
      setShowResult(false)
      
      if (fieldSelected === "searchEmployeeName") {
        document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
      }
      else if (fieldSelected === "searchEmployeeSituation") {
        document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
      }
      else {
        document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
      }
    }
  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, pageCurrent, recordPage, registration, name, situation, updatePage])

  const callDelete = (item) => {
    setCode(item.ID)
    setAskQuestionnaire("Deseja realmente excluir o funcionário (" + item.NAME + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const clean = () => {
    setRegistration("")
    setName("")
    setSituation(0)

    setErrorImport("")
    setUpdatePage(updatePage + 1)
  }

  const confirmDelete = () => {
    let returnJson = []
    const jEmployee = "{ \"ID\": "+ code +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }"

    setLoading("Aguarde excluido o registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jEmployee)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "EMPLOYEE/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível excluir o funcionário do RH. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchEmployeeName") {
          document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
        }
        else if (fieldSelected === "searchEmployeeSituation") {
          document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
        }
        else {
          document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
        }

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)
      setCode(0)
      setLoading("")

      if (pageCurrent !== 1) setPageCurrent(1)
      else setUpdatePage(updatePage + 1)
    })
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "EMPLOYEE/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os funcionarios do SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setCode(0)
        setLoading("")

        if (fieldSelected === "searchEmployeeName") {
          document.getElementById("searchEmployeeName") && document.getElementById("searchEmployeeName").focus()
        }
        else if (fieldSelected === "searchEmployeeSituation") {
          document.getElementById("searchEmployeeSituation") && document.getElementById("searchEmployeeSituation").focus()
        }
        else {
          document.getElementById("searchEmployeeRegistration") && document.getElementById("searchEmployeeRegistration").focus()
        }

        return
      }

      returnJson.WARNING && setErrorImport(returnJson.WARNING)

      setLoading("")

      setUpdatePage(99)
      setUpdatePage(1)
    })
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmDelete()}
                  setCancel={() => cancelDelete()} />
              :
                <>
                  <SearchButtons modeSearch={modeSearch} jsonCompany={jsonCompany} setAdd={() => setAdd()} clean={clean}
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    { errorImport &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{errorImport}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={(showMenu) ?
                          (width < 940) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                        : 
                          (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                        <div style={(showMenu) ?
                            (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                            : (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                          : 
                            (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={(showMenu) ? 
                              (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}
                              : (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}
                            : 
                              (width < 500) ? {display: "flex", flexGrow: 1, minWidth: 180}
                              : {display: "flex", flexGrow: 0, minWidth: 140, maxWidth: 140}}>

                            <Input
                              type="search"
                              name="searchEmployeeRegistration"
                              description="Matrícula"
                              planceHolder=""
                              value={registration}
                              maxLength={11}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setRegistration(event.target.value)} />
                          </div>
                          <div style={(showMenu) ? 
                              (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : (width < 690) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                              : (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                            : 
                              (width < 500) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                              : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>

                            <Input
                              type="search"
                              name="searchEmployeeName"
                              description="Nome"
                              planceHolder="Mínimo 3 caracteres"
                              value={name}
                              maxLength={50}
                              disabled={false}
                              require={false}
                              image={false}
                              typeImage={0}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={(event) => setName(event.target.value)} />
                          </div>
                        </div>
                        <div style={(showMenu) ? 
                            (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                          : 
                            (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>

                          <Select
                            name="searchEmployeeSituation"
                            description="Situação"
                            value={situation || 0}
                            disabled={false}
                            require={false}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Ativo\" }"+
                              ", {\"ID\": 2, \"VALUE\": \"Inativo\" }] }")}
                            image={false}
                            typeImage={0}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setSituation} />
                        </div>
                      </div>

                      { showResult && 
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 2, marginTop: 15}}>
                                <div style={(showMenu) ? 
                                    (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                  : 
                                    (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                  <div style={(showMenu) ? 
                                      (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={(showMenu) ?
                                        (width < 860) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                          minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 120,
                                          maxWidth: 120}
                                      : 
                                        (width < 560) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5, 
                                          minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 120,
                                          maxWidth: 120}}>

                                      <label style={{fontWeight: 600}}>Matrícula</label>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                      minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Nome</label>
                                    </div>
                                  </div>
                                  <div style={(showMenu) ? 
                                      (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                        minWidth: 180}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 90,
                                        maxWidth: 90}
                                    : 
                                      (width < 780) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                        minWidth: 180}
                                      : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 90,
                                        maxWidth: 90}}>

                                    <label style={{fontWeight: 600}}>Situação</label>
                                  </div>
                                </div>
                                <div style={(showMenu) ? 
                                    (width < 390) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                  : 
                                    (width < 500) ? {display: "none"}
                                    : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>

                                  <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                    paddingLeft: 5, height: 32, width: 32}}/>

                                  <div style={(modeSearch) ? {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5,
                                      paddingBottom: 5, paddingLeft: 0}
                                    : {display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 0,
                                      marginTop: 2, height: 28, width: 2}} />
                                </div>
                              </div>
                              <hr/>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={(showMenu) ?
                                    (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                  : 
                                    (width < 268) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4, minWidth: 180}
                                    : (width < 500) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)",
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5,
                                      paddingTop: 4, paddingBottom: 4, marginTop: 4}}>

                                  <div style={(showMenu) ? 
                                      (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}
                                    : 
                                      (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10}}>

                                    <div style={(showMenu) ? 
                                        (width < 860) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 560) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(showMenu) ? 
                                          (width < 860) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 180}
                                          : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 120,
                                            maxWidth: 120}
                                        : 
                                          (width < 560) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 180}
                                          : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 120,
                                            maxWidth: 120}}>

                                        <label>{(item.REGISTRATION !== 0) && item.REGISTRATION}</label>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                        minWidth: 180}}>

                                        <label>{item.NAME}</label>
                                      </div>
                                    </div>
                                    <div style={(showMenu) ? 
                                        (width < 980) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                          minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 90,
                                          maxWidth: 90}
                                      : 
                                        {display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5, minWidth: 90,
                                          maxWidth: 90}}>

                                      <label>{item.SITUATION === 1 ? "Ativo" : "Inativo"}</label>
                                    </div>
                                  </div>
                                  <div style={showMenu ?
                                      (width < 390) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}
                                    :
                                      (width < 500) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                      : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 85}}>
                                        
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      <img
                                        style={{height: 32, width: 32}}
                                        name="searchEmployeeSelected"
                                        src={image_select}
                                        alt="Selecionar"
                                        onClick={(() => setSelected(item))} />
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", paddingTop: 5, paddingRight: 5, paddingBottom: 5,
                                      paddingLeft: 5}}>

                                      { !modeSearch &&
                                        <img 
                                          style={{height: 28, width: 28, marginTop: 2}}
                                          name="searchEmployeeDelete"
                                          src={image_delete}
                                          alt="Deletar"
                                          onClick={() => callDelete(item)} />
                                      }
                                    </div>
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination 
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}

export default SearchEmployee