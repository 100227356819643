
import "./style.css"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DataPicker from "../../Components/DataPicker"
import Footer from "../../Components/Footer"
import Menu from "../Menu"
import Select from "../../Components/Select"
import image_07824993000165 from '../../Images/logos/07824993000165.png';
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_menu from "../../Images/Menu/menu.png"
import image_multiplaWhite from "../../Images/logo_multipla_branca.png"
import image_previous from "../../Images/Buttons/previous.png"
import image_printer from "../../Images/Buttons/printer.png"
import image_search from "../../Images/Buttons/search.png"

const ReportBankStatement = ({databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [initialPeriod, setInitialPeriod] = useState(null)
  const [finalPeriod, setFinalPeriod] = useState(null)
  const [accountCode, setAccountCode] = useState("0")
  const [order, setOrder] = useState("0")

  const [showMenu, setShowMenu] = useState(true)  
  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")  

  ////
  // Update Records // Atualizar registros
  ////
  const [optionsAccount, setOptionsAccount] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")
  
  useEffect(() => {
    let returnJson = []

    ////
    // Load account information // Carrega as informações das contas
    ////
    setWarning("")
    setError("")
    setLoading("Aguarde buscando as informações das contas...")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } }")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("QUANTITY_INITIAL", 0)
    formData.append("QUANTITY_RECORDS", 1000)

    axios.post(BASE_URL + "ACCOUNT/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar as contas. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("") 
        return
      }
      
      let account = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"

      returnJson && returnJson.OBJECT &&
        returnJson.OBJECT.map(item => (
          account += ", { \"ID\": "+ item.ID +", \"VALUE\": \""+ item.NUMBER + "-" + item.OWNER +"\" }"
        ))

      account += "]}"

      setOptionsAccount(account)
      setLoading("")
    })
  }, [])

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setInitialPeriod(null)
    setFinalPeriod(null)
    setAccountCode("0")
    setOrder("0")
  }

  const formatValue = (value, decimals) => {
    if (!value) {
      return "0.00"
    }

    if (value === ".") {
      return value = "0."
    }
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
    const decimalsNumber = value.toString().match(regex)[0]
    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) {
      return "0"
    }

    return parsed
  }

  const getDate = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    return today.toLocaleString();
  }

  const print = () => {
    window.print()
  }

  const returnSearch = () => {
    setInitialPeriod("")
    setFinalPeriod("")
    setAccountCode("0")
    setOrder("0")

    setShowResult(false)
    
    setResult({})
    setLoading(null)
    setWarning("")
    setError("")
  }

  const search = () => {
    let returnJson = []

    if (initialPeriod === "") {
      setError("É necessário informar o período inicial.")
      document.getElementById("reportBankStatementInitialPeriod") && document.getElementById("reportBankStatementInitialPeriod").focus()
      return
    }

    if (finalPeriod === "") {
      setError("É necessário informar o período final.")
      document.getElementById("reportBankStatementFinalPeriod") && document.getElementById("reportBankStatementFinalPeriod").focus()      
      return
    }

    if (order === "0") {
      setError("É necessário informar o ordenar.")
      document.getElementById("reportBankStatementOrder") && document.getElementById("reportBankStatementOrder").focus()
      return
    }

    let iniPeriod = ""
    let finPeriod = ""

    if ((validateDate(initialPeriod)) && (validateDate(finalPeriod)))
    {
      const periodI = initialPeriod.toLocaleString()
      const periodF = finalPeriod.toLocaleString()

      iniPeriod = String(periodI).substr(6,4) + "-" + String(periodI).substr(3,2) + "-" + String(periodI).substr(0,2) + "T00:00:00";
      finPeriod = String(periodF).substr(6,4) + "-" + String(periodF).substr(3,2) + "-" + String(periodF).substr(0,2) + "T23:59:59";
    }        
    else
    {
      setError("Período inicial ou final inválido.")
      document.getElementById("reportBankStatementInitialPeriod") && document.getElementById("reportBankStatementInitialPeriod").focus()      
      return            
    }

    if (accountCode === "0") {
      setError("É necessário informar uma conta.")
      document.getElementById("reportBankStatementAccountCode") && document.getElementById("reportBankStatementAccountCode").focus()
      return
    }        

    setLoading("")
    setError("")
    setLoading("Aguarde buscando as informações...")    

    const jCapaBankStatement = "{ \"ACCOUNT\": { \"ID\": "+ accountCode + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " } }" +
      ", \"INITIAL_DATE\": \""+ iniPeriod + "\"" + ", \"FINAL_DATE\": \""+ finPeriod + "\" }"
    
    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jCapaBankStatement)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("ORDER", order)

    axios.post(BASE_URL + "BANK_STATEMENT/Report", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os extratos da conta. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowResult(false)
        setLoading("")
        return
      }

      if (returnJson.WARNING === "Nenhum registro encontrado.") {
        setWarning("Nenhum registro encontrado com o filtro informado.")
        return
      }

      setResult(returnJson)
      setShowResult(true)
      setLoading("")
    })
  }

  const setFieldSelected = () => {
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");        

    if (date.search(ExpReg) === -1) error = true
    else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30)) error = true
    else if (ardt[1]===2) {
      if ((ardt[0] > 28) && ((ardt[2]%4) !== 0))
        error = true;
      if ((ardt[0] > 29) && ((ardt[2]%4) === 0))
        error = true;
    }

    if (error) return false;
    return true;
  }    

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, borderCollapse: "collapse", minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu && 
          <div style={{display: "flex", flexGrow: 0, flexDirection: "row"}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} width={width} logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(0, 177, 171)", paddingTop: 15, paddingBottom: 15}}>
            { showMenu ?
                <div style={(width < 520) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", paddingLeft: 10, paddingRight: 10}
                  :
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center", paddingLeft: 10,
                    paddingRight: 15}}>

                  <div style={{display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5,
                    paddingRight: 5, marginTop: 5}}>
                      <label style={
                        (width < 520) ? {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>Extrato da Conta Bancária</label>
                  </div>

                  { (width < 520) ?
                      <div style={{marginTop: 10}}>
                        <img src={image_multiplaWhite} 
                          style={{ height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={{ height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
              :
                <div style={(width < 560) ?
                    {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}
                  : 
                    {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-between", alignItems: "center",
                    paddingLeft: 10, paddingRight: 15}}>

                  <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "flex-start", alignItems: "center",
                      paddingLeft: 10, paddingRight: 10}}>
                        <img src={image_menu}
                        style={(width < 360) ? { height: "auto", width: 30 } : { height: "auto", width: 35 }}
                        alt="Retrair"
                        onClick={(() => { setShowMenu(!showMenu) })} />
                    </div>

                    <div style={(width < 420) ? 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5, marginTop: 15}
                      : 
                        {display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", paddingLeft: 5, paddingRight: 5, marginTop: 5}}>
                      <label style={
                        
                        (width < 420) ? {color: "white", fontSize: 16, fontWeight: 600, textAlign: "center"} 
                        : (width < 760) ? {color: "white", fontSize: 14, fontWeight: 600, textAlign: "center"} 
                        : {color: "white", fontSize: 18, fontWeight: 600, textAlign: "center"}}>Extrato da Conta Bancária</label>
                    </div>
                  </div>

                  { (width < 560) ?
                      <div style={{display: "flex", flex: 1, marginTop: 10, justifyContent: "center"}}>
                        <img src={image_multiplaWhite} 
                          style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                          alt="Logomarca" />
                      </div>
                    :
                      <img src={image_multiplaWhite} 
                        style={(width < 360) ? { height: "auto", width: 120 } : { height: "auto", width: 150 }}
                        alt="Logomarca" />
                  }
                </div>
            }
          </div>

          { !showResult &&
            <>
              { loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", alignItems: "center",
                  minHeight: 495}}>
                  <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                </div>
              }

              { !loading &&
                <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
                  <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => search()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => clean()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                      marginLeft: 5, width: 147}} onClick={() => setReturn()}>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                        paddingBottom: 5, paddingLeft: 10}}>
                        <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                        <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                      </div>
                    </div>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning && 
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div> 
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10, paddingLeft: 10,
                        paddingRight: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }
                    
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0, marginBottom: 10,
                      marginLeft: 0}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                    </div>

                    <div style={showMenu ?
                          (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 0, paddingRight: 10, marginBottom: 0,
                          marginLeft: 10}
                        : 
                          {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 0, paddingRight: 10, marginBottom: 0,
                          marginLeft: 10}
                      :
                          (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 0, paddingRight: 10, marginBottom: 0,
                          marginLeft: 10}
                        : 
                          {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 0, paddingRight: 10, marginBottom: 0,
                          marginLeft: 10}}>

                      <div style={showMenu ?
                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                          : (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}
                        :
                          (width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                          : (width < 790) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                          : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                        <div style={showMenu ?
                            (width < 1100) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                          :
                            (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>

                          <DataPicker
                            name="reportBankStatementInitialPeriod"
                            description="Inicial"
                            value={initialPeriod}
                            require={true}
                            onSelect={setFieldSelected}
                            onChange={(event) => setInitialPeriod(event)}
                            intervalo={true}
                            inicial={true}
                            endDate={finalPeriod} />
                        </div>
                        <div style={showMenu ?
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, paddingTop: 10}
                            : (width < 1100) ? {display: "flex", flexGrow: 1, minWidth: 180, paddingLeft: 10}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160, paddingLeft: 10}
                          :
                            (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, paddingTop: 10}
                            : (width < 790) ? {display: "flex", flexGrow: 1, minWidth: 180, paddingLeft: 10}
                            : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160, paddingLeft: 10}}>

                          <DataPicker
                            name="reportBankStatementFinalPeriod"
                            description="Final"
                            value={finalPeriod}
                            require={true}
                            onSelect={setFieldSelected}
                            onChange={(event) => setFinalPeriod(event)}
                            intervalo={true}
                            inicial={false}
                            startDate={initialPeriod} />
                        </div>
                      </div>
                      <div style={showMenu ?
                          (width < 1100) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, paddingLeft: 10, minWidth: 180}
                        :
                          (width < 790) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, paddingLeft: 10, minWidth: 180}}>
                        <Select
                          name="reportBankStatementAccountCode"
                          description="Conta"
                          value={accountCode || "0"}
                          require={true}
                          options={JSON.parse(optionsAccount)}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setAccountCode} />
                      </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, paddingRight: 10, marginBottom: 0, 
                      marginLeft: 10, minWidth: 180}}>
                      <Select
                        name="reportBankStatementOrder"
                        description="Ordenar:"
                        value={order || "0"}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Vencimento\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Baixa\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setOrder} />
                    </div>
                  </div>
                </div>
              }
            </>
          }

          { showResult &&
            <>
              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => print()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_printer} alt="Imprimir"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Imprimir</label>
                  </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                  marginLeft: 5, width: 147}} onClick={() => returnSearch()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10, 
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                  </div>
                </div>
              </div>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
              </div>

              <div id="print">
                <div style={showMenu ?
                    (width < 980) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                  :
                    (width < 720) ?
                      {display: "flex", flexGrow: 0, flexDirection: "column", borderCollapse: "collapse", borderLeftWidth: 1,
                      borderLeftStyle: "solid", borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black",
                      borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}
                    : 
                      {display: "flex", flexGrow: 0, flexDirection: "row", borderCollapse: "collapse", borderLeftWidth: 1, borderLeftStyle: "solid",
                      borderLeftColor: "black", borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "black", borderRightWidth: 1,
                      borderRightStyle: "solid", borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid",
                      borderBottomColor: "black", marginTop: 10, marginRight: 20, marginLeft: 20}}>

                  <div style={showMenu ?
                      (width < 980) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}
                    :
                      (width < 720) ? {display: "flex", flexGrow: 1, justifyContent: "center"}
                      : {display: "flex", flexGrow: 0, paddingLeft: 30}}>

                      { jsonCompany.CPF_CNPJ === "07824993000165" && 
                      <img src={image_07824993000165} alt="AVATAR" /> }
                  </div>

                  <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                    : {display: "flex", flexGrow: 1, flexDirection: "row"}}>
                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>Extrato da Conta Bancária</label>
                    </div>

                    <div style={showMenu ?
                        (width < 980) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}
                      :
                        (width < 720) ? {display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingRight: 10}
                        : {display: "flex", flexGrow: 0, paddingTop: 10, paddingRight: 10}}>
                      <label style={{fontWeight: 600, textAlign: "center"}}>{getDate()}</label>
                    </div>
                  </div>
                </div>

                <div style={{display: "flex", flexGrow: 1, flexDirection: "column", marginLeft: 20, marginRight: 20}}>
                  { result.OBJECT.LstITEM_BANK_STATEMENT.map(item => (
                      item.TYPE === 1 ?
                        <div style={showMenu ?
                            (width < 1100) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "rgb(0, 178, 177)",
                              borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", webkitPrintColorAdjust: "exact"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "rgb(0, 178, 177)",
                              borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", webkitPrintColorAdjust: "exact"}
                          :
                            (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "rgb(0, 178, 177)",
                              borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", webkitPrintColorAdjust: "exact"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "rgb(0, 178, 177)",
                              borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", webkitPrintColorAdjust: "exact"}}>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                            <div style={(width < 450) ?
                                {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : 
                                {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 70, maxWidth: 70}}>
                              <label style={{fontWeight: 600, color: "white"}}>Cód.</label>
                            </div>

                            <div style={showMenu ?
                                (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : (width < 1380) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : {display: "flex", flexGrow: 1, flexDirection: "row"}
                              :
                                (width < 850) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : (width < 1230) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  : 
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}

                                  : 
                                  (width < 850) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  : 
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}}>
                                <label style={{fontWeight: 600, color: "white"}}>Plano de Conta</label>
                              </div>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  : 
                                  (width < 1100) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  :
                                  (width < 1380) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  :
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}

                                  : 
                                  (width < 1230) ? 
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                  : 
                                  {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}}>
                                <label style={{fontWeight: 600, color: "white"}}>Cedente</label>
                              </div>
                            </div>
                          </div>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 1100) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}
                                :   
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 980) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center",  minWidth: 100, maxWidth: 100}}>
                                <label style={{fontWeight: 600, color: "white"}}>Dt. Vencto</label>
                              </div>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                  borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 100}
                                : 
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}}>
                                <label style={{fontWeight: 600, color: "white"}}> Dt. Baixa</label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}
                              :
                                (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 25, maxWidth: 25, paddingLeft: 10}
                                :
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 25, maxWidth: 25}}>
                                <label style={{fontWeight: 600, color: "white"}}>{/* D/C */}</label>
                              </div>
                              <div style={showMenu ?
                                  (width < 1100) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                                :
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                      borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}}>
                                <label style={{fontWeight: 600, color: "white"}}>Valor</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      : ((item.TYPE === 2) && (item.DESCRIPTION === "")) ?
                        <div style={
                          (item.SEQUENTIAL%2 === 0) ?
                            showMenu ?
                              (width < 1100) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                            :
                              (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                          :
                            showMenu ?
                              (width < 1100) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}
                            :
                              (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}}>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                            <div style={(width < 450) ?
                                {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : 
                                {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 70, maxWidth: 70}}>
                              <label>{item.CODE}</label>
                            </div>

                            <div style={showMenu ?
                                (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : (width < 1380) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : {display: "flex", flexGrow: 1, flexDirection: "row"}
                              :
                                (width < 850) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : (width < 1230) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  : 
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}

                                  : 
                                  (width < 850) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  : 
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}}>
                                <label>
                                  {item.ACCOUNT_PLAN.DESCRIPTION}
                                </label>
                              </div>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  : 
                                  (width < 1100) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  :
                                  (width < 1380) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                  :
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                :
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                    borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                                  :
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, flexBasis: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}

                                  : 
                                  (width < 1230) ? 
                                    {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}
                                  : 
                                  {display: "flex", flexGrow: 1, flexBasis: 1, paddingLeft: 5}}>
                                <label>
                                  {item.OWNER}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 1100) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}
                                :   
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 980) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center",  minWidth: 100, maxWidth: 100}}>
                                <label>
                                  { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                    String(item.DUE_DATE).substr(0,4) }
                                </label>
                              </div>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                  borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 100}
                                : 
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}}>
                                <label>
                                  { (item.DATE !== "") && 
                                    String(item.DATE).substr(8,2) + "/" + String(item.DATE).substr(5,2) + "/" + String(item.DATE).substr(0,4) }
                                </label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}
                              :
                                (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 25, maxWidth: 25, paddingLeft: 10}
                                :
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 25, maxWidth: 25}}>
                                <label>
                                  {item.DEBIT_CREDIT}
                                </label>
                              </div>
                              <div style={showMenu ?
                                  (width < 1100) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                                :
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                      borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}}>
                                <label>{formatValue(item.VALUE, 2)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      : ((item.TYPE === 2) && (item.DESCRIPTION !== "")) ?
                        <div style={
                          (item.SEQUENTIAL%2 === 0) ?
                            showMenu ?
                              (width < 1100) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                            :
                              (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#D3D3D3"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#D3D3D3"}
                          :
                            showMenu ?
                              (width < 1100) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}
                            :
                              (width < 980) ? {display: "flex", flexGrow: 0, flexDirection: "column", backgroundColor: "#DCDCDC"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row", backgroundColor: "#DCDCDC"}}>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                            : {display: "flex", flexGrow: 1, flexDirection: "row"}}>

                            <div style={(width < 450) ?
                                {display: "flex", flexGrow: 1, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", paddingLeft: 10, minWidth: 180}
                              : 
                                {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 70, maxWidth: 70}}>
                              <label>{" - "}</label>
                            </div>

                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row"}}>
                              <div style={(showMenu)?
                                  (width < 450) ? {display: "flex", flexGrow: 1, borderRightWidth: 1, borderRightStyle: "solid", 
                                    borderRightColor: "black", borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    paddingLeft: 10}
                                  : (width < 1100) ? {display: "flex", flexGrow: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  : {display: "flex", flexGrow: 1, paddingLeft: 5}
                                :
                                  (width < 450) ? {display: "flex", flexGrow: 1, borderRightWidth: 1, borderRightStyle: "solid", 
                                    borderRightColor: "black", borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black", 
                                    paddingLeft: 10}
                                  : (width < 980) ? {display: "flex", flexGrow: 1, borderRightWidth: 1, borderRightStyle: "solid",
                                    borderRightColor: "black", paddingLeft: 5}
                                  : {display: "flex", flexGrow: 1, paddingLeft: 5}}>
                                <label>
                                  {item.DESCRIPTION}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div style={(width < 450) ? {display: "flex", flexGrow: 0, flexDirection: "column"}
                            : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                              : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={showMenu ?
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 1100) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}
                                :   
                                  (width < 450) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100}
                                  : (width < 980) ?
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", paddingLeft: 10, minWidth: 100, maxWidth: 100}
                                  : 
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center",  minWidth: 100, maxWidth: 100}}>
                                <label>
                                  { String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                    String(item.DUE_DATE).substr(0,4) }
                                </label>
                              </div>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                  borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 100}
                                : 
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100}}>
                                <label>
                                  {" - "}
                                </label>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 1100) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}
                              :
                                (width < 980) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                              <div style={(width < 450) ?
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderLeftWidth: 1,
                                    borderLeftStyle: "solid", borderLeftColor: "black", minWidth: 25, maxWidth: 25, paddingLeft: 10}
                                :
                                  {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", minWidth: 25, maxWidth: 25}}>
                                <label>
                                  {item.DEBIT_CREDIT}
                                </label>
                              </div>
                              <div style={showMenu ?
                                  (width < 1100) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}
                                :
                                  (width < 980) ?
                                    {display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center", borderRightWidth: 1,
                                    borderRightStyle: "solid", borderRightColor: "black", paddingRight: 10, minWidth: 120}
                                  :
                                    {display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", borderRightWidth: 1,
                                      borderRightStyle: "solid", borderRightColor: "black", minWidth: 120, maxWidth: 120}}>
                                <label>{formatValue(item.VALUE, 2)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      :
                        <>
                          <div style={{display: "flex", flexGrow: 0, flexDirection: "row", justifyContent: "center",  borderWidth: 1,
                            borderStyle: "solid", borderColor: "black", webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)",
                            color: "white", paddingTop: 5, paddingBottom: 5}}>
                            <label style={{fontWeight: 600}} >{"Totalização"}</label>
                          </div>

                          <div style={showMenu ?
                              (width < 1200) ?
                                {display: "flex", flexGrow: 0, flexDirection: "column",                          
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black",
                                borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)", color: "white"}
                              : 
                                {display: "flex", flexGrow: 0, flexDirection: "row",                          
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black",
                                borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)", color: "white"}
                            :
                              (width < 1100) ?
                                {display: "flex", flexGrow: 0, flexDirection: "column",
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black",
                                borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)", color: "white"}
                              : 
                                {display: "flex", flexGrow: 0, flexDirection: "row",
                                borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black",
                                borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                                borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black",
                                webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)", color: "white"}}>

                            <div style={showMenu ?
                                (width < 880) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                              : 
                                (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                              <div style={{display: "flex", flexGrow: 1, flexBasis: 1}}>
                                <label style={{fontWeight: 600, paddingLeft: 10}}>Saldo Anterior: {formatValue(item.PREVIOUS_BALANCE, 2)}</label>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, flexBasis: 1}}>
                                <label style={{fontWeight: 600, paddingLeft: 10}}>Débitos: {formatValue(item.TOTAL_DEBIT, 2)}</label>
                              </div>
                            </div>
                            <div style={showMenu ?
                                (width < 880) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                              : 
                                (width < 580) ? {display: "flex", flexGrow: 1, flexDirection: "column", flexBasis: 1}
                                : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                              <div style={{display: "flex", flexGrow: 1, flexBasis: 1}}>
                                <label style={{fontWeight: 600, paddingLeft: 10}}>Créditos: {formatValue(item.TOTAL_CREDIT, 2)}</label>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, flexBasis: 1}}>
                                <label style={{fontWeight: 600, paddingLeft: 10}}>Saldo Atual: {formatValue(item.CURRENT_BALANCE, 2)}</label>
                              </div>
                            </div>
                          </div>
                        </>
                    ))
                  }
                </div>

                <div style={{display: "flex", flexGrow: 0, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "black",
                  borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "black", marginLeft: 20, marginRight: 20, height: 20}} />

                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", borderRightWidth: 1, borderRightStyle: "solid",
                  borderRightColor: "black", borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "black", borderLeftWidth: 1,
                  borderLeftStyle: "solid", borderLeftColor: "black", webkitPrintColorAdjust: "exact", backgroundColor: "rgb(0, 178, 177)",
                  color: "white", fontStretch: 600, marginLeft: 20, marginRight: 20}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>DESENVOLVEDORA DE SISTEMAS - SGE - MULTIPLA SISTEMAS - (031) 2511-0909 - www.multiplasistemas.com.br</label>
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default ReportBankStatement