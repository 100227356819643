import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import DataPicker from "../../../Components/DataPicker"
import InputMasked from "../../../Components/InputMasked"
import InputNumeric from "../../../Components/InputNumeric"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_add from "../../../Images/Buttons/add.png"
import image_clean from "../../../Images/Buttons/clean.png"
import image_loading from "../../../Images/spinner.gif"
import image_previous from "../../../Images/Buttons/previous.png"
import image_search from "../../../Images/Buttons/search.png"
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchBordereauReceive = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setAdd, setSelected, setReturn, showMenu, 
  width}) => {

  const [code, setCode] = useState("")
  const [value, setValue] = useState("")
  const [situation, setSituation] = useState(0)

  const [openDateIni, setOpenDateIni] = useState(null)
  const [openDateEnd, setOpenDateEnd] = useState(null)
  const [cancelDateIni, setCancelDateIni] = useState(null)
  const [cancelDateEnd, setCancelDateEnd] = useState(null)

  const [showResult, setShowResult] = useState(false)

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [updatePage, setUpdatePage] = useState(0)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  useEffect(() => {
    if (updatePage){
      search()
      setUpdatePage(false)
    }
  }, [updatePage])
  
  const clean = () => {
    setCode("")
    setValue("")
    setSituation(0)
  
    setOpenDateIni(null)
    setOpenDateEnd(null)
    setCancelDateIni(null)
    setCancelDateEnd(null)

    setShowResult(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpdatePage(updatePage + 1)
    }
  }  

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpdatePage(updatePage + 1)
      }
    }
  }

  const search = async() => {
    let returnJson = []

    setLoading("")
    setWarning("")
    setError("")

    let search = false
    if (code !== "") search = true
    if (!search) if ((validateDate(openDateIni)) && (validateDate(openDateEnd))) search = true
    if (!search) if ((validateDate(cancelDateIni)) && (validateDate(cancelDateEnd))) search = true
    if (!search) if (value !== "") search = true
    if (!search) if (String(situation) !== "0") search = true

    if (!search) 
    {
      setLoading("")
      setWarning("Nenhum filtro informado!")
      setError("")
      setShowResult(false)
    }

    if (search){
      setWarning("")
      setError("")
      setLoading("Aguarde buscando as informações...")

      let jBordereauReceive = "{"
      jBordereauReceive += " \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"

      if (code !== "") jBordereauReceive += ", \"ID\": "+ String(code).replaceAll("_","")

      if (validateDate(openDateIni) && validateDate(openDateEnd)){
        const openI = openDateIni.toLocaleString()
        const openE = openDateEnd.toLocaleString()
        
        const openIni = String(openI).substr(6,4) + "-" + String(openI).substr(3,2) + "-" + String(openI).substr(0,2) + "T00:00:00";
        const openEnd = String(openE).substr(6,4) + "-" + String(openE).substr(3,2) + "-" + String(openE).substr(0,2) + "T00:00:00";
        jBordereauReceive += ", \"OPEN_DATE_START\": \""+ openIni + "\""
        jBordereauReceive += ", \"OPEN_DATE_END\": \""+ openEnd + "\""
      }

      if (validateDate(cancelDateIni) && validateDate(cancelDateEnd)){
        const cancelI = cancelDateIni.toLocaleString()
        const cancelE = cancelDateEnd.toLocaleString()

        const cancelIni = String(cancelI).substr(6,4) + "-" + String(cancelI).substr(3,2) + "-" + String(cancelI).substr(0,2) + "T00:00:00";
        const cancelEnd = String(cancelE).substr(6,4) + "-" + String(cancelE).substr(3,2) + "-" + String(cancelE).substr(0,2) + "T00:00:00";
        jBordereauReceive += ", \"CANCEL_DATE_START\": \""+ cancelIni + "\""
        jBordereauReceive += ", \"CANCEL_DATE_END\": \""+ cancelEnd + "\""
      }

      if (value !== "") jBordereauReceive += ", \"VALUE\": "+ value
      if (String(situation) !== "0") jBordereauReceive += ", \"SITUATION\": "+ situation
      jBordereauReceive += " }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jBordereauReceive)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BORDEREAU_RECEIVE/TotalRecords", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total de Borderos a Receber. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setWarning("")
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setError("")
          setLoading("")
          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jBordereauReceive)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "BORDEREAU_RECEIVE/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar os Borderos a Receber. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setWarning("")
            setError(returnJson.ERROR)
            setLoading("") 
            return
          }

          setResult(returnJson)
          setShowResult(true)
          setLoading("")
        })
      })
    }
  }

  const setFieldSelected = () => {
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");        

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            {/* Begin - Buttons // Botões */}
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={search}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                </div>
              </div>

              { !modeSearch &&
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                  width: 147}} onClick={() => setAdd()}>
                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                    paddingBottom: 5, paddingLeft: 10}}>
                    <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                  </div>
                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                  </div>
                </div>
              }

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={clean}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                </div>
              </div>

              <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, marginLeft: 5,
                width: 147}} onClick={setReturn}>
                <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                  paddingBottom: 5, paddingLeft: 10}}>
                  <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                </div>
                <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                  <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                </div>
              </div>
            </div>
            {/* End - Buttons // Botões */}

            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
            </div>

            <div style={{paddingLeft: 10, paddingRight: 10}}>
              { warning &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                </div>
              }

              { error &&
                <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                  <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                </div>
              }

              <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}> 
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                  marginBottom: 10, marginLeft: 0}}>
                  <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                </div>

                <div style={(showMenu) ? 
                    (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={(showMenu) ? 
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                    : 
                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                    <div style={(showMenu) ? 
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                      : 
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 740) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                      <InputMasked
                        type="text"
                        name="searchBordereauReceiveCode"
                        description="Código"
                        mask="99999999999"
                        value={code}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setCode(event.target.value)} />
                    </div>
                    
                    <div style={(showMenu) ? 
                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 720) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 910) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                      : 
                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 740) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="searchBordereauReceiveValue"
                        description="Valor"
                        value={value || ""}
                        maxLength={13}
                        disabled={false}
                        require={false}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleValue} />
                    </div>
                  </div>
                  
                  <div style={(showMenu) ? 
                      (width < 910) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                    : 
                      (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                      : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                    <Select
                      name="searchBordereauReceiveSituation"
                      description="Situação"
                      value={situation || 0}
                      require={false}
                      options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Fechado\" }"+
                        ", {\"ID\": 2, \"VALUE\": \"Cancelado\" } ] }")}
                      image={false}
                      typeInput={2}
                      onSelect={setFieldSelected}
                      set={setSituation} />
                  </div>
                </div>

                <div style={(showMenu) ? 
                    (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                  : 
                    (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                  <div style={(showMenu) ? 
                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}
                    : 
                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                    <b>Data Lançamento</b>
                    <div style={(showMenu) ? 
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                        : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={(showMenu) ?
                          (width < 1020) ? {display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1, minWidth: 150}
                        : 
                          (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                        <DataPicker
                          name="searchBordereauReceiveOpenDateIni"
                          description="Inicial"
                          value={openDateIni}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setOpenDateIni(event)} 
                          intervalo={true}
                          inicial={true}
                          endDate={openDateEnd} />
                      </div>

                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                            marginTop: 20, minWidth: 25, maxWidth: 25}
                          : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                            minWidth: 25, maxWidth: 25}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                          minWidth: 25, maxWidth: 25}}>

                        <label style={{fontWeight: 600}}>à</label>
                      </div>

                      <div style={(showMenu) ? 
                          (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                        : 
                          (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                        <DataPicker
                          name="searchBordereauReceiveOpenDateEnd"
                          description="Final"
                          value={openDateEnd}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setOpenDateEnd(event)} 
                          intervalo={true}
                          inicial={false}
                          startDate={openDateIni} />
                      </div>
                    </div>
                  </div>

                  <div style={(showMenu) ? 
                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}
                    : 
                      (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                      : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                    <b>Data Cancelamento</b>
                    <div style={(showMenu) ? 
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                        : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={(showMenu) ? 
                          (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                        : 
                          (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                        <DataPicker
                          name="searchBordereauReceiveCancelDateIni"
                          description="Inicial"
                          value={cancelDateIni}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setCancelDateIni(event)} 
                          intervalo={true}
                          inicial={true}
                          endDate={cancelDateEnd} />
                      </div>

                      <div style={(showMenu) ? 
                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                          marginTop: 20, minWidth: 25, maxWidth: 25}
                          : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                          minWidth: 25, maxWidth: 25}
                        : 
                          (width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", marginTop: 10, minWidth: 25}
                          : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                          minWidth: 25, maxWidth: 25}}>

                        <label style={{fontWeight: 600}}>à</label>
                      </div>

                      <div style={(showMenu) ?
                          (width < 1020) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}
                        : 
                          (width < 740) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                        <DataPicker
                          name="searchBordereauReceiveCancelDateEnd"
                          description="Final"
                          value={cancelDateEnd}
                          require={false}
                          onSelect={setFieldSelected}
                          onChange={(event) => setCancelDateEnd(event)} 
                          intervalo={true}
                          inicial={false}
                          startDate={cancelDateIni} />
                      </div>
                    </div>
                  </div>
                </div>

                { showResult && 
                  <>

                    { result.OBJECT &&
                      <>
                        <div style={showMenu ?
                            (width < 400) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                          :
                            (width < 290) ? {display: "flex", flexDirection: "column", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}
                            :
                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                marginTop: 30, marginBottom: 4}}>
                                
                          <div style={(showMenu) ? 
                              (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            : 
                              (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                            <div style={(showMenu) ? 
                                (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 1010) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                              : 
                                (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                              <div style={(showMenu) ? 
                                  (width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 550) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 530) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={showMenu ?
                                    (width < 360) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                  :
                                    (width < 390) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                  <label style={{fontWeight: 600}}>Código</label>
                                </div>

                                <div style={showMenu ?
                                    (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    : (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                  :
                                    (width < 530) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                  <label style={{fontWeight: 600}}>Abertura</label>
                                </div>
                              </div>

                              <div style={showMenu ?
                                  (width < 1010) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                :
                                  (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                <label style={{fontWeight: 600}}>Cancelam.</label>
                              </div>
                            </div>

                            <div style={(width < 410) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                              <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                <label style={{fontWeight: 600}}>Valor</label>
                              </div>

                              <div style={(width < 410) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                <label style={{fontWeight: 600}}>Situação</label>
                              </div>
                            </div>
                          </div>

                          { showMenu && (width >= 400) && <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 50}} /> }
                          { !showMenu && (width >= 290) && <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 50}} /> }

                        </div>

                        { result.OBJECT.map(item => (
                          <div key={item.ID} style={showMenu ?
                              (width < 400) ?
                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                 backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                 borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                 marginTop: 4, minWidth: 180}
                              :
                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                marginTop: 4, minWidth: 180}
                            :
                              (width < 290) ?
                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                 backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                 borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                 marginTop: 4, minWidth: 180}
                              :
                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                marginTop: 4, minWidth: 180}}>
                          
                            <div style={(showMenu) ? 
                                (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                              : 
                                (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                              <div style={(showMenu) ? 
                                  (width < 550) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : (width < 810) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 1010) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                : 
                                  (width < 530) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                  : (width < 740) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                <div style={(showMenu) ? 
                                    (width < 360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 550) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                    : (width < 810) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}
                                  : 
                                    (width < 390) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                    : (width < 530) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                  <div style={showMenu ?
                                      (width < 360) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                    :
                                      (width < 390) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                    <label>{item.ID}</label>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 550) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                      : (width < 810) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                    :
                                      (width < 530) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                    <label>
                                      { String(item.OPEN_DATE).substr(8,2) + "/" +
                                        String(item.OPEN_DATE).substr(5,2) + "/" +
                                          String(item.OPEN_DATE).substr(0,4) }
                                    </label>
                                  </div>
                                </div>

                                <div style={showMenu ?
                                    (width < 1010) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                  :
                                    (width < 740) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                    : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                  <label>
                                    { item.CANCEL_DATE !== "0001-01-01T00:00:00" ?
                                        String(item.CANCEL_DATE).substr(8,2) + "/" +
                                          String(item.CANCEL_DATE).substr(5,2) + "/" +
                                            String(item.CANCEL_DATE).substr(0,4) 
                                      : "-" }
                                  </label>
                                </div>
                              </div>

                              <div style={(width < 410) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                <div style={{display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                  <label>{ item.VALUE ? formatNumeric(item.VALUE, 2) : "0.00" }</label>
                                </div>

                                <div style={(width < 410) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                  <label>{item.SITUATION === 1 && "Fechado"}</label>
                                  <label>{item.SITUATION === 2 && "Cancelado"}</label>
                                </div>
                              </div>
                            </div>

                            <div style={showMenu ?
                                (width < 400) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}
                              : 
                                (width < 290) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginBottom: 10}
                                : {display: "flex", justifyContent: "flex-end", marginRight: 5, width: 40}}>

                              { item.SITUATION !== 2 &&
                                <div style={{display: "flex", alignItems: "center"}}>
                                  <img 
                                    style={{height: 32, width: 32}}
                                    name="searchBordereauReceiveSelected"
                                    src={image_select}
                                    alt="Selecionar"
                                    onClick={(() => setSelected(item))} />
                                </div>
                              }

                            </div>
                          </div>
                          ))
                        }

                        <SearchPagination 
                          pageCurrent={pageCurrent}
                          totalPage={totalPage}
                          recordPage={recordPage}
                          optionsRegPage={JSON.parse(optionsRegPage)}
                          pagePrevious={pagePrevious}
                          pageNext={pageNext}
                          setSelectedField={setFieldSelected}
                          handleRecordPage={handleRecordPage}
                          width={width} />
                      </>
                    }
                    
                  </>
                }

              </div>
            </div>
          </div>
      }

    </div>
  )
}
    
export default SearchBordereauReceive